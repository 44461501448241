import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { Autocomplete, Box, Button, Grid, Modal, Stack, SxProps, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GetContextMenuItems, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectUserAgGrid } from 'src/components/app-components/bonsai/SelectUserAgGrid';
import { setComment, setRowData, setSelectedUser, setShowConfirm } from 'src/features/general/enquete-answer/store/selectConfirmerModalSlice';
import useSettings from 'src/hooks/useSettings';
import useSuggestUser from 'src/hooks/useSuggestUser';
import { RootState } from 'src/redux/store';
import palette from 'src/theme/palette';

export interface Props {
  surveyDetailId: string | undefined;
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export const SelectConfirmerModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { userSuggest, updateUserSuggest } = useSuggestUser();
  const { comment, rowData, selectedUser } = useSelector((state: RootState) => state.selectConfirmerModal);

  //-------------------------------------------------------------
  // useRef
  //-------------------------------------------------------------
  const gridApi = useRef<GridApi | null>(null);

  //-------------------------------------------------------------
  // グリッドの表示準備完了時の処理
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // 追加対象一覧に追加
  //-------------------------------------------------------------
  const onAddList = () => {
    // check input string
    const user = userSuggest.find((user) => user.key === selectedUser);
    if (!user) return enqueueSnackbar(t('answerConfirmStatus.selectConfirmerModal.message.notSelectUser'), { variant: 'error' });

    // check duplicated
    const addRowData = JSON.stringify(user.entity);
    const exists = rowData.filter((rowdata) => JSON.stringify(rowdata) === addRowData);
    if (exists.length !== 0) return enqueueSnackbar(t('answerConfirmStatus.selectConfirmerModal.message.alreadyRegistered'), { variant: 'warning' });

    // add to rowdata
    dispatch(setRowData([...rowData, user.entity]));
  };

  //-------------------------------------------------------------
  // 削除ボタンが押された時の処理
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('button.delete'),
        action: () => dispatch(setRowData(rowData.filter((row, i) => i !== params.node?.rowIndex))),
      },
    ];

    return menuItems;
  };

  //-------------------------------------------------------------
  // バリデーション
  //-------------------------------------------------------------
  const onValidation = () => {
    if (rowData.length === 0) return enqueueSnackbar(t('answerConfirmStatus.selectConfirmerModal.message.userNotAdded'), { variant: 'error' });
    if (comment === null) return enqueueSnackbar(t('answerConfirmStatus.selectConfirmerModal.message.noCommentEntered'), { variant: 'error' });
    dispatch(setShowConfirm(true));
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    if (props.open) {
      updateUserSuggest();
      dispatch(setShowConfirm(false));
    }

    return () => {
      dispatch(setShowConfirm(false));
    };
  }, [props.open]); // eslint-disable-line

  //-------------------------------------------------------------
  // SxProps
  //-------------------------------------------------------------
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80vw',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const tableStyle: SxProps = {
    height: '300px',
    width: '100%',
    transition: 'height 0.5s',
  };

  return (
    //  送信者選択画面
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={modalStyle}>
        <Stack spacing={2}>
          {/* modal title */}
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h4'} component={'h2'} children={t('answerConfirmStatus.selectConfirmerModal.title')} />
            </Grid>
          </Grid>

          {/* conditions */}
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            {/* user selector */}
            <Grid item xs={12} sm={10}>
              <Autocomplete
                size="small"
                fullWidth
                value={selectedUser}
                renderInput={(params) => (
                  <TextField {...params} label={t('answerConfirmStatus.selectConfirmerModal.textfield.selectConfirmer.label')} />
                )}
                options={userSuggest.map((item) => item.key)}
                onChange={(e, v) => dispatch(setSelectedUser(v))}
                onInputChange={(e, v, r) => (r !== 'reset' ? updateUserSuggest(v) : undefined)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Stack direction={'row-reverse'}>
                <Button
                  startIcon={<AddIcon />}
                  fullWidth={useMediaQuery(theme.breakpoints.down('sm'))}
                  variant={'contained'}
                  onClick={onAddList}
                  children={t('button.add')}
                />
              </Stack>
            </Grid>
          </Grid>

          {/* data table */}
          <Grid container>
            <Box sx={tableStyle} className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}>
              <SelectUserAgGrid surveyDetailId={props.surveyDetailId} rowData={rowData} onGridReady={onGridReady} contextMenu={contextMenu} />
            </Box>
          </Grid>

          {/* data table footer */}
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={3}
                placeholder={t('answerConfirmStatus.selectConfirmerModal.textfield.requestConfirmComment.label')}
                value={comment}
                onChange={(e) => dispatch(setComment(e.target.value === '' ? null : e.target.value))}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'right'}>
                <Button startIcon={<SendIcon />} variant={'contained'} children={t('button.send')} onClick={onValidation} />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};
