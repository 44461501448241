import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerPerformance } from 'src/@types/overviewEnquete';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import useSettings from 'src/hooks/useSettings';
import { RootState, useAppSelector } from 'src/redux/store';
import { useAgGridField } from '../hooks/useAgGridField';

export interface Props {
  isClosed: boolean;
}

const AgGridField: React.FC<Props> = React.memo((props) => {
  const { isClosed } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const overviewEnqueteOptionData = useAppSelector((state: RootState) => state.enqueteOption.overviewEnqueteOptionData as CustomerPerformance[]);

  const { defaultColDef, coldef, onGridReady, onCellValueChanged } = useAgGridField({ overviewEnqueteOptionData, isClosed });

  return (
    <>
      <Grid container item direction="row" justifyContent="flex-start" columnSpacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{`${t('enqueteCreate.overviewEnqueteOption.customerPerformance')}`}</Typography>
        </Grid>
        <Grid item xs={10} md={10}>
          <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: '35vh', width: '100%' }}>
            <StyledAgGrid
              defaultColDef={defaultColDef}
              coldef={coldef}
              rows={overviewEnqueteOptionData}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default AgGridField;
