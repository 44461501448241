import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Condition, GENERAL_ENQUETE_JSON_INPUT_TYPE, Question, QuestionState } from 'src/@types/generalEnquete';

// 回答した選択肢に設定されている条件を探す
const findConditionForAnswer = (questionId: string, answer: string, questions: Question[]) => {
  const question = questions.find((q) => q.id === questionId);
  if (!question) {
    return null;
  }

  const matchingCondition = question.conditions?.find((c) => c.questionOptionId === answer);
  if (!matchingCondition) {
    return null;
  }
  return matchingCondition;
};

// For Operating Question States
const operateSkip = (questionId: string, condition: Condition, questionStates: QuestionState[]) => {
  const questionIdSkipTo = condition.targetQuestionId;
  const questionIdSkipFrom = questionId;

  // set question state to hidden between questionIdSkipFrom and questionIdSkipTo
  // 自分より後ろの質問に対するstateを変更する
  const newQuestionStates = questionStates.map((questionState, index) => {
    if (questionState.questionId === questionIdSkipFrom || questionState.questionId === questionIdSkipTo) {
      questionState.state = 'visible';
    } else if (
      index > questionStates.findIndex((questionState) => questionState.questionId === questionIdSkipFrom) &&
      index < questionStates.findIndex((questionState) => questionState.questionId === questionIdSkipTo)
    ) {
      questionState.state = 'hidden';
    }
    return questionState;
  });
  return newQuestionStates;
};

const operateSkipAndDisable = (questionId: string, condition: Condition, questionStates: QuestionState[]) => {
  const questionIdSkipTo = condition.targetQuestionId;
  const questionIdSkipFrom = questionId;

  const newQuestionStates = questionStates.map((questionState, index) => {
    if (questionState.questionId === questionIdSkipFrom) {
      questionState.state = 'visible';
    } else if (questionState.questionId === questionIdSkipTo) {
      questionState.state = 'visible';
    } else if (
      index > questionStates.findIndex((questionState) => questionState.questionId === questionIdSkipFrom) &&
      index < questionStates.findIndex((questionState) => questionState.questionId === questionIdSkipTo)
    ) {
      questionState.state = 'disabled';
    }
    return questionState;
  });
  return newQuestionStates;
};

const operateHide = (condition: Condition, questionStates: QuestionState[]) => {
  // targetQuestionIdの質問を非表示にする
  const targetQuestionId = condition.targetQuestionId;
  const newQuestionStates: QuestionState[] = JSON.parse(JSON.stringify(questionStates));

  newQuestionStates.forEach((questionState) => {
    if (questionState.questionId === targetQuestionId) {
      questionState.state = 'hidden';
    }
  });

  return newQuestionStates;
};

const operateShow = (questionId: string, codition: Condition, questionStates: QuestionState[], initialQuestionStates: QuestionState[]) => {
  // targetQuestionIdの質問を表示する（ラジオ、プルダウン用）
  const targetQuestionId = codition.targetQuestionId;
  const newQuestionStates: QuestionState[] = JSON.parse(JSON.stringify(questionStates));
  const myIndex = newQuestionStates.findIndex((questionState) => questionState.questionId === questionId);
  const targetIndex = newQuestionStates.findIndex((questionState) => questionState.questionId === targetQuestionId);
  newQuestionStates.forEach((questionState, index) => {
    if (index > myIndex) {
      questionState.state = initialQuestionStates?.find((initialQuestionState) => initialQuestionState.questionId === questionState.questionId)
        ?.state as QuestionState['state'];
    } else {
      questionState.state = questionStates?.find((q) => questionState.questionId === q.questionId)?.state as QuestionState['state'];
    }
  });
  newQuestionStates[targetIndex].state = 'visible';
  return newQuestionStates;
};

const operateCheckboxShow = (codition: Condition, questionStates: QuestionState[]) => {
  // targetQuestionIdの質問を表示する（チェックボックス用）
  const targetQuestionId = codition.targetQuestionId;
  const newQuestionStates: QuestionState[] = JSON.parse(JSON.stringify(questionStates));
  const targetIndex = newQuestionStates.findIndex((questionState) => questionState.questionId === targetQuestionId);
  newQuestionStates[targetIndex].state = 'visible';
  return newQuestionStates;
};

const operateDisable = (codition: Condition, questionStates: QuestionState[]) => {
  // targetQuestionIdの質問を非活性にする
  const targetQuestionId = codition.targetQuestionId;
  const newQuestionStates = questionStates.map((questionState) => {
    if (questionState.questionId === targetQuestionId) {
      questionState.state = 'disabled';
    }
    return questionState;
  });
  return newQuestionStates;
};

const operateReset = (questionId: string, questionStates: QuestionState[], initialQuestionStates: QuestionState[]) => {
  // 自分より後ろのindexの質問を初期状態に戻す
  const myIndex = questionStates.findIndex((questionState) => questionState.questionId === questionId);

  const newQuestionStates: QuestionState[] = JSON.parse(JSON.stringify(questionStates));
  newQuestionStates.map((questionState, index) => {
    if (index > myIndex) {
      questionState.state = initialQuestionStates?.find((initialQuestionState) => initialQuestionState.questionId === questionState.questionId)
        ?.state as QuestionState['state'];
    }
    return questionState;
  });
  return newQuestionStates;
};

// 他の選択肢で設定されている条件を確認して、状態をクリアする
const operateResetInSameCondition = (
  questionId: string,
  conditions: Condition[],
  questionStates: QuestionState[],
  initialQuestionStates: QuestionState[],
) => {
  let newQuestionStates = [...questionStates];
  conditions.forEach((condition) => {
    if (condition.logic === 'skip' || condition.logic === 'skip-disable') {
      const questionIdSkipTo = condition.targetQuestionId;
      const questionIdSkipFrom = questionId;
      // questionIdSkipFromとquestionIdSkipToの間の質問を表示する
      newQuestionStates = newQuestionStates.map((questionState, index) => {
        if (questionState.questionId === questionIdSkipFrom) {
          questionState.state = initialQuestionStates?.find((questionState) => questionState.questionId === questionIdSkipFrom)?.state!;
        } else if (questionState.questionId === questionIdSkipTo) {
          questionState.state = initialQuestionStates?.find((questionState) => questionState.questionId === questionIdSkipTo)?.state!;
        } else if (
          index > questionStates.findIndex((questionState) => questionState.questionId === questionIdSkipFrom) &&
          index < questionStates.findIndex((questionState) => questionState.questionId === questionIdSkipTo)
        ) {
          questionState.state = initialQuestionStates?.find((questionState) => questionState.questionId === questionIdSkipTo)?.state!;
        }
        return questionState;
      });
    } else if (condition.logic === 'hide') {
      const targetQuestionId = condition.targetQuestionId;
      newQuestionStates = newQuestionStates.map((questionState) => {
        if (questionState.questionId === targetQuestionId) {
          questionState.state = initialQuestionStates?.find((questionState) => questionState.questionId === targetQuestionId)?.state!;
        }
        return questionState;
      });
    } else if (condition.logic === 'show') {
      const targetQuestionId = condition.targetQuestionId;
      newQuestionStates = newQuestionStates.map((questionState) => {
        if (questionState.questionId === targetQuestionId) {
          questionState.state = initialQuestionStates?.find((questionState) => questionState.questionId === targetQuestionId)?.state!;
        }
        return questionState;
      });
    } else if (condition.logic === 'disable') {
      const targetQuestionId = condition.targetQuestionId;
      newQuestionStates = newQuestionStates.map((questionState) => {
        if (questionState.questionId === targetQuestionId) {
          questionState.state = initialQuestionStates?.find((questionState) => questionState.questionId === targetQuestionId)?.state!;
        }
        return questionState;
      });
    }
  });
  return newQuestionStates;
};

const setAnswer = (
  questionId: string,
  value: string[],
  questions: Question[],
  questionStates: QuestionState[],
  initialQuestionStates: QuestionState[],
) => {
  const answerValues: string[] = [...value];
  // チェックボックスの場合は、複数回答が存在する場合があるのでループで設定する。
  answerValues.forEach((answerValue) => {
    const matchingCondition = findConditionForAnswer(questionId, answerValue, questions);
    if (!matchingCondition) {
      questionStates = operateReset(questionId, questionStates, initialQuestionStates);
      return;
    }

    const question = questions.find((q) => q.id === questionId);
    // 自分より後ろの質問の条件内で設定されている他の条件で変更された状態をクリアする
    // 上位の質問の選択肢が変更されたら下位の質問の条件で変更された状態をクリアする必要があるため
    if (question?.inputType !== GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX) {
      questionStates = operateReset(questionId, questionStates, initialQuestionStates);
    }
    // 同様に同じ質問の条件内で設定されている他の条件で変更された状態をクリアする
    const conditionList = questions.find((question) => question.id === questionId)!.conditions!;
    if (question?.inputType !== GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX) {
      questionStates = operateResetInSameCondition(questionId, conditionList, questionStates, initialQuestionStates);
    }

    const { logic } = matchingCondition;
    switch (logic) {
      case 'skip':
        questionStates = operateSkip(questionId, matchingCondition, questionStates);
        break;
      case 'skip-disable':
        questionStates = operateSkipAndDisable(questionId, matchingCondition, questionStates);
        break;
      case 'show':
        if (question?.inputType !== GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX) {
          questionStates = operateShow(questionId, matchingCondition, questionStates, initialQuestionStates);
        } else {
          questionStates = operateCheckboxShow(matchingCondition, questionStates);
        }
        break;
      case 'hide':
        questionStates = operateHide(matchingCondition, questionStates);
        break;
      case 'disable':
        questionStates = operateDisable(matchingCondition, questionStates);
        break;
      default:
        break;
    }
  });
  return questionStates;
};

// 条件の再描画用
const reOperate = (questions: Question[], questionStates: QuestionState[], initialQuestionStates: QuestionState[]) => {
  questionStates.forEach((questionState) => {
    const question = questions.find((question) => question.id === questionState.questionId);
    if (!question) {
      return;
    }
    // memo:questionState.state === 'hidden',questionState.state === 'disabled' の場合は回答を提出時にクリアする。
    if (questionState.state === 'visible') {
      // memo:checkboxの場合は複数になることを考慮
      const answers = question?.answerItem
        ?.filter((item) => item.value === true)
        ?.map((answerItem) => (answerItem.questionItemId !== undefined ? answerItem.questionItemId : ''));
      if (answers === undefined || answers.length === 0) {
        return;
      }
      answers.forEach((answerValue) => {
        const matchingCondition = findConditionForAnswer(question.id, answerValue, questions);
        if (!matchingCondition) {
          return;
        }
        const { logic } = matchingCondition;
        switch (logic) {
          case 'skip':
            questionStates = operateSkip(question.id, matchingCondition, questionStates);
            break;
          case 'skip-disable':
            questionStates = operateSkipAndDisable(question.id, matchingCondition, questionStates);
            break;
          case 'show':
            if (question?.inputType !== GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX) {
              questionStates = operateShow(question.id, matchingCondition, questionStates, initialQuestionStates);
            } else {
              questionStates = operateCheckboxShow(matchingCondition, questionStates);
            }
            break;
          case 'hide':
            questionStates = operateHide(matchingCondition, questionStates);
            break;
          case 'disable':
            questionStates = operateDisable(matchingCondition, questionStates);
            break;
          default:
            break;
        }
      });
    }
  });
  return questionStates;
};

// タブ：[回答]汎用回答 一問一答 定義
export const enqueteGeneralAnswerSlice = createSlice({
  name: 'enqueteGeneralAnswer',
  initialState: {
    questions: Array<Question>(),
    initialQuestionStates: [] as QuestionState[],
    questionStates: [] as QuestionState[],
    hasError: false as boolean,
  },
  reducers: {
    // Questionsを設定する（初期設定用）
    setInitGeneralQuestions: (state, action: PayloadAction<Array<Question>>) => {
      state.questions = action.payload;

      const newInitialQuestionStates = state.questions.map((question) => {
        const questionState: QuestionState = {
          id: question.id,
          questionId: question.id,
          state: 'visible',
        };
        return questionState;
      });

      state.questions.forEach((question) => {
        question.conditions?.forEach((condition) => {
          if (condition.logic === 'show') {
            const questionIdShow = condition.targetQuestionId;
            newInitialQuestionStates.forEach((questionState) => {
              if (questionState.questionId === questionIdShow) {
                questionState.state = 'hidden';
              }
            });
          }
        });
      });
      state.initialQuestionStates = JSON.parse(JSON.stringify(newInitialQuestionStates));

      // reOperate
      state.questionStates = reOperate(state.questions, JSON.parse(JSON.stringify(newInitialQuestionStates)), state.initialQuestionStates);
    },
    // Questionsを設定する（回答内容追加用）
    setGeneralQuestions: (state, action) => {
      const { index, value } = action.payload;
      state.questions.splice(index, 1, value);

      // memo：ラジオボタン、プルダウン、チェックボックスの場合のみquestionStatesを更新する。
      if (
        (value as Question).inputType === GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP ||
        (value as Question).inputType === GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT ||
        (value as Question).inputType === GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX
      ) {
        // memo:checkboxの場合は複数になることを考慮
        const answer = (value as Question).answerItem
          ?.filter((item) => item.value === true)
          ?.map((answerItem) => (answerItem.questionItemId !== undefined ? answerItem.questionItemId : ''));
        if (answer === undefined || answer.length === 0) {
          // operateReset
          // チェックボックス、プルダウンで何も選択されない状態になったら、リセットする。
          state.questionStates = reOperate(
            state.questions,
            operateReset(state.questions[index].id, state.questionStates, state.initialQuestionStates),
            state.initialQuestionStates,
          );
          return;
        }
        let newQuestionStates;
        if ((value as Question).inputType === GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX) {
          newQuestionStates = operateReset(state.questions[index].id, state.questionStates, state.initialQuestionStates);
        } else {
          newQuestionStates = JSON.parse(JSON.stringify(state.questionStates));
        }
        // reOperate
        state.questionStates = reOperate(
          state.questions,
          setAnswer(value.id, answer, state.questions, newQuestionStates, state.initialQuestionStates),
          state.initialQuestionStates,
        );
      }
    },
    // アンケート回答入力時の状態を保持する
    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
  },
});
export const { setGeneralQuestions, setInitGeneralQuestions, setHasError } = enqueteGeneralAnswerSlice.actions;

export default enqueteGeneralAnswerSlice.reducer;
