import { useCallback } from 'react';
import { setInitGeneralQuestions } from 'src/features/general/enquete-answer/store/enqueteGeneralAnswerSlice';
import {
  setIsModalOpenLogic,
  setIsModalOpenPreview,
  setIsModalOpenTranslationSupport,
  setIsOpenLogicErrorMessageModal,
  setLogicErrorMessages,
  setLogicQuestions,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';

export const useModalHandlers = () => {
  const { currentLang } = useLocales();
  const dispatch = useAppDispatch();
  const questionsJpn = useAppSelector((state: RootState) => state.enqueteCreate.questions);
  const questionsEng = useAppSelector((state: RootState) => state.enqueteCreate.questionsEng);
  const questions = currentLang.value === 'ja' ? questionsJpn : questionsEng;
  const onClickModalOpenTranslationSupport = useCallback(() => {
    dispatch(setIsModalOpenTranslationSupport(true));
  }, [dispatch]);

  const onClickModalCloseTranslationSupport = useCallback(() => {
    dispatch(setIsModalOpenTranslationSupport(false));
  }, [dispatch]);

  const onModalOpenPreview = useCallback(() => {
    dispatch(setIsModalOpenPreview(true));
    dispatch(setInitGeneralQuestions(questions));
  }, [dispatch, questions]);

  const onModalClosePreview = useCallback(() => {
    dispatch(setIsModalOpenPreview(false));
  }, [dispatch]);

  const onClickModalOpenLogic = useCallback(() => {
    dispatch(setLogicQuestions(questions));
    dispatch(setIsModalOpenLogic(true));
  }, [dispatch, questions]);

  const onClickModalCloseLogic = useCallback(() => {
    dispatch(setLogicQuestions([]));
    dispatch(setIsModalOpenLogic(false));
  }, [dispatch]);

  const onClickModalCloseLogicError = useCallback(() => {
    dispatch(setLogicErrorMessages([]));
    dispatch(setIsOpenLogicErrorMessageModal(false));
  }, [dispatch]);

  return {
    onClickModalOpenTranslationSupport,
    onClickModalCloseTranslationSupport,
    onModalOpenPreview,
    onModalClosePreview,
    onClickModalOpenLogic,
    onClickModalCloseLogic,
    onClickModalCloseLogicError,
  };
};
