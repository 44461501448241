import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { useAppDispatch } from 'src/redux/store';
import { EnqueteAnswerEntity, setConfirmHistoryData, setConfirmRequestData, setEnqueteAnswerData } from '../store/enqueteAnswerSlice';

const useEnqueteAnswer = () => {
  const dispatch = useAppDispatch();
  const { getAnswer, getConfirmRequest, getConfirmHistoryData } = useEnqueteAnswerInputApi();
  const fetchAnswer = async (respondentTargetId: string): Promise<EnqueteAnswerEntity | undefined> => {
    try {
      const response = await getAnswer(respondentTargetId);
      if (response) dispatch(setEnqueteAnswerData(response ?? {}));
      return response;
    } catch (error: unknown) {
      throw error;
    }
  };

  const fetchConfirmRequest = async (confrimRequestId: string): Promise<void> => {
    try {
      const response = await getConfirmRequest(confrimRequestId);
      if (response) dispatch(setConfirmRequestData(response));
    } catch (error: unknown) {
      throw error;
    }
  };

  const fetchConfirmHistories = async (answerId: string): Promise<void> => {
    try {
      const response = await getConfirmHistoryData(answerId);
      dispatch(setConfirmHistoryData(response));
    } catch (error: unknown) {
      throw error;
    }
  };

  return {
    fetchAnswer,
    fetchConfirmRequest,
    fetchConfirmHistories,
  };
};

export default useEnqueteAnswer;
