import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { ChipData } from '../components/pages/create/components/EnqueteThreadCreateLayout';

export type Rules = {
  required: {
    value: boolean;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
};

export const useValidator = (methods: UseFormReturn) => {
  const { t } = useTranslation();
  const threadUsers = useAppSelector((state) => state.enqueteThreadCreate.threadUsers);

  const rulesTextFieldTitle: Rules = {
    maxLength: { value: 100, message: t('validateError.maxLength', { max: '100' }) },
    required: t('validateError.required', { target: t('enqueteThread.create.threadTitle') }),
  };

  const rulesAutoComplete: Rules = {
    required: { value: true, message: t('validateError.requiredSelect', { target: t('enqueteThread.create.enqueteTitle') }) },
  };

  const rulesCategory = {
    message: (value: ChipData[]): boolean | string => {
      const result = value?.find((v) => v.selected === true);
      const errorMessage: string = t('validateError.requiredSelect', { target: t('enqueteThread.create.category') });
      return !!result || errorMessage;
    },
  };

  const rulesRadioPublic = {
    required: {
      message: t('validateError.requiredSelect', { target: t('enqueteThread.create.publicClassification') }),
      value: true,
    },
    validate: {
      message: () => {
        if ((methods.watch('isPrivate') === 'private' && threadUsers.length !== 0) || methods.watch('isPrivate') === 'public') {
          methods.clearErrors('threadUserDialog');
          return true;
        } else {
          methods.setError('threadUserDialog', { message: t('validateError.required', { target: t('enqueteThread.create.threadMember') }) });
          return true;
        }
      },
    },
  };

  const rulesThreadMemberDialog = {
    message: () => {
      if ((methods.watch('isPrivate') === 'private' && threadUsers.length !== 0) || methods.watch('isPrivate') === 'public') {
        methods.clearErrors('threadUserDialog');
        return true;
      } else {
        return t('validateError.required', { target: t('enqueteThread.create.threadMember') });
      }
    },
  };

  const rulesRadioSend: Rules = {
    required: {
      message: t('validateError.requiredSelect', { target: t('enqueteThread.create.sendMail') }),
      value: true,
    },
  };

  const rulesTextFieldContent: Rules = {
    required: t('validateError.required', { target: t('enqueteThread.create.threadContent') }),
    maxLength: { value: 3000, message: t('validateError.maxLength', { max: '3,000' }) },
  };

  return {
    rulesTextFieldTitle,
    rulesAutoComplete,
    rulesCategory,
    rulesRadioPublic,
    rulesThreadMemberDialog,
    rulesRadioSend,
    rulesTextFieldContent,
  };
};
