import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'ja',
    label: 'Japanese',
    icon: '/static/icons/ic_flag_ja.svg',
    code: 'JP',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
    code: 'EN',
  },
];

export interface languageDataTypes {
  ja: string | null;
  en: string | null;
}

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];
  const isJapanese = currentLang.value === 'ja';
  
  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
    isJapanese,
  };
}
