import { EnqueteAnswerListPage } from '../pages/EnqueteAnswerListPage';
import { EnqueteAnswerPage } from '../pages/EnqueteAnswerPage';

//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteAnswerRoute = {
  path: 'enquete-answer',
  children: [
    {
      path: '',
      element: <EnqueteAnswerListPage />,
    },
    {
      path: 'input',
      element: <EnqueteAnswerPage />,
    },
    {
      path: 'input/:id',
      element: <EnqueteAnswerPage />,
    },
  ],
};
