import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { OverviewEnqueteOptionData } from 'src/@types/overviewEnquete';
import { SeriesEnqueteOptionData } from 'src/@types/seriesEnquete';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export const useEnqueteOptionApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * オプションタブの更新
   * @param surveyDetailId
   * @param data
   * @returns
   */
  const putEnqueteOption = async (surveyDetailId: string, data: SeriesEnqueteOptionData | OverviewEnqueteOptionData) => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<SeriesEnqueteOptionData | OverviewEnqueteOptionData>(
        apiGateway + `/survey-edit/option/` + surveyDetailId,
        data,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );
      return result.status;
    } catch (error) {
      // memo: 上位層でエラーハンドリングの仕組みがないため、ここでは画面にエラーであることを表示し握りつぶす
      enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('enqueteCreate.tab.option') }), { variant: 'error' });
    }
  };

  return { putEnqueteOption };
};
