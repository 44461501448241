import { useEffect } from 'react';
import { FormState, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  setIsQuestionDescriptionEngValid,
  setIsQuestionDescriptionJpnValid,
  setQuestionDescriptionEng,
  setQuestionDescriptionJpn,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';

export type FormValues = {
  description: string;
};

type EnqueteDescriptionFormValues = {
  formState: FormState<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  labelToWatch: string;
  questionDescription: string;
  formLabel: string;
};

export const useEnqueteDescriptionForm = ({
  formState,
  trigger,
  getValues,
  setValue,
  labelToWatch,
  questionDescription,
  formLabel,
}: EnqueteDescriptionFormValues) => {
  const DEBOUNCE_TIME = 500;
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formLabel === 'questionDescriptionJpn') {
        dispatch(setQuestionDescriptionJpn(getValues('description')));
        dispatch(setIsQuestionDescriptionJpnValid(formState.isValid));
      } else {
        dispatch(setQuestionDescriptionEng(getValues('description')));
        dispatch(setIsQuestionDescriptionEngValid(formState.isValid));
      }
    }, DEBOUNCE_TIME);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, formState.isValid, labelToWatch, getValues]); // eslint-disable-line

  useEffect(() => {
    setValue('description', questionDescription);
    trigger('description');
  }, [setValue, questionDescription]); // eslint-disable-line
};
