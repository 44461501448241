import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppSelector } from 'src/redux/store';
import { useInputSubmitHandler } from '../hooks/useInputSubmitHandler';

export interface ChildProps {
  title?: string;
  message: string;
  discardLabel: string;
  continueLabel: string;
  open: boolean;
  // 破棄ボタンが押された時のコールバック
  onDiscard: () => void;
  // 続行ボタンが押された時のコールバック
  onContinue?: () => void;
}

export default function ConfirmationContinueDialog(props: ChildProps) {
  const { title, message, discardLabel, continueLabel, onDiscard, onContinue } = props;
  const parentOpen: boolean = props.open;
  const { hasEdited } = useAppSelector((state) => state.enqueteAnswer);
  const [open, setOpen] = useState<boolean>(true);
  const { submitHandler } = useInputSubmitHandler();

  useEffect(() => {
    setOpen(parentOpen);
  }, [parentOpen]);

  const handleCloseAllDialog = () => {
    setOpen(false);
    onDiscard();
  };

  const handleCloseThisDialog = () => {
    setOpen(false);
    if (onContinue !== undefined) {
      onContinue();
    }
  };
  const handleSave = () => {
    const sbumitResult = submitHandler();
    if (sbumitResult) {
      handleCloseThisDialog();
    } else {
      handleCloseAllDialog();
    }
  };

  return (
    <div>
      <Dialog
        open={hasEdited && parentOpen && open}
        onClose={handleCloseThisDialog}
        aria-labelledby="continue-dialog-title"
        aria-describedby="continue-dialog-description">
        {title && <DialogTitle id="continue-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="continue-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="outlined" onClick={handleCloseAllDialog}>
            {discardLabel}
          </Button>
          <Button variant="contained" onClick={handleSave} autoFocus>
            {continueLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
