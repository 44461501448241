import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type RHFNumericFieldProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> =
  UseControllerProps<TFieldValues, TName> & {
    textFieldProps?: TextFieldProps;
    handleOnChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };

/**
 * Input of react-number-format
 */
const NumberFormatCustom = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => {
  // 不要なプロパティを除くためdestructureする
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultValue, onChange, ...others } = props;

  return (
    <NumericFormat
      {...others}
      getInputRef={ref}
      thousandSeparator={true}
      defaultValue={defaultValue as number}
      onValueChange={(values, sourceInfo) => {
        if (!onChange || !sourceInfo.event) return;
        const event = {
          ...sourceInfo.event,
          target: {
            ...sourceInfo.event.target,
            name: props.name,
            value: values.value ?? null,
          },
        };
        onChange(event);
      }}
    />
  );
});

/**
 * MUI TextField component linked to React Hook Form and react-number-format.
 * Type arguments are optional, but specifying them provides powerful type checking and type inference.
 * @typeParam TFieldValues - Type of the form.
 * @typeParam TName - Field name.
 * @example
 * ```
 * type FormData = {
 *   assets: number;
 * };
 *
 * <RHFNumericField<FormData, 'assets'>
 *   name='assets'
 *   control={control}
 *   rules={{
 *     required: 'Enter your assets.',
 *     max: {
 *       value: 1000000,
 *       message: 'Enter $1,000,000 or less.'
 *     }
 *   }}
 *   textFieldProps={{
 *     label: 'Assets',
 *     fullWidth: true
 *   }}
 * />
 * ```
 */
const RHFNumericField: <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  props: RHFNumericFieldProps<TFieldValues, TName>,
) => JSX.Element = (props) => {
  const { textFieldProps, defaultValue, handleOnChange, ...others } = props;

  return (
    <Controller
      defaultValue={defaultValue !== null ? defaultValue : undefined}
      name={others.name}
      control={others.control}
      rules={others.rules}
      render={({ field, fieldState }) => (
        <TextField
          {...textFieldProps}
          name={field.name}
          value={field.value}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(e) => {
            field.onChange(e);
            handleOnChange && handleOnChange(e);
          }}
          InputLabelProps={{ shrink: field.value || field.value == 0 ? true : false }}
          onBlur={field.onBlur}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              ...field,
              style: { textAlign: 'right' },
            },
          }}
        />
      )}
    />
  );
};

export default RHFNumericField;
