import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardSwitcher from 'src/components/dashboard';
import AuthGuard from 'src/guards/AuthGuard';
import { loginSlice } from 'src/redux/slices/loginSlice';
import { dispatch, useAppSelector } from 'src/redux/store';
import { StyledLoading } from '../app-components/StyledLoading';
import { loginPathNameLocalStrageKey } from '../authentication/login/LoginForm';

export default function AuthGuardLayout() {
  const isLoading = useAppSelector((state) => state.login.isLoading);

  if (localStorage.getItem(loginPathNameLocalStrageKey)) {
    dispatch(loginSlice.actions.setLoadingState(true));
  }

  const location = useLocation();
  useEffect(() => {
    const loginPath: string | null = localStorage.getItem(loginPathNameLocalStrageKey);
    if (loginPath && loginPath === window.location.pathname + window.location.search) {
      localStorage.removeItem(loginPathNameLocalStrageKey);
      dispatch(loginSlice.actions.setLoadingState(false));
    }
  }, [location]);

  return (
    <AuthGuard>
      <DashboardSwitcher />

      {/** Excel処理ローディング */}
      <StyledLoading isOpen={isLoading} />
    </AuthGuard>
  );
}
