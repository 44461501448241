import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdditionalQuestion,
  Amount,
  OtherFixedTopic,
  OverviewEnquete,
  PurchaseFixedQuestions,
  SalesFixedQuestions,
} from 'src/@types/overviewEnquete';
import { AmountTransaction } from 'src/api/useCustomerApi';

// 取引先概況表回答データ
export const enqueteOverviewAnswerSlice = createSlice({
  name: 'enqueteOverviewAnswerSlice',
  initialState: {
    purchaseFixedAmount: {} as Amount, // 買い取引金額項目
    purchaseFixedQuestions: {} as PurchaseFixedQuestions, // 初期セットの買い取引項目
    salesFixedAmount: {} as Amount, // 売り取引項目金額項目
    salesFixedQuestions: {} as SalesFixedQuestions, // 初期セットの売り取引項目
    otherFixedTopic: {} as OtherFixedTopic, // その他トピック項目
    purchaseAdditionalQuestions: [] as AdditionalQuestion[], // 買い取引の追加項目
    salesAdditionalQuestions: [] as AdditionalQuestion[], // 売り取引の追加項目
    otherAdditionalQuestions: [] as AdditionalQuestion[], // その他質問の追加項目
    hasError: false as boolean,
    notConvertPurchaseAmount: [] as AmountTransaction[], // 円換算できない買い取引金額一覧
    notConvertSalesAmount: [] as AmountTransaction[], // 円換算できない売り取引金額一覧
  },
  reducers: {
    // Questionsを設定する（初期設定用）
    setInitOverviewQuestions: (state, action: PayloadAction<OverviewEnquete>) => {
      state.purchaseFixedAmount = action.payload.purchaseFixedAmount;
      state.purchaseFixedQuestions = action.payload.purchaseFixedQuestions;
      state.salesFixedAmount = action.payload.salesFixedAmount;
      state.salesFixedQuestions = action.payload.salesFixedQuestions;
      state.otherFixedTopic = action.payload.otherFixedTopic;
      state.purchaseAdditionalQuestions = action.payload.purchaseAdditionalQuestions;
      state.salesAdditionalQuestions = action.payload.salesAdditionalQuestions;
      state.otherAdditionalQuestions = action.payload.otherAdditionalQuestions;
    },

    setPurchaseFixedAmount: (state, action) => {
      state.purchaseFixedAmount.answer = action.payload;
    },

    setPurchaseFixedProduct: (state, action) => {
      state.purchaseFixedQuestions.product.answer = action.payload;
    },

    setPurchaseFixedMainCustomer: (state, action) => {
      state.purchaseFixedQuestions.mainCustomer.answer = action.payload;
    },

    setPurchaseFixedCommercialFlow: (state, action) => {
      state.purchaseFixedQuestions.commercialFlow.answer = action.payload;
    },

    setSalesFixedAmount: (state, action) => {
      state.salesFixedAmount.answer = action.payload;
    },

    setSalesFixedProduct: (state, action) => {
      state.salesFixedQuestions.product.answer = action.payload;
    },

    setSalesFixedCommercialFlow: (state, action) => {
      state.salesFixedQuestions.commercialFlow.answer = action.payload;
    },

    setOtherFixedTopic: (state, action) => {
      state.otherFixedTopic.answer = action.payload;
    },

    setPurchaseAdditionalQuestions: (state, action) => {
      const { value, index } = action.payload;
      let newAdditionalQuestions = state.purchaseAdditionalQuestions.concat();
      newAdditionalQuestions[index].answer = value;
      state.purchaseAdditionalQuestions = newAdditionalQuestions;
    },

    setSalesAdditionalQuestions: (state, action) => {
      const { value, index } = action.payload;
      let newAdditionalQuestions = state.salesAdditionalQuestions.concat();
      newAdditionalQuestions[index].answer = value;
      state.salesAdditionalQuestions = newAdditionalQuestions;
    },

    setOtherAdditionalQuestions: (state, action) => {
      const { value, index } = action.payload;
      let newAdditionalQuestions = state.otherAdditionalQuestions.concat();
      newAdditionalQuestions[index].answer = value;
      state.otherAdditionalQuestions = newAdditionalQuestions;
    },

    // アンケート回答入力時の状態を保持する
    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },

    setNotConvertPurchaseAmount: (state, action: PayloadAction<AmountTransaction[]>) => {
      state.notConvertPurchaseAmount = action.payload;
    },
    setNotConvertSalesAmount: (state, action: PayloadAction<AmountTransaction[]>) => {
      state.notConvertSalesAmount = action.payload;
    },
  },
});
export const {
  setInitOverviewQuestions,
  setPurchaseFixedAmount,
  setPurchaseFixedProduct,
  setPurchaseFixedMainCustomer,
  setPurchaseFixedCommercialFlow,
  setSalesFixedAmount,
  setSalesFixedProduct,
  setSalesFixedCommercialFlow,
  setOtherFixedTopic,
  setPurchaseAdditionalQuestions,
  setSalesAdditionalQuestions,
  setOtherAdditionalQuestions,
  setHasError,
  setNotConvertPurchaseAmount,
  setNotConvertSalesAmount,
} = enqueteOverviewAnswerSlice.actions;

export default enqueteOverviewAnswerSlice.reducer;
