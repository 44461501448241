import Dexie, { Table } from 'dexie';
import { LocalStoreProps } from '../components/pages/response-request-mail/hooks/useRequestMailForm';

export class MySubClassedDexie extends Dexie {
  // テーブルの型を定義
  mailDetail!: Table<LocalStoreProps>;

  constructor() {
    // IndexedDBでの名前を設定
    super('userLocalStore');

    // DBの初期化
    this.version(1).stores({
      mailDetail: 'respondetRequestId',
    });
  }
}

export const db = new MySubClassedDexie();
