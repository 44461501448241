import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchComments } from '../../../../store/enqueteCommentsActions';

export const useComments = (id: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchComments(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
