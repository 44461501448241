// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_OVERVIEW = '/top';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_GENERAL = '/top';
const ROOTS_HISTORY = '/history';
const ROOTS_MASTER = '/master';

// ----------------------------------------------------------------------
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: path(ROOTS_DASHBOARD, '/account'),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_GENERAL = {
  root: ROOTS_GENERAL,
  order: path(ROOTS_GENERAL, '/order'),
  shipping: path(ROOTS_GENERAL, '/shipping'),
  deliver: path(ROOTS_GENERAL, '/deliver'),
};

export const PATH_HISTORY = {
  root: ROOTS_HISTORY,
  order: path(ROOTS_HISTORY, '/order'),
  shipping: path(ROOTS_HISTORY, '/shipping'),
};

export const PATH_MASTER = {
  root: ROOTS_MASTER,
  item: path(ROOTS_MASTER, '/item'),
  ship: path(ROOTS_MASTER, '/ship'),
  mail: path(ROOTS_MASTER, '/mail'),
};

export const PATH_OVERVIEW = {
  root: ROOTS_OVERVIEW,
  overview: path(ROOTS_OVERVIEW, ''),
};
