import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { Box, Button } from '@mui/material';

interface PropsEntity {
  isOpen: boolean;
  agreeCallback: () => void;
  disagreeCallback: () => void;
}

export const DeleteConfirmMainTable = ({ isOpen, agreeCallback, disagreeCallback }: PropsEntity) => {
  const { t } = useTranslation();

  return (
    <StyledModal isOpen={isOpen} onCloseFunc={disagreeCallback} modalTitle={t('enqueteCreate.table.dialog.deleteMainTable.title')}>
      <Box
        sx={{
          position: 'relative',
          width: '60vw',
          height: 'auto',
          whiteSpace: 'pre-line',
          lineHeight: 'normal',
          alignItems: 'left',
          justifyContent: 'left',
        }}>
        {t('enqueteCreate.table.dialog.deleteMainTable.content')}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <Button color="error" variant="contained" size="large" onClick={disagreeCallback}>
          {t('button.cancel')}
        </Button>
        <Button color="primary" variant="contained" size="large" onClick={agreeCallback}>
          {t('button.ok')}
        </Button>
      </Box>
    </StyledModal>
  );
};
