import React from 'react';
import { Box, Fade, SxProps } from '@mui/material';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface StyledModalProps {
  isOpen: boolean;
}

export const StyledLoading: React.FC<StyledModalProps> = (props) => {
  //ラッピング要素スタイル
  const wrappingStyle: SxProps = {
    position: 'fixed',
    top: '-2vh',
    left: '0',
    bgcolor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
    transition: 'opacity 0.5s',
    backdropFilter: 'blur(5px)',
  };

  //ローディング位置指定要素スタイル
  const style: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Fade in={props.isOpen}>
      <Box className="wrapperBox" sx={wrappingStyle}>
        <Box sx={style}>
          <FontAwesomeIcon icon={faSpinner} spin={true} size={'6x'} />
        </Box>
      </Box>
    </Fade>
  );
};
