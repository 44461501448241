import useSettings from './useSettings';

const useTheme = () => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const className = isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark';

  return {
    className,
  };
};
export default useTheme;
