import { HotTable } from '@handsontable/react';
import { Divider, Grid, Typography } from '@mui/material';
import 'handsontable/dist/handsontable.full.min.css';
import { HyperFormula } from 'hyperformula';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EnqueteSubTableForm from 'src/components/app-components/bonsai/type-general-table/components/EnqueteSubTableForm';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import Label from 'src/components/Label';
import { hyperformulaLicenseKey } from 'src/config';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { RootState, useAppSelector } from 'src/redux/store';
import { usePreviewTableData } from '../hooks/usePreviewTableData';
import { MainTablesTabContainer } from './MainTablesTabContainer';
import { HelpTooltip } from 'src/components/app-components/bonsai/HelpTooltip';
import { useSurveyTables } from 'src/hooks/useSurveyTables';

const hyperformulaInstance = HyperFormula.buildEmpty({
  licenseKey: hyperformulaLicenseKey,
});
// define TRUE and FALSE constants
hyperformulaInstance.addNamedExpression('TRUE', '=TRUE()');
hyperformulaInstance.addNamedExpression('FALSE', '=FALSE()');

const EnqueteCreatePreviewTableForm: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { isMultiAnswerTableSurvey } = useSurveyTables();

  // ストア
  const subDatas = useAppSelector((state: RootState) => state.tableSetting.subDatas);

  const previewHotRef = useMemo(() => {
    return new Array(TABLE_SETTINGS.MAX_TOTAL_TABLE_LENGTH).fill(null).map(() => React.createRef<HotTable>());
  }, []);
  const previewHotRefForSubSheet = useMemo(() => {
    return new Array(TABLE_SETTINGS.SUB.MAX_LENGTH).fill(null).map(() => React.createRef<HotTable>());
  }, []);

  // Hooks
  usePreviewTableData(previewHotRef, previewHotRefForSubSheet, hyperformulaInstance);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 1 }}>
        <Label
          role="message"
          variant="ghost"
          sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
          {t('enqueteCreate.table.preview.explanation')}
        </Label>
        <Divider />
        <Typography variant="h5">{t('enqueteAnswerInput.table.answer.title')}</Typography>
        <Label
          role="message"
          variant="ghost"
          sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
          {t('enqueteAnswerInput.table.answer.explanation')}
          {/*複数回答表のみ表示*/}
          {isMultiAnswerTableSurvey && (
            <>
              {t('enqueteGeneralCommon.explanation.referenceAnswerTable')}
              <HelpTooltip contents={t('enqueteGeneralCommon.tooltip.referenceAnswerTableValue')} />
            </>
          )}
        </Label>
      </Grid>
      <Grid item xs={12}>
        <MainTablesTabContainer
          hotRef={previewHotRef}
          hotRefForSubSheet={previewHotRefForSubSheet}
          hyperformulaInstance={hyperformulaInstance}
          isPreview={true}
        />
      </Grid>
      {subDatas.length > 0 ? (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 1 }}>
          <Typography variant="h5">{t('enqueteAnswerInput.table.sub.title')}</Typography>
          <Label
            role="message"
            variant="ghost"
            sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
            {t('enqueteAnswerInput.table.sub.explanation')}
            <HelpTooltip contents={t('enqueteGeneralCommon.tooltip.referenceRefTableValue')} />
          </Label>
        </Grid>
      ) : null}
      <EnqueteSubTableForm
        hotRef={previewHotRef}
        hotRefForSubSheet={previewHotRefForSubSheet}
        hyperformulaInstance={hyperformulaInstance}
        isPreview={true}
        isVisibleContextMenu={false}
      />
    </Grid>
  );
});

export default EnqueteCreatePreviewTableForm;
