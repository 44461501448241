import { EnqueteThreadCreatePage } from '../pages/EnqueteThreadCreatePage';
import { EnqueteThreadEditPage } from '../pages/EnqueteThreadEditPage';
import { EnqueteThreadListPage } from '../pages/EnqueteThreadListPage';
import { EnqueteThreadPage } from '../pages/EnqueteThreadPage';

//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteThreadsRoute = {
  path: 'enquete-threads',
  children: [
    {
      path: '',
      element: <EnqueteThreadListPage />,
    },
    {
      path: 'detail/:id',
      element: <EnqueteThreadPage />,
    },
    {
      path: 'create',
      element: <EnqueteThreadCreatePage />,
    },
    {
      path: 'edit/:id',
      element: <EnqueteThreadEditPage />,
    },
  ],
};
