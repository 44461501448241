import React, { RefObject } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { HotTable } from '@handsontable/react';
import { HyperFormula } from 'hyperformula';
import { RootState, useAppSelector } from 'src/redux/store';
import EnqueteMainTableForm from 'src/components/app-components/bonsai/type-general-table/components/EnqueteMainTableForm';
import Label from 'src/components/Label';
import { useTranslation } from 'react-i18next';
import { TABLE_SETTINGS } from 'src/generalConstants';
import useLocales from 'src/hooks/useLocales';
import { useNewAnswerTableData } from '../hooks/useNewAnswerTableData';
import { useTableAnswerSave } from 'src/features/general/enquete-answer/hooks/useTableAnswerSave';
import { useTableTabs } from 'src/hooks/useTableTabs';
import { useSurveyTables } from 'src/hooks/useSurveyTables';
import { MainTableSheetNamesType } from 'src/features/general/enquete-create/store/tableSettingSlice';

interface PropsEntity {
  answerHotRef: RefObject<HotTable>[];
  answerHotRefForSubSheet: RefObject<HotTable>[];
  hyperformulaInstance: HyperFormula;
}

export const AnswerMainTablesTabContainer: React.FC<PropsEntity> = (props) => {
  const { answerHotRef, answerHotRefForSubSheet, hyperformulaInstance } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const { saveAllSettings } = useTableAnswerSave(answerHotRef, answerHotRefForSubSheet);
  const { tabNumber, handleTabClick } = useTableTabs();
  const { isMultiAnswerTableSurvey } = useSurveyTables();

  // ストア
  const { totalMainTables, mainTableSheetNames } = useAppSelector((state: RootState) => state.tableSetting);

  // メイン表データ読み込み
  useNewAnswerTableData(answerHotRef);

  return (
    <>
      {isMultiAnswerTableSurvey && (
        <Tabs
          value={mainTableSheetNames.length > 0 ? tabNumber : false} // mainTableSheetNamesが初回レンダリング時空配列によるエラー対応
          onChange={async (e, tabNumber) => {
            // グローバルステートを保存
            saveAllSettings();
            handleTabClick(e, tabNumber);
          }}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="main-table-tab">
          {mainTableSheetNames.map((sheetNames: MainTableSheetNamesType, index: number) => (
            <Tab label={isJapanese ? sheetNames.sheetNameJpn : sheetNames.sheetNameEng} value={index + 1} key={index} />
          ))}
        </Tabs>
      )}

      {totalMainTables.map((_, index) => (
        <Box key={index} sx={{ display: tabNumber === index + 1 ? 'block' : 'none', p: 2 }}>
          {isMultiAnswerTableSurvey && (
            <Label
              sx={{
                px: 1,
                py: 2,
                mb: 2,
              }}>
              {t('enqueteGeneralCommon.answerTableSheetReferenceWord', { tableNumber: index + 1 })}
            </Label>
          )}
          <EnqueteMainTableForm
            hotRef={answerHotRef[index]}
            hyperformulaInstance={hyperformulaInstance}
            sheetName={TABLE_SETTINGS.MAIN_TABLE_REFERENCE + String(index + 1)}
            isPreview={true}
            isVisibleContextMenu={false}
          />
        </Box>
      ))}
    </>
  );
};
