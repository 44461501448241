import { useEffect, useState } from 'react';
import { useAppSelector, useDispatch } from 'src/redux/store';
import { clearComments } from '../../../../store/enqueteCommentsSlice';
import { fetchThreadlist } from '../../../../store/enqueteThreadActions';
import { clearThreadUsers } from '../../../../store/enqueteThreadCreateSlice';
import { clearThread } from '../../../../store/enqueteThreadSlice';

export const useThreads = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const take = 10; // memo:1ページ当たりのスレッド表示件数

  const totalCount = useAppSelector((state) => state.enqueteThreads.threads.total);
  const count = Math.ceil(totalCount / take);

  useEffect(() => {
    dispatch(fetchThreadlist({ page, take }));
    dispatch(clearThreadUsers());
    dispatch(clearThread());
    dispatch(clearComments());
  }, [page, take, dispatch]);

  return { page, count, setPage };
};
