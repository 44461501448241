import { HotTable } from '@handsontable/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Grid, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ANSWER_STATUS, FORM_TYPE, FormType } from 'src/constants';
import { useEnqueteAnswerGrid } from 'src/features/general/enquete-answer/hooks/useEnqueteAnswerGrid';
import {
  EnqueteAnswerLoadInputEntity,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useAuth from 'src/hooks/useAuth';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/store';
import { EnqueteAnswerGeneralQuestionSaveButtons } from './EnqueteAnswerGeneralQuestionSaveButtons';
import EnqueteAnswerGeneralTableSaveButton from './EnqueteAnswerGeneralTableSaveButton';
import { EnqueteAnswerHeaderExcelButtons } from './EnqueteAnswerHeaderExcelButtons';
import { EnqueteAnswerHeaderInfo } from './EnqueteAnswerHeaderInfo';
import { EnqueteAnswerOverviewSaveButton } from './EnqueteAnswerOverviewSaveButton';
import { EnqueteAnswerSaveButtons } from './EnqueteAnswerSaveButtons';
import { SeriesSurveySaveButtons } from './SeriesSurveySaveButtons';
interface Props {
  formType: FormType | undefined;
  answerHotRef?: React.RefObject<HotTable>[];
  answerHotRefForSubSheet?: React.RefObject<HotTable>[];
  personnelEmploymentRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  personnelEmploymentResultAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  personnelEmploymentPlanAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  personnelCorporateRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  personnelCorporateOtherFormTextValue?: string | undefined;
  personnelSalesRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）1.正社員の行データ
  personnelPriorityDomainRegularEmployeeRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
  personnelPriorityDomainSecondmentFromTTCRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）3.2以外の出向受入の行データ
  personnelPriorityDomainSecondmentFromOtherRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
  personnelPriorityDomainDispatchEtcRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）年計 - 実見のデータ
  personnelPriorityDomainResultAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 人件費人員（重点分野別）中計 - 年計のデータ
  personnelPriorityDomainPlanAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  manufyearPlanurEmploymentRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  manufyearPlanurEmploymentResultAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  manufyearPlanurEmploymentPlanAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  // 製造原価人員（重点分野別）1.正社員の行データ
  manufyearPlanurPriorityDomainRegularEmployeeRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
  manufyearPlanurPriorityDomainOtherEmployeesRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 製造原価人員（重点分野別）年計 - 実見のデータ
  manufyearPlanurPriorityDomainResultAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 製造原価人員（重点分野別）中計 - 年計のデータ
  manufyearPlanurPriorityDomainPlanAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
}

export const EnqueteAnswerHeaderBox: React.FC<Props> = (props) => {
  const respondentTargetId = useParams().id;
  const confirmRequestId: string | null = new URLSearchParams(useLocation().search).get('confirmRequestId');
  const {
    formType,
    answerHotRef,
    answerHotRefForSubSheet,
    personnelEmploymentRowData,
    personnelEmploymentResultAndPlanDiffForm,
    personnelEmploymentPlanAndPlanDiffForm,
    personnelCorporateRowData,
    personnelCorporateOtherFormTextValue,
    personnelSalesRowData,
    // 人件費人員（重点分野別）1.正社員の行データ
    personnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
    personnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別）3.2以外の出向受入の行データ
    personnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
    personnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別）年計 - 実見のデータ
    personnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別）中計 - 年計のデータ
    personnelPriorityDomainPlanAndPlanDiffForm,
    manufyearPlanurEmploymentRowData,
    manufyearPlanurEmploymentResultAndPlanDiffForm,
    manufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別）1.正社員の行データ
    manufyearPlanurPriorityDomainRegularEmployeeRowData,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
    manufyearPlanurPriorityDomainOtherEmployeesRowData,
    // 製造原価人員（重点分野別）年計 - 実見のデータ
    manufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員（重点分野別）中計 - 年計のデータ
    manufyearPlanurPriorityDomainPlanAndPlanDiffForm,
  } = props;
  const { t } = useTranslation();

  const { user } = useAuth();
  const userEmail = null !== user ? user.email : '';

  const { isAdmin, isViewerAdmin } = usePermission();
  const { setConfirmationCommentsModalMode } = useEnqueteAnswerGrid();

  const params = useLocation().search;
  const confirmRequestIdParamsValue: string | null = new URLSearchParams(params).get('confirmRequestId');

  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  const isDisabled = formType === FORM_TYPE.SERIES && enqueteAnswerData.status === ANSWER_STATUS.ANSWERED;

  const openConfirmationCommentsModal = () => {
    setConfirmationCommentsModalMode(true);
  };

  const switchSaveButton = (formType: FormType | undefined) => {
    switch (formType) {
      case FORM_TYPE.QA:
        return <EnqueteAnswerGeneralQuestionSaveButtons respondentTargetId={respondentTargetId ?? ''} confirmRequestId={confirmRequestId ?? ''} />;
      case FORM_TYPE.TABLE:
        return (
          <EnqueteAnswerGeneralTableSaveButton
            respondentTargetId={respondentTargetId ?? ''}
            confirmRequestId={confirmRequestId ?? ''}
            answerHotRef={answerHotRef ?? []}
            answerHotRefForSubSheet={answerHotRefForSubSheet ?? []}
          />
        );
      case FORM_TYPE.SPECIAL:
        return (
          <Box display="flex" justifyContent="flex-end">
            <EnqueteAnswerSaveButtons
              respondentTargetId={respondentTargetId}
              comifrmRequestId={confirmRequestId ?? ''}
              enqueteId={!isEmpty(enqueteAnswerData) ? enqueteAnswerData.id : undefined}
              personnelEmploymentRowData={personnelEmploymentRowData}
              personnelEmploymentResultAndPlanDiffForm={personnelEmploymentResultAndPlanDiffForm}
              personnelEmploymentPlanAndPlanDiffForm={personnelEmploymentPlanAndPlanDiffForm}
              personnelCorporateRowData={personnelCorporateRowData}
              personnelCorporateOtherFormTextValue={personnelCorporateOtherFormTextValue}
              personnelSalesRowData={personnelSalesRowData}
              // 人件費人員（重点分野別）1.正社員の行データ
              personnelPriorityDomainRegularEmployeeRowData={personnelPriorityDomainRegularEmployeeRowData}
              // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
              personnelPriorityDomainSecondmentFromTTCRowData={personnelPriorityDomainSecondmentFromTTCRowData}
              // 人件費人員（重点分野別）3.2以外の出向受入の行データ
              personnelPriorityDomainSecondmentFromOtherRowData={personnelPriorityDomainSecondmentFromOtherRowData}
              // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
              personnelPriorityDomainDispatchEtcRowData={personnelPriorityDomainDispatchEtcRowData}
              // 人件費人員（重点分野別）年計 - 実見のデータ
              personnelPriorityDomainResultAndPlanDiffForm={personnelPriorityDomainResultAndPlanDiffForm}
              // 人件費人員（重点分野別）中計 - 年計のデータ
              personnelPriorityDomainPlanAndPlanDiffForm={personnelPriorityDomainPlanAndPlanDiffForm}
              manufyearPlanurEmploymentStatusRowData={manufyearPlanurEmploymentRowData}
              manufyearPlanurEmploymentResultAndPlanDiffForm={manufyearPlanurEmploymentResultAndPlanDiffForm}
              manufyearPlanurEmploymentPlanAndPlanDiffForm={manufyearPlanurEmploymentPlanAndPlanDiffForm}
              // 製造原価人員（重点分野別）1.正社員の行データ
              manufyearPlanurPriorityDomainRegularEmployeeRowData={manufyearPlanurPriorityDomainRegularEmployeeRowData}
              // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
              manufyearPlanurPriorityDomainOtherEmployeesRowData={manufyearPlanurPriorityDomainOtherEmployeesRowData}
              // 製造原価人員（重点分野別）年計 - 実見のデータ
              manufyearPlanurPriorityDomainResultAndPlanDiffForm={manufyearPlanurPriorityDomainResultAndPlanDiffForm}
              // 製造原価人員（重点分野別）中計 - 年計のデータ
              manufyearPlanurPriorityDomainPlanAndPlanDiffForm={manufyearPlanurPriorityDomainPlanAndPlanDiffForm}
            />
          </Box>
        );
      case FORM_TYPE.SERIES:
        return (
          <Box display="flex" justifyContent="flex-end">
            <SeriesSurveySaveButtons respondentTargetId={respondentTargetId ?? ''} confirmRequestId={confirmRequestId ?? ''} />
          </Box>
        );
      case FORM_TYPE.OVERVIEW:
        return (
          <Box display="flex" justifyContent="flex-end">
            <EnqueteAnswerOverviewSaveButton respondentTargetId={respondentTargetId ?? ''} confirmRequestId={confirmRequestId ?? ''} />
          </Box>
        );
      default:
        return '';
    }
  };

  return (
    <Box component={'div'} sx={{ mb: 1 }}>
      <Grid container>
        <Grid item xs={12} md={7}>
          <EnqueteAnswerHeaderInfo />
        </Grid>
        <Grid container item xs={12} md={5} justifyContent="flex-end">
          <Stack>
            <Box display="flex" justifyContent="flex-end">
              {confirmRequestIdParamsValue &&
                confirmRequestData.status === 'REQUESTED' &&
                (confirmRequestData.confirmerEmail === userEmail || isAdmin || isViewerAdmin) && (
                  <Button size="small" variant="contained" startIcon={<OpenInNewIcon />} onClick={openConfirmationCommentsModal}>
                    {t('enqueteAnswerInput.confirmRequestStatus.markAsConfirm')}
                  </Button>
                )}
              {confirmRequestIdParamsValue &&
                confirmRequestData.status === 'CONFIRMED' &&
                (confirmRequestData.confirmerEmail === userEmail || isAdmin || isViewerAdmin) && (
                  <Button size="small" variant="contained" startIcon={<CheckCircleIcon />} onClick={openConfirmationCommentsModal} color="inherit">
                    {t('enqueteAnswerInput.confirmRequestStatus.confirmed')}
                  </Button>
                )}
            </Box>
            {switchSaveButton(formType)}
            {(formType === FORM_TYPE.SPECIAL || formType === FORM_TYPE.SERIES) && (
              <EnqueteAnswerHeaderExcelButtons respondentTargetId={respondentTargetId ?? null} isDisabled={isDisabled} />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
