import { SelectChangeEvent } from '@material-ui/core';
import { debounce } from 'lodash';
import { ChangeEvent, SyntheticEvent, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AnswerItem, Question, QuestionItem } from 'src/@types/generalEnquete';
import { setGeneralQuestions } from 'src/features/general/enquete-answer/store/enqueteGeneralAnswerSlice';
import { dispatch } from 'src/redux/store';
import { v1 } from 'uuid';

export const useEnqueteAnswerHandleOnChange = () => {
  // handleOnChangeText related description area
  const handleOnChangeText = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, question: Question, index: number) => {
    debouncehandleChange(event, question, index);
  };

  const debouncehandleChange = useMemo(
    () =>
      debounce((event, question: Question, index: number) => {
        // アンケート期限情報を格納
        const id: string = question.answerItem?.length === 0 ? v1() : (question.answerItem as AnswerItem[])[0].id;

        const answerItem: AnswerItem = {
          id: id,
          label: question.questionItem[0].label,
          other: undefined,
          questionItemId: question.questionItem[0].id,
          value: event.target.value,
        };
        let newQuestion = { ...question };
        newQuestion.answerItem = [answerItem];
        dispatch(setGeneralQuestions({ index, value: newQuestion }));
      }, 1000),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // handleOnChangeCheckBox related description area
  const handleOnChangeCheckBox = (event: SyntheticEvent, questionItemId: string, index: number, question: Question) => {
    let newQuestion = { ...question };
    if (!newQuestion.answerItem || newQuestion.answerItem.length === 0) {
      const answerItems: AnswerItem[] = [];
      newQuestion.questionItem.forEach((questionItem) => {
        const answerItemData: AnswerItem = {
          id: v1(),
          label: questionItem.label,
          other: undefined,
          questionItemId: questionItem.id,
          value: false,
        };
        answerItems.push(answerItemData);
      });
      newQuestion.answerItem = answerItems;
    }

    const newAnswerItem: AnswerItem[] = newQuestion.answerItem as AnswerItem[];
    newQuestion.answerItem = [];
    const answerItems: AnswerItem[] = [];
    newAnswerItem.forEach((answerItem) => {
      if (questionItemId === answerItem.questionItemId) {
        const answerItemData: AnswerItem = {
          id: answerItem.id,
          label: answerItem.label,
          other: answerItem.other,
          questionItemId: answerItem.questionItemId,
          value: (event.target as HTMLInputElement).checked,
        };
        answerItems.push(answerItemData);
      } else {
        answerItems.push(answerItem);
      }
    });
    newQuestion.answerItem = answerItems;
    dispatch(setGeneralQuestions({ index, value: newQuestion }));
  };

  // Get default value (element specific)
  const getDefaultValue = (answerItems: AnswerItem[], questionItems: QuestionItem[]): string | undefined => {
    let defaultValue: string = '';
    let index = 0;
    answerItems.forEach((answerItem) => {
      if (answerItem.value === true) {
        defaultValue = questionItems[index].id;
        return defaultValue;
      }
      index++;
    });
    return defaultValue;
  };

  // Get default value (dropdown, radio button)
  const dropdownAndRadioDefaultValue = (index: number, question: Question, methods?: UseFormReturn): string | undefined => {
    if (methods && (question.answerItem as AnswerItem[]) && (question.answerItem as AnswerItem[]).length !== 0) {
      return getDefaultValue(question.answerItem as AnswerItem[], question.questionItem as QuestionItem[]);
    } else {
      return '';
    }
  };

  // HandleOnChange processing (dropdown, radio button)
  const handleOnChangeDropdownAndRadio = (event: SelectChangeEvent, index: number, question: Question) => {
    let newQuestion = { ...question };
    if (!newQuestion.answerItem || newQuestion.answerItem.length === 0) {
      const answerItems: AnswerItem[] = [];
      newQuestion.questionItem.forEach((questionItem) => {
        const answerItemData: AnswerItem = {
          id: v1(),
          label: questionItem.label,
          other: undefined,
          questionItemId: questionItem.id,
          value: false,
        };
        answerItems.push(answerItemData);
      });
      newQuestion.answerItem = answerItems;
    }

    let newAnswerItem: AnswerItem[] = newQuestion.answerItem as AnswerItem[];
    newQuestion.answerItem = [];
    const answerItems: AnswerItem[] = [];
    newAnswerItem.forEach((answerItem) => {
      const answerItemData: AnswerItem = {
        id: answerItem.id,
        label: answerItem.label,
        other: answerItem.other,
        questionItemId: answerItem.questionItemId,
        value: answerItem.questionItemId === event.target.value ? true : false,
      };
      answerItems.push(answerItemData);
    });
    newQuestion.answerItem = answerItems;
    dispatch(setGeneralQuestions({ index, value: newQuestion }));
  };
  return {
    handleOnChangeText,
    handleOnChangeCheckBox,
    handleOnChangeDropdownAndRadio,
    dropdownAndRadioDefaultValue,
  };
};
