import { Alert, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateSurveyOwnerData, useSurveyOwnerApi } from 'src/api/useSurveyOwnerApi';
import { UserEntity, useUserApi } from 'src/api/useUserApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { SelectUserAgGrid } from 'src/components/app-components/bonsai/SelectUserAgGrid';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { SelectOwnerModal } from 'src/features/general/enquete-create/components/modals/select-owner/components/SelectOwnerModal';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';

interface Props {
  respondentRequestId: string | undefined;
  surveyDetailId: string;
}

export const SurveyOwnerTab: React.FC<Props> = (props) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const userApi = useUserApi();
  const { enqueueSnackbar } = useSnackbar();
  const { getBySurveyDetailId, getByUnique, add, removeById } = useSurveyOwnerApi();
  const { t } = useTranslation();
  const { isViewerAdmin, isSurveyOwner } = usePermission(props.surveyDetailId);

  const [rowData, setRowData] = useState<UserEntity[]>([]);
  const [offsetHeight, setOffsetHeight] = useState<string>('100vh');
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  //ローディング
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // grid api
  const gridApi = useRef<GridApi | null>(null);

  //-------------------------------------------------------------
  // GridApiを取得するための関数
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // GridApiを取得するための関数
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    if (!props.surveyDetailId) return;
    // ローディング開始
    setIsLoading(true);

    // アンケート所有者のリストを取得
    // この時点ではemailしかユーザー情報がわかっていないため、後で詳細をポータルから取得する
    const owners = await getBySurveyDetailId(props.surveyDetailId);
    if (!owners) {
      // ローディング終了
      setIsLoading(false);
      return;
    }

    // 一人以上オーナーがいる場合はポータルから詳細を取得する
    if (owners.length > 0) {
      // 所有者情報からEmail情報を抽出
      // 本来emailのないユーザーは存在しないが、テーブルのフィールド設定では
      // emailがnullを許容しているためnullチェックを行う
      const emails: string[] = [];
      for (const owner of owners) {
        if (owner.userId) emails.push(owner.userId);
      }

      // ユーザーの詳細を取得
      const users = await userApi.getByEmail(emails, null);
      if (!users) {
        // ローディング終了
        setIsLoading(false);
        return;
      }

      // RowDataの更新
      setRowData(users);

      // ローディング終了
      setIsLoading(false);
    }
  };

  //-------------------------------------------------------------
  // 追加するRowDataのバリデーションを行う処理
  //-------------------------------------------------------------
  const onValidation = (values: UserEntity[]) => {
    // 追加行を１つずつ走査
    for (const value of values) {
      const jsonValue = JSON.stringify(value);
      // 既にページ用のAgGridのRowDataに存在していないか確認
      for (const row of rowData) {
        const jsonRow = JSON.stringify(row);
        if (jsonValue === jsonRow) {
          return false;
        }
      }
    }
    return true;
  };

  //-------------------------------------------------------------
  // RowDataに行データの追加を行う処理
  //-------------------------------------------------------------
  const onModalOk = async (values: UserEntity[]) => {
    // 登録用データを作成
    const data: CreateSurveyOwnerData[] = [];
    if (props.surveyDetailId) {
      for (const value of values) {
        if (value.email) {
          data.push({
            userId: value.email,
            bluePageEmailAddress: value.bluePageEmailAddress,
          });
        } else {
          return enqueueSnackbar(t('enqueteCreate.message.canNotRegistered'), { variant: 'error' });
        }
      }
    } else {
      return enqueueSnackbar(t('enqueteCreate.message.notCreated'), { variant: 'error' });
    }
    await add(props.surveyDetailId, data);
    await refreshRowData();
    setIsOpenAddModal(false);
  };

  //-------------------------------------------------------------
  // 管理者の情報を削除する処理
  //-------------------------------------------------------------
  const onRowDelete = async (params: UserEntity | undefined) => {
    if (!props.surveyDetailId) return enqueueSnackbar(t('enqueteCreate.message.notCreated'), { variant: 'error' });

    if (!params) return;
    if (!params.email) return enqueueSnackbar(t('enqueteCreate.message.canNotDeleted'), { variant: 'error' });
    if (rowData.length - 1 < 2) return enqueueSnackbar(t('enqueteCreate.message.emptyOwnerList'), { variant: 'error' });

    // SurveyOwnerのIDを取得
    const owners = await getByUnique(props.surveyDetailId, params.email);
    if (!owners) return;

    // 削除
    if (owners.length > 0) {
      await removeById(props.surveyDetailId, owners[0].id);
    }

    // 一覧のリフレッシュ
    await refreshRowData();
  };

  //-------------------------------------------------------------
  // Use Effect
  //-------------------------------------------------------------
  useEffect(() => {
    // calc data table height
    const appHeader = document.getElementsByTagName('header');
    const pageHeader = document.getElementById('page-header');
    if (pageHeader && appHeader.length > 0) {
      setOffsetHeight(`calc(100vh - ${pageHeader?.clientHeight}px - ${appHeader[0]?.clientHeight}px)`);
    }
    // rowdata refresh
    refreshRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.surveyDetailId]);

  return (
    <>
      {/* header action space*/}
      <Grid container>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'right'} spacing={1}>
            {/* 注意書き ※汎用実装時に不要になる */}
            {!isLoading && rowData.length < 2 && (
              <Grid item xs={12} md={10}>
                <Alert severity="error">{t('enqueteCreate.selectOwnerNotes')}</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={2} textAlign="right">
              <StyledButton onClick={() => setIsOpenAddModal(true)} children={t('button.add')} isDisabled={isViewerAdmin && !isSurveyOwner} />
            </Grid>
            {/* <Button variant={'outlined'} onClick={onDelete} color="error" children={"削除"} /> */}
          </Stack>
        </Grid>
      </Grid>

      {/* data table space */}
      <Grid container>
        <Grid item xs={12}>
          <Box
            component={'div'}
            sx={{ width: '100%', height: offsetHeight }}
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            data-testid={'aggrid'}>
            <LoadableComponent isLoading={isLoading} zIndex={1000}>
              <SelectUserAgGrid<UserEntity>
                surveyDetailId={props.surveyDetailId}
                rowData={rowData}
                onGridReady={onGridReady}
                onRowDelete={onRowDelete}
              />
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>

      {/* add modal */}
      <SelectOwnerModal
        surveyHeaderId={props.surveyDetailId}
        open={isOpenAddModal}
        onClose={() => setIsOpenAddModal(false)}
        onOk={onModalOk}
        onValidation={onValidation}
      />
    </>
  );
};
