import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface RankEntity {
  rank: string;
}

interface PortalRankEntity {
  rank: string;
}

const BASE_URL = `${portalApi}/master/rank`;

export const useRankApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getAll = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalRankEntity[]>(BASE_URL, {
        headers: { Authorization: token },
        params: { filter: { orderBy: [{ displayOrder: 'asc' }] } },
      });

      return result.data.map<RankEntity>((master) => ({ rank: master.rank }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRankApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getAll };
};
