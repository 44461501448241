import { Delete } from '@mui/icons-material';
import { Alert, Box, Button, Divider, FormHelperText, Grid, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserEntity } from 'src/api/useUserApi';
import { SelectOwnerModal } from 'src/features/general/enquete-create/components/modals/select-owner/components/SelectOwnerModal';
import useLocales from 'src/hooks/useLocales';
import { switchLabel } from 'src/utils/labelUtils';
import { StyledBox } from '../../../StyledBox';
import { useSurveyOwnerSelectBox } from '../hooks/useSurveyOwnerSelectBox';

type SurveyOwnerSelectBoxProps = {
  methods: UseFormReturn;
  isActive?: boolean;
};

export const SurveyOwnerSelectBox = ({ methods, isActive }: SurveyOwnerSelectBoxProps) => {
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const {
    handleOnAdd,
    handleOnCloseOnSelectOwnerModal,
    handleOnOkOnSelectOwnerModal,
    handleOnRemove,
    validateOnSelectOwnerModal,
    isModalOpenedOnce,
    isSelectOwnerModalOpen,
    fields,
  } = useSurveyOwnerSelectBox(methods, isActive);

  if (!isActive) {
    return null;
  }

  const renderOwnerList = () => {
    if (!isModalOpenedOnce && fields.length === 0) {
      return (
        <Alert severity="info" color="warning" variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="body2">{t('enqueteCreate.editEnqueteModal.warnMessage.coOwnerRequirementNotice')}</Typography>
        </Alert>
      );
    } else {
      return (
        <StyledBox>
          {fields.length > 0 &&
            (fields as UserEntity[]).map((field, index) => (
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" margin={1} key={index}>
                <Typography key={field.id}>
                  {switchLabel(field.fullNameJpn, field.fullNameEng, isJapanese)} ({field.email})
                </Typography>
                <Delete color="error" onClick={() => handleOnRemove(index)} sx={{ cursor: 'pointer' }} />
              </Box>
            ))}
          {fields.length === 0 && <FormHelperText error>{t('enqueteCreate.editEnqueteModal.message.invalidNumberOfCoOwner')}</FormHelperText>}
        </StyledBox>
      );
    }
  };

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={2}>
      <Divider variant="middle" sx={{ width: '100%', mt: 2 }} />
      <Grid item xs={12} md={4}>
        <Box>
          <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
            {t('enqueteCreate.editEnqueteModal.enqueteCoOwner')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        {renderOwnerList()}
        <Box textAlign={'right'}>
          <Button variant={'contained'} onClick={handleOnAdd}>
            {t('button.add')}
          </Button>
        </Box>
        <SelectOwnerModal
          surveyHeaderId={''}
          open={isSelectOwnerModalOpen}
          onClose={handleOnCloseOnSelectOwnerModal}
          onOk={handleOnOkOnSelectOwnerModal}
          onValidation={validateOnSelectOwnerModal}
        />
      </Grid>
      <Divider variant="middle" sx={{ width: '100%', m: 2 }} />
    </Grid>
  );
};
