import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { RootState } from 'src/redux/store';
import { useModalHandlers } from '../../../../hooks/useModalHandlers';
import EnqueteLogicForm from './EnqueteLogicForm';

const EnqueteLogicModal: React.FC = () => {
  const { t } = useTranslation();

  // ロジック設定表示制御
  const isModalOpenLogic = useSelector((state: RootState) => state.enqueteCreate.isModalOpenLogic);
  const { onClickModalCloseLogic } = useModalHandlers();

  return (
    <StyledModal isOpen={isModalOpenLogic} onCloseFunc={onClickModalCloseLogic} modalTitle={t('enqueteCreate.LogicTitle')} mode={'general'}>
      <EnqueteLogicForm />
    </StyledModal>
  );
};

export default EnqueteLogicModal;
