import { GetContextMenuItems, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserEntity } from 'src/api/useUserApi';
import { ConditionProps } from 'src/components/app-components/bonsai/SelectModal';
import { useSelectUserAgGrid } from 'src/components/app-components/bonsai/hooks/useSelectUserAgGrid';
import useSuggestUser from 'src/hooks/useSuggestUser';

export const useSelectRespondentManagerGrid = (
  open: boolean,
  onOk: (values: UserEntity[]) => void,
  onValidation: (values: UserEntity[]) => boolean,
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userSuggest, updateUserSuggest } = useSuggestUser();

  // grid rowdata
  const [rowData, setRowData] = useState<UserEntity[]>([]);
  // selector selected values
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  // grid api
  const gridApi = useRef<GridApi | null>(null);
  // グリッド定義
  const { columnDefs } = useSelectUserAgGrid();

  useEffect(() => {
    if (open) updateUserSuggest();
  }, [open]); //eslint-disable-line

  // condition define
  const conditions: ConditionProps[] = [
    {
      label: t('enqueteCreate.selectUserModal.textfield.selectUser.placeholder', {
        userRole: t('enqueteCreate.selectRespondentManagerModal.userRole'),
      }),
      value: selectedUser,
      list: userSuggest.map((item) => item.key),
      display: true,
      onChange: (value) => setSelectedUser(value),
      onInputChange: (v, r) => {
        if (r !== 'reset') updateUserSuggest(v);
      },
    },
  ];

  //-------------------------------------------------------------
  // グリッドの表示準備完了時の処理
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // 追加ボタンが押された時の処理
  //-------------------------------------------------------------
  const onSelected = () => {
    // check input string
    const user = userSuggest.find((user) => user.key === selectedUser);
    if (!user) return enqueueSnackbar(t('enqueteCreate.selectUserModal.message.noUserSelected'), { variant: 'error' });

    // check duplicated
    const addRowData = JSON.stringify(user.entity);
    const exists = rowData.filter((rowdata) => JSON.stringify(rowdata) === addRowData);
    if (exists.length !== 0) return enqueueSnackbar(t('enqueteCreate.selectUserModal.message.alreadySelected'), { variant: 'warning' });

    // validation with page grid
    if (!onValidation([user.entity])) {
      return enqueueSnackbar(
        t('enqueteCreate.selectUserModal.message.alreadyRegisteredUser', { userRole: t('enqueteCreate.selectRespondentManagerModal.userRole') }),
        { variant: 'warning' },
      );
    }

    // add to rowdata
    setRowData([...rowData, user.entity]);
  };

  //-------------------------------------------------------------
  // 削除ボタンが押された時の処理
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('button.delete'),
        action: () => setRowData(rowData.filter((_row, i) => i !== params.node?.rowIndex)),
      },
    ];

    return menuItems;
  };

  //-------------------------------------------------------------
  // OKボタンが押された時の処理
  //-------------------------------------------------------------
  const onApplyed = () => {
    const copyRowData = [...rowData];
    onOk(copyRowData);
    onReset();
  };

  //-------------------------------------------------------------
  // ステートのリセット処理
  //-------------------------------------------------------------
  const onReset = () => {
    setRowData([]);
    setSelectedUser(null);
  };

  return {
    rowData,
    conditions,
    columnDefs,
    onGridReady,
    contextMenu,
    onSelected,
    onApplyed,
  };
};
