import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import {
  addSheetNames,
  addTotalMainTables,
  addValidSheetNames,
  deleteSheetNames,
  deleteTotalMainTables,
  deleteValidSheetNames,
  setIsOpenColumnHeaderModal,
  setIsOpenRowHeaderModal,
  setSelectedTableIndex,
} from 'src/features/general/enquete-create/store/tableSettingSlice';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { createMainTableInitialData } from 'src/utils/handsontable';
import { HotTable } from '@handsontable/react';

export const useCreateMainTable = () => {
  const dispatch = useAppDispatch();

  // useState
  const [tabNumber, setTabNumber] = useState(1);
  const [isMaxTotalTableCount, setIsMaxTotalTableCount] = useState<boolean>(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState<boolean>(false);
  const [targetDeleteIndex, setTargetDeleteIndex] = useState<number | null>(null);

  // ストア
  const { totalMainTables, subDatas } = useAppSelector((state: RootState) => state.tableSetting);

  // タブクリックハンドラー
  const handleTabClick = useCallback(
    (e: React.SyntheticEvent, newTabNumber: number) => {
      e.stopPropagation();

      // ストアへ選択中のメイン表Indexを保存
      dispatch(setSelectedTableIndex(newTabNumber - 1));
      setTabNumber(newTabNumber);
    },
    [dispatch],
  );

  // 列ヘッダ定義ダイアログ開閉制御
  const setOpenColumnHeaderModal = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsOpenColumnHeaderModal(isOpen));
    },
    [dispatch],
  );

  // 行ヘッダ定義ダイアログ開閉制御
  const setOpenRowHeaderModal = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsOpenRowHeaderModal(isOpen));
    },
    [dispatch],
  );

  // メイン表を追加ボタンクリック
  const handleAddMainClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>, saveAllSettingsCallback: (saveMode: string) => Promise<void>) => {
      e.stopPropagation();

      // 追加前にグローバルステートを保存
      await saveAllSettingsCallback('nodb');
      // memo: totalMainTablesは各回答表を表示する際のループ文に使用されているため、追加時のエラーを避けるため
      // 先にmainTableSheetNamesからデータ追加を行う
      dispatch(addSheetNames());
      dispatch(addValidSheetNames());
      dispatch(addTotalMainTables(createMainTableInitialData(5, 5)));
      dispatch(setSelectedTableIndex(totalMainTables.length));
    },
    [totalMainTables, dispatch],
  );

  // メイン表削除ボタンクリック
  const handleDeleteMainClick = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number) => {
    e.stopPropagation();

    setTargetDeleteIndex(index);
    setIsOpenDeleteConfirm(true);
  }, []);

  // メイン表削除実行
  const deleteTargetMainTable = useCallback(
    async (ref: RefObject<HotTable>[], saveAllSettingsCallback: (saveMode: string) => Promise<void>) => {
      if (targetDeleteIndex === null) return;

      // 初めのタブを削除する以外は、削除対象タブの1つ左隣りにアクティブを移す
      const activeTab = tabNumber === 1 ? 1 : tabNumber - 1;
      setTabNumber(activeTab);

      // 削除前にグローバルステートを保存
      await saveAllSettingsCallback('nodb');
      ref[targetDeleteIndex].current?.hotInstance?.loadData([[]]);
      dispatch(deleteTotalMainTables(targetDeleteIndex));
      dispatch(setSelectedTableIndex(activeTab - 1));
      dispatch(deleteSheetNames(targetDeleteIndex));
      dispatch(deleteValidSheetNames(targetDeleteIndex));

      // 選択インデックス、削除確認ダイアログを初期化
      setTargetDeleteIndex(null);
      setIsOpenDeleteConfirm(false);
    },
    [tabNumber, targetDeleteIndex, dispatch],
  ); // eslint-disable-line

  // メイン表削除確認ダイアログクローズ処理
  const disagreeCallback = () => {
    // 選択インデックス、削除確認ダイアログを初期化
    setTargetDeleteIndex(null);
    setIsOpenDeleteConfirm(false);
  };

  useEffect(() => {
    // メイン表、サブ表の合計値が最大数に達しているか判定し格納
    setIsMaxTotalTableCount(totalMainTables.length + subDatas.length === TABLE_SETTINGS.MAX_TOTAL_TABLE_LENGTH);
  }, [totalMainTables, subDatas]);

  return {
    tabNumber,
    setTabNumber,
    isMaxTotalTableCount,
    setIsMaxTotalTableCount,
    isOpenDeleteConfirm,
    setIsOpenDeleteConfirm,
    targetDeleteIndex,
    setTargetDeleteIndex,
    handleTabClick,
    setOpenColumnHeaderModal,
    setOpenRowHeaderModal,
    handleAddMainClick,
    handleDeleteMainClick,
    deleteTargetMainTable,
    disagreeCallback,
  };
};
