import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { AnswerUnit } from 'src/constants';
import { SelectRespondentPersonModal } from 'src/features/general/enquete-create/components/modals/select-respondent-person/components/SelectRespondentPersonModal';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { useAppSelector } from 'src/redux/store';
import { ChangeRespondentModal } from '../../../../modals/change-respondent-target/components/ChangeRespondentModal';
import { EnqueteCreateGrid } from './EnqueteCreateGrid';
import { EnqueteCreateTopActionButtonGroup } from './EnqueteCreateTopActionButtonGroup';

interface Props {
  surveyDetailId: string;
  answerUnit?: AnswerUnit;
}

export const RespondentTab: React.FC<Props> = ({ surveyDetailId, answerUnit }) => {
  const { id } = useParams(); // memo: id = respondentRequestId
  const { setIsOpenSelectPersonModal, setIsOpenChangeRespondentModal } = useEnqueteCreateGrid();
  const isOpenSelectPersonModal = useAppSelector((state) => state.enqueteCreate.isOpenSelectPersonModal);
  const isOpenChangeRespondentModal = useAppSelector((state) => state.enqueteCreate.isOpenChangeRespondentModal);
  const [respondentTarget, setRespondentTarget] = useState<RespondentsListEntity>();

  return (
    <>
      <EnqueteCreateTopActionButtonGroup surveyDetailId={surveyDetailId} answerUnit={answerUnit} />
      <EnqueteCreateGrid answerUnit={answerUnit} setRespondentTarget={setRespondentTarget} />
      {/** 回答者選択モーダル */}
      {answerUnit && (
        <SelectRespondentPersonModal
          surveyDetailId={surveyDetailId}
          isOpen={isOpenSelectPersonModal}
          onClose={() => setIsOpenSelectPersonModal(false)}
          respondentTargetId={respondentTarget?.respondentTargetId ?? ''}
          respondentRequestId={id ?? ''}
          answerUnit={answerUnit}
        />
      )}
      {answerUnit && respondentTarget && (
        <ChangeRespondentModal
          surveyDetailId={surveyDetailId}
          isOpen={isOpenChangeRespondentModal}
          onClose={() => setIsOpenChangeRespondentModal(false)}
          respondentTarget={respondentTarget}
          respondentRequestId={id ?? ''}
        />
      )}
    </>
  );
};
