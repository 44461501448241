import { Alert, AlertTitle, Box, Button, Grid, Modal, Stack, SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
};

export const AlertModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, onClick } = props;
  const { t } = useTranslation();
  const style: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Alert severity="warning" sx={{ whiteSpace: 'pre-line' }}>
          <AlertTitle>{t('enqueteAnswerInput.modal.selectCustomer.overViewAlertModal.title')}</AlertTitle>
          {t('enqueteAnswerInput.modal.selectCustomer.overViewAlertModal.message')}
        </Alert>
        <Grid container mt={2}>
          <Grid item xs={6} justifyContent={'left'}>
            <Stack direction={'row'} spacing={1}>
              <Button variant="outlined" onClick={onClose} color="error">
                {t('button.cancel')}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row-reverse'}>
              <Button variant="contained" onClick={onClick}>
                {t('button.ok')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
