import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SurveyInformationEntity } from 'src/@types/generalEnquete';
import { EnqueteTargetDateForm } from 'src/components/app-components/bonsai/survey-information/components/EnqueteTargetDateForm';
import { RespondentRequestForm } from 'src/components/app-components/bonsai/survey-information/components/RespondentRequestForm';
import { FORM_TYPE, RequestStatus, SURVEY_TYPE } from 'src/constants';
import useLocales from 'src/hooks/useLocales';

//アンケート編集モーダル共通エンティティ
type SurveyInformationCommonFormEntity = {
  tempEnqueteData: SurveyInformationEntity;
  requestStatus: RequestStatus | undefined;
  methods: UseFormReturn;
};

export const SurveyInformationCommonForm: React.FC<SurveyInformationCommonFormEntity> = (props) => {
  const { methods, tempEnqueteData, requestStatus } = props;
  const { isJapanese } = useLocales();
  const { t } = useTranslation();

  return (
    <>
      {/** アンケート種別 */}
      <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }} children={t('enqueteCreate.editEnqueteModal.label')} />
        </Grid>
        <Grid item xs={12} md={8}>
          {isJapanese ? tempEnqueteData.labelJpn : tempEnqueteData.labelEng}
        </Grid>
      </Grid>

      {/** アンケートタイトル 依頼期間 */}
      <RespondentRequestForm enqueteData={tempEnqueteData} requestStatus={requestStatus} methods={methods} />

      {/** 対象年月 */}
      {tempEnqueteData.type !== SURVEY_TYPE.GENERAL && tempEnqueteData.formType !== FORM_TYPE.OVERVIEW && (
        <EnqueteTargetDateForm methods={methods} targetDate={tempEnqueteData.targetDate} />
      )}
    </>
  );
};
