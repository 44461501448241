import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { SELECT_RESPONDENT_LINE_MAX_LENGTH, SELECT_RESPONDENT_MARS_MAX_RETRIEVED } from 'src/constants';
import { useBearerToken } from './useBearerToken';

/**
 * ユーザー検索結果用のインターフェース
 *
 * コメント部分はPortal側のフィールド名
 */
export interface UserEntity {
  id: string; // id
  fullNameJpn: string | null; // fullName
  fullNameEng: string | null; // engFullName
  companyCode: string | null; // companySCdMain
  companyNameJpn: string | null; // companyNameMain
  companyNameEng: string | null; // companyEngNameMain
  companyAbbreviation: string | null; // companyAbbreviationMain
  divisionCode: string | null; // headquartersCdMain
  divisionNameJpn: string | null; // headquartersNameMain
  divisionNameEng: string | null; // headquertersEngNameMain
  sbuCode: string | null; // sbuCdMain
  sbuNameJpn: string | null; // sbuNameMain
  sbuNameEng: string | null; // sbuEngNameMain
  departmentCode: string | null; // departmentCdMain
  departmentNameJpn: string | null; // departmentNameMain
  departmentNameEng: string | null; // departmentEngNameMain
  groupCode: string | null; // groupCdMain
  groupNameJpn: string | null; // groupNameMain
  groupNameEng: string | null; // groupEngNameMain
  email: string | null; // emailAddress 補)テストデータ以外はemailがちゃんと入っている
  bluePageEmailAddress: string | null; // bluePageEmailAddress
  assignedCompanyNameJpn: string | null; // 出向先 (カナ名)
  assignedCompanyNameEng: string | null; // 出向先 (英語名)
}

/**
 * ポータル内のエンティティ（一部）
 */
export interface PortalUserEntity {
  id: string;
  fullName: string | null;
  engFullName: string | null;
  companySCdMain: string | null;
  companyNameMain: string | null;
  companyEngNameMain: string | null;
  companyAbbreviationMain: string | null;
  headquartersCdMain: string | null;
  headquartersNameMain: string | null;
  headquartersEngNameMain: string | null;
  sbuCdMain: string | null;
  sbuNameMain: string | null;
  sbuEngNameMain: string | null;
  departmentCdMain: string | null;
  departmentNameMain: string | null;
  departmentEngNameMain: string | null;
  groupCdMain: string | null;
  groupNameMain: string | null;
  groupEngNameMain: string | null;
  jobTitleCdMain?: string;
  jobTitleMain?: string;
  jobTitleEngNameMain?: string;
  storeCdMain?: string;
  storeNameMain?: string;
  storeEngNameMain?: string;
  countryCdMain?: string;
  countryNameMain?: string;
  countryEngNameMain?: string;
  emailAddress: string | null;
  bluePageEmailAddress: string | null;
  assignedCompanyName: string | null;
  assignedCompanyEngName: string | null;
  resource: string;
}

// getByFilter で指定可能な検索条件
interface UserSearchCondition {
  keyword?: string;
  companyCode?: string;
  countryCode?: string;
  divisionCode?: string;
  sbuCode?: string;
  departmentCode?: string;
  groupCode?: string;
  storeCode?: string;
  jobTitleCode?: string;
  gradeJobTitleCode?: string;
}

const selectList = {
  id: true,
  fullName: true,
  engFullName: true,
  companySCdMain: true,
  companyNameMain: true,
  companyEngNameMain: true,
  companyAbbreviationMain: true,
  headquartersCdMain: true,
  headquartersNameMain: true,
  headquartersEngNameMain: true,
  sbuCdMain: true,
  sbuNameMain: true,
  sbuEngNameMain: true,
  departmentCdMain: true,
  departmentNameMain: true,
  departmentEngNameMain: true,
  groupCdMain: true,
  groupNameMain: true,
  groupEngNameMain: true,
  emailAddress: true,
  bluePageEmailAddress: true,
  assignedCompanyName: true,
  assignedCompanyEngName: true,
  resource: true,
};

export const useUserApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 汎用検索用関数(最大30件取得)
   * @param query 検索クエリ(Prismaリクエストフォーマット)
   * @returns
   */
  const get = async (query: {}): Promise<UserEntity[] | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const result = await axios.get<PortalUserEntity[]>(portalApi + '/user/account', {
        headers: { Authorization: token },
        params: { filter: query },
      });

      // 結果確認
      if (result.status !== 200) {
        throw Error(t('apiExceptionMessage.useUserApi.failedGetUserData'));
      }

      // Portalのプロパティ名からBONSAIのプロパティ名に変換
      const users = result.data;
      return users.map<UserEntity>((user) => ({
        id: user.id,
        fullNameJpn: user.fullName,
        fullNameEng: user.engFullName,
        companyCode: user.companySCdMain,
        companyNameJpn: user.companyNameMain,
        companyNameEng: user.companyEngNameMain,
        companyAbbreviation: user.companyAbbreviationMain,
        divisionCode: user.headquartersCdMain,
        divisionNameJpn: user.headquartersNameMain,
        divisionNameEng: user.headquartersEngNameMain,
        sbuCode: user.sbuCdMain,
        sbuNameJpn: user.sbuNameMain,
        sbuNameEng: user.sbuEngNameMain,
        departmentCode: user.departmentCdMain,
        departmentNameJpn: user.departmentNameMain,
        departmentNameEng: user.departmentEngNameMain,
        groupCode: user.groupCdMain,
        groupNameJpn: user.groupNameMain,
        groupNameEng: user.groupEngNameMain,
        email: user.emailAddress,
        bluePageEmailAddress: user.bluePageEmailAddress,
        assignedCompanyNameJpn: user.assignedCompanyName,
        assignedCompanyNameEng: user.assignedCompanyEngName,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.message && error.response.data.message === 'User Not Found!') {
          // 検索HIT数が０の時はエラーの扱いとしない
          return;
        }
        enqueueSnackbar(t('apiExceptionMessage.useUserApi.failedGetUserData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 検索(デフォルト最大30件取得。null指定で無制限)
   * @param where 検索条件(Prismaフォーマット)
   */
  const getUpperLimit = async (where?: {}, limit: number | null = 30) => {
    const query = {
      take: limit ?? undefined,
      where: where,
      select: selectList,
    };
    return await get(query);
  };

  const makeConditionByKeyword = (keyword?: string | null) => {
    if (!keyword) {
      return undefined;
    }

    const keywordIgnoreCaseAndContains = {
      contains: keyword,
      mode: 'insensitive',
    };
    return {
      OR: [
        {
          AND: [
            { companySCdMain: 'S500' },
            {
              OR: [
                { id: keywordIgnoreCaseAndContains },
                { fullName: keywordIgnoreCaseAndContains },
                { engFullName: keywordIgnoreCaseAndContains },
                { companySCdMain: keywordIgnoreCaseAndContains },
                { companyNameMain: keywordIgnoreCaseAndContains },
                { companyEngNameMain: keywordIgnoreCaseAndContains },
                { companyAbbreviationMain: keywordIgnoreCaseAndContains },
                { headquartersCdMain: keywordIgnoreCaseAndContains },
                { headquartersNameMain: keywordIgnoreCaseAndContains },
                { headquartersEngNameMain: keywordIgnoreCaseAndContains },
                { sbuCdMain: keywordIgnoreCaseAndContains },
                { sbuNameMain: keywordIgnoreCaseAndContains },
                { sbuEngNameMain: keywordIgnoreCaseAndContains },
                { departmentCdMain: keywordIgnoreCaseAndContains },
                { departmentNameMain: keywordIgnoreCaseAndContains },
                { departmentEngNameMain: keywordIgnoreCaseAndContains },
                { groupCdMain: keywordIgnoreCaseAndContains },
                { groupNameMain: keywordIgnoreCaseAndContains },
                { groupEngNameMain: keywordIgnoreCaseAndContains },
                { emailAddress: keywordIgnoreCaseAndContains },
                { bluePageEmailAddress: keywordIgnoreCaseAndContains },
                { assignedCompanyName: keywordIgnoreCaseAndContains },
                { assignedCompanyEngName: keywordIgnoreCaseAndContains },
              ],
            },
          ],
        },
        {
          AND: [
            // Memo:豊田通商（S500）以外の場合は、本部、SBU、部はキーワードの条件から除外する。
            { NOT: { companySCdMain: 'S500' } },
            {
              OR: [
                { id: keywordIgnoreCaseAndContains },
                { fullName: keywordIgnoreCaseAndContains },
                { engFullName: keywordIgnoreCaseAndContains },
                { companySCdMain: keywordIgnoreCaseAndContains },
                { companyNameMain: keywordIgnoreCaseAndContains },
                { companyEngNameMain: keywordIgnoreCaseAndContains },
                { companyAbbreviationMain: keywordIgnoreCaseAndContains },
                { emailAddress: keywordIgnoreCaseAndContains },
                { bluePageEmailAddress: keywordIgnoreCaseAndContains },
              ],
            },
          ],
        },
      ],
    };
  };

  /**
   * フィルタ検索
   * 　Marsポータルより条件指定分の全データを取得する
   * 　Marsポータル側に取得制限件数が存在するため、複数回呼び出しを行う
   * @param condition 検索クエリ(Prismaリクエストフォーマット)
   * @returns
   */
  const getByFilter = async (condition: UserSearchCondition): Promise<UserEntity[] | undefined> => {
    // memo: 関数内で DataInsightPortal 用の検索条件に変換
    //       useUserApi の外では DataInsightPortal の仕様に引きずられないように考慮
    try {
      // 取得件数定義
      // 最大取得件数
      const maxData = SELECT_RESPONDENT_LINE_MAX_LENGTH;
      // Marsからの取得件数
      const maxMarsItems = SELECT_RESPONDENT_MARS_MAX_RETRIEVED;
      // Marsへの問い合わせ回数
      const maxLoopTimes = Math.ceil(maxData / maxMarsItems);
      // Marsからの取得結果を格納
      let users: UserEntity[] = [];

      // リクエスト
      const token = await getBearerToken();

      // Marsのエンドポイント /user/account から全件取得するまで処理を繰り返す
      let cnt = 0;
      while (cnt < maxLoopTimes) {
        // 最大取得件数 - 取得済み件数 がMarsからの取得件数以下の場合 taks に設定、それ以外の場合はMarsからの取得件数を設定する
        const takes = maxData - users.length < maxMarsItems ? maxData - users.length : maxMarsItems;
        const whereId = users.length === 0 ? undefined : { gt: users.at(-1)?.id };
        const query = {
          where: {
            ...makeConditionByKeyword(condition.keyword),
            id: whereId,
            companySCdMain: condition.companyCode,
            headquartersCdMain: condition.divisionCode,
            departmentCdMain: condition.departmentCode,
            sbuCdMain: condition.sbuCode,
            groupCdMain: condition.groupCode,
            countryCdMain: condition.countryCode,
            storeCdMain: condition.storeCode,
            jobTitleCdMain: condition.jobTitleCode,
            gradeJobTitleCd: condition.gradeJobTitleCode,
          },
          orderBy: { id: 'asc' },
          take: takes,
        };

        const result = await axios.get<PortalUserEntity[]>(portalApi + '/user/account', {
          headers: { Authorization: token },
          params: { filter: query },
        });

        // 結果確認
        if (result.status !== 200) {
          throw Error(t('apiExceptionMessage.useUserApi.failedGetUserData'));
        }

        // Portalのプロパティ名からBONSAIのプロパティ名に変換
        const resultData = result.data.map<UserEntity>((user) => ({
          id: user.id,
          fullNameJpn: user.fullName,
          fullNameEng: user.engFullName,
          companyCode: user.companySCdMain,
          companyNameJpn: user.companyNameMain,
          companyNameEng: user.companyEngNameMain,
          companyAbbreviation: user.companyAbbreviationMain,
          divisionCode: user.headquartersCdMain,
          divisionNameJpn: user.headquartersNameMain,
          divisionNameEng: user.headquartersEngNameMain,
          sbuCode: user.sbuCdMain,
          sbuNameJpn: user.sbuNameMain,
          sbuNameEng: user.sbuEngNameMain,
          departmentCode: user.departmentCdMain,
          departmentNameJpn: user.departmentNameMain,
          departmentNameEng: user.departmentEngNameMain,
          groupCode: user.groupCdMain,
          groupNameJpn: user.groupNameMain,
          groupNameEng: user.groupEngNameMain,
          email: user.emailAddress,
          bluePageEmailAddress: user.bluePageEmailAddress,
          assignedCompanyNameJpn: user.assignedCompanyName,
          assignedCompanyNameEng: user.assignedCompanyEngName,
        }));
        users.push(...resultData);
        cnt++;
      }
      return users;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.message && error.response.data.message === 'User Not Found!') {
          // 検索HIT数が０の時はエラーの扱いとしない
          return;
        }
        enqueueSnackbar(t('apiExceptionMessage.useUserApi.failedGetUserData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * キーワード検索用(最大30件取得)
   * @param keyword 検索キーワード
   */
  const getByKeyword = async (keyword?: string | null): Promise<UserEntity[] | undefined> => {
    const where = makeConditionByKeyword(keyword);
    return await getUpperLimit(keyword ? where : undefined);
  };

  /**
   * Email検索用(最大30件取得)
   */
  const getByEmail = useCallback(async (emails: string[], limit: number | null | undefined = undefined): Promise<UserEntity[] | undefined> => {
    return await getUpperLimit({ emailAddress: { in: emails } }, limit);
  }, []);

  return {
    getByKeyword,
    getByEmail,
    getByFilter,
  };
};
