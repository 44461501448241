import { useForm } from 'react-hook-form';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE } from 'src/@types/generalEnquete';
import { RootState, useSelector } from 'src/redux/store/index';
import QuestionCheckboxForm from './common/components/QuestionCheckboxForm';
import QuestionCommentForm from './common/components/QuestionCommentForm';
import QuestionDropdownForm from './common/components/QuestionDropdownForm';
import QuestionForm from './common/components/QuestionForm';
import QuestionRadioGroupForm from './common/components/QuestionRadioGroupForm';
import QuestionTextForm from './common/components/QuestionTextForm';

const EnqueteCreateQuestionSwitcher = () => {
  const question = useSelector((state: RootState) => state.enqueteCreate.question);
  const methods = useForm({ mode: 'onChange' });

  const renderSwitch = (type: string) => {
    switch (type) {
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT:
        return <QuestionTextForm methods={methods} />;
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXTAREA:
        return <QuestionCommentForm methods={methods} />;
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX:
        return <QuestionCheckboxForm methods={methods} />;
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP:
        return <QuestionRadioGroupForm methods={methods} />;
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT:
        return <QuestionDropdownForm methods={methods} />;
      default:
        return null;
    }
  };

  return question.inputType ? <QuestionForm methods={methods}>{renderSwitch(question.inputType)}</QuestionForm> : null;
};

export default EnqueteCreateQuestionSwitcher;
