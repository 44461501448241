import { FormControlLabel, FormHelperText, Radio, RadioGroup, SxProps, Theme } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { QuestionItem } from 'src/@types/generalEnquete';

type Props = {
  name: string;
  control: Control<any>;
  rules?: Object;
  defaultValue: any;
  questionItem?: QuestionItem[];
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

const RHFRadioGroup = ({ name, control, rules, defaultValue, questionItem, handleOnChange, disabled, sx }: Props) => {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <RadioGroup key={name} onChange={handleOnChange} sx={sx}>
            {(questionItem as QuestionItem[]).map((item: QuestionItem) => (
              <FormControlLabel
                {...field}
                control={<Radio checked={field.value === item.id} />}
                value={item.id}
                label={item.label}
                key={item.id}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
          <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
        </>
      )}
    />
  );
};

export default RHFRadioGroup;
