import { Box, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

type ConditionEditNotAllowedCardProps = {
  title: string;
  index: number;
  message: string;
};

export const ConditionEditNotAllowedCard = ({ title, message, index }: ConditionEditNotAllowedCardProps) => {
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  return (
    <Card sx={{ p: 2, m: 2, backgroundColor: isLight ? palette.dark.background.neutral : palette.dark.background.paper }}>
      <Box sx={{ m: 1 }}>
        <Typography variant="body1" sx={{ color: palette.light.text.disabled }}>
          {t('enqueteCreate.questionNo')}
          {index + 1} {title}
        </Typography>
        <Typography variant="body1" sx={{ color: palette.light.text.disabled }}>
          {message}
        </Typography>
      </Box>
    </Card>
  );
};
