import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const divisionRegionEditorSlice = createSlice({
  name: 'divisionRegionEditor',
  initialState: {
    isOpenEditModal: false,
    isLoading: false,
    refreshCounter: 0,
  },
  reducers: {
    setIsOpenEditModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenEditModal = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateRefreshCounter: (state) => {
      state.refreshCounter++;
    },
  },
});
