import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useGa } from 'src/config';

type PageTrackingProps = {
  companyCode: string;
  departmentCode?: string;
  sbuCode?: string;
  divisionCode?: string;
  groupCode?: string;
};

const usePageTracking = (props: PageTrackingProps) => {
  const location = useLocation();

  useEffect(() => {
    if (useGa) {
      ReactGA.send({
        hitType: 'pageview',
      });
      // 会社情報があればGAに送信する
      if (props.companyCode) {
        ReactGA.gtag('set', 'user_properties', {
          company: props.companyCode,
          department: props.departmentCode,
          division: props.divisionCode,
          sbu: props.sbuCode,
          group: props.groupCode,
        });
      }
    }
  }, [location, props.companyCode, props.departmentCode, props.divisionCode, props.groupCode, props.sbuCode]);
};

export default usePageTracking;
