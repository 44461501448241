import { useState } from "react";

export const useListToggle = () => {
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!isOpen);
  };
  return {
    isOpen,
    handleClick,
  };
};
