import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_CALL_STATUS } from 'src/api/api-constants';
import { EnqueteThreadCreatorEntitiy, EnqueteThreadPriorInfo } from 'src/api/getThreadCreatorApi';
import { UserEntity } from 'src/api/useUserApi';
import { fetchThreadPriorInfo } from './enqueteThreadActions';

export const enqueteThreadCreateSlice = createSlice({
  name: 'enquete-thread/create',
  initialState: {
    priorInfo: {} as EnqueteThreadPriorInfo,
    threadUsers: [] as UserEntity[],
    status: '' as EnqueteThreadCreatorEntitiy['status'],
  },
  reducers: {
    addThreadUsers(state, action: PayloadAction<UserEntity>) {
      state.threadUsers = [...state.threadUsers, action.payload];
    },
    deleteThreadUser(state, action) {
      state.threadUsers = action.payload;
    },
    clearThreadUsers(state) {
      state.threadUsers = [];
      state.priorInfo = {
        categories: [],
        surveyTitle: [],
      };
    },
    editThreadUsers(state, action: PayloadAction<UserEntity[]>) {
      state.threadUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreadPriorInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.priorInfo = action.payload;
      }
      state.status = API_CALL_STATUS.FULFILLED;
    });
    builder.addCase(fetchThreadPriorInfo.pending, (state) => {
      state.status = API_CALL_STATUS.PENDING;
    });
    builder.addCase(fetchThreadPriorInfo.rejected, (state, action) => {
      state.status = action.error;
    });
  },
});

export const { addThreadUsers, deleteThreadUser, clearThreadUsers, editThreadUsers } = enqueteThreadCreateSlice.actions;
export default enqueteThreadCreateSlice.reducer;
