import { AdditionalQuestion, Amount, PurchaseFixedQuestions, SalesFixedQuestions } from 'src/@types/overviewEnquete';
import { REQUEST_STATUS } from 'src/constants';
import { OverviewSurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { setAdditionalData, setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { QUESTION_TYPE, QuestionType } from '../components/OverviewInputEditBox';

interface Props {
  questionType: QuestionType;
}

type FixedInfo = {
  isRequire: boolean; // デフォルト項目の中で必須項目か
  label: string; // 入力項目名（useTranslation()へ渡す用）
  itemName: string; // 入力項目名（チェックボックス変更時の検索用）
  isAnswerTarget: boolean;
};

export const useSetFixedItem = (props: Props) => {
  const { questionType } = props;
  const dispatch = useAppDispatch();
  const defaultFixedItem = {
    isAnswerTarget: false,
    answer: '',
  };
  const { enqueteData, additionalData } = useAppSelector((state: RootState) => state.enqueteCreate);
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const surveyDetail = enqueteData.surveyDetail ? Object.assign(enqueteData.surveyDetail) : {};
  const overviewSurveyFormData = surveyDetail?.surveyFormData?.formData as OverviewSurveyFormData;
  const tabData = {
    ...overviewSurveyFormData?.tabData[0],
    purchaseFixedQuestions:
      overviewSurveyFormData?.tabData[0]?.purchaseFixedQuestions ??
      ({
        product: defaultFixedItem,
        mainCustomer: defaultFixedItem,
        commercialFlow: defaultFixedItem,
      } as PurchaseFixedQuestions),
    salesFixedQuestions:
      overviewSurveyFormData?.tabData[0]?.salesFixedQuestions ??
      ({
        product: defaultFixedItem,
        commercialFlow: defaultFixedItem,
      } as SalesFixedQuestions),
  };

  let questionTypeTitle; // 処理対象となる入力項目名（useTranslation()へ渡す用）
  let fixedInfo: FixedInfo[]; // デフォルト項目の情報
  let fixedQuestions;
  let additionalQuestions;
  switch (questionType) {
    case QUESTION_TYPE.PURCHASE:
      questionTypeTitle = 'enqueteCreate.overviewSurvey.purchaseTransactions';
      fixedQuestions = tabData?.purchaseFixedQuestions as PurchaseFixedQuestions;
      fixedInfo = [
        {
          isRequire: true,
          label: 'enqueteCreate.overviewSurvey.amount',
          itemName: 'amount',
          isAnswerTarget: tabData?.purchaseFixedAmount?.isAnswerTarget,
        },
        {
          isRequire: false,
          label: 'enqueteCreate.overviewSurvey.product',
          itemName: 'product',
          isAnswerTarget: fixedQuestions?.product.isAnswerTarget,
        },
        {
          isRequire: false,
          label: 'enqueteCreate.overviewSurvey.mainCustomer',
          itemName: 'mainCustomer',
          isAnswerTarget: fixedQuestions?.mainCustomer.isAnswerTarget,
        },
        {
          isRequire: false,
          label: 'enqueteCreate.overviewSurvey.commercialFlow',
          itemName: 'commercialFlow',
          isAnswerTarget: fixedQuestions?.commercialFlow.isAnswerTarget,
        },
      ];
      fixedQuestions = tabData?.purchaseFixedQuestions as PurchaseFixedQuestions;
      additionalQuestions = tabData?.purchaseAdditionalQuestions as AdditionalQuestion[];
      break;
    case QUESTION_TYPE.SALES:
      questionTypeTitle = 'enqueteCreate.overviewSurvey.salesTransactions';
      fixedQuestions = tabData?.salesFixedQuestions as SalesFixedQuestions;
      fixedInfo = [
        {
          isRequire: true,
          label: 'enqueteCreate.overviewSurvey.amount',
          itemName: 'amount',
          isAnswerTarget: tabData?.salesFixedAmount?.isAnswerTarget,
        },
        {
          isRequire: false,
          label: 'enqueteCreate.overviewSurvey.product',
          itemName: 'product',
          isAnswerTarget: fixedQuestions?.product.isAnswerTarget,
        },
        {
          isRequire: false,
          label: 'enqueteCreate.overviewSurvey.commercialFlow',
          itemName: 'commercialFlow',
          isAnswerTarget: fixedQuestions?.commercialFlow.isAnswerTarget,
        },
      ];
      fixedQuestions = tabData?.salesFixedQuestions as SalesFixedQuestions;
      additionalQuestions = tabData?.salesAdditionalQuestions as AdditionalQuestion[];
      break;
    case QUESTION_TYPE.OTHER:
      questionTypeTitle = 'enqueteCreate.overviewSurvey.other';
      fixedInfo = [
        {
          isRequire: true,
          label: 'common.topics',
          itemName: 'topics',
          isAnswerTarget: true,
        },
      ];
      additionalQuestions = tabData?.otherAdditionalQuestions as AdditionalQuestion[];
      break;
  }

  /**
   * デフォルト項目のチェックボックスを変更する
   * @param questionItemName 項目名
   * @param isAnswerTarget 変更後のtrue/false
   */
  const setFixedItemOnChange = async (questionItemName: string, isAnswerTarget: boolean) => {
    let purchaseFixedAmount: Amount = tabData?.purchaseFixedAmount;
    let purchaseFixedQuestions: PurchaseFixedQuestions = tabData?.purchaseFixedQuestions;
    if (questionType === QUESTION_TYPE.PURCHASE) {
      if (questionItemName === 'amount') {
        purchaseFixedAmount = {
          ...purchaseFixedAmount,
          isAnswerTarget: isAnswerTarget,
        };
      } else {
        purchaseFixedQuestions = {
          ...purchaseFixedQuestions,
          [questionItemName]: {
            isAnswerTarget: isAnswerTarget,
            answer: purchaseFixedQuestions[questionItemName as keyof typeof purchaseFixedQuestions].answer,
          },
        };
      }

      // アンケート実施中に追加項目を管理
      if (requestStatus === REQUEST_STATUS.IN_PROGRESS) {
        const addData: PurchaseFixedQuestions = {
          ...additionalData.purchaseFixedQuestions,
          [questionItemName]: {
            isAnswerTarget: isAnswerTarget,
            answer: '',
          },
        };
        dispatch(
          setAdditionalData({
            ...additionalData,
            purchaseFixedQuestions: addData,
          }),
        );
      }
    }

    let salesFixedAmount: Amount = tabData?.salesFixedAmount;
    let salesFixedQuestions: SalesFixedQuestions = tabData?.salesFixedQuestions;
    if (questionType === QUESTION_TYPE.SALES) {
      if (questionItemName === 'amount') {
        salesFixedAmount = {
          ...salesFixedAmount,
          isAnswerTarget: isAnswerTarget,
        };
      } else {
        salesFixedQuestions = {
          ...salesFixedQuestions,
          [questionItemName]: {
            isAnswerTarget: isAnswerTarget,
            answer: salesFixedQuestions[questionItemName as keyof typeof salesFixedQuestions].answer,
          },
        };
      }

      // アンケート実施中に追加項目を管理
      if (requestStatus === REQUEST_STATUS.IN_PROGRESS) {
        const addData: SalesFixedQuestions = {
          ...additionalData.salesFixedQuestions,
          [questionItemName]: {
            isAnswerTarget: isAnswerTarget,
            answer: '',
          },
        };
        dispatch(
          setAdditionalData({
            ...additionalData,
            salesFixedQuestions: addData,
          }),
        );
      }
    }

    const form: OverviewSurveyFormData = {
      pastRespondentRequestId: overviewSurveyFormData?.pastRespondentRequestId,
      tabData: [
        {
          ...tabData,
          purchaseFixedAmount,
          purchaseFixedQuestions,
          salesFixedAmount,
          salesFixedQuestions,
        },
      ],
    };

    dispatch(
      setEnqueteData({
        ...enqueteData,
        surveyDetail: {
          ...enqueteData.surveyDetail,
          surveyFormData: { ...enqueteData.surveyDetail?.surveyFormData, formData: form },
          surveyHeader: enqueteData.surveyDetail?.surveyHeader,
        },
      }),
    );
  };

  /**
   * 追加項目を削除する
   * @param questionTitleJpn 項目名
   */
  const deleteFixedItemOnClick = async (questionTitleJpn: string) => {
    let purchaseAdditionalQuestions: AdditionalQuestion[] = [...tabData?.purchaseAdditionalQuestions];
    let salesAdditionalQuestions: AdditionalQuestion[] = [...tabData?.salesAdditionalQuestions];
    let otherAdditionalQuestions: AdditionalQuestion[] = [...tabData?.otherAdditionalQuestions];
    switch (questionType) {
      case QUESTION_TYPE.PURCHASE:
        purchaseAdditionalQuestions = purchaseAdditionalQuestions.filter((question) => question.titleJpn !== questionTitleJpn);
        break;
      case QUESTION_TYPE.SALES:
        salesAdditionalQuestions = salesAdditionalQuestions.filter((question) => question.titleJpn !== questionTitleJpn);
        break;
      case QUESTION_TYPE.OTHER:
        otherAdditionalQuestions = otherAdditionalQuestions.filter((question) => question.titleJpn !== questionTitleJpn);
        break;
    }

    const form: OverviewSurveyFormData = {
      pastRespondentRequestId: overviewSurveyFormData?.pastRespondentRequestId,
      tabData: [
        {
          ...tabData,
          purchaseAdditionalQuestions: purchaseAdditionalQuestions,
          salesAdditionalQuestions: salesAdditionalQuestions,
          otherAdditionalQuestions: otherAdditionalQuestions,
        },
      ],
    };

    dispatch(
      setEnqueteData({
        ...enqueteData,
        surveyDetail: {
          ...enqueteData.surveyDetail,
          surveyFormData: { ...enqueteData.surveyDetail?.surveyFormData, formData: form },
          surveyHeader: enqueteData.surveyDetail?.surveyHeader,
        },
      }),
    );

    // アンケート実施中に追加された入力項目を削除する
    if (requestStatus === REQUEST_STATUS.IN_PROGRESS) {
      let purchaseAdditionalData: AdditionalQuestion[] = [...(additionalData?.purchaseAdditionalQuestions ?? [])];
      let salesAdditionalData: AdditionalQuestion[] = [...(additionalData?.salesAdditionalQuestions ?? [])];
      let otherAdditionalData: AdditionalQuestion[] = [...(additionalData?.otherAdditionalQuestions ?? [])];

      switch (questionType) {
        case QUESTION_TYPE.PURCHASE:
          if (!additionalData?.purchaseAdditionalQuestions) return;
          purchaseAdditionalData = [...additionalData.purchaseAdditionalQuestions.filter((data) => data.titleJpn !== questionTitleJpn)];
          break;
        case QUESTION_TYPE.SALES:
          if (!additionalData?.salesAdditionalQuestions) return;
          salesAdditionalData = [...additionalData.salesAdditionalQuestions.filter((data) => data.titleJpn !== questionTitleJpn)];
          break;
        case QUESTION_TYPE.OTHER:
          if (!additionalData?.otherAdditionalQuestions) return;
          otherAdditionalData = [...additionalData.otherAdditionalQuestions.filter((data) => data.titleJpn !== questionTitleJpn)];
          break;
      }

      dispatch(
        setAdditionalData({
          ...additionalData,
          purchaseAdditionalQuestions: purchaseAdditionalData,
          salesAdditionalQuestions: salesAdditionalData,
          otherAdditionalQuestions: otherAdditionalData,
        }),
      );
    }
  };

  // アンケート実施中の入力項目のdisabled判定
  const isAdditionalQuestionDisabled = (titleJpn: string, titleEng: string): boolean => {
    // アンケート実施中以外は判定が必要ないのでreturn
    if (requestStatus === REQUEST_STATUS.BEFORE_REQUEST) return false;
    if (requestStatus === REQUEST_STATUS.COMPLETE) return true;
    const additionalTargetData =
      questionType === 'PURCHASE'
        ? additionalData.purchaseAdditionalQuestions
        : questionType === 'SALES'
        ? additionalData.salesAdditionalQuestions
        : additionalData.otherAdditionalQuestions;

    // 返り値としてはboolean値が欲しいのであえてbooleanで返す
    return additionalTargetData?.find((data) => data.titleJpn === titleJpn && data.titleEng === titleEng) ? false : true;
  };

  // アンケート実施中の固定質問のdisabled判定
  const isDisabledCheckBox = (itemName: string, isAnswerTarget: boolean): boolean => {
    // その他のトピックスにおいては、常にユーザに変更できない値のため、disabledとして返却する
    if (questionType === 'OTHER') return true; // その他はトピックスのみのため、item名は見ない
    // アンケート実施中以外は判定が必要ないのでreturn
    if (requestStatus === REQUEST_STATUS.BEFORE_REQUEST) return false;
    if (requestStatus === REQUEST_STATUS.COMPLETE) return true;

    // 返り値としてはboolean値が欲しいのであえてbooleanで返す
    if (questionType === 'PURCHASE') {
      switch (itemName) {
        case 'product':
          return !additionalData?.purchaseFixedQuestions?.product && isAnswerTarget;
        case 'mainCustomer':
          return !additionalData?.purchaseFixedQuestions?.mainCustomer && isAnswerTarget;
        case 'commercialFlow':
          return !additionalData?.purchaseFixedQuestions?.commercialFlow && isAnswerTarget;
      }
    } else {
      switch (itemName) {
        case 'product':
          return !additionalData?.salesFixedQuestions?.product && isAnswerTarget;
        case 'commercialFlow':
          return !additionalData?.salesFixedQuestions?.commercialFlow && isAnswerTarget;
      }
    }
    // 金額の場合は引数に関係なくdisabled
    return true;
  };

  return {
    questionTypeTitle,
    fixedInfo,
    additionalQuestions,
    setFixedItemOnChange,
    deleteFixedItemOnClick,
    isAdditionalQuestionDisabled,
    isDisabledCheckBox,
  };
};
