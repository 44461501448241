import { useSnackbar } from 'notistack5';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CountryEntity } from 'src/api/useCountryApi';
import { CountryRegionEntity } from 'src/api/useCountryRegionApi';
import { ParentCustomer, useCustomerManagementApi } from 'src/api/useCustomerManagementApi';
import { RegionEntity } from 'src/api/useRegionApi';
import { closeEditCustomerModal, setBonsaiAddedCustomers } from 'src/features/manage/customer-management/store/customerManagementSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';
import { switchLabel } from 'src/utils/labelUtils';

interface OptionsType {
  key: string;
  label: string;
}

const initState = { key: '', label: '' };

export const useCustomerManagementEditModal = (
  methods: UseFormReturn,
  parentCustomers: ParentCustomer[],
  regions: RegionEntity[],
  countries: CountryEntity[],
  countryRegions: CountryRegionEntity[],
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const { editCustomer } = useAppSelector((state: RootState) => state.customerManagement);
  const { getCustomerManagement, registerCustomer, updateCustomer } = useCustomerManagementApi();
  const [parentCustomerOptions, setParentCustomerOptions] = useState<OptionsType[]>([]);
  const [parentCustomerDefaultValue, setParentCustomerDefaultValue] = useState<OptionsType>(initState);
  const [regionOptions, setRegionOptions] = useState<OptionsType[]>([]);
  const [regionDefaultValue, setRegionDefaultValue] = useState<OptionsType>(initState);
  const [countryOptions, setCountryOptions] = useState<OptionsType[]>([]);
  const [countryDefaultValue, setCountryDefaultValue] = useState<OptionsType>(initState);
  const [isCountryDisabled, setIsCountryDisabled] = useState<boolean>(true);

  const onSaveClick = useCallback(
    async (data: FieldValues) => {
      try {
        const body = {
          parent: {
            customerCode: data.parent.customerCode,
            topGlobalCustomerCode: data.parent.topGlobalCustomerCode,
            topDomesticCustomerCode: data.parent.topDomesticCustomerCode,
            countryCode: data.parent.countryCode,
            regionCode: data.parent.regionCode,
          },
          customerNameJpn: data.customerNameJpn,
          customerNameEng: data.customerNameEng,
          regionCode: data.regionCode,
          regionNameJpn: data.regionNameJpn,
          regionNameEng: data.regionNameEng,
          countryCode: data.countryCode,
          countryNameJpn: data.countryNameJpn,
          countryNameEng: data.countryNameEng,
        };
        if (editCustomer) {
          await updateCustomer(editCustomer.customerCode, body);
        } else {
          await registerCustomer(body);
        }
        const result = await getCustomerManagement();
        dispatch(setBonsaiAddedCustomers(result.bonsaiAddedCustomers));
      } finally {
        dispatch(closeEditCustomerModal());
      }
    },
    [editCustomer, getCustomerManagement, updateCustomer, registerCustomer],
  );

  const onCancelClick = useCallback(() => {
    dispatch(closeEditCustomerModal());
  }, []);

  /**
   * 極選択時の処理
   */
  const onRegionChange = (data?: { key: string; label: string }) => {
    const region = regions.find((el) => el.regionCode === data?.key);
    if (region) {
      methods.setValue('regionCode', region.regionCode);
      methods.setValue('regionNameJpn', region.regionNameJpn);
      methods.setValue('regionNameEng', region.regionNameEng);
      // 極に紐づく国を絞り込む
      associateCountry(region.regionCode, countryRegions);
    } else {
      methods.setValue('regionCode', '');
      methods.setValue('regionNameJpn', '');
      methods.setValue('regionNameEng', '');
      setIsCountryDisabled(true);
    }
    methods.setValue('country', null); // nullをセットしないとボタンが非活性にならない
    methods.setValue('countryCode', '');
    methods.setValue('countryNameJpn', '');
    methods.setValue('countryNameEng', '');
  };

  /**
   * 極に紐づく国を検索し、選択リスト値に設定
   * @param regionCode 選択された極コード
   * @param countryRegions 国極紐付け情報
   */
  const associateCountry = useCallback(
    (regionCode: string, countryRegions: CountryRegionEntity[]) => {
      // filterで絞り込んでからmapで変換
      const filteredList = countryRegions
        ?.filter((countryRegion) => countryRegion.regionCode === regionCode)
        .map((countryRegion) => countryRegion.countryCode);
      if (filteredList?.length > 0) {
        setCountryOptions(
          countries
            .filter((country) => filteredList.includes(country.countryIsoCode))
            .map<{ key: string; label: string }>((item) => {
              const label = isJapanese ? item.countryNameJpn : item.countryNameEng;
              return { key: item.countryIsoCode, label };
            }),
        );
        setIsCountryDisabled(false);
      } else {
        // 紐づく国がない場合
        enqueueSnackbar(t('message.notExistCountryRegion'), { variant: 'warning' });
        setIsCountryDisabled(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countries],
  );

  useEffect(() => {
    // 親取引先
    const parentCustomerOptions = parentCustomers.map<OptionsType>((item) => {
      // 日本語名が空またはnullの場合は英語名を表示する。逆の場合も同様
      const label = switchLabel(item.labelJpn, item.labelEng, isJapanese);

      return { key: item.customerCode, label };
    });
    setParentCustomerOptions(parentCustomerOptions);

    const parentCustomerKey = editCustomer?.parentCustomerCode ?? '';
    const parentCustomerLabel = parentCustomerOptions.find((el) => el.key === parentCustomerKey)?.label ?? '';
    setParentCustomerDefaultValue({ key: parentCustomerKey, label: parentCustomerLabel });
    const parent = parentCustomers.find((el) => el.customerCode === parentCustomerKey);
    if (parent) {
      methods.setValue('parentCustomer', { key: parentCustomerKey, label: parentCustomerLabel });
      methods.setValue('parent', parent);
    }

    // 極
    const regionOptions = regions.map<OptionsType>((item) => {
      const label = isJapanese ? item.regionNameJpn : item.regionNameEng;
      return { key: item.regionCode, label };
    });
    setRegionOptions(regionOptions);
    const regionKey = editCustomer?.regionCode ?? '';
    const regionLabel = regionOptions.find((el) => el.key === regionKey)?.label ?? '';
    setRegionDefaultValue({ key: regionKey, label: regionLabel });
    const region = regions.find((el) => el.regionCode === regionKey);
    if (region) {
      methods.setValue('region', { key: regionKey, label: regionLabel });
      methods.setValue('regionCode', region.regionCode);
      methods.setValue('regionNameJpn', region.regionNameJpn);
      methods.setValue('regionNameEng', region.regionNameEng);

      // 極に紐づく国を絞り込む
      associateCountry(region.regionCode, countryRegions);
    } else {
      // 極が未選択の場合国を非活性にする
      setIsCountryDisabled(true);
    }
    // 国
    const countryKey = editCustomer?.countryCode ?? '';
    const country = countries.find((el) => el.countryIsoCode === countryKey);
    const countryLabel = isJapanese ? country?.countryNameJpn : country?.countryNameEng;
    setCountryDefaultValue({ key: countryKey, label: countryLabel ?? '' });
    if (country) {
      methods.setValue('country', { key: countryKey, label: countryLabel });
      methods.setValue('countryCode', country.countryIsoCode);
      methods.setValue('countryNameJpn', country.countryNameJpn);
      methods.setValue('countryNameEng', country.countryNameEng);
    }
  }, [associateCountry, countries, countryRegions, editCustomer, isJapanese, methods, parentCustomers, regions]);

  return {
    parentCustomerOptions,
    parentCustomerDefaultValue,
    regionOptions,
    regionDefaultValue,
    countryOptions,
    countryDefaultValue,
    onSaveClick,
    onCancelClick,
    onRegionChange,
    associateCountry,
    isCountryDisabled,
  };
};
