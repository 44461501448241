import { Alert, Chip, FormControl, FormGroup, FormHelperText } from '@material-ui/core';
import { Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack5';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { API_CALL_STATUS } from 'src/api/api-constants';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { checkIfAPIResponseHasError } from 'src/utils/checkIfAPIResponseHasError';
import { useValidator } from '../../../../hooks/useValidator';
import { ENQUETE_THREAD_PATH } from '../../../../routes/path';
import { RHFThreadAutoComplete } from '../../common/components/RHFThreadAutoComplete';
import { RHFThreadRadioGroup } from '../../common/components/RHFThreadRadioGroup';
import RHFThreadTextField from '../../common/components/RHFThreadTextField';
import { MemberListCreateModal } from '../../modal/components/MemberListCreateModal';
import useThreadCreate from '../hooks/useThreadCreate';

export interface ChipData {
  key: string;
  label: string;
  selected: boolean;
}

export const EnqueteThreadCreateLayout = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    selector,
    chipData,
    surveyTitles,
    isClicked,
    handleClick,
    status,
    onSubmit,
    methods,
    isOpenAddModal,
    onCloseModal,
    onOpenModal,
    id,
    thread,
  } = useThreadCreate();
  const { rulesTextFieldTitle, rulesAutoComplete, rulesCategory, rulesRadioPublic, rulesRadioSend, rulesTextFieldContent } = useValidator(methods);
  const hasError = checkIfAPIResponseHasError(status);

  const questionItems = [
    { label: t('enqueteThread.create.public'), id: 'public' },
    { label: t('enqueteThread.create.private'), id: 'private' },
  ];

  const sendMailItems = [
    { label: t('enqueteThread.create.send'), id: 'send' },
    { label: t('enqueteThread.create.notSend'), id: 'notSend' },
  ];

  const pushDisabled = isClicked || !methods.formState.isValid;

  if (status === API_CALL_STATUS.PENDING || status === API_CALL_STATUS.INIT) {
    return <StyledLoading isOpen={true} />;
  }

  if (hasError) {
    enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteThread.create.priorInfo') }), { variant: 'error' });
    navigate(ENQUETE_THREAD_PATH.INDEX);
  }

  if (selector.priorInfo?.surveyTitle?.length === 0 && !hasError) {
    return (
      <Alert severity="info" color="error" variant="outlined" sx={{ mb: 2 }}>
        <Typography>{t('enqueteThread.create.getFailedPriorInfo')}</Typography>
      </Alert>
    );
  }

  if (isClicked) {
    <StyledLoading isOpen={true} />;
  }

  if (thread.title === undefined && id) {
    return <StyledLoading isOpen={true} />;
  }

  return (
    <Grid container direction="column" alignItems="center" justifyContent="space-between" sx={{ height: 'auto', width: '100%', p: 5, pt: 1 }}>
      <Grid component="form" container direction="column" alignItems="flex-start" onSubmit={methods.handleSubmit(onSubmit)} sx={{ width: '100%' }}>
        <RHFThreadTextField
          title={t('validateError.required', { target: t('enqueteThread.create.threadTitle') })}
          name="title"
          control={methods.control}
          rules={rulesTextFieldTitle}
          label={t('enqueteThread.create.threadTitle')}
          defaultValue={thread.title ?? ''}
          multiline={false}
        />
        <RHFThreadAutoComplete
          method={methods}
          rules={rulesAutoComplete}
          title={t('validateError.requiredSelect', { target: t('enqueteThread.create.enqueteTitle') })}
          surveyTitles={surveyTitles}
          label={t('enqueteThread.create.enqueteTitle')}
          defaultValue={thread.surveyDetail?.respondentRequests[0] && { key: 'aa', label: thread.surveyDetail?.respondentRequests[0]?.titleJpn }}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {t('validateError.requiredSelect', { target: t('enqueteThread.create.category') })}
        </Typography>
        <Grid container sx={{ mt: 0 }}>
          <FormControl error={methods.formState.errors?.hasOwnProperty('categories')}>
            <FormGroup>
              <Controller
                name="categories"
                control={methods.control}
                rules={{
                  validate: rulesCategory,
                }}
                render={({ fieldState: { error } }) => (
                  <>
                    <Grid sx={{ margin: 'none' }}>
                      {chipData!.length > 0 &&
                        chipData?.map((chipName) => (
                          <Chip
                            key={chipName.key}
                            label={chipName.label}
                            clickable
                            color={chipName.selected ? 'primary' : 'default'}
                            onClick={() => {
                              handleClick(chipData, chipName);
                            }}
                            sx={{ margin: 1 }}
                          />
                        ))}
                    </Grid>
                    <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
                  </>
                )}
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <RHFThreadRadioGroup
          title={t('validateError.requiredSelect', { target: t('enqueteThread.create.publicClassification') })}
          name="isPrivate"
          questionItems={questionItems}
          rules={rulesRadioPublic}
          buttonName={t('enqueteThread.create.createMember')}
          onClick={onOpenModal}
          defaultValue={thread.isPrivate !== undefined && (thread.isPrivate ? 'private' : 'public')}
          sx={{ display: 'flex' }}
          methods={methods}
          helpMessage={t('enqueteThread.create.publicHelpMessage')}
        />

        <RHFThreadRadioGroup
          title={t('validateError.requiredSelect', { target: t('enqueteThread.create.sendMail') })}
          name="isSendMailToUser"
          questionItems={sendMailItems}
          rules={rulesRadioSend}
          defaultValue={thread.isSendMailToUser !== undefined && (thread.isSendMailToUser ? 'send' : 'notSend')}
          methods={methods}
        />

        <RHFThreadTextField
          title={t('validateError.required', { target: t('enqueteThread.create.threadContent') })}
          name="description"
          control={methods.control}
          rules={rulesTextFieldContent}
          label={t('enqueteThread.create.threadContent')}
          defaultValue={thread.description ?? ''}
          multiline={true}
          rows={5}
        />

        <Grid container justifyContent="flex-end" sx={{ width: '100%' }}>
          <Button color="primary" variant="contained" onClick={methods.handleSubmit(onSubmit)} sx={{ mt: 2 }} disabled={pushDisabled}>
            {t('button.send')}
          </Button>
        </Grid>
      </Grid>
      <MemberListCreateModal open={isOpenAddModal} onClose={onCloseModal} />
    </Grid>
  );
};
