import { NotificationData, NotificationRequestData } from 'src/api/useNotificationApi';

export const useNotificationReRegisterTabContainer = (
  notification: NotificationData,
  reRegisterNotification: (data: NotificationRequestData) => Promise<void>,
) => {
  // 再登録処理
  const handlerReRegister = async (data: NotificationRequestData) => {
    // memo: 英語タブを一度も表示しない場合に各要素はundefinedとなってしまう。その場合は元の値か空を入力する。
    if (data.titleEng === undefined) {
      data.titleEng = notification?.titleEng ? notification?.titleEng : '';
    }
    if (data.contentEng === undefined) {
      data.contentEng = notification?.contentEng ? notification?.contentEng : '';
    }
    await reRegisterNotification(data);
  };

  return { handlerReRegister };
};
