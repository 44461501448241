import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageEntity } from 'src/api/useEnqueteStatusPageApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import useImportExcelValidate from '../../../hooks/useImportExcelValidate';

interface ErrorReportContentEntity {
  errorReport: ErrorMessageEntity[] | [] | string;
  onCloseFunc: () => void;
}

interface GenerateErrorMessageEntity {
  errorReport: ErrorMessageEntity[] | [] | string;
}

/**
 * エラーレポート詳細コンポーネント
 *
 * @param param0
 * @returns
 */
const ErrorReportContent: React.FC<ErrorReportContentEntity> = ({ errorReport, onCloseFunc }) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack>
        {errorReport !== undefined && errorReport.length === 0 ? (
          <Typography variant={'body1'} sx={{ textAlign: 'center' }} children={t('errorReport.message.emptyError')} />
        ) : (
          <GenerateErrorMessage errorReport={errorReport} />
        )}
      </Stack>
      <Stack sx={{ mt: 5 }}>
        <StyledButton onClick={onCloseFunc} children={t('button.close')} />
      </Stack>
    </>
  );
};

export default ErrorReportContent;

/**
 * エラー内容出力コンポーネント
 *
 * @param param0
 * @returns
 */
const GenerateErrorMessage: React.FC<GenerateErrorMessageEntity> = ({ errorReport }) => {
  const validateHooks = useImportExcelValidate();

  //エラーメッセージ取得
  const errorMessageList = validateHooks.getErrorMessageList(errorReport);

  //スタイル
  const styles = {
    px: 3,
    py: 2,
    overflowY: 'scroll',
    maxHeight: '30ch',
  };

  return (
    <Box sx={styles}>
      {errorMessageList.map((v, i) => (
        <Typography key={i} variant={'body1'} children={v} />
      ))}
    </Box>
  );
};
