import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useBlocker } from 'react-router-dom';

interface ConfirmDialogProps {
  title: string;
  messages: string[];
  when: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, messages, when = true }) => {
  const blocker = useBlocker(when);

  return (
    <Dialog open={blocker.state === 'blocked'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {messages.map((message, i) => (
          <DialogContentText id="alert-dialog-description" key={`block-confirm-form-${i}`}>
            {message}
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => blocker.reset?.()}>CANCEL</Button>
        <Button onClick={() => blocker.proceed?.()} variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
