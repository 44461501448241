import { Grid, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { GeneralEnquete, Question, QuestionState } from 'src/@types/generalEnquete';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { setHasError } from '../../../../../../../store/enqueteGeneralAnswerSlice';
import { PreviousAnswersList } from '../../../../../common/components/previous-answers-list/components/PreviousAnswersList';
import EnqueteAnswerGeneralQuestionFormSwitcher from './EnqueteAnswerGeneralQuestionFormSwitcher';
import { EnqueteAnswerDescriptionBox } from './common/EnqueteAnswerDescriptionBox';

const EnqueteAnswerGeneralQuestionFormTab: React.FC = () => {
  const methods = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();

  const { currentLang } = useLocales();

  const surveyDetailData = useAppSelector((stateGeneral) => stateGeneral.enqueteAnswer.surveyDetailData);
  const questionStates: QuestionState[] = useAppSelector((stateGeneral) => stateGeneral.enqueteGeneralAnswer.questionStates);
  const questions: Question[] = useAppSelector((stateGeneral) => stateGeneral.enqueteGeneralAnswer.questions);
  const memolizedEnqueteFormData = useMemo(() => surveyDetailData.surveyFormData.formData.tabData[0], [surveyDetailData]) as GeneralEnquete;
  const questionDescription =
    currentLang.value === 'ja' ? memolizedEnqueteFormData.questionDescriptionJpn : memolizedEnqueteFormData.questionDescriptionEng;

  // アンケート回答入力時のバリデーション状態を保存しておく
  useEffect(() => {
    dispatch(setHasError(!methods.formState.isValid));
  }, [methods.formState.isValid, dispatch]);

  return (
    <Stack component="form" sx={{ width: '100%' }}>
      <Grid container direction="column" justifyContent="flex-start">
        {/* ToDo: アンケート説明は独自のステートなので分ける */}
        <Grid item xs={12} md={12}>
          {questionDescription && questionDescription !== '' ? <EnqueteAnswerDescriptionBox questionDescription={questionDescription} /> : null}
        </Grid>
        <PreviousAnswersList />
        {/* ToDo: ここはステートダウンしてもいいはず */}
        <Grid item xs={12} md={12}>
          {questions !== undefined &&
            questions.length > 0 &&
            questions.map((question, index: number) => {
              return (
                <EnqueteAnswerGeneralQuestionFormSwitcher
                  methods={methods}
                  question={question}
                  index={index}
                  key={question.id}
                  questionStates={questionStates}
                />
              );
            })}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EnqueteAnswerGeneralQuestionFormTab;
