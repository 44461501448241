import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { StyledBox } from 'src/components/app-components/StyledBox';
import useLocales from 'src/hooks/useLocales';
import { getFullOrganizationString } from 'src/utils/getOrganizationString';
import { createRespondentTargetOrgData } from '../utils/createRespondentTargetOrgData';

type CurrentRespondentTargetOrgBoxProps = {
  respondentTarget: RespondentsListEntity;
};

export const CurrentRespondentTargetOrgBox = ({ respondentTarget }: CurrentRespondentTargetOrgBoxProps) => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const currentRespondentTargetOrg = createRespondentTargetOrgData(respondentTarget, isLangJpn);

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <StyledBox>
        <Grid container sx={{ width: 'auto' }}>
          <Grid item xs={2}>
            {t('enqueteCreate.changeRespondentConfirmationMordal.textfield.organizationBeforeUpdate')}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1" noWrap={false} sx={{ overflow: 'hidden', wordBreak: 'break-word', ml: 2, mr: 2 }}>
              {getFullOrganizationString(currentRespondentTargetOrg)}
            </Typography>
          </Grid>
        </Grid>
      </StyledBox>
    </Box>
  );
};
