import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Condition, Logic, Question } from 'src/@types/generalEnquete';
import { setLogicQuestions } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { dispatch, RootState, useSelector } from 'src/redux/store';

export const useLogicData = () => {
  const logicQuestions: Question[] = useSelector((state: RootState) => state.enqueteCreate.logicQuestions);
  const { t } = useTranslation();

  /**
   * ロジック条件（ロジック、設定対象質問）を変更した際のハンドラー
   * @param event
   * @param questionId
   * @param optionId
   * @param type
   */
  const handleConditionChange = (event: SelectChangeEvent, questionId: string, optionId: string, type: 'logic' | 'target-question') => {
    const value = event.target.value;
    const newQuestion: Question = JSON.parse(JSON.stringify(logicQuestions.find((question) => question.id === questionId)));
    if (newQuestion.conditions === undefined) {
      newQuestion.conditions = [];
    }
    const newCondition = newQuestion.conditions.find((condition) => condition.id === optionId);
    if (newCondition) {
      if (type === 'logic') {
        if (value !== '' || (value === '' && newCondition.targetQuestionId !== '')) {
          newCondition.logic = value as Logic;
        } else if (value === '' && newCondition.targetQuestionId === '') {
          // ロジックと質問が空になったらロジック条件を削除
          newQuestion.conditions = newQuestion.conditions.filter((condition) => {
            return condition.id !== optionId;
          });
        }
      } else if (type === 'target-question') {
        if (value !== '' || (value === '' && newCondition.logic !== '')) {
          newCondition.targetQuestionId = value;
        } else if (value === '' && newCondition.logic === '') {
          // ロジックと質問が空になったらロジック条件を削除
          newQuestion.conditions = newQuestion.conditions.filter((condition) => {
            return condition.id !== optionId;
          });
        }
      }
    } else {
      if (type === 'logic') {
        newQuestion.conditions.push({
          id: optionId,
          logic: value as Logic,
          targetQuestionId: '',
          questionOptionId: optionId,
        });
      } else if (type === 'target-question') {
        newQuestion.conditions.push({
          id: optionId,
          targetQuestionId: value,
          questionOptionId: optionId,
          logic: '',
        });
      }
    }
    updateCondition(questionId, newQuestion.conditions);
  };

  /**
   * 設定したロジック条件を、 グローバルステートlogicQuestions に設定する
   * @param questionId　設定対象の質問ID
   * @param conditions　設定た仕様のロジック条件
   */
  const updateCondition = (questionId: string, conditions: Condition[]) => {
    const newQuestions = [
      ...logicQuestions.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            conditions: conditions,
          };
        } else {
          return question;
        }
      }),
    ];
    // memo:　ロジック設定用の一時保存questionsに設定しておく
    //　setQuestions(newQuestions)をやってしまうと、ロジック設定ダイアログを閉じた場合に編集中のロジック条件が残ってしまうため。
    dispatch(setLogicQuestions(newQuestions));
  };

  /**
   * ロジックの入力チェックを行う
   *
   * @returns チェックエラーの場合のエラーメッセージの配列　エラーなしの場合は空配列
   */
  const checkQuestionConditionErrors = () => {
    const errorMessages: string[] = [];

    logicQuestions.forEach((question, index) => {
      const questionNo = index + 1;
      question.conditions?.forEach((condition: Condition) => {
        const { targetQuestionId, logic } = condition;

        // ロジックが設定されていない（ターゲットの質問だけ設定されている）状態の場合はエラー
        if (logic === '') {
          errorMessages.push(t('enqueteCreate.logic.errorMessage.logicCheckNoLogicError', { questionNo }));
        }
        // ターゲットの質問がが設定されていない（ロジックだけ設定されている）状態の場合はエラー
        if (targetQuestionId === '') {
          errorMessages.push(t('enqueteCreate.logic.errorMessage.logicCheckNoQuestionError', { questionNo }));
        }
      });
    });

    return errorMessages;
  };

  /**
   * 自分より後の質問を取得する
   * @param questionId　設定対象の質問ID
   * @returns 自分より後の質問の配列
   */
  const getAfterQuestions = (questionId: string) => {
    const index = logicQuestions.findIndex((question) => question.id === questionId);
    return logicQuestions.slice(index + 1);
  };

  return {
    handleConditionChange,
    checkQuestionConditionErrors,
    updateCondition,
    getAfterQuestions,
  };
};
