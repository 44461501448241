import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { NotificationCreateLayout } from '../components/pages/create/components/NotificationCreateLayout';

export const NotificationCreatePage = () => {
  const { t } = useTranslation();
  const pageTitle = t('notifications.createPage.pageTitle');

  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('notifications.createPage.pageTitle'),
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('notifications.editListPage.pageTitle'), href: '/manage/notification/edit' },
    ],
    activeLast: true,
  };

  return <NotificationCreateLayout pageTitle={pageTitle} breadCrumbs={breadcrumbs} />;
};
