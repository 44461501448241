import { Auth } from 'aws-amplify';
import axios from 'axios';
import { apiGateway } from 'src/config';
import { Permission, StatusType } from './api-constants';

export interface Category {
  id: string;
  nameJpn: string;
  nameEng: string;
}

export interface EnqueteThread {
  id: string;
  title: string;
  description: string;
  isSolved: boolean;
  isPrivate: boolean;
  isSendMailToUser: boolean;
  updatedAt: string | null;
  visitedAt: string | null;
  permission: Permission;
  creator: {
    userId: string;
    userNameEng: string;
    userNameJpn: string;
    companyNameJpn: string;
    companyNameEng: string;
    divisionNameJpn: string;
    divisionNameEng: string;
    sbuNameJpn: string;
    sbuNameEng: string;
    departmentNameJpn: string;
    departmentNameEng: string;
    groupNameJpn: string;
    groupNameEng: string;
  };
  categories: Category[];
  commentCount: number;
}
export interface PaginatedEnqueteThread {
  enqueteThreads: EnqueteThread[];
  total: number;
}

export interface EnqueteThreadsEntity {
  threads: EnqueteThread[];
  status: StatusType;
}

export interface RespondentRequests {
  surveyDetailId: string;
  titleJpn: string;
  titleEng: string;
}

export interface ThreadUser {
  userId: string;
  bluePageEmailAddress: string;
}

interface ThreadCategory {
  category: Category;
}

export interface EnqueteThreadEntity {
  id: string;
  title: string;
  surveyDetailId: string;
  description: string;
  isSolved: boolean;
  isPrivate: boolean;
  isSendMailToUser: boolean;
  createdBy: string;
  updatedAt: string;
  surveyDetail: {
    respondentRequests: RespondentRequests[];
  };
  threadUsers: ThreadUser[];
  threadCategories: ThreadCategory[];
}

export interface Pagination {
  page: number;
  take: number;
}

export const getThreadsApi = () => {
  const getBearerToken = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return `Bearer ${token}`;
  };

  const getThreads = async (page: number, take: number) => {
    const token = await getBearerToken();
    const response = await axios.get<PaginatedEnqueteThread>(apiGateway + `/enquete-threads/list?page=${page}&take=${take}`, {
      headers: {
        Authorization: token,
      },
      timeout: 60000,
    });
    return response.data;
  };

  const getThreadById = async (threadId: string) => {
    const token = await getBearerToken();
    const response = await axios.get<EnqueteThreadEntity>(apiGateway + `/enquete-threads/${threadId}`, {
      headers: {
        Authorization: token,
      },
      timeout: 60000,
    });
    return response.data;
  };
  return { getThreads, getThreadById };
};
