import { Box, Button, IconButton, Stack, Tooltip } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import 'ag-grid-community/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OverviewDivisionAggregateSupplement } from 'src/@types/overviewAggregateSupplement';
import { TARGET_OLG_LEVEL_TYPE } from 'src/@types/overviewEnquete';
import Label from 'src/components/Label';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { REQUEST_STATUS } from 'src/constants';
import { useAppSelector } from 'src/redux/store';
import { FormValues, useDivisionTab } from '../hooks/useDivisionTab';
import { useDivisionTabColdef } from '../hooks/useDivisionTabColdef';
export interface DivisionTabProps {
  aggregateSupplement: OverviewDivisionAggregateSupplement;
}
export const DivisionTab: React.FC<DivisionTabProps> = ({ aggregateSupplement }) => {
  const { t } = useTranslation();
  const { control, getValues, setValue, handleSubmit, trigger } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { defaultColDef, colDef, gridOptions } = useDivisionTabColdef(aggregateSupplement);
  const { onClickReflectKeyTopics, onGridReady, onClickTemporarilySave, onClickDone, isSaving } = useDivisionTab(getValues, setValue, trigger);
  const { isAdmin, isSurveyOwner, allowWriteDivisions } = useAppSelector((state) => state.overviewAggregateSupplement);
  const { requestStatus } = useAppSelector((state) => state.enqueteStatus);
  const notAllowWrite = !(isAdmin || isSurveyOwner || allowWriteDivisions.includes(aggregateSupplement.divisionCode));
  const isButtonDisabled = requestStatus !== REQUEST_STATUS.IN_PROGRESS || notAllowWrite;
  const isCompany = aggregateSupplement?.targetOrgLevelType === TARGET_OLG_LEVEL_TYPE.COMPANY;

  const renderWarningIcon = () => {
    if (requestStatus === REQUEST_STATUS.IN_PROGRESS && notAllowWrite) {
      return (
        <Tooltip title={t('enqueteStatus.aggregateSupplement.warning.notAllowWrite') ?? ''}>
          <IconButton>
            <InfoIcon color="warning" />
          </IconButton>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    setValue('divisionData', aggregateSupplement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction={'column'} spacing={2}>
      <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
        <Label variant="ghost" color="info" sx={{ lineHeight: 'normal', whiteSpace: 'normal', height: 'auto', p: '10px' }}>
          {t('enqueteStatus.aggregateSupplement.description.aggregationUnit', {
            unit: isCompany ? t('common.companyName') : t('common.sbuName'),
          })}
        </Label>
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
          {renderWarningIcon()}
          <Button color="primary" variant="contained" onClick={onClickReflectKeyTopics} disabled={isButtonDisabled}>
            {t('enqueteStatus.aggregateSupplement.button.reflectKeyTopics')}
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit(onClickTemporarilySave)} disabled={isButtonDisabled || isSaving}>
            {t('button.temporarilySave')}
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit(onClickDone)} disabled={isButtonDisabled || isSaving}>
            {t('button.done')}
          </Button>
        </Stack>
      </Stack>
      <Box sx={{ height: '400px', width: '100%' }}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={colDef}
          gridOptions={gridOptions}
          rowData={aggregateSupplement.companyAggregateSupplements}
          rowMultiSelectWithClick={true}
        />
      </Box>
      <RHFTextField
        name="topics"
        label={t('common.topics')}
        control={control}
        rows={12}
        multiline={true}
        fullWidth
        defaultValue={aggregateSupplement.topic}
        placeholder={t('validateError.required', { target: t('common.topics') })}
        rules={{
          required: t('validateError.required', { target: t('common.topics') }),
        }}
      />
    </Stack>
  );
};
