import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface GradeEntity {
  gradeJobTitleCode: string;
  gradeJobTitleNameJpn: string;
  gradeJobTitleNameEng: string;
}

interface PortalGradeEntity {
  job_title_cd: string; // 役職資格コード
  job_title_name: string; // 役職資格表示名（M1, M2など）
  job_title_eng_name: string; // 役職資格英語名
}

const BASE_URL = `${portalApi}/master/grade`;

// memo: GradeMaster には、S500の等級情報のみ格納されているため、利用時は注意
export const useGradeApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 等級マスタの内、役職資格のみをDistinctして取得します
   * @returns
   */
  const getAllGradeJobTitles = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalGradeEntity[]>(BASE_URL, {
        headers: { Authorization: token },
        params: {
          filter: {
            where: { NOT: { job_title_cd: '' } },
            distinct: ['job_title_cd', 'job_title_name', 'job_title_eng_name'],
            select: { job_title_cd: true, job_title_name: true, job_title_eng_name: true },
            orderBy: [{ job_title_cd: 'asc' }],
          },
        },
      });

      return result.data.map<GradeEntity>((master) => ({
        gradeJobTitleCode: master.job_title_cd,
        gradeJobTitleNameJpn: master.job_title_name,
        gradeJobTitleNameEng: master.job_title_eng_name,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useGradeApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getAllGradeJobTitles };
};
