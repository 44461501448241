import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { useEnqueteDescriptionTab } from '../hooks/useEnqueteDescriptionTab';
import { EnqueteDescriptionInput } from './EnqueteDescriptionInput';
import { EnqueteDescriptionPreviewBox } from './EnqueteDescriptionPreviewBox';

export const EnqueteDescriptionForm: React.FC = () => {
  const { t } = useTranslation();
  const { tabNumber, handleTabClick } = useEnqueteDescriptionTab();

  return (
    <StyledBox>
      <Grid item xs={12} md={12}>
        <TabContext value={tabNumber}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabClick} aria-label="descripton-tab">
              <Tab label={t('enqueteCreate.questionDescriptionJpn')} value="1" />
              <Tab label={t('enqueteCreate.questionDescriptionEng')} value="2" />
              <Tab label={t('enqueteCreate.preview')} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EnqueteDescriptionInput formLabel="questionDescriptionJpn" />
          </TabPanel>
          <TabPanel value="2">
            <EnqueteDescriptionInput formLabel="questionDescriptionEng" />
          </TabPanel>
          <TabPanel value="3">
            <EnqueteDescriptionPreviewBox />
          </TabPanel>
        </TabContext>
      </Grid>
    </StyledBox>
  );
};
