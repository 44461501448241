import { Settings } from 'handsontable/plugins/nestedHeaders/nestedHeaders';
import { GridSettings } from 'handsontable/settings';
import { useCallback, useRef } from 'react';
import { RootState, useSelector } from 'src/redux/store';

export const useNestedHeadersHistory = () => {
  const nestedHeadersHistory = useRef<Settings[]>([[]]);
  const currentHistoryIndex = useRef(0);
  const isUndoRedoAction = useRef(false);

  // ストア
  const totalMainTables = useSelector((state: RootState) => state.tableSetting.totalMainTables);
  const selectedTableIndex = useSelector((state: RootState) => state.tableSetting.selectedTableIndex);

  // memo: storedNestedHeaders が更新されたタイミング(フック再実行のタイミング)で履歴をリセットする
  const storedNestedHeaders = totalMainTables[selectedTableIndex]?.nestedHeaders ?? [];
  if (storedNestedHeaders.length > 0) {
    nestedHeadersHistory.current = [[]];
    currentHistoryIndex.current = 0;
  }

  const initNestedHeadersHistory = useCallback((nestedHeaders: Settings) => {
    if (nestedHeaders && nestedHeadersHistory.current[0].length === 0) {
      nestedHeadersHistory.current[0] = nestedHeaders;
    }
  }, []);

  const updateNestedHeadersHistory = useCallback((nestedHeaders: Settings) => {
    if (isUndoRedoAction.current) {
      return;
    }
    if (!nestedHeaders) return;
    nestedHeadersHistory.current = nestedHeadersHistory.current.slice(0, currentHistoryIndex.current + 1);
    nestedHeadersHistory.current.push(nestedHeaders);
    currentHistoryIndex.current = nestedHeadersHistory.current.length - 1;
  }, []);

  const setIsUndoRedoAction = (value: boolean) => {
    isUndoRedoAction.current = value;
  };

  const applyNestedHeaders = useCallback((action: 'redo' | 'undo', callBack: (settings: GridSettings, init?: boolean | undefined) => void) => {
    if (action === 'undo' && currentHistoryIndex.current > 0) {
      currentHistoryIndex.current = currentHistoryIndex.current - 1;
      callBack({ nestedHeaders: nestedHeadersHistory.current[currentHistoryIndex.current] });
    }

    if (action === 'redo' && currentHistoryIndex.current < nestedHeadersHistory.current.length - 1) {
      currentHistoryIndex.current += 1;
      callBack({ nestedHeaders: nestedHeadersHistory.current[currentHistoryIndex.current] });
    }
  }, []);

  return {
    initNestedHeadersHistory,
    updateNestedHeadersHistory,
    setIsUndoRedoAction,
    applyNestedHeaders,
  };
};
