import { HotTable } from '@handsontable/react';
import { Box } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { FORM_TYPE } from 'src/constants';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { useAppSelector } from 'src/redux/store';
import { EnqueteAnswerTypeGeneralQuestionTabContainer } from '../../../tab-containers/type-general-question/components/tabs/question-and-answer/components/EnqueteAnswerTypeGeneralQuestionTabContainer';
import { EnqueteAnswerTypeGeneralTableTabContainer } from '../../../tab-containers/type-general-table/components/EnqueteAnswerTypeGeneralTableTabContainer';
import { EnqueteAnswerGeneralQuestionFloatingSaveButtons } from './header-box/components/EnqueteAnswerGeneralQuestionFloatingSaveButtons';
import { EnqueteAnswerHeaderBox } from './header-box/components/EnqueteAnswerHeaderBox';

export const GeneralFormTypeSwitcher: React.FC = () => {
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);

  const answerHotRef = useMemo(() => {
    return new Array(TABLE_SETTINGS.MAX_TOTAL_TABLE_LENGTH).fill(null).map(() => React.createRef<HotTable>());
  }, []);
  const answerHotRefForSubSheet = useMemo(() => {
    return new Array(TABLE_SETTINGS.SUB.MAX_LENGTH).fill(null).map(() => React.createRef<HotTable>());
  }, []);

  const targetRef = useRef<HTMLDivElement>(null);

  const formType = surveyDetailData.surveyHeader?.formType;
  switch (formType) {
    case FORM_TYPE.QA:
      return (
        <>
          <Box ref={targetRef}>
            <EnqueteAnswerHeaderBox formType={FORM_TYPE.QA} />
          </Box>
          <EnqueteAnswerTypeGeneralQuestionTabContainer />
          <EnqueteAnswerGeneralQuestionFloatingSaveButtons targetRef={targetRef} />
        </>
      );
    case FORM_TYPE.TABLE:
      return (
        <>
          <EnqueteAnswerHeaderBox formType={formType ?? ''} answerHotRef={answerHotRef} answerHotRefForSubSheet={answerHotRefForSubSheet} />
          <EnqueteAnswerTypeGeneralTableTabContainer answerHotRef={answerHotRef} answerHotRefForSubSheet={answerHotRefForSubSheet} />
        </>
      );
    default:
      return null;
  }
};
