import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { SurveyEditEntity } from 'src/api/useSurveyEditApi';
import { useUserAgGridColDef } from 'src/components/app-components/bonsai/hooks/useUserAgGridColDef';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { useRank } from 'src/hooks/useRank';
import useStatus from 'src/hooks/useStatus';
import { formatterDateTime } from 'src/utils/formatDateTime';
import { switchLabel } from 'src/utils/labelUtils';

const useEnqueteCreateAddressColDef = () => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const { toStringAnswerStatus } = useStatus();
  const isLangJpn = currentLang.value === 'ja';
  const { complementRank } = useRank();

  const { userAgGridColDef } = useUserAgGridColDef({
    companyCodeColName: 'personCompanyCode',
    emailColDefSetting: { key: 'email' },
    fullNameColDefSetting: { key: 'userName' },
    companyNameColDefSetting: { key: 'personCompanyName' },
    companyAbbreviationColDefSetting: { key: 'personCompanyAbbreviation' },
    assignedCompanyColDefSetting: { key: 'assignedCompanyName' },
    divisionNameColDefSetting: { key: 'personDivisionName', columnGroupShowValue: 'open' },
    sbuNameColDefSetting: { key: 'personSbuName', columnGroupShowValue: 'open' },
    departmentNameColDefSetting: { key: 'personDepartmentName', columnGroupShowValue: 'open' },
    groupNameColDefSetting: { key: 'personGroupName', columnGroupShowValue: 'open' },
  });

  const convertTargetOrgIsDeleted = (params: ValueGetterParams<SurveyEditEntity>) => {
    let cellValue: string = '';
    if (params.data?.targetOrgIsDeleted) cellValue = t('enqueteCreate.respondentGridColDef.targetOrgIsDeleted');
    return cellValue;
  };
  const convertRespondentPersonIsDisalbeText = (params: ValueGetterParams<SurveyEditEntity>) => {
    let cellValue: string = '';
    if (params.data?.respondentPersonIsDisabled) cellValue = t('enqueteCreate.respondentGridColDef.respondentPersonIsDisabled');
    return cellValue;
  };

  const defaultColDef: ColDef<SurveyEditEntity> = {
    width: 100,
    resizable: true,
    filter: true,
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true,
    },
    flex: 0,
  };

  //ベースとなるColDef
  const colDef: (ColDef<SurveyEditEntity> | ColGroupDef<SurveyEditEntity>)[] = [
    {
      field: 'answerStatus',
      headerName: t('gridColumnHeaderName.answerStatus'),
      width: 120,
      valueGetter: (params: ValueGetterParams<SurveyEditEntity>) => {
        return toStringAnswerStatus(params.data?.answerStatus);
      },
      sortable: true,
    },
    {
      headerName: t('enqueteCreate.respondentGridColDef.targetGroupName'),
      children: [
        {
          field: 'companyCode',
          headerName: t('enqueteCreate.respondentGridColDef.companyCode'),
          columnGroupShow: 'open',
          sortable: true,
        },
        {
          field: 'rank',
          headerName: t('enqueteCreate.respondentGridColDef.rank'),
          columnGroupShow: 'open',
          valueGetter: (params: ValueGetterParams) => {
            return complementRank(params.data.companyCode, params.data.rank);
          },
          sortable: true,
        },
        {
          field: 'targetOrgIsDeleted',
          headerName: t('enqueteCreate.respondentGridColDef.targetOrgIsDeleted'),
          valueGetter: convertTargetOrgIsDeleted,
          sortable: true,
        },
        {
          field: isLangJpn ? 'targetCompanyNameJpn' : 'targetCompanyNameEng',
          tooltipValueGetter: (params) => switchLabel(params.data?.targetCompanyNameJpn ?? '', params.data?.targetCompanyNameEng ?? '', isLangJpn),
          headerName: t('enqueteCreate.respondentGridColDef.targetCompanyName'),
          width: 250,
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.targetCompanyNameJpn ?? '', params.data?.targetCompanyNameEng ?? '', isLangJpn),
        },
        {
          field: 'targetCompanyAbbreviation',
          tooltipField: 'targetCompanyAbbreviation',
          headerName: t('common.companyAbbreviation'),
          width: 150,
          sortable: true,
        },
        {
          field: isLangJpn ? 'targetDivisionNameJpn' : 'targetDivisionNameEng',
          tooltipValueGetter: (params) => switchLabel(params.data?.targetDivisionNameJpn ?? '', params.data?.targetDivisionNameEng ?? '', isLangJpn),
          headerName: t('enqueteCreate.respondentGridColDef.targetDivisionName'),
          width: 150,
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.targetDivisionNameJpn ?? '', params.data?.targetDivisionNameEng ?? '', isLangJpn),
        },
        {
          field: isLangJpn ? 'targetSbuNameJpn' : 'targetSbuNameEng',
          tooltipValueGetter: (params) => switchLabel(params.data?.targetSbuNameJpn ?? '', params.data?.targetSbuNameEng ?? '', isLangJpn),
          headerName: t('enqueteCreate.respondentGridColDef.targetSbuName'),
          width: 150,
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.targetSbuNameJpn ?? '', params.data?.targetSbuNameEng ?? '', isLangJpn),
        },
        {
          field: isLangJpn ? 'targetDepartmentNameJpn' : 'targetDepartmentNameEng',
          tooltipValueGetter: (params) =>
            switchLabel(params.data?.targetDepartmentNameJpn ?? '', params.data?.targetDepartmentNameEng ?? '', isLangJpn),
          headerName: t('enqueteCreate.respondentGridColDef.targetDepartmentName'),
          width: 150,
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.targetDepartmentNameJpn ?? '', params.data?.targetDepartmentNameEng ?? '', isLangJpn),
        },
        {
          field: isLangJpn ? 'targetGroupNameJpn' : 'targetGroupNameEng',
          tooltipValueGetter: (params) => switchLabel(params.data?.targetGroupNameJpn ?? '', params.data?.targetGroupNameEng ?? '', isLangJpn),
          headerName: t('organization.names.groupName'),
          width: 150,
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.targetGroupNameJpn ?? '', params.data?.targetGroupNameEng ?? '', isLangJpn),
        },
        {
          field: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
          tooltipValueGetter: (params) => switchLabel(params.data?.regionNameJpn ?? '', params.data?.regionNameEng ?? '', isLangJpn),
          headerName: t('common.region'),
          columnGroupShow: 'open',
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.regionNameJpn ?? '', params.data?.regionNameEng ?? '', isLangJpn),
        },
        {
          field: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
          tooltipValueGetter: (params) => switchLabel(params.data?.countryNameJpn ?? '', params.data?.countryNameEng ?? '', isLangJpn),
          headerName: t('common.country'),
          columnGroupShow: 'open',
          sortable: true,
          valueGetter: (params) => switchLabel(params.data?.countryNameJpn ?? '', params.data?.countryNameEng ?? '', isLangJpn),
        },
      ],
    },
    {
      headerName: t('enqueteCreate.respondentGridColDef.respondentGroupName'),
      children: [
        {
          field: 'respondentPersonIsDisabled',
          headerName: t('enqueteCreate.respondentGridColDef.respondentPersonIsDisabled'),
          valueGetter: convertRespondentPersonIsDisalbeText,
          sortable: true,
        },
        ...userAgGridColDef,
      ],
    },
    {
      field: 'sendedAt',
      headerName: t('enqueteCreate.respondentGridColDef.sendedAt'),
      valueGetter: (params) => {
        if (params.data?.sendedAt) return formatterDateTime(params.data.sendedAt);
        return '-';
      },
      tooltipValueGetter: (params) => {
        if (params.data?.sendedAt) return formatterDateTime(params.data.sendedAt);
        return '-';
      },
      width: 200,
      sortable: true,
    },
  ];

  //依頼先タブ用グリッドColDef
  const surveyEditGridColDef: (ColDef | ColGroupDef)[] = (() => {
    let coldef = [...colDef];

    //先頭にチェックボックスを追加
    coldef.unshift({
      field: 'checkBox',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      minWidth: 30,
      width: 50,
      suppressMenu: true,
      filter: false,
    });

    //最後尾に三点ドットを追加
    coldef.push({
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    });

    return coldef;
  })();

  return { defaultColDef, colDef, surveyEditGridColDef };
};

export default useEnqueteCreateAddressColDef;
