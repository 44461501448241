import { SyntheticEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TableType } from 'src/@types/generalEnquete';

export const TAB_VALUE = {
  TABLE: 'Table',
  CONFIRM: 'EnqueteStatus',
  CHANGE: 'ChangeRespondents',
} as const;
export type TabValue = typeof TAB_VALUE[keyof typeof TAB_VALUE];

export const useTypeGeneralTableTabContainer = (saveAllSettingsCallback: () => TableType) => {
  const params = useLocation().search;

  // URLに含まれるTabパラメータを取得
  const tabParamsValue = new URLSearchParams(params).get('tab');
  const defaultTab = tabParamsValue ? tabParamsValue : TAB_VALUE.TABLE;
  const [tabValue, setTabValue] = useState<TabValue>(defaultTab as TabValue);

  // タブ移動ハンドラー
  const handleTabChanged = (_: SyntheticEvent, newValue: TabValue) => {
    if (tabValue === TAB_VALUE.TABLE) saveAllSettingsCallback();
    setTabValue(newValue);
  };

  return { handleTabChanged, tabValue, TAB_VALUE };
};
