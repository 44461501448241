import HotTable from '@handsontable/react';
import { RefObject, useEffect } from 'react';
import { ColWidths, MergeCells, NestedHeader, RowHeights, TableData } from 'src/@types/generalEnquete';
import { initialSettings } from 'src/components/app-components/bonsai/type-general-table/constants/initialSettings';
import { RootState, useSelector } from 'src/redux/store';
import { calcRowHeaderWidth, createTableInitialData } from 'src/utils/handsontable';

export const useNewTableData = (hotRef: RefObject<HotTable>[]) => {
  const totalMainTables = useSelector((state: RootState) => state.tableSetting.totalMainTables);

  /**
   * HotTableインスタンスに必要なデータを定義し初期化を実施
   */
  useEffect(() => {
    // メイン表のインタンス初期化−保存データがあればそのデータで定義
    const loadDataMainTable = (
      hr: RefObject<HotTable>,
      srcData: TableData,
      cellData: any[],
      mrgCells: MergeCells,
      cWidths: ColWidths,
      rHeights: RowHeights,
      ntdHeaders: (string | NestedHeader)[][] | undefined,
      rHeaders: string[] | undefined,
      fixCol: number | undefined,
      fixRow: number | undefined,
    ) => {
      if (hr.current) {
        const dataForLoad = srcData.map((row) => {
          return row.map((cell) => {
            return cell ?? '';
          });
        });

        hr.current.hotInstance?.loadData(dataForLoad);

        // create new cell array for current table from cell array for all table
        const newCell = cellData.length > 0 ? JSON.parse(JSON.stringify(cellData)) : [];
        // change readOnly for current table
        newCell.forEach((cell: any) => {
          cell.readOnly = undefined;
        });

        // 画面表示は、readOnly = falseにする。
        // set cell settings
        hr.current.hotInstance?.updateSettings({
          ...initialSettings,
          cell: newCell,
          mergeCells: mrgCells,
          rowHeights: rHeights,
          colWidths: Array.isArray(cWidths) && 0 < cWidths.length ? cWidths : 100,
          nestedHeaders: Array.isArray(ntdHeaders) && 0 < ntdHeaders.length ? ntdHeaders : undefined,
          rowHeaders: Array.isArray(rHeaders) ? rHeaders : true,
          rowHeaderWidth: calcRowHeaderWidth(rHeaders),
          fixedColumnsStart: fixCol,
          fixedRowsTop: fixRow,
        });
      }
    };

    // 初回実行
    if (totalMainTables.length === 0) {
      hotRef[0].current?.hotInstance?.loadData(createTableInitialData(5, 5));
      hotRef[0].current?.hotInstance?.updateSettings({ ...initialSettings });
    } else {
      // totalMainTablesに保存されたデータを各HotTableに定義
      totalMainTables.forEach((tableTypeData, index) => {
        if (tableTypeData.sourceData.every((el) => el.length === 0)) {
          hotRef[index].current?.hotInstance?.loadData(createTableInitialData(5, 5));
          hotRef[index].current?.hotInstance?.updateSettings({ ...initialSettings });
        } else {
          loadDataMainTable(
            hotRef[index],
            tableTypeData.sourceData,
            tableTypeData.cell,
            tableTypeData.mergeCells,
            tableTypeData.colWidths,
            tableTypeData.rowHeights,
            tableTypeData.nestedHeaders,
            tableTypeData.rowHeaders,
            tableTypeData.fixedColumnsStart,
            tableTypeData.fixedRowsTop,
          );
        }
      });
    }
  }, [totalMainTables]); // eslint-disable-line
};
