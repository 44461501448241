import { Avatar } from '@mui/material';

type ThreadAvatarProps = {
  userName: string | undefined;
  textSize?: string;
};

export const ThreadAvatar = ({ userName, textSize = '16px' }: ThreadAvatarProps) => {
  const avatarString = userName ? userName.replaceAll(/[　 ]/g, '').substring(0, 2) : undefined;
  return <Avatar sx={{ fontSize: textSize }}>{avatarString}</Avatar>;
};
