import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SurveyInformationEntity } from 'src/@types/generalEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import RHFDatePickers from 'src/components/react-hook-form/RHFDatePickers';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { FORM_TYPE, REQUEST_STATUS, RequestStatus, SURVEY_TYPE } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { RootState, useAppSelector } from 'src/redux/store';

//依頼期間コンポーネントエンティティ
type RequestStatusEditProps = {
  enqueteData: SurveyInformationEntity | RespondentRequestEntity;
  methods: UseFormReturn;
  requestStatus?: RequestStatus;
};

export const RespondentRequestForm: React.FC<RequestStatusEditProps> = (props) => {
  const { enqueteData, methods, requestStatus } = props;
  const { t } = useTranslation();
  const { rulesTitle, dateRules1, dateRules2 } = useValidator(methods);
  const isGeneral = 'type' in enqueteData && enqueteData.type === SURVEY_TYPE.GENERAL;
  const formType = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData.surveyDetail.surveyHeader?.formType);
  const isOverview = formType && FORM_TYPE.OVERVIEW === formType;
  // memo: 登録されている依頼開始日時が過去日の場合にinvalidになってしまうためRulesを制御
  let isChangedOpenDate = false;
  // 依頼開始日時のRulesをセットする処理
  const openDateOnChange = () => {
    if (!isChangedOpenDate) {
      methods.register('openedAt', dateRules1);
      isChangedOpenDate = true;
    }
  };
  return (
    <>
      {/* アンケートタイトル日本語 */}
      <Grid item container justifyContent="center" alignItems="center" xs={12} md={12}>
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            sx={{ wordBreak: 'keep-all', textAlign: 'center' }}
            children={t('enqueteCreate.editEnqueteModal.surveyTitleJpn')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <RHFTextField
            placeholder={t('enqueteCreate.editEnqueteModal.surveyTitleJpn')}
            name="titleJpn"
            defaultValue={enqueteData.titleJpn ? enqueteData.titleJpn : ''}
            control={methods.control}
            fullWidth
            size="small"
            label={t('enqueteCreate.editEnqueteModal.surveyTitleJpn')}
            variant="outlined"
            rules={rulesTitle}
          />
        </Grid>
      </Grid>
      {/* アンケートタイトル英語 */}
      <Grid item container justifyContent="center" alignItems="center" xs={12} md={12}>
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            sx={{ wordBreak: 'keep-all', textAlign: 'center' }}
            children={t('enqueteCreate.editEnqueteModal.surveyTitleEng')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <RHFTextField
            placeholder={t('enqueteCreate.editEnqueteModal.surveyTitleEng')}
            name="titleEng"
            control={methods.control}
            defaultValue={enqueteData.titleEng ? enqueteData.titleEng : ''}
            fullWidth
            size="small"
            label={t('enqueteCreate.editEnqueteModal.surveyTitleEng')}
            variant="outlined"
            rules={rulesTitle}
          />
        </Grid>
      </Grid>
      {/* アンケート期間 */}
      <RHFDatePickers
        title={t('enqueteCreate.enqueteRequestTermTitle')}
        openDateLabel={t('enqueteCreate.editEnqueteModal.requestOpenDate')}
        closeDateLabel={t('enqueteCreate.editEnqueteModal.requestCloseDate')}
        openDateOnChange={openDateOnChange}
        openDateRules={{}}
        closeDateRules={dateRules2}
        control={methods.control}
        defalutCloseDate={enqueteData.closedAt ? new Date(enqueteData.closedAt) : null}
        defalutOpenDate={enqueteData.openedAt ? new Date(enqueteData.openedAt) : null}
        openDateDisabled={(isGeneral || isOverview) && !!requestStatus && REQUEST_STATUS.BEFORE_REQUEST !== requestStatus}
        closeDateDisabled={false}
      />
    </>
  );
};
