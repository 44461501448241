import { useCallback } from 'react';
import { temporaryOrganizationSlice } from 'src/features/general/enquete-create/store/temporaryOrganizationSlice';
import { useDispatch } from 'src/redux/store';

export const useTemporaryOrganizationHooks = () => {
  const dispatch = useDispatch();

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // データリフレッシュカウンター
  const updateRefreshCounter = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.updateRefreshCounter());
  }, []); // eslint-disable-line

  // モーダル オープン
  const openEditModal = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsOpenEditModal(true));
  }, []); // eslint-disable-line

  // モーダル クローズ
  const closeEditModal = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsOpenEditModal(false));
  }, []); // eslint-disable-line

  // ローディング開始
  const startLoading = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsLoading(true));
  }, []); // eslint-disable-line

  // ローディング終了
  const stopLoading = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsLoading(false));
  }, []); // eslint-disable-line

  return {
    updateRefreshCounter,
    openEditModal,
    closeEditModal,
    startLoading,
    stopLoading,
  };
};
