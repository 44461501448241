import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE, Question } from 'src/@types/generalEnquete';
import { RootState, useSelector } from 'src/redux/store';
import { ConditionEditForm } from './ConditionEditForm';
import { ConditionEditNotAllowedCard } from './ConditionEditNotAllowedCard';

export const ConditionEditSwitcher = () => {
  const logicQuestions: Question[] = useSelector((state: RootState) => state.enqueteCreate.logicQuestions);
  const { t } = useTranslation();
  return (
    <Grid>
      {logicQuestions.map((question, index) => {
        switch (question.inputType) {
          case GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX:
          case GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP:
          case GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT:
            return <ConditionEditForm question={question} index={index} key={question.id} />;
          default:
            return (
              <ConditionEditNotAllowedCard
                title={question.title}
                index={index}
                key={question.id}
                message={t('enqueteCreate.logic.settingNotAllowedMessage')}
              />
            );
        }
      })}
    </Grid>
  );
};
