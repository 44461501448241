import { DateTime } from 'luxon';
import { DisclosureLevel, FormType, SurveyType } from 'src/constants';

//アンケート情報エンティティ
export interface SurveyInformationEntity {
  respondentRequestId: string;
  surveyDetailId: string;
  disclosureLevel: DisclosureLevel | null;
  titleJpn: string;
  titleEng: string;
  targetDate: string | null;
  openedAt: string;
  closedAt: string;
  labelJpn: string;
  labelEng: string;
  type: SurveyType;
  formType: FormType;
}

//  汎用アンケート用JSON構造
export type GeneralEnquete = {
  questionDescriptionJpn: string; // アンケートの説明（日本語）
  questionDescriptionEng: string; // アンケートの説明（英語）
  questionsJpn?: Question[]; // アンケート（一問一答）日本語を格納
  questionsEng?: Question[]; // アンケート（一問一答）英語を格納
  questionsTranslationData?: string[][]; // 一問一答形式 日英変換データ格納
  tables?: TableType; // アンケート（表形式）日本語を格納
};

// アンケート（一問一答）構造
export type Question = {
  id: string; // ID（uid）
  title: string; // 質問内容
  inputType: string; // 回答のインプットタイプ。Input形式を指定する。
  questionItem: QuestionItem[]; // 回答選択肢内容
  validation?: Validation; // バリデーション内容
  answerItem?: AnswerItem[]; // 回答内容
  conditions?: Condition[]; // ロジック条件
};

// 回答選択肢内容構造
export type QuestionItem = {
  id: string; // ID(uid)
  label?: string; // ラベル
};

// バリデーション内容構造
export type Validation = {
  required: {
    isRequired: boolean;
    errorMessage: string;
  };
  pattern?: {
    value: string | 'date' | 'email' | 'password' | 'phone' | 'time' | 'url' | 'number' | 'string' | RegExp;
    errorMessage: string;
  };
  minLength?: {
    value: number;
    errorMessage: string;
  };
  maxLength?: {
    value: number;
    errorMessage: string;
  };
  min?: {
    value: number;
    errorMessage: string;
  };
  max?: {
    value: number;
    errorMessage: string;
  };
  minNumberOfElements?: {
    value: number;
    errorMessage: string;
  };
  maxNumberOfElements?: {
    value: number;
    errorMessage: string;
  };
  numberOfDecimalPlaces?: {
    value: number;
    errorMessage: string;
  };
};

// 回答内容構造
export type AnswerItem = {
  id: string; // ID(uid)
  value: string | Number | boolean | DateTime; // 1.3.6.3.回答内容を格納
  other?: string | Number | boolean | DateTime; // 1.3.6.5.その他専用。テキスト内容が反映される。
  questionItemId?: string; // 1.3.6.6.QuestionItemとの紐づけ用ID
  label?: string; // 1.3.6.7.ラベル
};

// ロジックアクション
export type Logic = 'skip-disable' | 'skip' | 'show' | 'disable' | 'hide' | '';

// ロジック条件
export type Condition = {
  id: string; // ID(uid)
  questionOptionId?: string; // QuestionItemとの紐づけ用ID
  logic: Logic; // ロジックアクション
  targetQuestionId: string; // ロジックアクション適用先QuestionのId
};

// 制御ステータス
export type State = 'visible' | 'hidden' | 'disabled';

//  回答画面表示制御データ
export type QuestionState = {
  id: string; // ID(uid)
  questionId: string; // 制御対象QuestionのId
  state: State;
};

// インプットタイプ。Question.type定義。
export const GENERAL_ENQUETE_JSON_INPUT_TYPE = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  RADIOGROUP: 'RadioGroup',
  CHECKBOX: 'checkbox',
} as const;
export type GeneralEnqueteJsonInputType = typeof GENERAL_ENQUETE_JSON_INPUT_TYPE[keyof typeof GENERAL_ENQUETE_JSON_INPUT_TYPE];

// Validationパターン。
export const GENERAL_ENQUETE_VALIDATION_PATTERN = {
  STRING: 'string',
  DATE: 'date',
  EMAIL: 'email',
  PASSWORD: 'password',
  PHONE: 'phone',
  TIME: 'time',
  URL: 'url',
  NUMBER: 'number',
  REGEXP: 'RegExp',
};

// チェックボックスの回答選択肢数「多くとも」、「少なくとも」の定義。
export const NUMBER_OF_ELEMENRS = {
  AT_LEAST: 'atLeast',
  AT_MOST: 'atMost',
} as const;

// メイン表（回答表）構造 定義
export type MainTableType = {
  sourceData: TableData; // TableDataデータ定義（メイン表計算式用データ）
  answerData: TableData; // TableDataデータ定義（メイン表回答保存後の集計用データ）
  cell: any[]; // Cellセル情報定義(CellProperties)
  mergeCells: MergeCells; // セル結合情報定義
  colWidths: ColWidths; // 列幅情報定義
  rowHeights: RowHeights; // 行高さ情報定義
  nestedHeaders?: (string | NestedHeader)[][]; // 列ヘッダー
  rowHeaders?: string[]; // 行ヘッダー
  sheetNameJpn?: string; // シート名(日本語)
  sheetNameEng?: string; // シート名(英語)
  fixedColumnsStart?: number | undefined; // 固定列
  fixedRowsTop?: number | undefined; // 固定行
};

// アンケート（表形式）構造 定義
export type TableType = MainTableType & {
  subDatas: TableData[]; // subTableDataデータ定義 (sub表参照用テーブルのデータ)
  additionalTables?: MainTableType[]; // 2つ目以降のメイン表（回答表）を格納するデータ
};

// アンケート　表形式用　TableData定義
export type TableData = (string | number)[][];
// アンケート　表形式用　RowHeights定義
export type RowHeights = (string | undefined | number)[];
// アンケート　表形式用　ColWidths定義
export type ColWidths = (string | undefined | number)[];

// アンケート　表形式用　MergeCells定義
export type MergeCells = {
  row: number; // 行番号
  col: number; // 列番号
  rowspan: number; // 行数
  colspan: number; // 列数
}[];

// 列ヘッダーデータ型定義
export type NestedHeader = { label: string; colspan: number };
