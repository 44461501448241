import { Grid, Stack, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CountryEntity } from 'src/api/useCountryApi';
import { CountryRegionEntity } from 'src/api/useCountryRegionApi';
import { ParentCustomer } from 'src/api/useCustomerManagementApi';
import { RegionEntity } from 'src/api/useRegionApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import RHFSelectAutocomplete from 'src/components/react-hook-form/RHFSelectAutocomplete';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { useCustomerManagementEditModal } from 'src/features/manage/customer-management/components/modals/edit/hooks/useCustomerManagementEditModal';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector } from 'src/redux/store';
import { useValidator } from '../hooks/useValidator';

type CustomerManagementEditModalProps = {
  parentCustomers: ParentCustomer[];
  regions: RegionEntity[];
  countries: CountryEntity[];
  countryRegions: CountryRegionEntity[];
};

const CustomerManagementEditModal = memo(({ parentCustomers, regions, countries, countryRegions }: CustomerManagementEditModalProps) => {
  const methods = useForm({ mode: 'onChange' });
  const [isSubmitProcess, setIsSubmitProcess] = useState(false);
  const {
    parentCustomerOptions,
    parentCustomerDefaultValue,
    regionOptions,
    regionDefaultValue,
    countryOptions,
    countryDefaultValue,
    onSaveClick,
    onCancelClick,
    onRegionChange,
    isCountryDisabled,
  } = useCustomerManagementEditModal(methods, parentCustomers, regions, countries, countryRegions);
  const { t } = useTranslation();
  const sx = { wordBreak: 'keep-all', textAlign: 'center' };
  const { rulesParentCustomer, rulesCustomerNameJpn, rulesCustomerNameEng, rulesRegion, rulesCountry } = useValidator();
  const { isEditModalOpen, editCustomer } = useAppSelector((state: RootState) => state.customerManagement);
  const { isViewerAdmin } = usePermission();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    setIsButtonDisabled(!methods.formState.isValid || !methods.formState.isDirty);
  }, [methods.formState.isValid, methods.formState.isDirty]);

  return (
    <>
      <StyledModal isOpen={isEditModalOpen} modalTitle={t('customerManagement.editModal.title')} onCloseFunc={() => {}}>
        {/* モーダル表示時に時間がかかる場合、isSubmitProcess が true の間は modal 操作不可となるように circularProgress を描画 */}
        <Grid container>
          {/* 親取引先 */}
          <Grid item container alignItems="center" p={2}>
            <Grid item md={4}>
              <Typography variant="body1" sx={sx}>
                {t('common.parentCustomer')}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <RHFSelectAutocomplete
                name={'parentCustomer'}
                label={t('common.parentCustomer')}
                options={parentCustomerOptions}
                defaultValue={parentCustomerDefaultValue}
                methods={methods}
                rules={rulesParentCustomer}
                onChange={(data?: { key: string; label: string }) => {
                  if (data) {
                    const parent = parentCustomers.find((el) => el.customerCode === data.key);
                    if (parent) {
                      methods.setValue('parent', parent);
                    }
                  } else {
                    methods.setValue('parent', '');
                  }
                }}
              />
            </Grid>
          </Grid>
          {/* 取引先名(日本語) */}
          <Grid item container alignItems="center" p={2}>
            <Grid item md={4}>
              <Typography variant="body1" sx={sx} children={t('customerManagement.editModal.field.customerNameJpn')} />
            </Grid>
            <Grid item md={8}>
              <RHFTextField
                label={t('customerManagement.editModal.field.customerNameJpn')}
                defaultValue={editCustomer?.customerNameJpn ?? ''}
                control={methods.control}
                name="customerNameJpn"
                size="small"
                rules={rulesCustomerNameJpn}
              />
            </Grid>
          </Grid>
          {/* 取引先名(英語) */}
          <Grid item container alignItems="center" p={2}>
            <Grid item md={4}>
              <Typography variant="body1" sx={sx} children={t('customerManagement.editModal.field.customerNameEng')} />
            </Grid>
            <Grid item md={8}>
              <RHFTextField
                label={t('customerManagement.editModal.field.customerNameEng')}
                defaultValue={editCustomer?.customerNameEng ?? ''}
                control={methods.control}
                name="customerNameEng"
                size="small"
                rules={rulesCustomerNameEng}
              />
            </Grid>
          </Grid>
          {/* 極 */}
          <Grid item container alignItems="center" p={2}>
            <Grid item md={4}>
              <Typography variant="body1" sx={sx} children={t('common.region')} />
            </Grid>
            <Grid item md={8}>
              <RHFSelectAutocomplete
                name={'region'}
                label={t('common.region')}
                options={regionOptions}
                defaultValue={regionDefaultValue}
                methods={methods}
                rules={rulesRegion}
                onChange={onRegionChange}
              />
            </Grid>
          </Grid>
          {/* 国 */}
          <Grid item container alignItems="center" p={2}>
            <Grid item md={4}>
              <Typography variant="body1" sx={sx} children={t('common.country')} />
            </Grid>
            <Grid item md={8}>
              <RHFSelectAutocomplete
                name={'country'}
                label={t('common.country')}
                options={countryOptions}
                defaultValue={countryDefaultValue}
                methods={methods}
                rules={rulesCountry}
                onChange={(data?: { key: string; label: string }) => {
                  if (data) {
                    const country = countries.find((el) => el.countryIsoCode === data.key);
                    if (country) {
                      methods.setValue('countryCode', country.countryIsoCode);
                      methods.setValue('countryNameJpn', country.countryNameJpn);
                      methods.setValue('countryNameEng', country.countryNameEng);
                    }
                  } else {
                    methods.setValue('countryCode', '');
                    methods.setValue('countryNameJpn', '');
                    methods.setValue('countryNameEng', '');
                  }
                }}
                disabled={isCountryDisabled}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ mr: 2, width: '100%', textAlign: 'right' }}>
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
              <StyledButton
                variant={'contained'}
                onClick={() => {
                  onCancelClick();
                  methods.reset();
                }}
                color="error">
                {t('button.cancel')}
              </StyledButton>
              <StyledButton
                variant={'contained'}
                onClick={async () => {
                  setIsSubmitProcess(true);
                  await methods.handleSubmit(onSaveClick)();
                  methods.reset();
                  setIsSubmitProcess(false);
                }}
                isDisabled={isViewerAdmin || isButtonDisabled || isSubmitProcess}>
                {t('button.save')}
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </StyledModal>
      <StyledLoading isOpen={isSubmitProcess} />
    </>
  );
});

export default CustomerManagementEditModal;
