import { useTranslation } from 'react-i18next';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { RootState, useSelector } from 'src/redux/store';
import HeaderSettingForm from '../components/HeaderSettingForm';
import { useCreateMainTable } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useCreateMainTable';

/**
 * 行ヘッダ定義ダイアログ
 *
 * @constructor
 */
export const HeaderSettingModal = () => {
  const { t } = useTranslation();
  const { setOpenRowHeaderModal } = useCreateMainTable();

  // 行ヘッダ情報定義ダイアログ表示制御
  const isOpenRowHeaderModal = useSelector((state: RootState) => state.tableSetting.isOpenRowHeaderModal);

  return (
    <StyledModal
      isOpen={isOpenRowHeaderModal}
      onCloseFunc={() => setOpenRowHeaderModal(false)}
      modalTitle={t('enqueteCreate.headerSettingModal.pageTitle')}
      mode={'general-headerSetting'}
      children={<HeaderSettingForm />}
    />
  );
};
