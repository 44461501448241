import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { useRank } from 'src/hooks/useRank';
import { switchLabel } from 'src/utils/labelUtils';

const useTemporaryOrganizationGrid = (isCodeHide: boolean) => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { complementRank } = useRank();

  const defaultColDef: ColDef = {
    filter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true,
    },
    floatingFilter: true,
    flex: 1,
    resizable: true,
    sortable: false,
  };

  const orgListWithCodeColdef: ColDef[] = [
    {
      field: 'companyCode',
      tooltipField: 'companyCode',
      headerName: t('organization.names.companyCode'),
      headerTooltip: t('organization.names.companyCode'),
      minWidth: 100,
    },
    {
      field: 'rank',
      headerName: t('organization.names.rank'),
      headerTooltip: t('organization.names.rank'),
      minWidth: 100,
      valueGetter: (params: ValueGetterParams) => {
        return complementRank(params.data.companyCode, params.data.rank);
      },
    },
    {
      field: isLangJpn ? 'companyNameJpn' : 'companyNameEng',
      tooltipField: isLangJpn ? 'companyNameJpn' : 'companyNameEng',
      headerName: t('organization.names.companyName'),
      headerTooltip: t('organization.names.companyName'),
      minWidth: 250,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.companyNameJpn ?? '', params.data.companyNameEng ?? '', isLangJpn);
      },
    },
    {
      field: 'divisionCode',
      tooltipField: 'divisionCode',
      headerName: t('organization.names.divisionCode'),
      headerTooltip: t('organization.names.divisionCode'),
      minWidth: 120,
      hide: isCodeHide,
    },
    {
      field: isLangJpn ? 'divisionNameJpn' : 'divisionNameEng',
      tooltipField: isLangJpn ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('organization.names.divisionName'),
      headerTooltip: t('organization.names.divisionName'),
      minWidth: 150,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.divisionNameJpn ?? '', params.data.divisionNameEng ?? '', isLangJpn);
      },
    },
    {
      field: 'sbuCode',
      tooltipField: 'sbuCode',
      headerName: t('organization.names.sbuCode'),
      headerTooltip: t('organization.names.sbuCode'),
      minWidth: 120,
      hide: isCodeHide,
    },
    {
      field: isLangJpn ? 'sbuNameJpn' : 'sbuNameEng',
      tooltipField: isLangJpn ? 'sbuNameJpn' : 'sbuNameEng',
      headerName: t('organization.names.sbuName'),
      headerTooltip: t('organization.names.sbuName'),
      minWidth: 150,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.sbuNameJpn ?? '', params.data.sbuNameEng ?? '', isLangJpn);
      },
    },
    {
      field: 'departmentCode',
      tooltipField: 'departmentCode',
      headerName: t('organization.names.departmentCode'),
      headerTooltip: t('organization.names.departmentCode'),
      minWidth: 120,
      hide: isCodeHide,
    },
    {
      field: isLangJpn ? 'departmentNameJpn' : 'departmentNameEng',
      tooltipField: isLangJpn ? 'departmentNameJpn' : 'departmentNameEng',
      headerName: t('organization.names.departmentName'),
      headerTooltip: t('organization.names.departmentName'),
      minWidth: 150,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.departmentNameJpn ?? '', params.data.departmentNameEng ?? '', isLangJpn);
      },
    },
    {
      field: 'groupCode',
      tooltipField: 'groupCode',
      headerName: t('organization.names.groupCode'),
      headerTooltip: t('organization.names.groupCode'),
      minWidth: 120,
      hide: isCodeHide,
    },
    {
      field: isLangJpn ? 'groupNameJpn' : 'groupNameEng',
      tooltipField: isLangJpn ? 'groupNameJpn' : 'groupNameEng',
      headerName: t('organization.names.groupName'),
      headerTooltip: t('organization.names.groupName'),
      minWidth: 150,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.groupNameJpn ?? '', params.data.groupNameEng ?? '', isLangJpn);
      },
    },
    {
      field: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
      tooltipField: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
      headerName: t('common.region'),
      headerTooltip: t('common.region'),
      minWidth: 100,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.regionNameJpn ?? '', params.data.regionNameEng ?? '', isLangJpn);
      },
    },
    {
      field: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
      tooltipField: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
      headerName: t('common.country'),
      headerTooltip: t('common.country'),
      minWidth: 100,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data.countryNameJpn ?? '', params.data.countryNameEng ?? '', isLangJpn);
      },
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return {
    defaultColDef,
    orgListWithCodeColdef,
  };
};

export default useTemporaryOrganizationGrid;
