import React from 'react';
import { SURVEY_TYPE } from 'src/constants';
import { useAppSelector } from 'src/redux/store';
import { GeneralFormTypeSwitcher } from './GeneralFormTypeSwitcher';
import { SpecialFormTypeSwitcher } from './SpecialFormTypeSwitcher';

export const EnqueteAnswerInputSurveyTypeSwitcher: React.FC = () => {
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);

  switch (surveyDetailData.surveyHeader?.type) {
    case SURVEY_TYPE.GENERAL:
      return <GeneralFormTypeSwitcher />;
    case SURVEY_TYPE.SPECIAL:
      return <SpecialFormTypeSwitcher />;
    default:
      return null;
  }
};
