import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { HeaderBreadcrumbsProps } from '../../../../components/HeaderBreadcrumbs';
import { ComplementaryOrganizationListActions } from '../components/ComplementaryOrganizationListActions';
import { ComplementaryOrganizationListGrid } from '../components/ComplementaryOrganizationListGrid';

export const ComplementaryOrganizationListPage = () => {
  const { t } = useTranslation();
  const title = t('complementaryOrganizationList.pageTitle');
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };
  return (
    <PageLayout title={title} breadcrumbs={breadcrumbs}>
      <ComplementaryOrganizationListActions />
      <ComplementaryOrganizationListGrid />
    </PageLayout>
  );
};
