import HotTable from '@handsontable/react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Paper, Tab } from '@mui/material';
import { registerAllModules } from 'handsontable/registry';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ANSWER_UNIT } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { tabStyles } from 'src/utils/tabStyles';
import { useTableAnswerSave } from '../../../../hooks/useTableAnswerSave';
import { EnqueteConfirmTab } from '../../shared-tabs/answer-confirm-status/components/EnqueteConfirmTab';
import ChangeRespondentComponent from '../../shared-tabs/change-respondent/components/ChangeRespondentComponent';
import OutsideSurveyPeriodTypography from '../../shared-tabs/outside-survey/components/OutsideSurveyPeriodTypography';
import EnqueteAnswerGeneralTableFormTab from './tabs/table/components/EnqueteAnswerGeneralTableFormTab';
import { useTypeGeneralTableTabContainer } from '../hook/useTypeGeneralTableTabContainer';
import { useTableAnswerData } from './tabs/table/hooks/useTableAnswerData';

// handsontable利用するための設定
registerAllModules();

export interface EnqueteAnswerTypeGeneralTableTabContainerProps {
  answerHotRef: React.RefObject<HotTable>[];
  answerHotRefForSubSheet: React.RefObject<HotTable>[];
}

export const EnqueteAnswerTypeGeneralTableTabContainer: React.FC<EnqueteAnswerTypeGeneralTableTabContainerProps> = (props) => {
  const { answerHotRef, answerHotRefForSubSheet } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const { saveAllSettings } = useTableAnswerSave(answerHotRef, answerHotRefForSubSheet);
  const { handleTabChanged, tabValue, TAB_VALUE } = useTypeGeneralTableTabContainer(saveAllSettings);

  // ストア
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);
  const isBeforeRequestTerm = useAppSelector((state) => state.enqueteAnswer.isBeforeRequestTerm);
  const isAfterRequestTerm = useAppSelector((state) => state.enqueteAnswer.isAfterRequestTerm);
  const surveyDetailData = useAppSelector((stateGeneral) => stateGeneral.enqueteAnswer.surveyDetailData);

  // アンケート情報、回答データをストアに保存
  useTableAnswerData();

  // 個人アンケート判定
  const isPersonalEnquete = surveyDetailData?.surveyHeader?.answerUnit === ANSWER_UNIT.PERSONAL;

  return (
    <TabContext value={tabValue}>
      <Paper variant="elevation">
        <TabList onChange={handleTabChanged}>
          <Tab
            style={tabStyles(currentLang.value)}
            label={t('enqueteAnswerInput.tab.surveyTypeGeneralTable')}
            value={TAB_VALUE.TABLE}
            sx={{ paddingLeft: 2 }}
            disabled={isBeforeRequestTerm}
          />
          <Tab
            style={tabStyles(currentLang.value)}
            label={t('enqueteAnswerInput.tab.responseConfirmationStatus')}
            value={TAB_VALUE.CONFIRM}
            disabled={isBeforeRequestTerm}
          />
          {!isPersonalEnquete ? (
            <Tab
              style={tabStyles(currentLang.value)}
              label={t('enqueteAnswerInput.tab.changeRespondents')}
              value={TAB_VALUE.CHANGE}
              disabled={isAfterRequestTerm}
            />
          ) : null}
        </TabList>
        {/** アンケートコンポーネント */}
        <TabPanel value={TAB_VALUE.TABLE}>
          {isBeforeRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'before'} />
          ) : (
            <EnqueteAnswerGeneralTableFormTab answerHotRef={answerHotRef!} answerHotRefForSubSheet={answerHotRefForSubSheet!} />
          )}
        </TabPanel>
        {/** 回答確認状況コンポーネント */}
        <TabPanel value={TAB_VALUE.CONFIRM}>
          {isBeforeRequestTerm ? <OutsideSurveyPeriodTypography requestStatus={'before'} /> : <EnqueteConfirmTab />}
        </TabPanel>
        {!isPersonalEnquete ? (
          <TabPanel value={TAB_VALUE.CHANGE}>
            {isAfterRequestTerm ? (
              <OutsideSurveyPeriodTypography requestStatus={'after'} />
            ) : (
              <ChangeRespondentComponent
                respondentRequestId={respondentRequestData.id}
                respondentTargetId={respondentTargetData.id}
                organizationId={respondentTargetData.organizationId}
              />
            )}
          </TabPanel>
        ) : null}
      </Paper>
    </TabContext>
  );
};
