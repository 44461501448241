import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { EnqueteThreadListLayout } from '../components/pages/list/components/EnqueteThreadListLayout';

export const EnqueteThreadListPage = () => {
  const { t } = useTranslation();

  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('enqueteThread.pageTitle'),
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };
  return (
    <PageLayout title={t('enqueteThread.pageTitle')} breadcrumbs={breadcrumbs}>
      <EnqueteThreadListLayout />
    </PageLayout>
  );
};
