import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserEntity } from 'src/api/useUserApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { SelectUserAgGrid } from 'src/components/app-components/bonsai/SelectUserAgGrid';
import { SelectRespondentManagerModal } from 'src/features/general/enquete-create/components/modals/select-respondent-manager/comoponents/SelectRespondentManagerModal';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useGridOffsetHeight } from './hooks/useGridOffsetHeight';
import { useRespondentManagerTabGrid } from './hooks/useRespondentManagerTabGrid';

type RespondentManagerTabProps = {
  surveyDetailId: string;
};

export const RespondentManagerTab = ({ surveyDetailId }: RespondentManagerTabProps) => {
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { isViewerAdmin, isSurveyOwner } = usePermission(surveyDetailId);
  const { offsetHeight } = useGridOffsetHeight();
  const { isLoading, rowData, onGridReady, onRowDelete, onModalOk, onValidation } = useRespondentManagerTabGrid(surveyDetailId);
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);

  return (
    <>
      {/* header action space*/}
      <Grid container>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'right'} spacing={1}>
            <Grid item xs={12} md={2} textAlign="right">
              <StyledButton onClick={() => setIsOpenAddModal(true)} children={t('button.add')} isDisabled={isViewerAdmin && !isSurveyOwner} />
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      {/* data table space */}
      <Grid container>
        <Grid item xs={12}>
          <Box
            component={'div'}
            sx={{ width: '100%', height: offsetHeight }}
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            data-testid={'aggrid'}>
            <LoadableComponent isLoading={isLoading} zIndex={1000}>
              <SelectUserAgGrid<UserEntity> surveyDetailId={surveyDetailId} rowData={rowData} onGridReady={onGridReady} onRowDelete={onRowDelete} />
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>

      {/* add modal */}
      <SelectRespondentManagerModal
        open={isOpenAddModal}
        onClose={() => setIsOpenAddModal(false)}
        onOk={(values) => {
          onModalOk(values);
          setIsOpenAddModal(false);
        }}
        onValidation={onValidation}
      />
    </>
  );
};
