import { useCallback, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CatsCustomer, useCustomerManagementApi } from 'src/api/useCustomerManagementApi';
import { closeApplyA1CodeModal, setBonsaiAddedCustomers } from 'src/features/manage/customer-management/store/customerManagementSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';

export const useApplyA1CodeModal = (methods: UseFormReturn, catsCustomers: CatsCustomer[]) => {
  const { getCustomerManagement, applyA1Code } = useCustomerManagementApi();
  const { editCustomer } = useAppSelector((state: RootState) => state.customerManagement);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const matchParent = useCallback(
    (a1Code: string): boolean => {
      const none = isJapanese ? 'なし' : 'None';
      const catsCustomer = catsCustomers.find((catsCustomer) => catsCustomer.customerCode === a1Code);
      const isMatched = editCustomer?.parentCustomerCode === catsCustomer?.parentCustomerCode;
      if (!isMatched) {
        const previous = isJapanese ? editCustomer?.parentCustomerNameJpn : editCustomer?.parentCustomerNameEng;
        const updated = isJapanese ? catsCustomer?.parentCustomerNameJpn : catsCustomer?.parentCustomerNameEng;
        setErrorMessage(t('customerManagement.applyA1CodeModal.message.unmatchedError', { previous: previous ?? none, updated: updated ?? none }));
      }

      return isMatched;
    },
    [isJapanese, catsCustomers, editCustomer, t],
  );
  const onApplyA1CodeClick = useCallback(
    async (data: FieldValues) => {
      if (!editCustomer) {
        // memo: 通常の操作では発生させられないルートなのでsnackbarを使ったエラーメッセージの表示はしません
        return;
      }
      setInProgress(true);
      if (!matchParent(data.a1Code.key)) {
        setInProgress(false);
        return;
      }
      await applyA1Code(editCustomer.customerCode, { a1Code: data.a1Code.key });
      const result = await getCustomerManagement();
      dispatch(setBonsaiAddedCustomers(result.bonsaiAddedCustomers));
      setInProgress(false);
      dispatch(closeApplyA1CodeModal());
    },
    [editCustomer, matchParent, applyA1Code, getCustomerManagement],
  );
  const clear = () => {
    methods.reset();
    setErrorMessage('');
  };

  return { inProgress, onApplyA1CodeClick, errorMessage, clear };
};
