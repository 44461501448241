import { Grid, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RHFDeskTopDatePicker from 'src/components/react-hook-form/RHFDeskTopDatePicker';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { yearMonthFormat } from 'src/utils/formatDateTime';

type EnqueteTargetDateFormProps = {
  methods: UseFormReturn;
  targetDate?: string | null;
};

export const EnqueteTargetDateForm = ({ methods, targetDate }: EnqueteTargetDateFormProps) => {
  const { rulesTargetDate } = useValidator(methods);
  const { t } = useTranslation();

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
      <Grid item xs={12} md={4}>
        <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
          {t('enqueteCreate.editEnqueteModal.enqueteTargetDate')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <RHFDeskTopDatePicker
          name="targetDate"
          label={t('enqueteCreate.editEnqueteModal.enqueteTargetDate')}
          control={methods.control}
          mask="____/__"
          views={['year', 'month']}
          defaultValue={targetDate ?? null}
          openTo="year"
          rules={rulesTargetDate}
          inputFormat={yearMonthFormat}
          type="month"
        />
      </Grid>
    </Grid>
  );
};
