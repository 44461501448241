import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { GeneralEnquete } from 'src/@types/generalEnquete';
import { OverviewEnquete } from 'src/@types/overviewEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';
import { UserEntity } from 'src/api/useUserApi';
import { ANSWER_UNIT, DISCLOSURE_LEVEL, FORM_TYPE, SURVEY_TYPE } from 'src/constants';
import { SurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useEnqueteCreate } from 'src/features/general/enquete-create/components/pages/create/hooks/useEnqueteCreate';
import { setEnqueteData, toEnabledSaveButton } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { save } from 'src/features/general/enquete-create/store/enqueteInfoSlice';
import { useDispatch } from 'src/redux/store';

export const useInformationForm = (enqueteTypeData: SurveyHeaderEntity, enqueteData: RespondentRequestEntity) => {
  const { isOverviewCreateMode } = useEnqueteCreate();
  const dispatch = useDispatch();

  const createNewEnqueteData = useCallback(
    (
      data: FieldValues,
      surveyFormData: SurveyFormData,
      enqueteData: RespondentRequestEntity,
      newEnqueteTypeData: SurveyHeaderEntity,
    ): RespondentRequestEntity => {
      return {
        ...enqueteData,
        titleJpn: data.titleJpn,
        titleEng: data.titleEng,
        targetDate: data.openedAt.toISOString(),
        openedAt: data.openedAt.toISOString(),
        closedAt: data.closedAt.toISOString(),
        surveyDetail: {
          id: '',
          version: 1,
          surveyFormData: surveyFormData,
          surveyHeader: newEnqueteTypeData,
          surveyHeaderId: newEnqueteTypeData.id,
          disclosureLevel: data.disclosureLevel === DISCLOSURE_LEVEL.PRIVATE ? null : data.disclosureLevel,
          createdBy: '',
          updatedBy: '',
          createdAt: null,
          updatedAt: null,
          isDeleted: false,
        },
        surveyOwnerEmails: data.surveyOwnerEmails.map((surveyOwner: UserEntity) => surveyOwner.email),
      };
    },
    [],
  );

  const makeNewEnqueteDataOverview = useCallback(
    (data: FieldValues, enqueteTypeData: SurveyHeaderEntity, enqueteData: RespondentRequestEntity) => {
      const newEnqueteTypeData: SurveyHeaderEntity = {
        ...enqueteTypeData,
        ownerOrganizationId: data.ownerOrganization.key,
        id: data.labelJpn.id,
        labelJpn: data.labelJpn.labelJpn,
        labelEng: data.labelEng,
        type: SURVEY_TYPE.SPECIAL,
        formType: FORM_TYPE.OVERVIEW,
        answerUnit: ANSWER_UNIT.ORGANIZATION,
      };
      const surveyFormData: SurveyFormData = { formData: { tabData: [{} as OverviewEnquete] } };
      return createNewEnqueteData(data, surveyFormData, enqueteData, newEnqueteTypeData);
    },
    [createNewEnqueteData],
  );

  const makeNewEnqueteDataGeneral = useCallback(
    (data: FieldValues, enqueteTypeData: SurveyHeaderEntity, enqueteData: RespondentRequestEntity) => {
      const newEnqueteTypeData: SurveyHeaderEntity = {
        ...enqueteTypeData,
        ownerOrganizationId: data.ownerOrganization.key,
        id: data.labelJpn.id,
        labelJpn: data.labelJpn.labelJpn,
        labelEng: data.labelEng,
        type: SURVEY_TYPE.GENERAL,
        formType: data.enqueteQuestionFormat,
        answerUnit: data.enqueteAnswerUnit,
      };
      // FORM_TYPE　判定
      // data.enqueteQuestionFormat === 'QA'　一問一答形式 をデフォルト設定
      let surveyFormData: SurveyFormData = { formData: { tabData: [{ questionsJpn: {}, questionsEng: {} } as GeneralEnquete] } };
      if (data.enqueteQuestionFormat === FORM_TYPE.TABLE) {
        surveyFormData = { formData: { tabData: [{ tables: {} } as GeneralEnquete] } };
      }
      return createNewEnqueteData(data, surveyFormData, enqueteData, newEnqueteTypeData);
    },
    [createNewEnqueteData],
  );

  const makeNewEnqueteData = useCallback(
    (data: FieldValues, enqueteTypeData: SurveyHeaderEntity, enqueteData: RespondentRequestEntity) => {
      if (isOverviewCreateMode) {
        return makeNewEnqueteDataOverview(data, enqueteTypeData, enqueteData);
      } else {
        return makeNewEnqueteDataGeneral(data, enqueteTypeData, enqueteData);
      }
    },
    [isOverviewCreateMode, makeNewEnqueteDataGeneral, makeNewEnqueteDataOverview],
  );

  const onClickSaveData = useCallback(
    (data: FieldValues) => {
      dispatch(save(data.ownerOrganization));
      const newEnqueteData = makeNewEnqueteData(data, enqueteTypeData, enqueteData);
      dispatch(setEnqueteData(newEnqueteData));
      dispatch(toEnabledSaveButton());
    },
    [dispatch, enqueteData, enqueteTypeData, makeNewEnqueteData],
  );

  return {
    onClickSaveData,
  };
};
