import { Grid, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';

type RHFInputRangeProps = {
  control: Control<any>;
  minRules?: Object;
  maxRules?: Object;
  question?: Question;
};

const RHFInputRange = ({ control, minRules, maxRules, question }: RHFInputRangeProps) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={3} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">{t('enqueteCreate.validation.rangeAnswers')}</Typography>
      </Grid>
      <Grid item xs={4} sx={{ textAlign: 'center' }}>
        <RHFNumericField
          name="setMin"
          control={control}
          rules={minRules}
          defaultValue={
            question
              ? question.validation?.pattern?.value === 'number'
                ? question.validation.min?.value
                : question.validation?.minLength?.value
              : ''
          }
        />
      </Grid>
      <Grid item xs={1} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">～</Typography>
      </Grid>
      <Grid item xs={4}>
        <RHFNumericField
          name="setMax"
          control={control}
          rules={maxRules}
          defaultValue={
            question
              ? question.validation?.pattern?.value === 'number'
                ? question.validation.max?.value
                : question.validation?.maxLength?.value
              : ''
          }
        />
      </Grid>
    </Grid>
  );
};

export default RHFInputRange;
