import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColWidths, MergeCells, NestedHeader, RowHeights, TableData } from 'src/@types/generalEnquete';
import { TABLE_SETTINGS } from 'src/generalConstants';

// totalMainTablesType用型定義
export type TotalMainTablesType = {
  sourceData: TableData; // TableDataデータ定義（メイン表計算式用データ）
  answerData: TableData; // TableDataデータ定義（メイン表回答保存後の集計用データ）
  cell: any[]; // Cellセル情報定義(CellProperties)
  mergeCells: MergeCells; // セル結合情報定義
  colWidths: ColWidths; // 列幅情報定義
  rowHeights: RowHeights; // 行高さ情報定義
  nestedHeaders?: (string | NestedHeader)[][]; // 列ヘッダー
  rowHeaders?: string[]; // 行ヘッダー
  fixedColumnsStart?: number | undefined; // 固定列
  fixedRowsTop?: number | undefined; // 固定行
};

// 回答表シート名データ型定義
export type MainTableSheetNamesType = { sheetNameJpn: string; sheetNameEng: string };

// 回答表シート名データ更新用型定義
export type UpdateSheetNamesType = { sheetNameJpn: string; sheetNameEng?: string } | { sheetNameJpn?: string; sheetNameEng: string };

export const tableSettingSlice = createSlice({
  name: 'tableSetting',
  initialState: {
    subDatas: [] as TableData[],
    totalMainTables: [] as TotalMainTablesType[],
    mainTableSheetNames: [] as MainTableSheetNamesType[],
    isOpenColumnHeaderModal: false,
    isOpenRowHeaderModal: false,
    selectedTableIndex: 0,
    validSheetNames: [] as boolean[][],
  },
  reducers: {
    initSettings(state) {
      state.subDatas = [];
      state.totalMainTables = [];
      state.mainTableSheetNames = [];
      state.isOpenColumnHeaderModal = false;
      state.isOpenRowHeaderModal = false;
      state.selectedTableIndex = 0;
      state.validSheetNames = [];
    },
    setSettings(state, action) {
      state.subDatas = action.payload.subDatas;
      state.totalMainTables = action.payload.totalMainTables;
    },
    setSubData: (state, action) => {
      state.subDatas = action.payload;
    },
    addSubData: (state, action) => {
      state.subDatas.push(action.payload);
    },
    deleteSubData: (state, action) => {
      state.subDatas.splice(action.payload, 1);
    },
    addTotalMainTables(state, action) {
      state.totalMainTables.push(action.payload);
    },
    deleteTotalMainTables(state, action) {
      state.totalMainTables.splice(action.payload, 1);
    },
    setTotalMainTables(state, action) {
      state.totalMainTables = action.payload;
    },
    setTargetMainTable(state, action: PayloadAction<{ index: number; table: TotalMainTablesType }>) {
      state.totalMainTables.splice(action.payload.index, 1, action.payload.table);
    },
    setMainTableSheetNames(state, action) {
      state.mainTableSheetNames = action.payload;
    },
    // tabIndex：回答表配列“totalMainTables”のIndexと同等、data：回答表シート名オブジェクト
    updateSheetNames: (state, action: PayloadAction<{ tabIndex: number; data: UpdateSheetNamesType }>) => {
      state.mainTableSheetNames[action.payload.tabIndex] = { ...state.mainTableSheetNames[action.payload.tabIndex], ...action.payload.data };
    },
    addSheetNames: (state) => {
      state.mainTableSheetNames.push({
        sheetNameJpn: TABLE_SETTINGS.DEFAULT_SHEET_NAME_JPN + (state.mainTableSheetNames.length + 1),
        sheetNameEng: TABLE_SETTINGS.DEFAULT_SHEET_NAME_ENG + (state.mainTableSheetNames.length + 1),
      } as MainTableSheetNamesType);
    },
    deleteSheetNames: (state, action) => {
      state.mainTableSheetNames.splice(action.payload, 1);
    },
    setIsOpenColumnHeaderModal: (state, action) => {
      state.isOpenColumnHeaderModal = action.payload;
    },
    setIsOpenRowHeaderModal: (state, action) => {
      state.isOpenRowHeaderModal = action.payload;
    },
    setSelectedTableIndex: (state, action) => {
      state.selectedTableIndex = action.payload;
    },
    addValidSheetNames: (state) => {
      state.validSheetNames.push([true, true]);
    },
    deleteValidSheetNames: (state, action) => {
      state.validSheetNames.splice(action.payload, 1);
    },
    // 初期表示定義 - 渡された数値分の配列を生成 ※Payloadに回答表配列数が渡される想定
    initValidSheetNames: (state, action) => {
      const actionPayload = action.payload;
      state.validSheetNames = Array(actionPayload).fill([true, true]);
    },
    // tabIndex：回答表配列“totalMainTables”のIndexと同等、languageIndex：Index0 > 日本語シート名検証結果, Index1 > 英語シート名検証結果、valid：検証結果
    setValidSheetNames: (state, action: PayloadAction<{ tabIndex: number; languageIndex: number; valid: boolean }>) => {
      state.validSheetNames[action.payload.tabIndex][action.payload.languageIndex] = action.payload.valid;
    },
  },
});

export const {
  initSettings,
  setSettings,
  setSubData,
  addSubData,
  deleteSubData,
  addTotalMainTables,
  deleteTotalMainTables,
  setTotalMainTables,
  setTargetMainTable,
  addSheetNames,
  deleteSheetNames,
  setMainTableSheetNames,
  updateSheetNames,
  setIsOpenColumnHeaderModal,
  setIsOpenRowHeaderModal,
  setSelectedTableIndex,
  addValidSheetNames,
  deleteValidSheetNames,
  initValidSheetNames,
  setValidSheetNames,
} = tableSettingSlice.actions;
