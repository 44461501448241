import { useTranslation } from 'react-i18next';

export const useEnqueteAnswerValidator = () => {
  const { t } = useTranslation();
  const rulesEnqueteAnswerTarget = (target: string, isMultiLine: boolean, isRequired: boolean = true) => {
    return {
      required: { value: isRequired, message: t('validateError.required', { target: target }) },
      validate: {
        maxLength: (value: string | null) => {
          if (!value) return undefined;
          const maxLength = isMultiLine ? 1000 : 100;
          if (value.length > maxLength) return `${t('validateError.maxLength', { max: maxLength })}`;
        },
      },
    };
  };

  const rulesEnqueteAnswerAmount = (isRequired: boolean) => {
    return {
      required: { value: isRequired, message: t('validateError.required', { target: t('common.amount') }) },
      pattern: {
        value: /^-?([1-9]\d*|0)$/,
        message: t('validateError.integersOnly'),
      },
      validate: {
        maxLength: (value: string | number | null) => {
          if (!value) return undefined;
          const stringValue = value.toString();
          const pattern = /^([1-9]\d*|0)$/;
          const digit = pattern.test(`${stringValue}`) ? 12 : 13;
          if (stringValue.length > digit) return `${t('validateError.maxLengthForInteger', { max: 12 })}`;
        },
      },
    };
  };

  return {
    rulesEnqueteAnswerTarget,
    rulesEnqueteAnswerAmount,
  };
};
