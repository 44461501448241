import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface StyledDialogProps {
  children?: React.ReactNode;
  dialogTitle: string;
  dialogContent?: JSX.Element | string;
  isOpen: boolean;
  disagreeCallback: () => void;
  agreeCallback: () => void;
}

const StyledDialog: React.FC<StyledDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.disagreeCallback} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ fontSize: '16pxs' }}>{props.dialogTitle}</Box>
        </DialogTitle>
        {props.dialogContent && <DialogContent sx={{ mt: 2, pb: 0 }}>{props.dialogContent}</DialogContent>}
        <DialogActions>
          <Button variant={'contained'} onClick={props.disagreeCallback} color="error">
            {t('button.no')}
          </Button>
          <Button variant={'contained'} onClick={props.agreeCallback} autoFocus>
            {t('button.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StyledDialog;
