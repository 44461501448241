import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface useSurveyAnswerApiEntity {
  surveyHeaderId: string;
  surveyDetailId: string;
  respondentRequestId: string;
  respondentTargetId: string;
  respondentPersonId: string;
  requestedDate: string;
  labelJpn: string;
  labelEng: string;
  type: string;
  titleJpn: string;
  titleEng: string;
  ansId: string;
  orgId: string;
  answerStatus: string;
  companyCode?: string;
  companyNameJpn?: string;
  companyNameEng?: string;
  sbuCode?: string;
  sbuNameJpn?: string;
  sbuNameEng?: string;
  divisionCode?: string;
  divisionNameJpn?: string;
  divisionNameEng?: string;
  departmentCode?: string;
  departmentNameJpn?: string;
  departmentNameEng?: string;
  openedAt?: string;
  closedAt?: string;
  createdCompany: string;
  createdCompanyNameJpn: string;
  createdCompanyNameEng: string;
  createdDivision: string;
  createdDivisionNameJpn: string;
  createdDivisionNameEng: string;
  createdSbu: string;
  createdSbuNameJpn: string;
  createdSbuNameEng: string;
  createdDepartment: string;
  createdDepartmentNameJpn: string;
  createdDepartmentNameEng: string;
  userId: string;
  version: number;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}
export interface UseCountSurveyAnswerApiEntity {
  count: string;
}

export const useSurveyAnswerApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getSurveyPastAnswer = async (
    surveyHeaderId: string,
    surveyDetailId: string,
    version: number,
  ): Promise<useSurveyAnswerApiEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<useSurveyAnswerApiEntity[]>(apiGateway + '/enquete-answer-input/survey-answer/past-list', {
        headers: {
          Authorization: token,
        },
        params: { surveyHeaderId, surveyDetailId, version },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyAnswer.failedGetResponseList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getCountLimitedSurveyAnswer = async (filterModel: any, currentLang: string, isAll: boolean): Promise<number | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<number>(apiGateway + '/survey-answer/count-limited-list', {
        headers: {
          Authorization: token,
        },
        params: {
          FilterModel: filterModel,
          CurrentLang: currentLang,
          isAll: isAll,
        },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyAnswer.failedGetResponseList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getLimitedSurveyAnswer = async (
    blockSize: number,
    startRow: number,
    sortModel: any,
    filterModel: any,
    currentLang: string,
    isAll: boolean,
  ): Promise<useSurveyAnswerApiEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<useSurveyAnswerApiEntity[]>(apiGateway + '/survey-answer/limited-list', {
        headers: {
          Authorization: token,
        },
        params: {
          BlockSize: blockSize,
          StartRow: startRow,
          SortModel: sortModel,
          FilterModel: filterModel,
          CurrentLang: currentLang,
          isAll: isAll,
        },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyAnswer.failedGetResponseList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getSurveyPastAnswer,
    getLimitedSurveyAnswer,
    getCountLimitedSurveyAnswer,
  };
};
