import Decimal from 'decimal.js';

//  取引先概況表用JSON構造
export type OverviewEnquete = {
  questionDescriptionJpn: string; // アンケートの説明、目的 （日本語）
  questionDescriptionEng: string; // アンケートの説明、目的 （英語）
  parentCustomerCode?: string; // 親取引先コード
  parentCustomerNameJpn?: string; // 親取引先名
  parentCustomerNameEng?: string; // 親取引先名（英語）
  customerCode?: string; // 取引先コード
  customerNameJpn?: string; // 取引先名
  customerNameEng?: string; // 取引先名（英語）
  purchaseFixedAmount: Amount; // 買い取引金額項目
  purchaseFixedQuestions: PurchaseFixedQuestions; // 初期セットの買い取引項目
  salesFixedAmount: Amount; // 売り取引項目金額項目
  salesFixedQuestions: SalesFixedQuestions; // 初期セットの売り取引項目
  otherFixedTopic: OtherFixedTopic; // その他トピック項目
  purchaseAdditionalQuestions: AdditionalQuestion[]; // 買い取引の追加項目
  salesAdditionalQuestions: AdditionalQuestion[]; // 売り取引の追加項目
  otherAdditionalQuestions: AdditionalQuestion[]; // その他質問の追加項目
  reportingPeriod: ReportingPeriod; // 集計期間
  targetOrgLevelType: TargetOrgLevelType; // 集計対象組織
};

export type Amount = {
  isAnswerTarget: boolean; // 回答者に金額を設定させるかどうか
  answer?: Decimal; // 回答
};

export type PurchaseFixedQuestions = {
  // 品目名
  product: FixedItem;
  // 主売先
  mainCustomer: FixedItem;
  // 商流
  commercialFlow: FixedItem;
};

export type SalesFixedQuestions = {
  // 品目名
  product: FixedItem;
  // 商流
  commercialFlow: FixedItem;
};

export type OtherFixedTopic = {
  answer?: string; // 回答
};

export type FixedItem = {
  isAnswerTarget: boolean; // 回答項目として指定するか
  answer?: string; // 回答
};

export type AdditionalQuestion = {
  isAnswerTarget: boolean; // 回答項目として指定するか
  titleJpn: string; // 項目名(日本語)
  titleEng: string; // 項目名（英語）
  answer?: string; // 回答
  inputType: 'text' | 'textarea'; // インプットタイプ。Input形式を指定する
  isRequired: boolean; // 必須/非必須
};

export type ReportingPeriod = {
  startDate: string; // 集計期間開始日
  endDate: string; // 集計期間終了日
};

//  概況表アンケート オプションデータJSON構造
export type OverviewEnqueteOptionData = {
  parentCustomerCode: string; // 親会社コード
  parentCustomerName: string; // 親会社名
  parentCustomerNameEng: string; // 親会社名（英語）
  customerCode: string; // 取引先コード
  customerName: string; // 取引先名
  customerNameEng: string; // 取引先名（英語）
  address: string | null; // 本社所在地
  estabDate: Date | null; // 設立年月日
  sector: string | null; // 業種
  capital: Decimal | null; // 資本金
  systemCapital: Decimal | null; // 初期値提案値 資本金(外貨)
  capitalCurrencyCode: string | null; // 資本金通貨コード
  isConvertYen: boolean; // 円換算実行フラグ
  ceoName: string | null; // 代表者名
  employeesNumber: number | null; // 従業員数
  employeesConsolidationClass: EmployeesConsolidationClass; //  従業員数種別
  shareholderList: string[]; // 株主
  shareholderRatioList: (number | null)[]; // 持株比率
  mainCustomerList: string[]; // 主販売先名
  mainSupplierList: string[]; // 主仕入れ先名
  customerPerformance: CustomerPerformance[]; // 業績(直近5年分を持つ)
  addItemList: AddItem[]; // 追加項目
};

export type CustomerPerformance = {
  fiscalYear: Date; // 決算期
  salesAmount: Decimal | null; // 売上高
  netProfit: Decimal | null; // 純利益
  isConvertYen: boolean; // 円換算実行フラグ
  currencyCode: string; // 通貨コード
  accountingClass: AccountingClass; // 会計基準
  consolidationClass: ConsolidationClass; // 連結区分
  systemSalesAmount: Decimal | null; // 初期値提案値 売上高(外貨)
  systemNetProfit: Decimal | null; // 初期値提案値 純利益(外貨)
};

export type AddItem = {
  itemNameJpn: string; // 追加項目名
  itemNameEng: string; // 追加項目名英語
  inputContent: string; // 入力内容
  displayOrder: number; // 表示順序 1始まり
};

// アンケート種別：取引先概況表アンケート
export type OverviewSurveyFormAnswerData = {
  pastRespondentRequestId?: string;
  tabData: OverviewEnquete[];
};

// 会計区分
export const ACCOUNTING_CLASS = {
  IFRS: '1', // 国際会計
  LOCALGAAP: '2', // 所在国会計
  USGAAP: '3', // 米国会計
} as const;

export type AccountingClass = typeof ACCOUNTING_CLASS[keyof typeof ACCOUNTING_CLASS];

// 連結区分
export const CONSOLIDATION_CLASS = {
  STANDALONE: '1', // 単体
  CONSOLIDATED: '2', // 連結
} as const;

export type ConsolidationClass = typeof CONSOLIDATION_CLASS[keyof typeof CONSOLIDATION_CLASS];

// 従業員数種別
export const EMPLOYEES_CONSOLIDATION_CLASS = {
  STANDALONE: 'Standalone', // 単体
  CONSOLIDATED: 'Consolidated', // 連結
} as const;

export type EmployeesConsolidationClass = typeof EMPLOYEES_CONSOLIDATION_CLASS[keyof typeof EMPLOYEES_CONSOLIDATION_CLASS];

export const TARGET_OLG_LEVEL_TYPE = {
  COMPANY: 'COMPANY',
  SBU: 'SBU',
} as const;
export type TargetOrgLevelType = typeof TARGET_OLG_LEVEL_TYPE[keyof typeof TARGET_OLG_LEVEL_TYPE];

// アンケート実施中に追加された質問を管理
export interface AdditionalData {
  purchaseFixedQuestions: PurchaseFixedQuestions; // 初期セットの買い取引項目
  purchaseAdditionalQuestions: AdditionalQuestion[]; // 買い取引の追加項目
  salesFixedQuestions: SalesFixedQuestions; // 初期セットの売り取引項目
  salesAdditionalQuestions: AdditionalQuestion[]; // 売り取引の追加項目
  otherAdditionalQuestions: AdditionalQuestion[]; // その他質問の追加項目}
}
