import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question, Validation } from 'src/@types/generalEnquete';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import RHFUnRegisterCheckBox from 'src/components/react-hook-form/RHFUnRegisterCheckBox';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';

type Props = {
  methods: UseFormReturn;
  existingQuestion?: Question;
};

const RequiredValidationForm = ({ methods, existingQuestion }: Props) => {
  const { t } = useTranslation();
  const { rulesTypeValidationErrorMessage } = useValidator(methods);
  const [requireCheck] = useState<boolean>(existingQuestion ? (existingQuestion.validation as Validation).required.isRequired : false);

  useEffect(() => {
    methods.setValue('requireCheck', requireCheck);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <RHFUnRegisterCheckBox
          name={'requireCheck'}
          control={methods.control}
          defaultChecked={requireCheck}
          label={t('enqueteCreate.validation.required')}
          fieldName={['requireErrorMessage']}
          unregister={methods.unregister}
        />
      </Grid>
      {methods.watch('requireCheck', '') ? (
        <Grid item xs={12} md={12}>
          <Typography variant="body1">{t('enqueteCreate.validation.requiredMessage')}</Typography>
          <RHFTextField
            margin="normal"
            fullWidth
            name="requireErrorMessage"
            control={methods.control}
            rules={rulesTypeValidationErrorMessage}
            defaultValue={
              existingQuestion
                ? existingQuestion.validation?.required.isRequired
                  ? existingQuestion.validation.required.errorMessage
                  : t('enqueteCreate.validation.requiredDefultMessage')
                : t('enqueteCreate.validation.requiredDefultMessage')
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default RequiredValidationForm;
