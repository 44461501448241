import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { usePermission } from 'src/hooks/usePermission';
import { NotificationEditTabContainer } from '../../../tab-containers/edit/components/NotificationEditTabContainer';
import { useNotificationEdit } from '../hooks/useNotificationEdit';

type NotificationCreateLayoutProps = {
  pageTitle: string;
  breadCrumbs: HeaderBreadcrumbsProps;
};

export const NotificationEditLayout = ({ pageTitle, breadCrumbs }: NotificationCreateLayoutProps) => {
  const { t } = useTranslation();
  const { isAdmin, isViewerAdmin } = usePermission();
  const { id } = useParams<{ id: string }>();
  const { isLoading, hasError, notification, duplicateNotification, updateNotification } = useNotificationEdit(id);

  const conditionalRender = () => {
    if (!isAdmin && !isViewerAdmin) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.noPermissions')}</Typography>
        </Grid>
      );
    } else if (isLoading) {
      return <StyledLoading isOpen={isLoading} />;
    } else if (!id || hasError) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.failedToRetrieve')}</Typography>
        </Grid>
      );
    } else if (!notification) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.noUpdates')}</Typography>
        </Grid>
      );
    } else {
      return (
        <NotificationEditTabContainer
          id={id}
          notification={notification}
          duplicateNotification={duplicateNotification}
          updateNotification={updateNotification}
        />
      );
    }
  };

  return (
    <PageLayout title={pageTitle} breadcrumbs={breadCrumbs}>
      <Container maxWidth="xl">{conditionalRender()}</Container>
    </PageLayout>
  );
};
