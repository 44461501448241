import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface PositionEntity {
  jobTitleCode: string;
  jobTitleNameJpn: string;
  jobTitleNameEng: string;
}

interface PortalBaseEntity {
  job_title_cd: string;
  job_title_master_id: string;
  job_title_name: string;
  job_title_eng_name: string;
}

const BASE_URL = `${portalApi}/master/position`;

// memo: PositionMaster には、S500の職位情報のみ格納されているため、利用時は注意
//       S500以外は、JobTitleMaster に同じようなデータ（部長等）が含まれる
export const usePositionApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getAll = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalBaseEntity[]>(BASE_URL, {
        headers: { Authorization: token },
        params: { filter: { orderBy: [{ job_title_cd: 'asc' }] } },
      });

      return result.data.map<PositionEntity>((master) => ({
        jobTitleCode: master.job_title_cd,
        jobTitleNameJpn: master.job_title_name,
        jobTitleNameEng: master.job_title_eng_name,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.usePositionApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getAll };
};
