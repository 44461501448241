import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Chip, Grid, Tab } from '@mui/material';
import { isValid } from 'date-fns';
import { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationRequestData } from 'src/api/useNotificationApi';
import { StyledBox } from 'src/components/app-components/StyledBox';
import RHFDeskTopDatePicker from 'src/components/react-hook-form/RHFDeskTopDatePicker';
import { useNotificationTab } from '../../../hooks/useNotificationTab';
import { NotificationFormTab } from '../../common/tabs/components/NotificationFormTab';
import { NOTIFICATION_CATEGORIES } from 'src/constants';

type NotificationCreateTabContainerProps = {
  createNotification: (data: NotificationRequestData) => Promise<void>;
  notification: NotificationRequestData | undefined;
};

export const NotificationCreateTabContainer = memo(({ createNotification, notification }: NotificationCreateTabContainerProps) => {
  const { t } = useTranslation();
  const { currentTab, handleTabClick } = useNotificationTab();
  const { control, handleSubmit, setValue, getValues, formState } = useForm<NotificationRequestData>({
    mode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (data: NotificationRequestData) => {
    await createNotification(data);
  };

  return (
    <>
      <StyledBox>
        <Grid item xs={12} md={12}>
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabClick} aria-label="descripton-tab">
                <Tab label={t('notifications.form.jpn')} value="jp" />
                <Tab label={t('notifications.form.eng')} value="en" />
              </TabList>
            </Box>
            <Box sx={{ display: currentTab === 'jp' ? 'block' : 'none', p: 2 }}>
              <NotificationFormTab
                control={control}
                getValues={getValues}
                titleName="titleJpn"
                contentName="contentJpn"
                title={notification?.titleJpn}
                content={notification?.contentJpn}
              />
            </Box>
            <Box sx={{ display: currentTab === 'en' ? 'block' : 'none', p: 2 }}>
              <NotificationFormTab
                control={control}
                getValues={getValues}
                titleName="titleEng"
                contentName="contentEng"
                title={notification?.titleEng}
                content={notification?.contentEng}
              />
            </Box>
          </TabContext>
        </Grid>
      </StyledBox>
      <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={12}>
          <StyledBox>
            <Box>
              <Controller
                name="category"
                control={control}
                defaultValue={NOTIFICATION_CATEGORIES[0]}
                render={({ field: { value } }) => (
                  <Box>
                    {NOTIFICATION_CATEGORIES.map((chipName) => (
                      <Chip
                        key={chipName}
                        label={t(chipName)}
                        clickable
                        color={value === chipName ? 'primary' : 'default'}
                        onClick={() => setValue('category', chipName)}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </Box>
                )}
              />
            </Box>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <RHFDeskTopDatePicker
              control={control}
              name="publishedAt"
              defaultValue={new Date()}
              label={t('notifications.form.publishDate')}
              rules={{
                required: {
                  value: true,
                  message: t('notifications.form.validation.required'),
                },
                validate: {
                  isValidDate: (value: string) => {
                    return isValid(new Date(value)) || `${t('validateError.format.datetime')}`;
                  },
                },
              }}
            />
          </StyledBox>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end', mb: 10 }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {t('button.save')}
        </Button>
      </Grid>
    </>
  );
});
