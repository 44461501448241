import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { RootState } from 'src/redux/store';
import { useModalHandlers } from '../../../../hooks/useModalHandlers';
import EnquetePreviewForm from './EnquetePreviewForm';

const EnquetePreviewModal: React.FC = () => {
  const { t } = useTranslation();
  const { onModalClosePreview } = useModalHandlers();

  // プレビューダイアログ表示制御
  const isModalOpenPreview = useSelector((state: RootState) => state.enqueteCreate.isModalOpenPreview);

  return (
    <StyledModal
      isOpen={isModalOpenPreview}
      onCloseFunc={onModalClosePreview}
      modalTitle={t('enqueteCreate.PreviewTitle')}
      mode={'general'}
      children={<EnquetePreviewForm />}
    />
  );
};

export default EnquetePreviewModal;
