import { useParams } from 'react-router-dom';
import { FORM_TYPE, SURVEY_TYPE } from 'src/constants';
import { initializeRespondentSetting } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import { initializePreviewEdit } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { useDispatch } from 'src/redux/store';
import { clearEnqueteData, enqueteCreateSlice } from '../../../../store/enqueteCreateSlice';
import { initialize } from '../../../../store/enqueteInfoSlice';

export const useEnqueteCreate = () => {
  const dispatch = useDispatch();

  /**
   *  memo:
   *  編集ルートの場合IDが取得可能.
   *  新規作成の場合enqueteTypeが取得可能
   *  enqueteType = general or overview
   */
  const { id, enqueteType } = useParams();
  const isNewCreateMode = !id;
  const isGeneralCreateMode = SURVEY_TYPE.GENERAL.toLowerCase() === enqueteType;
  const isOverviewCreateMode = FORM_TYPE.OVERVIEW.toLowerCase() === enqueteType;

  const initializeStore = () => {
    dispatch(clearEnqueteData());
    dispatch(initializeRespondentSetting());
    dispatch(enqueteCreateSlice.actions.initRequestListData([]));
    dispatch(initialize());
    dispatch(initializePreviewEdit());
  };

  return {
    isNewCreateMode,
    isGeneralCreateMode,
    isOverviewCreateMode,
    initializeStore,
  };
};
