import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';

interface Props {
  customerCode?: string;
  parentCustomerCode?: string;
  parentCustomerNameJpn?: string;
  parentCustomerNameEng?: string;
}
export const EnqueteParentCustomerGrid: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isJapanese } = useLocales();

  if (props.parentCustomerCode && props.customerCode !== props.parentCustomerCode) {
    return (
      <Grid item xs={4}>
        <Grid container item direction="row">
          <Typography variant="subtitle1">{`${t('enqueteCreate.overviewEnqueteOption.parentCustomer')}：`}</Typography>
          <Typography variant="subtitle1">{isJapanese ? trimStockCompany(props.parentCustomerNameJpn) : props.parentCustomerNameEng}</Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
};
