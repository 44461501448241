import Decimal from 'decimal.js';
import { AdditionalQuestion, TargetOrgLevelType } from './overviewEnquete';

export type FixedQuestions = {
  product?: string;
  mainCustomer?: string;
  commercialFlow?: string;
};

export type OverviewCompanyAggregateSupplement = {
  sbuCode: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentCode?: string;
  departmentNameJpn?: string;
  departmentNameEng?: string;
  companyCode: string;
  companyNameJpn: string;
  companyNameEng: string;
  salesFixedAmount?: Decimal;
  purchaseFixedAmount?: Decimal;
  purchaseFixedQuestions: FixedQuestions;
  salesFixedQuestions: FixedQuestions;
  purchaseAdditionalQuestions: AdditionalQuestion[];
  salesAdditionalQuestions: AdditionalQuestion[];
  otherAdditionalQuestions: AdditionalQuestion[];
  otherFixedTopic?: string;
};

export type OverviewDivisionAggregateSupplement = {
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  organizationId: string;
  companyAggregateSupplements: OverviewCompanyAggregateSupplement[];
  sumSalesFixedAmount?: Decimal;
  sumPurchaseFixedAmount?: Decimal;
  topic?: string;
  aggregateStatus?: AggregateStatus;
  targetOrgLevelType?: TargetOrgLevelType;
};

export type OverviewAggregateSupplement = {
  topic?: string;
  divisionAggregateSupplements: OverviewDivisionAggregateSupplement[];
};

export const AGGREGATE_STATUS = {
  COMPLETED: 'COMPLETED',
} as const;
export type AggregateStatus = 'COMPLETED';

export interface UpdateDivisionOrganization {
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  organizationId: string;
}
