import { DecimalFormType } from 'src/@types/seriesEnquete';
import { PartialCustomerData } from 'src/api/useCustomerApi';
import { AmountSourceType, AMOUNT_SOURCE_TYPE, CustomerStatus, CUSTOMER_STATUS } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { MAXDAYS, MAXINPUTAMOUNT, MAXYOY } from './constants';

/**
 * 債権債務回転日数表記のための関数
 * 引数のいずれかが数値でない場合は、"0.00"を返却する
 * @param balance 債権債務残高
 * @param currentYearValue 売上高または仕入高
 * @returns 債権債務回転日数
 */
export const toStringDeptAndCredit = (balance: DecimalFormType, currentYearValue: DecimalFormType): string => {
  const b = parseFloat(`${balance}`);
  const c = parseFloat(`${currentYearValue}`);
  if (!b || !c || isNaN(b) || isNaN(c)) return '0.00';
  const calculateResult = b / (c / 365);
  // 債権債務の回転日数の計算で結果が999以上になる場合は999+と表示する
  return calculateResult > MAXDAYS ? `${MAXDAYS}+` : calculateResult.toFixed(2);
};

/**
 * 増減率の計算
 * 両方の引数が数値でない場合、0を返却する
 * @param currentYearAmount 対象年度の売上高または仕入高
 * @param preYearAmount 対象年度の前年度の売上高または仕入高
 * @returns 増減率
 */
export const toStringForYoY = (currentYearAmount: DecimalFormType, preYearAmount: DecimalFormType): string | number => {
  const c: number = isNaN(parseFloat(`${currentYearAmount}`)) ? 0 : parseFloat(`${currentYearAmount}`);
  const p: number = isNaN(parseFloat(`${preYearAmount}`)) ? 0 : parseFloat(`${preYearAmount}`);
  if (!c && !p) return 0;
  const calculateResult = Math.round((c / p - 1) * 100);

  // 最大値を999に設定、それ以上は999+と表示
  return Math.abs(calculateResult) > MAXYOY ? `${MAXYOY}+` : calculateResult;
};

/**
 * 前年度差異を表示
 * 小数点以下桁数がnullまたは売上高と仕入高が両方とも数値でない場合、0を返却する
 * @param currentYearAmount 対象年度の売上高または仕入高
 * @param preYearAmount 対象年度の前年度の売上高または仕入高
 * @param decimalPlace 小数点以下桁数
 * @returns 前年度差異
 */
export const toStringDiffPreYear = (
  currentYearAmount: DecimalFormType,
  preYearAmount: DecimalFormType,
  decimalPlace: number | null,
): string | number => {
  const c: number = isNaN(parseFloat(`${currentYearAmount}`)) ? 0 : parseFloat(`${currentYearAmount}`);
  const p: number = isNaN(parseFloat(`${preYearAmount}`)) ? 0 : parseFloat(`${preYearAmount}`);
  if (decimalPlace === null || (!c && !p)) return 0;

  // 999999999999を最大値に設定、それ以上は999999999999+と表示
  return Math.abs(c - p) > MAXINPUTAMOUNT
    ? `${MAXINPUTAMOUNT.toLocaleString()}+`
    : (c - p).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlace,
        maximumFractionDigits: decimalPlace,
      });
};

/**
 * 会社が削除された場合、親会社が系列から外れた場合をチェックする
 * @param customerCode 取引先コード
 * @param parentCustomerCode 親会社コード
 * @param customers Get /customer APIのレスポンス
 * @returns CUSTOMER_STATUS
 */
export const getCustomerStatus = (customerCode: string, customers: PartialCustomerData[], parentCustomerCode?: string) => {
  const customer = customers.find((c) => c.customerCode === customerCode);
  if (!customer) return CUSTOMER_STATUS.OUTSIDES_COMPANY;

  // check customer status
  if (customer?.isDeleted) {
    return CUSTOMER_STATUS.DELETE;
  }

  return customers.some((c) => c.customerCode === (parentCustomerCode ?? customer.parentCustomerCode))
    ? CUSTOMER_STATUS.EXIST
    : CUSTOMER_STATUS.OUTSIDES_COMPANY;
};

/**
 * 取得してきたcustomerStatusを見て、取引先の状態を判断
 * @param customerStatus
 * @returns
 */
export const isNotCustomerExist = (customerStatus: CustomerStatus) => {
  return customerStatus !== CUSTOMER_STATUS.EXIST;
};

/**
 * 取得してきたsourceTypeを見て、ANSWEREDか確認する
 * @param sourceType
 * @returns
 */
export const isAnswered = (sourceType?: AmountSourceType) => {
  return sourceType === AMOUNT_SOURCE_TYPE.ANSWERED;
};

export const isPreAnswered = (sourceType?: AmountSourceType) => {
  return sourceType === AMOUNT_SOURCE_TYPE.PRE_ANSWERED;
};

/**
 * 取得してきたcustomerStatus、value,sourceTypeを見て、値を返す
 * 取引先のステータスがEXIST以外で
 * 入力不可の場合は0を返す
 * @param isNotExist
 * @param value
 * @param sourceType
 * @returns
 */
export const returnInputValue = (isNotExist: boolean, value: number | null, sourceType?: AmountSourceType) => {
  if (sourceType) {
    return isNotExist && isAnswered(sourceType) && !value ? 0 : value;
  }
  return isNotExist && !value ? 0 : value;
};

/**
 * inputFormのdisabledの設定
 * 過去回答を使用している場合はDisabled
 * 取引先が存在せず、前年度が過去回答で、もう片方は初期値提案されていない場合、初期値提案されていないformはDisabled
 * 取引先が存在せず、前年度または今年度データのどちらかが初期値提案され、もう片方は初期値提案されていない場合、初期値提案されていないformはDisabled
 * 取引先が存在せず、過去回答でないが前年度または今年度データのどちらかが初期値提案され、もう片方は初期値提案されていない場合、初期値提案されていないformはDisabled
 * 取引先が存在せず、過去回答でないが前年度、今年度データの両方が初期値提案されている場合Enabled
 * @param isPrevYear
 * @param customerStatus
 * @param amount
 * @param sourceType
 * @returns
 */
export const isDisabled = (isPrevYear: boolean, customerStatus: CustomerStatus, amount: number | null, sourceType?: AmountSourceType) => {
  // 前年度のinputFormの場合は過去回答データを使用しているか確認
  // isNotCustomerExistを条件文に入れている理由は回答者が意図的に0を入力したことでdisabledになることを防ぐため
  const isNotExist = isNotCustomerExist(customerStatus);
  const isPreAnsweredForPrevYear = isPreAnswered(sourceType);
  return isPrevYear
    ? isPreAnsweredForPrevYear || (isNotExist && returnInputValue(isNotExist, amount, sourceType) === 0)
    : isNotExist && returnInputValue(isNotExist, amount, sourceType) === 0;
};
