import { useTranslation } from 'react-i18next';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE, Question } from 'src/@types/generalEnquete';

interface Props {
  question: Question;
  languageCode?: string;
}

// 数値を3桁カンマ区切りのフォーマットに変換する。
const toThousandSeparator = (value?: number) => {
  // memo: 小数部を最大20桁まで表示できるようにする。
  return value !== undefined ? value.toLocaleString(undefined, { maximumFractionDigits: 20 }) : '';
};

const useEnqueteAnswerFormTitle = (props: Props) => {
  const { t } = useTranslation();

  const descriptionKey = 'enqueteAnswerInput.generalQuestion.validateDescription';
  const validateDescriptions = [];
  if (props.question.validation?.required.isRequired) {
    validateDescriptions.push(t(`${descriptionKey}.required`, { lng: props.languageCode }));
  }
  switch (props.question.inputType) {
    case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT:
    case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXTAREA:
      if (props.question.validation?.pattern?.value === 'number') {
        const min = toThousandSeparator(props.question.validation?.min?.value);
        const max = toThousandSeparator(props.question.validation?.max?.value);
        const numberOfDecimalPlacesValue = props.question.validation?.numberOfDecimalPlaces?.value;
        let description = t(`${descriptionKey}.number`, { lng: props.languageCode, min, max });

        // Memo: numberOfDecimalPlacesValueのnull判定は、小数点以下の桁数は、未入力も許容しているため。
        // 小数点以下の桁数が設定されている質問を編集する際に、
        // 設定ありから、値を削除した場合に、nullになるケースがあるためnull判定を行う。
        if (numberOfDecimalPlacesValue !== undefined && numberOfDecimalPlacesValue !== null) {
          const numberOfDecimalPlaces = toThousandSeparator(numberOfDecimalPlacesValue);
          description += t(`${descriptionKey}.numberOfDecimalPlaces`, { lng: props.languageCode, numberOfDecimalPlaces });
        }
        validateDescriptions.push(description);
      }
      if (props.question.validation?.pattern?.value === 'string') {
        const min = toThousandSeparator(props.question.validation?.minLength?.value);
        const max = toThousandSeparator(props.question.validation?.maxLength?.value);
        const description = t(`${descriptionKey}.string`, { lng: props.languageCode, min, max });
        validateDescriptions.push(description);
      }
      break;
    case GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX:
      if (props.question.validation?.minNumberOfElements?.value) {
        const min = toThousandSeparator(props.question.validation?.minNumberOfElements?.value);
        const description = t(`${descriptionKey}.minNumberOfElements`, { lng: props.languageCode, min });
        validateDescriptions.push(description);
      }
      if (props.question.validation?.maxNumberOfElements?.value) {
        const max = toThousandSeparator(props.question.validation?.maxNumberOfElements.value);
        const description = t(`${descriptionKey}.maxNumberOfElements`, { lng: props.languageCode, max });
        validateDescriptions.push(description);
      }
      break;
    case GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP:
    case GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT:
    default:
      break;
  }

  let validateDescription = validateDescriptions.join(', ');
  if (validateDescription !== '') {
    validateDescription = `（${validateDescriptions}）`;
  }

  return { validateDescription };
};

export default useEnqueteAnswerFormTitle;
