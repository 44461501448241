import { useSnackbar } from 'notistack5';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getThreadCreatorApi } from 'src/api/getThreadCreatorApi';
import { EnqueteThreadEditor, getThreadEditorApi } from 'src/api/getThreadEditorApi';
import { ENQUETE_THREAD_PATH } from 'src/features/general/enquete-threads/routes/path';
import { fetchThreadById } from 'src/features/general/enquete-threads/store/enqueteThreadActions';
import { enqueteThreadCreateSlice } from 'src/features/general/enquete-threads/store/enqueteThreadCreateSlice';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { ChipData } from '../components/EnqueteThreadEditLayout';

const useThreadEdit = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isJapanese } = useLocales();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onChange', shouldUnregister: false });

  const modalThreadUsers = useAppSelector((state) => state.enqueteThreadCreate.threadUsers);
  const enqueteThread = useAppSelector((state) => state.enqueteThread);
  const thread = enqueteThread.thread;
  const status = enqueteThread.status;
  const priorInfo = enqueteThread.priorInfo;

  const [chipData, setChipData] = useState<{ key: string; label: string; selected: boolean }[] | undefined>([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const { createThread } = getThreadCreatorApi();
  const { editThread } = getThreadEditorApi();

  useEffect(() => {
    dispatch(fetchThreadById(id));
  }, [dispatch, id]);

  useMemo(() => {
    if (thread.threadCategories === undefined || chipData?.length !== 0) {
      return;
    }
    const chips = priorInfo.categories.map((v) => {
      return {
        key: v.id,
        label: isJapanese ? v.nameJpn : v.nameEng,
        selected: false,
      };
    });
    const editChips = thread.threadCategories.map((v) => {
      return {
        key: v.category?.id,
        label: isJapanese ? v.category.nameJpn : v.category.nameEng,
        selected: true,
      };
    });
    const mergedChips = chips.map((item) => {
      const match = editChips.find((element) => element.key === item.key);
      return match ?? item;
    });
    if (id) {
      setChipData(mergedChips);
    }
    methods.setValue('categories', mergedChips, { shouldValidate: true });
  }, [thread, isJapanese, id, chipData, methods, priorInfo]);

  const handleClick = (chipData: ChipData[], chipName: ChipData) => {
    const newChipData = [...chipData];
    const editedChipData = newChipData.map((item) => {
      if (item.key === chipName.key && item.selected === false) {
        return { ...item, selected: true };
      }
      if (item.key === chipName.key && item.selected === true) {
        return { ...item, selected: false };
      }
      return item;
    });
    setChipData(editedChipData);
    methods.setValue('categories', editedChipData, { shouldValidate: true });
  };

  const onSubmit = async (data: FieldValues) => {
    const categories = data.categories.filter((v: ChipData) => v.selected === true);
    const categoryIds = categories?.map((v: ChipData) => v.key);
    const threadUsersId = modalThreadUsers.map((v) => v.email);
    const result: EnqueteThreadEditor = {
      title: data.title,
      isSendMailToUser: data.isSendMailToUser === 'send',
      isPrivate: data.isPrivate === 'private',
      surveyDetailId: data.surveyDetailId.key,
      categories: categoryIds,
      description: data.description,
      threadUsers: threadUsersId.filter((v) => v !== null) as string[],
    };
    setIsClicked(true);
    try {
      if (id === undefined) return;
      await editThread(result, id);
      dispatch(enqueteThreadCreateSlice.actions.clearThreadUsers());
      navigate(ENQUETE_THREAD_PATH.INDEX);
    } catch {
      enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('enqueteThread.detail.pageTitle') }), { variant: 'error' });
      setIsClicked(false);
    }
  };

  const onCloseModal = () => {
    methods.setValue('threadUserDialog', 'closed', { shouldValidate: true });
    setIsOpenAddModal(false);
  };

  const onOpenModal = () => {
    setIsOpenAddModal(true);
  };

  return {
    status,
    isOpenAddModal,
    chipData,
    isClicked,
    handleClick,
    onCloseModal,
    onOpenModal,
    onSubmit,
    createThread,
    methods,
    id,
    thread,
    isJapanese,
  };
};

export default useThreadEdit;
