import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'src/hooks/usePermission';
import { PATH_OVERVIEW } from 'src/routes/paths';
import { useNavbarRoutes } from './useNavbarRoutes';

export const useDashboardLayout = () => {
  const { t } = useTranslation();
  const { generalRoutes, manageRoutes } = useNavbarRoutes();
  const { isAdmin, isViewerAdmin, isOrganizationEditor, isCustomerEditor } = usePermission();

  const manageMenu = [];

  if (isAdmin || isViewerAdmin || isOrganizationEditor) {
    manageMenu.push(manageRoutes.COMPLEMENTARY_ORGANIZATION);
  }

  if (isAdmin || isViewerAdmin) {
    manageMenu.push(manageRoutes.NOTIFICATION);
  }

  if (isAdmin || isViewerAdmin || isCustomerEditor) {
    manageMenu.push(manageRoutes.CUSTOMER_MANAGEMENT);
  }

  return {
    navConfig: [
      {
        subheader: '',
        items: [
          {
            title: 'TOP',
            icon: <DashboardIcon />,
            path: PATH_OVERVIEW.overview,
          },
        ],
      },
      {
        subheader: t('features.general.title'),
        items: Object.values(generalRoutes),
      },
      { subheader: t('features.manage.title'), items: manageMenu },
    ],
  };
};
