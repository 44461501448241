import { FormGroup, TextField } from '@mui/material';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question, State } from 'src/@types/generalEnquete';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import { useEnqueteAnswerHandleOnChange } from '../hooks/useEnqueteAnswerHandleOnChange';

type EnqueteAnswerCommentFormProps = {
  question: Question;
  index: number;
  methods?: UseFormReturn;
  validationRule?: Object;
  languageCode?: string;
  questionState?: State;
};

const EnqueteAnswerCommentForm = ({ question, index, methods, validationRule, languageCode, questionState }: EnqueteAnswerCommentFormProps) => {
  const { t } = useTranslation();
  const { handleOnChangeText } = useEnqueteAnswerHandleOnChange();

  // 質問表示状態が変ったらルールをスイッチする。
  useEffect(() => {
    if (methods && question.answerItem) {
      if (methods?.getValues(question.questionItem[0].id) !== undefined) {
        const stateRule = questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule;
        const value = methods?.getValues(question.questionItem[0].id);
        methods?.control.unregister(question.questionItem[0].id);
        methods?.control.register(question.questionItem[0].id, stateRule);
        methods?.setValue(question.questionItem[0].id, value);
        methods?.trigger(question.questionItem[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionState]);

  return (
    <>
      {methods ? (
        question.validation?.pattern?.value === 'number' ? (
          <RHFNumericField
            textFieldProps={{
              fullWidth: true,
              id: question.questionItem[0].id,
              label: question.questionItem[0].label,
              placeholder: t('enqueteAnswerInput.generalQuestion.text.placeholder', { lng: languageCode }),
              disabled: questionState === 'disabled',
              // memo:以下複数行の設定が有効にならないため数値の場合は1行入力とする。
              // multiline: true,
              // rows: 4,
            }}
            control={(methods as UseFormReturn).control}
            name={question.questionItem[0].id}
            defaultValue={question.answerItem !== undefined && question.answerItem[0] !== undefined ? question.answerItem[0].value : ''}
            rules={questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule}
            key={question.questionItem[0].id}
            handleOnChange={(event) => {
              handleOnChangeText(event, question, index);
            }}
          />
        ) : (
          <RHFonChangeTextField
            control={(methods as UseFormReturn).control}
            name={question.questionItem[0].id}
            defaultValue={question.answerItem !== undefined && question.answerItem[0] !== undefined ? question.answerItem[0].value : ''}
            id={question.questionItem[0].id}
            label={question.questionItem[0].label}
            placeholder={t('enqueteAnswerInput.generalQuestion.text.placeholder', { lng: languageCode })}
            rules={questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule}
            multiline
            rows={4}
            key={question.questionItem[0].id}
            handleOnChange={(event) => {
              handleOnChangeText(event, question, index);
            }}
            disabled={questionState === 'disabled'}
          />
        )
      ) : question.validation?.pattern?.value === 'number' ? (
        <FormGroup>
          <TextField
            name={question.questionItem[0].id}
            defaultValue={question.answerItem !== undefined && question.answerItem[0] !== undefined ? question.answerItem[0].value : ''}
            id={question.questionItem[0].id}
            label={question.questionItem[0].label}
            placeholder={t('enqueteAnswerInput.generalQuestion.text.placeholder', { lng: languageCode })}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <TextField
            name={question.questionItem[0].id}
            defaultValue={question.answerItem !== undefined && question.answerItem[0] !== undefined ? question.answerItem[0].value : ''}
            id={question.questionItem[0].id}
            label={question.questionItem[0].label}
            placeholder={t('enqueteAnswerInput.generalQuestion.text.placeholder', { lng: languageCode })}
            multiline
            rows={4}
          />
        </FormGroup>
      )}
    </>
  );
};

export default EnqueteAnswerCommentForm;
