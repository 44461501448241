import { FirstDataRenderedEvent } from 'ag-grid-community';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-enterprise';
import { useTranslation } from 'react-i18next';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { currencyFormat } from 'src/utils/currencyFormat';
import { switchLabel } from 'src/utils/labelUtils';

export const useSummaryAggregateDetailColdef = (previousYear: string, currentYear: string) => {
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { t } = useTranslation();

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    rowGroupPanelShow: 'always',
    onRowDataUpdated(event) {
      event.columnApi?.autoSizeAllColumns(false);
    },
    onColumnRowGroupChanged: (event) => {
      event.columnApi?.autoSizeAllColumns(false);
    },
    suppressAggFuncInHeader: true,
    minColWidth: 150,
  };

  // 初回グリッドがレンダーされた際に列幅自動調整
  const firstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi?.autoSizeAllColumns(false);
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
  };

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      headerName: t('enqueteStatus.summaryAggregate.company'),
      field: isLangJpn ? 'companyNameJpn' : 'companyNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.companyNameJpn, params.data.companyNameEng, true)
          : switchLabel(params.data.companyNameJpn, params.data.companyNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.division'),
      field: isLangJpn ? 'divisionNameJpn' : 'divisionNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.divisionNameJpn, params.data.divisionNameEng, true)
          : switchLabel(params.data.divisionNameJpn, params.data.divisionNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.sbu'),
      field: isLangJpn ? 'sbuNameJpn' : 'sbuNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.sbuNameJpn, params.data.sbuNameEng, true)
          : switchLabel(params.data.sbuNameJpn, params.data.sbuNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.department'),
      field: isLangJpn ? 'departmentNameJpn' : 'departmentNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.departmentNameJpn, params.data.departmentNameEng, true)
          : switchLabel(params.data.departmentNameJpn, params.data.departmentNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.group'),
      field: isLangJpn ? 'groupNameJpn' : 'groupNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.groupNameJpn, params.data.groupNameEng, true)
          : switchLabel(params.data.groupNameJpn, params.data.groupNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.region'),
      field: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.regionNameJpn, params.data.regionNameEng, true)
          : switchLabel(params.data.regionNameJpn, params.data.regionNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.country'),
      field: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.countryNameJpn, params.data.countryNameEng, true)
          : switchLabel(params.data.countryNameJpn, params.data.countryNameEng, false),
    },
    {
      headerName: t('common.customer'),
      field: isLangJpn ? 'customerNameJpn' : 'customerNameEng',
      enableRowGroup: true,
      valueFormatter: (params): string => (isLangJpn ? trimStockCompany(params.value) : params.value),
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(trimStockCompany(params.data.customerNameJpn), params.data.customerNameEng, true)
          : switchLabel(trimStockCompany(params.data.customerNameJpn), params.data.customerNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.customerRegion'),
      field: isLangJpn ? 'customerRegionNameJpn' : 'customerRegionNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.customerRegionNameJpn, params.data.customerRegionNameEng, true)
          : switchLabel(params.data.customerRegionNameJpn, params.data.customerRegionNameEng, false),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.customerCountry'),
      field: isLangJpn ? 'customerCountryNameJpn' : 'customerCountryNameEng',
      enableRowGroup: true,
      valueGetter: (params): string =>
        isLangJpn
          ? switchLabel(params.data.customerCountryNameJpn, params.data.customerCountryNameEng, true)
          : switchLabel(params.data.customerCountryNameJpn, params.data.customerCountryNameEng, false),
    },
    {
      field: 'preSales',
      headerName: `FY${previousYear}(${t('enqueteStatus.summaryAggregate.sales')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    {
      field: 'currentSales',
      headerName: `FY${currentYear}(${t('enqueteStatus.summaryAggregate.sales')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    {
      field: 'prePurchase',
      headerName: `FY${previousYear}(${t('enqueteStatus.summaryAggregate.purchase')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    {
      field: 'currentPurchase',
      headerName: `FY${currentYear}(${t('enqueteStatus.summaryAggregate.purchase')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    { field: 'salesComment', headerName: t('enqueteStatus.summaryAggregate.salesComment'), minWidth: 150 },
    { field: 'purchaseComment', headerName: t('enqueteStatus.summaryAggregate.purchaseComment'), minWidth: 150 },
  ];
  return {
    defaultColDef,
    gridOptions,
    colDef,
    firstDataRendered,
  };
};
