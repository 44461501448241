import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { Question, State } from 'src/@types/generalEnquete';
import RHFEnqueteAnswerMultiCheckBox from './RHFEnqueteAnswerMultiCheckBox';

type EnqueteAnswerCheckboxFormProps = {
  question: Question;
  index: number;
  methods?: UseFormReturn;
  validationRule?: Object;
  questionState?: State;
};
const EnqueteAnswerCheckboxForm = ({ question, index, methods, validationRule, questionState }: EnqueteAnswerCheckboxFormProps) => {
  const defaultCheckedValues: string[] = [];
  question.questionItem?.forEach((item, questionItemindex) => {
    if (question.answerItem && question.answerItem[questionItemindex] && question.answerItem[questionItemindex].value) {
      defaultCheckedValues.push(item.id);
    }
  });

  return (
    <>
      {methods ? (
        <>
          <RHFEnqueteAnswerMultiCheckBox
            methods={methods}
            rules={validationRule}
            key={question.id}
            question={question}
            questionIndex={index}
            defaultCheckedValues={defaultCheckedValues}
            state={questionState}
          />
        </>
      ) : (
        <FormGroup>
          {question.questionItem.map((item, index) => (
            <FormControlLabel control={<Checkbox />} label={item.label} key={item.id} />
          ))}
        </FormGroup>
      )}
    </>
  );
};
export default EnqueteAnswerCheckboxForm;
