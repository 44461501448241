import { useEffect, useState } from 'react';

export const useGridOffsetHeight = () => {
  const [offsetHeight, setOffsetHeight] = useState<string>('100vh');

  useEffect(() => {
    // calc data table height
    const appHeader = document.getElementsByTagName('header');
    const pageHeader = document.getElementById('page-header');
    if (pageHeader && appHeader.length > 0) {
      setOffsetHeight(`calc(100vh - ${pageHeader?.clientHeight}px - ${appHeader[0]?.clientHeight}px)`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { offsetHeight };
};
