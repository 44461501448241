import { Grid, Stack } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { dispatch, RootState, useAppSelector } from 'src/redux/store';
import { useHeaderSettingFormValidator } from '../hooks/useHeaderSettingFormValidator';
import { useCreateMainTable } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useCreateMainTable';
import { setTargetMainTable } from 'src/features/general/enquete-create/store/tableSettingSlice';

/**
 * 行ヘッダ定義コンポーネント
 *
 * @constructor
 */
const HeaderSettingForm: React.FC = () => {
  const { t } = useTranslation();

  // ストア
  const totalMainTables = useAppSelector((state: RootState) => state.tableSetting.totalMainTables);
  const selectedTableIndex = useAppSelector((state: RootState) => state.tableSetting.selectedTableIndex);

  // rowHeader設定値格納
  const rowHeaderSetting =
    totalMainTables[selectedTableIndex].rowHeaders !== undefined ? totalMainTables[selectedTableIndex].rowHeaders?.join(',') : '';

  // RHF初期化
  const methods = useForm({
    mode: 'onChange',
  });
  // バリデーション定義
  const { rulesRowColumnHeader } = useHeaderSettingFormValidator();
  // 行ヘッダー定義ダイアログ開閉読み込み
  const { setOpenRowHeaderModal } = useCreateMainTable();

  // 設定保存
  const onClickOk = (formInputData: FieldValues) => {
    if (formInputData.rowHeaders !== '') {
      dispatch(
        setTargetMainTable({
          index: selectedTableIndex,
          table: {
            ...totalMainTables[selectedTableIndex],
            ...{ rowHeaders: formInputData.rowHeaders.split(',') },
          },
        }),
      );
    }

    setOpenRowHeaderModal(false);
  };

  return (
    <Stack component="form" onSubmit={methods.handleSubmit(onClickOk)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 1 }}>
          <Label
            role="message"
            color="info"
            variant="ghost"
            sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
            {t('enqueteCreate.headerSettingModal.matrixHeaderDescription')}
          </Label>
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
          <Grid item xs={12} md={12}>
            <RHFTextField
              placeholder={t('enqueteCreate.headerSettingModal.rowHeaders')}
              name="rowHeaders"
              defaultValue={rowHeaderSetting}
              control={methods.control}
              fullWidth
              size="small"
              label={t('enqueteCreate.headerSettingModal.rowHeaders')}
              variant="outlined"
              rules={rulesRowColumnHeader}
            />
          </Grid>
        </Grid>

        <Grid item container justifyContent="right" alignItems="right" xs={12} md={12} spacing={1}>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <StyledButton
              color="error"
              onClick={() => {
                setOpenRowHeaderModal(false);
              }}>
              {t('button.cancel')}
            </StyledButton>
            <StyledButton onClick={methods.handleSubmit(onClickOk)} isDisabled={!methods.formState.isValid}>
              {t('button.setting')}
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HeaderSettingForm;
