import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SurveyEditEntity } from 'src/api/useSurveyEditApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { MailFormDataProps } from '../../../pages/response-request-mail/hooks/useRequestMailForm';
import useSendConfirmationModal from '../hooks/useSendConfirmationModal';

//ページコンポーネント
interface Props {
  isOpenConfirmModal: boolean;
  onCloseConfirmModal: () => void;
  mailFormData: MailFormDataProps;
  rowData: SurveyEditEntity[];
  attachedFiles: File[];
}

const SendConfirmationModal = ({ isOpenConfirmModal, onCloseConfirmModal, mailFormData, rowData, attachedFiles }: Props) => {
  const { t } = useTranslation();
  const { isSending, sendMail } = useSendConfirmationModal({ onCloseConfirmModal, mailFormData, rowData, attachedFiles });

  return (
    <StyledModal
      isOpen={isOpenConfirmModal}
      onCloseFunc={() => onCloseConfirmModal()}
      modalTitle={t('enqueteCreate.responseRequest.sendingConfirmationModal.title')}
      children={
        <Grid container direction={'column'}>
          <Grid item justifyContent="center" sx={{ pb: 2, whiteSpace: 'pre-line' }}>
            <Typography>{t('enqueteCreate.responseRequest.sendingConfirmationModal.description')} </Typography>
          </Grid>

          {isSending && (
            <Grid item sx={{ textAlign: 'center' }}>
              <FontAwesomeIcon icon={faSpinner} spin={true} size={'2x'} />
            </Grid>
          )}

          <Grid item sx={{ pt: 2 }}>
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
              <StyledButton onClick={() => onCloseConfirmModal()} color="error">
                {t('button.cancel')}
              </StyledButton>
              <StyledButton onClick={sendMail}>{t('button.ok')}</StyledButton>
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
};

export default SendConfirmationModal;
