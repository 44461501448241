import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, RootState, useAppSelector } from 'src/redux/store';
import { setValidSheetNames, updateSheetNames, UpdateSheetNamesType } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { MainTableSheetNamesType } from 'src/features/general/enquete-create/store/tableSettingSlice';

type useMainSheetNameFormType = {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  isValid: boolean;
  formLabel: string;
  formName: string;
  index: number;
};

export const useMainSheetNameForm = ({ watch, setValue, isValid, formLabel, formName, index }: useMainSheetNameFormType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // 日本語のフォームかを判定
  const isJapaneseForm: boolean = formLabel === TABLE_SETTINGS.MAIN_TABLE_SHEET_NAME_JPN_PATH;
  // シート名検証管理配列に検証結果を保存するIndexを定義[true, true] ※ Index0 > 日本語シート名検証結果, Index1 > 英語シート名検証結果
  const languageIndex: number = isJapaneseForm ? 0 : 1;

  // ストア
  const { mainTableSheetNames, validSheetNames } = useAppSelector((state: RootState) => state.tableSetting);
  // useState
  const [invalidSheet, setInvalidSheet] = useState<string>('');
  // フォーム監視
  const formToWatch: string = watch(formName);

  // Excelのシート名の入力制限に抵触していないか検証
  const validateEnableSheetName = useMemo(
    () => (inputData: string) => {
      const enableSheetNamePattern = new RegExp(TABLE_SETTINGS.SHEET_NAME_REG);
      const isInvalidSheetName = enableSheetNamePattern.test(inputData);
      return isInvalidSheetName ? t('validateError.format.sheetName') : null;
    },
    [t],
  );

  // 渡された配列から曖昧検索(大文字・小文字問わず)し、 渡された文字と比較し一致しているかを真偽で返却
  const isMatched = (sheetNames: string[], input: string): boolean => {
    return sheetNames.some((item) => item.toUpperCase() === input.toUpperCase());
  };

  // シート名の重複を検証し、結果を返却
  const validateDuplicateSheetName = useMemo(
    () => (inputData: string) => {
      const sheetName = isJapaneseForm ? 'sheetNameJpn' : 'sheetNameEng';

      // 入力中タブ以外のシート名リストを定義
      const sheetNames = mainTableSheetNames.filter((_, i: number) => i !== index).map((table: MainTableSheetNamesType) => table[sheetName]);
      // シート名リストの中から入力中のシート名と重複しているか検証
      const isIncludedSheetName = sheetNames.length > 0 && isMatched(sheetNames, inputData);

      // 重複している→メッセージを返却、重複していない→null
      return isIncludedSheetName ? t('validateError.duplicated', { target: t(formLabel) }) : null;
    },
    [t, mainTableSheetNames, formLabel, isJapaneseForm, index],
  );

  // シート名バリデーション
  const rulesSheetName = {
    required: t('validateError.required', { target: t(invalidSheet) }),
    maxLength: {
      value: TABLE_SETTINGS.MAX_SHEET_STRING_LENGTH,
      message: t('validateError.maxLength', { max: TABLE_SETTINGS.MAX_SHEET_STRING_LENGTH }),
    },
    validate: { validateEnableSheetName, validateDuplicateSheetName },
  };

  // シート名をストアに保存
  const setSheetNameData = debounce(() => {
    // 更新された内容をストアへ保存
    let updateSheetName: UpdateSheetNamesType;
    if (isJapaneseForm) {
      updateSheetName = { sheetNameJpn: formToWatch };
      setInvalidSheet(TABLE_SETTINGS.MAIN_TABLE_SHEET_NAME_JPN_PATH);
    } else {
      updateSheetName = { sheetNameEng: formToWatch };
      setInvalidSheet(TABLE_SETTINGS.MAIN_TABLE_SHEET_NAME_ENG_PATH);
    }

    dispatch(updateSheetNames({ tabIndex: index, data: updateSheetName }));
  }, TABLE_SETTINGS.DEBOUNCE_TIME);

  useEffect(() => {
    // シート名検証の状態をストアのシート名検証管理配列に格納
    // 回答表追加処理内の“addValidSheetNames”よりも、このCustom Hookが発火するため、事前に存在確認を行い
    // 追加された回答表のシート名コンポーネントがレンダリングされたタイミングで、本処理を実行
    if (validSheetNames[index] !== undefined) {
      dispatch(setValidSheetNames({ tabIndex: index, languageIndex: languageIndex, valid: isValid }));
    }

    // シート名をストアに保存
    setSheetNameData();

    // クリーンアップ関数でdebounced関数をキャンセル
    // debounce関数で非同期に処理しているため、関数内のsetInvalidSheetが実行時に
    // アンマウントされるエラーの対処
    return () => {
      setSheetNameData.cancel();
    };
  }, [isValid, formToWatch, index]); // eslint-disable-line

  // バリデーション実行し、フォーム値を更新
  useEffect(() => {
    if (mainTableSheetNames.length === 0) return;

    if (isJapaneseForm) {
      setValue(formName, mainTableSheetNames[index].sheetNameJpn, { shouldValidate: true });
    } else {
      setValue(formName, mainTableSheetNames[index].sheetNameEng, { shouldValidate: true });
    }
  }, [mainTableSheetNames]); // eslint-disable-line

  return { rulesSheetName };
};
