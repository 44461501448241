import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectModal } from 'src/components/app-components/bonsai/SelectModal';
import { useMemberListCreateModal } from '../hooks/useMemberListCreateModal';

export interface Props {
  open: boolean;
  onClose: () => void;
}

export const MemberListCreateModal: React.FC<Props> = (props) => {
  const { contextMenu, onSelected, onGridReady, conditions, columnDefs, updateUserSuggest, threadUsers } = useMemberListCreateModal();
  const { t } = useTranslation();
  useEffect(() => {
    if (props.open) updateUserSuggest();
  }, [props.open]); //eslint-disable-line

  return (
    <SelectModal
      title={t('enqueteThread.create.createMember')}
      open={props.open}
      conditions={conditions}
      table={{
        columnDefs: columnDefs,
        rowData: threadUsers,
        onGridReady,
        contextMenu: contextMenu,
      }}
      onClose={props.onClose}
      onAdd={onSelected}
    />
  );
};
