import { Grid, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { useAppSelector } from 'src/redux/store';
import RHFInputTypeAutoComplete from './RHFInputTypeAutoComplete';

type EnqueteTypeFormProps = {
  methods: UseFormReturn;
  enqueteTypeData: SurveyHeaderEntity;
};

export const EnqueteTypeForm = ({ methods, enqueteTypeData }: EnqueteTypeFormProps) => {
  const enqueteTypeList = useAppSelector((state) => state.enqueteInfo.enqueteTypeList);
  const { rulesType } = useValidator(methods);
  const watchDisabled = methods.watch('labelJpn')?.id || !methods.watch('ownerOrganization')?.key ? true : false;
  const { t } = useTranslation();

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
            {t('enqueteGeneralCommon.enqueteTypeJpn')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <RHFInputTypeAutoComplete
            name="labelJpn"
            label={t('enqueteGeneralCommon.enqueteTypeJpn')}
            options={enqueteTypeList}
            defaultValue={enqueteTypeData}
            methods={methods}
            rules={rulesType}
          />
        </Grid>
      </Grid>
      {/* アンケートタイプ（英語） */}
      <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
            {t('enqueteGeneralCommon.enqueteTypeEng')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <RHFTextField
            placeholder={t('enqueteGeneralCommon.enqueteTypeEng')}
            name="labelEng"
            defaultValue={enqueteTypeData.labelEng}
            control={methods.control}
            fullWidth
            size="small"
            label={t('enqueteGeneralCommon.enqueteTypeEng')}
            variant="outlined"
            rules={rulesType}
            disabled={watchDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
};
