import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnqueteStatusListTab from '../../shared-tabs/enquete-status-list/components/EnqueteStatusListTab';

export const EnqueteStatusTabContainer: React.FC = () => {
  const { t } = useTranslation();

  //タブ
  const [value, setValue] = useState('1');

  //タブ切り替え関数
  const handleChange = (_e: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange}>
        <Tab label={t('enqueteStatus.tab.answerStatus')} value="1" />
      </TabList>
      <TabPanel value="1">
        <EnqueteStatusListTab />
      </TabPanel>
    </TabContext>
  );
};
