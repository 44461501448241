import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { DISCLOSURE_LEVEL } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { formatterDateTime } from 'src/utils/formatDateTime';

const EnqueteRequestListColDef = (): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  const colDefList: (ColDef | ColGroupDef)[] = [
    {
      field: 'serialNumber',
      tooltipField: 'serialNumber',
      headerName: 'No.',
      headerTooltip: 'No.',
      valueGetter: 'node.rowIndex + 1',
      minWidth: 20,
      width: 70,
      suppressMenu: true,
      flex: 0,
      sortable: false,
    },
    {
      field: currentLang.value === 'ja' ? 'ownerDepartmentJpn' : 'ownerDepartmentEng',
      tooltipField: currentLang.value === 'ja' ? 'ownerDepartmentJpn' : 'ownerDepartmentEng',
      headerName: t('enqueteRequest.gridColDef.ownerDepartment'),
      headerTooltip: t('enqueteRequest.gridColDef.ownerDepartment'),
      minWidth: 30,
      width: 170,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'labelJpn' : 'labelEng',
      tooltipField: currentLang.value === 'ja' ? 'labelJpn' : 'labelEng',
      headerName: t('enqueteRequest.gridColDef.label'),
      headerTooltip: t('enqueteRequest.gridColDef.label'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'titleJpn' : 'titleEng',
      tooltipField: currentLang.value === 'ja' ? 'titleJpn' : 'titleEng',
      headerName: t('enqueteRequest.gridColDef.title'),
      headerTooltip: t('enqueteRequest.gridColDef.title'),
      minWidth: 150,
      width: 300,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'isRequestStatusOpen',
      tooltipField: 'isRequestStatusOpen',
      headerName: t('enqueteRequest.gridColDef.requestStatus'),
      headerTooltip: t('enqueteRequest.gridColDef.requestStatus'),
      valueGetter: (params) => {
        const currentDateMilliSecond = new Date().getTime();
        const opendAtMilliSecond = new Date(params.data.openedAt).getTime();
        const closedAtMilliSecond = new Date(params.data.closedAt).getTime();
        let requestStatusMessage: string = '';

        if (currentDateMilliSecond < opendAtMilliSecond) {
          //現在日時よりも開始日時が先の場合
          requestStatusMessage = t('enqueteRequest.requestStatus.beforeRequest');
        } else if (closedAtMilliSecond < currentDateMilliSecond) {
          //終了日時よりも現在日時が先の場合
          requestStatusMessage = t('enqueteRequest.requestStatus.complete');
        } else {
          //現在日時が、開始日時以上 - 終了日時以内の場合
          requestStatusMessage = t('enqueteRequest.requestStatus.inProgress');
        }

        return requestStatusMessage;
      },
      minWidth: 150,
      width: 100,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
        values: [
          t('enqueteRequest.requestStatus.beforeRequest'),
          t('enqueteRequest.requestStatus.complete'),
          t('enqueteRequest.requestStatus.inProgress'),
        ],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'disclosureLevel',
      tooltipField: 'disclosureLevel',
      headerName: t('enqueteRequest.gridColDef.disclosureLevel'),
      headerTooltip: t('enqueteRequest.gridColDef.disclosureLevel'),
      valueGetter: (params) => {
        if (params.data.disclosureLevel === DISCLOSURE_LEVEL.PUBLIC) {
          return t('enqueteRequest.disclosureLevel.public');
        } else if (params.data.disclosureLevel === DISCLOSURE_LEVEL.INTERNAL) {
          return t('enqueteRequest.disclosureLevel.internal');
        } else {
          return t('enqueteRequest.disclosureLevel.private');
        }
      },
      minWidth: 150,
      width: 100,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
        values: [
          t('enqueteRequest.disclosureLevel.private'),
          t('enqueteRequest.disclosureLevel.internal'),
          t('enqueteRequest.disclosureLevel.public'),
        ],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'openedAt',
      headerName: t('enqueteRequest.gridColDef.openedAt'),
      headerTooltip: t('enqueteRequest.gridColDef.openedAt'),
      valueGetter: (params) => {
        return formatterDateTime(params.data.openedAt) as string;
      },
      tooltipValueGetter: (params: any) => {
        return formatterDateTime(params.data.openedAt) as string;
      },
      minWidth: 80,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: [
          {
            displayKey: 'inRange',
            displayName: 'inRange',
            numberOfInputs: 2,
            predicate: ([fv1, fv2]: [Date, Date], cellValue: string) => {
              if (cellValue == null) return true;
              const cellDate = new Date(cellValue);
              fv2.setDate(fv2.getDate() + 1);
              return cellDate.getTime() >= fv1.getTime() && cellDate.getTime() <= fv2.getTime();
            },
          },
        ],
        inRangeInclusive: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'closedAt',
      headerName: t('enqueteRequest.gridColDef.closedAt'),
      headerTooltip: t('enqueteRequest.gridColDef.closedAt'),
      valueGetter: (params) => {
        return formatterDateTime(params.data.closedAt) as string;
      },
      tooltipValueGetter: (params: any) => {
        return formatterDateTime(params.data.closedAt) as string;
      },
      minWidth: 80,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: [
          {
            displayKey: 'inRange',
            displayName: 'inRange',
            numberOfInputs: 2,
            predicate: ([fv1, fv2]: [Date, Date], cellValue: string) => {
              if (cellValue == null) return true;
              const cellDate = new Date(cellValue);
              fv2.setDate(fv2.getDate() + 1);
              return cellDate.getTime() >= fv1.getTime() && cellDate.getTime() <= fv2.getTime();
            },
          },
        ],
        inRangeInclusive: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'createdBy',
      tooltipField: 'createdBy',
      headerName: t('enqueteRequest.gridColDef.createdBy'),
      headerTooltip: t('enqueteRequest.gridColDef.createdBy'),
      minWidth: 80,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'id',
      tooltipField: 'id',
      headerName: t('enqueteRequest.gridColDef.id'),
      headerTooltip: t('enqueteRequest.gridColDef.id'),
      minWidth: 140,
      width: 100,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      flex: 1,
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return colDefList;
};

export default EnqueteRequestListColDef;
