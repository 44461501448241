import { ThreadUser } from 'src/api/getCommentsApi';

export const getOrganizationName = (data: Omit<ThreadUser, 'permission'> | null, isJapanese: boolean): string => {
  if (!data) return '';
  // memo : 表示する組織を定義
  const attributes = ['company', 'division', 'sbu', 'department', 'group'];
  return getJoinedName(data, attributes, isJapanese);
};

export const getUserName = (data: Omit<ThreadUser, 'permission'> | null, isJapanese: boolean): string => {
  if (!data) return '';
  const attributes = ['user'];
  return getJoinedName(data, attributes, isJapanese);
};

const getJoinedName = (data: Omit<ThreadUser, 'permission'>, attributes: string[], isJapanese: boolean) => {
  return attributes
    .map((attribute) => {
      const keyJpn = `${attribute}NameJpn` as keyof Omit<ThreadUser, 'permission'>;
      const keyEng = `${attribute}NameEng` as keyof Omit<ThreadUser, 'permission'>;
      // 日本語の場合は日本語を優先、英語の時は英語を優先する
      return isJapanese ? data[keyJpn] || data[keyEng] : data[keyEng] || data[keyJpn];
    })
    .filter((name) => name)
    .join(' ')
    .trim();
};
