import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useUserAgGridColDef } from 'src/components/app-components/bonsai/hooks/useUserAgGridColDef';

export const useSelectRespondentsAgGrid = () => {
  const { defaultColDef, userAgGridColDef } = useUserAgGridColDef({
    companyCodeColName: 'personCompanyCode',
    emailColDefSetting: { key: 'email' },
    fullNameColDefSetting: { key: 'userName' },
    companyNameColDefSetting: { key: 'personCompanyName' },
    companyAbbreviationColDefSetting: { key: 'personCompanyAbbreviation' },
    assignedCompanyColDefSetting: { key: 'assignedCompanyName' },
    divisionNameColDefSetting: { key: 'personDivisionName' },
    sbuNameColDefSetting: { key: 'personSbuName' },
    departmentNameColDefSetting: { key: 'personDepartmentName' },
    groupNameColDefSetting: { key: 'personGroupName' },
  });

  //-------------------------------------------------------------
  // カラム定義
  //-------------------------------------------------------------
  // def variables
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'serialNumber',
      tooltipField: 'serialNumber',
      headerName: 'No.',
      valueGetter: 'node.rowIndex + 1',
      minWidth: 30,
      width: 70,
      suppressMenu: true,
      flex: 0,
    },
    ...userAgGridColDef,
    {
      field: '-',
      headerName: '',
      cellRenderer: 'threeDotMenuRenderer',
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return { columnDefs, defaultColDef };
};
