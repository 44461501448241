import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

type QuestionNumberGridProps = {
  questionsNo: number;
};

const QuestionNumberGrid = ({ questionsNo }: QuestionNumberGridProps) => {
  const { t } = useTranslation();
  return (
    <Grid>
      {t('enqueteCreate.questionNo')}
      {questionsNo}
    </Grid>
  );
};

export default QuestionNumberGrid;
