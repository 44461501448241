import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { SetInputItemModal } from 'src/features/general/enquete-create/components/modals/set-input-item/components/SetInputItemModal';
import OverviewQuestions from './OverviewQuestions';

export const QUESTION_TYPE = {
  PURCHASE: 'PURCHASE',
  SALES: 'SALES',
  OTHER: 'OTHER',
} as const;
export type QuestionType = typeof QUESTION_TYPE[keyof typeof QUESTION_TYPE];

const OverviewInputEditBox = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  return (
    <>
      <StyledBox>
        {/** StyledBoxの影響で左に寄ってしまうため真ん中にするためにmarginLeftを設定 */}
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} marginLeft={'-8px'}>
          {/** 買い取引の入力項目 **/}
          <OverviewQuestions questionType={QUESTION_TYPE.PURCHASE} />
          {/** 売り取引の入力項目 **/}
          <OverviewQuestions questionType={QUESTION_TYPE.SALES} />
          {/** その他の入力項目 **/}
          <OverviewQuestions questionType={QUESTION_TYPE.OTHER} />
          {/** 入力項目を追加するボタン **/}
          <StyledButton
            onClick={() => {
              setIsOpen(true);
            }}
            children={t('enqueteCreate.overviewSurvey.button.addInputItem')}
            sx={{ marginTop: '16px' }}
          />
        </Grid>
      </StyledBox>

      {/** モーダル **/}
      <SetInputItemModal isOpen={isOpen} setIsOpen={setIsOpen} isEdit={isEdit} setIsEdit={setIsEdit} questionType={QUESTION_TYPE.PURCHASE} />
    </>
  );
};

export default OverviewInputEditBox;
