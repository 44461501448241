import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { AnswerStatus } from 'src/constants';
import { useBearerToken } from './useBearerToken';

export interface AnswerHistoryEntity {
  id: string;
  answerId: string | undefined | null;
  userId: string;
  userName: string | null;
  userNameEng: string | null;
  answerData: JSON;
  status: AnswerStatus;
  respondentTargetId: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string | null;
  updatedAt: Date | null;
}

export const useAnswerHisotryApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 回答履歴取得用のAPI
   * @param answerId
   * @returns
   */
  const get = async (answerId: string): Promise<AnswerHistoryEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<AnswerHistoryEntity[]>(apiGateway + '/enquete-answer-input/answer-histories/' + answerId, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useAnswerHistoryApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    get,
  };
};
