import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FORM_TYPE, FormType } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import InputRadioGroup from './InputRadioGroup';

type EnqueteQuestionFormatFormProps = {
  methods: UseFormReturn;
  formType: FormType;
};

export const EnqueteQuestionFormatForm = ({ methods, formType }: EnqueteQuestionFormatFormProps) => {
  const { enqueteQuestionFormatRules } = useValidator(methods);
  const watchDisabled = methods.watch('labelJpn')?.id || !methods.watch('ownerOrganization')?.key ? true : false;
  const { t } = useTranslation();

  return (
    <InputRadioGroup
      name={'enqueteQuestionFormat'}
      defaultValue={formType}
      control={methods.control}
      rules={enqueteQuestionFormatRules}
      getValues={methods.getValues}
      list={[
        { value: FORM_TYPE.QA, label: t('enqueteGeneralCommon.enqueteQuestionFormatQA') },
        { value: FORM_TYPE.TABLE, label: t('enqueteGeneralCommon.enqueteQuestionFormatTable') },
      ]}
      disabled={watchDisabled}
    />
  );
};
