import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SeriesSurveyFormAggregationData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { enqueteCreateSlice } from 'src/features/general/enquete-create/store/enqueteCreateSlice';

export interface SeriesEnqueteRowData {
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  divisionOrganizationId: string;
  destinationSbuCode: string;
  destinationSbuNameJpn: string;
  destinationSbuNameEng: string;
  destinationSbuOrganizationId: string;
  sourceSbuCode: string;
  sourceSbuNameJpn: string;
  sourceSbuNameEng: string;
  sourceSbuOrganizationId: string;
}

export const useEnqueteModal = () => {
  const dispatch = useDispatch();

  // モーダル オープン
  const openEditModal = useCallback(() => {
    dispatch(enqueteCreateSlice.actions.setIsOpenSbuAggregationModal(true));
  }, []); // eslint-disable-line

  // モーダル クローズ
  const closeEditModal = useCallback(() => {
    dispatch(enqueteCreateSlice.actions.setIsOpenSbuAggregationModal(false));
  }, []); // eslint-disable-line

  // 編集モード変更
  const modalEditMode = useCallback(() => {
    dispatch(enqueteCreateSlice.actions.setIsEditSbuAggregationModal(true));
  }, []); // eslint-disable-line

  // 通常モード変更
  const modalNormalMode = useCallback(() => {
    dispatch(enqueteCreateSlice.actions.setIsEditSbuAggregationModal(false));
  }, []); // eslint-disable-line

  /**
   * アンケートデータを画面表示用に加工する
   * @param data SBU集約情報
   * @param isModal モーダル表示の場合true
   * @returns 画面表示用構造のSBU集約情報
   */
  const toViewData = (data: SeriesSurveyFormAggregationData[] | undefined, isModal: boolean = false) => {
    return data
      ?.map((v) => {
        return v.sbu
          .map((s, divisionIndex) => {
            const result = s.source.map((val, destinationIndex) => {
              return {
                divisionCode: v.divisionCode,
                divisionNameJpn:
                  destinationIndex === 0 && divisionIndex === 0 ? (isModal ? `${v.divisionNameJpn} (${v.divisionCode})` : v.divisionNameJpn) : '', // 同値は表示しないため空として表示
                divisionNameEng:
                  destinationIndex === 0 && divisionIndex === 0 ? (isModal ? `${v.divisionNameEng} (${v.divisionCode})` : v.divisionNameEng) : '',
                destinationSbuCode: s.destination.sbuCode,
                destinationSbuNameJpn:
                  destinationIndex === 0 ? (isModal ? `${s.destination.sbuNameJpn} (${s.destination.sbuCode})` : s.destination.sbuNameJpn) : '',
                destinationSbuNameEng:
                  destinationIndex === 0 ? (isModal ? `${s.destination.sbuNameEng} (${s.destination.sbuCode})` : s.destination.sbuNameEng) : '',
                sourceSbuCode: val.sbuCode,
                sourceSbuNameJpn: isModal ? `${val.sbuNameJpn} (${val.sbuCode})` : val.sbuNameJpn,
                sourceSbuNameEng: isModal ? `${val.sbuNameEng} (${val.sbuCode})` : val.sbuNameEng,
              };
            });
            return result;
          })
          .flat(); // 多重配列になるためflat化;
      })
      .flat(); // 多重配列になるためflat化;
  };

  return { openEditModal, closeEditModal, modalEditMode, modalNormalMode, toViewData };
};
