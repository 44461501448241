import { SxProps, TextField } from '@mui/material';
import { isUndefined } from 'lodash';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

export interface EnqueteFormDataEditProps {
  enqueteData: RespondentRequestEntity;
  surveyFormData: string | undefined;
  setSurveyFormData: React.Dispatch<React.SetStateAction<string | undefined>>;
  isAdminRoleUser: boolean;
}

const EnqueteFormDataEdit: React.FC<EnqueteFormDataEditProps> = (props) => {
  const { enqueteData, surveyFormData, setSurveyFormData, isAdminRoleUser } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  //アンケートフォームデータ定義
  const setSurveyFormDataState = () => {
    if (isUndefined(enqueteData.surveyDetail)) return;

    setSurveyFormData(JSON.stringify(enqueteData.surveyDetail.surveyFormData, null, '\t'));
  };

  //初回読み込み
  useEffect(() => {
    setSurveyFormDataState();
  }, [enqueteData]); //eslint-disable-line

  //SX Styles
  const sxStyles: SxProps = {
    bgcolor: isLight ? palette.light.background.default : palette.dark.background.paper,
  };

  return (
    <TextField
      id="outlined-basic"
      label={t('enqueteCreate.textfield.formData.placeholder')}
      fullWidth
      multiline
      rows={30}
      value={surveyFormData}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setSurveyFormData(e.target.value)}
      sx={sxStyles}
      disabled={!isAdminRoleUser}
    />
  );
};

export default EnqueteFormDataEdit;
