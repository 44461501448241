import { RespondentRequestEntity as apiRespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { RespondentRequestEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useStatus from 'src/hooks/useStatus';
import i18n from 'src/locales/i18n';
import { useAppSelector } from 'src/redux/store';
import { formatterDateTime } from 'src/utils/formatDateTime';
import { getResponseOrganizationInfo } from 'src/utils/getResponseOrganizationInfo';

export const useEnqueteAnswerHeaderInfo = (respondentRequestData: (RespondentRequestEntity | apiRespondentRequestEntity) | undefined) => {
  const { toStringAnswerStatus } = useStatus();

  const enqueteAnswer = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  const enqueteAnswerStatus = toStringAnswerStatus(enqueteAnswer?.status);

  const language = i18n.language;
  const enqueteTitle =
    undefined !== respondentRequestData ? (language === 'ja' ? respondentRequestData.titleJpn : respondentRequestData.titleEng) : '';

  const requestStatusTerm =
    undefined !== respondentRequestData && 'openedAt' in respondentRequestData
      ? `${formatterDateTime(respondentRequestData.openedAt)} 〜 ${formatterDateTime(respondentRequestData.closedAt)}`
      : '';

  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  // fixme: organization の型定義が any になっているため、リファクタリング必須
  const organization = undefined !== respondentTargetData ? respondentTargetData.organization : [];
  const enqueteAnswerOrganization = getResponseOrganizationInfo(organization, language);

  return {
    enqueteAnswerStatus,
    enqueteTitle,
    requestStatusTerm,
    enqueteAnswerOrganization,
  };
};
