import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface CurrencyRow {
  currencyCode: string;
  displayOrder: number;
  expirationDate: Date | null;
  numberOfDecimalPlaces?: number;
}
export interface ResCurrency {
  currencies: CurrencyRow[];
}

export const useCurrencyApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getAllCurrency = async (respondentTargetId: string): Promise<ResCurrency | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<ResCurrency>(`${apiGateway}/currency/${respondentTargetId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useCurrencyApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getAllCurrency };
};
