import { Alert, Box, Grid, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledBox } from 'src/components/app-components/StyledBox';
import RHFAmountField from 'src/components/react-hook-form/RHFAmountField';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import {
  setPurchaseAdditionalQuestions,
  setPurchaseFixedAmount,
  setPurchaseFixedCommercialFlow,
  setPurchaseFixedMainCustomer,
  setPurchaseFixedProduct,
} from 'src/features/general/enquete-answer/store/enqueteOverviewAnswerSlice';
import useLocales from 'src/hooks/useLocales';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerValidator } from '../hooks/useEnqueteAnswerValidator';

export interface EnqueteAnswerPurchaseFormProps {
  methods: UseFormReturn<FieldValues>;
}

const EnqueteAnswerPurchaseForm: React.FC<EnqueteAnswerPurchaseFormProps> = (props) => {
  const { methods } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isJapanese } = useLocales();
  const { purchaseFixedAmount, purchaseFixedQuestions, purchaseAdditionalQuestions, notConvertPurchaseAmount } = useAppSelector(
    (state) => state.enqueteOverviewAnswer,
  );
  const { rulesEnqueteAnswerAmount, rulesEnqueteAnswerTarget } = useEnqueteAnswerValidator();

  const prefix = 'purchase-';
  return (
    <>
      <Box>{t('enqueteAnswerInput.overviewSurvey.purchaseTitle')}</Box>
      <StyledBox>
        <Grid container direction="column" rowSpacing={2}>
          <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columns={18} columnSpacing={1}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">{t('enqueteAnswerInput.overviewSurvey.amount')}</Typography>
            </Grid>
            <Grid item xs={5} md={true}>
              <RHFAmountField
                control={methods.control}
                name={`${prefix}amount}`}
                rules={rulesEnqueteAnswerAmount(purchaseFixedAmount.isAnswerTarget)}
                handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  // TODO purchaseFixedAmountのanswerはdecimal型であるべきだが、現状stringでstateにセットできるようになっている
                  dispatch(setPurchaseFixedAmount(event.target.value));
                }}
                defaultValue={purchaseFixedAmount.answer}
                textFieldProps={{
                  size: 'small',
                }}
                disabled={!purchaseFixedAmount.isAnswerTarget}
              />
            </Grid>

            {purchaseFixedQuestions.product.isAnswerTarget && (
              <>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">{t('enqueteAnswerInput.overviewSurvey.product')}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <RHFonChangeTextField
                    name={`${prefix}product}`}
                    defaultValue={purchaseFixedQuestions.product.answer}
                    control={methods.control}
                    size="small"
                    variant="outlined"
                    rules={rulesEnqueteAnswerTarget(t('enqueteAnswerInput.overviewSurvey.product'), false)}
                    handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      dispatch(setPurchaseFixedProduct(event.target.value));
                    }}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container direction="row" columns={18}>
            <Grid item xs={3}></Grid>
            <Grid item xs={5}>
              {notConvertPurchaseAmount && notConvertPurchaseAmount.length !== 0 && purchaseFixedAmount.isAnswerTarget && (
                <Alert severity="warning" sx={{ marginRight: 5, marginTop: 1 }}>
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1">{t('enqueteAnswerInput.overviewSurvey.notConvertedAmount')}：</Typography>
                    </Grid>
                    <Grid item>
                      {notConvertPurchaseAmount.map((value, index) => (
                        <>
                          <Typography>{`${t('common.currencyCode')}:${value.currencyCode}`}</Typography>
                          <Typography>{`${t('enqueteAnswerInput.overviewSurvey.amount')}:${Number(value.amount).toLocaleString()}`}</Typography>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Alert>
              )}
            </Grid>
          </Grid>
          {purchaseFixedQuestions.mainCustomer.isAnswerTarget && (
            <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columns={18} columnSpacing={1}>
              <Grid item xs={3}>
                <Typography variant="subtitle1">{t('enqueteAnswerInput.overviewSurvey.mainCustomer')}</Typography>
              </Grid>
              <Grid item xs={15}>
                <RHFonChangeTextField
                  name={`${prefix}mainCustomer}`}
                  defaultValue={purchaseFixedQuestions.mainCustomer.answer}
                  control={methods.control}
                  size="small"
                  variant="outlined"
                  rules={rulesEnqueteAnswerTarget(t('enqueteAnswerInput.overviewSurvey.mainCustomer'), false)}
                  handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    dispatch(setPurchaseFixedMainCustomer(event.target.value));
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {purchaseFixedQuestions.commercialFlow.isAnswerTarget && (
            <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columns={18} columnSpacing={1}>
              <Grid item xs={3}>
                <Typography variant="subtitle1">{t('enqueteAnswerInput.overviewSurvey.commercialFlow')}</Typography>
              </Grid>
              <Grid item xs={15}>
                <RHFonChangeTextField
                  name={`${prefix}commercialFlow}`}
                  defaultValue={purchaseFixedQuestions.commercialFlow.answer}
                  control={methods.control}
                  size="small"
                  variant="outlined"
                  rules={rulesEnqueteAnswerTarget(t('enqueteAnswerInput.overviewSurvey.commercialFlow'), false)}
                  handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    dispatch(setPurchaseFixedCommercialFlow(event.target.value));
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          {purchaseAdditionalQuestions &&
            purchaseAdditionalQuestions.map((value, index) => (
              <>
                {value.isAnswerTarget && (
                  <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columns={18} columnSpacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">{isJapanese ? value.titleJpn : value.titleEng}</Typography>
                    </Grid>
                    <Grid item xs={15}>
                      <RHFonChangeTextField
                        key={`${prefix}AddQuestions${index}`}
                        name={`${prefix}AddQuestions${index}`}
                        defaultValue={value.answer}
                        control={methods.control}
                        size="small"
                        variant="outlined"
                        fullWidth
                        rules={rulesEnqueteAnswerTarget(
                          isJapanese ? value.titleJpn : value.titleEng,
                          value.inputType === 'textarea',
                          value.isRequired,
                        )}
                        multiline={value.inputType === 'textarea'}
                        rows={value.inputType === 'textarea' ? 3 : 1}
                        handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          dispatch(setPurchaseAdditionalQuestions({ value: event.target.value, index }));
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      </StyledBox>
    </>
  );
};

export default EnqueteAnswerPurchaseForm;
