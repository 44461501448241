import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Comment } from 'src/api/getCommentsApi';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { checkIfAPIResponseHasError } from 'src/utils/checkIfAPIResponseHasError';
import { getOrganizationName, getUserName } from 'src/utils/getThreadUserInfo';
import { ThreadUserInfoBox } from '../../common/components/ThreadUserInfoBox';
import { useComments } from '../hooks/useComments';
import { CommentForm } from './CommentForm';
import { ThreadCommentCard } from './ThreadCommentCard';
import { ThreadDescriptionCard } from './ThreadDescriptionCard';
import { ThreadHeaderBox } from './ThreadHeaderBox';

export const EnqueteThreadLayout = () => {
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const { id } = useParams() as { id: string };

  useComments(id);
  const enqueteComments = useAppSelector((state) => state.enqueteComments.comment);
  const threadStatus = useAppSelector((state) => state.enqueteComments.status);
  const hasError = checkIfAPIResponseHasError(threadStatus);
  const creatorName = getUserName(enqueteComments.createdBy, isJapanese);
  const creatorOrganizationName = getOrganizationName(enqueteComments.createdBy, isJapanese);

  if (threadStatus === 'pending' || threadStatus === '') {
    return <StyledLoading isOpen={true} />;
  }

  if (hasError) {
    return (
      <Typography variant="h4" textAlign="center">
        {t('apiExceptionMessage.common.failedFetch', { target: t('enqueteThread.commentList') })}
      </Typography>
    );
  }

  return (
    <Grid item xs={12} gap={2}>
      <Card sx={{ borderRadius: 2, boxShadow: 2, pl: 2, pt: 1, pr: 2 }}>
        <ThreadHeaderBox thread={enqueteComments} />
        <ThreadUserInfoBox userName={creatorName} organizationName={creatorOrganizationName} updatedAt={enqueteComments.updatedAt} sx={{ pl: 0 }} />
        <ThreadDescriptionCard description={enqueteComments.description} />
      </Card>

      {enqueteComments.comments.length > 0 && (
        <>
          <Divider variant="middle" sx={{ width: '100%', mt: 2, mb: 2, ml: 0, mr: 0 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="h4" sx={{ alignSelf: 'center', mr: 2 }}>
              {t('enqueteThread.detail.reply')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('enqueteThread.common.commentCount', { variable: enqueteComments.comments.length })}
            </Typography>
          </Box>
          {enqueteComments.comments?.map((comment: Comment) => {
            return (
              <Box sx={{ ml: 5 }} key={comment.id}>
                <ThreadCommentCard commentProps={comment} />
              </Box>
            );
          })}
        </>
      )}
      <Divider variant="middle" sx={{ width: '100%', mt: 2, mb: 2, ml: 0, mr: 0 }} />
      <CommentForm threadId={id} />
    </Grid>
  );
};
