import { Box, Grid } from '@mui/material';
import type { ColDef, ColGroupDef, GetContextMenuItems, GetContextMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import StyledDialog from 'src/components/app-components/StyledDialog';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useComplementaryOrganizationListGrid } from '../hooks/useComplementaryOrganizationListGrid';
import { ORGANIZATION_FUTURE_PATH } from '../routes/path';
import ComplementaryOrganizationListColDef from './ComplementaryOrganizationListColDef';

export const ComplementaryOrganizationListGrid: React.FC = (): React.ReactElement => {
  const listData = useAppSelector((state) => state.complementaryOrganization.listData);
  const hook = useComplementaryOrganizationListGrid();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isViewerAdmin } = usePermission();

  //メッセージ定義
  const deleteConfirmDialogTitle = t('complementaryOrganizationList.message.deleteConfirmDialogTitle');
  const deleteConfirmDialogContent = t('complementaryOrganizationList.message.deleteConfirmDialogContent');

  // 削除ダイアログ
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<string>('');
  //ローディング
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  // 初回起動
  useEffect(() => {
    // データ取得
    getList();
  }, []); // eslint-disable-line

  // ColDef読み込み
  const coldef: (ColDef | ColGroupDef)[] = ComplementaryOrganizationListColDef();

  //グリッドデータ取得
  const getList = async () => {
    setIsLoading(true);
    await hook.getList();
    setIsLoading(false);
  };

  //ダブルクリック時の挙動定義
  const onRowDoubleClickedCallback = (event: RowDoubleClickedEvent) => {
    if (!isViewerAdmin) navigate(`${ORGANIZATION_FUTURE_PATH.INDEX}/${event.data.id}`);
  };

  //行を削除
  const onClickDelete = () => {
    hook.deleteRow(deleteRowId);
    setIsOpen(false);
    setDeleteRowId('');
  };

  //コンテキストメニュー
  const contextMenu: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('complementaryOrganizationList.contextMenu.edit'),
        action: () => {
          navigate(`${ORGANIZATION_FUTURE_PATH.INDEX}/${params.node?.data.id}`);
        },
        disabled: isViewerAdmin,
      },
      {
        name: t('complementaryOrganizationList.contextMenu.delete'),
        action: () => {
          setDeleteRowId(params.node?.data.id);
          setIsOpen(true);
        },
        disabled: isViewerAdmin,
      },
    ];
    return menuItems;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            sx={{
              height: '95vh',
              width: '100%',
            }}>
            <LoadableComponent isLoading={isLoading}>
              <StyledAgGrid coldef={coldef} rows={listData} contextMenu={contextMenu} onRowDoubleClickedCallback={onRowDoubleClickedCallback} />
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>

      {/* 削除ダイアログ */}
      <StyledDialog
        dialogTitle={deleteConfirmDialogTitle}
        dialogContent={deleteConfirmDialogContent}
        isOpen={isOpen}
        disagreeCallback={() => setIsOpen(false)}
        agreeCallback={onClickDelete}
      />
    </>
  );
};
