import { Box } from '@mui/material';
import { GetContextMenuItems, GetContextMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TemporaryOrganizationDataTypes, TemporaryOrganizationEntity, useTemporaryOrganizationApi } from 'src/api/useTemporaryOrganizationApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import useTemporaryOrganizationGrid from '../hooks/useTemporaryOrganizationGrid';
import { useTemporaryOrganizationHooks } from '../hooks/useTemporaryOrganizationHooks';

////// Interface //////
// Page Component Types
interface PageComponentTypes {
  isCodeHide: boolean;
  surveyDetailId: string;
  editRowCallback: (rowData: TemporaryOrganizationDataTypes) => void;
  isStatusComplete: boolean;
}

const TemporaryOrganizationGrid: React.FC<PageComponentTypes> = (props) => {
  const { isCodeHide, surveyDetailId, editRowCallback, isStatusComplete } = props;
  const { isViewerAdmin, isSurveyOwner } = usePermission(surveyDetailId);
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { t } = useTranslation();

  const { getTemporaryOrganizationList, deleteTemporaryOrganization } = useTemporaryOrganizationApi();
  const { startLoading, stopLoading } = useTemporaryOrganizationHooks();
  const { defaultColDef, orgListWithCodeColdef } = useTemporaryOrganizationGrid(isCodeHide);

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isLoading = useAppSelector((state) => state.temporaryOrganization.isLoading);
  const refreshCounter = useAppSelector((state) => state.temporaryOrganization.refreshCounter);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [rowData, setRowData] = useState<TemporaryOrganizationEntity[] | undefined>();
  const [offsetHeight, setOffsetHeight] = useState<string>('100vh');

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // 仮組織一覧 データ取得
  const refreshRowData = async (surveyDetailId: string) => {
    if (!surveyDetailId) return;

    startLoading();
    setRowData(await getTemporaryOrganizationList(surveyDetailId));
    stopLoading();
  };

  // 編集処理を定義
  const editRowData = (rowData: TemporaryOrganizationDataTypes) => editRowCallback(rowData);
  const onRowDoubleClickedCallback = (e: RowDoubleClickedEvent) => editRowCallback(e.data);

  // 行情報を削除
  const deleteRow = async (params: TemporaryOrganizationDataTypes) => {
    if (!params) return;
    startLoading();

    // 削除
    await deleteTemporaryOrganization(params.id, surveyDetailId);

    // 一覧のリフレッシュ(本関数内でローディング解除)
    await refreshRowData(surveyDetailId);
  };

  /***********************************************************************************
   * Context Menu
   ***********************************************************************************/
  const contextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.edit'),
        action: () => {
          if (params.node) editRowData(params.node.data);
        },
        disabled: isStatusComplete || (isViewerAdmin && !isSurveyOwner),
      },
      {
        name: t('button.delete'),
        action: () => {
          if (params.node) deleteRow(params.node.data);
        },
        disabled: isStatusComplete || (isViewerAdmin && !isSurveyOwner),
      },
    ];
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    // calc data table height
    const appHeader = document.getElementsByTagName('header');
    const pageHeader = document.getElementById('page-header');
    if (pageHeader && appHeader.length > 0) {
      setOffsetHeight(`calc(100vh - ${pageHeader?.clientHeight}px - ${appHeader[0]?.clientHeight}px)`);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    // 仮組織一覧 データ取得
    refreshRowData(surveyDetailId);
  }, [refreshCounter, surveyDetailId]); // eslint-disable-line

  return (
    <Box
      component={'div'}
      sx={{ width: '100%', height: offsetHeight }}
      className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
      data-testid={'aggrid'}>
      <LoadableComponent isLoading={isLoading} zIndex={1000}>
        <StyledAgGrid
          defaultColDef={defaultColDef}
          coldef={orgListWithCodeColdef}
          rows={rowData ?? []}
          contextMenu={contextMenuItems}
          onRowDoubleClickedCallback={onRowDoubleClickedCallback}
        />
      </LoadableComponent>
    </Box>
  );
};

export default TemporaryOrganizationGrid;
