import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import useLocales from 'src/hooks/useLocales';

type RHFTextAutoCompleteProps = {
  name: string;
  label: string;
  options: { labelJpn: string; labelEng: string }[];
  defaultValue: string;
  methods: UseFormReturn<FieldValues, any>;
  rules?: RegisterOptions;
  helperText?: string;
  minRows?: number | string;
  multiline?: boolean;
  disabled?: boolean;
  settingGlobalState?: (name: string, value: any) => void;
};

const RHFTextAutoComplete = ({
  name,
  label,
  defaultValue,
  methods,
  rules,
  helperText,
  options,
  settingGlobalState,
  ...rest
}: RHFTextAutoCompleteProps) => {
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const [optionsLabel, setOptionsLabel] = useState<string[]>([]);
  const [value, setValue] = useState<string | null>(defaultValue);
  useMemo(() => {
    const labelList = options.map((option) => (isJapanese ? option.labelJpn : option.labelEng));
    setOptionsLabel(labelList);
  }, [options, isJapanese]);

  useEffect(() => {
    methods.setValue(name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          freeSolo
          options={optionsLabel}
          value={value}
          disabled={rest.disabled}
          onChange={(e, newInputValue) => {
            methods.setValue(name, newInputValue);
            settingGlobalState?.(name, newInputValue);
            setValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...rest}
              value={value}
              label={label}
              error={!!fieldState?.error}
              helperText={fieldState?.error ? fieldState?.error?.message ?? 'Invalid Input' : helperText}
              onChange={(e) => {
                methods.setValue(name, e.target.value);
                settingGlobalState?.(name, e.target.value);
              }}
            />
          )}
        />
      )}
    />
  );
};

export default RHFTextAutoComplete;
