///////////////////////////
// 汎用アンケート用Constants
///////////////////////////

// アンケート種別最大文字数
export const ENQUETE_TYPE = {
  MAX_LENGTH: 100,
} as const;

// 表形式 行列ヘッダ最大文字列
export const ROW_COLUMN_HEADER = {
  MAX_LENGTH: 50,
} as const;

// アンケートタイトル最大文字数
export const ENQUETE_TITLE = {
  MAX_LENGTH: 200,
} as const;

// アンケート質問最大文字数
export const QUESTION_TITLE = {
  MAX_LENGTH: 500,
} as const;

// アンケートバリデーションエラーメッセージ最大文字数
export const VALIDATION_ERROR_MESSAGE = {
  MAX_LENGTH: 100,
} as const;

// 回答形式数値最小値,最大値
export const ANSWER_TYPE_NUMBER = {
  MIN: -9007199254740991,
  MAX: 9007199254740991,
} as const;

// 回答形式数値小数点桁数最小値,最大値
export const ANSWER_TYPE_NUMBER_OF_DECIMAL_PLACES = {
  MIN: 0,
  MAX: 6,
} as const;

// 回答形式文字列最小値,最大値
export const ANSWER_TYPE_STRING = {
  MIN: 0,
  MAX: 2000,
} as const;

// 回答選択肢文字列最大文字数,回答選択子の最小設定数,最大設定数
export const QUESTION_ITEM = {
  MAX_LENGTH: 200,
  MIN_COUNT: 1,
  MAX_COUNT: 20,
} as const;

// QUESTION保存モード
export const QUESTION_SAVE_MODE = {
  ADD: 'addition',
  DELETE: 'delete',
  EDIT: 'edit',
  COPY: 'copy',
} as const;

// QUESTIONITEM保存モード
export const QUESTIONITEM_SAVE_MODE = {
  ADD: 'addition',
  MODIFY: 'modification',
  DELETE: 'remove',
  REFILL: 'refill',
} as const;

// 表形式設定値
export const TABLE_SETTINGS = {
  MAIN: {
    TABLE_HEIGHT: '500px',
    MAX_ROWS: 300,
    MAX_COLS: 200,
  },
  SUB: {
    TABLE_HEIGHT: '250px',
    MAX_LENGTH: 3,
    MAX_ROWS: 3000,
    MAX_COLS: 50,
  },
  MAX_TOTAL_TABLE_LENGTH: 4,
  MAX_SHEET_STRING_LENGTH: 31,
  MAIN_TABLE_REFERENCE: 'main',
  DEFAULT_SHEET_NAME_JPN: '回答表',
  DEFAULT_SHEET_NAME_ENG: 'Answer table',
  MAIN_TABLE_SHEET_NAME_JPN_PATH: 'enqueteCreate.table.main.sheetNameJpn',
  MAIN_TABLE_SHEET_NAME_ENG_PATH: 'enqueteCreate.table.main.sheetNameEng',
  SHEET_NAME_REG: /[:：?？\\＼\[\]［］\/／*＊'’¥￥]/gi, // eslint-disable-line
  DEBOUNCE_TIME: 1000,
} as const;

// データ保存最大サイズ
// Snowflakeへ連携する際に、Snowflake側で16MBのサイズ制限があるためBonsai側のデータサイズを16MB以下にする必要がある。
export const MAX_DATA_SIZE = {
  SURVEY_FORM: 9437184, //9MB
  ANSWER: 10485760, //10MB
} as const;

// 行ヘッダの幅
export const HEADER_WIDTH = {
  FULL_WIDTH: 13, //全角
  HALF_WIDTH: 7, //半角
  MARGIN: 26, //余白
} as const;
