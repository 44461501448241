import type { ErrorResponse } from '../@types/common';
import { AppThunk, dispatch } from '../redux/store';
import axios from 'axios';

// ----------------------------------------------------------------------

const axiosErrorHandler = (error: unknown, handler: (message: string) => AppThunk) => {
  if (axios.isAxiosError(error)) {
    // error: AxiosError
    if (error.response) {
      const serverResponse: ErrorResponse = error.response.data;
      dispatch(handler(serverResponse.message));
    } else {
      dispatch(handler(error.message));
    }
  } else {
    if (error instanceof Error) {
      // error: Error
      dispatch(handler(error.message));
    } else {
      const message = 'Unknown error';
      dispatch(handler(message));
    }
  }
};

export default axiosErrorHandler;
