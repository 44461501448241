import { Button } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import React, { ReactNode } from 'react';

export interface StyledButtonProps {
  children: React.ReactNode;
  sx?: CSSProperties;
  onClick: () => void | Promise<void>;
  fullwidth?: boolean;
  isDisabled?: boolean;
  color?: 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'contained' | 'outlined';
  startIcon?: ReactNode;
}

export const StyledButton: React.FC<StyledButtonProps> = (props) => {
  return (
    <Button
      fullWidth={props.fullwidth}
      sx={props.sx}
      variant={props.variant ? props.variant : 'contained'}
      onClick={props.onClick}
      disabled={props.isDisabled}
      color={props.color ? props.color : undefined}
      startIcon={props.startIcon}>
      {props.children}
    </Button>
  );
};
