import { useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import { UserEntity } from '../api/useUserApi';
import { switchLabel } from '../utils/labelUtils';

export const UseUserTooltip = () => {
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  const [organizationInfo, setOrganizationInfo] = useState<string>('');
  const [assignedCompanyInfo, setAssignedCompanyInfo] = useState<string | null>(null);

  // 組織情報を整形
  const handlerOrganizationInfo = (data: UserEntity) => {
    // 単体かを判定
    const isTtc = data.companyCode === 'S500';

    // 非単体だった場合、「-(ハイフン)」に差替え
    data.divisionNameJpn = isTtc ? data.divisionNameJpn : '-';
    data.divisionNameEng = isTtc ? data.divisionNameEng : '-';
    data.sbuNameJpn = isTtc ? data.sbuNameJpn : '-';
    data.sbuNameEng = isTtc ? data.sbuNameEng : '-';
    data.departmentNameJpn = isTtc ? data.departmentNameJpn : '-';
    data.departmentNameEng = isTtc ? data.departmentNameEng : '-';
    data.groupNameJpn = isTtc ? data.groupNameJpn : '-';
    data.groupNameEng = isTtc ? data.groupNameEng : '-';

    // 配列に格納
    const ary = [
      switchLabel(data.companyNameJpn, data.companyNameEng, isLangJpn),
      switchLabel(data.divisionNameJpn, data.divisionNameEng, isLangJpn),
      switchLabel(data.sbuNameJpn, data.sbuNameEng, isLangJpn),
      switchLabel(data.departmentNameJpn, data.departmentNameEng, isLangJpn),
      switchLabel(data.groupNameJpn, data.groupNameEng, isLangJpn),
    ];

    // 全角スペースで区切りながら、配列内の文字列を結合
    setOrganizationInfo(ary.join(' '));
  };

  // 出向情報を整形
  const handlerAssignedCompanyInfo = (data: UserEntity) => {
    // 非単体所属である、または出向情報が日英ともなければ、空文字を設定
    if (data.companyCode !== 'S500' || (!data.assignedCompanyNameJpn && !data.assignedCompanyNameEng)) setAssignedCompanyInfo(null);
    // 管理変数に格納
    setAssignedCompanyInfo(switchLabel(data.assignedCompanyNameJpn, data.assignedCompanyNameEng, isLangJpn));
  };

  return { organizationInfo, assignedCompanyInfo, handlerOrganizationInfo, handlerAssignedCompanyInfo };
};
