import { HotTable } from '@handsontable/react';
import { RefObject, useCallback } from 'react';
import { ColWidths, MergeCells, NestedHeader, RowHeights, TableData } from '../@types/generalEnquete';
import { initialSettings } from '../components/app-components/bonsai/type-general-table/constants/initialSettings';
import { RootState, useAppSelector } from '../redux/store';

export const useSurveyTables = () => {
  // ストア
  const mainTableSheetNames = useAppSelector((state: RootState) => state.tableSetting.mainTableSheetNames);

  // 複数化対応後の複数回答表データか判定
  const isMultiAnswerTableSurvey = mainTableSheetNames[0]?.sheetNameJpn !== undefined;

  // TableInstanceからデータを抽出し返却
  const getTableInstanceData = (hr: RefObject<HotTable>, isReedOnly: boolean) => {
    // data
    const data = hr?.current?.hotInstance?.getData() as string[][];
    // source
    const sourceData = hr?.current?.hotInstance?.getSourceData() as string[][];
    // cell meta for each cell and the arrays below
    const cell = [];
    const mergeCells: MergeCells = [];
    const colWidths: ColWidths = [];
    const rowHeights: RowHeights = [];
    const rowHeaders: string[] | undefined = hr?.current?.hotInstance?.getRowHeader().map(String);
    const nestedHeaders: (string | NestedHeader)[][] | undefined = hr?.current?.hotInstance?.getSettings().nestedHeaders;
    const fixedRowsTop = hr?.current?.hotInstance?.getSettings().fixedRowsTop || 0;
    const fixedColumnsStart = hr?.current?.hotInstance?.getSettings().fixedColumnsStart || 0;

    // インスタンス内の行幅、列幅、セル結合情報を抽出、変数格納
    for (let row = 0; row < data.length; row++) {
      // push rowHeights
      rowHeights.push(hr?.current?.hotInstance?.getRowHeight(row));

      for (let col = 0; col < data[row].length; col++) {
        // push colWidths only once
        if (row === 0) {
          colWidths.push(hr?.current?.hotInstance?.getColWidth(col));
        }

        const cellMeta = { ...hr?.current?.hotInstance?.getCellMeta(row, col) };
        if (cellMeta) {
          // アンケート作成時にセル入力しているデータと、アンケート回答でセル入力しているデータとを区別する
          // 回答画面で作成時に入力してあるデータは、編集不可になる
          if (isReedOnly) {
            if (sourceData[row][col] !== null && sourceData[row][col] !== '') {
              cellMeta.readOnly = true;
            } else {
              cellMeta.readOnly = undefined;
            }
          }

          const cellData = {
            row: cellMeta.row,
            col: cellMeta.col,
            className: cellMeta.className,
            type: cellMeta.type,
            source: cellMeta.source,
            readOnly: cellMeta.readOnly,
            colspan: cellMeta.colspan,
            rowspan: cellMeta.rowspan,
          };
          cell.push(cellData);
        }

        if (cellMeta?.colspan && cellMeta?.rowspan) {
          // formatt is  {row: 1, col: 1, rowspan: 3, colspan: 3},
          mergeCells.push({
            row,
            col,
            rowspan: cellMeta.rowspan,
            colspan: cellMeta.colspan,
          });
        }
      }
    }

    return {
      data,
      sourceData,
      cell,
      mergeCells,
      colWidths,
      rowHeights,
      rowHeaders,
      nestedHeaders,
      fixedColumnsStart,
      fixedRowsTop,
    };
  };

  // 作成時の回答表インタンスからデータを抽出し返却
  const formatCreateMainTableData = useCallback((hr: RefObject<HotTable>) => {
    const { sourceData, cell, mergeCells, colWidths, rowHeights, rowHeaders, nestedHeaders, fixedColumnsStart, fixedRowsTop } = getTableInstanceData(
      hr,
      true,
    );

    return {
      sourceData: sourceData,
      answerData: [],
      cell,
      mergeCells,
      colWidths,
      rowHeights,
      rowHeaders,
      nestedHeaders,
      fixedColumnsStart,
      fixedRowsTop,
    };
  }, []);

  // 回答時の回答表インタンスからデータを抽出し返却
  const formatAnswerMainTableData = useCallback((hr: RefObject<HotTable>) => {
    const { data, sourceData, cell, mergeCells, colWidths, rowHeights, rowHeaders, nestedHeaders, fixedColumnsStart, fixedRowsTop } =
      getTableInstanceData(hr, false);

    return {
      sourceData: sourceData,
      answerData: data,
      cell,
      mergeCells,
      colWidths,
      rowHeights,
      rowHeaders,
      nestedHeaders,
      fixedColumnsStart,
      fixedRowsTop,
    };
  }, []);

  // 参照表インタンスからデータを抽出し返却
  const formatSubTableData = useCallback((hotRefForSubSheet: RefObject<HotTable>[], subDatas: TableData[], isPreview: boolean | undefined) => {
    subDatas.forEach((subData: TableData, index: number) => {
      if (subData && subData.length !== 0) {
        const subTableDataForLoad = subData.map((row) => {
          return row.map((cell) => {
            return cell ?? '';
          });
        });

        const cell = [];
        if (isPreview) {
          // memo: sub表を数値3桁カンマ区切りにするためsub表の入力タイプをnumericに設定する
          for (let row = 0; row < subData.length; row++) {
            for (let col = 0; col < subData[row].length; col++) {
              const cellMeta = { ...hotRefForSubSheet[index]?.current?.hotInstance?.getCellMeta(row, col) };
              if (cellMeta) {
                const cellData = {
                  row: row,
                  col: col,
                  type: 'numeric',
                };
                cell.push(cellData);
              }
            }
          }
        }

        if (hotRefForSubSheet[index].current) {
          hotRefForSubSheet[index].current?.hotInstance?.loadData(subTableDataForLoad);
          if (isPreview) {
            hotRefForSubSheet[index].current?.hotInstance?.updateSettings({
              ...initialSettings,
              cell: cell,
            });
          } else {
            hotRefForSubSheet[index].current?.hotInstance?.updateSettings(initialSettings);
          }
        }
      }
    });
  }, []);

  return { isMultiAnswerTableSurvey, formatCreateMainTableData, formatAnswerMainTableData, formatSubTableData };
};
