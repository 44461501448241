import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { SyntheticEvent, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Question, QuestionItem, State } from 'src/@types/generalEnquete';
import { useEnqueteAnswerHandleOnChange } from 'src/components/app-components/bonsai/type-general-question/hooks/useEnqueteAnswerHandleOnChange';

type RHFEnqueteAnswerMultiCheckBoxProps = {
  methods: UseFormReturn;
  rules?: Object;
  question: Question;
  questionIndex: number;
  defaultCheckedValues?: string[];
  state?: State;
};

const RHFEnqueteAnswerMultiCheckBox = ({
  methods,
  rules,
  question,
  questionIndex,
  state,
  defaultCheckedValues,
}: RHFEnqueteAnswerMultiCheckBoxProps) => {
  const { handleOnChangeCheckBox } = useEnqueteAnswerHandleOnChange();

  const makeCheckedValuesString = (question: Question, questionItem: QuestionItem, event?: SyntheticEvent) => {
    // チェック済みの値を取得
    let checkedValues: string[] = methods.getValues(question.id);

    if (!checkedValues) {
      checkedValues = defaultCheckedValues ? defaultCheckedValues : [];
    }
    // 空要素削除
    checkedValues = checkedValues.filter((v) => v);

    // 変更を反映
    let newCheckedValues: string[] = [];

    if (event && (event.target as HTMLInputElement).checked) {
      // 値を追加
      newCheckedValues = [...(checkedValues ?? []), questionItem.id];
    } else {
      // 値を除外
      newCheckedValues = checkedValues?.filter((value) => value !== questionItem.id);
    }

    return newCheckedValues;
  };

  // 質問表示状態が変ったらルールをスイッチする。
  useEffect(() => {
    if (methods && question.answerItem) {
      if (methods?.getValues(question.id) !== undefined) {
        const stateRule = state === 'disabled' || state === 'hidden' ? {} : rules;
        const value = methods?.getValues(question.id);
        methods?.control.unregister(question.id);
        methods?.control.register(question.id, stateRule);
        methods?.setValue(question.id, value);
        methods?.trigger(question.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <FormControl error={methods.formState.errors?.hasOwnProperty(question.id)}>
      <FormGroup>
        <Controller
          name={question.id}
          control={methods.control}
          rules={state === 'disabled' || state === 'hidden' ? {} : rules}
          defaultValue={defaultCheckedValues}
          render={({ field, fieldState: { error } }) => (
            <>
              {question.questionItem.map((item, indexAnswerItem) => (
                <FormControlLabel
                  {...field}
                  label={item.label}
                  key={item.id}
                  onChange={(event) => {
                    const checkedValues = makeCheckedValuesString(question, item, event); // 最新のチェック状態を取得
                    field.onChange(checkedValues);
                    handleOnChangeCheckBox(event, item.id, questionIndex, question); // ストアへの保存
                  }}
                  control={
                    <Checkbox
                      defaultChecked={defaultCheckedValues && defaultCheckedValues.includes(item.id)}
                      checked={field.value.includes(item.id)}
                      disabled={state === 'disabled'}
                    />
                  }
                />
              ))}
              <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
};

export default RHFEnqueteAnswerMultiCheckBox;
