import { PageLayout } from 'src/components/app-components/PageLayout';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { useAppSelector } from 'src/redux/store';
import { ConfirmationCommentsModalLayout } from '../../../modal/confirm-comments/ConfirmationCommentsModalLayout';
import useEnqueteAnswerInputLayout from '../hooks/useEnqueteAnswerInputLayout';
import { EnqueteAnswerInputSurveyTypeSwitcher } from './EnqueteAnswerInputSurveyTypeSwitcher';
export interface EnqueteAnswerInputLayoutProps {
  pageTitle: string;
  breadCrumbs: HeaderBreadcrumbsProps;
}

export const EnqueteAnswerInputLayout = (props: EnqueteAnswerInputLayoutProps) => {
  const isImporting = useAppSelector((state) => state.enqueteAnswer.isImporting);
  const { isLoading } = useEnqueteAnswerInputLayout();
  return (
    <PageLayout title={props.pageTitle} breadcrumbs={props.breadCrumbs}>
      {isLoading || isImporting ? (
        <StyledLoading isOpen={true} />
      ) : (
        <>
          {!isImporting && <EnqueteAnswerInputSurveyTypeSwitcher />}
          <ConfirmationCommentsModalLayout />
        </>
      )}
    </PageLayout>
  );
};
