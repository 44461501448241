import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useState } from 'react';
import { SubmitHandler, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  AggregateStatus,
  AGGREGATE_STATUS,
  OverviewCompanyAggregateSupplement,
  OverviewDivisionAggregateSupplement,
} from 'src/@types/overviewAggregateSupplement';
import { AdditionalQuestion } from 'src/@types/overviewEnquete';
import { useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import { setDivisionAggregateSupplements } from 'src/features/general/enquete-status/store/overviewAggregateSupplementSlice';
import useLocales from 'src/hooks/useLocales';
import { dispatch } from 'src/redux/store';

type FixedContent = { purchase: string; sales: string };

type TopicsMaterial = {
  products: FixedContent;
  commercialFlow: FixedContent;
  mainCustomer: string;
  otherFixedTopics: string;
  purchaseAdditionalQuestions: string;
  salesAdditionalQuestions: string;
  otherAdditionalQuestions: string;
};

export type FormValues = {
  topics: string;
  divisionData: OverviewDivisionAggregateSupplement;
};

export const useDivisionTab = (
  getValues: UseFormGetValues<FormValues>,
  setValue: UseFormSetValue<FormValues>,
  trigger: UseFormTrigger<FormValues>,
) => {
  const { isJapanese } = useLocales();
  const [gridApi, setGridApi] = useState<GridApi<OverviewCompanyAggregateSupplement> | null>(null);
  const { putOverviewAggregateSupplement } = useEnqueteStatusPageApi();
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();
  const INDENT = '  ';

  /**
   * ◇買い：⚪︎⚪︎、⚪︎⚪︎、⚪︎⚪︎
   * ◇売り：⚪︎⚪︎、⚪︎⚪︎、⚪︎⚪︎
   * の表示の文言を生成する
   * @param parentHeader 買い、売りの親項目(商流、品目)
   * @param fixedQuestion 固定項目(商流、品目)
   * @param indentRepeat 買い、売りの前の字下げを何回行うかの数値
   * @returns 生成した文言
   */
  const genTextFixedQuestions = (parentHeader: string, fixedQuestion: FixedContent, indentRepeat: number): string => {
    const purchase = fixedQuestion.purchase ? `${INDENT.repeat(indentRepeat)}◇買い：${fixedQuestion.purchase}` : undefined;
    const sales = fixedQuestion.sales ? `${INDENT.repeat(indentRepeat)}◇売り：${fixedQuestion.sales}` : undefined;
    if (purchase || sales) {
      return [parentHeader, purchase, sales].filter((elm) => elm).join('\n');
    }
    return '';
  };

  /**
   * 追加項目の表示文言を生成する
   * @param purchaseAdditionalQuestions 買い追加項目を連結した文字列
   * @param salesAdditionalQuestions 売り追加項目を連結した文字列
   * @param otherAdditionalQuestions その他追加項目を連結した文字列
   * @returns 追加項目の表示文言
   */
  const genTextAdditionalQuestions = (
    purchaseAdditionalQuestions: string,
    salesAdditionalQuestions: string,
    otherAdditionalQuestions: string,
  ): string => {
    const textAdditionalQuestions = [];
    if (purchaseAdditionalQuestions || salesAdditionalQuestions || otherAdditionalQuestions) {
      textAdditionalQuestions.push(`${INDENT}◆追加項目`);
      if (purchaseAdditionalQuestions) {
        textAdditionalQuestions.push(`${INDENT.repeat(2)}◇買い`);
        textAdditionalQuestions.push(purchaseAdditionalQuestions);
      }
      if (salesAdditionalQuestions) {
        textAdditionalQuestions.push(`${INDENT.repeat(2)}◇売り`);
        textAdditionalQuestions.push(salesAdditionalQuestions);
      }
      if (otherAdditionalQuestions) {
        textAdditionalQuestions.push(`${INDENT.repeat(2)}◇その他`);
        textAdditionalQuestions.push(otherAdditionalQuestions);
      }
    }
    return textAdditionalQuestions.join('\n');
  };

  /**
   * 追加するトピックスを生成する
   * @param topicsMaterial トピックスを生成するための材料
   * @returns 追加するトピックス
   */
  const generateAddTopics = ({
    products,
    mainCustomer,
    commercialFlow,
    purchaseAdditionalQuestions,
    salesAdditionalQuestions,
    otherAdditionalQuestions,
    otherFixedTopics,
  }: TopicsMaterial) => {
    const textMainCustomer = mainCustomer ? `${INDENT}◆主売先\n${INDENT.repeat(2)}${mainCustomer}` : '';
    const textAdditionalQuestions = genTextAdditionalQuestions(purchaseAdditionalQuestions, salesAdditionalQuestions, otherAdditionalQuestions);
    const textOtherFixedTopics = otherFixedTopics ? `${INDENT}◆トピックス\n${INDENT.repeat(2)}${otherFixedTopics}` : '';
    const displayContents = [
      genTextFixedQuestions(`${INDENT}◆品目`, products, 2),
      textMainCustomer,
      genTextFixedQuestions(`${INDENT}◆商流`, commercialFlow, 2),
      textAdditionalQuestions,
      textOtherFixedTopics,
    ];
    return displayContents.filter((content) => content).join('\n');
  };

  /**
   * 文字列の配列を連結文字で連結する
   * @param connection 連結文字
   * @param params 文字列の配列
   * @returns 連結した文字列
   */
  const concatenateString = (connection: string, ...params: (string | undefined)[]): string => {
    const stringList: string[] = [];
    for (const param of params) {
      param && stringList.push(param);
    }
    if (stringList.length === 0) {
      return '';
    }
    return stringList.join(connection);
  };

  /**
   * トピックスに反映する追加項目の文字列を組み立てる
   * @param additionalQuestions 追加項目
   * @return トピックスに反映する追加項目の文字列
   */
  const genAdditionalString = (additionalQuestions: AdditionalQuestion[]): string => {
    // 表の追加項目列で見る時のために、サーバーで追加項目を繋げ合わせる
    const result = [];
    for (const additionalQuestion of additionalQuestions) {
      if (!additionalQuestion.answer) continue;
      const title = `${additionalQuestion.titleJpn}`;
      if (additionalQuestion.inputType === 'text') {
        // memo: textは次の形式で表示するため、質問と回答を：つなぎの1行で表示する
        // 項目タイトル日本語(項目タイトル英語)：回答
        result.push(`${INDENT.repeat(3)}${title}：${additionalQuestion.answer}`);
      } else {
        // memo: textareaは次の形式で表示するため、回答の改行箇所にインデントを加える
        // 項目タイトル日本語(項目タイトル英語)
        //   回答行1
        //   回答行2
        const answer = additionalQuestion.answer.replace(/\n/g, `\n${INDENT.repeat(4)}`);
        // テキストエリア形式の場合、質問と回答の間を改行して表示する
        result.push(`${INDENT.repeat(3)}${additionalQuestion.titleJpn}\n${INDENT.repeat(4)}${answer}`);
      }
    }
    return result.join('\n');
  };

  /**
   * 主要トピックスとして反映するを押下時に呼ばれる関数
   */
  const onClickReflectKeyTopics = () => {
    const selectedRows = gridApi?.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) return;
    const topics = getValues('topics');
    const sbuMap = new Map<string, TopicsMaterial>();
    const sortedRows = selectedRows.sort((a, b) => (a.rowIndex !== null && b.rowIndex !== null ? a.rowIndex - b.rowIndex : 0));
    for (const { data } of sortedRows) {
      if (!data) {
        continue;
      }
      const { sbuNameJpn, sbuNameEng, salesFixedQuestions, purchaseFixedQuestions, otherFixedTopic } = data;
      const sbuName = isJapanese && sbuNameJpn ? sbuNameJpn : sbuNameEng || sbuNameJpn;
      const content = sbuMap.get(sbuName);
      // 反映するトピックスの細かいところを組み立てる、大枠は最後に組み立てる
      sbuMap.set(sbuName, {
        products: {
          purchase: concatenateString('、', content?.products?.purchase, purchaseFixedQuestions.product),
          sales: concatenateString('、', content?.products?.sales, salesFixedQuestions.product),
        },
        mainCustomer: concatenateString('、', content?.mainCustomer, purchaseFixedQuestions.mainCustomer),
        commercialFlow: {
          purchase: concatenateString('、', content?.commercialFlow?.purchase, purchaseFixedQuestions.commercialFlow),
          sales: concatenateString('、', content?.commercialFlow?.sales, salesFixedQuestions.commercialFlow),
        },
        otherFixedTopics: concatenateString(
          `\n${INDENT.repeat(2)}`,
          content?.otherFixedTopics,
          otherFixedTopic?.replaceAll('\n', `\n${INDENT.repeat(2)}`),
        ),
        purchaseAdditionalQuestions: concatenateString(
          '\n',
          content?.purchaseAdditionalQuestions,
          genAdditionalString(data.purchaseAdditionalQuestions),
        ),
        salesAdditionalQuestions: concatenateString('\n', content?.salesAdditionalQuestions, genAdditionalString(data.salesAdditionalQuestions)),
        otherAdditionalQuestions: concatenateString('\n', content?.otherAdditionalQuestions, genAdditionalString(data.otherAdditionalQuestions)),
      });
    }
    // sbuごとでまとめたトピックを追加
    const addTopics = Array.from(sbuMap.entries())
      .map(([key, value]) => `<${key}>\n${generateAddTopics(value)}`)
      .join('\n\n');
    const value = topics?.trim() ? `${topics}\n${addTopics}` : addTopics;
    setValue('topics', value);
    trigger();
  };

  /**
   * AgGridに指定するプロパティ
   * @param params https://www.ag-grid.com/javascript-data-grid/grid-lifecycle/#grid-ready
   */
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  /**
   * トピックスを登録する処理
   * @param data https://www.react-hook-form.com/ts/#FieldValues
   * @param status 保存ステータス
   */
  const save = async (data: FormValues, status?: AggregateStatus) => {
    if (!id) {
      return;
    }
    try {
      setIsSaving(true);
      const result = await putOverviewAggregateSupplement(id, data.topics, data.divisionData, status);
      result && dispatch(setDivisionAggregateSupplements(result.divisionAggregateSupplements));
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * 一時保存を押下時に呼ばれる関数
   * @param data https://www.react-hook-form.com/ts/#FieldValues
   */
  const onClickTemporarilySave: SubmitHandler<FormValues> = async (data) => {
    save(data as FormValues);
  };

  /**
   * 完了を押下時に呼ばれる関数
   * @param data https://www.react-hook-form.com/ts/#FieldValues
   */
  const onClickDone: SubmitHandler<FormValues> = async (data) => {
    save(data as FormValues, AGGREGATE_STATUS.COMPLETED);
  };

  return {
    onClickReflectKeyTopics,
    onGridReady,
    onClickTemporarilySave,
    onClickDone,
    isSaving,
  };
};
