import React from 'react';
import { SURVEY_TYPE } from 'src/constants';
import { TabValue } from 'src/features/general/enquete-create/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { GeneralEnqueteTypeSwitcher } from './GeneralEnqueteTypeSwitcher';
import { SpecialEnqueteTypeSwitcher } from './SpecialEnqueteTypeSwitcher';

export interface EnqueteCreateFormTypeSwitcherProps {
  id: string | undefined;
  selectedTabValue: TabValue;
  isNewCreateMode: boolean;
}

export const EnqueteCreateFormTypeSwitcher: React.FC<EnqueteCreateFormTypeSwitcherProps> = (props) => {
  const { id, selectedTabValue, isNewCreateMode } = props;

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const Type = enqueteData.surveyDetail?.surveyHeader?.type;

  switch (Type) {
    // 汎用アンケート
    case SURVEY_TYPE.GENERAL:
      return <GeneralEnqueteTypeSwitcher id={id} selectedTabValue={selectedTabValue} isNewCreateMode={isNewCreateMode} />;
    // 専用の処理を必要とする特殊アンケート
    case SURVEY_TYPE.SPECIAL:
      return <SpecialEnqueteTypeSwitcher id={id} selectedTabValue={selectedTabValue} />;
    default:
      return null;
  }
};
