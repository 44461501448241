import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExchangeRateRow } from 'src/api/useEnqueteStatusPageApi';
import Label from 'src/components/Label';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import useTheme from 'src/hooks/useTheme';
import { useExchangeRateColdef } from '../hooks/useExchangeRateColdef';

interface Props {
  exchangeRateRows: ExchangeRateRow[];
  isOpen: boolean;
  onCloseFunc: () => void;
  currentYear: string;
}
export const ExchangeRateModal = (props: Props) => {
  const { exchangeRateRows, isOpen, onCloseFunc, currentYear } = props;
  const { t } = useTranslation();
  const { defaultColDef, colDef, gridOptions } = useExchangeRateColdef();
  const { className } = useTheme();

  return (
    <StyledModal isOpen={isOpen} modalTitle={t('enqueteStatus.summaryAggregate.exchangeRateModal.title')} onCloseFunc={onCloseFunc}>
      <Box sx={{ height: 'fit-content', justifyContent: 'space-between' }} className={className} display={'flex'} flexDirection="column" gap={1}>
        <Label variant="ghost" sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', p: 1 }}>
          {t('enqueteStatus.summaryAggregate.exchangeRateModal.rateDescription', { startYear: currentYear, endYear: Number(currentYear) + 1 })}
        </Label>
        <Box>
          <StyledAgGrid defaultColDef={defaultColDef} coldef={colDef} gridOptions={gridOptions} rows={exchangeRateRows} />
        </Box>
        <Box sx={{ alignSelf: 'flex-end' }}>
          <StyledButton onClick={onCloseFunc}>{t('button.ok')}</StyledButton>
        </Box>
      </Box>
    </StyledModal>
  );
};
