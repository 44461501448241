import HotTable from '@handsontable/react';
import { RefObject, useEffect } from 'react';
import { RootState, useSelector } from 'src/redux/store';
import { useSurveyTables } from 'src/hooks/useSurveyTables';

export const useSubTableData = (hotRefForSubSheet: RefObject<HotTable>[], isPreview: boolean) => {
  const { formatSubTableData } = useSurveyTables();

  const subDatas = useSelector((state: RootState) => state.tableSetting.subDatas);

  // it ie needed to execute loadData api in useEffect
  useEffect(() => {
    formatSubTableData(hotRefForSubSheet, subDatas, isPreview);
  }, [formatSubTableData, hotRefForSubSheet, subDatas, isPreview]);
};
