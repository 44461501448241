import { Box, Grid } from '@mui/material';
import { GetContextMenuItems, RowDoubleClickedEvent } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestedTargetEntity, useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { ENQUETE_ANSWER_PATH } from 'src/features/general/enquete-answer/routes/path';
import { useEnqueteStatusList } from 'src/features/general/enquete-status/hooks/useEnqueteStatusList';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import useRequestTargetDataGridColDef from '../hooks/useRequestTargetDataGridColDef';

export const RequestTargetDataGrid: React.FC = () => {
  const { id: respondentRequestId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const surveyDetailId: string = String(params.get('surveyDetailId'));
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { defaultColDef, columnDefs } = useRequestTargetDataGridColDef();

  const { getRequestedTargetList } = useEnqueteStatusPageApi();
  const { setIsLoading } = useEnqueteStatusList();

  const isLoading = useAppSelector((state) => state.enqueteStatus.isLoading);
  const updateCounter = useAppSelector((state) => state.enqueteStatus.updateCounter);

  const [rowData, setRowData] = useState<RequestedTargetEntity[]>([]);

  const refreshRowData = async () => {
    setIsLoading(true);

    // todo: error handling されていないため、おそらく error が throw されるとローディング状態のままになると思われる
    if (respondentRequestId) {
      const data = await getRequestedTargetList(respondentRequestId);
      if (data) {
        setRowData(data);
      }
    }

    setIsLoading(false);
  };

  const onRowDoubleClickedCallback = (event: RowDoubleClickedEvent) => {
    navigate(
      `${ENQUETE_ANSWER_PATH.INPUT}${event.data.respondentTargetId}?respondentRequestId=${respondentRequestId}&surveyDetailId=${surveyDetailId}`,
    );
  };

  const contextMenuCallback: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('enqueteStatus.contextMenu.goToSurveyAnswerInput'),
        action: () => {
          navigate(
            `${ENQUETE_ANSWER_PATH.INPUT}${params.node?.data.respondentTargetId}?respondentRequestId=${respondentRequestId}&surveyDetailId=${surveyDetailId}`,
          );
        },
      },
    ];

    return menuItems;
  };

  useEffect(() => {
    refreshRowData();
  }, [respondentRequestId, updateCounter]); //eslint-disable-line

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: '80vh',
            width: '100%',
          }}>
          <LoadableComponent isLoading={isLoading}>
            <StyledAgGrid
              defaultColDef={defaultColDef}
              coldef={columnDefs}
              rows={rowData}
              contextMenu={contextMenuCallback}
              onRowDoubleClickedCallback={onRowDoubleClickedCallback}
              suppressAutoSize={true}
              storageKey={'RequestTargetDataTable'}
            />
          </LoadableComponent>
        </Box>
      </Grid>
    </Grid>
  );
};
