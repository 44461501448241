import { Link } from "@mui/material";

export const replaceURLWithHTMLLinks = (str: string) => {
  const regex = /(https?:\/\/[^\s]+)/g;
  if (!str) return null;
  const result = str.split(regex).map((line, i) => {
    if (regex.test(line)) {
      return (
        <Link key={i} href={line} target="_blank" rel="noopener noreferrer">
          {line}
        </Link>
      );
    }
    return line;
  });
  return result;
};
