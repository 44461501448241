import { useEffect, useState } from 'react';
import { UserEntity, useUserApi } from 'src/api/useUserApi';
import useAuth from './useAuth';

export const useUser = () => {
  const { user } = useAuth();
  const { getByEmail } = useUserApi();

  const [userNameJpn, setUserNameJpn] = useState<string>('');
  const [userNameEng, setUserNameEng] = useState<string>('');

  useEffect(() => {
    const initialize = async () => {
      //ユーザー情報取得
      const userData: UserEntity[] | undefined = user?.email ? await getByEmail(user.email) : undefined;
      if (userData?.length) {
        userData[0].fullNameJpn && setUserNameJpn(userData[0].fullNameJpn);
        userData[0].fullNameEng && setUserNameEng(userData[0].fullNameEng);
      }
    };
    if (!user || (userNameJpn !== '' && userNameEng !== '')) return;
    initialize();
  }, [getByEmail, user]);

  return { userNameJpn, userNameEng };
};
