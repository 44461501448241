import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QuestionItem } from 'src/@types/generalEnquete';
import {
  setIsOpenLogicErrorMessageModal,
  setLogicErrorMessages,
  setQuestionItem,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { QUESTIONITEM_SAVE_MODE } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { v1 } from 'uuid';
import { useLogicSettingCheck } from './useLogicSettingCheck';

export const useQuestionItemForm = (methods: UseFormReturn, questionItem: QuestionItem[], inputType: String) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLogicQuestionItem } = useLogicSettingCheck();
  const questions = useSelector((state: RootState) => state.enqueteCreate.questions);

  // 削除ボタン押下
  const handleDeleteOnClick = (index: number, id: string) => {
    // 回答選択肢にロジックが設定されているか？
    if (isLogicQuestionItem(questions, id)) {
      // ロジックエラーメッセージダイアログを表示する
      dispatch(setLogicErrorMessages([t('enqueteCreate.logic.errorMessage.questionItemDeleteError')]));
      dispatch(setIsOpenLogicErrorMessageModal(true));
    } else {
      dispatch(
        setQuestionItem({
          index,
          mode: QUESTIONITEM_SAVE_MODE.DELETE,
          currentInputType: inputType,
        }),
      );
      methods.unregister('questionItem' + (questionItem.length - 1));
    }
    methods.trigger();
  };

  // 追加ボタン押下
  const handleAddOnClick = () => {
    dispatch(
      setQuestionItem({
        index: questionItem.length,
        mode: QUESTIONITEM_SAVE_MODE.ADD,
        currentInputType: inputType,
        value: { id: v1(), label: '' } as QuestionItem,
      }),
    );
    methods.trigger();
  };

  return {
    handleDeleteOnClick,
    handleAddOnClick,
  };
};
