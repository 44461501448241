import { Grid, Typography } from '@mui/material';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';

export interface ArrayFieldProps {
  methods: UseFormReturn<FieldValues>;
  name: string;
  displayName: string | null;
  defaultValue: string[];
  length: number;
  label: string;
}

const ArrayField: React.FC<ArrayFieldProps> = (props) => {
  const { methods, name, displayName, defaultValue, length, label } = props;
  return (
    <>
      <Grid item xs={2}>
        <Typography variant="subtitle1"> {displayName}</Typography>
      </Grid>
      {defaultValue.slice(0, length).map((value, index) => {
        return (
          <Grid item xs={2} id={`${name}${index}`} key={`${name}${index}`}>
            <RHFTextField
              name={`${name}${index}`}
              defaultValue={value}
              control={methods.control}
              label={`${label}${index + 1}`}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
        );
      })}
    </>
  );
};

export default ArrayField;
