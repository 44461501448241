import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import CustomerManagementListLayout from '../components/pages/list/components/CustomerManagementListLayout';

const CustomerManagementListPage = () => {
  const { t } = useTranslation();

  const title = t('customerManagement.pageTitle');
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };
  return <CustomerManagementListLayout title={title} breadcrumbs={breadcrumbs} />;
};

export default CustomerManagementListPage;
