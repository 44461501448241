export const getResponseOrganizationInfo = (org: any, lang: string): string => {
  const array: string[] = [];
  if (org) {
    const keywords =
      lang === 'ja'
        ? [org.companyNameJpn, org.divisionNameJpn, org.sbuNameJpn, org.departmentNameJpn, org.groupNameJpn]
        : [org.companyNameEng, org.divisionNameEng, org.sbuNameEng, org.departmentNameEng, org.groupNameEng];
    keywords.forEach((value) => {
      if (value) array.push(value);
    });
  }
  return array.join(' ');
};
