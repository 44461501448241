export const AccessRoleTypeEnum = {
  FULL: { roleName: 'FULL', viewName: 'AccessRoleType.full' },
  ANSWER_READ: { roleName: 'ANSWER_READ', viewName: 'AccessRoleType.answerRead' },
  ANSWER_EDIT: { roleName: 'ANSWER_EDIT', viewName: 'AccessRoleType.answerEdit' },
} as const;

export interface AccessRoleType {
  id: string;
  roleName: string;
  answerRead: boolean;
  answerEdit: boolean;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  isDeleted?: boolean;
}
