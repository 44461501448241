import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PageProps {
  requestStatus: 'before' | 'after';
}

const OutsideSurveyPeriodTypography: React.FC<PageProps> = (props) => {
  const { t } = useTranslation();

  const showMessage = () => {
    if (props.requestStatus === 'before') return t('enqueteAnswerInput.message.beforeRequestTerm');
    if (props.requestStatus === 'after') return t('enqueteAnswerInput.message.afterRequestTerm');
  };

  return <Typography variant="h4" sx={{ textAlign: 'center', py: 5 }} children={showMessage()} />;
};

export default OutsideSurveyPeriodTypography;
