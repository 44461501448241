import { Autocomplete, TextField } from '@mui/material';
import { Controller, ControllerRenderProps, FieldValues, UseFormReturn } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';
import { ANSWER_UNIT, FORM_TYPE, SURVEY_TYPE } from 'src/constants';

type RHFInputTypeAutoCompleteProps = {
  name: string;
  label: string;
  options: SurveyHeaderEntity[];
  defaultValue: SurveyHeaderEntity | undefined;
  methods: UseFormReturn;
  rules: RegisterOptions;
};
const RHFInputTypeAutoComplete = ({ name, label, options, defaultValue, methods, rules }: RHFInputTypeAutoCompleteProps) => {
  const defaultEntity = {
    id: '',
    labelJpn: '',
    labelEng: '',
    description: '',
    descriptionEng: '',
    tags: [],
    type: SURVEY_TYPE.GENERAL,
    answerUnit: ANSWER_UNIT.ORGANIZATION,
    formType: FORM_TYPE.QA,
    ownerOrganizationId: '',
    createdAt: '',
    updatedAt: '',
    isDeleted: false,
  };

  const onInputChange = (field: ControllerRenderProps<FieldValues, string>, newInputValue: string) => {
    const oldData = methods.getValues(name) || { id: '', labelJpn: '', description: '' };
    const selectedId = options.find((item) => item.labelJpn === newInputValue)?.id || '';
    const newData = { id: selectedId, labelJpn: newInputValue };
    if (selectedId !== '') {
      const selectedEnqueteType = options.find((item) => item.id === selectedId);
      methods.setValue('labelEng', selectedEnqueteType?.labelEng || '');
      methods.setValue('enqueteQuestionFormat', selectedEnqueteType?.formType || '');
      methods.setValue('enqueteAnswerUnit', selectedEnqueteType?.answerUnit || '');

      methods.clearErrors('labelEng');
      methods.clearErrors('enqueteQuestionFormat');
      methods.clearErrors('enqueteAnswerUnit');
    } else if (oldData?.id !== '' && oldData?.id !== undefined && oldData !== null) {
      methods.setValue('labelEng', '');
      methods.setValue('enqueteQuestionFormat', '');
      methods.setValue('enqueteAnswerUnit', '');
    }
    methods.setValue(name, newData);
    field.onChange(newData);
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          freeSolo
          defaultValue={defaultValue ?? defaultEntity}
          options={options}
          value={methods.getValues(name) === undefined ? defaultValue : methods.getValues(name)}
          getOptionLabel={(option: SurveyHeaderEntity) => option.labelJpn || ''}
          onInputChange={(_, newInputValue) => {
            onInputChange(field, newInputValue);
          }}
          onChange={(_, newInputValue) => {
            field.onChange(newInputValue);
          }}
          disabled={!methods.watch('ownerOrganization')?.key ? true : false}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              label={label}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={!methods.watch('ownerOrganization')?.key ? true : false}
            />
          )}
        />
      )}
    />
  );
};

export default RHFInputTypeAutoComplete;
