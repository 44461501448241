import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import * as lodash from 'lodash';
import { RefObject, useEffect, useState } from 'react';
import { MergeCells, NestedHeader } from 'src/@types/generalEnquete';
import { initialSettings } from 'src/components/app-components/bonsai/type-general-table/constants/initialSettings';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';
import { createTableInitialData } from 'src/utils/handsontable';
import { setTargetMainTable } from 'src/features/general/enquete-create/store/tableSettingSlice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  hotRefForNestedHeaders: RefObject<HotTable>;
}

const useNestedHeadersSetting = (props: Props) => {
  const { onClose, hotRefForNestedHeaders, isOpen } = props;

  const [isNestedHeaderValid, setIsNestedHeaderValid] = useState(true);

  // ストア
  const totalMainTables = useAppSelector((state: RootState) => state.tableSetting.totalMainTables);
  const selectedTableIndex = useAppSelector((state: RootState) => state.tableSetting.selectedTableIndex);

  // memo:
  //   NestedHeader は上位行のマージ状態よりも内側に収まっている（ネストされている）必要があり、
  //   それを満たさない場合、Handsontable の要素が壊れてしまう
  //   表示破壊を防ぐため、設定ダイアログ側で破壊につながる設定はさせないよう Validate する
  //   例えば、1行目はA列とB列がマージ、2行目はB列とC列がマージされると、上位行のマージ状態の中におさまっていないためNG
  //   1行目がA列からC列までマージされていればOKとなる
  const isValidNestedHeaders = (nestedHeadersData: (string | NestedHeader)[][]): boolean => {
    const colDividedPositions = new Set<number>();
    for (const row of nestedHeadersData) {
      let colIndex = 0;
      for (const cell of row) {
        const startColPos = colIndex;
        const endColPos = colIndex + (typeof cell === 'object' && cell !== null ? cell.colspan : 1);
        if (typeof cell === 'object') {
          for (let i = startColPos + 1; i < endColPos; i++) {
            if (colDividedPositions.has(i)) {
              return false;
            }
          }
        }
        colDividedPositions.add(startColPos);
        colDividedPositions.add(endColPos);
        colIndex += 1 + (typeof cell === 'object' && cell !== null ? cell.colspan - 1 : 0);
      }
    }
    return true;
  };

  const onClick = () => {
    const nestedHeaders: (string | NestedHeader)[][] = [];
    const data = hotRefForNestedHeaders.current?.hotInstance?.getData() as string[][];

    // memo: 表内部に1箇所でも値が設定されている場合は、その内容を NestedHeader として store へ保存
    if (data && data.some((row) => row.some((col) => col !== ''))) {
      for (let row = 0; row < data.length; row++) {
        const nestedHeader = [];
        for (let col = 0; col < data[row].length; col++) {
          const cellMeta = { ...hotRefForNestedHeaders?.current?.hotInstance?.getCellMeta(row, col) };
          // memo: cell が merge されている場合は、 nestedHeader にもそのマージ情報を反映する
          if (cellMeta && cellMeta.colspan) {
            nestedHeader.push({ label: data[row][col], colspan: cellMeta.colspan } as NestedHeader);
            col += cellMeta.colspan - 1;
          } else {
            nestedHeader.push(data[row][col]);
          }
        }
        nestedHeaders.push(nestedHeader);
      }
      if (!isValidNestedHeaders(nestedHeaders)) {
        setIsNestedHeaderValid(false);
      } else {
        dispatch(
          setTargetMainTable({
            index: selectedTableIndex,
            table: {
              ...totalMainTables[selectedTableIndex],
              ...{ nestedHeaders: nestedHeaders },
            },
          }),
        );

        setIsNestedHeaderValid(true);
        onClose();
      }
    } else {
      onClose();
    }
  };

  useEffect(() => {
    const storedNestedHeaders = totalMainTables[selectedTableIndex]?.nestedHeaders ?? [];
    const sourceData = totalMainTables[selectedTableIndex]?.sourceData;

    const clearCellMetas = (data: (string | NestedHeader)[][]) => {
      for (let row = 0; row < data.length; row++) {
        for (let col = 0; col < data[row].length; col++) {
          hotRefForNestedHeaders?.current?.hotInstance?.setCellMeta(row, col, 'colspan', 0);
        }
      }
      hotRefForNestedHeaders?.current?.hotInstance?.render();
    };

    const updateCellMetaWithMergeCells = (mergeCells: MergeCells) => {
      mergeCells.forEach((mergeCell) => {
        const { row, col, colspan } = mergeCell;
        hotRefForNestedHeaders?.current?.hotInstance?.setCellMeta(row, col, 'colspan', colspan);
      });
      hotRefForNestedHeaders?.current?.hotInstance?.render();
    };

    const updateMergeCells = (data: (string | NestedHeader)[][]) => {
      const mergeCells: MergeCells = [];
      data.forEach((row, rowIndex) => {
        let offset = 0;
        row.forEach((col, colIndex) => {
          if (col && typeof col === 'object' && 'colspan' in col) {
            mergeCells.push({ row: rowIndex, col: colIndex + offset, rowspan: 1, colspan: (col as NestedHeader).colspan });
            offset += col.colspan - 1;
          }
        });
      });
      return mergeCells;
    };

    const convertNestedHeadertoData = (data: (string | NestedHeader)[][]) => {
      const result: string[][] = [];
      data.forEach((row) => {
        const rowForLoad: string[] = [];
        row.forEach((col) => {
          if (col && typeof col === 'object' && 'colspan' in col) {
            // memo : formatt is  {row: 1, col: 1, rowspan: 3, colspan: 3},
            rowForLoad.push(col.label);

            for (let i = 1; i < col.colspan; i++) {
              rowForLoad.push('');
            }
          } else {
            rowForLoad.push(col);
          }
        });
        result.push(rowForLoad);
      });
      return result;
    };

    if (!hotRefForNestedHeaders.current || isOpen === false) return;
    // memo: NestedHeaders が設定済みの場合は、その設定を元に Handsontable の設定を復元する
    if (storedNestedHeaders && 0 < storedNestedHeaders.length && storedNestedHeaders[0].length !== 0) {
      const data = lodash.cloneDeep(storedNestedHeaders);
      const dataForLoad = convertNestedHeadertoData(data);
      const mergeCells: MergeCells = updateMergeCells(data);
      hotRefForNestedHeaders.current.hotInstance?.loadData(dataForLoad);
      hotRefForNestedHeaders.current.hotInstance?.updateSettings({ ...initialSettings, mergeCells });

      // memo: cellMeta情報をクリアする
      clearCellMetas(dataForLoad);
      updateCellMetaWithMergeCells(mergeCells);
    } else {
      // memo: カラム数はメイン表のカラム数に合わせる
      // sourceData.length > 0 の時にそもそもヘッダー定義をさせないのがいいかもしれない
      const colLength = sourceData && 0 < sourceData.length && !sourceData?.every((el) => el.length === 0) ? sourceData[0].length : 5;
      hotRefForNestedHeaders.current.hotInstance?.loadData(createTableInitialData(1, colLength));
      hotRefForNestedHeaders.current.hotInstance?.updateSettings({ ...initialSettings });
    }
    hotRefForNestedHeaders.current.hotInstance?.render();
  }, [hotRefForNestedHeaders, totalMainTables, selectedTableIndex, isOpen]);

  return {
    isNestedHeaderValid,
    setIsNestedHeaderValid,
    onClick,
  };
};

export default useNestedHeadersSetting;
