import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { switchLabel } from 'src/utils/labelUtils';

const ComplementaryOrganizationListColDef = (): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  const colDefList: (ColDef | ColGroupDef)[] = [
    {
      field: 'serialNumber',
      tooltipField: 'serialNumber',
      headerName: 'No.',
      headerTooltip: 'No.',
      valueGetter: 'node.rowIndex + 1',
      minWidth: 20,
      width: 70,
      suppressMenu: true,
      flex: 0,
    },
    {
      field: 'orgLevelType',
      tooltipField: 'orgLevelType',
      headerName: t('complementaryOrganizationList.gridColDef.orgLevelType'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.orgLevelType'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'companyCode',
      tooltipField: 'companyCode',
      headerName: t('complementaryOrganizationList.gridColDef.companyCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.companyCode'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'companyNameJpn' : 'companyNameEng',
      tooltipField: currentLang.value === 'ja' ? 'companyNameJpn' : 'companyNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.companyNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.companyNameJpn'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.companyNameJpn, params.data.companyNameEng, currentLang.value === 'ja'),
    },
    {
      field: 'divisionCode',
      tooltipField: 'divisionCode',
      headerName: t('complementaryOrganizationList.gridColDef.divisionCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.divisionCode'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'divisionNameJpn' : 'divisionNameEng',
      tooltipField: currentLang.value === 'ja' ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.divisionNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.divisionNameJpn'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.divisionNameJpn, params.data.divisionNameEng, currentLang.value === 'ja'),
    },

    {
      field: 'sbuCode',
      tooltipField: 'sbuCode',
      headerName: t('complementaryOrganizationList.gridColDef.sbuCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.sbuCode'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'sbuNameJpn' : 'sbuNameEng',
      tooltipField: currentLang.value === 'ja' ? 'sbuNameJpn' : 'sbuNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.sbuNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.sbuNameJpn'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.sbuNameJpn, params.data.sbuNameEng, currentLang.value === 'ja'),
    },
    {
      field: 'departmentCode',
      tooltipField: 'departmentCode',
      headerName: t('complementaryOrganizationList.gridColDef.departmentCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.departmentCode'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'departmentNameJpn' : 'departmentNameEng',
      tooltipField: currentLang.value === 'ja' ? 'departmentNameJpn' : 'departmentNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.departmentNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.departmentNameJpn'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.departmentNameJpn, params.data.departmentNameEng, currentLang.value === 'ja'),
    },
    {
      field: 'groupCode',
      tooltipField: 'groupCode',
      headerName: t('complementaryOrganizationList.gridColDef.groupCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.groupCode'),
      minWidth: 30,
      width: 200,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'groupNameJpn' : 'groupNameEng',
      tooltipField: currentLang.value === 'ja' ? 'groupNameJpn' : 'groupNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.groupNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.groupNameJpn'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.groupNameJpn, params.data.groupNameEng, currentLang.value === 'ja'),
    },
    {
      field: 'regionCode',
      tooltipField: 'regionCode',
      headerName: t('common.code.region'),
      headerTooltip: t('common.code.region'),
      minWidth: 30,
      width: 200,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'regionNameJpn' : 'regionNameEng',
      tooltipField: currentLang.value === 'ja' ? 'regionNameJpn' : 'regionNameEng',
      headerName: t('common.region'),
      headerTooltip: t('common.region'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.regionNameJpn, params.data.regionNameEng, currentLang.value === 'ja'),
    },
    {
      field: 'countryCode',
      tooltipField: 'countryCode',
      headerName: t('common.code.country'),
      headerTooltip: t('common.code.country'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'countryNameJpn' : 'countryNameEng',
      tooltipField: currentLang.value === 'ja' ? 'countryNameJpn' : 'countryNameEng',
      headerName: t('common.country'),
      headerTooltip: t('common.country'),
      minWidth: 150,
      width: 240,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data.countryNameJpn, params.data.countryNameEng, currentLang.value === 'ja'),
    },
    {
      field: 'rank',
      tooltipField: 'rank',
      headerName: t('complementaryOrganizationList.gridColDef.rank'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.rank'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'createdBy',
      tooltipField: 'createdBy',
      headerName: t('complementaryOrganizationList.gridColDef.createdBy'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.createdBy'),
      minWidth: 30,
      width: 250,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'updatedBy',
      tooltipField: 'updatedBy',
      headerName: t('complementaryOrganizationList.gridColDef.updatedBy'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.updatedBy'),
      minWidth: 30,
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return colDefList;
};

export default ComplementaryOrganizationListColDef;
