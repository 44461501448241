import { notificationRoute } from 'src/features/manage/notification/routes';
import AuthGuardLayout from '../../../components/layouts/AuthGuardLayout';
import { complementaryOrganizationRoute } from '../complementary-organization/routes';

import { customerManagementRoute } from '../customer-management/routes';
import { enqueteAnswererGroupRoute } from '../enquete-answerer-group/routes';

//authGuardをパスしたとき、apiRouteで指定したページを表示する
export const manageRoute = {
  path: 'manage',
  element: <AuthGuardLayout />,
  children: [enqueteAnswererGroupRoute, complementaryOrganizationRoute, customerManagementRoute, notificationRoute],
};
