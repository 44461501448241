import { Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

export interface cardItemTypes {
  title: string;
  description: string;
  url: string;
}

export interface OverViewCardTypes {
  children?: React.ReactNode;
  overviewCardList: {
    genreTitle: string;
    list: cardItemTypes[];
  }[];
}

//カード
const CardItems: FC<cardItemTypes[]> = (cardItems) => {
  const navigate = useNavigate();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  return (
    <>
      {cardItems.map((card, i) => {
        return (
          <Grid key={i} item xs={12} md={4} style={{ minHeight: '150px', padding: '10px' }}>
            <Card sx={{ border: 1, borderColor: 'primary.main' }} onClick={() => navigate(card.url)}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom color={isLight ? 'primary' : palette.dark.text.primary} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

//ジャンル毎の表示
const OverviewCard: FC<OverViewCardTypes> = (props) => {
  return (
    <>
      {props.overviewCardList.map((genre, i) => {
        return (
          <Grid key={i} container justifyContent="flex-start" style={{ padding: '10px' }}>
            {CardItems(genre.list)}
          </Grid>
        );
      })}
    </>
  );
};

export default OverviewCard;
