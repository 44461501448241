import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Grid, Modal, Stack, SxProps, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import useSettings from 'src/hooks/useSettings';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';
import { setComment, setInitialState, setIsRequested, setShowConfirm } from '../../../../store/sendTextModalSlice';
import { MAXLENGTH } from '../../../pages/input/components/header-box/components/common/constants';

interface Props {
  // モーダルの表示/非表示フラグ
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  label: string;
  placeholder: string;
  methods: UseFormReturn<FieldValues, any>;
}

export const SendTextModal: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, title, label, placeholder, methods } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const { isRequested } = useAppSelector((state) => state.sendTextModal);
  const isLight = themeMode === 'light';
  const dispatch = useAppDispatch();
  // モーダルスタイルの定義
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 900,
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debounceHandleChange(e);
  };
  const debounceHandleChange = useMemo(
    () =>
      debounce((e) => {
        const value = e.target.value;
        const textLength = methods.getValues('requestReversionModal').length;

        if (textLength > MAXLENGTH) return dispatch(setIsRequested(true));

        if (value.trim().length !== 0) {
          dispatch(setIsRequested(false));
          dispatch(setComment(value));
        } else {
          dispatch(setInitialState());
        }
      }, 300),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}>
        <Box component={'div'} sx={modalStyle}>
          <Stack spacing={1}>
            {/* modal title */}
            <Typography variant="h4" component={'h2'}>
              {title}
            </Typography>

            {/* conditions */}
            <Grid container component={'div'} sx={{ mt: 1 }} paddingTop={2}>
              <Grid item xs={12} md={12} />
              <Grid item xs={12} md={12} />
              <Grid item xs={12}>
                <RHFonChangeTextField
                  name={'requestReversionModal'}
                  control={methods.control}
                  label={label}
                  multiline
                  rows={5}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 1000 }}
                  placeholder={placeholder}
                  fullWidth={true}
                  handleOnChange={handleChangeTextField}
                  rules={{
                    maxLength: {
                      value: MAXLENGTH,
                      message: t('validateError.maxLength', { max: MAXLENGTH }),
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* modal bottom actions */}
            <Grid container>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'right'}>
                  <Button
                    startIcon={<SendIcon />}
                    variant={'contained'}
                    children={t('button.send')}
                    onClick={() => {
                      dispatch(setShowConfirm(true));
                    }}
                    disabled={isRequested}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
