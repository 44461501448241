import React, { useCallback, useState } from 'react';
import { dispatch } from 'src/redux/store';
import { setSelectedTableIndex } from '../features/general/enquete-create/store/tableSettingSlice';

export const useTableTabs = () => {
  const [tabNumber, setTabNumber] = useState(1);

  // タブクリックハンドラー
  const handleTabClick = useCallback(
    (e: React.SyntheticEvent, newTabNumber: number) => {
      e.stopPropagation();

      // ストアへ選択中のメイン表Indexを保存
      dispatch(setSelectedTableIndex(newTabNumber - 1));
      setTabNumber(newTabNumber);
    },
    [setTabNumber],
  );

  return { tabNumber, setTabNumber, handleTabClick };
};
