import { Question } from 'src/@types/generalEnquete';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector, useSelector } from 'src/redux/store';

const MAX_NUMBER_OF_QUESTIONS = 150;

export const useControlButton = (questions: Question[], surveyDetailId?: string) => {
  const isQuestionDescriptionJpnValid = useSelector((state: RootState) => state.enqueteCreate.isQuestionDescriptionJpnValid);
  const isQuestionDescriptionEngValid = useSelector((state: RootState) => state.enqueteCreate.isQuestionDescriptionEngValid);
  const isValidSaveButton = useAppSelector((state: RootState) => state.enqueteCreate.isValidSaveButton);
  const isOpenEditor = useSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit.isOpenEditor);
  const { isAdmin, isSurveyOwner, isBonsaiCreator } = usePermission(surveyDetailId);

  // memo: 全てのバリデーションが正常状態であるか
  const isAllValid = isValidSaveButton && isQuestionDescriptionJpnValid && isQuestionDescriptionEngValid;
  // memo: すでに保存済みのアンケート(surveyDetailIdが振られている)は、システム管理者かアンケート管理者が保存できる
  //       未保存の時(新規作成した直後)はシステム管理者か、アンケート作成ロールがある人が保存できる
  const hasPermission = surveyDetailId ? isAdmin || isSurveyOwner : isAdmin || isBonsaiCreator;
  const isDisabledSave = isOpenEditor || !isAllValid || !hasPermission;
  const isDisabledLogicSetting = isOpenEditor || !isAllValid || (questions && questions.length < 2);
  const isDisabledAddQuestion = MAX_NUMBER_OF_QUESTIONS <= questions.length;
  return {
    hasPermission,
    isDisabledSave,
    isDisabledLogicSetting,
    isDisabledAddQuestion,
  };
};
