import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OverviewEnqueteOptionData } from 'src/@types/overviewEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';

export const useAddOptionItemForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData) as RespondentRequestEntity;
  const maxLength = 15;

  /**
   * モーダル内の保存ボタン押下時イベント
   * @param data
   * @param overviewInfoData
   * @param setOverviewEnqueteOptionData
   * @param setIsModalOpen
   */
  const onClickSaveModal = (
    data: FieldValues,
    overviewInfoData: OverviewEnqueteOptionData,
    setOverviewEnqueteOptionData: React.Dispatch<React.SetStateAction<OverviewEnqueteOptionData>>,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const addItemOptionData = {
      ...overviewInfoData,
      addItemList: overviewInfoData.addItemList.concat({
        itemNameJpn: data.itemJpn,
        itemNameEng: data.itemEng,
        inputContent: '',
        displayOrder: overviewInfoData.addItemList.length + 1,
      }),
    };

    dispatch(
      setEnqueteData({
        ...enqueteData,
        surveyDetail: {
          ...enqueteData.surveyDetail,
          surveyFormOptionData: {
            ...addItemOptionData,
          },
        },
      }),
    );
    setOverviewEnqueteOptionData(addItemOptionData);
    setIsModalOpen(false);
  };

  const modalValidation = (value: string) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: value }),
      },
      maxLength: {
        value: maxLength,
        message: t('validateError.maxLength', { max: maxLength }),
      },
    };
  };

  return {
    onClickSaveModal,
    modalValidation,
  };
};
