import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useState } from 'react';
import { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import { setDivisionAggregateSupplements, setTopic } from 'src/features/general/enquete-status/store/overviewAggregateSupplementSlice';
import useLocales from 'src/hooks/useLocales';
import { dispatch } from 'src/redux/store';

export const useOwnerTabContainer = (
  getValues: UseFormGetValues<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  trigger: UseFormTrigger<FieldValues>,
) => {
  const { isJapanese } = useLocales();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { putOverviewAggregateSupplement } = useEnqueteStatusPageApi();
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();

  const INDENT = '  ';

  const onClickReflectKeyTopics = () => {
    const selectedRows = gridApi?.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) return;
    const topics = getValues('topics');
    const sortedRows = selectedRows.sort((a, b) => (a.rowIndex !== null && b.rowIndex !== null ? a.rowIndex - b.rowIndex : 0));
    const addTopics = sortedRows
      .map((node) => {
        const divisionName = isJapanese ? node.data.divisionNameJpn : node.data.divisionNameEng;
        const topic = node.data.topic?.trim() ? `${INDENT}${node.data.topic}`.replaceAll('\n', `\n${INDENT}`) : '';
        return `${divisionName}\n${topic}`;
      })
      .join('\n\n');
    const value = topics?.trim() ? `${topics}\n${addTopics}` : addTopics;
    setValue('topics', value);
    trigger();
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const save = async (data: FieldValues) => {
    if (!id) {
      return;
    }
    try {
      setIsSaving(true);
      const result = await putOverviewAggregateSupplement(id, data.topics);
      if (result) {
        dispatch(setDivisionAggregateSupplements(result.divisionAggregateSupplements));
        dispatch(setTopic(result.topic));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onClickSave = async (data: FieldValues) => {
    save(data);
  };

  return {
    onClickReflectKeyTopics,
    onGridReady,
    onClickSave,
    isSaving,
  };
};
