import { Auth } from 'aws-amplify';
import axios from 'axios';
import { apiGateway } from 'src/config';
import { StatusType } from './api-constants';

export interface EnqueteThreadPriorInfo {
  categories: {
    id: string;
    nameJpn: string;
    nameEng: string;
  }[];
  surveyTitle: {
    surveyDetailId: string;
    titleJpn: string;
    titleEng: string;
  }[];
}

export interface EnqueteThreadCreator {
  title: string;
  surveyDetailId: string;
  isPrivate: boolean;
  isSendMailToUser: boolean;
  description: string;
  categories: string[];
  threadUsers: string[];
}

export interface EnqueteThreadCreatorEntitiy {
  priorInfo: EnqueteThreadCreator;
  status: StatusType;
}

export const getThreadCreatorApi = () => {
  const getBearerToken = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return `Bearer ${token}`;
  };

  const getThreadPriorInfo = async () => {
    const token = await getBearerToken();
    const response = await axios.get<EnqueteThreadPriorInfo>(apiGateway + '/enquete-threads/create', {
      headers: {
        Authorization: token,
      },
      timeout: 60000,
    });
    return response.data;
  };
  const createThread = async (data: EnqueteThreadCreator) => {
    try {
      const token = await getBearerToken();
      const response = await axios({
        url: apiGateway + `/enquete-threads/create`,
        method: 'POST',
        data: data,
        headers: { Authorization: token, 'Content-Type': 'application/json; charset=utf-8 ' },
        timeout: 60000,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      } else {
        throw error;
      }
    }
  };

  return { getThreadPriorInfo, createThread };
};
