export const initialSettings = {
  // Column settings
  colHeaders: true,
  startCols: 4,
  colWidths: 100,
  manualColumnResize: true,
  manualColumnMove: false,
  dateFormat: 'YYYY/MM/DD',
  numericFormat: { pattern: '0,0' },
  // Row settings
  rowHeaders: true,
  startRows: 4,
  manualRowResize: true,
  manualRowMove: false,
  // cell settings
  cells: undefined,
  cell: undefined,
  mergeCells: true,
  // whole table settings
  undo: true,
  redo: true,
  autoFill: true,
};
