import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { initSurveyDetail, SurveyDetailEntity } from './useSurveyDetailApi';
import { useBearerToken } from './useBearerToken';

export interface RespondentRequestEntity {
  id: string;
  titleJpn: string;
  titleEng: string;
  targetDate: string | null;
  requestedDate: string | null;
  openedAt: string;
  closedAt: string;
  createdOrganizationId: string;
  previousRespondentRequestId: string | null;
  RespondentRequestEntity: string | null;
  surveyDetail: SurveyDetailEntity;
  surveyDetailId: string;
  surveyOwnerEmails: string[];
  createdBy?: string;
  updatedBy?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export const initRespondentRequestData: RespondentRequestEntity = {
  id: '',
  titleJpn: '',
  titleEng: '',
  openedAt: '',
  closedAt: '',
  targetDate: '',
  requestedDate: '',
  createdOrganizationId: '',
  previousRespondentRequestId: '',
  RespondentRequestEntity: '',
  surveyDetail: initSurveyDetail,
  surveyDetailId: '',
  surveyOwnerEmails: [],
};

/**
 * RespondentRequest API
 *
 * @returns
 */
export const useRespondentRequestApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * アンケート管理者かを判定
   *
   * @param respondentRequestId
   * @returns
   */
  const hasOwner = async (respondentRequestId: string): Promise<RespondentRequestEntity[] | []> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<RespondentRequestEntity[] | []>(apiGateway + '/respondent-request/has-owner/' + respondentRequestId, {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });

      if (result === undefined) return [];

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRespondentRequestApi.failedOwnerData'), { variant: 'error' });
      } else {
        throw error;
      }

      return [];
    }
  };

  return {
    hasOwner,
  };
};
