import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import Decimal from 'decimal.js';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { AggregateStatus, OverviewAggregateSupplement, UpdateDivisionOrganization } from 'src/@types/overviewAggregateSupplement';
import { AggregateCustomer, AggregateSupplement, AggregateSupplementData, GetAggregateSupplement } from 'src/@types/seriesAggregateSupplement';
import { apiGateway } from 'src/config';
import { FormType, SentStatus, SurveyType } from 'src/constants';
import { NetworkAccessError } from 'src/errors/errors';
import { AnswerEntity } from './useAnswerApi';
import { useBearerToken } from './useBearerToken';

export interface EnqueteInfoEntity {
  answeredCount: number;
  requestedCount: number;
  companyNameJpn: string;
  companyNameEng: string;
  divisionNameJpn: string | null;
  divisionNameEng: string | null;
  sbuNameJpn: string | null;
  sbuNameEng: string | null;
  departmentNameJpn: string | null;
  departmentNameEng: string | null;
  id: string;
  titleJpn?: string;
  titleEng?: string;
  openedAt: string;
  closedAt: string;
  surveyType: SurveyType;
  sentStatus: SentStatus;
  sentAt: string | null;
  formType: FormType;
}

export interface RequestedTargetEntity {
  statusAns: string | null;
  updateAtAns: Date | null;
  hasReversionHistory: boolean | null;
  userNameJpn: string | null;
  userNameEng: string | null;
  companyCode: string | null;
  rank: string | null;
  companyNameJpn: string;
  companyNameEng: string;
  divisionNameJpn: string | null;
  divisionNameEng: string | null;
  sbuNameJpn: string | null;
  sbuNameEng: string | null;
  departmentNameJpn: string | null;
  departmentNameEng: string | null;
  groupCode: string | null;
  groupNameJpn: string | null;
  groupNameEng: string | null;
  regionNameJpn: string | null;
  regionNameEng: string | null;
  countryNameJpn: string | null;
  countryNameEng: string | null;
  assignedCompanyNameJpn: string | null;
  assignedCompanyNameEng: string | null;
  respondentTargetId: string | null;
  companyAbbreviation: string | null;
}

export interface ErrorReportEntity {
  statusCode: number;
  message: ErrorMessageEntity[];
  error: string;
}

export type ErrorMessageValue =
  | { limit: number }
  | { decimal_digits: number }
  | { header: string; value: string }
  | { employment_status: { jpn: string; eng: string } }
  | null;
export interface ErrorMessageEntity {
  code: number;
  row: number;
  column: string;
  description: string;
  value: ErrorMessageValue;
}

export type AggregateDetailRow = {
  companyNameJpn: string;
  companyNameEng: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupNameJpn: string;
  groupNameEng: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryNameJpn: string;
  countryNameEng: string;
  customerNameJpn: string;
  customerNameEng: string;
  customerRegionNameJpn: string;
  customerRegionNameEng: string;
  customerCountryNameJpn: string;
  customerCountryNameEng: string;
  amountJpn: string;
  amountEng: string;
  previousYear: number;
  currentYear: number;
  comment: string;
};

export type AggregateRow = {
  customerNameJpn: string;
  customerNameEng: string;
  amountJpn: string;
  amountEng: string;
  previousYear: number;
  currentYear: number;
};
export interface AggregateDetail {
  customerNameJpn: string;
  customerNameEng: string;
  aggregateDetailRows: AggregateDetailRow[];
}

export interface ExchangeRateRow {
  originalCurrencyCode: string;
  inHouseRate: Decimal;
  displayOrder: number;
  numberOfDecimalPlaces?: number;
}

export type GetSummaryAggregate = {
  currentYear: string;
  previousYear: string;
  aggregateRows: AggregateRow[];
  aggregateDetailMap: { [key: string]: AggregateDetail };
  exchangeRateRows: ExchangeRateRow[];
};

export const useEnqueteStatusPageApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * アンケート回答状況一覧画面のヘッダー部の情報を取得するためのAPI
   * @param respondentRequestId
   * @returns
   */
  const getEnqueteInfo = async (respondentRequestId: string): Promise<EnqueteInfoEntity[] | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios.get(`${apiGateway}/enquete-status-page/header/${respondentRequestId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      enqueueSnackbar(t('apiExceptionMessage.useEnqueteStatusPageApi.failedGetSurveyInformation'), { variant: 'error' });
      throw error;
    }
  };

  /**
   * アンケート回答状況一覧画面のデータテーブル部の情報を取得するためのAPI
   * @param respondentRequestId
   * @returns
   */
  const getRequestedTargetList = async (respondentRequestId: string): Promise<RequestedTargetEntity[] | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios.get(`${apiGateway}/enquete-status-page/table/${respondentRequestId}`, {
        headers: {
          Authorization: token,
        },
      });

      // レスポンス確認
      if (response.status !== 200) {
        throw Error(t('apiExceptionMessage.useEnqueteStatusPageApi.failedGetSurveyTableData'));
      }

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useEnqueteStatusPageApi.failedGetSurveyTableData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答結果エクスポート
   *
   * @param respondentRequestId
   * @returns
   */
  const exportAnswerResult = async (respondentRequestId: string, lang: 'jpn' | 'eng'): Promise<boolean | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      await axios({
        url: `${apiGateway}/enquete-status-page/download/${respondentRequestId}?lang=${lang}`,
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: token },
      }).then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = format(new Date(), 'yyyyMMddHHmmss') + '_answer_list.xlsx';
        a.click();
        a.remove();
      });

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useEnqueteStatusPageApi.failedExport'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答インポート
   *
   * @param respondentRequestId
   * @returns
   */
  const importAnswer = async (respondentRequestId: string, file: any): Promise<AnswerEntity | AxiosResponse<any> | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios({
        url: `${apiGateway}/enquete-status-page/upload/${respondentRequestId}`,
        method: 'POST',
        data: file,
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      } else {
        throw error;
      }
    }
  };

  /**
   * Data-Insight個別送信
   *
   * @param surveyDetailId
   * @returns
   */
  const sentDataInsight = async (surveyDetailId: string): Promise<boolean | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios({
        url: `${apiGateway}/enquete-status-page/send-data-to-datainsight/${surveyDetailId}`,
        method: 'POST',
        headers: { Authorization: token },
        timeout: 60000,
      });

      // レスポンス確認
      if (response.status !== 201) {
        throw Error(t('apiExceptionMessage.useEnqueteStatusPageApi.failedDataInsightSent'));
      }

      enqueueSnackbar(t('message.executeDataInsightSent'), { variant: 'success' });
      return;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new NetworkAccessError(error.response?.status);
      }
      throw error;
    }
  };

  /**
   * 系列別:アンケートの回答結果を集計した結果を取得する
   * @param respondentRequestId アンケート回答依頼ID
   * @returns 集計結果
   */
  const getTypeSeriesAggregate = async (respondentRequestId?: string): Promise<GetSummaryAggregate> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios.get<GetSummaryAggregate>(`${apiGateway}/enquete-status-page/aggregate/${respondentRequestId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteStatus.tab.summaryAggregate') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
    return { currentYear: '', previousYear: '', aggregateRows: [], aggregateDetailMap: {}, exchangeRateRows: [] };
  };

  /**
   * 系列別:アンケート集計結果の補足事項を取得する
   * @param respondentRequestId アンケート回答依頼ID
   * @returns 補足事項
   */
  const getSeriesAggregateSupplement = async (respondentRequestId: string): Promise<GetAggregateSupplement> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios.get<GetAggregateSupplement>(`${apiGateway}/enquete-status-page/aggregate-supplement/${respondentRequestId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteStatus.tab.aggregateSupplement') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
    return {
      currentYear: '',
      previousYear: '',
      answeredAggregateSupplement: new AggregateSupplementData(),
      defaultAggregateSupplement: new AggregateSupplementData(),
    };
  };

  /**
   * 系列別:アンケート集計結果の補足事項を更新する
   * @param surveyDetailId
   * @returns 補足事項
   */
  const updateSeriesAggregateSupplement = async (surveyDetailId: string, aggregateCustomers: AggregateCustomer[]): Promise<AggregateSupplement> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios.put(
        `${apiGateway}/enquete-status-page/aggregate-supplement/${surveyDetailId}`,
        { aggregateSupplementData: { aggregateCustomers } },
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );
      enqueueSnackbar(t('message.update'), { variant: 'success' });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('enqueteStatus.tab.aggregateSupplement') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
    return { aggregateSupplementData: { aggregateCustomers: [] } };
  };

  /**
   * 概況表:アンケート集計結果の補足事項を取得する
   * @param respondentRequestId アンケート回答依頼ID
   * @returns 補足事項
   */
  const getOverviewAggregateSupplement = async (respondentRequestId: string): Promise<OverviewAggregateSupplement | null> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios.get<OverviewAggregateSupplement | null>(
        `${apiGateway}/enquete-status-page/aggregate-supplement/overview/${respondentRequestId}`,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteStatus.tab.aggregateSupplement') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
    return null;
  };

  /**
   * 概況表:アンケート集計結果の補足事項を更新する
   * @param respondentRequestId アンケート回答依頼ID
   * @param topic 補足事項のトピック
   * @param divisionCode 本部コード
   * @param aggregateStatus 集計ステータス
   */
  const putOverviewAggregateSupplement = async (
    respondentRequestId: string,
    topic: string,
    divisionOrganization?: UpdateDivisionOrganization,
    aggregateStatus?: AggregateStatus,
  ): Promise<OverviewAggregateSupplement | null> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const result = await axios.put<OverviewAggregateSupplement | null>(
        `${apiGateway}/enquete-status-page/aggregate-supplement/overview/${respondentRequestId}`,
        { ...divisionOrganization, topic, aggregateStatus },
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );
      enqueueSnackbar(t('message.update'), { variant: 'success' });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('enqueteStatus.tab.aggregateSupplement') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
    return null;
  };

  return {
    getEnqueteInfo,
    getRequestedTargetList,
    exportAnswerResult,
    importAnswer,
    sentDataInsight,
    getTypeSeriesAggregate,
    getSeriesAggregateSupplement,
    updateSeriesAggregateSupplement,
    getOverviewAggregateSupplement,
    putOverviewAggregateSupplement,
  };
};
