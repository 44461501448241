import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Paper, Tab } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ANSWER_UNIT } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { tabStyles } from 'src/utils/tabStyles';
import { EnqueteConfirmTab } from '../../../../../shared-tabs/answer-confirm-status/components/EnqueteConfirmTab';
import ChangeRespondentComponent from '../../../../../shared-tabs/change-respondent/components/ChangeRespondentComponent';
import OutsideSurveyPeriodTypography from '../../../../../shared-tabs/outside-survey/components/OutsideSurveyPeriodTypography';
import EnqueteAnswerGeneralQuestionFormTab from './EnqueteAnswerGeneralQuestionFormTab';

const TAB_VALUE = {
  QUESTION: 'generalQuestion',
  CONFIRM: 'EnqueteStatus',
  CHANGE: 'ChangeRespondents',
} as const;
type TabValue = typeof TAB_VALUE[keyof typeof TAB_VALUE];

export const EnqueteAnswerTypeGeneralQuestionTabContainer: React.FC = () => {
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);
  const isBeforeRequestTerm = useAppSelector((state) => state.enqueteAnswer.isBeforeRequestTerm);
  const isAfterRequestTerm = useAppSelector((state) => state.enqueteAnswer.isAfterRequestTerm);
  const surveyDetailData = useAppSelector((stateGeneral) => stateGeneral.enqueteAnswer.surveyDetailData);

  // 個人アンケート判定
  const isPersonalEnquete = surveyDetailData?.surveyHeader?.answerUnit === ANSWER_UNIT.PERSONAL;
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  // タブ切り替え
  const handleChange = (event: SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const params = useLocation().search;
  // URLに含まれるTabパラメータを取得
  const tabParmsValue = new URLSearchParams(params).get('tab');
  const defaultTab = tabParmsValue ? tabParmsValue : TAB_VALUE.QUESTION;
  const [tabValue, setTabValue] = useState<TabValue>(defaultTab as TabValue);

  return (
    <TabContext value={tabValue}>
      <Paper variant="elevation" sx={{ mb: 10 }}>
        <TabList onChange={handleChange}>
          <Tab
            style={tabStyles(currentLang.value)}
            label={t('enqueteAnswerInput.tab.surveyTypeGeneralQuestion')}
            value={TAB_VALUE.QUESTION}
            sx={{ paddingLeft: 2 }}
            disabled={isBeforeRequestTerm}
          />
          <Tab
            style={tabStyles(currentLang.value)}
            label={t('enqueteAnswerInput.tab.responseConfirmationStatus')}
            value={TAB_VALUE.CONFIRM}
            disabled={isBeforeRequestTerm}
          />
          {!isPersonalEnquete ? (
            <Tab
              style={tabStyles(currentLang.value)}
              label={t('enqueteAnswerInput.tab.changeRespondents')}
              value={TAB_VALUE.CHANGE}
              disabled={isAfterRequestTerm}
            />
          ) : null}
        </TabList>
        {/** アンケートコンポーネント */}
        <TabPanel value={TAB_VALUE.QUESTION}>
          {isBeforeRequestTerm ? <OutsideSurveyPeriodTypography requestStatus={'before'} /> : <EnqueteAnswerGeneralQuestionFormTab />}
        </TabPanel>
        {/** 回答確認状況コンポーネント */}
        <TabPanel value={TAB_VALUE.CONFIRM}>
          {isBeforeRequestTerm ? <OutsideSurveyPeriodTypography requestStatus={'before'} /> : <EnqueteConfirmTab />}
        </TabPanel>
        {!isPersonalEnquete ? (
          <TabPanel value={TAB_VALUE.CHANGE}>
            {isAfterRequestTerm ? (
              <OutsideSurveyPeriodTypography requestStatus={'after'} />
            ) : (
              <ChangeRespondentComponent
                respondentRequestId={respondentRequestData.id}
                respondentTargetId={respondentTargetData.id}
                organizationId={respondentTargetData.organizationId}
              />
            )}
          </TabPanel>
        ) : null}
      </Paper>
    </TabContext>
  );
};
