import { useState } from 'react';

export const useReturnedHandleClick = (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
  const [isReversionRequest, setIsReversionRequest] = useState<boolean>(false);

  const handleReversionType = (event: string) => {
    setIsOpen(true);
    setIsReversionRequest(event === 'ReversionRequest');
  };
  return { handleReversionType, isReversionRequest };
};
