import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DISCLOSURE_LEVEL, DisclosureLevel } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import InputRadioGroup from './InputRadioGroup';

type DisclousureLevelFormProps = {
  methods: UseFormReturn;
  disclosureLevel: DisclosureLevel | null | undefined;
};

export const DisclousureLevelForm = ({ methods, disclosureLevel }: DisclousureLevelFormProps) => {
  const { rulesDisclosureLevel } = useValidator(methods);
  const { t } = useTranslation();

  return (
    <InputRadioGroup
      name={'disclosureLevel'}
      defaultValue={disclosureLevel === undefined || disclosureLevel === null ? DISCLOSURE_LEVEL.PRIVATE : disclosureLevel}
      control={methods.control}
      rules={rulesDisclosureLevel}
      getValues={methods.getValues}
      list={[
        { value: DISCLOSURE_LEVEL.PRIVATE, label: t('enqueteGeneralCommon.disclosureLevelPrivate') },
        { value: DISCLOSURE_LEVEL.INTERNAL, label: t('enqueteGeneralCommon.disclosureLevelInternal') },
        { value: DISCLOSURE_LEVEL.PUBLIC, label: t('enqueteGeneralCommon.disclosureLevelPublic') },
      ]}
      disabled={false}
    />
  );
};
