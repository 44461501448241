import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationEntity, useOrganizationApi } from 'src/api/useOrganizationApi';
import { SearchConditionProps } from 'src/components/app-components/bonsai/SearchModal';
import { SearchConditionList } from './useSearchConditionList';

interface Props {
  surveyDetailId: string;
  list: {
    orgLevelTypes: SearchConditionList[];
    companies: SearchConditionList[];
    divisions: SearchConditionList[];
    sbus: SearchConditionList[];
    departments: SearchConditionList[];
    groups: SearchConditionList[];
    ranks: SearchConditionList[];
    countries: SearchConditionList[];
    regions: SearchConditionList[];
  };
  methods: {
    makeSbus: { (divisionCode?: string): Promise<void> };
    makeDepartments: { (divisionCode?: string, sbuCode?: string): Promise<void> };
    makeGroups: { (divisionCode?: string, sbuCode?: string, departmentCode?: string): Promise<void> };
  };
}

interface SearchCondition {
  orgLevelType: string;
  companyCode?: string;
  divisionCode?: string;
  sbuCode?: string;
  departmentCode?: string;
  groupCode?: string;
  rank?: string;
  countryCode?: string;
  regionCode?: string;
  isDeleted: boolean;
}

export const useSearchOrganization = (props: Props) => {
  const { t } = useTranslation();
  const { getOrganizationsByFilter } = useOrganizationApi();
  const [condition, setCondition] = useState<SearchCondition>({ orgLevelType: 'COMPANY', isDeleted: false });
  const [orgs, setOrgs] = useState<OrganizationEntity[]>([]);

  const getOrganizations = useCallback(
    async (where: {}) => {
      if (!props.surveyDetailId) return;
      const res = await getOrganizationsByFilter(props.surveyDetailId, where);
      setOrgs(res ?? []);
    },
    [getOrganizationsByFilter, props.surveyDetailId],
  );

  const onChange = useCallback(
    async (key: string, value: string | undefined) => {
      const cond = condition;
      switch (key) {
        case 'orgLevel':
          cond.orgLevelType = value ?? 'COMPANY';
          break;
        case 'company':
          cond.companyCode = value;
          break;
        case 'division':
          cond.divisionCode = value;
          cond.sbuCode = undefined;
          cond.departmentCode = undefined;
          cond.groupCode = undefined;
          await props.methods.makeSbus(cond.divisionCode);
          await props.methods.makeDepartments(cond.divisionCode, cond.sbuCode);
          await props.methods.makeGroups(cond.divisionCode, cond.sbuCode, cond.departmentCode);
          break;
        case 'sbu':
          cond.sbuCode = value;
          cond.departmentCode = undefined;
          cond.groupCode = undefined;
          await props.methods.makeDepartments(cond.divisionCode, cond.sbuCode);
          await props.methods.makeGroups(cond.divisionCode, cond.sbuCode, cond.departmentCode);
          break;
        case 'department':
          cond.departmentCode = value;
          cond.groupCode = undefined;
          await props.methods.makeGroups(cond.divisionCode, cond.sbuCode, cond.departmentCode);
          break;
        case 'group':
          cond.groupCode = value;
          break;
        case 'rank':
          cond.rank = value;
          break;
        case 'country':
          cond.countryCode = value;
          break;
        case 'region':
          cond.regionCode = value;
          break;
      }
      setCondition(cond);
      await getOrganizations(cond);
    },
    [condition, getOrganizations, props.methods],
  );

  useEffect(() => {
    getOrganizations(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索条件を定義
  const labelPrefix = 'enqueteCreate.selectRespondentAndExportModal.condition';
  const makeSearchConditionProp = useCallback(
    (key: string, style: { xs: number }, value: string | undefined | null, list: { key: string; label: string }[]): SearchConditionProps => {
      const selectedValue = value === undefined ? null : value;
      return {
        id: key,
        label: ['region', 'country'].includes(key) ? t(`common.${key}`) : t(`${labelPrefix}.${key}`),
        style,
        type: 'autocomplete',
        props: {
          value: selectedValue,
          list,
          onChange: (value) => onChange(key, value),
        },
      };
    },
    [onChange, t],
  );

  const conditions: SearchConditionProps[][] = useMemo(() => {
    return [
      [
        makeSearchConditionProp('orgLevel', { xs: 3 }, condition.orgLevelType, props.list.orgLevelTypes),
        makeSearchConditionProp('company', { xs: 8 }, condition.companyCode, props.list.companies),
      ],
      [
        makeSearchConditionProp('division', { xs: 5 }, condition.divisionCode, props.list.divisions),
        makeSearchConditionProp('sbu', { xs: 6 }, condition.sbuCode, props.list.sbus),
      ],
      [
        makeSearchConditionProp('department', { xs: 5 }, condition.departmentCode, props.list.departments),
        makeSearchConditionProp('group', { xs: 5 }, condition.groupCode, props.list.groups),
      ],
      [
        makeSearchConditionProp('rank', { xs: 2 }, condition.rank, props.list.ranks),
        makeSearchConditionProp('region', { xs: 4 }, condition.regionCode, props.list.regions),
        makeSearchConditionProp('country', { xs: 4 }, condition.countryCode, props.list.countries),
      ],
    ];
  }, [
    condition.companyCode,
    condition.countryCode,
    condition.departmentCode,
    condition.divisionCode,
    condition.groupCode,
    condition.orgLevelType,
    condition.rank,
    condition.regionCode,
    condition.sbuCode,
    makeSearchConditionProp,
    props.list.companies,
    props.list.countries,
    props.list.departments,
    props.list.divisions,
    props.list.groups,
    props.list.orgLevelTypes,
    props.list.ranks,
    props.list.regions,
    props.list.sbus,
  ]);

  return { conditions, orgs };
};
