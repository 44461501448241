import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Alert, Tab } from '@mui/material';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { DivisionTab } from './DivisionTab';

export const DivisionRepTabContainer: React.FC = () => {
  const { isJapanese } = useLocales();
  const { t } = useTranslation();
  const requestStatus = useAppSelector((state) => state.enqueteStatus.requestStatus);
  const { divisionAggregateSupplements } = useAppSelector((state) => state.overviewAggregateSupplement);
  //タブ
  const [tabValue, setTabValue] = useState('0');

  //タブ切り替え関数
  const handleChange = (_e: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const renderAlert = () => {
    if (requestStatus === REQUEST_STATUS.IN_PROGRESS) {
      return <Alert severity="info"> {t('enqueteStatus.aggregateSupplement.description.divisionRep')}</Alert>;
    } else {
      return <Alert severity="warning"> {t('enqueteStatus.aggregateSupplement.warning.notInProgress')}</Alert>;
    }
  };

  const rendetTabs = useMemo(() => {
    return (
      <TabList variant="scrollable" scrollButtons={false} onChange={handleChange}>
        {divisionAggregateSupplements.map((data, i) => (
          <Tab key={`division-tab-${data.divisionCode}`} label={isJapanese ? data.divisionNameJpn : data.divisionNameEng} value={`${i}`} />
        ))}
      </TabList>
    );
  }, [divisionAggregateSupplements, isJapanese]);

  if (divisionAggregateSupplements.length === 0) {
    return <Alert severity="warning">{t('enqueteStatus.aggregateSupplement.warning.notExistAnswered')}</Alert>;
  }

  return (
    <>
      {renderAlert()}
      <TabContext value={tabValue}>
        {rendetTabs}
        {divisionAggregateSupplements.map((data, i) => (
          <TabPanel key={`division-tab-panel-${data.divisionCode}`} value={`${i}`}>
            <DivisionTab aggregateSupplement={data} />
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
};
