import { Autocomplete, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useSurveyHeaderApi } from 'src/api/useSurveyHeaderApi';
import { ANSWER_UNIT, FORM_TYPE, SURVEY_TYPE } from 'src/constants';
import { SearchConditionList } from 'src/features/general/enquete-create/hooks/useSearchConditionList';
import { setEnqueteTypeList } from 'src/features/general/enquete-create/store/enqueteInfoSlice';
import { useDispatch } from 'src/redux/store';

type RHFInputOwnerAutoCompleteProps = {
  name: string;
  label: string;
  options: SearchConditionList[];
  defaultValue: SearchConditionList;
  methods: UseFormReturn;
  rules: RegisterOptions;
  isOverview: boolean;
};

const RHFInputOwnerAutoComplete = ({ name, label, options, defaultValue, methods, rules, isOverview }: RHFInputOwnerAutoCompleteProps) => {
  const dispatch = useDispatch();
  const surveyHeaderApi = useSurveyHeaderApi();
  const onInputChange = async (newInputValue: SearchConditionList) => {
    const selectedId = options.find((item: { key: string; label: string }) => item.label === newInputValue.label)?.key || '';
    const newData = newInputValue;

    if (selectedId !== '') {
      const selectOwnerOrganization = options.find((item: { key: string; label: string }) => item.key === selectedId);

      if (selectOwnerOrganization) {
        const surveyType = isOverview ? SURVEY_TYPE.SPECIAL : SURVEY_TYPE.GENERAL;
        const formType = isOverview ? FORM_TYPE.OVERVIEW : undefined;
        const enqueteTypeListByOwnerOrganization = await surveyHeaderApi.getSurveyHeaderListByOwnerOrganizationId(
          selectOwnerOrganization.key,
          surveyType,
          formType,
        );
        dispatch(setEnqueteTypeList(enqueteTypeListByOwnerOrganization));
        methods.setValue('labelJpn', []);
        methods.setValue('labelEng', '');
        methods.setValue('enqueteQuestionFormat', FORM_TYPE.QA);
        methods.setValue('enqueteAnswerUnit', ANSWER_UNIT.ORGANIZATION);
        methods.clearErrors('labelJpn');
        methods.clearErrors('labelEng');
        methods.clearErrors('enqueteQuestionFormat');
        methods.clearErrors('enqueteAnswerUnit');
      }
    }
    methods.setValue(name, newData);
  };

  useEffect(() => {
    // 「新規作成」ボタンから遷移する場合、デフォルト値をセット
    if (methods.getValues(name) === undefined && defaultValue.key) {
      // デフォルト値がある場合、onInputChangeを呼び出し、その他画面項目の状態を変更
      onInputChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          size="small"
          options={options}
          defaultValue={defaultValue ?? { key: '', label: '' }}
          value={methods.getValues(name) === undefined ? defaultValue : methods.getValues(name)}
          // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
          isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
          onChange={(_, newInputValue) => {
            onInputChange(newInputValue ?? { key: '', label: '' });
            field.onChange(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" label={label} error={!!fieldState.error} helperText={fieldState.error?.message} />
          )}
        />
      )}
    />
  );
};

export default RHFInputOwnerAutoComplete;
