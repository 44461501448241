import { HotTable } from '@handsontable/react';
import { Box, Grid, Typography } from '@mui/material';
import { CellCoords, CellRange } from 'handsontable';
import 'handsontable/dist/handsontable.full.min.css';
import { enUS, jaJP } from 'handsontable/i18n';
import { useSnackbar } from 'notistack5';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import { handsontableLicenseKey } from 'src/config';
import { TABLE_SETTINGS } from 'src/generalConstants';
import useLocales from 'src/hooks/useLocales';
import { replaceURLWithHTMLLinks } from 'src/utils/replaceURLWithHTMLLinks';
import useNestedHeadersSetting from '../hooks/useNestedHeadersSetting';
import { useCreateMainTable } from '../../../tab-containers/type-general-table/components/tabs/table/hooks/useCreateMainTable';
import { RootState, useSelector } from 'src/redux/store';

const NestedHeadersSettingModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { currentLang } = useLocales();
  const { setOpenColumnHeaderModal } = useCreateMainTable();
  const explanation = replaceURLWithHTMLLinks(t('enqueteCreate.colHeaderSettingModal.explanation'));
  const hotRefForNestedHeaders = useRef<HotTable>(null);

  // ストア
  const isOpenColumnHeaderModal = useSelector((state: RootState) => state.tableSetting.isOpenColumnHeaderModal);
  // Hooks
  const { isNestedHeaderValid, onClick, setIsNestedHeaderValid } = useNestedHeadersSetting({
    isOpen: isOpenColumnHeaderModal,
    onClose: () => setOpenColumnHeaderModal(false),
    hotRefForNestedHeaders,
  });

  return (
    <StyledModal
      isOpen={isOpenColumnHeaderModal}
      modalTitle={t('enqueteCreate.colHeaderSettingModal.pageTitle')}
      onCloseFunc={() => setOpenColumnHeaderModal(false)}
      keepMounted={true}>
      <Label color="info" sx={{ whiteSpace: 'pre-wrap', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}>
        <Box style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{explanation}</Box>
      </Label>
      <Grid container>
        <Grid item xs={12}>
          {!isNestedHeaderValid && (
            <Typography variant="body1" color="error">
              {t('enqueteCreate.colHeaderSettingModal.message.invalidNestedStructure')}
            </Typography>
          )}
          <HotTable
            style={{ height: TABLE_SETTINGS.SUB.TABLE_HEIGHT, overflowX: 'hidden', overflowY: 'hidden' }}
            ref={hotRefForNestedHeaders}
            language={currentLang.value === 'ja' ? jaJP.languageCode : enUS.languageCode}
            width="100%"
            manualColumnMove={false}
            manualRowMove={false}
            maxRows={TABLE_SETTINGS.MAIN.MAX_ROWS}
            maxCols={TABLE_SETTINGS.MAIN.MAX_COLS}
            contextMenu={{
              items: {
                // memo:
                //   列数はこのダイアログでヘッダを設定する対象となる HotTable の列数に合わせればよく、
                //   このダイアログ内で列操作は不要なため、コンテキストメニューからは除外しておく
                row_above: {},
                row_below: {},
                remove_row: {},
                mergeCells: {},
              },
            }}
            afterContextMenuShow={() => {
              // memo:
              //   MUI Modal の z-index が 1060 で Modal 上で Handsontable の ContextMenu を開いたときに
              //   Modal の裏に隠れてしまわないよう、無理やり z-index を変更
              //   また、編集画面側の HotTable の contextMenu が表示されていない場合であっても、
              //   HTML 要素としては存在してしまうため、複数取得されるところも考慮している
              const contextMenus = [...document.querySelectorAll('.htContextMenu.handsontable')] as HTMLElement[];
              contextMenus.forEach((e) => {
                if (e.offsetParent) e.style.zIndex = '1500';
              });
            }}
            beforeMergeCells={(cellRange: CellRange) => {
              if (0 < cellRange.to.row - cellRange.from.row) {
                enqueueSnackbar(t('enqueteCreate.colHeaderSettingModal.message.warningMergeCells'), { variant: 'warning' });
              }
            }}
            afterUnmergeCells={(cellRange: { from: CellCoords; to: CellCoords; highlight: CellCoords }) => {
              const { from, to } = cellRange;
              for (let row = from.row; row <= to.row; row++) {
                for (let col = from.col; col <= to.col; col++) {
                  hotRefForNestedHeaders?.current?.hotInstance?.setCellMeta(row, col, 'rowspan', 0);
                  hotRefForNestedHeaders?.current?.hotInstance?.setCellMeta(row, col, 'colspan', 0);
                }
              }
              hotRefForNestedHeaders?.current?.hotInstance?.render();
            }}
            licenseKey={handsontableLicenseKey}
          />
        </Grid>
        <Grid item container xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
          <StyledButton
            color="error"
            onClick={() => {
              setIsNestedHeaderValid(true);
              setOpenColumnHeaderModal(false);
            }}>
            {t('button.cancel')}
          </StyledButton>
          <StyledButton onClick={() => onClick()}>{t('button.setting')}</StyledButton>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default NestedHeadersSettingModal;
