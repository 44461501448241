import { ColDef, ColGroupDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'src/utils/currencyFormat';

export const useExchangeRateColdef = () => {
  const { t } = useTranslation();

  const gridOptions: GridOptions = {
    domLayout: 'print',
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: false,
    resizable: true,
  };

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      field: 'originalCurrencyCode',
      headerName: t('common.currency'),
      minWidth: 200,
    },
    {
      field: 'inHouseRate',
      headerName: t('common.yen'),
      valueFormatter: (params: ValueFormatterParams) => {
        const numberOfDecimalPlaces = params.data.numberOfDecimalPlaces;
        return currencyFormat(params.value, numberOfDecimalPlaces, numberOfDecimalPlaces);
      },
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
    },
    { field: 'displayOrder', hide: true, sort: 'asc' },
  ];

  return {
    defaultColDef,
    colDef,
    gridOptions,
  };
};
