import React, { useState } from 'react';
import { FORM_TYPE } from 'src/constants';
import {
  EnqueteAnswerLoadInputEntity,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useAppSelector } from 'src/redux/store';
import { SeriesSurveyTabContainer } from '../../../tab-containers/series-survey/components/SeriesSurveyTabContainer';
import { EnqueteAnswerTypeOverviewTabContainer } from '../../../tab-containers/type-overview-survey/components/EnqueteAnswerTypeOverviewTabContainer';
import { TypePersonnelSurveyTabContainer } from '../../../tab-containers/type-personnel-survey/components/TypePersonnelSurveyTabContainer';
import { EnqueteAnswerHeaderBox } from './header-box/components/EnqueteAnswerHeaderBox';

export const SpecialFormTypeSwitcher: React.FC = () => {
  // 取得したSurveyDetailの情報を格納
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);

  // 人事
  const enqueteType = surveyDetailData.surveyFormData?.enqueteType
    ? surveyDetailData.surveyFormData?.enqueteType
    : surveyDetailData.surveyHeader?.formType;

  // 人件費人員(雇用形態別)：グリッド回答情報
  const [personnelEmploymentRowData, setPersonnelEmploymentRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(雇用形態別)：年計-実見入力内容
  const [personnelEmploymentResultAndPlanDiffForm, setPersonnelEmploymentResultAndPlanDiffForm] =
    useState<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>(undefined);
  // 人件費人員(雇用形態別)：中計-年計入力内容
  const [personnelEmploymentPlanAndPlanDiffForm, setPersonnelEmploymentPlanAndPlanDiffForm] =
    useState<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>(undefined);
  // 人件費人員(機能別)：コーポレートグリッド回答情報
  const [personnelCorporateRowData, setPersonnelCorporateRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(機能別)：その他詳細入力内容
  const [personnelCorporateOtherFormTextValue, setOtherFormTextValue] = useState<string | undefined>(undefined);
  // 人件費人員(機能別)：営業グリッド回答情報
  const [personnelSalesRowData, setPersonnelSalesRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)：1.正社員　グリッド回答情報
  const [personnelPriorityDomainRegularEmployeeRowData, setPersonnelPriorityDomainRegularEmployeeRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)：2.TTCからの出向受入　グリッド回答情報
  const [personnelPriorityDomainSecondmentFromTTCRowData, setPersonnelPriorityDomainSecondmentFromTTCRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)：3.2.以外の出向受入　グリッド回答情報
  const [personnelPriorityDomainSecondmentFromOtherRowData, setPersonnelPriorityDomainSecondmentFromOtherRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト　グリッド回答情報
  const [personnelPriorityDomainDispatchEtcRowData, setPersonnelPriorityDomainDispatchEtcRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)：年計-実見入力内容
  const [personnelPriorityDomainResultAndPlanDiffForm, setPersonnelPriorityDomainResultAndPlanDiffForm] =
    useState<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>(undefined);
  // 人件費人員(重点分野)：中計-年計入力内容
  const [personnelPriorityDomainPlanAndPlanDiffForm, setPersonnelPriorityDomainPlanAndPlanDiffForm] =
    useState<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>(undefined);
  // 製造原価人員(雇用形態別)：グリッド回答情報
  const [manufyearPlanurEmploymentRowData, setManufyearPlanurEmploymentRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 製造原価人員(雇用形態別)：年計-実見入力内容
  const [manufyearPlanurEmploymentResultAndPlanDiffForm, setManufyearPlanurEmploymentResultAndPlanDiffForm] =
    useState<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>(undefined);
  // 製造原価人員(雇用形態別)：中計-年計入力内容
  const [manufyearPlanurEmploymentPlanAndPlanDiffForm, setManufyearPlanurEmploymentPlanAndPlanDiffForm] =
    useState<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>(undefined);
  // 製造原価人員(重点分野)：1.正社員　グリッド回答情報
  const [manufyearPlanurPriorityDomainRegularEmployeeRowData, setManufPriorityDomainFullTimeEmployeeRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　グリッド回答情報
  const [manufyearPlanurPriorityDomainOtherEmployeesRowData, setManufPriorityDomainOtherRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)：年計-実見入力内容
  const [manufyearPlanurPriorityDomainResultAndPlanDiffForm, setManufPriorityDomainResultAndPlanDiffForm] =
    useState<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>(undefined);
  // 人件費人員(重点分野)：中計-年計入力内容
  const [manufyearPlanurPriorityDomainPlanAndPlanDiffForm, setManufPriorityDomainPlanAndPlanDiffForm] =
    useState<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>(undefined);

  switch (enqueteType) {
    // 人員計画調査アンケート
    case 'personnelSurvey':
      return (
        <>
          <EnqueteAnswerHeaderBox
            formType={surveyDetailData.surveyHeader ? surveyDetailData.surveyHeader.formType : undefined}
            personnelEmploymentRowData={personnelEmploymentRowData}
            personnelEmploymentResultAndPlanDiffForm={personnelEmploymentResultAndPlanDiffForm}
            personnelEmploymentPlanAndPlanDiffForm={personnelEmploymentPlanAndPlanDiffForm}
            personnelCorporateRowData={personnelCorporateRowData}
            personnelCorporateOtherFormTextValue={personnelCorporateOtherFormTextValue}
            personnelSalesRowData={personnelSalesRowData}
            // 人件費人員(重点分野)：1.正社員　グリッド回答情報
            personnelPriorityDomainRegularEmployeeRowData={personnelPriorityDomainRegularEmployeeRowData}
            // 人件費人員(重点分野)：2.TTCからの出向受入　グリッド回答情報
            personnelPriorityDomainSecondmentFromTTCRowData={personnelPriorityDomainSecondmentFromTTCRowData}
            // 人件費人員(重点分野)：3.2.以外の出向受入　グリッド回答情報
            personnelPriorityDomainSecondmentFromOtherRowData={personnelPriorityDomainSecondmentFromOtherRowData}
            // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト　グリッド回答情報
            personnelPriorityDomainDispatchEtcRowData={personnelPriorityDomainDispatchEtcRowData}
            // 人件費人員(重点分野)：年計-実見入力内容
            personnelPriorityDomainResultAndPlanDiffForm={personnelPriorityDomainResultAndPlanDiffForm}
            // 人件費人員(重点分野)：中計-年計入力内容
            personnelPriorityDomainPlanAndPlanDiffForm={personnelPriorityDomainPlanAndPlanDiffForm}
            manufyearPlanurEmploymentRowData={manufyearPlanurEmploymentRowData}
            manufyearPlanurEmploymentResultAndPlanDiffForm={manufyearPlanurEmploymentResultAndPlanDiffForm}
            manufyearPlanurEmploymentPlanAndPlanDiffForm={manufyearPlanurEmploymentPlanAndPlanDiffForm}
            // 製造原価人員(重点分野)：1.正社員　グリッド回答情報
            manufyearPlanurPriorityDomainRegularEmployeeRowData={manufyearPlanurPriorityDomainRegularEmployeeRowData}
            // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　グリッド回答情報
            manufyearPlanurPriorityDomainOtherEmployeesRowData={manufyearPlanurPriorityDomainOtherEmployeesRowData}
            // 人件費人員(重点分野)：年計-実見入力内容
            manufyearPlanurPriorityDomainResultAndPlanDiffForm={manufyearPlanurPriorityDomainResultAndPlanDiffForm}
            // 人件費人員(重点分野)：中計-年計入力内容
            manufyearPlanurPriorityDomainPlanAndPlanDiffForm={manufyearPlanurPriorityDomainPlanAndPlanDiffForm}
          />

          <TypePersonnelSurveyTabContainer
            personnelEmploymentRowData={personnelEmploymentRowData}
            setPersonnelEmploymentRowData={setPersonnelEmploymentRowData}
            personnelEmploymentResultAndPlanDiffForm={personnelEmploymentResultAndPlanDiffForm}
            setPersonnelEmploymentResultAndPlanDiffForm={setPersonnelEmploymentResultAndPlanDiffForm}
            personnelEmploymentPlanAndPlanDiffForm={personnelEmploymentPlanAndPlanDiffForm}
            setPersonnelEmploymentPlanAndPlanDiffForm={setPersonnelEmploymentPlanAndPlanDiffForm}
            personnelCorporateRowData={personnelCorporateRowData}
            setPersonnelCorporateRowData={setPersonnelCorporateRowData}
            personnelCorporateOtherFormTextValue={personnelCorporateOtherFormTextValue}
            setOtherFormTextValue={setOtherFormTextValue}
            personnelSalesRowData={personnelSalesRowData}
            setPersonnelSalesRowData={setPersonnelSalesRowData}
            // 人件費人員(重点分野)：1.正社員　グリッド回答情報
            personnelPriorityDomainRegularEmployeeRowData={personnelPriorityDomainRegularEmployeeRowData}
            setPersonnelPriorityDomainRegularEmployeeRowData={setPersonnelPriorityDomainRegularEmployeeRowData}
            // 人件費人員(重点分野)：2.TTCからの出向受入　グリッド回答情報
            personnelPriorityDomainSecondmentFromTTCRowData={personnelPriorityDomainSecondmentFromTTCRowData}
            setPersonnelPriorityDomainSecondmentFromTTCRowData={setPersonnelPriorityDomainSecondmentFromTTCRowData}
            // 人件費人員(重点分野)：3.2.以外の出向受入　グリッド回答情報
            personnelPriorityDomainSecondmentFromOtherRowData={personnelPriorityDomainSecondmentFromOtherRowData}
            setPersonnelPriorityDomainSecondmentFromOtherRowData={setPersonnelPriorityDomainSecondmentFromOtherRowData}
            // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト　グリッド回答情報
            personnelPriorityDomainDispatchEtcRowData={personnelPriorityDomainDispatchEtcRowData}
            setPersonnelPriorityDomainDispatchEtcRowData={setPersonnelPriorityDomainDispatchEtcRowData}
            // 人件費人員(重点分野)：年計-実見入力内容
            personnelPriorityDomainResultAndPlanDiffForm={personnelPriorityDomainResultAndPlanDiffForm}
            setPersonnelPriorityDomainResultAndPlanDiffForm={setPersonnelPriorityDomainResultAndPlanDiffForm}
            // 人件費人員(重点分野)：中計-年計入力内容
            personnelPriorityDomainPlanAndPlanDiffForm={personnelPriorityDomainPlanAndPlanDiffForm}
            setPersonnelPriorityDomainPlanAndPlanDiffForm={setPersonnelPriorityDomainPlanAndPlanDiffForm}
            manufyearPlanurEmploymentRowData={manufyearPlanurEmploymentRowData}
            setManufyearPlanurEmploymentRowData={setManufyearPlanurEmploymentRowData}
            manufyearPlanurEmploymentResultAndPlanDiffForm={manufyearPlanurEmploymentResultAndPlanDiffForm}
            setManufyearPlanurEmploymentResultAndPlanDiffForm={setManufyearPlanurEmploymentResultAndPlanDiffForm}
            manufyearPlanurEmploymentPlanAndPlanDiffForm={manufyearPlanurEmploymentPlanAndPlanDiffForm}
            setManufyearPlanurEmploymentPlanAndPlanDiffForm={setManufyearPlanurEmploymentPlanAndPlanDiffForm}
            // 製造原価人員(重点分野)：1.正社員　グリッド回答情報
            manufyearPlanurPriorityDomainRegularEmployeeRowData={manufyearPlanurPriorityDomainRegularEmployeeRowData}
            setManufPriorityDomainFullTimeEmployeeRowData={setManufPriorityDomainFullTimeEmployeeRowData}
            // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　グリッド回答情報
            manufyearPlanurPriorityDomainOtherEmployeesRowData={manufyearPlanurPriorityDomainOtherEmployeesRowData}
            setManufPriorityDomainOtherRowData={setManufPriorityDomainOtherRowData}
            // 人件費人員(重点分野)：年計-実見入力内容
            manufyearPlanurPriorityDomainResultAndPlanDiffForm={manufyearPlanurPriorityDomainResultAndPlanDiffForm}
            setManufPriorityDomainResultAndPlanDiffForm={setManufPriorityDomainResultAndPlanDiffForm}
            // 人件費人員(重点分野)：中計-年計入力内容
            manufyearPlanurPriorityDomainPlanAndPlanDiffForm={manufyearPlanurPriorityDomainPlanAndPlanDiffForm}
            setManufPriorityDomainPlanAndPlanDiffForm={setManufPriorityDomainPlanAndPlanDiffForm}
          />
        </>
      );
    case FORM_TYPE.SERIES:
      return (
        <>
          {/* ヘッダーコンポーネント */}
          <EnqueteAnswerHeaderBox formType={FORM_TYPE.SERIES} />
          <SeriesSurveyTabContainer />
        </>
      );
    case FORM_TYPE.OVERVIEW:
      return (
        <>
          {/* ヘッダーコンポーネント */}
          <EnqueteAnswerHeaderBox formType={FORM_TYPE.OVERVIEW} />
          <EnqueteAnswerTypeOverviewTabContainer />
        </>
      );
    default:
      return null;
  }
};
