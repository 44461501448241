import { Box, Drawer, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavListProps } from 'src/components/dashboard/nav-section/type';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import { NavSectionVertical } from 'src/components/dashboard/nav-section';
import { NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import useResponsive from 'src/hooks/useResponsive';
import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  navConfig: {
    subheader: string;
    items: NavListProps[];
  }[];
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar, navConfig }: Props) {
  const { pathname } = useLocation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}>
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Link to={'/'}>
            <Logo />
          </Link>
          {isCollapse ? (
            ''
          ) : (
            <Typography
              style={{
                marginTop: '2px',
                marginLeft: '1px',
                fontSize: '13px',
                fontWeight: 'bolder',
                fontFamily: 'monospace',
                ...(!isDesktop && { marginRight: '30px' }),
              }}>
              <Link
                to={'/'}
                style={{ textDecoration: 'none', color: isLight ? palette.light.text.primary : palette.dark.text.primary, fontSize: 24 }}>
                BONSAI
              </Link>
            </Typography>
          )}

          {isDesktop && !isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
        </Stack>
      </Stack>
      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}>
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              position: 'relative',
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              height: '115vh',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
            },
          }}>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
