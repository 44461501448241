import { StatusType } from 'src/api/api-constants';

/**
 * 受け取ったstatusがエラーかを判定する
 * @param status StatusType
 * @returns エラーの場合はtrue、エラーでない場合はfalseを返す
 */
export const checkIfAPIResponseHasError = (status: StatusType): boolean => {
  return typeof status === 'object' && status.hasOwnProperty('name') && status.name === 'Error' && status.hasOwnProperty('message');
};
