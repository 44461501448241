import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useEnqueteAnswer from 'src/features/general/enquete-answer/hooks/useEnqueteAnswer';
import { ConfirmRequestPostEntity, ConfirmedRequestDto } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../hooks/useEnqueteAnswerGrid';

interface Props {
  onClose: (isOpen: boolean) => {
    payload: boolean;
    type: string;
  };
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useConfirmationCommentsModal = ({ onClose, setIsLoading }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { sendConfirmRequestConfirmedMail } = useEnqueteAnswerGrid();
  const { fetchAnswer, fetchConfirmRequest, fetchConfirmHistories } = useEnqueteAnswer();

  const respondentTargetId = useParams().id;

  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);

  const updateConfirmationComments = async (confirmationComment: string) => {
    if (confirmationComment === null || confirmationComment.length === 0) {
      enqueueSnackbar(t('answerConfirmStatus.selectConfirmerModal.message.noCommentEntered'), { variant: 'error' });
      return;
    }
    try {
      setIsLoading(true);
      const newConfirmRequestData: ConfirmRequestPostEntity = {
        id: confirmRequestData.id,
        status: 'CONFIRMED',
        confirmedDate: confirmRequestData.confirmedDate ?? DateTime.fromJSDate(new Date(Date.now())).toUTC().toString(),
        confirmComment: confirmationComment,
      };
      const newSendConfirmRequestConfirmedMail: ConfirmedRequestDto = {
        confirmRequestId: confirmRequestData.id,
        message: confirmationComment,
      };

      await sendConfirmRequestConfirmedMail(newSendConfirmRequestConfirmedMail);
      await Promise.all([
        fetchAnswer(respondentTargetId ?? ''),
        fetchConfirmRequest(newConfirmRequestData.id),
        fetchConfirmHistories(confirmRequestData.answerId),
      ]);
      onClose(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateConfirmationComments,
  };
};

export default useConfirmationCommentsModal;
