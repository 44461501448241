import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Stack, SxProps } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

interface Props {
  // 子モーダル自体のタイトル
  title: string;
  // 子モーダルの表示/非表示フラグ
  open: boolean;
  // OKボタンが押された時のコールバック
  onOk: () => void;
  // 子モーダルの枠外が押された時のコールバック
  // または、Closeボタンがある場合はCloseボタンが押された時のコールバック
  onClose?: () => void;

  edited?: boolean;

  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

  handleOpen: () => void;
}

export const ConfirmRemoveCommentsModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 900,
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" onClick={props.edited ? props.handleOpen : props.onOk} color="error">
        {t('button.cancel')}
      </Button>
      <Modal hideBackdrop open={props.open} onClose={handleClose}>
        <Box component={'div'} sx={modalStyle}>
          <h2>{props.title}</h2>
          <Stack direction={'row-reverse'} spacing={2} paddingTop={2}>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                props.onOk();
              }}>
              {t('button.ok')}
            </Button>
            <Button variant="outlined" onClick={handleClose} color="error">
              {t('button.cancel')}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
