import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import { setDivisionAggregateSupplements, setTopic } from 'src/features/general/enquete-status/store/overviewAggregateSupplementSlice';
import { dispatch } from 'src/redux/store';

export const useOverviewAggregateSupplementTab = () => {
  const { getOverviewAggregateSupplement } = useEnqueteStatusPageApi();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const fetch = async (respondentRequestId: string) => {
    try {
      setIsLoading(true);
      const result = await getOverviewAggregateSupplement(respondentRequestId);
      if (result) {
        if ('divisionAggregateSupplements' in result) {
          dispatch(setDivisionAggregateSupplements(result.divisionAggregateSupplements));
        }
        if ('topic' in result) {
          dispatch(setTopic(result.topic));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    id && fetch(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isLoading };
};
