import { Box } from '@mui/material';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { replaceURLWithHTMLLinks } from 'src/utils/replaceURLWithHTMLLinks';

type EnqueteAnswerDescriptionBoxProps = {
  questionDescription: string;
};

export const EnqueteAnswerDescriptionBox: React.FC<EnqueteAnswerDescriptionBoxProps> = ({ questionDescription: QuestionDescription }) => {
  return (
    <StyledBox>
      <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{replaceURLWithHTMLLinks(QuestionDescription)}</Box>
    </StyledBox>
  );
};
