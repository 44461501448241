import { useConfirmRequestApi } from 'src/api/useConfirmRequestApi';
import { UserEntity, useUserApi } from 'src/api/useUserApi';
import { useAppDispatch } from 'src/redux/store';
import { ConfirmRequestStatus, enqueteConfirmSlice } from '../../../../store/enqueteConfirmSlice';

export const useEnqueteConfirmListGrid = () => {
  const dispatch = useAppDispatch();
  const confirmRequestApi = useConfirmRequestApi();
  const userApi = useUserApi();

  /**
   * アンケート確認一覧画面のグリッドの初期化処理
   */
  const changeStatus = async (status: ConfirmRequestStatus) => {
    dispatch(enqueteConfirmSlice.actions.handleConfirmRequestStatus(status));
  };

  /**
   * APIからデータ取得
   */
  const countLimitedLoadList = async (status: ConfirmRequestStatus, filterModel: { [key: string]: any }, currentLang: string) => {
    const data = await confirmRequestApi.getCountLimitedList(status, filterModel, currentLang);
    if (data === undefined) return;

    return data;
  };

  /**
   * APIからデータ取得
   */
  const limitedLoadList = async (
    status: ConfirmRequestStatus,
    blockSize: number,
    startRow: number,
    sortModel: any,
    filterModel: { [key: string]: any },
    currentLang: string,
  ) => {
    const data = await confirmRequestApi.getLimitedList(status, blockSize, startRow, sortModel, filterModel, currentLang);
    if (data === undefined) return;

    // ストアの初期化
    dispatch(enqueteConfirmSlice.actions.initEnqueteConfirmListData(data || []));
    dispatch(enqueteConfirmSlice.actions.handleConfirmRequestStatus(status));
  };

  const getUserInfo = async (
    email: string,
    callbacks: {
      onSuccess: (data: UserEntity) => void;
    },
  ) => {
    try {
      const data = await userApi.getByEmail([email]);
      return callbacks.onSuccess(data![0]);
    } catch {
      return;
    }
  };

  return { changeStatus, countLimitedLoadList, limitedLoadList, getUserInfo };
};
