import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Question, QuestionState } from 'src/@types/generalEnquete';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { clearQuestionItemOrValidation, setIsQuestionOpen } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { setIsOpenEditor, setMode } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { QUESTION_SAVE_MODE } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import useEnqueteAnswerFormTitle from '../hooks/useEnqueteAnswerFormTitle';

type EnqueteAnswerFormProps = {
  question: Question;
  index: number;
  children: React.ReactNode;
  isDisabledAddQuestion?: boolean;
  languageCode?: string;
  questionStates?: QuestionState[];
};

const EnqueteAnswerForm = ({ question, index, children, isDisabledAddQuestion, languageCode, questionStates }: EnqueteAnswerFormProps) => {
  const { selectQuestionId } = useSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { validateDescription } = useEnqueteAnswerFormTitle({ question, languageCode });

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { innerText } = event.currentTarget;
    switch (innerText) {
      case t('enqueteAnswerInput.editButton.edit'):
        dispatch(setMode(QUESTION_SAVE_MODE.EDIT));
        dispatch(setIsQuestionOpen(false));
        dispatch(clearQuestionItemOrValidation());
        dispatch(setIsOpenEditor(true));
        break;
      case t('enqueteAnswerInput.editButton.delete'):
        dispatch(setMode(QUESTION_SAVE_MODE.DELETE));
        break;
      case t('enqueteAnswerInput.editButton.copy'):
        dispatch(setMode(QUESTION_SAVE_MODE.COPY));
        break;
      default:
        break;
    }
  };

  return (
    <StyledBox state={questionStates?.find((questionState) => questionState.questionId === question.id)?.state}>
      <Grid item container sx={{ pt: 1, pb: 2, alignItems: 'center' }}>
        <Grid container sx={{ fontSize: '1.4rem', fontWeight: 'bold', whiteSpace: 'break-spaces', minHeight: '2.4rem' }}>
          <Grid item>
            <Typography component="span" sx={{ color: 'gray', fontSize: '1rem', mr: 2 }}>
              {t('enqueteCreate.questionNo', { lng: languageCode })}
              {index + 1}
              {/* Memo:非表示にした際に質問番号を詰める場合は上記{index + 1}の代わりに以下を利用する */}
              {/* {questionStates !== undefined
              ? questionStates
                  ?.slice(0, index)
                  .reduce((acc, questionState) => acc + (questionState.state === 'visible' || questionState.state === 'disabled' ? 1 : 0), 1)
              : index + 1} */}
            </Typography>
          </Grid>
          <Grid item>
            {question.title}
            <Typography variant="subtitle1" component="span" sx={{ color: 'gray' }}>
              {validateDescription}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ flexGrow: 1, verticalAlign: 'bottom' }}>
          <Box
            sx={{
              display: selectQuestionId === question.id ? 'flex' : 'none',
              justifyContent: 'flex-end',
            }}>
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleOnClick}>
              {t('enqueteAnswerInput.editButton.edit')}
            </Button>
            <Button variant="outlined" sx={{ ml: 2 }} color="error" onClick={handleOnClick}>
              {t('enqueteAnswerInput.editButton.delete')}
            </Button>
            <Button variant="outlined" sx={{ ml: 2 }} color="warning" onClick={handleOnClick} disabled={isDisabledAddQuestion ?? false}>
              {t('enqueteAnswerInput.editButton.copy')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </StyledBox>
  );
};
export default EnqueteAnswerForm;
