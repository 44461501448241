import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetInputItemModal } from 'src/features/general/enquete-create/components/modals/set-input-item/components/SetInputItemModal';
import useLocales from 'src/hooks/useLocales';
import { useSetFixedItem } from '../hooks/useSetFixedItem';
import { QuestionType } from './OverviewInputEditBox';

type Props = {
  questionType: QuestionType;
};

export interface QuestionPlaceInfo {
  questionType: QuestionType;
  questionIndex: number;
}

const OverviewQuestions: React.FC<Props> = (props) => {
  const { questionType } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const {
    questionTypeTitle,
    fixedInfo,
    additionalQuestions,
    setFixedItemOnChange,
    deleteFixedItemOnClick,
    isAdditionalQuestionDisabled,
    isDisabledCheckBox,
  } = useSetFixedItem({
    questionType,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionPlaceInfo>();

  return (
    <>
      <Grid
        item
        container
        key={questionType}
        direction="row"
        alignItems="center"
        sx={{ pb: 2, borderBottom: 1, borderColor: 'rgba(169, 169, 169, .5)' }}
        spacing={1}>
        <Grid item key={questionType + '-title'} xs={3}>
          {t('enqueteCreate.overviewSurvey.inputItemFor', { target: t(questionTypeTitle) })}
        </Grid>
        <Grid item key={questionType + '-item'} container direction="row" xs={9} sx={{ width: 'auto' }}>
          {/** デフォルト項目 **/}
          {fixedInfo?.map((item, n) => (
            <Grid item key={questionType + '-fixedQuestions-' + n}>
              <FormControlLabel
                control={<Checkbox disabled={isDisabledCheckBox(item.itemName, item.isAnswerTarget)} />}
                checked={item.isAnswerTarget}
                onChange={() => {
                  setFixedItemOnChange(item.itemName, !item.isAnswerTarget);
                }}
                label={t(item.label)}
                sx={{ width: 'auto' }}
              />
            </Grid>
          ))}
          {/** 追加項目 **/}
          {additionalQuestions?.map((item, n) => (
            <Grid item container sx={{ width: 'auto' }} key={questionType + '-additionalQuestions-' + n}>
              <FormControlLabel
                control={<Checkbox checked disabled={isAdditionalQuestionDisabled(item.titleJpn, item.titleEng)} />}
                label={isJapanese ? item.titleJpn : item.titleEng}
                sx={{ width: 'auto' }}
              />
              {/** 編集アイコン **/}
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedQuestion({ questionType, questionIndex: n });
                  setIsEdit(true);
                  setIsOpen(true);
                }}
                sx={{ marginLeft: -2 }}
                disabled={isAdditionalQuestionDisabled(item.titleJpn, item.titleEng)}>
                <EditIcon fontSize="small" />
              </IconButton>
              {/** 削除アイコン **/}
              <IconButton
                color="error"
                onClick={() => {
                  deleteFixedItemOnClick(item.titleJpn);
                }}
                sx={{ marginRight: 2 }}
                disabled={isAdditionalQuestionDisabled(item.titleJpn, item.titleEng)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/** 入力項目設定モーダル **/}
      <SetInputItemModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        questionType={questionType}
        questionPlaceInfo={selectedQuestion}
      />
    </>
  );
};

export default OverviewQuestions;
