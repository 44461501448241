import AuthGuardLayout from '../../../components/layouts/AuthGuardLayout';
import { enqueteCreateHistoryRoute } from '../enquete-create-history/routes';
import { enqueteRequestHistoryRoute } from '../enquete-request-history/routes';

//authGuardをパスしたとき、apiRouteで指定したページを表示する
export const historyRoute = {
  path: 'history',
  element: <AuthGuardLayout />,
  children: [enqueteCreateHistoryRoute, enqueteRequestHistoryRoute],
};
