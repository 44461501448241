import { NotificationCreatePage } from '../pages/NotificationCreatePage';
import { NotificationEditListPage } from '../pages/NotificationEditListPage';
import { NotificationEditPage } from '../pages/NotificationEditPage';
import { NotificationReRegisterPage } from '../pages/NotificationReRegisterPage';

//コンテンツ一覧画面の描画
export const notificationRoute = {
  path: 'notification',
  children: [
    {
      path: 'edit',
      element: <NotificationEditListPage />,
    },
    {
      path: ':id/edit',
      element: <NotificationEditPage />,
    },
    {
      path: 'create',
      element: <NotificationCreatePage />,
    },
    {
      path: ':id/reRegister',
      element: <NotificationReRegisterPage />,
    },
  ],
};

export default notificationRoute;
