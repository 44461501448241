import { createSlice } from '@reduxjs/toolkit';
import { API_CALL_STATUS } from 'src/api/api-constants';
import { EnqueteCommentEntitiy, EnqueteComments } from 'src/api/getCommentsApi';
import { fetchComments } from './enqueteCommentsActions';

export const enqueteCommentslice = createSlice({
  name: 'enquete-comments',
  initialState: {
    comment: {} as EnqueteComments,
    status: '' as EnqueteCommentEntitiy['status'],
  },
  reducers: {
    clearComments: (state) => {
      state.comment = {} as EnqueteComments;
      state.status = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      if (action.payload) {
        state.comment = action.payload;
      }
      state.status = API_CALL_STATUS.FULFILLED;
    });
    builder.addCase(fetchComments.pending, (state, action) => {
      state.status = API_CALL_STATUS.PENDING;
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      state.status = action.error;
    });
  },
});

export const { clearComments } = enqueteCommentslice.actions;
export default enqueteCommentslice.reducer;
