/**
 * コードが存在する場合は、名称(コード)、
 * コードが存在しない場合は、名称　を返却する。
 * @param label 名称
 * @param code コード
 * @returns
 */
export const addSuffix = (label: string, code: string | null) => {
  if (code === null || code === '') {
    return label;
  }
  return `${label}(${code})`;
};

/**
 * 日本語と英語の出力を切り替える。原則、画面で指定された言語表示とするが、
 * データが存在しない（空、もしくはnull）の場合は、もう一方の言語を表示させる。
 * @param jpn 日本語
 * @param eng 英語
 * @param isJapanese 日本語/英語判定
 * @returns
 */
export const switchLabel = (jpn: string | null, eng: string | null, isJapanese: boolean) => {
  if (isJapanese) {
    return jpn === '' || jpn === null ? eng ?? '' : jpn;
  } else {
    return eng === '' || eng === null ? jpn ?? '' : eng;
  }
};
