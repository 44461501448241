import { Box } from '@material-ui/system';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { ColDef, ColGroupDef, GetContextMenuItems, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import useEnqueteAnswer from 'src/features/general/enquete-answer/hooks/useEnqueteAnswer';
import { ConfirmHistoryEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { formatterDateTime } from 'src/utils/formatDateTime';
import ConfirmationContinueDialog from '../../../../modal/confirm-continue/components/ConfirmationContinueDialog';
import { ConfirmerDeletingModal } from '../../../../modal/confirmer-delete/components/ConfirmerDeletingModal';
import { SelectConfirmerModalLayout } from '../../../../modal/select-confirmer/SelectConfirmerModalLayout';

interface Props {
  surveyDetailId: string | undefined;
}

export const ConfirmInfoDataGrid: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const confirmHistoryData = useAppSelector((state) => state.enqueteAnswer.confirmHistoryData);
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  const { isAnswerReader, isSurveyOwner, isAdmin, isConfirmor, isRespondentPerson } = usePermission(props.surveyDetailId, respondentTargetData.id);
  const isOnlyAnswerReader = isAnswerReader && !isSurveyOwner && !isAdmin && !isConfirmor && !isRespondentPerson;

  const { fetchConfirmHistories } = useEnqueteAnswer();

  // state
  const [openSelectConfirmerModal, setOpenSelectConfirmerModal] = useState<boolean>(false);
  const [openDeleteConfirmerModal, setOpenDeleteConfirmerModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetRowData, setTargetRowData] = useState<ConfirmHistoryEntity>();

  // lang
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  // data tabel theme
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  // default coldef
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
  };

  //-------------------------------------------------------------
  // Show Translate User Name
  //-------------------------------------------------------------
  const existTranslateParams = (params: ValueGetterParams | ITooltipParams, jpnFieldName: string, engFieldName: string) => {
    const jpnModeData: string = params.data[jpnFieldName] ? params.data[jpnFieldName] : params.data[engFieldName];
    const engModeData: string = params.data[engFieldName] ? params.data[engFieldName] : params.data[jpnFieldName];

    return isLangJpn ? jpnModeData : engModeData;
  };

  //-------------------------------------------------------------
  // Column define
  //-------------------------------------------------------------
  const baseColumnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: t('answerConfirmStatus.confirmInfoDataTable.gridColDef.confirmRequester'),
      field: isLangJpn ? 'requesterName' : 'requesterNameEng',
      valueGetter: (params) => existTranslateParams(params, 'requesterName', 'requesterNameEng'),
      tooltipValueGetter: (params) => existTranslateParams(params, 'requesterName', 'requesterNameEng'),
      minWidth: 120,
    },
    {
      headerName: t('answerConfirmStatus.confirmInfoDataTable.gridColDef.requestedTime'),
      field: 'requestedDate',
      valueFormatter: (param) => {
        return formatterDateTime(param.data.requestedDate) as string;
      },
      tooltipValueGetter: (param) => {
        return formatterDateTime(param.data.requestedDate) as string;
      },
      minWidth: 120,
    },
    {
      headerName: t('answerConfirmStatus.confirmInfoDataTable.gridColDef.requestComment'),
      field: 'requestComment',
      tooltipField: 'requestComment',
      minWidth: 120,
    },
    {
      headerName: t('answerConfirmStatus.confirmInfoDataTable.gridColDef.confirmer'),
      field: isLangJpn ? 'confirmerName' : 'confirmerNameEng',
      valueGetter: (params) => existTranslateParams(params, 'confirmerName', 'confirmerNameEng'),
      tooltipValueGetter: (params) => existTranslateParams(params, 'confirmerName', 'confirmerNameEng'),
      minWidth: 120,
    },
    {
      headerName: t('answerConfirmStatus.confirmInfoDataTable.gridColDef.confirmedTime'),
      field: 'confirmedDate',
      valueFormatter: (param) => {
        if (param.data.confirmedDate) {
          return formatterDateTime(param.data.confirmedDate) as string;
        }
        return '';
      },
      tooltipValueGetter: (param) => {
        if (param.data.confirmedDate) {
          return formatterDateTime(param.data.confirmedDate) as string;
        }
        return '';
      },
      minWidth: 120,
    },
    {
      headerName: t('answerConfirmStatus.confirmInfoDataTable.gridColDef.confirmComment'),
      field: 'confirmComment',
      tooltipField: 'confirmComment',
      minWidth: 120,
    },
  ];

  // モーダル外で使用するメニュー付きのColDefを用意する。
  // 代入とpushを同時にやると戻り値がnumberになるので間を挟む
  let tempColumDefs: (ColDef | ColGroupDef)[] = _.cloneDeep(baseColumnDefs);
  tempColumDefs.push({
    field: '-',
    headerName: '',
    cellRenderer: ThreeDotMenuRenderer,
    editable: false,
    minWidth: 80,
    width: 80,
    filter: false,
    pinned: 'right',
  });
  const columnDefs: (ColDef | ColGroupDef)[] = tempColumDefs;

  //-------------------------------------------------------------
  // Context Menu
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('answerConfirmStatus.confirmInfoDataTable.contextMenu.delete'),
        action: () => {
          setTargetRowData(params.node?.data);
          setOpenDeleteConfirmerModal(true);
        },
        disabled: isOnlyAnswerReader,
      },
    ];

    return menuItems;
  };

  const refreshRowData = async () => {
    if (enqueteAnswerData.id) {
      setIsLoading(true);
      await fetchConfirmHistories(enqueteAnswerData.id).finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Stack>
        {/* header block */}
        <Grid container alignItems={'center'}>
          <Grid item xs={6}>
            <Stack direction={'row'}>
              <Typography>{t('answerConfirmStatus.confirmInfoDataTable.gridTitle')}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} spacing={1} justifyContent={'right'}>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => setOpenSelectConfirmerModal(true)}
                children={t('answerConfirmStatus.button.addConfirmer')}
                disabled={(enqueteAnswerData.id ? false : true) || isOnlyAnswerReader}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* data table */}
        <Grid container alignItems={'center'} sx={{ pt: 1 }}>
          <Grid item xs={12}>
            <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: 300, width: '100%' }}>
              <LoadableComponent isLoading={isLoading}>
                <StyledAgGrid
                  defaultColDef={defaultColDef}
                  coldef={columnDefs}
                  rows={confirmHistoryData}
                  suppressAutoSize={true}
                  tooltipShowDelay={0}
                  contextMenu={contextMenu}
                />
              </LoadableComponent>
            </Box>
          </Grid>
        </Grid>
      </Stack>

      <ConfirmationContinueDialog
        title={''}
        message={
          t('answerConfirmStatus.confirmInfoDataTable.continueDialog.message1') +
          '\n' +
          t('answerConfirmStatus.confirmInfoDataTable.continueDialog.message2')
        }
        discardLabel={t('button.no')}
        continueLabel={t('button.yes')}
        open={openSelectConfirmerModal}
        onDiscard={() => setOpenSelectConfirmerModal(false)}
      />

      {/* confirmer selecting modal */}
      <SelectConfirmerModalLayout
        surveyDetailId={props.surveyDetailId}
        answerId={enqueteAnswerData.id}
        open={openSelectConfirmerModal}
        onClose={() => setOpenSelectConfirmerModal(false)}
        onComplete={() => refreshRowData()}
      />

      {/* confirmer deleting modal */}
      <ConfirmerDeletingModal
        openDeleteConfirmerModal={openDeleteConfirmerModal}
        setOpenDeleteConfirmerModal={setOpenDeleteConfirmerModal}
        baseColumnDefs={baseColumnDefs}
        targetRowData={targetRowData}
        refreshRowData={refreshRowData}
      />
    </>
  );
};
