import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway, portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface CountryEntity {
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
  countryIsoCode: string;
}

interface PortalCountryEntity {
  country_cd: string;
  country_name: string;
  country_eng_name: string;
  country_iso_cd: string;
}

const PORTAL_URL = `${portalApi}/master/country`;
const BONSAI_URL = `${apiGateway}/country`;

export const useCountryApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const get = async (filter?: {}) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalCountryEntity[]>(PORTAL_URL, {
        headers: { Authorization: token },
        params: { filter },
      });

      return result.data.map<CountryEntity>((master) => ({
        countryCode: master.country_cd,
        countryNameJpn: master.country_name,
        countryNameEng: master.country_eng_name,
        countryIsoCode: master.country_iso_cd,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.country') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * この関数は MARSポータルに存在する国マスタの内、数字の国コードが設定済みの国情報のみ取得する
   * 数字の国コードを利用しているのは、MARSポータル上のユーザ及び組織であり、
   * BONSAIでユーザや組織を検索する場合は、数字の国コードを利用する
   * @returns
   */
  const getOnlySetNumberCountryCode = async () => {
    try {
      const response = await get({ where: { NOT: { country_cd: '' } }, orderBy: [{ country_cd: 'asc' }] });
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * この関数は MARSポータルに存在する国マスタの内、英字の国コード,国/地域の名称（英語含む）が設定済みの国情報のみ取得する
   * @returns
   */
  const getOnlySetIsoCountryCode = async () => {
    try {
      const response = await get({
        where: {
          NOT: [{ country_iso_cd: '' }, { country_name: '' }, { country_eng_name: '' }],
        },
        orderBy: [{ country_iso_cd: 'asc' }],
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getCountriesByRegionCode = async (regionCode?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(BONSAI_URL, {
        headers: { Authorization: token },
        timeout: 60000,
        params: {
          regionCode,
        },
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.country') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { get, getOnlySetNumberCountryCode, getCountriesByRegionCode, getOnlySetIsoCountryCode };
};
