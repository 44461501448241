import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack5';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useBearerToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getBearerToken = useCallback(async (): Promise<string | undefined> => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      return `Bearer ${token}`;
    } catch (error) {
      enqueueSnackbar(t('account.failedGetToken'), {
        variant: 'warning',
        preventDuplicate: true,
      });
      throw error;
    }
  }, [enqueueSnackbar, t]);

  return { getBearerToken };
};
