import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question, QuestionState } from 'src/@types/generalEnquete';
import EnqueteAnswerGeneralQuestionFormSwitcher from 'src/features/general/enquete-answer/components/tab-containers/type-general-question/components/tabs/question-and-answer/components/EnqueteAnswerGeneralQuestionFormSwitcher';
import { EnqueteAnswerDescriptionBox } from 'src/features/general/enquete-answer/components/tab-containers/type-general-question/components/tabs/question-and-answer/components/common/EnqueteAnswerDescriptionBox';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { useModalHandlers } from '../../../../hooks/useModalHandlers';

const EnquetePreviewForm: React.FC = () => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const { onModalClosePreview } = useModalHandlers();
  const methods = useForm({ mode: 'onChange' });
  const questions: Question[] = useAppSelector((stateGeneral: RootState) => stateGeneral.enqueteGeneralAnswer.questions);
  const questionStates: QuestionState[] = useAppSelector((stateGeneral) => stateGeneral.enqueteGeneralAnswer.questionStates);
  const questionDescriptionJpn = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);
  let questionDescription: string | undefined;
  if (currentLang.value === 'ja') {
    questionDescription = questionDescriptionJpn;
  } else if (currentLang.value === 'en') {
    questionDescription = questionDescriptionEng;
  }

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} spacing={1} mb={2} mt={2}>
      <Box component={'div'} sx={{ overflowY: 'auto', overflowX: 'hidden', height: '100%', width: '100%' }}>
        <Grid item xs={12} md={12} pl={1}>
          {questionDescription && questionDescription !== '' ? <EnqueteAnswerDescriptionBox questionDescription={questionDescription} /> : null}
        </Grid>
        <Grid item container justifyContent="top" alignItems="top" xs={12} spacing={1} mb={2} mt={2} pl={2}>
          {questions &&
            questions.length > 0 &&
            questions.map((question, index: number) => {
              return (
                <EnqueteAnswerGeneralQuestionFormSwitcher
                  methods={methods}
                  question={question}
                  index={index}
                  key={question.id}
                  questionStates={questionStates}
                />
              );
            })}
        </Grid>
        <Grid item xs={12} sx={{ width: '100%', textAlign: 'right' }}>
          <Button key="close" variant={'contained'} onClick={onModalClosePreview} children={t('button.close')} color="error" />
        </Grid>
      </Box>
    </Grid>
  );
};

export default EnquetePreviewForm;
