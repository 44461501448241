import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-enterprise';
import { useTranslation } from 'react-i18next';
import useLocales from 'src/hooks/useLocales';
import { currencyFormat } from 'src/utils/currencyFormat';
import { switchLabel } from 'src/utils/labelUtils';

export const useOwnerTabContainerColdef = () => {
  const { isJapanese } = useLocales();
  const { t } = useTranslation();

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
  };

  const defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  };

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      flex: 0.3,
      sortable: false,
      lockPosition: 'left',
    },
    {
      field: isJapanese ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.division'),
      sort: 'asc',
      flex: 1.5,
      valueGetter: (params) => switchLabel(params.data.divisionNameJpn, params.data.divisionNameEng, isJapanese),
    },
    {
      field: 'sumPurchaseFixedAmount',
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.purchase'),
      valueFormatter: (params) => (params.value ? currencyFormat(params.value) : ''),
      cellStyle: { textAlign: 'right' },
      flex: 1.5,
    },
    {
      field: 'sumSalesFixedAmount',
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.sales'),
      valueFormatter: (params) => (params.value ? currencyFormat(params.value) : ''),
      cellStyle: { textAlign: 'right' },
      flex: 1.5,
    },
    {
      field: 'topic',
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.topic'),
      cellStyle: { whiteSpace: 'pre-wrap' },
      flex: 3,
    },
    {
      field: 'aggregateStatus',
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.done'),
      flex: 0.6,
      valueFormatter: (params) => {
        return params.value === 'COMPLETED' ? '○' : '';
      },
    },
  ];

  return {
    defaultColDef,
    colDef,
    gridOptions,
  };
};
