import { ColDef, ColGroupDef, ITooltipParams, ValueGetterFunc, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { UserEntity } from 'src/api/useUserApi';
import { SelectRespondentModalResultRowData } from 'src/features/general/enquete-create/components/modals/select-respondent/components/SelectRespondentModal';
import useLocales from 'src/hooks/useLocales';
import { invisibleConditionOrganization, showUserName } from 'src/utils/gridValueUtils';
import { joinEmails } from 'src/utils/joinEmails';

interface HandlesInvisibleConditionOrganizationTypes {
  valueGetter: string | ValueGetterFunc<any> | undefined;
  tooltipValueGetter: ((params: ITooltipParams<any, any>) => any) | undefined;
}

export interface UserAgGridColDefSetting {
  key: string;
  minWidth?: number;
  columnGroupShowValue?: 'open' | 'closed' | 'not';
}
export interface AgGridSetting {
  suppressMenu?: boolean;
  sortable?: boolean;
  filter?: boolean;
  floatingFilter?: boolean;
}

interface Props {
  companyCodeColName: string;
  emailColDefSetting: UserAgGridColDefSetting;
  fullNameColDefSetting: UserAgGridColDefSetting;
  companyNameColDefSetting: UserAgGridColDefSetting;
  companyAbbreviationColDefSetting: UserAgGridColDefSetting;
  assignedCompanyColDefSetting: UserAgGridColDefSetting;
  divisionNameColDefSetting: UserAgGridColDefSetting;
  sbuNameColDefSetting: UserAgGridColDefSetting;
  departmentNameColDefSetting: UserAgGridColDefSetting;
  groupNameColDefSetting: UserAgGridColDefSetting;
  agGridSetting?: AgGridSetting;
  bluePageEmailAddressColName?: string;
}

export const useUserAgGridColDef = (props: Props) => {
  const { t } = useTranslation();
  const { isJapanese } = useLocales();

  // デフォルトColDef
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    editable: false,
    filter: true,
  };

  // デフォルトAgGridSetting
  const defaultAgGridSetting: AgGridSetting = {
    suppressMenu: false,
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  // デフォルトMinWidth
  const defaultMinWidth = {
    email: 300,
    fullName: 200,
    companyName: 200,
    companyAbbreviation: 150,
    assignedCompany: 200,
    divisionName: 200,
    sbuName: 200,
    departmentName: 200,
    groupName: 200,
  };

  // Grid定義 日英文言切り替え
  const makeColDefIncludeLangSwitch = (
    userAgGridColDefSetting: UserAgGridColDefSetting,
    headerNameKey: string,
    minWidth: number,
    isInvisible?: boolean,
  ): ColDef | ColGroupDef => {
    const columnGroup =
      userAgGridColDefSetting.columnGroupShowValue !== undefined || userAgGridColDefSetting.columnGroupShowValue !== 'not'
        ? { columnGroupShow: userAgGridColDefSetting.columnGroupShowValue }
        : {};

    const handleInvisibleConditionOrganization: HandlesInvisibleConditionOrganizationTypes = isInvisible
      ? {
          valueGetter: (params) =>
            invisibleConditionOrganization(
              params,
              props.companyCodeColName,
              `${userAgGridColDefSetting.key}Jpn`,
              `${userAgGridColDefSetting.key}Eng`,
              isJapanese,
            ),
          tooltipValueGetter: (params) =>
            invisibleConditionOrganization(
              params,
              props.companyCodeColName,
              `${userAgGridColDefSetting.key}Jpn`,
              `${userAgGridColDefSetting.key}Eng`,
              isJapanese,
            ),
        }
      : {
          valueGetter: (params) => viewVisibleName(params, userAgGridColDefSetting.key, isJapanese),
          tooltipValueGetter: (params) => viewVisibleName(params, userAgGridColDefSetting.key, isJapanese),
        };
    return {
      ...{
        field: isJapanese ? `${userAgGridColDefSetting.key}Jpn` : `${userAgGridColDefSetting.key}Eng`,
        headerName: t(`common.${headerNameKey}`),
        minWidth: userAgGridColDefSetting.minWidth ?? minWidth,
        suppressMenu: props.agGridSetting?.suppressMenu ?? defaultAgGridSetting.suppressMenu,
        sortable: props.agGridSetting?.sortable ?? defaultAgGridSetting.sortable,
        tooltipValueGetter: (params) => params.value,
        filter: props.agGridSetting?.filter ?? defaultAgGridSetting.filter,
        filterParams:
          props.agGridSetting?.filter === false
            ? undefined
            : {
                applyMiniFilterWhileTyping: true,
              },
        floatingFilter: props.agGridSetting?.floatingFilter ?? defaultAgGridSetting.floatingFilter,
      },
      ...columnGroup,
      ...handleInvisibleConditionOrganization,
    };
  };

  const viewVisibleName = (params: ValueGetterParams | ITooltipParams, key: string, isJapanese: boolean) => {
    // 言語指定でkeyが変わるパターンをこちらで拾う
    return isJapanese ? params.data[`${key}Jpn`] || params.data[`${key}Eng`] || '-' : params.data[`${key}Eng`] || params.data[`${key}Jpn`] || '-';
  };

  // Grid定義 オブジェクト生成
  const makeColDefFormatter = (userAgGridColDefSetting: UserAgGridColDefSetting, headerNameKey: string, minWidth: number): ColDef | ColGroupDef => {
    const columnGroup =
      userAgGridColDefSetting.columnGroupShowValue !== undefined || userAgGridColDefSetting.columnGroupShowValue !== 'not'
        ? { columnGroupShow: userAgGridColDefSetting.columnGroupShowValue }
        : {};

    return {
      ...{
        field: userAgGridColDefSetting.key,
        headerName: t(`common.${headerNameKey}`),
        minWidth: userAgGridColDefSetting.minWidth ?? minWidth,
        suppressMenu: props.agGridSetting?.suppressMenu ?? defaultAgGridSetting.suppressMenu,
        sortable: props.agGridSetting?.sortable ?? defaultAgGridSetting.sortable,
        tooltipValueGetter: (params: ITooltipParams) => params.value,
        filter: props.agGridSetting?.filter ?? defaultAgGridSetting.filter,
        filterParams:
          props.agGridSetting?.filter === false
            ? undefined
            : {
                applyMiniFilterWhileTyping: true,
              },
        floatingFilter: props.agGridSetting?.floatingFilter ?? defaultAgGridSetting.floatingFilter,
      },
      ...columnGroup,
    };
  };

  // UserIdメールアドレスとBluePageメールアドレスを結合し出力
  const makeEmailColDefFormatter = () => {
    const colDef: ColDef = makeColDefFormatter(props.emailColDefSetting, 'email', defaultMinWidth.email);
    const mailKey = props.emailColDefSetting.key;
    const bluePageEmailAddressKey = props.bluePageEmailAddressColName ?? 'bluePageEmailAddress';

    colDef.valueGetter = (params) => joinEmails(params.data[`${mailKey}`], params.data[`${bluePageEmailAddressKey}`]);
    return colDef;
  };

  // UserIdメールアドレスとBluePageメールアドレスを結合し出力
  const makeFullNameColDefFormatter = (userAgGridColDefSetting: UserAgGridColDefSetting) => {
    // userNameパターン
    if (userAgGridColDefSetting.key === 'userName') {
      const colDef: ColDef = makeColDefFormatter(userAgGridColDefSetting, 'fullName', defaultMinWidth.fullName);

      colDef.valueGetter = (params: ValueGetterParams<RespondentsListEntity>) => showUserName(params, 'userNameJpn', 'userNameEng', isJapanese);
      colDef.tooltipValueGetter = (params: ITooltipParams<RespondentsListEntity>) => showUserName(params, 'userNameJpn', 'userNameEng', isJapanese);
      return colDef;
    }
    // respondentPersonFullNameパターン
    else if (userAgGridColDefSetting.key === 'respondentPersonFullName') {
      const colDef: ColDef = makeColDefFormatter(userAgGridColDefSetting, 'fullName', defaultMinWidth.fullName);

      colDef.valueGetter = (params: ValueGetterParams<SelectRespondentModalResultRowData>) =>
        showUserName(params, 'respondentPersonFullNameJpn', 'respondentPersonFullNameEng', isJapanese);
      colDef.tooltipValueGetter = (params: ITooltipParams<SelectRespondentModalResultRowData>) =>
        showUserName(params, 'respondentPersonFullNameJpn', 'respondentPersonFullNameEng', isJapanese);
      return colDef;
    }
    // fullName パターン
    else {
      const colDef: ColDef = makeColDefFormatter(userAgGridColDefSetting, 'fullName', defaultMinWidth.fullName);

      colDef.valueGetter = (params: ValueGetterParams<UserEntity>) => showUserName(params, 'fullNameJpn', 'fullNameEng', isJapanese);
      colDef.tooltipValueGetter = (params: ITooltipParams<UserEntity>) => showUserName(params, 'fullNameJpn', 'fullNameEng', isJapanese);
      return colDef;
    }
  };

  const userAgGridColDef: (ColDef | ColGroupDef)[] = [
    makeEmailColDefFormatter(),
    makeFullNameColDefFormatter(props.fullNameColDefSetting),
    makeColDefIncludeLangSwitch(props.companyNameColDefSetting, 'companyName', defaultMinWidth.companyName),
    makeColDefFormatter(props.companyAbbreviationColDefSetting, 'companyAbbreviation', defaultMinWidth.companyAbbreviation),
    makeColDefIncludeLangSwitch(props.assignedCompanyColDefSetting, 'assignedCompany', defaultMinWidth.assignedCompany, true),
    makeColDefIncludeLangSwitch(props.divisionNameColDefSetting, 'divisionName', defaultMinWidth.divisionName, true),
    makeColDefIncludeLangSwitch(props.sbuNameColDefSetting, 'sbuName', defaultMinWidth.sbuName, true),
    makeColDefIncludeLangSwitch(props.departmentNameColDefSetting, 'departmentName', defaultMinWidth.departmentName, true),
    makeColDefIncludeLangSwitch(props.groupNameColDefSetting, 'groupName', defaultMinWidth.groupName, true),
  ];

  return { defaultColDef, userAgGridColDef };
};
