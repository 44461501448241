import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE, Question, QuestionItem } from 'src/@types/generalEnquete';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { setQuestionItem } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { QUESTIONITEM_SAVE_MODE } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store/index';
import { v1 } from 'uuid';
import RequiredValidationForm from './RequiredValidationForm';
import TypeValidationForm from './TypeValidationForm';

type QuestionTextFormProps = {
  methods: UseFormReturn;
  existingQuestion?: Question;
};

const QuestionTextForm = ({ methods, existingQuestion }: QuestionTextFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const questionItem = useSelector((state: RootState) => state.enqueteCreate.questionItem);
  const { rulesCreateQuestionTitle } = useValidator(methods);

  useEffect(() => {
    if (existingQuestion) {
      dispatch(
        setQuestionItem({
          mode: QUESTIONITEM_SAVE_MODE.REFILL,
          currentInputType: GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT,
          value: existingQuestion.questionItem,
        }),
      );
    } else {
      if (!questionItem.length) {
        dispatch(
          setQuestionItem({
            index: 0,
            mode: QUESTIONITEM_SAVE_MODE.ADD,
            currentInputType: GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT,
            value: { id: v1(), label: '' } as QuestionItem,
          }),
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <RHFTextField
            margin="normal"
            name="textQuestion"
            control={methods.control}
            fullWidth
            rows={2}
            multiline
            placeholder={t('enqueteCreate.inputQuestion')}
            rules={rulesCreateQuestionTitle}
            defaultValue={existingQuestion ? existingQuestion.title : ''}
          />
        </Grid>
      </Grid>

      <RequiredValidationForm methods={methods} existingQuestion={existingQuestion} />
      <TypeValidationForm methods={methods} existingQuestion={existingQuestion} />
    </>
  );
};

export default QuestionTextForm;
