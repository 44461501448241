import { Box, Grid } from '@mui/material';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import {
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  PersonnelSurveyGridFormData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../../../../hooks/useEnqueteAnswerGrid';
import {
  DarkRowStyleByPersonnelSurvey,
  NotInputDarkRowStyleByPersonnelSurvey,
  NotInputRowStyleByPersonnelSurvey,
  RowStyleByPersonnelSurvey,
} from '../../../../utils/RowStyleByPersonnelSurvey';
import { useEnqueteAnswerTranslation } from '../../../../hooks/useEnqueteAnswerTranslation';

export interface CreateTotalOnlyGridProps {
  form: PersonnelSurveyGridFormData;
  rowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  pastRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
}

export const CreateTotalOnlyGrid: React.FC<CreateTotalOnlyGridProps> = (props) => {
  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { setPersonnelEmploymentTotal } = useEnqueteAnswerGrid();
  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  // 言語情報
  const { currentLang } = useLocales();
  const useJudg = useJudgmentCurrentLanguage();
  const useAnswerTranslate = useEnqueteAnswerTranslation();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [colDef, setColDef] = useState<(ColDef | ColGroupDef)[]>([]);
  const [rowData, setRowData] = useState<EnqueteAnswerInputEntity[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDestroyed, setIsDestroyed] = useState(false);

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    getRowStyle: isEnqueteAnswerOpen
      ? isLight
        ? RowStyleByPersonnelSurvey
        : DarkRowStyleByPersonnelSurvey
      : isLight
      ? NotInputRowStyleByPersonnelSurvey
      : NotInputDarkRowStyleByPersonnelSurvey,
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    refreshColDefData();
  }, [isEnqueteAnswerOpen]); //eslint-disable-line

  useEffect(() => {
    refreshRowData();
  }, [props.form, props.rowData, props.pastRowData, currentLang]); //eslint-disable-line

  useEffect(() => {
    destroyGrid();
  }, [isLight, isEnqueteAnswerOpen]); //eslint-disable-line

  //-------------------------------------------------------------
  // テーマ変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    setIsDestroyed(true);
    setTimeout(() => recreateGrid(), 0);
  };

  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  //-------------------------------------------------------------
  // テーブルの更新
  //-------------------------------------------------------------
  const refreshColDefData = async () => {
    setIsLoading(true);

    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = useAnswerTranslate.formatColDef(props.form);

    await setColDef(enqueteAnswerColDef);
    await new Promise((resolve) => setTimeout(resolve, 100)); // 0.1秒待つ

    setIsLoading(false);
  };

  const refreshRowData = async () => {
    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = useAnswerTranslate.formatColDef(props.form);

    // ヘッダー名削除
    enqueteAnswerColDef.forEach((columnColDef: ColDef) => {
      if (columnColDef.field === 'inputItem') {
        columnColDef.headerName = '';
        columnColDef.headerTooltip = '';
      }
    });
    await setColDef(enqueteAnswerColDef);

    // FormJsonからグリッドの列項目を取得
    let baseData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.form.initialValue) {
      props.form.initialValue.forEach((row: any) => {
        baseData.push(row);
      });
    }

    // AnswerJsonから回答情報を取得する
    let answerRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.rowData) {
      props.rowData.forEach((row: any) => {
        answerRowData.push(row);
      });
    }

    // FromとAnswerを合体させてRowDataを作成する。
    let tempRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== baseData && undefined !== answerRowData) {
      baseData.forEach((row: any) => {
        const tempAnswerRowData = answerRowData.filter((x) => x.id === row.id);
        const pushRowData: EnqueteAnswerInputEntity = {
          id: row.id as string,
          inputItem: row.inputItem as string,
          resultForecast: tempAnswerRowData[0] && tempAnswerRowData[0].resultForecast !== undefined ? tempAnswerRowData[0].resultForecast : undefined,
          yearPlan: tempAnswerRowData[0] && tempAnswerRowData[0].yearPlan !== undefined ? tempAnswerRowData[0].yearPlan : undefined,
          midTermPlan: tempAnswerRowData[0] && tempAnswerRowData[0].midTermPlan !== undefined ? tempAnswerRowData[0].midTermPlan : undefined,
        };

        tempRowData.push(pushRowData);
      });
    }

    // 追加しているTotal行を除外する
    tempRowData = tempRowData.filter((x) => x.id !== 'sumTotal');

    // Totalに含めない要素を除外する。
    tempRowData = tempRowData.filter((x) => x.id !== 'notConsolidated');

    // 過去参照値の取得
    let tempPastRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.pastRowData) {
      props.pastRowData.forEach((row: any) => {
        tempPastRowData.push(row);
      });
    }

    // idと過去参照値の紐付きを作る。
    let pastYearPlans = new Map<string, number>();
    tempPastRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data.yearPlan) {
        pastYearPlans.set(data.id, data.yearPlan);
      }
    });

    // RowDataの中身をコピーして編集できるようにする。
    let newRowData = _.cloneDeep(tempRowData);
    newRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data) {
        const previousYearPlan = undefined !== pastYearPlans.get(data.id) ? pastYearPlans.get(data.id) : 0;
        data.previousYearPlan = previousYearPlan;
      }
    });

    let previousYearPlanTotal: number = 0;
    let resultForecastTotal: number = 0;
    let yearPlanTotal: number = 0;
    let middlePlanTotal: number = 0;

    newRowData.forEach((data: any) => {
      previousYearPlanTotal += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
      resultForecastTotal += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
      yearPlanTotal += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      middlePlanTotal += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
    });

    const totalRowData: EnqueteAnswerInputEntity[] = [
      {
        id: 'sumTotal',
        inputItem: useJudg.getText(props.form['referenceGridTotalName'] as languageDataTypes),
        previousYearPlan: previousYearPlanTotal,
        resultForecast: resultForecastTotal,
        yearPlan: yearPlanTotal,
        midTermPlan: middlePlanTotal,
      },
    ];

    //過去参照値データがなければ、undefinedに置換
    if (!props.pastRowData) {
      totalRowData.forEach((data: EnqueteAnswerInputEntity) => {
        if (data !== undefined && data.id !== 'sumTotal') data.previousYearPlan = undefined;
      });
    }

    await setPersonnelEmploymentTotal(totalRowData[0]);
    await setRowData(totalRowData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ pb: '6px' }} style={{ whiteSpace: 'pre-line' }}>
          <h4>{useJudg.getText(props.form['referenceHeadline'] as languageDataTypes)}</h4>
        </Box>
        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: '97px',
            width: '80%',
            transition: 'height 0.5s',
          }}>
          <LoadableComponent isLoading={isLoading}>
            {!isDestroyed && <StyledAgGrid coldef={colDef} rows={rowData} gridOptions={gridOptions} />}
          </LoadableComponent>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
};
