import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { SurveyIdsTypes, useCreatedSurveyApi } from 'src/api/useCreatedSurvey';
import { NetworkAccessError } from 'src/errors/errors';
import { useAppDispatch } from 'src/redux/store';
import { enqueteRequestSlice } from '../store/enqueteRequestSlice';

export const useEnqueteRequestListGrid = () => {
  const dispatch = useAppDispatch();
  const createdSurveyApi = useCreatedSurveyApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * APIからデータ取得
   */
  const getCountLimitedLoadList = async (filterModel: { [key: string]: any }, currentLang: string) => {
    const countCreatedSurveyList = await createdSurveyApi.getCountLimitedList(filterModel, currentLang);
    if (countCreatedSurveyList === undefined) return;

    return countCreatedSurveyList;
  };

  /**
   * APIからデータ取得
   */
  const getLimitedLoadList = async (
    blockSize: number,
    startRow: number,
    sortModel: any,
    filterModel: { [key: string]: any },
    currentLang: string,
  ) => {
    const createdSurveyList = await createdSurveyApi.getLimitedList(blockSize, startRow, sortModel, filterModel, currentLang);
    if (createdSurveyList === undefined) return;

    // ストアの初期化
    dispatch(enqueteRequestSlice.actions.initListData(createdSurveyList));
  };

  /**
   * 1行削除
   */
  const deleteRow = async (ids: SurveyIdsTypes) => {
    try {
      await createdSurveyApi.deleteData(ids.surveyDetailId);
    } catch (error) {
      if (error instanceof NetworkAccessError) {
        if (error.isForbidden()) {
          enqueueSnackbar(t('enqueteRequest.message.failedDeleteNotAdmin'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('apiExceptionMessage.useRespondentRequestApi.failedDeleteData'), { variant: 'error' });
        }
      }
      return;
    }

    dispatch(enqueteRequestSlice.actions.deleteRowData(ids.respondentRequestId));
  };

  return { getLimitedLoadList, getCountLimitedLoadList, deleteRow };
};
