import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface SurveyDetailPermissionEntity {
  userId: string;
  surveyDetailId: string;
  respondentRequestId: string;
  respondentTargetId: string;
  isOwner: boolean;
  isRespondentManager: boolean;
  isRespondentPerson: boolean;
  // TODO: 将来的には本部極担当者の判定もやりたい
}

export const usePermissionApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * アンケートに対する権限を持っているか確認
   *
   * @param surveyDetailId
   * @param respondentTargetId
   * @returns
   */
  const checkPermission = async (surveyDetailId: string, respondentTargetId?: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<SurveyDetailPermissionEntity>(`${apiGateway}/permission/${surveyDetailId}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        params: { respondentTargetId },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.db.surveyDetail') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    checkPermission,
  };
};
