/**
 * 円表記で使用する関数
 * @param num 円表記にする値
 * @param minimumFractionDigits 使用する小数部の最小桁数
 * @returns ￥表記文字列
 */
export function currencyFormat(num?: number, minimumFractionDigits?: number, maximumFractionDigits?: number): string {
  const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return num !== undefined ? formatter.format(num).replace(/^-￥/, '￥-') : '';
}
