import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'enqueteCreate',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});
