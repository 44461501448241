export const convertToYYYYMMDD = (time: string) => {
  const date = Intl.DateTimeFormat('ja-Jp', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',

  }).format(new Date(time));

  return date;
};
