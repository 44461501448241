import { Container, Grid } from '@material-ui/core';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';

export const EnqueteAnswererGroupListPage = () => {
  return (
    <Page title="アンケート依頼グループ管理">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <HeaderBreadcrumbs
              heading="アンケート依頼グループ管理"
              links={[
                // { name: '一般', href: '/general' },
                { name: 'アンケート依頼グループ管理', href: '/general/enquete-create' },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
