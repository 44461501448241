import { Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';

interface Props {
  isOpen: boolean;
  onClose: (entity?: RespondentRequestEntity) => void;
  respondentRequestId: string | undefined;
}

export const CancelConfirmModal: React.FC<Props> = ({ isOpen, onClose, respondentRequestId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { surveyCancel } = useSurveyEditApi();

  if (respondentRequestId === undefined) {
    return <></>;
  }

  const translationKey = 'enqueteCreate.enqueteCancelModal';
  const onClickHandler = async () => {
    let entity = undefined;
    try {
      entity = await surveyCancel(respondentRequestId);
      if (entity !== undefined) {
        // memo: entity が undefined になる場合、useRespondentRequestApi で SnackBar が表示されるため、ここでは表示しない
        enqueueSnackbar(t(`${translationKey}.message`), { variant: 'success' });
      }
    } catch {
      // memo: axios error 以外の場合を考慮して SnackBar を表示
      enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedCancel'), { variant: 'error' });
    } finally {
      onClose(entity);
    }
  };

  return (
    <StyledModal isOpen={isOpen} onCloseFunc={() => onClose()} modalTitle={t(`${translationKey}.title`)}>
      <Grid container direction={'column'}>
        <Grid item justifyContent="center" sx={{ pb: 2 }}>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{t(`${translationKey}.warning`)}</Typography>
        </Grid>
        <Grid item sx={{ pt: 2 }}>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <StyledButton
              onClick={() => {
                onClose();
              }}
              color="error">
              {t('button.cancel')}
            </StyledButton>
            <StyledButton onClick={onClickHandler}>{t('button.ok')}</StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </StyledModal>
  );
};
