export const useRank = () => {
  // memo: BONSAI 上の格付け表示は以下の処理で統一
  const complementRank = (companyCode: string, rank: string | null) => {
    if (companyCode === 'S500' && (rank === null || rank === '')) return 'TTC';
    if (companyCode !== 'S500' && (rank === null || rank === '')) return '-';
    if (companyCode !== 'S500' && (rank !== null || rank !== '')) return rank;
    return '';
  };

  return {
    complementRank,
  };
};
