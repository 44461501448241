import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Container, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMPLOYEES_CONSOLIDATION_CLASS, OverviewEnquete, OverviewEnqueteOptionData } from 'src/@types/overviewEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import Label from 'src/components/Label';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { EnqueteParentCustomerGrid } from 'src/components/app-components/bonsai/type-overview/EnqueteParentCustomerGrid';
import RHFAmountField from 'src/components/react-hook-form/RHFAmountField';
import RHFDeskTopDatePicker from 'src/components/react-hook-form/RHFDeskTopDatePicker';
import RHFDropdown from 'src/components/react-hook-form/RHFDropdown';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';
import { REQUEST_STATUS } from 'src/constants';
import { setOverviewEnqueteOptionAgGridData } from 'src/features/general/enquete-create/store/enqueteOptionSlice';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector, useDispatch } from 'src/redux/store';
import { dateFormat } from 'src/utils/formatDateTime';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useOverviewOption } from '../hooks/useOverviewOption';
import { AddOptionItemForm } from './AddOptionItemForm';
import AgGridField from './AgGridField';
import ArrayField from './ArrayField';
import ArrayNumericField from './ArrayNumericField';
import NoteField from './NoteField';
import TextField from './TextField';

export const OverviewOptionTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isJapanese } = useLocales();
  const methods = useForm({
    mode: 'onChange',
  });

  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData) as RespondentRequestEntity;
  const setCustomerCode = (enqueteData.surveyDetail.surveyFormData?.formData?.tabData[0] as OverviewEnquete).customerCode as string;
  const setSurveyFormOptionData = enqueteData.surveyDetail?.surveyFormOptionData as OverviewEnqueteOptionData;
  const isSave = setCustomerCode && methods.formState.isValid ? true : false;
  const isCompleted = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt) === REQUEST_STATUS.COMPLETE;

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAddOptionItemModal, setIsOpenAddOptionItemModal] = useState(false);
  const [isOpenMessageForInitialValuesModal, setIsOpenMessageForInitialValuesModal] = useState(false);

  const {
    initData,
    onClickSaveData,
    deleteAddItem,
    overviewInfoData,
    setOverviewEnqueteOptionData,
    isShowForInitialValues,
    numberValidation,
    arrayNumberValidation,
    dateValidation,
    createMessageForInitialValues,
  } = useOverviewOption();

  const MAX_DISPLAY_ITEMS = 5;

  useEffect(() => {
    (async function () {
      // 登録データはすでに質問タブで登録済み 初回のみ初期化データを渡す
      const data = setSurveyFormOptionData || initData;
      setOverviewEnqueteOptionData(data);
      dispatch(setOverviewEnqueteOptionAgGridData(data?.customerPerformance));
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <Grid item justifyContent={'flex-start'} flex={'50%'} xs={6}></Grid>
        <Grid item xs={6}>
          <Grid container justifyContent={'flex-end'} flex={'50%'} sx={{ paddingRight: 2, marginBottom: 1 }}>
            {/* 保存ボタン */}
            <Grid item>
              <Button
                variant={'contained'}
                onClick={methods.handleSubmit(onClickSaveData)}
                children={t('button.save')}
                sx={{ ml: 2 }}
                disabled={!isSave || isCompleted}
              />
            </Grid>
          </Grid>
        </Grid>
        <StyledBox>
          <Box sx={{ ml: 2 }}>
            <Grid container sx={{ marginBottom: 4 }} direction="row">
              <Grid item xs={10}>
                <Label color="info" sx={{ whiteSpace: 'pre-wrap', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}>
                  <Box style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{t('enqueteCreate.overviewEnqueteOption.message')}</Box>
                </Label>
              </Grid>
              <Grid item xs={2}>
                {isShowForInitialValues() && (
                  <Button
                    variant={'contained'}
                    onClick={() => {
                      setIsOpenMessageForInitialValuesModal(true);
                    }}
                    children={t('enqueteCreate.overviewEnqueteOption.initialValueButton')}
                    sx={{ ml: 10 }}
                    disabled={!isSave}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          {!isLoading && (
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
              <Grid container direction="column" rowSpacing={2}>
                <Typography variant="h5">{t('enqueteCreate.overviewEnqueteOption.title')}</Typography>
                <Grid container item direction="row">
                  <EnqueteParentCustomerGrid
                    customerCode={overviewInfoData.customerCode}
                    parentCustomerCode={overviewInfoData.parentCustomerCode}
                    parentCustomerNameJpn={overviewInfoData.parentCustomerName}
                    parentCustomerNameEng={overviewInfoData.parentCustomerNameEng}
                  />
                  <Grid item xs={4}>
                    <Grid container item direction="row">
                      <Typography variant="subtitle1">{`${t('enqueteCreate.overviewEnqueteOption.customerName')}：`}</Typography>
                      <Typography variant="subtitle1">
                        {isJapanese ? trimStockCompany(overviewInfoData.customerName) : overviewInfoData.customerNameEng}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/** 本社所在地 **/}
                <Grid container item direction="row" alignItems="center">
                  <TextField
                    methods={methods}
                    name={'address'}
                    label={t('enqueteCreate.overviewEnqueteOption.address')}
                    displayName={t('enqueteCreate.overviewEnqueteOption.address')}
                    defaultValue={overviewInfoData.address}
                    size={8}
                  />
                </Grid>

                {/** 資本金 **/}
                <Grid container item direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle1"> {`${t('enqueteCreate.overviewEnqueteOption.capital')}`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <RHFAmountField
                      control={methods.control}
                      name={`capital`}
                      handleOnChange={() => {}}
                      defaultValue={overviewInfoData.capital}
                      textFieldProps={{
                        size: 'small',
                        label: t('enqueteCreate.overviewEnqueteOption.capital'),
                      }}
                      rules={numberValidation}
                    />
                  </Grid>
                </Grid>

                {/** 設立年月日 / 業種 **/}
                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1"> {`${t('enqueteCreate.overviewEnqueteOption.estabDate')}`}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <RHFDeskTopDatePicker
                      name="estabDate"
                      label={t('enqueteCreate.overviewEnqueteOption.estabDate')}
                      control={methods.control}
                      mask="____/__/__"
                      views={['year', 'month', 'day']}
                      defaultValue={overviewInfoData.estabDate}
                      openTo="year"
                      rules={dateValidation}
                      inputFormat={dateFormat}
                      type="month"
                    />
                  </Grid>
                  {/**  業種 **/}
                  <TextField
                    methods={methods}
                    label={t('enqueteCreate.overviewEnqueteOption.sector')}
                    name={'sector'}
                    displayName={t('enqueteCreate.overviewEnqueteOption.sector')}
                    defaultValue={overviewInfoData.sector}
                    size={3}
                  />
                </Grid>

                {/** 代表者名 / 従業員数 / 従業員数種別 **/}
                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2}>
                  {/**  代表者名 **/}
                  <TextField
                    methods={methods}
                    name={'ceoName'}
                    label={t('enqueteCreate.overviewEnqueteOption.ceoName')}
                    displayName={t('enqueteCreate.overviewEnqueteOption.ceoName')}
                    defaultValue={overviewInfoData.ceoName}
                    size={3}
                  />
                  {/**  従業員数 **/}
                  <Grid item xs={2}>
                    <Typography variant="subtitle1">{t('enqueteCreate.overviewEnqueteOption.employeesNumber')}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <RHFNumericField
                      control={methods.control}
                      name={`employeesNumber`}
                      handleOnChange={() => {}}
                      rules={numberValidation}
                      defaultValue={overviewInfoData.employeesNumber}
                      textFieldProps={{
                        size: 'small',
                        label: t('enqueteCreate.overviewEnqueteOption.employeesNumber'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {/**   従業員数種別 **/}
                    <RHFDropdown
                      name={'employeesConsolidationClass'}
                      label={t('common.employeesConsolidationClass')}
                      control={methods.control}
                      defaultValue={overviewInfoData.employeesConsolidationClass}
                      handleOnChange={() => {}}>
                      {Object.values(EMPLOYEES_CONSOLIDATION_CLASS).map((v) => (
                        <MenuItem key={v} value={v}>
                          {`${t('common.employeesConsolidationClassType.' + v)}`}
                        </MenuItem>
                      ))}
                    </RHFDropdown>
                  </Grid>
                </Grid>

                {/** 株主 **/}
                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2}>
                  <ArrayField
                    methods={methods}
                    name={'shareholder'}
                    label={t('enqueteCreate.overviewEnqueteOption.shareholder')}
                    displayName={t('enqueteCreate.overviewEnqueteOption.shareholder')}
                    defaultValue={overviewInfoData.shareholderList}
                    length={MAX_DISPLAY_ITEMS}
                  />
                </Grid>

                {/** 持株比率 **/}
                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2}>
                  <ArrayNumericField
                    methods={methods}
                    name={'shareholderRatio'}
                    label={t('enqueteCreate.overviewEnqueteOption.shareholderRatio')}
                    displayName={`${t('enqueteCreate.overviewEnqueteOption.shareholderRatio')}(%)`}
                    defaultValue={overviewInfoData.shareholderRatioList}
                    length={MAX_DISPLAY_ITEMS}
                    rules={arrayNumberValidation}
                  />
                </Grid>

                {/** 主販売先 **/}
                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2}>
                  <ArrayField
                    methods={methods}
                    name={'mainCustomer'}
                    label={t('enqueteCreate.overviewEnqueteOption.mainCustomer')}
                    displayName={t('enqueteCreate.overviewEnqueteOption.mainCustomer')}
                    defaultValue={overviewInfoData.mainCustomerList}
                    length={MAX_DISPLAY_ITEMS}
                  />
                </Grid>

                {/** 主仕入先 **/}
                <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columnSpacing={2}>
                  <ArrayField
                    methods={methods}
                    name={'mainSupplier'}
                    label={t('enqueteCreate.overviewEnqueteOption.mainSupplier')}
                    displayName={t('enqueteCreate.overviewEnqueteOption.mainSupplier')}
                    defaultValue={overviewInfoData.mainSupplierList}
                    length={MAX_DISPLAY_ITEMS}
                  />
                </Grid>

                {/** 業務推移 **/}
                <AgGridField isClosed={false} />

                {/* 概況項目追加ボタン */}
                <Grid container item justifyContent={'flex-end'} columns={18}>
                  <Grid item xs={2}>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        setIsOpenAddOptionItemModal(true);
                      }}
                      children={t('enqueteCreate.overviewEnqueteOption.saveButton')}
                      disabled={!setCustomerCode}
                    />
                  </Grid>
                </Grid>
                {/** 追加項目 **/}
                {overviewInfoData.addItemList.map((value, index) => (
                  <>
                    <Grid container item direction="row" alignItems="center">
                      <NoteField
                        methods={methods}
                        name={`item${index}`}
                        displayName={isJapanese ? value.itemNameJpn : value.itemNameEng}
                        defaultValue={value.inputContent}
                        size={9}
                        rowSize={3}
                      />
                      <Button
                        color="error"
                        onClick={(e) => {
                          deleteAddItem(e, methods);
                        }}
                        variant={'contained'}
                        value={`${index}`}
                        sx={{ justifyContent: 'center', m: 'auto' }}>
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Container>
          )}
        </StyledBox>
      </Stack>
      {/** モーダル **/}
      <StyledModal
        isOpen={isOpenAddOptionItemModal}
        onCloseFunc={() => setIsOpenAddOptionItemModal(false)}
        modalTitle={t('enqueteCreate.overviewEnqueteOption.addItemModalTitle')}
        children={
          <AddOptionItemForm
            setIsModalOpen={(v) => setIsOpenAddOptionItemModal(v)}
            setOverviewEnqueteOptionData={(v) => {
              setOverviewEnqueteOptionData(v);
            }}
            overviewInfoData={overviewInfoData}
          />
        }
      />

      {/** モーダル **/}
      <StyledModal
        isOpen={isOpenMessageForInitialValuesModal}
        onCloseFunc={() => setIsOpenMessageForInitialValuesModal(false)}
        modalTitle={t('enqueteCreate.overviewEnqueteOption.initialValueModalTitle')}>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{t('enqueteCreate.overviewEnqueteOption.initialValueMessage')}</Typography>
        <Box sx={{ m: 1 }} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
          {createMessageForInitialValues()}
        </Box>
        <Box textAlign="center" sx={{ m: 2 }}>
          <StyledButton
            onClick={() => {
              setIsOpenMessageForInitialValuesModal(false);
            }}>
            {t('button.close')}
          </StyledButton>
        </Box>
      </StyledModal>
    </>
  );
};
