import { format, isAfter, isSameDay, isValid } from 'date-fns';
import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddItem, EMPLOYEES_CONSOLIDATION_CLASS, OverviewEnquete, OverviewEnqueteOptionData } from 'src/@types/overviewEnquete';
import { useCustomerApi } from 'src/api/useCustomerApi';
import { useEnqueteOptionApi } from 'src/api/useEnqueteOptionApi';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';

/** 初期データ  */
const initData: OverviewEnqueteOptionData = {
  parentCustomerCode: '', // 親会社コード
  parentCustomerName: '', // 親会社名
  parentCustomerNameEng: '', // 親会社名（英語）
  customerCode: '', // 取引先コード
  customerName: '', // 取引先名
  customerNameEng: '', // 取引先名（英語）
  address: null, // 本社所在地
  estabDate: null, // 設立年月日
  sector: null, // 業種
  capital: null, // 資本金
  systemCapital: null, // 資本金(外貨)
  capitalCurrencyCode: null, // 資本金通貨コード
  isConvertYen: false, // 円換算実行フラグ
  ceoName: null, // 代表者名
  employeesNumber: null, // 従業員数
  employeesConsolidationClass: EMPLOYEES_CONSOLIDATION_CLASS.STANDALONE, // 従業員数種別
  shareholderList: ['', '', '', '', ''], // 株主
  shareholderRatioList: [null, null, null, null, null], // 持株比率
  mainCustomerList: ['', '', '', '', ''], // 主販売先名
  mainSupplierList: ['', '', '', '', ''], // 主仕入れ先名
  customerPerformance: [], // 業績(直近5年分を持つ)
  addItemList: [], // 追加項目
};

export const useOverviewOption = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData) as RespondentRequestEntity;
  const overviewEnqueteOptionData = useAppSelector((state: RootState) => state.enqueteOption.overviewEnqueteOptionData);
  const [overviewInfoData, setOverviewEnqueteOptionData] = useState<OverviewEnqueteOptionData>(initData);

  const pageApi = useEnqueteOptionApi();
  const { getInitialCustomerOverviewInfo } = useCustomerApi();

  const overviewEnquete = enqueteData.surveyDetail.surveyFormData?.formData?.tabData[0] as OverviewEnquete;
  const parentCustomerCode = overviewEnquete?.parentCustomerCode as string;
  const parentCustomerNameJpn = overviewEnquete?.parentCustomerNameJpn as string;
  const parentCustomerNameEng = overviewEnquete?.parentCustomerNameEng as string;

  /**
   * 初期データを作成する
   * @param surveyDetailId
   * @param customerCode
   * @returns
   */
  const createInitData = async (surveyDetailId: string, customerCode: string) => {
    const customerOverviewInfo = await getInitialCustomerOverviewInfo(surveyDetailId);
    const initData: OverviewEnqueteOptionData = {
      parentCustomerCode: parentCustomerCode || '', // 親会社コード
      parentCustomerName: parentCustomerNameJpn || '', // 親会社名
      parentCustomerNameEng: parentCustomerNameEng || '', // 親会社名（英語）
      customerCode: customerCode || '', // 取引先コード
      customerName: customerOverviewInfo?.customerNameJpn || '', // 取引先名
      customerNameEng: customerOverviewInfo?.customerNameEng || '', // 取引先名（英語）
      address: customerOverviewInfo?.address ?? null, // 本社所在地
      estabDate: customerOverviewInfo?.estabDate ?? null, // 設立年月日
      sector: customerOverviewInfo?.sector ?? null, // 業種
      capital: customerOverviewInfo?.capital ?? null, // 資本金
      systemCapital: customerOverviewInfo?.systemCapital ?? null,
      capitalCurrencyCode: customerOverviewInfo?.capitalCurrencyCode ?? null, // 資本金
      isConvertYen: !!customerOverviewInfo?.isConvertYen, // 円換算実行フラグ
      ceoName: customerOverviewInfo?.ceoName ?? '', // 代表者名
      employeesNumber: customerOverviewInfo?.employeesNumber ?? null, // 従業員数
      employeesConsolidationClass: customerOverviewInfo?.employeesConsolidationClass ?? EMPLOYEES_CONSOLIDATION_CLASS.STANDALONE, // 従業員数種別
      shareholderList: customerOverviewInfo?.shareholderList ?? ['', '', '', '', ''], // 株主
      shareholderRatioList: customerOverviewInfo?.shareholderRatioList ?? [null, null, null, null, null], // 持株比率
      mainCustomerList: customerOverviewInfo?.mainCustomerList ?? ['', '', '', '', ''], // 主販売先名
      mainSupplierList: customerOverviewInfo?.mainSupplierList ?? ['', '', '', '', ''], // 主仕入れ先名
      customerPerformance: customerOverviewInfo?.customerPerformance ?? [], // 業績(直近5年分を持つ)
      addItemList: [],
    };

    await pageApi.putEnqueteOption(surveyDetailId, initData);

    return initData;
  };

  /**
   * 会社概況情報を保存する
   * @param inputData
   * @returns
   */
  const onClickSaveData = async (inputData: FieldValues) => {
    const updateData: OverviewEnqueteOptionData = {
      parentCustomerCode: overviewInfoData.parentCustomerCode, // 親会社コード
      parentCustomerName: overviewInfoData.parentCustomerName, // 親会社名
      parentCustomerNameEng: overviewInfoData.parentCustomerNameEng, // 親会社名（英語）
      customerCode: overviewInfoData.customerCode, // 取引先コード
      customerName: overviewInfoData.customerName, // 取引先名
      customerNameEng: overviewInfoData.customerNameEng, // 取引先名（英語）
      address: inputData.address, // 本社所在地
      estabDate: inputData.estabDate, // 設立年月日
      sector: inputData.sector, // 業種
      capital: inputData.capital, // 資本金
      systemCapital: overviewInfoData.systemCapital,
      capitalCurrencyCode: overviewInfoData.capitalCurrencyCode, // 資本金
      isConvertYen: overviewInfoData.isConvertYen, // 円換算実行フラグ
      ceoName: inputData.ceoName, // 代表者名
      employeesNumber: inputData.employeesNumber !== undefined ? Number(inputData.employeesNumber) : null, // 従業員数
      employeesConsolidationClass: inputData.employeesConsolidationClass,
      shareholderList: [inputData.shareholder0, inputData.shareholder1, inputData.shareholder2, inputData.shareholder3, inputData.shareholder4], // 株主
      shareholderRatioList: [
        inputData.shareholderRatio0 ? Number(inputData.shareholderRatio0) : null,
        inputData.shareholderRatio1 ? Number(inputData.shareholderRatio1) : null,
        inputData.shareholderRatio2 ? Number(inputData.shareholderRatio2) : null,
        inputData.shareholderRatio3 ? Number(inputData.shareholderRatio3) : null,
        inputData.shareholderRatio4 ? Number(inputData.shareholderRatio4) : null,
      ], // 持株比率
      mainCustomerList: [inputData.mainCustomer0, inputData.mainCustomer1, inputData.mainCustomer2, inputData.mainCustomer3, inputData.mainCustomer4], // 主販売先名
      mainSupplierList: [inputData.mainSupplier0, inputData.mainSupplier1, inputData.mainSupplier2, inputData.mainSupplier3, inputData.mainSupplier4], // 主仕入れ先名
      customerPerformance: overviewEnqueteOptionData, // 業績(直近5年分を持つ)
      addItemList: getAddItemList(inputData), // 追加項目
    };
    // EnqueteDataを更新
    const newEnqueteData = {
      ...enqueteData,
      surveyDetail: {
        ...enqueteData.surveyDetail,
        surveyFormOptionData: updateData,
      },
    };
    dispatch(setEnqueteData(newEnqueteData));

    const result = await pageApi.putEnqueteOption(enqueteData.surveyDetailId, updateData);
    if (result) {
      enqueueSnackbar(t('button.saved'), { variant: 'success' });
    }
  };

  /**
   * 画面の追加項目を取得し、保存用にオブジェクトを作成する
   * @param inputData
   * @returns
   */
  const getAddItemList = (inputData: FieldValues) => {
    const result: AddItem[] = [];
    overviewInfoData.addItemList.forEach((obj, index) => {
      result.push({
        itemNameJpn: obj.itemNameJpn,
        itemNameEng: obj.itemNameEng,
        inputContent: inputData['item' + index],
        displayOrder: index + 1,
      });
    });
    return result;
  };

  /**
   * 追加項目削除イベント
   * @param event
   */
  const deleteAddItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, methods: UseFormReturn) => {
    const index = parseInt(event.currentTarget.value);
    const addItemList = overviewInfoData.addItemList.concat();
    addItemList.splice(index, 1);
    // 表示順序を正しく当てはめ返却する
    setOverviewEnqueteOptionData({
      ...overviewInfoData,
      addItemList: addItemList.map((v, index) => {
        return { ...v, displayOrder: index + 1 };
      }),
    });
    // 削除した項目を除き、画面側の表示を正しくする
    addItemList.forEach((item, index) => {
      let value = methods?.getValues(`item${item.displayOrder - 1}`) ?? '';
      // 削除した項目以降の場合は削除前の項目の場所を取得する
      if (index >= item.displayOrder) {
        value = methods?.getValues(`item${item.displayOrder}`) ?? '';
      }
      methods?.setValue(`item${index}`, value);
    });
  };

  const numberValidation = {
    pattern: {
      value: /^([1-9]\d*|0)$/,
      message: t('validateError.positiveIntegersOnly'),
    },
    min: {
      value: 0,
      message: t('validateError.positiveIntegersOnly'),
    },
  };

  const arrayNumberValidation = {
    validate: {
      pattern: (value: string | number | null) => {
        if (value) {
          const inputValueList = value.toString().split('.');
          const stringValue = inputValueList[0];
          // 正の整数、または小数一桁まで許容
          if (/^(-)?$/.test(`${stringValue}`) || !/^([1-9]\d*|0)(\.\d+)?$/.test(`${stringValue}`)) return t('validateError.positiveIntegersOnly');
          // 100以下であること
          if (Number(stringValue) > 100) return `${t('validateError.maxNumerical', { max: 100 })}`;
          // 小数は一桁まで入力可能
          if (inputValueList.length > 1) {
            if (inputValueList[1].length > 1) return `${t('validateError.maxDecimal', { max: 1 })}`;
          }
        }
      },
    },
  };

  const dateValidation = {
    validate: {
      isValidDate: (value: string) => {
        if (!isValid(new Date(value))) {
          return `${t('validateError.format.date')}`;
        }
        // 過去日かつ本日と同じ日付でないこと
        if (isAfter(new Date(value), new Date()) || isSameDay(new Date(value), new Date())) {
          return `${t('validateError.dateIsPast')}`;
        }
        return true;
      },
    },
  };

  /**
   * 円換算未解決のものがあるか確認する.
   * @returns 円換算未解決のものがある場合 True
   */
  const isShowForInitialValues = () => {
    if (!overviewInfoData.isConvertYen && overviewInfoData.capitalCurrencyCode) {
      return true;
    }
    let result = false;
    for (const data of overviewInfoData.customerPerformance) {
      if (!data.isConvertYen && data.currencyCode) {
        result = true;
        break;
      }
    }
    return result;
  };

  /**
   * 円換算未解決のものの画面出力用文言を作成する
   * @returns
   */
  const createMessageForInitialValues = () => {
    const newlineCode = '\n';
    let message = '';
    if (!overviewInfoData.isConvertYen && overviewInfoData.capitalCurrencyCode) {
      message += `${t('enqueteCreate.overviewEnqueteOption.capital')} ${newlineCode}`;
      message += `  ${t('common.currencyCode')}: ${overviewInfoData.capitalCurrencyCode} ${newlineCode}`;
      message += `  ${t('common.amount')}: ${Number(overviewInfoData.systemCapital).toLocaleString()}${newlineCode}`;
    }
    overviewInfoData.customerPerformance.forEach((v) => {
      if (!v.isConvertYen && v.currencyCode) {
        message += `${newlineCode} ${t('enqueteCreate.overviewEnqueteOption.gridColDef.fiscalYear')}: ${format(
          new Date(v.fiscalYear),
          'yyyy/MM',
        )} ${newlineCode}`;
        message += `   ${t('common.currencyCode')}: ${v.currencyCode} ${newlineCode}`;
        message += `   ${t('common.salesAmount')}: ${v.systemSalesAmount ? Number(v.systemSalesAmount).toLocaleString() : '0'}`;
        message += newlineCode;
        message += `   ${t('common.netProfit')}: ${v.systemNetProfit ? Number(v.systemNetProfit).toLocaleString() : '0'}`;
      }
    });
    return message;
  };

  return {
    createInitData,
    onClickSaveData,
    deleteAddItem,
    initData,
    overviewInfoData,
    numberValidation,
    arrayNumberValidation,
    dateValidation,
    setOverviewEnqueteOptionData,
    isShowForInitialValues,
    createMessageForInitialValues,
  };
};
