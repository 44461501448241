import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BonsaiAddedCustomer } from 'src/api/useCustomerManagementApi';

export const customerManagementSlice = createSlice({
  name: 'customerManagement',
  initialState: {
    bonsaiAddedCustomers: [] as BonsaiAddedCustomer[],
    isEditModalOpen: false as boolean,
    editCustomer: null as BonsaiAddedCustomer | null,
    isApplyA1CodeModalOpen: false,
    isCustomerLoading: true,
  },
  reducers: {
    setBonsaiAddedCustomers: (state, action: PayloadAction<BonsaiAddedCustomer[]>) => {
      state.bonsaiAddedCustomers = action.payload;
    },
    openEditCustomerModal: (state, action: PayloadAction<BonsaiAddedCustomer | null>) => {
      state.editCustomer = action.payload;
      state.isEditModalOpen = true;
    },
    closeEditCustomerModal: (state, _action: PayloadAction) => {
      state.editCustomer = null;
      state.isEditModalOpen = false;
    },
    openApplyA1CodeModal: (state, action: PayloadAction<BonsaiAddedCustomer>) => {
      state.editCustomer = action.payload;
      state.isApplyA1CodeModalOpen = true;
    },
    closeApplyA1CodeModal: (state, _action: PayloadAction) => {
      state.editCustomer = null;
      state.isApplyA1CodeModalOpen = false;
    },
    setIsCustomerLoading: (state, action: PayloadAction<boolean>) => {
      state.isCustomerLoading = action.payload;
    },
  },
});

export const {
  setBonsaiAddedCustomers,
  openEditCustomerModal,
  closeEditCustomerModal,
  openApplyA1CodeModal,
  closeApplyA1CodeModal,
  setIsCustomerLoading,
} = customerManagementSlice.actions;
