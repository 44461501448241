import { GetContextMenuItems, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchDataEntity, useOrganizationApi } from 'src/api/useOrganizationApi';
import { UserEntity } from 'src/api/useUserApi';
import { useSelectUserAgGrid } from 'src/components/app-components/bonsai/hooks/useSelectUserAgGrid';
import { ConditionProps, SelectModal } from 'src/components/app-components/bonsai/SelectModal';
import useSuggestUser from 'src/hooks/useSuggestUser';

export interface Props {
  respondentTargetId: string | undefined;
  open: boolean;
  onClose: () => void;
  onOk: (values: UserEntity[]) => void;
  onValidation: (values: UserEntity[]) => boolean;
}

export const SelectRespondentsModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { userSuggest, updateUserSuggest } = useSuggestUser();

  // API hooks
  const orgApi = useOrganizationApi();
  // grid rowdata
  const [rowData, setRowData] = useState<UserEntity[]>([]);
  // selector selected values
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  // grid api
  const gridApi = useRef<GridApi | null>(null);
  // グリッド定義
  const { columnDefs } = useSelectUserAgGrid();

  // init
  useEffect(() => {
    // init suggest
    if (props.open) updateUserSuggest();
  }, [props.open]); //eslint-disable-line

  // condition define
  const conditions: ConditionProps[] = [
    {
      label: t('enqueteAnswerInput.changeRespondents.addRespondentsModal.textfield.placeholder'),
      value: selectedUser,
      list: userSuggest.map((item) => item.key),
      display: true,
      onChange: (value) => setSelectedUser(value),
      onInputChange: (v, r) => {
        if (r !== 'reset') updateUserSuggest(v);
      },
    },
  ];

  //-------------------------------------------------------------
  // グリッドの表示準備完了時の処理
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // 追加ボタンが押された時の処理
  //-------------------------------------------------------------
  const onSelected = async () => {
    // check input string
    const user = userSuggest.find((user) => user.key === selectedUser);
    if (!user) return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.addRespondentsModal.message.noUserSelected'), { variant: 'error' });

    // 検索条件定義
    const condition: searchDataEntity = {
      companyCode: user.entity.companyCode ?? '',
      divisionCode: user.entity.divisionCode ?? '',
      sbuCode: user.entity.sbuCode ?? '',
      departmentCode: user.entity.departmentCode ?? '',
      groupCode: user.entity.groupCode ?? '',
      isFuture: false,
      isMultiSegment: false,
    };
    // 組織情報解決
    const result = await orgApi.getResolveOrganizationData(condition);
    if (result === undefined) return;

    const addRowData = {
      ...user.entity,
      divisionCode: result[0].divisionCode,
      sbuCode: result[0].sbuCode,
      departmentCode: result[0].departmentCode,
    };
    // check duplicated
    const exists = rowData.filter((rowdata) => JSON.stringify(rowdata) === JSON.stringify(addRowData));
    if (exists.length !== 0)
      return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.addRespondentsModal.message.alreadySelected'), { variant: 'warning' });

    // validation with page grid
    if (!props.onValidation([addRowData])) {
      return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.addRespondentsModal.message.alreadyRegisteredRespondent'), {
        variant: 'warning',
      });
    }

    // add to rowdata
    setRowData([...rowData, addRowData]);
  };

  //-------------------------------------------------------------
  // 削除ボタンが押された時の処理
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('button.delete'),
        action: () => setRowData(rowData.filter((row, i) => i !== params.node?.rowIndex)),
      },
    ];

    return menuItems;
  };

  //-------------------------------------------------------------
  // OKボタンが押された時の処理
  //-------------------------------------------------------------
  const onApplyed = () => {
    if (props.onValidation(rowData)) {
      const copyRowData = [...rowData];
      props.onOk(copyRowData);
      onReset();
    }
  };

  //-------------------------------------------------------------
  // ステートのリセット処理
  //-------------------------------------------------------------
  const onReset = () => {
    setRowData([]);
    setSelectedUser(null);
  };

  return (
    <SelectModal
      title={t('enqueteAnswerInput.changeRespondents.addRespondentsModal.title')}
      open={props.open}
      conditions={conditions}
      table={{
        columnDefs: columnDefs,
        rowData: rowData,
        onGridReady,
        contextMenu: contextMenu,
      }}
      onClose={props.onClose}
      onAdd={onSelected}
      onOk={onApplyed}
    />
  );
};
