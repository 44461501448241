import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';

export const useCalculateFiscalYear = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);
  // 依頼期間を取得
  if (!respondentRequestData.targetDate) {
    enqueueSnackbar(t('message.abnormalData'), { variant: 'error' });
    // エラーのため適当な年度を返す
    return { currentYear: 1111, previousYear: 1111 };
  }
  const baseDate = respondentRequestData.targetDate.split('-');

  // 仕入高・売上高のラベル用の年度換算 基本的には前年度、前前年度が対象。1月以降は年は変わるが年度は変わらないため2年分遡る
  const currentYear = parseInt(baseDate[1]) > 3 ? parseInt(baseDate[0]) - 1 : parseInt(baseDate[0]) - 2;
  const previousYear = parseInt(baseDate[1]) > 3 ? parseInt(baseDate[0]) - 2 : parseInt(baseDate[0]) - 3;

  return { currentYear, previousYear };
};
