import { useCallback } from 'react';
import { GeneralEnquete, MainTableType, NestedHeader } from 'src/@types/generalEnquete';
import { MainTableSheetNamesType, TotalMainTablesType } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { useSurveyTables } from 'src/hooks/useSurveyTables';
import { RootState, useAppSelector } from 'src/redux/store';

export const useSurveyTableAnswerDataService = () => {
  const { isMultiAnswerTableSurvey } = useSurveyTables();

  // ストア
  const mainTableSheetNames = useAppSelector((state: RootState) => state.tableSetting.mainTableSheetNames);

  // 渡されたデータからtotalMainTablesを作成
  const makeTotalMainTables = useCallback(
    (tabData: GeneralEnquete, rowHeaders: string[] | undefined, nestedHeaders: (string | NestedHeader)[][] | undefined) => {
      if (!tabData.tables) return [];

      // メイン表を格納
      const data: TotalMainTablesType[] = [
        {
          sourceData: tabData.tables.sourceData,
          answerData: [],
          cell: tabData.tables.cell,
          mergeCells: tabData.tables.mergeCells,
          colWidths: tabData.tables.colWidths,
          rowHeights: tabData.tables.rowHeights,
          rowHeaders: tabData.tables.rowHeaders ?? rowHeaders,
          nestedHeaders: tabData.tables.nestedHeaders ?? nestedHeaders,
          fixedColumnsStart: tabData.tables.fixedColumnsStart,
          fixedRowsTop: tabData.tables.fixedRowsTop,
        },
      ];

      // 追加分回答表データがあれば、追加分の回答表を結合
      const additionalTables = tabData.tables.additionalTables ?? [];
      if (additionalTables.length > 0) data.push(...additionalTables);

      return data;
    },
    [],
  );

  // 渡されたデータからmainTableSheetNamesを作成
  const makeMainTableSheetNames = useCallback((tabData: GeneralEnquete) => {
    if (!tabData.tables?.sheetNameJpn || !tabData.tables?.sheetNameEng) return [];

    // メイン表を格納
    const data: MainTableSheetNamesType[] = [
      {
        sheetNameJpn: tabData.tables.sheetNameJpn,
        sheetNameEng: tabData.tables.sheetNameEng,
      },
    ];

    // 追加分回答表データがあれば、追加分の回答表シート名を結合
    const originalAdditionalTables = tabData.tables.additionalTables ?? [];
    if (originalAdditionalTables.length > 0) {
      const additionalTables = originalAdditionalTables.map((table: MainTableType) => ({
        sheetNameJpn: table.sheetNameJpn ?? '',
        sheetNameEng: table.sheetNameEng ?? '',
      }));

      data.push(...additionalTables);
    }

    return data;
  }, []);

  // APIリクエスト前に、ストアデータをアンケート構造に習い整形
  const formatSurveyAnswerData = useCallback(
    (totalMainTables: TotalMainTablesType[]) => {
      // 1つ目の回答表を定義
      const mainTableData = {
        sourceData: totalMainTables[0].sourceData,
        answerData: totalMainTables[0].answerData,
        cell: totalMainTables[0].cell,
        mergeCells: totalMainTables[0].mergeCells,
        colWidths: totalMainTables[0].colWidths,
        rowHeights: totalMainTables[0].rowHeights,
        rowHeaders: totalMainTables[0].rowHeaders,
        nestedHeaders: totalMainTables[0].nestedHeaders,
        fixedColumnsStart: totalMainTables[0].fixedColumnsStart,
        fixedRowsTop: totalMainTables[0].fixedRowsTop,
        // シートデータがなければ未定義とする（単一回答表対応）
        ...(isMultiAnswerTableSurvey && { sheetNameJpn: mainTableSheetNames[0].sheetNameJpn }),
        ...(isMultiAnswerTableSurvey && { sheetNameEng: mainTableSheetNames[0].sheetNameEng }),
      };

      // 2つ目以降の回答表
      const currentAdditionalTables: MainTableType[] = totalMainTables.filter((_, index) => index !== 0);
      const additionalTables = currentAdditionalTables.map((table, index) => {
        return {
          ...table,
          // シートデータがなければ未定義とする（単一回答表対応）
          ...(isMultiAnswerTableSurvey && { sheetNameJpn: mainTableSheetNames[index + 1].sheetNameJpn }),
          ...(isMultiAnswerTableSurvey && { sheetNameEng: mainTableSheetNames[index + 1].sheetNameEng }),
        };
      });

      return { ...mainTableData, additionalTables };
    },
    [isMultiAnswerTableSurvey, mainTableSheetNames],
  );

  return { makeTotalMainTables, makeMainTableSheetNames, formatSurveyAnswerData };
};
