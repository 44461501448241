import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AggregateRow } from 'src/api/useEnqueteStatusPageApi';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import useTheme from 'src/hooks/useTheme';
import { useSummaryAggregateColdef } from '../hooks/useSummaryAggregateColdef';
interface Props {
  aggregateRows: AggregateRow[];
  previousYear: string;
  currentYear: string;
}

export const SummaryAggregateGrid: React.FC<Props> = ({ aggregateRows, previousYear, currentYear }) => {
  const { defaultColDef, colDef, gridOptions } = useSummaryAggregateColdef(previousYear, currentYear);
  const { className } = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={className}>
          <Typography variant="h5">{t('enqueteStatus.summaryAggregate.gridTitle.summary')}</Typography>
          <StyledAgGrid defaultColDef={defaultColDef} coldef={colDef} rows={aggregateRows} gridOptions={gridOptions} />
        </Box>
      </Grid>
    </Grid>
  );
};
