import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputRadioGroup from 'src/components/app-components/bonsai/survey-information/components/InputRadioGroup';
import { ANSWER_UNIT, AnswerUnit } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';

type EnqueteAnserUnitFormProps = {
  methods: UseFormReturn;
  answerUnit: AnswerUnit;
};

export const EnqueteAnswerUnitForm = ({ methods, answerUnit }: EnqueteAnserUnitFormProps) => {
  const { rulesAnswerUnit } = useValidator(methods);
  const watchDisabled = methods.watch('labelJpn')?.id || !methods.watch('ownerOrganization')?.key ? true : false;
  const { t } = useTranslation();

  return (
    <InputRadioGroup
      name={'enqueteAnswerUnit'}
      defaultValue={answerUnit}
      control={methods.control}
      rules={rulesAnswerUnit}
      getValues={methods.getValues}
      list={[
        { value: ANSWER_UNIT.ORGANIZATION, label: t('enqueteGeneralCommon.enqueteAnswerUnitOrganization') },
        { value: ANSWER_UNIT.PERSONAL, label: t('enqueteGeneralCommon.enqueteAnswerUnitPersonal') },
      ]}
      disabled={watchDisabled}
    />
  );
};
