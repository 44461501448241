import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserAgGridColDef } from 'src/components/app-components/bonsai/hooks/useUserAgGridColDef';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { useRank } from 'src/hooks/useRank';

const useSelectRespondentGrid = () => {
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { t } = useTranslation();
  const { complementRank } = useRank();

  const { userAgGridColDef } = useUserAgGridColDef({
    companyCodeColName: 'respondentPersonCompanyCode',
    emailColDefSetting: { key: 'respondentPersonEmail' },
    fullNameColDefSetting: { key: 'respondentPersonFullName' },
    companyNameColDefSetting: { key: 'respondentPersonCompanyName' },
    companyAbbreviationColDefSetting: { key: 'respondentPersonCompanyAbbreviation' },
    assignedCompanyColDefSetting: { key: 'respondentPersonAssignedCompanyName' },
    divisionNameColDefSetting: { key: 'respondentPersonDivisionName', columnGroupShowValue: 'open' },
    sbuNameColDefSetting: { key: 'respondentPersonSbuName', columnGroupShowValue: 'open' },
    departmentNameColDefSetting: { key: 'respondentPersonDepartmentName', columnGroupShowValue: 'open' },
    groupNameColDefSetting: { key: 'respondentPersonGroupName', columnGroupShowValue: 'open' },
    bluePageEmailAddressColName: 'respondentPersonBluePageEmailAddress',
  });

  // AgGridのカラム開閉フラグ
  const [openOrganizationColumnGroup, setOpenOrganizationColumnGroup] = useState<boolean>(false);
  const [openRespondentColumnGroup, setOpenRespondentColumnGroup] = useState<boolean>(false);

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'serialNumber',
      tooltipField: 'serialNumber',
      headerName: 'No.',
      valueGetter: 'node.rowIndex + 1',
      minWidth: 30,
      width: 70,
      suppressMenu: true,
      flex: 0,
    },
    {
      headerName: t('enqueteCreate.respondentGridColDef.targetGroupName'),
      openByDefault: openOrganizationColumnGroup,
      children: [
        {
          headerName: t('enqueteCreate.respondentGridColDef.companyCode'),
          field: 'targetCompanyCode',
          minWidth: 120,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
          columnGroupShow: 'open',
        },
        {
          headerName: t('enqueteCreate.respondentGridColDef.rank'),
          field: 'targetRank',
          minWidth: 65,
          valueGetter: (params: ValueGetterParams) => {
            return complementRank(params.data.targetCompanyCode, params.data.targetRank);
          },
          suppressMenu: true,
          sortable: false,
          columnGroupShow: 'open',
        },
        {
          headerName: t('enqueteCreate.respondentGridColDef.targetCompanyName'),
          field: isLangJpn ? 'targetCompanyNameJpn' : 'targetCompanyNameEng',
          minWidth: 200,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: t('common.companyAbbreviation'),
          field: 'targetCompanyAbbreviation',
          minWidth: 200,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: t('enqueteCreate.respondentGridColDef.targetDivisionName'),
          field: isLangJpn ? 'targetDivisionNameJpn' : 'targetDivisionNameEng',
          minWidth: 200,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: t('enqueteCreate.respondentGridColDef.targetSbuName'),
          field: isLangJpn ? 'targetSbuNameJpn' : 'targetSbuNameEng',
          minWidth: 200,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: t('enqueteCreate.respondentGridColDef.targetDepartmentName'),
          field: isLangJpn ? 'targetDepartmentNameJpn' : 'targetDepartmentNameEng',
          minWidth: 200,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: t('common.groupName'),
          field: isLangJpn ? 'targetGroupNameJpn' : 'targetGroupNameEng',
          minWidth: 200,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: t('common.region'),
          field: isLangJpn ? 'targetRegionNameJpn' : 'targetRegionNameEng',
          minWidth: 140,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
          columnGroupShow: 'open',
        },
        {
          headerName: t('common.country'),
          field: isLangJpn ? 'targetCountryNameJpn' : 'targetCountryNameEng',
          minWidth: 140,
          tooltipValueGetter: (params) => params.value,
          suppressMenu: true,
          sortable: false,
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: t('enqueteCreate.respondentGridColDef.respondentGroupName'),
      openByDefault: openRespondentColumnGroup,
      children: [...userAgGridColDef],
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return { columnDefs, setOpenOrganizationColumnGroup, setOpenRespondentColumnGroup };
};

export default useSelectRespondentGrid;
