import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

const apiEndPoint: string = '/access-role-type';

// 本部極担当者アクセス権限 レスポンスインターフェース
export interface AccessRoleType {
  id: string;
  roleName: string;
  answerRead: boolean;
  answerEdit: boolean;
}

export const useAccessRoleTypeApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();
  /**
   * 一覧取得
   *
   * @param surveyDetailId
   * @returns
   */
  const getAccessRoleTypeList = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<AccessRoleType[]>(apiGateway + `${apiEndPoint}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      return result.data;
    } catch (error) {
      // memo: 上位層でエラーハンドリングの仕組みがないため、ここでは画面にエラーであることを表示し握りつぶす
      enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: apiEndPoint }), { variant: 'error' });
    }
  };

  return { getAccessRoleTypeList };
};
