import { SerializedError } from '@reduxjs/toolkit';

export const API_CALL_STATUS = {
  FULFILLED: 'fulfilled', // 達成
  PENDING: 'pending', // 処理中
  INIT: '', // 初期値
} as const;
export type ApiCallStatus = typeof API_CALL_STATUS[keyof typeof API_CALL_STATUS];
export type StatusType = ApiCallStatus | SerializedError;

export const PERMISSION = {
  SUPER_USER: 'SUPER_USER',
  MEMBER: 'MEMBER',
};

export type Permission = typeof PERMISSION[keyof typeof PERMISSION];
