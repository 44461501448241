import PropTypes from 'prop-types';
import { FC, ReactNode, useState } from 'react';
import { Navigate, json, useLocation } from 'react-router-dom';
import Login from '../features/authentication/Login';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children: ReactNode;
}

const isBonsaiUser = (roles: string[]) => {
  // 速度を考えてforを使用
  for (const r of roles) {
    if (r.startsWith('bonsai-')) {
      return true;
    }
  }
  return false;
};

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const currentPathName: string = location.pathname + location.search;

  if (!auth.isAuthenticated) {
    if (currentPathName !== requestedLocation) {
      setRequestedLocation(currentPathName);
    }

    return <Login />;
  }

  if (auth.method === 'COGNITO') {
    if (auth.user && auth.isAuthenticated && !isBonsaiUser(auth.user.role)) {
      throw json(null, { status: 403, statusText: 'You are not allowed to access this page' });
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && currentPathName !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
