import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE, Question } from 'src/@types/generalEnquete';
import EnqueteAnswerCheckboxForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerCheckboxForm';
import EnqueteAnswerCommentForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerCommentForm';
import EnqueteAnswerDropdownForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerDropdownForm';
import EnqueteAnswerForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerForm';
import EnqueteAnswerRadioGroupForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerRadioGroupForm';
import EnqueteAnswerTextForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerTextForm';
import { saveQuestion, setIsOpenLogicErrorMessageModal, setLogicErrorMessages } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { removeMode, setMode } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { QUESTION_SAVE_MODE } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store/index';
import { v1 } from 'uuid';
import { useControlButton } from '../hooks/useControlButton';
import { useLogicSettingCheck } from '../hooks/useLogicSettingCheck';
import QuestionCheckboxForm from './common/components/QuestionCheckboxForm';
import QuestionCommentForm from './common/components/QuestionCommentForm';
import QuestionDropdownForm from './common/components/QuestionDropdownForm';
import QuestionForm from './common/components/QuestionForm';
import QuestionRadioGroupForm from './common/components/QuestionRadioGroupForm';
import QuestionTextForm from './common/components/QuestionTextForm';

type Props = {
  question: Question;
  index: number;
};

const EnqueteCreateQuestionPreviewSwitcher = ({ question, index }: Props) => {
  const isOpenEditor = useSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit.isOpenEditor);
  const mode = useSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit.mode);
  const selectQuestionId = useSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit.selectQuestionId);
  const questions = useSelector((state: RootState) => state.enqueteCreate.questions);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm({ mode: 'onChange' });

  const { isDisabledAddQuestion } = useControlButton(questions);

  const { isLogicTagetQuestion } = useLogicSettingCheck();

  useEffect(() => {
    if (mode === QUESTION_SAVE_MODE.DELETE && question.id === selectQuestionId) {
      // 質問がロジックの設定対象質問に設定されているか？
      if (isLogicTagetQuestion(questions, selectQuestionId)) {
        // ロジックエラーメッセージダイアログを表示する
        dispatch(setLogicErrorMessages([t('enqueteCreate.logic.errorMessage.questionDeleteError')]));
        dispatch(setIsOpenLogicErrorMessageModal(true));
      } else {
        dispatch(saveQuestion({ mode, index: questions.findIndex((qt) => qt.id === selectQuestionId) }));
        dispatch(removeMode());
      }
      dispatch(setMode(''));
    }
    if (mode === QUESTION_SAVE_MODE.COPY && question.id === selectQuestionId) {
      const copyData: Question = {
        id: v1(),
        // 末尾にコピーの文字列を追加する場合はconcatするようにする。（titleの最大長オーバーに注意）
        // title: question.title.concat('(' + t('enqueteAnswerInput.editButton.copy') + ')'),
        // memo:ロジック設定(conditions)はコピー対象としない。
        title: question.title,
        inputType: question.inputType,
        questionItem: question.questionItem.map((newQuestionItem) => ({ ...newQuestionItem, id: v1() })),
        validation: question.validation,
        answerItem: [],
      };
      dispatch(saveQuestion({ mode, copyData }));
      dispatch(removeMode());
    }
  }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

  const switchRender = (type: string) => {
    switch (type) {
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT:
        return isOpenEditor && question.id === selectQuestionId ? (
          <QuestionTextForm existingQuestion={question} methods={methods} />
        ) : (
          <EnqueteAnswerTextForm question={question} index={index} />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXTAREA:
        return isOpenEditor && question.id === selectQuestionId ? (
          <QuestionCommentForm existingQuestion={question} methods={methods} />
        ) : (
          <EnqueteAnswerCommentForm question={question} index={index} />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX:
        return isOpenEditor && question.id === selectQuestionId ? (
          <QuestionCheckboxForm existingQuestion={question} methods={methods} />
        ) : (
          <EnqueteAnswerCheckboxForm question={question} index={index} />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP:
        return isOpenEditor && question.id === selectQuestionId ? (
          <QuestionRadioGroupForm existingQuestion={question} methods={methods} />
        ) : (
          <EnqueteAnswerRadioGroupForm question={question} index={index} />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT:
        return isOpenEditor && question.id === selectQuestionId ? (
          <QuestionDropdownForm existingQuestion={question} methods={methods} />
        ) : (
          <EnqueteAnswerDropdownForm question={question} index={index} />
        );
      default:
        break;
    }
  };
  return isOpenEditor && question.id === selectQuestionId ? (
    <QuestionForm methods={methods} existingQuestion={question} index={index}>
      {switchRender(question.inputType)}
    </QuestionForm>
  ) : (
    <EnqueteAnswerForm question={question} index={index} isDisabledAddQuestion={isDisabledAddQuestion}>
      {switchRender(question.inputType)}
    </EnqueteAnswerForm>
  );
};

export default EnqueteCreateQuestionPreviewSwitcher;
