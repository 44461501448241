import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserEntity } from 'src/api/useUserApi';

export const selectConfirmerModalSlice = createSlice({
  name: 'selectConfirmerModal',
  initialState: {
    comment: null as string | null,
    rowData: [] as UserEntity[],
    selectedUser: null as string | null,
    showConfirm: false as boolean,
  },
  reducers: {
    setComment: (state, action: PayloadAction<string | null>) => {
      state.comment = action.payload;
    },
    setRowData: (state, action: PayloadAction<Array<UserEntity>>) => {
      state.rowData = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<string | null>) => {
      state.selectedUser = action.payload;
    },
    setShowConfirm: (state, action: PayloadAction<boolean>) => {
      state.showConfirm = action.payload;
    },
  },
});
export const { setComment, setRowData, setSelectedUser, setShowConfirm } = selectConfirmerModalSlice.actions;

export default selectConfirmerModalSlice.reducer;
