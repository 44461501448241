import { Box, Grid } from '@mui/material';
import HeaderBreadcrumbs, { HeaderBreadcrumbsProps } from '../HeaderBreadcrumbs';

export interface PageLayoutHeaderProps {
  title: string;
  breadcrumbs: HeaderBreadcrumbsProps;
}

export const PageLayoutHeader: React.FC<PageLayoutHeaderProps> = (props) => {
  return (
    <Box id="page-header" component={'div'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderBreadcrumbs {...props.breadcrumbs} />
        </Grid>
      </Grid>
    </Box>
  );
};
