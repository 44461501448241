import { FormControl } from '@material-ui/core';
import { FormHelperText, MenuItem, Select, SelectProps, Skeleton } from '@mui/material';
import * as React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

interface Props extends SelectProps {
  items: { key: string | number; name: string }[];
  isLoading: boolean;
  helperText?: string;
}

export const SelectControl = (props: UseControllerProps & Props) => {
  const { field } = useController(props);
  const { items, isLoading, helperText } = props;
  const selectProps = props as SelectProps;

  return isLoading ? (
    <Skeleton variant="text" width={400} height={40} />
  ) : (
    <FormControl>
      <Select {...field} {...selectProps}>
        {items.map((item) => (
          <MenuItem value={item.key}>{item.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText error={selectProps.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
