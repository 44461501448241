import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

type RHFonChangeTextFieldProps = {
  control: Control<any>;
  name: string;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  //Optional
  autoComplete?: string;
  autoFocus?: boolean;
  classes?: object;
  color?: TextFieldProps['color'];
  defaultValue?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  FormHelperTextProps?: object;
  helperText?: string;
  id?: string;
  InputLabelProps?: object;
  inputProps?: object;
  InputProps?: object;
  label?: string;
  margin?: 'dense' | 'none' | 'normal';
  maxRows?: number | string;
  minRows?: number | string;
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number | string;
  rules?: object;
  size?: 'small' | 'medium' | undefined;
  sx?: SxProps<Theme>;
  type?:
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'datetime'
    | 'email'
    | 'file'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

  variant?: 'filled' | 'outlined' | 'standard';
};

const RHFonChangeTextField = ({
  control,
  name,
  defaultValue,
  rules,
  helperText,
  rows,
  disabled,
  handleOnChange,
  ...rest
}: RHFonChangeTextFieldProps) => {
  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : ''}
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...rest}
          rows={rows}
          error={fieldState?.error ? true : false}
          inputRef={field.ref}
          disabled={disabled}
          fullWidth
          helperText={fieldState?.error ? fieldState?.error?.message || 'Invalid Input' : helperText}
          onChange={(e) => {
            field.onChange(e);
            handleOnChange(e);
          }}
        />
      )}
    />
  );
};

export default RHFonChangeTextField;
