import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway, portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface RegionEntity {
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
}

interface PortalRegionEntity {
  regionCode: string;
  regionName: string;
  regionEnName: string;
}

const PORTAL_URL = `${portalApi}/master/region`;
const BONSAI_URL = `${apiGateway}/region`;

export const useRegionApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getAll = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalRegionEntity[]>(PORTAL_URL, {
        headers: { Authorization: token },
        params: {
          filter: {
            // memo: 先頭「0」以外のコードは廃止されている（らしい）ため、除外
            //       連携元データの修正もできず、DataInsight側でもうかつに削除できないため、
            //       BONSAI側で除外することとする。
            where: { regionCode: { startsWith: '0' } },
            orderBy: [{ regionCode: 'asc' }],
          },
        },
      });

      return result.data.map<RegionEntity>((master) => ({
        regionCode: master.regionCode,
        regionNameJpn: master.regionName,
        regionNameEng: master.regionEnName,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRegionApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getRegions = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(BONSAI_URL, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRegionApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getAll, getRegions };
};
