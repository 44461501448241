import { Box, Grid, Stack, Typography } from '@mui/material';
import { ColDef, ColGroupDef, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnswerHisotryApi } from 'src/api/useAnswerHistoryApi';
import { useUserApi } from 'src/api/useUserApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import useStatus from 'src/hooks/useStatus';
import { useAppSelector } from 'src/redux/store';
import { formatterDateTime } from 'src/utils/formatDateTime';

interface AnswerStatus {
  userName: string | null;
  userNameEng: string | null;
  status: string;
  statusText: string;
  updatedAt: Date | null;
  updatedUserName?: string | null;
  updatedUserNameEng?: string | null;
}

//日時フォーマット定義
const DEFAULT_DATE_TIME_VALUE = {
  DEFAULT_OPENED_DATE_TIME: '1970-01-01 00:00:00',
  DEFAULT_CLOSED_DATE_TIME: '9999-12-31 23:59:59',
} as const;

export const AnswerInfoDataGrid: React.FC = () => {
  const { t } = useTranslation();
  const { toStringAnswerStatus } = useStatus();

  const historyApi = useAnswerHisotryApi();
  const userApi = useUserApi();

  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);

  // state
  const [rowData, setRowData] = useState<AnswerStatus[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // lang
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  // data table theme
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  //-------------------------------------------------------------
  // Show Translate User Name
  //-------------------------------------------------------------
  const existTranslateParams = (params: ValueGetterParams | ITooltipParams, jpnFieldName: string, engFieldName: string) => {
    const jpnModeData: string = params.data[jpnFieldName] ? params.data[jpnFieldName] : params.data[engFieldName];
    const engModeData: string = params.data[engFieldName] ? params.data[engFieldName] : params.data[jpnFieldName];

    return isLangJpn ? jpnModeData : engModeData;
  };

  //-------------------------------------------------------------
  // Default column define
  //-------------------------------------------------------------
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
  };

  //-------------------------------------------------------------
  // Column define
  //-------------------------------------------------------------
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: t('answerConfirmStatus.answerInfoDataTable.gridColDef.respondent'),
      field: isLangJpn ? 'userName' : 'userNameEng',
      valueGetter: (params) => existTranslateParams(params, 'userName', 'userNameEng'),
      tooltipValueGetter: (params) => existTranslateParams(params, 'userName', 'userNameEng'),
    },
    {
      headerName: t('answerConfirmStatus.answerInfoDataTable.gridColDef.answerStatus'),
      field: 'statusText',
      tooltipValueGetter: (param) => {
        return param.data.statusText;
      },
    },
    {
      headerName: t('answerConfirmStatus.answerInfoDataTable.gridColDef.updateBy'),
      field: isLangJpn ? 'updatedUserName' : 'updatedUserNameEng',
      valueGetter: (params) => existTranslateParams(params, 'updatedUserName', 'updatedUserNameEng'),
      tooltipValueGetter: (params) => existTranslateParams(params, 'updatedUserName', 'updatedUserNameEng'),
    },
    {
      headerName: t('answerConfirmStatus.answerInfoDataTable.gridColDef.updateDate'),
      field: 'updatedAt',
      resizable: false,
      valueFormatter: (param) => {
        const date = param.data.updatedAt || DEFAULT_DATE_TIME_VALUE.DEFAULT_OPENED_DATE_TIME;
        return formatterDateTime(date);
      },
      tooltipValueGetter: (param) => {
        const date = param.data.updatedAt || DEFAULT_DATE_TIME_VALUE.DEFAULT_OPENED_DATE_TIME;
        return formatterDateTime(date);
      },
    },
  ];

  //-------------------------------------------------------------
  // Refresh row data
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    if (enqueteAnswerData.id) {
      setIsLoading(true);

      // Get answer hisotry
      const history = await historyApi.get(enqueteAnswerData.id);

      if (!history) {
        setIsLoading(false);
        return;
      }

      // 更新日時順（降順）
      const sortedList = _.orderBy(
        history,
        (obj) => {
          return obj.updatedAt !== null ? new Date(obj.updatedAt) : null;
        },
        'desc',
      );

      // userId(updatedBy) distinct
      const userIdArray = Array.from(new Set(sortedList.filter((elm) => elm.updatedBy).map((elm) => elm.updatedBy as string)));
      const userEntities = await userApi.getByEmail(userIdArray);

      const answerStatusDatas: AnswerStatus[] = [];
      sortedList.forEach((sortedListData) => {
        const userEntity = userEntities?.find((userEntityData) => userEntityData.email === sortedListData.updatedBy);

        const answerStatusData: AnswerStatus = {
          ...sortedListData,
          statusText: toStringAnswerStatus(sortedListData.status),
          updatedUserName: userEntity?.fullNameJpn ?? '',
          updatedUserNameEng: userEntity?.fullNameEng ?? '',
        };
        answerStatusDatas.push(answerStatusData);
      });

      setRowData(answerStatusDatas);
      setIsLoading(false);
    }
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    refreshRowData();
  }, [currentLang]); //eslint-disable-line

  useEffect(() => {
    refreshRowData();
  }, [enqueteAnswerData]); //eslint-disable-line
  //-------------------------------------------------------------
  // Calc table height
  //-------------------------------------------------------------
  const getTableHeight = () => {
    const someBorderHeight = 2;
    const headerHeight = 48;
    const rowHeight = 41;
    const rowCount = 3;
    return someBorderHeight + headerHeight + rowHeight * rowCount;
  };

  return (
    <Stack>
      {/* header block*/}
      <Grid container alignItems={'center'}>
        <Grid item xs={12}>
          <Stack direction={'row'}>
            <Typography>{t('answerConfirmStatus.answerInfoDataTable.gridTitle')}</Typography>
          </Stack>
        </Grid>
      </Grid>

      {/* data table */}
      <Grid container alignItems={'center'} sx={{ pt: 1 }}>
        <Grid item xs={12} sm={7}>
          <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: getTableHeight(), width: '100%' }}>
            <LoadableComponent isLoading={isLoading}>
              <StyledAgGrid defaultColDef={defaultColDef} coldef={columnDefs} rows={rowData} suppressAutoSize={true} tooltipShowDelay={0} />
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
