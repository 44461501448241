import { ColDef, ColGroupDef } from 'ag-grid-community';
import { DateTime } from 'luxon';
import {
  EnqueteAnswerInputEntity,
  PersonnelSurveyColDef,
  PersonnelSurveyGridFormData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import { languageDataTypes } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { conversionDateWithoutLocalHour } from 'src/utils/formatDateTime';
import { EnqueteAnswerCreateColumn } from '../utils/EnqueteAnswerCreateColumn';

export const useEnqueteAnswerTranslation = () => {
  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const useJudg = useJudgmentCurrentLanguage();

  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  // 取得したSurveyDetailの情報を格納
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);
  // 取得したRespondentRequestの情報を格納
  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);

  // 初版アンケートか判定
  const isFirstSurvey = surveyDetailData.id === 'SD0001';

  const formatColDef = (form: PersonnelSurveyGridFormData | undefined): (ColDef | ColGroupDef)[] | [] => {
    let enqueteAnswerColDef: (ColDef | ColGroupDef)[] = [];
    if (form === undefined) return enqueteAnswerColDef;

    // 列情報解析
    if (undefined !== form.data) {
      form.data.forEach((column: PersonnelSurveyColDef, i: number) => {
        // 初回アンケートとで処理分岐
        if (isFirstSurvey && i === 1) return;

        let _column = { ...column };

        // 言語モードを判定し表示を切り替え
        _column.headerName = useJudg.getText(addYearMonthToGridTitle(_column.headerName as languageDataTypes, i));
        _column.headerTooltip = useJudg.getText(addYearMonthToGridTitle(_column.headerTooltip as languageDataTypes, i));
        // 計画列におけるダークモードの切り替えに対応
        if (_column.field === 'previousYearPlan') {
          if (isLight) {
            _column.cellStyle = {
              backgroundColor: '#F4F6F8',
              textAlign: 'end',
            };
          } else {
            _column.cellStyle = {
              backgroundColor: '#0b0907',
              textAlign: 'end',
            };
          }
        }

        enqueteAnswerColDef.push(EnqueteAnswerCreateColumn(_column, isEnqueteAnswerOpen));
      });
    }

    return enqueteAnswerColDef;
  };

  const formatTabFormData = (formData: PersonnelSurveyGridFormData) => {
    let _tempFormData: PersonnelSurveyGridFormData = { ...formData };

    if (_tempFormData.title !== undefined || typeof _tempFormData.title !== 'string') _tempFormData = conversionLanglageData('title', _tempFormData);
    if (_tempFormData.tabTitle !== undefined || typeof _tempFormData.tabTitle !== 'string')
      _tempFormData = conversionLanglageData('tabTitle', _tempFormData);

    if (_tempFormData.initialValue !== undefined && typeof _tempFormData.initialValue !== 'string') {
      _tempFormData.initialValue = [];
      _tempFormData.initialValue = formData.initialValue?.map((data: any) => {
        return formatRowData(data);
      });
    }

    return _tempFormData;
  };

  const formatRowData = (rowData: any): EnqueteAnswerInputEntity => {
    let _data = { ...rowData };

    _data = conversionLanglageData('inputItem', _data);

    return _data;
  };

  //指定プロパティを言語変換
  const conversionLanglageData = (propertyName: string, data: any) => {
    if (data[propertyName] === undefined) return '';

    const text = useJudg.getText(data[propertyName] as languageDataTypes);
    delete data[propertyName];
    data[propertyName] = text;

    return data;
  };

  //グリッドタイトルに年月を追加（日英）（初回アンケートと挙動を変更）
  const addYearMonthToGridTitle = (languageData: languageDataTypes, i: number) => {
    if (respondentRequestData.targetDate === null) return languageData;
    if (i === 0) return languageData;

    //仮変数
    let tempLanguageData: languageDataTypes = { ja: '', en: '' };

    const targetDateWithLocalHour: Date = conversionDateWithoutLocalHour(respondentRequestData.targetDate);
    const jpnFormat: string = 'yy/M';
    const engFormat: string = 'yy';

    if (i === 1) {
      tempLanguageData.ja = DateTime.fromJSDate(targetDateWithLocalHour).toFormat(jpnFormat) + ' ' + languageData.ja;
      tempLanguageData.en = 'FY' + DateTime.fromJSDate(targetDateWithLocalHour).minus({ years: 1 }).toFormat(engFormat) + ' ' + languageData.en;
    }

    if (i === 2) {
      tempLanguageData.ja = DateTime.fromJSDate(targetDateWithLocalHour).toFormat(jpnFormat) + ' ' + languageData.ja;
      tempLanguageData.en = 'FY' + DateTime.fromJSDate(targetDateWithLocalHour).minus({ years: 1 }).toFormat(engFormat) + ' ' + languageData.en;
    }

    if (i === 3) {
      tempLanguageData.ja = DateTime.fromJSDate(targetDateWithLocalHour).plus({ years: 1 }).toFormat(jpnFormat) + ' ' + languageData.ja;
      tempLanguageData.en = 'FY' + DateTime.fromJSDate(targetDateWithLocalHour).toFormat(engFormat) + ' ' + languageData.en;
    }

    if (i === 4) {
      tempLanguageData.ja = DateTime.fromJSDate(targetDateWithLocalHour).plus({ years: 3 }).toFormat(jpnFormat) + ' ' + languageData.ja;
      tempLanguageData.en = 'FY' + DateTime.fromJSDate(targetDateWithLocalHour).plus({ years: 2 }).toFormat(engFormat) + ' ' + languageData.en;
    }

    return tempLanguageData;
  };

  return { formatColDef, formatTabFormData, formatRowData };
};
