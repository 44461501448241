import { useCallback, useEffect, useState } from 'react';
import { AggregateDetail, AggregateRow, ExchangeRateRow, useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';

export const useSummaryAggregate = (respondentRequestId?: string) => {
  const [aggregateRows, setAggregateRows] = useState<AggregateRow[]>([]);
  const [previousYear, setPreviousYear] = useState<string>('');
  const [currentYear, setCurrentYear] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [aggregateDetailMap, setAggregateDetailMap] = useState<{ [key: string]: AggregateDetail }>({});
  const [exchangeRateRows, setExchangeRateRows] = useState<ExchangeRateRow[]>([]);
  const { getTypeSeriesAggregate } = useEnqueteStatusPageApi();

  const fetch = useCallback(
    async (respondentRequestId?: string) => {
      try {
        setIsLoading(true);
        const result = await getTypeSeriesAggregate(respondentRequestId);
        if (result) {
          setPreviousYear(result.previousYear);
          setCurrentYear(result.currentYear);
          setAggregateRows(result.aggregateRows);
          setAggregateDetailMap(result.aggregateDetailMap);
          setExchangeRateRows(result.exchangeRateRows);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [getTypeSeriesAggregate],
  );

  useEffect(() => {
    respondentRequestId && fetch(respondentRequestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    aggregateRows,
    previousYear,
    currentYear,
    isLoading,
    aggregateDetailMap,
    exchangeRateRows,
  };
};
