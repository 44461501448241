import { OverviewEnquete } from 'src/@types/overviewEnquete';
import { OverviewSurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { setEnqueteData, setIsDeletedCustomer } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { SelectedRowType } from './useGetSelectedRow';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getSelectedRowElement: () => SelectedRowType[];
  setIsAlertModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChangeCustomer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useTypeOverviewSurveyClickHandle = (props: Props) => {
  const { setIsOpen, getSelectedRowElement, setIsAlertModalOpen, setIsChangeCustomer } = props;
  const dispatch = useAppDispatch();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const setCustomerCode = (enqueteData.surveyDetail.surveyFormData?.formData?.tabData[0] as OverviewEnquete)?.customerCode as string;

  const overviewSurveyOnClick = async () => {
    // 追加されたデータを取得
    const searchElement = getSelectedRowElement()[0]; // 取引先未選択時は追加ボタンが非活性のため、getSelectedRowElement()から空配列が返ることはない想定
    const surveyDetail = enqueteData.surveyDetail ? Object.assign(enqueteData.surveyDetail) : {};
    const overviewSurveyFormData = surveyDetail?.surveyFormData?.formData as OverviewSurveyFormData;
    const overviewTabData = overviewSurveyFormData.tabData[0] as OverviewEnquete;

    // TODO::リファクタ対象
    // 初期値の作成はここでやらずに外出しすべきである。
    // また、createのはずなのにanswer配下にあるのは違和感しかないのと、追うのが大変になるため、ディレクトリ構成も見直すべきかもしれない
    // overviewTabDataを展開してからの各パラメータの設定は処理として無駄があるので書き方の見直しが必要
    const tabData: OverviewEnquete = {
      ...overviewTabData,
      purchaseFixedAmount: overviewTabData?.purchaseFixedAmount ?? { isAnswerTarget: false, answer: 0 },
      purchaseFixedQuestions: overviewTabData?.purchaseFixedQuestions ?? {
        product: {
          isAnswerTarget: false,
          answer: '',
        },
        mainCustomer: {
          isAnswerTarget: false,
          answer: '',
        },
        commercialFlow: {
          isAnswerTarget: false,
          answer: '',
        },
      },
      salesFixedAmount: overviewTabData?.salesFixedAmount ?? { isAnswerTarget: false, answer: 0 },
      salesFixedQuestions: overviewTabData?.salesFixedQuestions ?? {
        product: {
          isAnswerTarget: false,
          answer: '',
        },
        commercialFlow: {
          isAnswerTarget: false,
          answer: '',
        },
      },
      otherFixedTopic: overviewTabData?.otherFixedTopic ?? { answer: '' },
      purchaseAdditionalQuestions: overviewTabData?.purchaseAdditionalQuestions ?? [],
      salesAdditionalQuestions: overviewTabData?.salesAdditionalQuestions ?? [],
      otherAdditionalQuestions: overviewTabData?.otherAdditionalQuestions ?? [],
    };
    const form: OverviewSurveyFormData = {
      pastRespondentRequestId: overviewSurveyFormData?.pastRespondentRequestId,
      tabData: [
        {
          ...tabData,
          parentCustomerCode: searchElement ? searchElement.parentCustomerCode : '',
          parentCustomerNameJpn: searchElement ? searchElement.parentCustomerName : '',
          parentCustomerNameEng: searchElement ? searchElement.parentCustomerNameEng : '',
          customerCode: searchElement ? searchElement.customerCode : '',
          customerNameJpn: searchElement ? searchElement.customerName : '',
          customerNameEng: searchElement ? searchElement.customerNameEng : '',
        },
      ],
    };
    dispatch(
      setEnqueteData({
        ...enqueteData,
        surveyDetail: {
          ...enqueteData.surveyDetail,
          surveyFormData: { ...enqueteData.surveyDetail?.surveyFormData, formData: form },
          surveyHeader: enqueteData.surveyDetail?.surveyHeader,
        },
      }),
    );
    // 取引先が変更されているかを検知
    setIsChangeCustomer(overviewTabData?.customerCode !== searchElement?.customerCode);
    setIsOpen(false);

    if (setCustomerCode && searchElement) {
      // 取引先追加ダイアログでは削除済みの取引先を選択できないため、削除済みフラグをリセット
      dispatch(setIsDeletedCustomer(false));
    }
  };

  // 取引先概況表経由で追加ボタン押された際の挙動
  const validOverviewOnClick = () => {
    if (setCustomerCode) {
      setIsAlertModalOpen(true);
    } else {
      overviewSurveyOnClick();
    }
  };

  return { overviewSurveyOnClick, validOverviewOnClick };
};
