import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { DISCLOSURE_LEVEL } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { formatterDateTime } from 'src/utils/formatDateTime';

const useEnquetePageCommonGrid = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { currentLang } = useLocales();
  const lang = currentLang.value === 'ja' ? 'jpn' : 'eng';
  const { exportCustomer } = useSurveyEditApi();
  //依頼期間出力
  const getRequestStatusTerm = (enqueteData: RespondentRequestEntity) => {
    if (enqueteData.openedAt === '') {
      return '';
    }
    return `${formatterDateTime(enqueteData.openedAt)} 〜 ${formatterDateTime(enqueteData.closedAt)}`;
  };

  //Data-Insight公開区分出力
  const getDisclosureLevel = (enqueteData: RespondentRequestEntity) => {
    if (enqueteData.surveyDetail.disclosureLevel === DISCLOSURE_LEVEL.INTERNAL) {
      return t('enqueteGeneralCommon.disclosureLevelInternal');
    } else if (enqueteData.surveyDetail.disclosureLevel === DISCLOSURE_LEVEL.PUBLIC) {
      return t('enqueteGeneralCommon.disclosureLevelPublic');
    } else {
      return t('enqueteGeneralCommon.disclosureLevelPrivate');
    }
  };

  const onClickExportCustomer = async () => {
    if (params.id) {
      await exportCustomer(params.id, lang);
    }
  };

  return {
    getRequestStatusTerm,
    getDisclosureLevel,
    onClickExportCustomer,
  };
};

export default useEnquetePageCommonGrid;
