import { BonsaiRole, Role } from 'src/@types/role';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import CustomerManagementListPage from '../pages/CustomerManagementListPage';

//アクセス可能なロールを定義
const accessibleRoleList: Role[] = [BonsaiRole.ADMIN, BonsaiRole.VIEWER_ADMIN, BonsaiRole.CUSTOMER_EDITOR];

export const customerManagementRoute = {
  path: 'customer-management',
  children: [
    {
      path: '',
      element: (
        <RoleBasedGuard accessibleRoles={accessibleRoleList}>
          <CustomerManagementListPage />
        </RoleBasedGuard>
      ),
    },
  ],
};
