import { ChevronRight, ExpandMore } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { useListToggle } from 'src/features/general/enquete-answer/components/tab-containers/common/components/previous-answers-list/hooks/useListToggle';

const EnqueteLogiccDescriptionGrid: React.FC = () => {
  const { isOpen, handleClick } = useListToggle();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12} sx={{ mr: 3, width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
          {t('enqueteCreate.logic.description')}
          <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} onClick={handleClick} />
          {isOpen ? <ExpandMore onClick={handleClick} /> : <ChevronRight onClick={handleClick} />}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mr: 3, width: '100%', textAlign: 'left', display: isOpen ? 'flex' : 'none' }}>
        <Label
          role="message"
          variant="ghost"
          sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
          {t('enqueteCreate.logic.descriptionDetail')}
        </Label>
      </Grid>
    </>
  );
};

export default EnqueteLogiccDescriptionGrid;
