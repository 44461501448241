import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../hooks/useEnqueteAnswerGrid';
import ConfirmationContinueDialog from '../confirm-continue/components/ConfirmationContinueDialog';
import { ConfirmationCommentsModal } from './components/ConfimationCommentsModal';

export function ConfirmationCommentsModalLayout() {
  const { setConfirmationCommentsModalMode } = useEnqueteAnswerGrid();
  const isOpenConfirmationCommentsModal = useAppSelector((state) => state.enqueteAnswer.isConfirmationCommentsModalOpen);
  const { t } = useTranslation();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [edited, setEdited] = useState(false);
  //ローディング
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // モーダル閉じる処理
  function modelClose() {
    setEdited(false);
    setConfirmationCommentsModalMode(false);
  }

  return (
    <>
      <ConfirmationContinueDialog
        title={''}
        message={
          t('enqueteAnswerInput.confirmationCommentsModal.continueDialog.message1') +
          '\n' +
          t('enqueteAnswerInput.confirmationCommentsModal.continueDialog.message2')
        }
        discardLabel={t('button.no')}
        continueLabel={t('button.yes')}
        open={isOpenConfirmationCommentsModal}
        onDiscard={modelClose}
      />

      <ConfirmationCommentsModal
        title={t('enqueteAnswerInput.confirmationCommentsModal.title')}
        open={isOpenConfirmationCommentsModal}
        onClose={setConfirmationCommentsModalMode}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        edited={edited}
        setEdited={setEdited}
      />

      {/** ローディング */}
      <StyledLoading isOpen={isLoading} />
    </>
  );
}
