import { HotTable } from '@handsontable/react';
import { RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { TableData } from 'src/@types/generalEnquete';
import { setSettings, TotalMainTablesType } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { useSurveyTables } from 'src/hooks/useSurveyTables';
import { useSurveyTableAnswerDataService } from '../components/tab-containers/type-general-table/components/tabs/table/hooks/useSurveyTableAnswerDataService';

export const useTableAnswerSave = (hotRef: RefObject<HotTable>[], hotRefForSubTable: RefObject<HotTable>[]) => {
  const dispatch = useDispatch();
  const { formatAnswerMainTableData } = useSurveyTables();
  const { formatSurveyAnswerData } = useSurveyTableAnswerDataService();

  const saveAllSettings = () => {
    // HotTableインスタンスからデータを抽出
    const currentTotalMainTables: TotalMainTablesType[] = [];
    for (let i = 0; i < hotRef.length; i++) {
      if (hotRef[i].current === null) break;
      currentTotalMainTables.push(formatAnswerMainTableData(hotRef[i]));
    }

    // サブ表定義
    let subDatas: TableData[] = [];
    hotRefForSubTable.forEach((subTable) => {
      if (subTable.current) {
        subDatas.push(subTable.current?.hotInstance?.getSourceData() as TableData);
      }
    });

    // 現状をストアに格納
    dispatch(
      setSettings({
        subDatas, // サブ表格納
        totalMainTables: currentTotalMainTables, // メイン表格納
      }),
    );

    // データ返却
    const requestSurveyAnswerData = formatSurveyAnswerData(currentTotalMainTables);
    return { ...requestSurveyAnswerData, subDatas };
  };

  return {
    saveAllSettings,
  };
};
