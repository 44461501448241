import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCommentsApi } from 'src/api/getCommentsApi';

export const fetchComments = createAsyncThunk('enquete-thread/detail', async (id: string | undefined, thunkAPI) => {
  if (id === undefined) {
    return undefined;
  }
  const { getComments } = getCommentsApi();
  try {
    const comments = await getComments(id);
    return comments;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
});
