import { Grid, Typography } from '@mui/material';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';

export interface NoteFieldProps {
  methods: UseFormReturn<FieldValues, any>;
  name: string;
  displayName: string;
  defaultValue: string | null;
  size: number;
  rowSize: number;
}

const NoteField: React.FC<NoteFieldProps> = (props) => {
  const { methods, name, displayName, defaultValue, size, rowSize } = props;
  return (
    <>
      <Grid item xs={2}>
        <Typography variant="subtitle1"> {displayName}</Typography>
      </Grid>

      <Grid item xs={size}>
        <RHFTextField
          name={name}
          defaultValue={defaultValue}
          control={methods.control}
          size="small"
          variant="outlined"
          multiline
          fullWidth
          rows={rowSize}
        />
      </Grid>
    </>
  );
};

export default NoteField;
