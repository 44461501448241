import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { ENQUETE_CREATE_PATH } from 'src/features/general/enquete-create/routes/path';
import { enqueteCreateSlice } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { initializeRespondentSetting } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

const useEnqueteCreateTopActionButtonGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const surveyEditApi = useSurveyEditApi();
  const { getRequestAnswerList } = useEnqueteCreateGrid();
  const [inProgress, setInProgress] = useState<boolean>(false);

  // RespondentRequestID取得
  const { id } = useParams();

  const isOpenSelectAndExportModal = useAppSelector((state) => state.enqueteCreate.isOpenSelectAndExportModal);
  const openSelectAddressModal = useAppSelector((state) => state.enqueteCreate.openSelectAddressModal);
  const tempSelectedRespondentList = useAppSelector((state) => state.respondentSetting.tempSelectedRespondentList);
  const tempSelectedRespondentCount = useAppSelector((state) => state.respondentSetting.tempSelectedRespondentCount);
  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData);
  const selectedRowCount = tempSelectedRespondentCount;

  const startProcess = () => setInProgress(true);
  const stopProcess = () => setInProgress(false);

  const setIsOpenSelectAndExportModal = (isOpen: boolean) => {
    dispatch(enqueteCreateSlice.actions.setOpenSelectAndExportModal(isOpen));
  };
  const setOpenSelectAddressModal = (isOpen: boolean) => {
    dispatch(enqueteCreateSlice.actions.setOpenSelectAddressModal(isOpen));
  };

  const goToResponseRequestPage = () => {
    startProcess();
    navigate(`${ENQUETE_CREATE_PATH.SEND_RESPONSE_REQUEST}/${id}`, { state: { enqueteData, tempSelectedRespondentList } });
    stopProcess();
  };

  const onClickBulkDelete = async () => {
    if (id) {
      startProcess();
      const respondentPersonIds: string[] = [];
      for (let respondent of Object.values(tempSelectedRespondentList)) {
        respondentPersonIds.push(respondent.respondentPersonId);
      }
      await surveyEditApi.bulkDeleteRespondent(id, respondentPersonIds);
      // memo: 削除後、データの再取得 及び ステートリセットにより、画面をリフレッシュ
      await getRequestAnswerList(id);
      dispatch(initializeRespondentSetting());
    }
    stopProcess();
  };

  return {
    id,
    inProgress,
    startProcess,
    stopProcess,
    selectedRowCount,
    isOpenSelectAndExportModal,
    setIsOpenSelectAndExportModal,
    openSelectAddressModal,
    setOpenSelectAddressModal,
    goToResponseRequestPage,
    onClickBulkDelete,
  };
};

export default useEnqueteCreateTopActionButtonGroup;
