export enum BonsaiRole {
  ADMIN = 'bonsai-admin',
  VIEWER_ADMIN = 'bonsai-viewer-admin',
  CREATOR = 'bonsai-creator',
  ORGANIZATION_EDITOR = 'bonsai-organization-editor',
  CUSTOMER_EDITOR = 'bonsai-customer-editor',
  OVERVIEW_CREATOR = 'bonsai-overview-creator',
  ANSWER_SERIES = 'bonsai-answer-series',
}
export type Role = BonsaiRole;
