import { EnqueteConfirmListPage } from '../pages/EnqueteConfirmListPage';

//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteConfirmRoute = {
  path: 'enquete-confirm',
  children: [
    {
      path: '',
      element: <EnqueteConfirmListPage />,
    },
  ],
};
