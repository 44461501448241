import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter, useLocation } from 'react-router-dom';
// layouts
// guards
import AuthGuardLayout from '../components/layouts/AuthGuardLayout';
import GuestGuard from '../guards/GuestGuard';
// components
import ScrollToTop from 'src/components/ScrollToTop';
import LogoOnlyLayout from 'src/components/layouts/LogoOnlyLayout';
import Settings from 'src/components/settings';
import { RootErrorBoundary } from 'src/features/errors/RootErrorBoundary';
import { generalRoute } from 'src/features/general/routes/generalRoute';
import { historyRoute } from 'src/features/history/routes/historyRoute';
import { manageRoute } from 'src/features/manage/routes/manageRoute';
import { overviewRoute } from 'src/features/overview/routes';
import LoadingScreen from '../components/LoadingScreen';
import { INITIAL_PATH } from '../constants';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import('src/features/authentication/Login')));

export const router = createBrowserRouter([
  {
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: '/',
        element: (
          <>
            <AuthGuardLayout />,
            <Settings />
            <ScrollToTop />
          </>
        ),
        children: [{ path: '/', element: <Navigate to={INITIAL_PATH} replace /> }],
      },
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
          },
          { path: 'reset-password', element: Loadable, lazy: () => import('src/features/authentication/ResetPassword') },
          { path: 'verify', element: Loadable, lazy: () => import('src/features/authentication/VerifyCode') },
        ],
      },
      // Feature Routes
      overviewRoute,
      generalRoute,
      historyRoute,
      manageRoute,
      {
        path: '*',
        element: <LogoOnlyLayout />,
        children: [
          { path: '500', element: Loadable, lazy: () => import('src/features/errors/Page500') },
          { path: '404', element: Loadable, lazy: () => import('src/features/errors/Page404') },
          { path: '*', element: <Navigate to="/404" replace /> },
        ],
      },
    ],
  },
]);
