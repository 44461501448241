import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';
import { Autocomplete, Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, Skeleton, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ComplementaryOrganizationEntity, CountryEntity, OmitField } from 'src/api/useComplementaryOrganizationApi';
import { OrganizationEntity, useOrganizationApi } from 'src/api/useOrganizationApi';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import * as yup from 'yup';
import { useComplementaryOrganizationDetail } from '../hooks/useComplementaryOrganizationDetail';
import { useOrganizationDetailSave } from '../hooks/useOrganizationDetailSave';
import { SelectControl } from './SelectControl';
import { TextFieldControl } from './TextFieldControl';

export type Organization = { key: string; entity: Omit<OrganizationEntity, OmitField> };
export type Country = { key: string | null; entity: CountryEntity };

interface Props {
  setTitle: (sufix: string) => void;
}

export const ComplementaryOrganizationDetail: React.FC<Props> = (props) => {
  const { setTitle } = props;
  const { t } = useTranslation();

  const hookDetail = useComplementaryOrganizationDetail();
  const organizationApi = useOrganizationApi();

  const { id } = useParams();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const { currentLang } = useLocales();
  const regions = useAppSelector((state) => state.complementaryOrganization.regionData);
  const ranks = useAppSelector((state) => state.complementaryOrganization.rankData);

  const companyType = query.get('companytype');

  const [targetOrganizationList, setTargetOrganizationList] = useState<Organization[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [targetCountryList, setTargetCountryList] = useState<Country[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const [tmpCountryCode, setTmpCountryCode] = useState<string>('');

  const [inputOrganization, setInputOrganization] = useState<string>('');
  const [inputCountry, setInputCountry] = useState<string>('');
  const ORG_LEVEL_TYPE = ['COMPANY', 'DIVISION', 'SBU', 'DEPARTMENT', 'GROUP'] as const;
  type TOrgLevelType = typeof ORG_LEVEL_TYPE[number];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDummyCode, setIsDummyCode] = useState<boolean>(true);
  const [isGroupCompany, setIsGroupCompany] = useState<boolean>(false);

  const orgLevelTypeSelectItems = ORG_LEVEL_TYPE.filter((x) => x !== 'COMPANY').map((x) => {
    return { key: x, name: t(`complementaryOrganizationDetail.orgLevelType.${x.toLowerCase()}`) };
  });
  const managedOrgLevelTypeSelectItems = ORG_LEVEL_TYPE.filter((x) => x !== 'COMPANY').map((x) => {
    return { key: x, name: t(`complementaryOrganizationDetail.managedOrgLevelType.${x.toLowerCase()}`) };
  });

  const regionsItems =
    Object.keys(regions).length === 0
      ? []
      : regions.map((x) => {
          return { key: x.regionCode || '', name: currentLang.value === 'ja' ? x.regionNameJpn || '' : x.regionNameEng || '' };
        });

  const rankItems =
    Object.keys(ranks).length === 0
      ? []
      : ranks.map((x) => {
          return { key: x.rank || '', name: x.rank || '' };
        });
  /**
   * ここでフォームのバリデーション仕様を定義する
   */
  const YupObj = {
    code: isDummyCode ? yup.string() : yup.string().required(t('complementaryOrganizationDetail.error.required')),
    jpn: yup.string().required(t('complementaryOrganizationDetail.error.required')),
    eng: yup.string().required(t('complementaryOrganizationDetail.error.required')),
    upperOrg: yup.string().required(t('complementaryOrganizationDetail.error.required')).nullable(),
    regionCode: yup.string().required(t('complementaryOrganizationDetail.error.required')).nullable(),
    country: yup.string().required(t('complementaryOrganizationDetail.error.required')).nullable(),
  };
  const schema = yup.object(YupObj).required();

  //React-Hook-Form
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      rank: '',
      managedOrgLevelType: '',
      orgLevelType: '',
      regionCode: '',
      country: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(schema) as Resolver,
  });

  const { onSubmit } = useOrganizationDetailSave(getValues, targetOrganizationList, targetCountryList);

  //表示用文字列作成
  const makeDisplayOrgData = useCallback(
    (org: Omit<OrganizationEntity, OmitField>) => {
      let strJpn = '';
      let strEng = '';

      const ttc = getValues('ttc').find((x: OrganizationEntity) => x.companyCode === 'S500') || {};

      if (ttc.companyCode) {
        strJpn += `${ttc.companyCode} : ${ttc.companyNameJpn}`;
        strEng += `${ttc.companyCode} : ${ttc.companyNameEng}`;
      }

      if (org.divisionCode) {
        strJpn += ` - ${org.divisionCode} : ${org.divisionNameJpn}`;
        strEng += ` - ${org.divisionCode} : ${org.divisionNameEng}`;
      }
      if (org.sbuCode) {
        strJpn += ` - ${org.sbuCode} : ${org.sbuNameJpn}`;
        strEng += ` - ${org.sbuCode} : ${org.sbuNameEng}`;
      }
      if (org.departmentCode) {
        strJpn += ` - ${org.departmentCode} : ${org.departmentNameJpn}`;
        strEng += ` - ${org.departmentCode} : ${org.departmentNameEng}`;
      }
      if (org.groupCode) {
        strJpn += ` - ${org.groupCode} : ${org.groupNameJpn}`;
        strEng += ` - ${org.groupCode} : ${org.groupNameEng}`;
      }
      return currentLang.value === 'ja' ? { key: strJpn, entity: org } : { key: strEng, entity: org };
    },
    [currentLang.value, getValues],
  );

  //表示用文字列作成
  const makeDisplayCountryData = useCallback(
    (country: CountryEntity) => {
      const strJpn = `${country.countryNameEng} (${country.countryNameJpn})`;
      const strEng = country.countryNameEng;

      return currentLang.value === 'ja' ? { key: strJpn, entity: country } : { key: strEng, entity: country };
    },
    [currentLang.value],
  );

  // 組織データ取得
  const getOrganizations = useCallback(
    async (key: TOrgLevelType) => {
      setIsLoading(true);
      // 組織情報の取得
      const orgs = await organizationApi.getOrgLevelType(key);
      if (!orgs) return setTargetOrganizationList([]);

      // 表示用文字列作成
      const list = orgs.map((org: OrganizationEntity) => makeDisplayOrgData(org));
      // 保存
      setTargetOrganizationList(list);
      setOptions(list.map((x: any) => x.key));
      setValue('upperOrg', '');
      setInputOrganization('');
      setIsLoading(false);
    },
    [makeDisplayOrgData, organizationApi, setValue],
  );

  const changeUpperOrgLevelType = useCallback((_orgLevelType: TOrgLevelType) => {
    if (_orgLevelType === 'DIVISION') {
      return 'COMPANY';
    } else if (_orgLevelType === 'SBU') {
      return 'DIVISION';
    } else if (_orgLevelType === 'DEPARTMENT') {
      return 'SBU';
    } else if (_orgLevelType === 'GROUP') {
      return 'DEPARTMENT';
    }
    return 'COMPANY';
  }, []);

  const convertManagedOrgLevelType = useCallback((data: ComplementaryOrganizationEntity) => {
    if (data.groupCode) return 'GROUP';
    if (data.departmentCode) return 'DEPARTMENT';
    if (data.sbuCode) return 'SBU';
    return 'DIVISION';
  }, []);

  const changeCountry = useCallback(
    async (regionCode: string) => {
      const results = (await hookDetail.getCountriesByRegionCode(regionCode)) || [];
      const countries = results.map((country) => makeDisplayCountryData(country));
      setTargetCountryList(countries);
      const options = countries.map((x: any) => x.key) || [];
      setCountriesOptions(options);
    },
    [hookDetail, makeDisplayCountryData],
  );

  // 詳細データ取得
  const getDetail = useCallback(
    async (id: string) => {
      const data = await hookDetail.getDetail(id);

      if (data !== undefined) {
        const _orgLevelType = data.orgLevelType || 'DIVISION';
        setIsGroupCompany(_orgLevelType === 'COMPANY');

        if (_orgLevelType === 'COMPANY') {
          setValue('code', data.companyCode);
          setValue('jpn', data.companyNameJpn);
          setValue('eng', data.companyNameEng);
        } else if (_orgLevelType === 'DIVISION') {
          setValue('code', data.divisionCode);
          setValue('jpn', data.divisionNameJpn);
          setValue('eng', data.divisionNameEng);
        } else if (_orgLevelType === 'SBU') {
          setValue('code', data.sbuCode);
          setValue('jpn', data.sbuNameJpn);
          setValue('eng', data.sbuNameEng);
        } else if (_orgLevelType === 'DEPARTMENT') {
          setValue('code', data.departmentCode);
          setValue('jpn', data.departmentNameJpn);
          setValue('eng', data.departmentNameEng);
        } else if (_orgLevelType === 'GROUP') {
          setValue('code', data.groupCode);
          setValue('jpn', data.groupNameJpn);
          setValue('eng', data.groupNameEng);
        }
        setValue('rank', data.rank);
        setValue('regionCode', data.regionCode);

        changeCountry(data.regionCode || '');

        const targetCountry: CountryEntity = {
          countryCode: data.countryCode,
          countryNameJpn: data.countryNameJpn,
          countryNameEng: data.countryNameEng,
          regionCode: data.regionCode,
        };

        const selectedCountry = makeDisplayCountryData(targetCountry).key;
        setValue('country', selectedCountry);

        setValue('orgLevelType', _orgLevelType);

        const managedOrgLevelType = convertManagedOrgLevelType(data);

        setValue('managedOrgLevelType', managedOrgLevelType);

        if (_orgLevelType === 'COMPANY') {
          await getOrganizations(managedOrgLevelType);
        } else {
          await getOrganizations(changeUpperOrgLevelType(_orgLevelType));
        }

        const target =
          _orgLevelType === 'COMPANY'
            ? { ...data }
            : {
                ...data,
                divisionCode: _orgLevelType === 'DIVISION' ? '' : data.divisionCode,
                sbuCode: _orgLevelType === 'SBU' ? '' : data.sbuCode,
                departmentCode: _orgLevelType === 'DEPARTMENT' ? '' : data.departmentCode,
                groupCode: '',
              };

        const selected = makeDisplayOrgData(target);
        setValue('upperOrg', selected.key);
        setInputOrganization(selected.key);
      }
    },
    [
      changeCountry,
      changeUpperOrgLevelType,
      convertManagedOrgLevelType,
      getOrganizations,
      hookDetail,
      makeDisplayCountryData,
      makeDisplayOrgData,
      setValue,
    ],
  );

  // 初回起動
  useEffect(() => {
    const init = async () => {
      await hookDetail.getRegions();
      await hookDetail.getRanks();
      const ttc = await hookDetail.getTTC();
      setValue('ttc', ttc);
      if (id) {
        await getDetail(id);
      } else {
        setIsGroupCompany(companyType === 'group');
        const defaultOrgLevelType = companyType === 'group' ? 'COMPANY' : 'DIVISION';
        setValue('orgLevelType', defaultOrgLevelType);
        const defaultManagedOrgLevelType = 'SBU';
        setValue('managedOrgLevelType', defaultManagedOrgLevelType);
        getOrganizations(companyType === 'group' ? defaultManagedOrgLevelType : changeUpperOrgLevelType(defaultOrgLevelType));
      }
    };
    setIsLoading(true);
    init();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setTitle(
      t('complementaryOrganizationDetail.pageTitle') +
        (isGroupCompany ? t('complementaryOrganizationList.companyType.group') : t('complementaryOrganizationList.companyType.ttc')),
    );
    changeCountry(getValues('regionCode'));
    const country = targetCountryList.find((x: any) => x.key === getValues('country'));
    if (country) {
      setTmpCountryCode(country.entity.countryCode ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang.value, isGroupCompany, setTitle, t]);

  useEffect(() => {
    const newCountry = targetCountryList.find((x: Country) => x.entity.countryCode === tmpCountryCode);
    if (newCountry) {
      setValue('country', newCountry.key);
    }
  }, [setValue, targetCountryList, tmpCountryCode]);

  const changeOrgLevelType = useCallback(
    (e, name) => {
      setValue(name, e.target.value as TOrgLevelType);
      const filter = name === 'orgLevelType' ? changeUpperOrgLevelType(e.target.value) : e.target.value;
      getOrganizations(filter);
    },
    [changeUpperOrgLevelType, getOrganizations, setValue],
  );

  const handleChangeCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue('code', '');
      setIsDummyCode(event.target.checked);
    },
    [setValue],
  );

  // 組織種別のコンポーネント
  const OrgleveltypeSelect = useMemo(() => {
    const name = isGroupCompany ? 'managedOrgLevelType' : 'orgLevelType';
    return (
      <FormControl sx={{ width: '50%' }} size="small">
        <InputLabel id="select-orgLevelType">{t(`complementaryOrganizationDetail.helpText.${name}`)}</InputLabel>
        <SelectControl
          label-id="select-orgLevelType"
          label={t(`complementaryOrganizationDetail.helpText.${name}`)}
          control={control}
          name={name}
          items={isGroupCompany ? managedOrgLevelTypeSelectItems : orgLevelTypeSelectItems}
          disabled={Boolean(id)}
          onChange={(e) => {
            changeOrgLevelType(e, name);
          }}
          isLoading={isLoading}
        />
      </FormControl>
    );
  }, [isGroupCompany, t, control, managedOrgLevelTypeSelectItems, orgLevelTypeSelectItems, id, isLoading, changeOrgLevelType]);

  return (
    <Container maxWidth={false}>
      <form
        onSubmit={handleSubmit(() => {
          onSubmit();
        })}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {OrgleveltypeSelect}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <Controller
                name="upperOrg"
                control={control}
                render={({ field }) =>
                  isLoading ? (
                    <Skeleton variant="text" width={400} height={40} />
                  ) : (
                    <Autocomplete
                      {...field}
                      inputValue={inputOrganization}
                      options={options}
                      onChange={(event: any, newValue: string | null) => {
                        setValue('upperOrg', newValue);
                      }}
                      onInputChange={(e, newInputValue, reason) => {
                        if (reason === 'reset' && !newInputValue) {
                          return;
                        }
                        setInputOrganization(newInputValue);
                      }}
                      disabled={Boolean(id)}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            size="small"
                            label={
                              isGroupCompany
                                ? t(`complementaryOrganizationDetail.helpText.managedOrg`)
                                : t(`complementaryOrganizationDetail.helpText.upperOrg`)
                            }
                            error={!!errors.upperOrg}
                            helperText={errors.upperOrg?.message || ''}
                          />
                        </>
                      )}
                    />
                  )
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextFieldControl
              control={control}
              size="small"
              variant="outlined"
              fullWidth
              name="code"
              label={t(`complementaryOrganizationDetail.label.${getValues('orgLevelType')?.toLowerCase() || 'division'}Code`)}
              defaultValue=""
              disabled={isLoading || Boolean(id) || isDummyCode}
              error={!!errors.code}
              helperText={errors.code?.message || ''}
            />
            {id ? undefined : (
              <FormControlLabel
                control={<Checkbox checked={isDummyCode} onChange={handleChangeCheckBox} />}
                label={t('complementaryOrganizationDetail.helpText.useDummyCode')}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextFieldControl
              control={control}
              size="small"
              variant="outlined"
              fullWidth
              defaultValue=""
              name="jpn"
              label={t(`complementaryOrganizationDetail.label.${getValues('orgLevelType')?.toLowerCase() || 'division'}NameJpn`)}
              disabled={isLoading}
              error={!!errors.jpn}
              helperText={errors.jpn?.message || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldControl
              control={control}
              size="small"
              variant="outlined"
              fullWidth
              defaultValue=""
              name="eng"
              label={t(`complementaryOrganizationDetail.label.${getValues('orgLevelType')?.toLowerCase() || 'division'}NameEng`)}
              disabled={isLoading}
              error={!!errors.eng}
              helperText={errors.eng?.message || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-region">{t('common.region')}</InputLabel>
              <SelectControl
                control={control}
                name="regionCode"
                labelId="select-region"
                label={t('common.region')}
                items={regionsItems}
                isLoading={isLoading}
                onChange={(e) => {
                  const regionCode = e.target.value as string;
                  setValue('regionCode', regionCode);
                  setInputCountry('');
                  setValue('country', '');
                  changeCountry(regionCode);
                }}
                error={!!errors.regionCode}
                helperText={errors.regionCode?.message || ''}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <Controller
                name="country"
                control={control}
                render={({ field }) =>
                  isLoading ? (
                    <Skeleton variant="text" width={400} height={40} />
                  ) : (
                    <Autocomplete
                      {...field}
                      inputValue={inputCountry}
                      options={countriesOptions}
                      onChange={(event, newValue) => {
                        setValue('country', newValue);
                      }}
                      onInputChange={(e, newInputValue, reason) => {
                        if (reason === 'reset' && !newInputValue) {
                          return;
                        }
                        setInputCountry(newInputValue);
                      }}
                      disabled={!getValues('regionCode')}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            size="small"
                            label={t('common.country')}
                            error={!!errors.country}
                            helperText={errors.country?.message || ''}
                          />
                        </>
                      )}
                    />
                  )
                }
              />
            </FormControl>
          </Grid>
          {isGroupCompany ? (
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-rank">{t(`complementaryOrganizationDetail.label.rank`)}</InputLabel>
                <SelectControl
                  control={control}
                  defaultValue=""
                  name="rank"
                  labelId="select-rank"
                  label={t(`complementaryOrganizationDetail.label.rank`)}
                  items={rankItems}
                  isLoading={isLoading}
                  onChange={(e) => {
                    setValue('rank', e.target.value);
                  }}
                  error={!!errors.rank}
                  helperText={errors.rank?.message || ''}
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12} md={4} />
          )}

          <Grid item xs={12} md={12} spacing={2}>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              {t('button.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
