import { useDispatch } from 'react-redux';
import { TemporaryOrganizationDataTypes } from 'src/api/useTemporaryOrganizationApi';
import { CreateOrgTypeList } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/temporary-organization/components/TemporaryOrganizationTab';
import { temporaryOrganizationSlice } from 'src/features/general/enquete-create/store/temporaryOrganizationSlice';
import { dummyOrganizationCodePrefix, InputVulesProps } from './useTemporaryOrganization';

interface ArgumentProps {
  createType: CreateOrgTypeList | null;
  baseOrganizationData: TemporaryOrganizationDataTypes;
  selectAutocompleteVules: InputVulesProps;
  companyNameJpn: string;
  companyNameEng: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupNameJpn: string;
  groupNameEng: string;
}

export const useTemporaryOrganizationValidation = () => {
  const dispatch = useDispatch();

  /***********************************************************************************
   * Use State
   ***********************************************************************************/
  let createTypeVal: CreateOrgTypeList | null = null;
  let baseOrganizationDataVal: TemporaryOrganizationDataTypes;
  let isExistCompanyNameVal: boolean = false;
  let isExistCompanyBothNamesVal: boolean = false;
  let isExistDivisionNameVal: boolean = false;
  let isExistDivisionBothNamesVal: boolean = false;
  let isExistDivisionPullDownVal: boolean = false;
  let isExistSbuNameVal: boolean = false;
  let isExistSbuBothNamesVal: boolean = false;
  let isExistSbuPullDownVal: boolean = false;
  let isExistDepartmentNameVal: boolean = false;
  let isExistDepartmentBothNamesVal: boolean = false;
  let isExistDepartmentPullDownVal: boolean = false;
  let isExistGroupNameVal: boolean = false;
  let isExistGroupBothNamesVal: boolean = false;
  let isExistGroupPullDownVal: boolean = false;
  let isExistRegionPullDownVal: boolean = false;
  let isExistCountryPullDownVal: boolean = false;
  let isExistRankPullDownVal: boolean = false;

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // Init
  const init = ({
    createType,
    baseOrganizationData,
    selectAutocompleteVules,
    companyNameJpn,
    companyNameEng,
    divisionNameJpn,
    divisionNameEng,
    sbuNameJpn,
    sbuNameEng,
    departmentNameJpn,
    departmentNameEng,
    groupNameJpn,
    groupNameEng,
  }: ArgumentProps) => {
    //////////////////////////////////////// Condition ////////////////////////////////////////
    // set Create Type
    // eslint-disable-next-line
    createTypeVal = createType;
    baseOrganizationDataVal = baseOrganizationData;
    // Company Check
    isExistCompanyNameVal = companyNameJpn !== '' || companyNameEng !== '';
    isExistCompanyBothNamesVal = companyNameJpn !== '' && companyNameEng !== '';
    // Division Check
    isExistDivisionNameVal = divisionNameJpn !== '' || divisionNameEng !== '';
    isExistDivisionBothNamesVal = divisionNameJpn !== '' && divisionNameEng !== '';
    isExistDivisionPullDownVal = selectAutocompleteVules.divisionCode !== '';
    // SBU Check
    isExistSbuNameVal = sbuNameJpn !== '' || sbuNameEng !== '';
    isExistSbuBothNamesVal = sbuNameJpn !== '' && sbuNameEng !== '';
    isExistSbuPullDownVal = selectAutocompleteVules.sbuCode !== '';
    // Department Check
    isExistDepartmentNameVal = departmentNameJpn !== '' || departmentNameEng !== '';
    isExistDepartmentBothNamesVal = departmentNameJpn !== '' && departmentNameEng !== '';
    isExistDepartmentPullDownVal = selectAutocompleteVules.departmentCode !== '';
    // Group Check
    isExistGroupNameVal = groupNameJpn !== '' || groupNameEng !== '';
    isExistGroupBothNamesVal = groupNameJpn !== '' && groupNameEng !== '';
    isExistGroupPullDownVal = selectAutocompleteVules.groupCode !== '';
    // Region Check
    isExistRegionPullDownVal = baseOrganizationData.regionCode !== '' || selectAutocompleteVules.regionCode !== '';
    // Country Check
    isExistCountryPullDownVal = baseOrganizationData.countryCode !== '' || selectAutocompleteVules.countryCode !== '';
    // Rank Check
    isExistRankPullDownVal = baseOrganizationData.rank !== '' || selectAutocompleteVules.rank !== '';
  };

  // Validation
  const checkValidation = (argumentProps: ArgumentProps) => {
    // initialize
    init(argumentProps);

    // reset Store
    resetError();

    let isError = false;
    let isErrorTtc = false;
    // Abount Create
    if (createTypeVal !== null) {
      if (baseOrganizationDataVal.groupCode || isExistGroupNameVal || isExistGroupPullDownVal) {
        // Exist Group
        const checkA = checkNotExistGroup();
        const checkB = checkNotExistDepartment();
        const checkC = checkNotExistSbu();
        const checkD = checkNotExistDivision();
        const checkE = checkNotExistCompany();
        isError = checkA || checkB || checkC || checkD || checkE;
      } else if (baseOrganizationDataVal.departmentCode || isExistDepartmentNameVal || isExistDepartmentPullDownVal) {
        // Exist Department
        const checkA = checkNotExistDepartment();
        const checkB = checkNotExistSbu();
        const checkC = checkNotExistDivision();
        const checkD = checkNotExistCompany();
        isError = checkA || checkB || checkC || checkD;
      } else if (baseOrganizationDataVal.sbuCode || isExistSbuNameVal || isExistSbuPullDownVal) {
        // Exist SBU
        const checkA = checkNotExistSbu();
        const checkB = checkNotExistDivision();
        const checkC = checkNotExistCompany();
        isError = checkA || checkB || checkC;
      } else if (!isExistCompanyNameVal || !isExistDivisionNameVal || !isExistDivisionPullDownVal) {
        // Not Exist Company or Division (Require Pattern)
        const checkA = checkNotExistDivision();
        const checkB = checkNotExistCompany();
        isError = checkA || checkB;
      }

      // Only create TTC
      if (createTypeVal === CreateOrgTypeList.ttc) {
        const checkA = checkNotTemporaryGroup();
        const checkB = checkNotTemporaryDepartment();
        const checkC = checkNotTemporarySbu();
        isErrorTtc = checkA || checkB || checkC;
      }
    } // About Edit
    else {
      let checkDivisionField = false;
      let checkSbuField = false;
      let checkDepartmentField = false;
      let checkGroupField = false;

      // Check Division name field
      if (baseOrganizationDataVal.divisionCode !== '' && baseOrganizationDataVal.divisionCode.indexOf(dummyOrganizationCodePrefix) !== -1) {
        checkDivisionField = checkDivisionNamesField();
      }

      // Check SBU name field
      if (baseOrganizationDataVal.sbuCode !== '' && baseOrganizationDataVal.sbuCode.indexOf(dummyOrganizationCodePrefix) !== -1) {
        checkSbuField = checkSbuNamesField();
      }

      // Check Department name field
      if (baseOrganizationDataVal.departmentCode !== '' && baseOrganizationDataVal.departmentCode.indexOf(dummyOrganizationCodePrefix) !== -1) {
        checkDepartmentField = checkDepartmentNamesField();
      }

      // Check Group name field
      if (baseOrganizationDataVal.groupCode !== '' && baseOrganizationDataVal.groupCode.indexOf(dummyOrganizationCodePrefix) !== -1) {
        checkGroupField = checkGroupNamesField();
      }

      isError = checkDivisionField || checkSbuField || checkDepartmentField || checkGroupField;
    }

    return isError || isErrorTtc;
  };

  const checkNotTemporarySbu = () => {
    if (isExistDivisionPullDownVal && !isExistSbuPullDownVal && !isExistSbuBothNamesVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorSbuNames(true));
      return true;
    }

    return false;
  };
  const checkNotTemporaryDepartment = () => {
    if (isExistSbuPullDownVal && !isExistDepartmentPullDownVal && !isExistDepartmentBothNamesVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorDepartmentNames(true));
      return true;
    }

    return false;
  };
  const checkNotTemporaryGroup = () => {
    if (isExistDepartmentPullDownVal && !isExistGroupPullDownVal && !isExistGroupBothNamesVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorGroupNames(true));
      return true;
    }

    return false;
  };

  const checkNotExistCompany = () => {
    if (!isExistCompanyBothNamesVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorCompanyNames(!isExistCompanyBothNamesVal));
      return true;
    }

    return false;
  };
  const checkNotExistDivision = () => {
    if (!isExistDivisionBothNamesVal && !isExistDivisionPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorDivisionNames(!isExistDivisionBothNamesVal));
      dispatch(temporaryOrganizationSlice.actions.setIsErrorDivisionPullDown(!isExistDivisionPullDownVal));
      return true;
    }

    return false;
  };

  const checkNotExistSbu = () => {
    if (!isExistSbuBothNamesVal && !isExistSbuPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorSbuNames(!isExistSbuBothNamesVal));
      dispatch(temporaryOrganizationSlice.actions.setIsErrorSbuPullDown(!isExistSbuPullDownVal));
      return true;
    }

    return false;
  };

  const checkNotExistDepartment = () => {
    if (!isExistDepartmentBothNamesVal && !isExistDepartmentPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorDepartmentNames(!isExistDepartmentBothNamesVal));
      dispatch(temporaryOrganizationSlice.actions.setIsErrorDepartmentPullDown(!isExistDepartmentPullDownVal));
      return true;
    }

    return false;
  };

  const checkNotExistGroup = () => {
    if (!isExistGroupBothNamesVal && !isExistGroupPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorGroupNames(!isExistGroupBothNamesVal));
      dispatch(temporaryOrganizationSlice.actions.setIsErrorGroupPullDown(!isExistGroupPullDownVal));
      return true;
    }

    return false;
  };

  const checkDivisionNamesField = () => {
    const isInvalidField = !isExistDivisionBothNamesVal || !isExistDivisionNameVal;
    dispatch(temporaryOrganizationSlice.actions.setIsErrorDivisionNames(isInvalidField));

    return isInvalidField;
  };

  const checkSbuNamesField = () => {
    const isInvalidField = !isExistSbuBothNamesVal || !isExistSbuNameVal;
    dispatch(temporaryOrganizationSlice.actions.setIsErrorSbuNames(isInvalidField));

    return isInvalidField;
  };

  const checkDepartmentNamesField = () => {
    const isInvalidField = !isExistDepartmentBothNamesVal || !isExistDepartmentNameVal;
    dispatch(temporaryOrganizationSlice.actions.setIsErrorDepartmentNames(isInvalidField));

    return isInvalidField;
  };

  const checkGroupNamesField = () => {
    const isInvalidField = !isExistGroupBothNamesVal || !isExistGroupNameVal;
    dispatch(temporaryOrganizationSlice.actions.setIsErrorGroupNames(isInvalidField));

    return isInvalidField;
  };

  // eslint-disable-next-line
  const checkNotExistRegion = () => {
    if (!isExistRegionPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorRegionPullDown(!isExistRegionPullDownVal));
      return true;
    }

    return false;
  };

  // eslint-disable-next-line
  const checkNotExistCountry = () => {
    if (!isExistCountryPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorCountryPullDown(!isExistCountryPullDownVal));
      return true;
    }

    return false;
  };

  // eslint-disable-next-line
  const checkNotExistRank = () => {
    if (!isExistRankPullDownVal) {
      dispatch(temporaryOrganizationSlice.actions.setIsErrorRankPullDown(!isExistRankPullDownVal));
      return true;
    }

    return false;
  };

  // reset Store
  const resetError = () => {
    dispatch(temporaryOrganizationSlice.actions.setIsErrorCompanyNames(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorDivisionNames(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorDivisionPullDown(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorSbuNames(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorSbuPullDown(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorDepartmentNames(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorDepartmentPullDown(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorGroupNames(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorGroupPullDown(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorRegionPullDown(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorCountryPullDown(false));
    dispatch(temporaryOrganizationSlice.actions.setIsErrorRankPullDown(false));
  };

  return { checkValidation, resetError };
};
