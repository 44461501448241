import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AggregateDivision, AggregateRegion } from 'src/@types/seriesAggregateSupplement';
import { StyledButton } from 'src/components/app-components/StyledButton';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import { RootState, useAppSelector } from 'src/redux/store';
import { SupplementCommentMaxLength } from './constants';

interface Props {
  aggregate: AggregateDivision | AggregateRegion;
  methods: UseFormReturn<FieldValues, any>;
  customerIndex: number;
  detailIndex: number;
}

const AggregateSupplementPanelComponent: React.FC<Props> = (props) => {
  const { aggregate, methods, customerIndex, detailIndex } = props;
  const { t } = useTranslation();
  const defaultAggregateSupplement = useAppSelector((state: RootState) => state.seriesAggregateSupplement.defaultAggregateSupplement);

  const isDivision = 'divisionCode' in aggregate;
  const arrayName = isDivision ? 'aggregateDivisions' : 'aggregateRegions';
  const keyPrefix = `aggregateCustomers.${customerIndex}.${arrayName}.${detailIndex}`;
  const salesKey = `${keyPrefix}.salesSupplementComment`;
  const purchaseKey = `${keyPrefix}.purchaseSupplementComment`;
  const onClickRestoreDefaults = () => {
    const aggregateCustomer = defaultAggregateSupplement[customerIndex];
    const aggregateDetails = isDivision ? aggregateCustomer.aggregateDivisions : aggregateCustomer.aggregateRegions;
    const aggregateDetail = aggregateDetails[detailIndex];
    methods.setValue(salesKey, aggregateDetail.salesSupplementComment);
    methods.setValue(purchaseKey, aggregateDetail.purchaseSupplementComment);
    methods.trigger();
  };
  return (
    <Grid container display="flex" spacing={2} sx={{ mt: 2 }}>
      <Grid container item xs={12} flexDirection="row-reverse">
        <StyledButton
          onClick={() => onClickRestoreDefaults()}
          children={t('enqueteStatus.aggregateSupplement.restoreDefaults')}
          isDisabled={false}
          sx={{ textAlign: 'right' }}
          color="primary"
        />
      </Grid>
      <Grid container item xs={12} flexDirection="row">
        <Grid item xs={3} alignSelf="center">
          <Typography variant="body1" sx={{ textAlign: 'right', mr: '2em' }}>
            {t('enqueteStatus.aggregateSupplement.salesComment')}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <RHFonChangeTextField
            minRows={3}
            defaultValue={aggregate.salesSupplementComment}
            control={methods.control}
            name={salesKey}
            key={salesKey}
            handleOnChange={() => {}}
            multiline={true}
            rules={{
              maxLength: {
                value: SupplementCommentMaxLength,
                message: t('validateError.maxLength', { max: SupplementCommentMaxLength }),
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} flexDirection="row">
        <Grid item xs={3} alignSelf="center">
          <Typography variant="body1" sx={{ textAlign: 'right', mr: '2em' }}>
            {t('enqueteStatus.aggregateSupplement.purchaseComment')}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <RHFonChangeTextField
            minRows={3}
            defaultValue={aggregate.purchaseSupplementComment}
            control={methods.control}
            name={purchaseKey}
            key={purchaseKey}
            handleOnChange={() => {}}
            multiline={true}
            rules={{
              maxLength: {
                value: SupplementCommentMaxLength,
                message: t('validateError.maxLength', { max: SupplementCommentMaxLength }),
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AggregateSupplementPanelComponent;
