import { Box } from '@mui/material';

export interface PageLayoutBodyProps {
  children: React.ReactNode;
}

export const PageLayoutBody: React.FC<PageLayoutBodyProps> = (props) => {
  return (
    <Box id="page-body" component={'div'}>
      {props.children}
    </Box>
  );
};
