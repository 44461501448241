import HotTable from '@handsontable/react';
import { HyperFormula } from 'hyperformula';
import { RefObject, useEffect } from 'react';
import { initialSettings } from 'src/components/app-components/bonsai/type-general-table/constants/initialSettings';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { RootState, useSelector } from 'src/redux/store';
import { calcRowHeaderWidth } from 'src/utils/handsontable';
import { ColWidths, MergeCells, NestedHeader, RowHeights, TableData } from 'src/@types/generalEnquete';
import { useSurveyTables } from 'src/hooks/useSurveyTables';

export const usePreviewTableData = (
  previewHotRef: RefObject<HotTable>[],
  previewHotRefForSubSheet: RefObject<HotTable>[],
  hyperformulaInstance: HyperFormula,
) => {
  const { formatSubTableData } = useSurveyTables();

  const totalMainTables = useSelector((state: RootState) => state.tableSetting.totalMainTables);
  const subDatas = useSelector((state: RootState) => state.tableSetting.subDatas);

  // メイン表のインタンス初期化−保存データがあればそのデータで定義
  const loadDataMainTable = (
    hr: RefObject<HotTable>,
    srcData: TableData,
    cellData: any[],
    mrgCells: MergeCells,
    cWidths: ColWidths,
    rHeights: RowHeights,
    ntdHeaders: (string | NestedHeader)[][] | undefined,
    rHeaders: string[] | undefined,
  ) => {
    const dataForLoad = srcData.map((row) => {
      return row.map((cell) => {
        return cell ?? '';
      });
    });

    if (hr.current) {
      hr.current.hotInstance?.loadData(dataForLoad);

      // 画面表示は、readOnly = falseにする。
      // set cell settings
      hr.current.hotInstance?.updateSettings({
        ...initialSettings,
        cell: cellData,
        mergeCells: mrgCells,
        colWidths: cWidths,
        rowHeights: rHeights,
        nestedHeaders: Array.isArray(ntdHeaders) && 0 < ntdHeaders.length ? ntdHeaders : undefined,
        rowHeaders: Array.isArray(rHeaders) ? rHeaders : true,
        rowHeaderWidth: calcRowHeaderWidth(rHeaders),
      });
    }
  };

  /**
   * HotTableインスタンスに必要なデータを定義し初期化を実施
   */
  useEffect(() => {
    for (let i = 0; i < TABLE_SETTINGS.SUB.MAX_LENGTH; i++) {
      previewHotRefForSubSheet[i].current?.hotInstance?.loadData([]);
      previewHotRefForSubSheet[i].current?.hotInstance?.updateSettings({});
      //  memo シートを削除した場合に、削除したシートの値の参照が残りっぱなしになる現象の対応のためclearSheetしておく
      if (hyperformulaInstance.getSheetName(i + 1)) {
        hyperformulaInstance.clearSheet(i + 1);
      }
    }

    // ストアデータからインスタンス化
    formatSubTableData(previewHotRefForSubSheet, subDatas, true);

    // 保存データ読込時
    totalMainTables.forEach((tableTypeData, index) => {
      loadDataMainTable(
        previewHotRef[index],
        tableTypeData.sourceData,
        tableTypeData.cell,
        tableTypeData.mergeCells,
        tableTypeData.colWidths,
        tableTypeData.rowHeights,
        tableTypeData.nestedHeaders,
        tableTypeData.rowHeaders,
      );
    });
  }, [totalMainTables]); // eslint-disable-line
};
