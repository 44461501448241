import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreatedSurveyEntity } from 'src/api/useCreatedSurvey';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector } from 'src/redux/store';
import { setDuplicateEnqueteData } from '../utils/setDuplicateEnqueteData';
import { SurveyCreateForm } from './SurveyCreateForm';
import { SurveyDuplicateForm } from './SurveyDuplicateForm';
import { SurveyEditForm } from './SurveyEditForm';

type SurveyInformationModalProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  isNewCreateMode?: boolean;
  sourceEnqueteData?: CreatedSurveyEntity;
  modalTitle: string;
};

export const SurveyInformationModal = ({
  setIsModalOpen,
  isModalOpen,
  isNewCreateMode,
  sourceEnqueteData,
  modalTitle,
}: SurveyInformationModalProps) => {
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const surveyDetailId = sourceEnqueteData ? sourceEnqueteData.surveyDetailId : enqueteData.surveyDetailId;
  const { isAdmin, isBonsaiCreator, isSurveyOwner, isLoadingPermission } = usePermission(surveyDetailId);
  const duplicateEnqueteData = setDuplicateEnqueteData(sourceEnqueteData);
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
  });

  // memo: アンケート新規作成時は、アンケート情報編集ダイアログを open した状態とする
  useEffect(() => {
    if (isNewCreateMode && !isModalOpen) setIsModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = () => {
    if (duplicateEnqueteData) {
      // memo : アンケートの複製
      return (
        <>
          {/** アンケート複製 アンケート作成者権限とアンケート管理者権限を持つ必要がある */}
          {(isAdmin || (isBonsaiCreator && isSurveyOwner)) && (
            <SurveyDuplicateForm enqueteData={duplicateEnqueteData} setIsModalOpen={setIsModalOpen} />
          )}
          {/** アンケート複製 アンケート作成者権限がない場合とアンケート管理者権限がない場合の表示 */}
          {!isAdmin && !isBonsaiCreator ? (
            <Stack sx={{ width: 500 }} textAlign="center">
              <Typography variant="h5">{t('enqueteRequest.surveyDuplicationModal.message.dontHavePermissionToCreate')}</Typography>
              <Box sx={{ mt: 5, mr: 3, width: '100%' }}>
                <Button variant={'contained'} onClick={() => setIsModalOpen(false)} children={t('button.close')} color="error" />
              </Box>
            </Stack>
          ) : (
            !isAdmin &&
            !isSurveyOwner && (
              <Stack sx={{ width: 500 }} textAlign="center">
                <Typography variant="h5">{t('enqueteRequest.surveyDuplicationModal.message.haveNotSurveyOwnerAuthority')}</Typography>
                <Box sx={{ mt: 5, mr: 3, width: '100%' }}>
                  <Button variant={'contained'} onClick={() => setIsModalOpen(false)} color="error">
                    {t('button.close')}
                  </Button>
                </Box>
              </Stack>
            )
          )}
        </>
      );
    } else if (isNewCreateMode) {
      // memo : アンケートの作成
      return (
        <>
          {/** アンケート作成 アンケート作成者権限を持つ必要がある */}
          {(isAdmin || isBonsaiCreator) && <SurveyCreateForm setIsModalOpen={setIsModalOpen} methods={methods} />}
          {/** アンケート作成でアンケート作成者権限がない場合の表示 */}
          {!isAdmin && !isBonsaiCreator && (
            <Stack sx={{ width: 500 }} textAlign="center">
              <Typography variant="h5">{t('enqueteRequest.surveyDuplicationModal.message.dontHavePermissionToCreate')}</Typography>
              <Box sx={{ mt: 5, mr: 3, width: '100%' }}>
                <Button variant={'contained'} onClick={() => setIsModalOpen(false)} children={t('button.close')} color="error" />
              </Box>
            </Stack>
          )}
        </>
      );
    } else {
      // memo : アンケートの編集
      return (
        <>
          {/** アンケート編集 アンケート管理者権限を持つ必要がある*/}
          {(isAdmin || isSurveyOwner) && <SurveyEditForm setIsModalOpen={setIsModalOpen} methods={methods} />}
          {/** アンケート編集でアンケート管理者権限がない場合の表示 */}
          {!isAdmin && !isSurveyOwner && (
            <Stack sx={{ width: 500 }} textAlign="center">
              <Typography variant="h5">{t('enqueteRequest.surveyDuplicationModal.message.dontHavePermissionToCreate')}</Typography>
              <Box sx={{ mt: 5, mr: 3, width: '100%' }}>
                <Button variant={'contained'} onClick={() => setIsModalOpen(false)} children={t('button.close')} color="error" />
              </Box>
            </Stack>
          )}
        </>
      );
    }
  };
  return (
    <StyledModal isOpen={isModalOpen} onCloseFunc={() => setIsModalOpen(false)} modalTitle={modalTitle}>
      {!isLoadingPermission && render()}
    </StyledModal>
  );
};
