import { GridApi } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import useLocales from 'src/hooks/useLocales';

interface Props {
  // gridApi: GridApi | undefined;
  gridApi: MutableRefObject<GridApi<any> | null>;
}

export interface SelectedRowType {
  parentCustomerCode: string;
  parentCustomerName: string;
  parentCustomerNameEng: string;
  customerCode: string;
  customerName: string;
  customerNameEng: string;
  regionCode: string;
  regionName: string;
  regionNameEng: string;
  countryCode: string;
  countryName: string;
  countryNameEng: string;
}

export const useGetSelectedRow = (props: Props) => {
  const { isJapanese } = useLocales();
  const { gridApi } = props;

  const getSelectedRowCustomerNames = (): string[] => {
    const customerName: string[] = [];
    gridApi?.current?.getSelectedRows().forEach((element) => {
      if (isJapanese ? element?.customerNameJpn : element?.customerNameEng) {
        customerName.push(isJapanese ? element?.customerNameJpn : element?.customerNameEng);
      }
    });

    return customerName;
  };
  const getSelectedRowElement = (): SelectedRowType[] => {
    const elements: SelectedRowType[] = [];
    gridApi?.current?.getSelectedRows().forEach((element) => {
      if (element) {
        const elm = {
          parentCustomerCode: element?.parentCustomerCode ?? element?.customerCode,
          parentCustomerName: element?.parentCustomerNameJpn ?? element?.parentCustomerNameEng ?? element?.customerNameJpn,
          parentCustomerNameEng: element?.parentCustomerNameEng ?? element?.parentCustomerNameJpn ?? element?.customerNameEng,
          customerCode: element?.customerCode,
          customerName: element?.customerNameJpn ?? element?.customerNameEng,
          customerNameEng: element?.customerNameEng ?? element?.customerNameJpn,
          regionCode: element?.regionCode,
          regionName: element?.regionNameJpn,
          regionNameEng: element?.regionNameEng,
          countryCode: element?.countryCode,
          countryName: element?.countryNameJpn,
          countryNameEng: element?.countryNameEng,
        };
        elements.push(elm);
      }
    });
    return elements;
  };

  return { getSelectedRowElement, getSelectedRowCustomerNames };
};
