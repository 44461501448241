import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE, Question, QuestionState } from 'src/@types/generalEnquete';
import EnqueteAnswerCheckboxForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerCheckboxForm';
import EnqueteAnswerCommentForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerCommentForm';
import EnqueteAnswerDropdownForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerDropdownForm';
import EnqueteAnswerForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerForm';
import EnqueteAnswerRadioGroupForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerRadioGroupForm';
import EnqueteAnswerTextForm from 'src/components/app-components/bonsai/type-general-question/components/EnqueteAnswerTextForm';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerValidator } from '../hooks/useEnqueteAnswerValidator';

type EnqueteAnswerGeneralQuestionFormSwitcherProps = {
  methods: UseFormReturn;
  question: Question;
  index: number;
  languageCode?: string;
  questionStates?: QuestionState[];
};

const EnqueteAnswerGeneralQuestionFormSwitcher = ({
  methods,
  question,
  index,
  languageCode,
  questionStates,
}: EnqueteAnswerGeneralQuestionFormSwitcherProps) => {
  const storedGeneralQuestion: Question[] = useAppSelector((storedGeneral) => storedGeneral.enqueteGeneralAnswer.questions);
  const { rulesEnqueteAnswer, rulesEnqueteAnswerCheckbox } = useEnqueteAnswerValidator(methods as UseFormReturn, storedGeneralQuestion[index]);

  const memolizedRule = useMemo(() => rulesEnqueteAnswer, [rulesEnqueteAnswer]);
  const memolizedRuleCheckBox = useMemo(() => rulesEnqueteAnswerCheckbox, [rulesEnqueteAnswerCheckbox]);
  const renderSwitch = (type: string) => {
    switch (type) {
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT:
        return (
          <EnqueteAnswerTextForm
            question={question}
            index={index}
            methods={methods}
            validationRule={rulesEnqueteAnswer}
            languageCode={languageCode}
            questionState={questionStates?.find((questionState) => questionState.questionId === question.id)?.state}
          />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXTAREA:
        return (
          <EnqueteAnswerCommentForm
            question={question}
            index={index}
            methods={methods}
            validationRule={memolizedRule}
            languageCode={languageCode}
            questionState={questionStates?.find((questionState) => questionState.questionId === question.id)?.state}
          />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX:
        return (
          <EnqueteAnswerCheckboxForm
            question={question}
            index={index}
            methods={methods}
            validationRule={memolizedRuleCheckBox}
            questionState={questionStates?.find((questionState) => questionState.questionId === question.id)?.state}
          />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP:
        return (
          <EnqueteAnswerRadioGroupForm
            question={question}
            index={index}
            methods={methods}
            validationRule={memolizedRule}
            questionState={questionStates?.find((questionState) => questionState.questionId === question.id)?.state}
          />
        );
      case GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT:
        return (
          <EnqueteAnswerDropdownForm
            question={question}
            index={index}
            methods={methods}
            validationRule={memolizedRule}
            questionState={questionStates?.find((questionState) => questionState.questionId === question.id)?.state}
          />
        );
      default:
        return null;
    }
  };

  return question.inputType ? (
    <EnqueteAnswerForm question={question} index={index} languageCode={languageCode} questionStates={questionStates}>
      {renderSwitch(question.inputType)}
    </EnqueteAnswerForm>
  ) : null;
};

export default EnqueteAnswerGeneralQuestionFormSwitcher;
