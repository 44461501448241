import AuthGuardLayout from '../../../components/layouts/AuthGuardLayout';
import { enqueteAnswerRoute } from '../enquete-answer/routes';
import { enqueteConfirmRoute } from '../enquete-confirm/routes';
import { enqueteCreateRoute } from '../enquete-create/routes';
import { enqueteRequestRoute } from '../enquete-request/routes';
import { enqueteStatusRoute } from '../enquete-status/routes';
import { enqueteThreadsRoute } from '../enquete-threads/routes';

//authGuardをパスしたとき、apiRouteで指定したページを表示する
export const generalRoute = {
  path: 'general',
  element: <AuthGuardLayout />,
  children: [
    //
    enqueteCreateRoute,
    enqueteAnswerRoute,
    enqueteRequestRoute,
    enqueteConfirmRoute,
    enqueteStatusRoute,
    enqueteThreadsRoute,
  ],
};
