import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from 'react-i18next';
import { PATH_OVERVIEW } from 'src/routes/paths';
import { useNavbarRoutes } from './useNavbarRoutes';

export const useOnlyAnswerDashboardLayout = () => {
  const { t } = useTranslation();
  const { generalRoutes } = useNavbarRoutes();

  return {
    navConfig: [
      {
        subheader: '',
        items: [
          {
            title: 'TOP',
            icon: <DashboardIcon />,
            path: PATH_OVERVIEW.overview,
          },
        ],
      },
      {
        subheader: t('features.general.title'),
        items: [generalRoutes.ANSWER, generalRoutes.CONFIRM, generalRoutes.THREADS],
      },
    ],
  };
};
