import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { useAppSelector } from 'src/redux/store';
import { useAggregateSupplement } from '../hooks/useAggregateSupplement';
import AggregateSupplementTabByUnit from './AggregateSupplementTabByUnit';

const SeriesAggregateSupplementTab: React.FC = () => {
  const { id: respondentRequestId } = useParams();
  const { isLoading, aggregateSupplement, onSaveClick } = useAggregateSupplement(respondentRequestId);
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const { isAdmin, isSurveyOwner } = useAppSelector((state) => state.enqueteStatus);
  const isSaveDisabled = !methods.formState.isValid || (!isAdmin && !isSurveyOwner);
  //タブ
  const [value, setValue] = useState('1');
  //タブ切り替え関数
  const handleChange = (e: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    methods.setValue('aggregateCustomers', aggregateSupplement);
  }, [methods, aggregateSupplement]);

  return (
    <LoadableComponent isLoading={isLoading}>
      <Box sx={{ textAlign: 'right' }}>
        <StyledButton onClick={methods.handleSubmit(onSaveClick)} children={t('button.save')} isDisabled={isSaveDisabled} />
      </Box>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label={t('enqueteStatus.aggregateSupplement.byDivision')} value="1" />
          <Tab label={t('enqueteStatus.aggregateSupplement.byRegion')} value="2" />
        </TabList>
        <TabPanel value="1">
          <AggregateSupplementTabByUnit type="division" customers={aggregateSupplement} methods={methods} />
        </TabPanel>
        <TabPanel value="2">
          <AggregateSupplementTabByUnit type="region" customers={aggregateSupplement} methods={methods} />
        </TabPanel>
      </TabContext>
    </LoadableComponent>
  );
};

export default SeriesAggregateSupplementTab;
