import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useState } from 'react';
import { UserEntity } from 'src/api/useUserApi';
import { useUserAgGridColDef } from 'src/components/app-components/bonsai/hooks/useUserAgGridColDef';
import { GridProps } from 'src/components/app-components/bonsai/SearchModal';

interface Props {
  users: UserEntity[];
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSearchPersonGrid = (props: Props) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const { users, setIsSelected } = props;
  const getSelectedRows = (): UserEntity[] => {
    return gridApi?.getSelectedRows() as UserEntity[];
  };

  const { userAgGridColDef } = useUserAgGridColDef({
    companyCodeColName: 'companyCode',
    emailColDefSetting: { key: 'email' },
    fullNameColDefSetting: { key: 'fullName' },
    companyNameColDefSetting: { key: 'companyName' },
    companyAbbreviationColDefSetting: { key: 'companyAbbreviation' },
    assignedCompanyColDefSetting: { key: 'assignedCompanyName' },
    divisionNameColDefSetting: { key: 'divisionName' },
    sbuNameColDefSetting: { key: 'sbuName' },
    departmentNameColDefSetting: { key: 'departmentName' },
    groupNameColDefSetting: { key: 'groupName' },
  });

  // ダイアログ内の Grid 定義を構築
  const grid: GridProps<UserEntity> = {
    colDefs: [...userAgGridColDef],
    rowData: users,
    onGridReady: (params: GridReadyEvent) => {
      setGridApi(params.api);
    },
    onSelectionChanged: () => {
      setIsSelected(!!getSelectedRows().length);
    },
  };

  return { grid, getSelectedRows };
};
