import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const sendTextModalSlice = createSlice({
  name: 'sendTextModal',
  initialState: {
    comment: null as string | null,
    showConfirm: false as boolean,
    isRequested: true as boolean,
  },
  reducers: {
    setComment: (state, action: PayloadAction<string | null>) => {
      state.comment = action.payload;
    },
    setShowConfirm: (state, action: PayloadAction<boolean>) => {
      state.showConfirm = action.payload;
    },
    setIsRequested: (state, action: PayloadAction<boolean>) => {
      state.isRequested = action.payload;
    },
    setInitialState: (state) => {
      state.comment = null;
      state.showConfirm = false;
      state.isRequested = true;
    },
  },
});
export const { setComment, setShowConfirm, setIsRequested, setInitialState } = sendTextModalSlice.actions;

export default sendTextModalSlice.reducer;
