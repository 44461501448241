import { BonsaiRole, Role } from 'src/@types/role';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { ComplementaryOrganizationDetailPage } from '../pages/ComplementaryOrganizationDetailPage';
import { ComplementaryOrganizationListPage } from '../pages/ComplementaryOrganizationListPage';

//アクセス可能なロールを定義
const accessibleRoleList: Role[] = [BonsaiRole.ADMIN, BonsaiRole.VIEWER_ADMIN, BonsaiRole.ORGANIZATION_EDITOR];

//ユーザのロールを確認し、ページを表示するかどうか決める
export const complementaryOrganizationRoute = {
  path: 'complementary-organization',
  children: [
    {
      path: '',
      element: (
        <RoleBasedGuard accessibleRoles={accessibleRoleList}>
          <ComplementaryOrganizationListPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'create',
      element: (
        <RoleBasedGuard accessibleRoles={accessibleRoleList}>
          <ComplementaryOrganizationDetailPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: ':id',
      element: (
        <RoleBasedGuard accessibleRoles={accessibleRoleList}>
          <ComplementaryOrganizationDetailPage />
        </RoleBasedGuard>
      ),
    },
  ],
};
