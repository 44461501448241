import { ResponseVisibleConditionTypes } from 'src/api/useVisibleConditionsApi';
import { OrganizationEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

export class VisibleConditionUtils {
  /**
   * 組織情報が可視条件に合致するか
   * @param organization 組織情報
   * @param condition 可視条件
   * @returns 組織情報が可視条件に合致する場合true
   */
  static isMatchOrganization(organization: OrganizationEntity, condition: ResponseVisibleConditionTypes) {
    return (
      (!condition.companyCode || condition.companyCode === organization.companyCode) &&
      (!condition.divisionCode || condition.divisionCode === organization.divisionCode) &&
      (!condition.sbuCode || condition.sbuCode === organization.sbuCode) &&
      (!condition.departmentCode || condition.departmentCode === organization.departmentCode) &&
      (!condition.groupCode || condition.groupCode === organization.groupCode) &&
      (!condition.regionCode || condition.regionCode === organization.regionCode) &&
      (!condition.countryCode || condition.countryCode === organization.countryCode)
    );
  }
}
