import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AggregateCustomer } from 'src/@types/seriesAggregateSupplement';

export interface CustomerSupplement {
  customerCode: string;
  divisionSupplements: DivisionSupplement[];
  regionSupplements: RegionSupplement[];
}

export interface DivisionSupplement {
  divisionCode: string;
  supplement: string;
}

export interface RegionSupplement {
  regionCode: string;
  supplement: string;
}

export const seriesAggregateSupplementSlice = createSlice({
  name: 'seriesAggregateSupplement',
  initialState: {
    previousYear: '',
    currentYear: '',
    defaultAggregateSupplement: [] as AggregateCustomer[],
  },
  reducers: {
    setPreviousYear: (state, action: PayloadAction<string>) => {
      state.previousYear = action.payload;
    },
    setCurrentYear: (state, action: PayloadAction<string>) => {
      state.currentYear = action.payload;
    },
    setDefaultAggregateSupplement: (state, action: PayloadAction<AggregateCustomer[]>) => {
      state.defaultAggregateSupplement = action.payload;
    },
  },
});
export const { setPreviousYear, setCurrentYear, setDefaultAggregateSupplement } = seriesAggregateSupplementSlice.actions;
