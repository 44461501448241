import { Alert, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { RootState, useAppSelector } from 'src/redux/store';

type LogicErrorMessageModalProps = {
  onCloseFunc: () => void;
};
const LogicErrorMessageModal = ({ onCloseFunc }: LogicErrorMessageModalProps) => {
  const { t } = useTranslation();
  const logicErrorMessages = useAppSelector((state: RootState) => state.enqueteCreate.logicErrorMessages);
  return (
    <>
      <Alert severity="error" color="error" variant="outlined" sx={{ mb: 2 }}>
        {logicErrorMessages.map((logicErrorMessage, index) => (
          <Typography key={index} variant={'body1'}>
            {logicErrorMessage}
          </Typography>
        ))}
      </Alert>
      <Box textAlign="center">
        <StyledButton variant="contained" color="error" onClick={onCloseFunc}>
          {t('button.close')}
        </StyledButton>
      </Box>
    </>
  );
};

export default LogicErrorMessageModal;
