import HelpIcon from '@mui/icons-material/Help';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OverviewEnquete } from 'src/@types/overviewEnquete';
import RHFDeskTopDatePicker from 'src/components/react-hook-form/RHFDeskTopDatePicker';
import { REQUEST_STATUS } from 'src/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useOverviewValidator } from '../hooks/useOverviewValidator';

interface Props {
  methods: UseFormReturn;
}

const OverviewSetRateBox = (props: Props) => {
  const { methods } = props;
  const { t } = useTranslation();

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const tabData = enqueteData?.surveyDetail?.surveyFormData?.formData.tabData[0] as OverviewEnquete;
  const startDate = tabData?.reportingPeriod?.startDate ? new Date(tabData?.reportingPeriod?.startDate) : null;
  const { startDateRule, endDateRule } = useOverviewValidator(methods);
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const isNotBeforeRequest = requestStatus !== REQUEST_STATUS.BEFORE_REQUEST;

  return (
    <Grid container direction="column" alignItems="right" justifyContent="center" spacing={2}>
      <Grid container item direction="row" alignItems="flex-start" spacing={2}>
        <Grid item sx={{ marginTop: '8px', display: 'flex' }}>
          <Typography variant="body1">{t('enqueteCreate.overviewSurvey.AggregationPeriod')}</Typography>
          <Tooltip title={<Box>{t('enqueteStatus.overviewSurvey.message.periodDescription')}</Box>}>
            <IconButton sx={{ pt: '0px', pb: '0px' }}>
              <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <RHFDeskTopDatePicker
            control={methods.control}
            name="startDate"
            label={t('enqueteCreate.overviewSurvey.startDate')}
            rules={startDateRule}
            defaultValue={startDate}
            views={['year', 'month']}
            inputFormat={'yyyy/MM'}
            disabled={isNotBeforeRequest}
          />
        </Grid>

        <Grid item sx={{ marginTop: '8px' }}>
          <Typography variant="body1">〜</Typography>
        </Grid>
        <Grid item>
          <RHFDeskTopDatePicker
            control={methods.control}
            name="endDate"
            label={t('enqueteCreate.overviewSurvey.endDate')}
            rules={endDateRule}
            defaultValue={new Date(tabData?.reportingPeriod?.endDate)}
            views={['year', 'month']}
            inputFormat={'yyyy/MM'}
            disabled={isNotBeforeRequest}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OverviewSetRateBox;
