import { Box, Button, Grid, Modal, Stack, SxProps, Typography } from '@mui/material';
import { SnackbarKey } from 'notistack5';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSettings from 'src/hooks/useSettings';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';
import { setInitialState, setShowConfirm } from '../../../../store/sendTextModalSlice';

interface Props {
  // モーダルの表示/非表示フラグ
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  onClick: () => Promise<SnackbarKey | undefined>;
  methods: UseFormReturn<FieldValues, any>;
}

export const SubmitConfirmationModal: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, title, description, onClick, methods } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const dispatch = useAppDispatch();
  const { isRequested } = useAppSelector((state) => state.sendTextModal);

  //-------------------------------------------------------------
  // SxProps
  //-------------------------------------------------------------
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80vw',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={{ ...modalStyle, width: '400px' }}>
        <Stack spacing={4}>
          {/* modal title */}
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h4'} component={'h2'} children={title} />
            </Grid>
          </Grid>

          {/* message block */}
          <Grid container>
            <Grid item xs={12} justifyContent="center">
              <Typography>{description} </Typography>
            </Grid>
          </Grid>

          {/* action block */}
          <Grid container>
            <Grid item xs={12}>
              <Stack direction={'row-reverse'} spacing={1}>
                <Button
                  variant={'contained'}
                  children={t('button.ok')}
                  disabled={isRequested}
                  onClick={async () => {
                    await onClick();
                    methods.reset();
                    dispatch(setInitialState());
                  }}
                />
                <Button
                  variant={'contained'}
                  onClick={() => {
                    dispatch(setShowConfirm(false));
                  }}
                  children={t('button.cancel')}
                  color="error"
                  disabled={isRequested}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};
