import { useTranslation } from 'react-i18next';
import { ANSWER_STATUS, AnswerStatus } from 'src/constants';

// ステータスの表示等に関わるロジック（ドメイン）を各画面機能で共通化することを目的としたhooksです
const useStatus = () => {
  const { t } = useTranslation();
  const toStringAnswerStatus = (value: AnswerStatus | undefined) => {
    switch (value) {
      case ANSWER_STATUS.ANSWERED:
        return t('answerStatus.answered');
      case ANSWER_STATUS.SAVED:
        return t('answerStatus.saved');
      case ANSWER_STATUS.RETURNED:
        return t('answerStatus.returned');
      default:
        return t('answerStatus.unAnswered');
    }
  };

  return { toStringAnswerStatus };
};

export default useStatus;
