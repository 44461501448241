import axios from 'axios';
import Decimal from 'decimal.js';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { AccountingClass, ConsolidationClass, EmployeesConsolidationClass } from 'src/@types/overviewEnquete';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface CustomerEntity {
  customerCode: string;
  customerNameJpn: string;
  customerNameEng: string;
  topGlobalCustomerCode: string;
  topDomesticCustomerCode: string;
  parentCustomerCode: string;
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
  address: string;
  estabDate: Date;
  entryDate: string;
  sector: string;
  capital: Decimal; // memo:capitalCurrencyCodeによっては小数点あり
  capitalCurrencyCode: string;
  ceoName: string;
  ceoPosition: string;
  employeesNumber: number;
  employeesNumberConsol: number;
  shareholder: string[];
  shareholderRatio: string[];
  mainCustomer: string[];
  mainSupplier: string[];
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  isDeleted: boolean;
}
export interface PartialCustomerData {
  customerCode: string;
  customerNameJpn: string;
  customerNameEng: string;
  topGlobalCustomerCode: string;
  topGlobalCustomerNameJpn: string;
  topGlobalCustomerNameEng: string;
  parentCustomerCode: string;
  parentCustomerNameJpn: string;
  parentCustomerNameEng: string;
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
  address: string;
  entryDate: string;
  isDeleted: boolean;
}

export interface CustomerSearchCondition {
  customerCode?: string[];
  parentCustomerCode?: string[];
  topGlobalCustomerCode?: string[];
  isDeleted?: boolean;
  countryCode?: string;
  regionCode?: string;
  entryDate?: string;
  keyword?: string;
  not?: [
    {
      and?: {
        topGlobalCustomerCode?: string[];
        parentCustomerCode?: string[];
      };
      or?: {
        customerCode?: string[];
      };
    },
  ];
  limit?: number;
}

export interface CustomerTransaction {
  purchaseList: AmountTransaction[];
  salesList: AmountTransaction[];
}

export interface AmountTransaction {
  currencyCode: string;
  amount: Decimal;
}

export interface CustomerOverviewInfo {
  customerNameJpn: string;
  customerNameEng: string;
  address: string | null;
  estabDate: Date | null;
  sector: string | null;
  capital: Decimal | null;
  systemCapital: Decimal | null;
  capitalCurrencyCode: string | null;
  isConvertYen: boolean;
  ceoName: string | null;
  employeesNumber: number | null;
  employeesConsolidationClass: EmployeesConsolidationClass;
  shareholderList: string[];
  shareholderRatioList: (number | null)[];
  mainCustomerList: string[];
  mainSupplierList: string[];
  customerPerformance: CustomerPerformanceInfo[];
}

export interface CustomerPerformanceInfo {
  fiscalYear: Date;
  salesAmount: Decimal | null;
  netProfit: Decimal | null;
  currencyCode: string;
  isConvertYen: boolean;
  accountingClass: AccountingClass;
  consolidationClass: ConsolidationClass;
  systemSalesAmount: Decimal | null;
  systemNetProfit: Decimal | null;
}

const BONSAI_URL = `${apiGateway}/customer`;

export const useCustomerApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  // 取引先情報を Prisma の filter 仕様に沿って検索するためのAPI
  const getCustomersByFilter = async (where?: CustomerSearchCondition) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PartialCustomerData[]>(`${BONSAI_URL}`, {
        headers: { Authorization: token },
        params: { where },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useCustomerApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 概況表初期値提案用の金額を取得する
   * @param respondentTargetId respondentTargetId
   * @returns 初期値提案用 仕入高 売上高
   */
  const getCustomerTransaction = async (respondentTargetId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<CustomerTransaction>(BONSAI_URL + '/transaction/' + respondentTargetId, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data || { purchaseAmount: [], salesAmount: [] };
    } catch (error) {
      // memo: 上位層でエラーハンドリングの仕組みがないため、ここでは画面にエラーであることを表示し握りつぶす
      enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.answer') }), {
        variant: 'error',
      });
    }
  };

  /**
   * 会社概況情報初期値提案用の情報を取得する
   * @param surveyDetailId surveyDetailId
   * @returns 会社概況情報初期値提案用の情報
   */
  const getInitialCustomerOverviewInfo = async (surveyDetailId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<CustomerOverviewInfo>(BONSAI_URL + '/overview-info/' + surveyDetailId, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.overviewInfo') }), {
          variant: 'error',
        });
      } else {
        throw error;
      }
    }
  };

  return {
    getCustomersByFilter,
    getCustomerTransaction,
    getInitialCustomerOverviewInfo,
  };
};
