import HelpIcon from '@mui/icons-material/Help';
import { Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GENERAL_ENQUETE_VALIDATION_PATTERN, Question, Validation } from 'src/@types/generalEnquete';
import RHFDropdown from 'src/components/react-hook-form/RHFDropdown';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import RHFUnRegisterCheckBox from 'src/components/react-hook-form/RHFUnRegisterCheckBox';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import RHFInputRange from './RHFInputRange';

type Props = {
  methods: UseFormReturn;
  existingQuestion?: Question;
};

const TypeValidationForm = ({ methods, existingQuestion }: Props) => {
  const {
    rulesTypeValidationErrorMessage,
    rulesCreateQuestionNumberRange1,
    rulesCreateQuestionNumberRange2,
    rulesCreateQuestionNumberOfDecimalPlaces,
    rulesCreateQuestionStringRange1,
    rulesCreateQuestionStringRange2,
    rulesPatternRequire,
  } = useValidator(methods);
  const [isTypeChecked] = useState<boolean>(existingQuestion ? ((existingQuestion.validation as Validation).pattern ? true : false) : false);
  const { t } = useTranslation();

  useEffect(() => {
    const patternValue = existingQuestion?.validation?.pattern?.value;
    methods.setValue(
      'pattern',
      [GENERAL_ENQUETE_VALIDATION_PATTERN.NUMBER, GENERAL_ENQUETE_VALIDATION_PATTERN.STRING].find((v) => v === patternValue),
    );
    methods.setValue('typeCheck', isTypeChecked);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * 形式の設定に応じた入力フォームを返却する。
   * @return RHFInputRange
   */
  const switchInputTypeRender = () => {
    switch (methods.watch('pattern')) {
      case GENERAL_ENQUETE_VALIDATION_PATTERN.NUMBER:
        return (
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={5}>
              <RHFInputRange
                control={methods.control}
                minRules={rulesCreateQuestionNumberRange1}
                maxRules={rulesCreateQuestionNumberRange2}
                question={existingQuestion}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={8} sx={{ textAlign: 'center' }}>
                  <Typography variant="body1">
                    {t('enqueteCreate.validation.numberOfDecimalPlaces')}
                    <Tooltip
                      title={t('enqueteCreate.tooltip.numberOfDecimalPlaces')
                        .split('\n')
                        .map((text) => (
                          <div key={text}>{text}</div>
                        ))}>
                      <IconButton>
                        <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <RHFNumericField
                    name="setNumberOfDecimalPlaces"
                    control={methods.control}
                    rules={rulesCreateQuestionNumberOfDecimalPlaces}
                    defaultValue={existingQuestion ? existingQuestion.validation?.numberOfDecimalPlaces?.value : ''}
                    textFieldProps={{ sx: { maxWidth: 220 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case GENERAL_ENQUETE_VALIDATION_PATTERN.STRING:
        return (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid container item xs={5}>
              <RHFInputRange
                control={methods.control}
                minRules={rulesCreateQuestionStringRange1}
                maxRules={rulesCreateQuestionStringRange2}
                question={existingQuestion}
              />
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RHFUnRegisterCheckBox
          name={'typeCheck'}
          control={methods.control}
          defaultChecked={isTypeChecked}
          label={t('enqueteCreate.validation.type')}
          fieldName={['pattern', 'setMin', 'setMax', 'typeValidationErrorMessage']}
          unregister={methods.unregister}
        />
      </Grid>
      {methods.watch('typeCheck', '') ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-start" wrap="wrap">
              <Grid item>
                <RHFDropdown
                  name="pattern"
                  control={methods.control}
                  rules={rulesPatternRequire}
                  label={t('enqueteCreate.validation.answerType')}
                  handleOnChange={(event) => {
                    methods.setValue('setMin', '');
                    methods.setValue('setMax', '');
                    methods.setValue('setNumberOfDecimalPlaces', '');
                    methods.clearErrors('setMin');
                    methods.clearErrors('setMax');
                    methods.clearErrors('setNumberOfDecimalPlaces');
                  }}
                  defaultValue={
                    existingQuestion
                      ? existingQuestion.validation?.pattern
                        ? !(existingQuestion.validation.pattern.value instanceof RegExp)
                          ? existingQuestion.validation.pattern.value
                          : ''
                        : ''
                      : ''
                  }>
                  <MenuItem value="number">{t('enqueteCreate.validation.typeMenu.number')}</MenuItem>
                  <MenuItem value="string">{t('enqueteCreate.validation.typeMenu.string')}</MenuItem>
                </RHFDropdown>
              </Grid>
              <Grid item xs={10}>
                {switchInputTypeRender()}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">{t('enqueteCreate.validation.typeMessage')}</Typography>
            <RHFTextField
              name="typeValidationErrorMessage"
              defaultValue={
                existingQuestion
                  ? (existingQuestion.validation as Validation).pattern
                    ? existingQuestion.validation?.pattern?.errorMessage
                    : t('enqueteCreate.validation.typeDefultMessage')
                  : t('enqueteCreate.validation.typeDefultMessage')
              }
              control={methods.control}
              fullWidth
              margin="normal"
              variant="outlined"
              rules={rulesTypeValidationErrorMessage}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default TypeValidationForm;
