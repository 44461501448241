import { HEADER_WIDTH } from 'src/generalConstants';
import { MainTableType } from '../@types/generalEnquete';

// 初期メイン表データ作成
export const createMainTableInitialData = (row: number, col: number): MainTableType => {
  return {
    sourceData: createTableInitialData(row, col),
    answerData: [],
    cell: [],
    mergeCells: [],
    colWidths: [],
    rowHeights: [],
    rowHeaders: [],
    nestedHeaders: [],
  };
};

// 初期データ配列作成
export const createTableInitialData = (row: number, col: number) => {
  let initialData: string[][] = [];
  for (let i = 1; i <= row; i++) {
    let colData: string[] = [];
    for (let j = 1; j <= col; j++) {
      colData.push('');
    }
    initialData.push(colData);
  }
  return initialData;
};

export const calcRowHeaderWidth = (rowHeaders: string[] | undefined) => {
  // 長さの計算
  const calculateLength = (str: string): number => {
    // 入力できる文字：[ -~\u2460-\u24E9\u3000-\u30ff\u3200-\u33ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\uff9f\uffe0-\uffee]
    // 半角を除く（-~（0020～007e）、ff61-ff9f、ffe8-ffee）
    const fullWidthRegex = /[\u2460-\u24E9\u3000-\u30ff\u3200-\u33ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\uFF60\uffe0-\uffe7]/;
    return Array.from(str).reduce((length, char) => {
      // memo: 1文字につき 13px 確保(半角文字は7px確保)
      return length + (fullWidthRegex.test(char) ? HEADER_WIDTH.FULL_WIDTH : HEADER_WIDTH.HALF_WIDTH);
    }, 0);
  };
  let rowHeaderWidth: number | undefined = undefined;
  if (Array.isArray(rowHeaders)) {
    let maxRowHeaderWidth = 0;
    rowHeaders.forEach((rowHeader) => {
      // 計算結果を取得
      let length = calculateLength(rowHeader);
      if (maxRowHeaderWidth < length) maxRowHeaderWidth = length;
    });
    if (0 < maxRowHeaderWidth) {
      // 余白としてさらに 2文字分確保しておく（26px追加）
      rowHeaderWidth = maxRowHeaderWidth + HEADER_WIDTH.MARGIN;
    }
  }
  return rowHeaderWidth;
};
