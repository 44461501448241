export type GetAggregateSupplement = {
  currentYear: string;
  previousYear: string;
  answeredAggregateSupplement: AggregateSupplementData;
  defaultAggregateSupplement: AggregateSupplementData;
};
export type AggregateSupplement = {
  aggregateSupplementData: AggregateSupplementData;
};
export class AggregateSupplementData {
  aggregateCustomers: AggregateCustomer[];
  constructor() {
    this.aggregateCustomers = [];
  }
}

export type AggregateCustomer = {
  customerCode: string;
  customerNameJpn: string;
  customerNameEng: string;
  currentSalesAggregate: number;
  previousSalesAggregate: number;
  currentPurchaseAggregate: number;
  previousPurchaseAggregate: number;
  aggregateDivisions: AggregateDivision[];
  aggregateRegions: AggregateRegion[];
};

export type AggregateDivision = {
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  currentSalesAggregate: number;
  previousSalesAggregate: number;
  currentPurchaseAggregate: number;
  previousPurchaseAggregate: number;
  salesSupplementComment: string;
  purchaseSupplementComment: string;
};

export type AggregateRegion = {
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  currentSalesAggregate: number;
  previousSalesAggregate: number;
  currentPurchaseAggregate: number;
  previousPurchaseAggregate: number;
  salesSupplementComment: string;
  purchaseSupplementComment: string;
};
