import { EnqueteRequestListPage } from '../pages/EnqueteRequestListPage';

//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteRequestRoute = {
  path: 'enquete-request',
  children: [
    {
      path: '',
      element: <EnqueteRequestListPage />,
    },
  ],
};
