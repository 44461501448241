import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { setCustomerGroups } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useProposalInitValue } from '../../../tab-containers/series-survey/components/tabs/components/common/hooks/useProposalInitValue';
import { useTradingVolumeForm } from '../../../tab-containers/series-survey/components/tabs/components/common/hooks/useTradingVolumeForm';
import { useCheckCustomer } from './useCheckCustomer';
import { SelectedRowType } from './useGetSelectedRow';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getSelectedRowElement: () => SelectedRowType[];
}

export const useTypeSeriesSurveyClickHandle = (props: Props) => {
  const { setIsOpen, getSelectedRowElement } = props;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { initialValueProposalCustomer } = useEnqueteAnswerInputApi();
  const { setCustomerGroup } = useProposalInitValue();
  const { checkRegisteredCustomer } = useCheckCustomer();
  const { initDefaultValues } = useTradingVolumeForm();

  const [isAddCustomerLoading, setIsAddCustomerLoading] = useState<boolean>(false);

  // 取得したRespondentTargetの情報を格納
  const { customerGroups } = useAppSelector((state) => state.seriesAnswer);
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);

  /**
   * 取引先追加のオンクリックイベント
   * @returns
   */
  const seriesSurveyOnClick = async () => {
    // 追加データ構築に時間がかかるためLoadingにする
    setIsAddCustomerLoading(true);
    // 追加されたデータを取得
    const selectElement = getSelectedRowElement();
    const searchElement: string[] = [];
    // 取引先の重複チェック
    selectElement.forEach((elm) => {
      const isUnRegistered = checkRegisteredCustomer(elm.customerCode, elm.parentCustomerCode, customerGroups);
      isUnRegistered && searchElement.push(elm.customerCode);
    });

    if (searchElement.length === 0) {
      setIsOpen(false);
      setIsAddCustomerLoading(false);
      return;
    }

    // 取引先データと初期値提案を取得
    const addCustomerData = await initialValueProposalCustomer(respondentTargetData.id, searchElement);

    // 上記データを取得できなかった場合はスナックバーを表示し,return
    if (!addCustomerData) {
      enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteAnswerInput.seriesSurvey.initialValueProposal') }), {
        variant: 'error',
      });
      setIsAddCustomerLoading(false);
      setIsOpen(false);
      return;
    }

    // 取得したデータでCustomerGroup[]を構築
    const result = await setCustomerGroup(addCustomerData);

    // グローバルステートは直接変更できないため変数に格納
    const newCustomerGroups = [...customerGroups];
    result.customerGroups.forEach((res) => {
      // 親取引先の位置を取得
      const parentIndex = newCustomerGroups.findIndex((customer) => customer.parentCustomerCode === res.parentCustomerCode);

      // 親取引先の位置を取得したらそのまま取引先をpush
      if (parentIndex >= 0) {
        const newElement = {
          ...newCustomerGroups[parentIndex],
          childrenCustomer: [...newCustomerGroups[parentIndex].childrenCustomer, ...res.childrenCustomer],
        };
        newCustomerGroups[parentIndex] = newElement;
      } else {
        // 親取引先がなかった場合：親取引先含めてpush
        newCustomerGroups.push(res);
      }
    });
    dispatch(setCustomerGroups(newCustomerGroups));
    initDefaultValues(respondentTargetData.id, newCustomerGroups);
    setIsAddCustomerLoading(false);
    setIsOpen(false);
  };
  return { isAddCustomerLoading, seriesSurveyOnClick };
};
