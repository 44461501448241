import { Box, Button, Typography, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ThreadTextBoxProps = {
  text: string;
  lineCount?: number;
};

export const ThreadTextBox = ({ text, lineCount = 3 }: ThreadTextBoxProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showReadMore, setShowRreadMore] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const fontSize = theme.typography.body1.fontSize;
  const lineHeight = theme.typography.body1.lineHeight || 1.5;
  const maxHeight = `calc(${fontSize} * ${lineHeight} * ${lineCount})`;

  const shouldShowReadMore = useCallback((): boolean => {
    if (!contentRef.current) return false;
    return contentRef.current.scrollHeight > contentRef.current.offsetHeight || isExpanded;
  }, [contentRef, isExpanded]);

  useEffect(() => {
    setShowRreadMore(shouldShowReadMore());
  }, [text, isExpanded, shouldShowReadMore]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Box
        ref={contentRef}
        component="div"
        data-testid="text-box"
        sx={{
          maxHeight: isExpanded ? 'none' : maxHeight,
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word',
        }}>
        <Typography variant="body1" component="p" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
      </Box>
      {showReadMore && (
        <Button onClick={handleToggle} variant="text" sx={{ display: 'block', m: 0, p: 0 }}>
          {isExpanded ? t('button.showLess') : t('button.showMore')}
        </Button>
      )}
    </>
  );
};
