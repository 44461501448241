import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowConfirm } from 'src/features/general/enquete-answer/store/selectConfirmerModalSlice';
import useSuggestUser from 'src/hooks/useSuggestUser';
import { RootState } from 'src/redux/store';
import { SelectConfirmerModal } from './components/SelectConfirmerModal';
import { SubmitConfirmModal } from './components/SubmitConfirmModal';

export interface Props {
  surveyDetailId: string | undefined;
  answerId: string | undefined;
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export const SelectConfirmerModalLayout: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { updateUserSuggest } = useSuggestUser();
  const { showConfirm } = useSelector((state: RootState) => state.selectConfirmerModal);

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    if (props.open) {
      updateUserSuggest();
      dispatch(setShowConfirm(false));
    }

    return () => {
      dispatch(setShowConfirm(false));
    };
  }, [props.open]); // eslint-disable-line

  return (
    <>
      {/* 送信者選択画面 */}
      <SelectConfirmerModal surveyDetailId={props.surveyDetailId} open={props.open} onClose={props.onClose} onComplete={props.onComplete} />

      {/* 送信確認画面 */}
      {showConfirm && <SubmitConfirmModal answerId={props.answerId} open={props.open} onClose={props.onClose} onComplete={props.onComplete} />}
    </>
  );
};
