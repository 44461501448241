import HelpIcon from '@mui/icons-material/Help';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { ColDef, ColGroupDef, ValueSetterParams } from 'ag-grid-community';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerManager, SeriesEnqueteOptionData } from 'src/@types/seriesEnquete';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { setSeriesEnqueteOptionCustomer, setSeriesEnqueteOptionData } from 'src/features/general/enquete-create/store/enqueteOptionSlice';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';

type Props = {
  seriesEnqueteOptionData?: SeriesEnqueteOptionData;
  isClosed: boolean;
};

const CustomerManagerForm: React.FC<Props> = (props) => {
  const { seriesEnqueteOptionData, isClosed } = props;
  const { currentLang } = useLocales();
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: false,
    resizable: true,
    editable: true,
    filter: false,
    minWidth: 300,
  };

  const dispatch = useDispatch();
  const saveNewValue = (params: ValueSetterParams<CustomerManager>) => {
    let field = params.column.getColId() as keyof CustomerManager;
    let newRow = { ...params.data };
    newRow[field] = params.newValue;
    dispatch(setSeriesEnqueteOptionCustomer(newRow));
    return true;
  };

  const isJapanese = currentLang.value === 'ja';

  const coldef: (ColDef | ColGroupDef)[] = [
    {
      field: isJapanese ? 'customerNameJpn' : 'customerNameEng',
      headerName: t('enqueteCreate.selectOptionModal.customerTable.header.customer'),
      editable: false,
      valueFormatter: (params: any): string => (isJapanese ? trimStockCompany(params.value) : params.value),
    },
    {
      field: 'manager',
      headerName: t('enqueteCreate.selectOptionModal.customerTable.header.manager'),
      editable: !isClosed,
      valueSetter: saveNewValue,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellStyle: { whiteSpace: 'pre-wrap' },
      autoHeight: true,
    },
    {
      field: 'subManager',
      headerName: t('enqueteCreate.selectOptionModal.customerTable.header.subManager'),
      editable: !isClosed,
      valueSetter: saveNewValue,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellStyle: { whiteSpace: 'pre-wrap' },
      autoHeight: true,
    },
  ];

  const onGridReady = () => {
    if (seriesEnqueteOptionData) dispatch(setSeriesEnqueteOptionData(seriesEnqueteOptionData));
  };

  const onCellValueChanged = (params: any) => {
    // saveNewValueを発火させるため、フォーカスを外して、再度フォーカスを行う
    const cellPosition = params.api.getFocusedCell();
    setTimeout(() => {
      params.api.setFocusedCell(cellPosition!.rowIndex, cellPosition!.column);
    }, 100);
  };

  const optionData = useAppSelector((state: RootState) => state.enqueteOption.seriesEnqueteOptionData);

  return (
    <StyledBox>
      <Grid item xs={12} md={12}>
        <Typography>
          {t('enqueteCreate.selectOptionModal.customerAccountManager')}
          <Tooltip title={<div>{t('aggrid.helper.textarea')}</div>} placement="right">
            <IconButton>
              <HelpIcon color="inherit" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: '60vh', width: '100%' }}>
              <LoadableComponent isLoading={false}>
                <StyledAgGrid
                  defaultColDef={defaultColDef}
                  coldef={coldef}
                  rows={optionData.customerManager}
                  onGridReady={onGridReady}
                  onCellValueChanged={onCellValueChanged}
                />
              </LoadableComponent>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default CustomerManagerForm;
