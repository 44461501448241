import { createSlice } from '@reduxjs/toolkit';
import { API_CALL_STATUS } from 'src/api/api-constants';
import { EnqueteThreadsEntity, PaginatedEnqueteThread } from 'src/api/getThreadsApi';
import { fetchThreadlist } from './enqueteThreadActions';

export const enqueteThreadsSlice = createSlice({
  name: 'enquete-thread',
  initialState: {
    threads: { enqueteThreads: [], total: 0 } as PaginatedEnqueteThread,
    status: '' as EnqueteThreadsEntity['status'],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchThreadlist.fulfilled, (state, action) => {
      if (action.payload) {
        state.threads = action.payload;
      }
      state.status = API_CALL_STATUS.FULFILLED;
    });
    builder.addCase(fetchThreadlist.pending, (state, action) => {
      state.status = API_CALL_STATUS.PENDING;
    });
    builder.addCase(fetchThreadlist.rejected, (state, action) => {
      state.status = action.error;
    });
  },
});

export default enqueteThreadsSlice.reducer;
