import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from 'src/features/general/enquete-create/store/dbMailDetail';

//メールフォーム
export interface MailFormDataProps extends roopStringKey {
  subject: string;
  content: string;
}

//メール検証
export interface inputErrorProps extends roopStringKey {
  subject: validationTypeProps;
  content: validationTypeProps;
}

//検証内容
export interface validationTypeProps extends roopStringKey {
  required: boolean;
}

//オブジェクトループ用
interface roopStringKey {
  [key: string]: any;
}

//IndexedDB
export interface LocalStoreProps extends MailFormDataProps {
  respondetRequestId: string;
  files?: File[];
}

const useRequestMailForm = () => {
  const initMailFormData = { subject: '', content: '' };
  const initInputError = { subject: { required: false }, content: { required: false } };

  const { respondentRequestId } = useParams();

  const [isLoadStore, setIsLoadStore] = useState<boolean>(false);
  const [mailFormData, setMailFormData] = useState<MailFormDataProps>(initMailFormData);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [validationError, setValidationError] = useState<inputErrorProps>(initInputError);

  //メール内容を管理変数に格納
  const onChangeMailForm = (e: ChangeEvent<HTMLInputElement>) => {
    //入力値を格納
    setMailFormData({ ...mailFormData, ...{ [e.target.name]: e.target.value } });
    //フォームエラー検証
    validationFormData(e);
  };

  //フォームエラー検証
  const validationFormData = (e: ChangeEvent<HTMLInputElement>) => {
    //空文字か判定
    setValidationError((prevState: inputErrorProps) => ({
      ...prevState,
      ...{ [e.target.name]: { required: e.target.value === '' } },
    }));
  };

  //空白フォームがあるか検証、結果を返却
  const checkEmptyForm = () => {
    let isExistEmptyForm: boolean = false;

    //空白フォームをエラーに指定
    Object.keys(mailFormData).forEach((formName: string) => {
      if (mailFormData[formName] === '') {
        setValidationError((prevState: inputErrorProps) => ({
          ...prevState,
          ...{ [formName]: { required: true } },
        }));
        isExistEmptyForm = true;
      }
    });

    return isExistEmptyForm;
  };

  //エラーになっている項目があるか検証、結果を返却
  const checkExistError = () => {
    let isExistError: boolean = false;

    //検証フォーム名の配列作成
    const formNameList: string[] = Object.keys(validationError);
    //各フォームでエラーになっている項目があるかチェックし、あればTrueを返却
    formNameList.forEach((formName: string) => {
      const validationName: string[] = Object.keys(validationError[formName]);

      //エラー項目判定
      validationName.forEach((validationName: string) => {
        if (validationError[formName][validationName]) isExistError = true;
      });
    });

    return isExistError;
  };

  //ローカルストレージにデータを保存する
  const saveMailFormDataToLocalStore = useCallback(() => {
    db.mailDetail.put({
      respondetRequestId: respondentRequestId as string,
      subject: mailFormData.subject,
      content: mailFormData.content,
      files: attachedFiles,
    });
  }, [attachedFiles, mailFormData.content, mailFormData.subject, respondentRequestId]);

  //ローカルストレージデータが存在していれば、フォームの値を置き換える
  const loadMailFormDataToLocalStore = useCallback(() => {
    if (respondentRequestId === undefined) return;

    setIsLoadStore(true);
    db.mailDetail
      .get(respondentRequestId)
      .then((fileData: LocalStoreProps | undefined) => {
        if (fileData !== undefined) {
          setMailFormData((state) => {
            return { ...state, ...{ subject: fileData.subject }, ...{ content: fileData.content } };
          });
        }

        if (fileData !== undefined && fileData.files !== undefined) setAttachedFiles([...fileData.files]);

        setIsLoadStore(false);
      })
      .catch((error) => {
        setIsLoadStore(false);
      });
  }, [respondentRequestId]);

  //添付ファイルを管理配列に追加
  const addAttachedFile = (e: ChangeEvent<HTMLInputElement>, allowedExtensionsString: string) => {
    const files = e.target.files;
    if (files?.length === 0 || files === null) return false;

    // memo: 許可される拡張子を配列にする
    const allowdExtensionsArray = allowedExtensionsString
      .split(',')
      .map((item) => item.trim())
      .filter(Boolean);

    // memo: 指定された拡張子以外が含まれるときははじく
    for (const file of files) {
      const extension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
      if (!allowdExtensionsArray.includes(extension)) {
        return false;
      }
    }

    setAttachedFiles((currentFilesList: File[]) => [...currentFilesList, ...[files[0]]]);
    return true;
  };

  // todo:
  //   ここの useEffect も本来は不要と思われる
  //   mailFormData / attachedFiles のステートを更新しているイベント処理において、
  //   saveMailFormDataToLocalStore を call すればよいはず（動作未確認）
  //   →　最初のロードはやはり useEffect で副作用として実行してやるのが良いかなと思います。
  //   →　また、保存はUnmountのタイミングでやるよう今の方針で問題対応しています。

  useEffect(() => {
    loadMailFormDataToLocalStore();
  }, [loadMailFormDataToLocalStore]);

  useEffect(() => {
    return () => {
      saveMailFormDataToLocalStore();
    };
  }, [mailFormData, attachedFiles, saveMailFormDataToLocalStore]);

  return {
    isLoadStore,
    mailFormData,
    attachedFiles,
    validationError,
    onChangeMailForm,
    setAttachedFiles,
    addAttachedFile,
    checkEmptyForm,
    checkExistError,
    loadMailFormDataToLocalStore,
  };
};

export default useRequestMailForm;
