import { Box, Card, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { RootState, useSelector } from 'src/redux/store';
import { useLogicData } from '../hooks/useLogicData';
import { ConditionEditNotAllowedCard } from './ConditionEditNotAllowedCard';

type ConditionEditFormProps = {
  question: Question;
  index: number;
};

export const ConditionEditForm = ({ question, index }: ConditionEditFormProps) => {
  const { t } = useTranslation();
  const logicQuestions: Question[] = useSelector((state: RootState) => state.enqueteCreate.logicQuestions);
  const { handleConditionChange, getAfterQuestions } = useLogicData();

  return (
    <>
      {/** 最後の質問は条件を設定できない */}
      {question.id === logicQuestions[logicQuestions.length - 1].id ? (
        <ConditionEditNotAllowedCard title={question.title} index={index} message={t('enqueteCreate.logic.lastQuestionMessage')} />
      ) : (
        <Card sx={{ p: 2, m: 2 }} key={question.id}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t('enqueteCreate.questionNo')}
            {index + 1} {question.title}
          </Typography>
          {question.questionItem.map((option) => (
            <StyledBox key={option.id}>
              <Typography variant="body1" sx={{ m: 1 }}>
                {option.label}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-round',
                }}>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id={option.id}>{t('enqueteCreate.logic.logic')}</InputLabel>
                  <Select
                    labelId={option.id}
                    id={'logic'}
                    value={question.conditions?.find((condition) => condition.id === option.id)?.logic ?? ''}
                    label="Logic"
                    onChange={(event) => handleConditionChange(event, question.id, option.id, 'logic')}>
                    <MenuItem value="">None</MenuItem>
                    {/* Memo：ロジック機能複雑になるため[表示]を選択できないようにしています2023年9月時点 */}
                    {/* showのコメントアウトを外し、画面から選択できるようにすれば、機能としては動作するようになります。 */}
                    <MenuItem value={'skip'}>{t('enqueteCreate.logic.skip')}</MenuItem>
                    <MenuItem value={'skip-disable'}>{t('enqueteCreate.logic.skipDisable')}</MenuItem>
                    <MenuItem value={'hide'}>{t('enqueteCreate.logic.hide')}</MenuItem>
                    <MenuItem value={'disable'}>{t('enqueteCreate.logic.disable')}</MenuItem>
                    {/* <MenuItem value={'show'}>{t('enqueteCreate.logic.show')}</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, flexGrow: 1 }}>
                  <InputLabel id={option.id}>{t('enqueteCreate.logic.targetQuestion')}</InputLabel>
                  <Select
                    labelId={option.id}
                    id={'target-question'}
                    value={question.conditions?.find((condition) => condition.id === option.id)?.targetQuestionId ?? ''}
                    label="Target Question"
                    onChange={(event) => handleConditionChange(event, question.id, option.id, 'target-question')}>
                    <MenuItem value="">None</MenuItem>
                    {getAfterQuestions(question.id).map((question, afterIndex) => (
                      <MenuItem value={question.id} key={question.id}>
                        {index + 1 + afterIndex + 1}:{question.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </StyledBox>
          ))}
        </Card>
      )}
    </>
  );
};
