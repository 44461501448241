import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getThreadCreatorApi } from 'src/api/getThreadCreatorApi';
import { Pagination, getThreadsApi } from 'src/api/getThreadsApi';

export const fetchThreadlist = createAsyncThunk('enquete-thread/useThreads', async ({ page, take }: Pagination, thunkAPI) => {
  try {
    const { getThreads } = getThreadsApi();
    const threads = await getThreads(page, take);
    return threads;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
});

export const fetchThreadPriorInfo = createAsyncThunk('enquete-thread/createThread', async (_args, thunkAPI) => {
  try {
    const { getThreadPriorInfo } = getThreadCreatorApi();
    const threads = await getThreadPriorInfo();
    return threads;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
});

export const fetchThreadById = createAsyncThunk('enquete-thread/useThread', async (id: string | undefined, thunkAPI) => {
  if (id === undefined) {
    return undefined;
  }
  try {
    const { getThreadById } = getThreadsApi();
    const { getThreadPriorInfo } = getThreadCreatorApi();
    const result = await Promise.all([getThreadById(id), getThreadPriorInfo()]);
    return result;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
});
