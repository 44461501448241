import { ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import { switchLabel } from './labelUtils';

/**
 * S500以外の組織の本部以下を表示させない
 *
 * @param params
 * @param targetCode
 * @param colNameJpn
 * @param colNameEng
 * @param isLangJpn
 * @returns
 */
export const invisibleConditionOrganization = (
  params: ValueGetterParams | ITooltipParams,
  targetCode: string,
  colNameJpn: string,
  colNameEng: string,
  isJapanese: boolean,
) => {
  if (!params.data[colNameJpn] && !params.data[colNameEng]) return '-';

  return params.data[targetCode] === 'S500' ? switchLabel(params.data[colNameJpn], params.data[colNameEng], isJapanese) : '-';
};

/**
 * ユーザー名出力
 *
 * @param params
 * @param colNameJpn
 * @param colNameEng
 * @param isJapanese
 * @returns
 */
export const showUserName = <T, K1 extends keyof T, K2 extends keyof T>(
  params: ValueGetterParams<T> | ITooltipParams<T>,
  colNameJpn: K1,
  colNameEng: K2,
  isJapanese: boolean,
) => {
  if (!params.data) return '';
  const getTrimmedName = (key: K1 | K2) => {
    if (!params.data) return;
    const name = params.data[key];
    return typeof name === 'string' ? name.trim() : '';
  };

  const jpn = switchLabel(getTrimmedName(colNameJpn) ?? null, getTrimmedName(colNameEng) ?? null, true) ?? '';
  const eng = switchLabel(getTrimmedName(colNameJpn) ?? null, getTrimmedName(colNameEng) ?? null, false) ?? '';

  return isJapanese ? jpn || eng || '-' : eng || jpn || '-';
};
