import axios from 'axios';
import { useCallback } from 'react';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export type NotificationData = {
  id: number;
  titleJpn: string;
  titleEng: string;
  contentJpn: string;
  contentEng: string;
  category: string;
  publishedAt: string;
  isDeleted: boolean;
};

export type NotificationListData = {
  data: NotificationData[] | undefined;
  total: number;
};

export type NotificationRequestData = Omit<NotificationData, 'id'>;

export const useNotificationApi = () => {
  const { getBearerToken } = useBearerToken();

  const create = useCallback(
    async (data: NotificationRequestData): Promise<void> => {
      const token = await getBearerToken();
      return await axios.post(apiGateway + `/notification`, data, {
        headers: { Authorization: token },
        timeout: 60000,
      });
    },
    [getBearerToken],
  );

  const update = useCallback(
    async (id: string, data: NotificationRequestData): Promise<void> => {
      const token = await getBearerToken();
      return await axios.put(apiGateway + `/notification/${id}`, data, {
        headers: { Authorization: token },
        timeout: 60000,
      });
    },
    [getBearerToken],
  );

  const getById = useCallback(
    async (id: string): Promise<NotificationData> => {
      const token = await getBearerToken();
      const response = await axios.get<NotificationData>(apiGateway + `/notification/${id}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    },
    [getBearerToken],
  );

  const getByIdForAdmin = useCallback(
    async (id: string): Promise<NotificationData> => {
      const token = await getBearerToken();
      const response = await axios.get<NotificationData>(apiGateway + `/notification/admin/${id}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    },
    [getBearerToken],
  );

  const getAllList = useCallback<(page: number, take: number) => Promise<NotificationListData>>(
    async (page: number, take: number): Promise<NotificationListData> => {
      const token = await getBearerToken();
      const response = await axios.get<NotificationListData>(apiGateway + `/notification?page=${page}&take=${take}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    },
    [getBearerToken],
  );

  const getAllListForAdmin = useCallback<(page: number, take: number) => Promise<NotificationListData>>(
    async (page: number, take: number): Promise<NotificationListData> => {
      const token = await getBearerToken();
      const response = await axios.get<NotificationListData>(apiGateway + `/notification/admin?page=${page}&take=${take}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    },
    [getBearerToken],
  );

  const deleteById = useCallback(
    async (id: string): Promise<void> => {
      const token = await getBearerToken();
      return await axios.delete(apiGateway + `/notification/${id}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
    },
    [getBearerToken],
  );

  return {
    create,
    update,
    getById,
    getByIdForAdmin,
    getAllList,
    deleteById,
    getAllListForAdmin,
  };
};
