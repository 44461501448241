import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Question, State } from 'src/@types/generalEnquete';
import RHFRadioGroup from 'src/components/react-hook-form/RHFRadioGroup';
import { useEnqueteAnswerHandleOnChange } from '../hooks/useEnqueteAnswerHandleOnChange';

type EnqueteAnswerRadioGroupFormProps = {
  question: Question;
  index: number;
  methods?: UseFormReturn;
  validationRule?: Object;
  questionState?: State;
};

const EnqueteAnswerRadioGroupForm = ({ question, index, methods, validationRule, questionState }: EnqueteAnswerRadioGroupFormProps) => {
  const { handleOnChangeDropdownAndRadio, dropdownAndRadioDefaultValue } = useEnqueteAnswerHandleOnChange();

  useEffect(() => {
    if (methods && question.answerItem) {
      methods?.setValue(question.id, dropdownAndRadioDefaultValue(index, question, methods));
    }
  }, [dropdownAndRadioDefaultValue, index, methods, question, question.answerItem]);
  // バリデーション処理に、初期表示時点の入力状態を設定する

  // 質問表示状態が変ったらルールをスイッチする。
  useEffect(() => {
    if (methods && question.answerItem) {
      if (methods?.getValues(question.id) !== undefined) {
        const stateRule = questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule;
        const value = methods?.getValues(question.id);
        methods?.control.unregister(question.id);
        methods?.control.register(question.id, stateRule);
        methods?.setValue(question.id, value);
        methods?.trigger(question.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionState]);

  return (
    <>
      {methods ? (
        <RHFRadioGroup
          name={question.id}
          control={methods.control}
          rules={questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule}
          defaultValue={dropdownAndRadioDefaultValue(index, question, methods)}
          questionItem={question.questionItem}
          key={question.id}
          handleOnChange={(event) => {
            handleOnChangeDropdownAndRadio(event, index, question);
          }}
          disabled={questionState === 'disabled'}
        />
      ) : (
        <RadioGroup defaultValue={''}>
          {question.questionItem.map((item, index) => (
            <FormControlLabel control={<Radio />} value={item.id} label={item.label} key={item.id} />
          ))}
        </RadioGroup>
      )}
    </>
  );
};

export default EnqueteAnswerRadioGroupForm;
