import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { NotificationData, useNotificationApi } from 'src/api/useNotificationApi';

export const useNotification = () => {
  const [data, setData] = useState<NotificationData[]>();
  const [isVisible, setIsVisible] = useState(false);
  const { getAllList } = useNotificationApi();
  const page = 1;
  const take = 4;

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getAllList(page, take);
        if (!response?.data) return;
        if (response.data?.length === 0) setIsVisible(false);
        setData(response.data);
        // memo: ローカルストレージから最後に確認した公開日時を取得して比較する
        const closedNotificationTime = localStorage.getItem('closedNotificationTime');
        if (!closedNotificationTime || new Date(closedNotificationTime) < new Date(response.data[0].publishedAt)) {
          setIsVisible(true);
        }
      } catch {
        setIsVisible(false);
      }
    };
    fetchNotifications();
  }, [getAllList]);

  const handleClose = useCallback(
    (event?: Event | SyntheticEvent) => {
      event?.stopPropagation();
      if (data) {
        setIsVisible(false);
        localStorage.setItem('closedNotificationTime', data[0].publishedAt.toString());
      }
    },
    [data],
  );

  return { notifications: data, isVisible, handleClose, setIsVisible };
};
