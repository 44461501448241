import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { EnqueteThreadEditLayout } from '../components/pages/edit/components/EnqueteThreadEditLayout';

export const EnqueteThreadEditPage = () => {
  const { t } = useTranslation();
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('enqueteThread.create.editThread'),
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('enqueteThread.pageTitle'), href: '/general/enquete-threads/' },
    ],
    activeLast: true,
  };
  return (
    <PageLayout title={t('enqueteThread.create.editThread')} breadcrumbs={breadcrumbs}>
      <EnqueteThreadEditLayout />
    </PageLayout>
  );
};
