import React from 'react';
import { FORM_TYPE } from 'src/constants';
import { TabValue } from 'src/features/general/enquete-create/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { GeneralQuestionTabContainer } from '../../../tab-containers/type-general-question/components/GeneralQuestionTabContainer';
import { GeneralTableTabContainer } from '../../../tab-containers/type-general-table/components/GeneralTableTabContainer';
import { TypeUndefinedTabContainer } from '../../../tab-containers/type-undefined/components/TypeUndefinedTabContainer';

export interface GeneralEnqueteTypeSwitcherProps {
  id: string | undefined;
  selectedTabValue: TabValue;
  isNewCreateMode: boolean;
}

export const GeneralEnqueteTypeSwitcher: React.FC<GeneralEnqueteTypeSwitcherProps> = (props) => {
  const { id, selectedTabValue, isNewCreateMode } = props;

  const isSetup = useAppSelector((state) => state.enqueteInfo.isSetup);
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const formType = enqueteData.surveyDetail?.surveyHeader?.formType;

  if (isNewCreateMode && (!isSetup || formType === undefined)) {
    return <TypeUndefinedTabContainer id={id} selectedTabValue={selectedTabValue} />;
  }
  switch (formType) {
    // 一問一答
    case FORM_TYPE.QA:
      return <GeneralQuestionTabContainer id={id} selectedTabValue={selectedTabValue} />;
    // 表形式
    case FORM_TYPE.TABLE:
      return <GeneralTableTabContainer id={id} selectedTabValue={selectedTabValue} />;
    default:
      return null;
  }
};
