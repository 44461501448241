import { Button, Stack } from '@material-ui/core';
import { Alert, Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { REQUEST_STATUS } from 'src/constants';
import { useAppSelector } from 'src/redux/store';
import { useOwnerTabContainer } from '../hooks/useOwnerTabContainer';
import { useOwnerTabContainerColdef } from '../hooks/useOwnerTabContainerColdef';

export const OwnerTabContainer: React.FC = () => {
  const { t } = useTranslation();
  const { isAdmin, isSurveyOwner } = useAppSelector((state) => state.overviewAggregateSupplement);
  const requestStatus = useAppSelector((state) => state.enqueteStatus.requestStatus);
  const { divisionAggregateSupplements, topic } = useAppSelector((state) => state.overviewAggregateSupplement);

  const { control, getValues, setValue, handleSubmit, trigger } = useForm({
    mode: 'onChange',
  });
  const { defaultColDef, colDef, gridOptions } = useOwnerTabContainerColdef();
  const { onClickReflectKeyTopics, onGridReady, onClickSave, isSaving } = useOwnerTabContainer(getValues, setValue, trigger);

  const isButtonDisabled = requestStatus !== REQUEST_STATUS.IN_PROGRESS || !(isAdmin || isSurveyOwner);

  const renderAlert = () => {
    if (requestStatus !== REQUEST_STATUS.IN_PROGRESS) {
      return <Alert severity="warning"> {t('enqueteStatus.aggregateSupplement.warning.notInProgress')}</Alert>;
    }
  };

  useEffect(() => {
    setValue('topics', topic);
  }, [topic, setValue]);

  return (
    <Stack direction={'column'} spacing={2}>
      {renderAlert()}
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
        <Button color="primary" variant="contained" onClick={onClickReflectKeyTopics} disabled={isButtonDisabled}>
          {t('enqueteStatus.aggregateSupplement.button.reflectKeyTopics')}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit(onClickSave)} disabled={isButtonDisabled || isSaving}>
          {t('button.save')}
        </Button>
      </Stack>
      <Box sx={{ height: '400px', width: '100%' }}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={colDef}
          gridOptions={gridOptions}
          rowData={divisionAggregateSupplements}
          rowMultiSelectWithClick={true}
        />
      </Box>
      <RHFTextField
        name="topics"
        label={t('common.topics')}
        control={control}
        rows={12}
        multiline={true}
        fullWidth
        placeholder={t('validateError.required', { target: t('common.topics') })}
        rules={{
          required: t('validateError.required', { target: t('common.topics') }),
        }}
      />
    </Stack>
  );
};
