import { debounce } from 'lodash';
import { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { AMOUNT_SOURCE_TYPE, CustomerGroup, setCustomerGroups } from '../../../../../../../../store/seriesAnswerSlice';

interface Props {
  customerCode: string;
  parentCustomerCode: string;
  methods: UseFormReturn<FieldValues, any>;
}

export const useDebounceHandleChange = (props: Props) => {
  const { customerCode, parentCustomerCode, methods } = props;
  const dispatch = useAppDispatch();
  const customerGroups = useAppSelector((state) => state.seriesAnswer.customerGroups);
  const debouncehandleChange = useMemo(
    () =>
      debounce((e) => {
        const value: number = e.target.value;
        // ほしいデータが配列で入っているので分割して抽出
        const originalName = e.target.name;
        const tradeVolumeType = originalName.split('-')[0];
        const fiscalYear = tradeVolumeType === 'purchaseCurrentYear' || tradeVolumeType === 'salesCurrentYear' ? 'currentYear' : 'previousYear';
        const answerType = tradeVolumeType.match(/purchase/) ? 'answerPurchaseAmount' : 'answerSalesAmount';
        const isAccounts = tradeVolumeType === 'receivableBalance' || tradeVolumeType === 'payableBalance';
        // 親会社コードが一致しているものを抽出し、該当する売上高等を更新する
        const customer = customerGroups.find((customerGroup) => customerGroup.parentCustomerCode === parentCustomerCode);

        if (customer) {
          const updateChildren = customer.childrenCustomer.map((children) => {
            const isPurchase = tradeVolumeType.match(/purchase/);
            const currentChild = children?.currentYear;
            const preChild = children?.previousYear;

            const currentYearSourceType =
              isPurchase !== null && fiscalYear === 'currentYear'
                ? currentChild.systemPurchaseAmount !== value && currentChild.systemSalesAmount !== currentChild.answerSalesAmount
                  ? AMOUNT_SOURCE_TYPE.ANSWERED
                  : children.currentYear.sourceType
                : isPurchase === null && fiscalYear === 'currentYear'
                ? currentChild.systemPurchaseAmount !== currentChild.answerPurchaseAmount && currentChild.systemSalesAmount !== value
                  ? AMOUNT_SOURCE_TYPE.ANSWERED
                  : children.currentYear.sourceType
                : children.currentYear.sourceType;

            const preYearSourceType =
              isPurchase !== null && fiscalYear === 'previousYear'
                ? preChild?.systemPurchaseAmount !== value && preChild.systemSalesAmount !== preChild.answerSalesAmount
                  ? AMOUNT_SOURCE_TYPE.ANSWERED
                  : children.previousYear.sourceType
                : isPurchase === null && fiscalYear === 'previousYear'
                ? preChild?.systemPurchaseAmount !== preChild.answerPurchaseAmount && preChild.systemSalesAmount !== value
                  ? AMOUNT_SOURCE_TYPE.ANSWERED
                  : children.previousYear.sourceType
                : children.previousYear.sourceType;

            if (children.customerCode === customerCode) {
              // comment,債権債務の場合かそれ以外
              const element = !isAccounts
                ? {
                    ...children,
                    [fiscalYear]: {
                      ...children[fiscalYear],
                      [answerType]: value,
                      sourceType: fiscalYear === 'currentYear' ? currentYearSourceType : preYearSourceType,
                    },
                  }
                : {
                    ...children,
                    [tradeVolumeType]: value,
                  };
              return element;
            }
            return children;
          });

          const newCustomerGroups: CustomerGroup[] = customerGroups.map((customerGroup) => {
            if (customerGroup.parentCustomerCode === parentCustomerCode) {
              return {
                ...customerGroup,
                childrenCustomer: updateChildren,
              };
            }
            return customerGroup;
          });
          dispatch(setCustomerGroups([...newCustomerGroups]));
          methods.trigger([`salesComment-${customerCode}`, `purchaseComment-${customerCode}`]);
        }
      }, 300),
    [customerGroups], // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Autocompleteの候補が選ばれた時にグローバルステートを更新するための関数
   * @param name フィールド名 ex) salesComment-A1452950
   * @param value 事業撤退
   */
  const setCommentValue = (name: string, value: string) => {
    const key = name.split('-')[0];
    const customerCode = name.split('-')[1];

    const newCustomerGroups: CustomerGroup[] = customerGroups.map((customerGroup) => {
      if (customerGroup.parentCustomerCode === parentCustomerCode) {
        const updateChildren = customerGroup.childrenCustomer.map((children) => {
          if (children.customerCode === customerCode) {
            return {
              ...children,
              [key]: value,
            };
          }
          return children;
        });
        return { ...customerGroup, childrenCustomer: updateChildren };
      } else {
        return customerGroup;
      }
    });
    dispatch(setCustomerGroups(newCustomerGroups));
    methods.trigger([`salesComment-${customerCode}`, `purchaseComment-${customerCode}`]);
  };

  return { debouncehandleChange, setCommentValue };
};
