import { Card, CardContent } from '@mui/material';
import { Comment } from 'src/api/getCommentsApi';
import useLocales from 'src/hooks/useLocales';
import { getOrganizationName, getUserName } from 'src/utils/getThreadUserInfo';
import { ThreadUserInfoBox } from '../../common/components/ThreadUserInfoBox';
import { ThreadTextBox } from './ThreadTextBox';

interface Props {
  commentProps: Comment;
}

export const ThreadCommentCard = ({ commentProps }: Props) => {
  const { updatedAt, comment, user } = commentProps;
  const { isJapanese } = useLocales();
  const userName = getUserName(user, isJapanese);
  const organizationName = getOrganizationName(user, isJapanese);

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 2, mb: 2 }}>
      <ThreadUserInfoBox userName={userName} organizationName={organizationName} updatedAt={updatedAt} sx={{ pl: 2, pt: 2 }} />
      <CardContent>
        <ThreadTextBox text={comment} lineCount={3} />
      </CardContent>
    </Card>
  );
};
