import { useAppDispatch } from 'src/redux/store';
import { enqueteStatusSlice } from '../store/enqueteStatusSlice';

export const useEnqueteStatusList = () => {
  const dispatch = useAppDispatch();
  /**
   * グリッドローディング
   */
  const setIsLoading = (isLoading: boolean) => {
    // ストアの初期化
    dispatch(enqueteStatusSlice.actions.setIsLoading(isLoading));
  };

  /**
   * データ更新用カウンター
   */
  const incrementUpdateCounter = () => {
    dispatch(enqueteStatusSlice.actions.incrementUpdateCounter());
  };

  return { setIsLoading, incrementUpdateCounter };
};
