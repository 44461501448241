import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { usePermission } from 'src/hooks/usePermission';
import { ORGANIZATION_FUTURE_PATH } from '../routes/path';

export const ComplementaryOrganizationListActions: React.FC = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { isAdmin, isOrganizationEditor } = usePermission();
  // adminとorganizationEditorの権限を持つユーザーのみ操作可能
  const isOnlyViewerAdmin = !isAdmin && !isOrganizationEditor;

  const onClickCreateTTCButton = () => {
    nav(`${ORGANIZATION_FUTURE_PATH.CREATE}?companytype=ttc`);
  };
  const onClickCreateGroupButton = () => {
    nav(`${ORGANIZATION_FUTURE_PATH.CREATE}?companytype=group`);
  };

  return (
    <Box component={'div'} sx={{ mb: 1 }}>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item xs={3} md={3}>
          <StyledButton fullwidth={true} onClick={onClickCreateTTCButton} isDisabled={isOnlyViewerAdmin}>
            {t('complementaryOrganizationList.button.new')}
            {t('complementaryOrganizationList.companyType.ttc')}
          </StyledButton>
        </Grid>
        <Grid item xs={3} md={3}>
          <StyledButton fullwidth={true} onClick={onClickCreateGroupButton} isDisabled={isOnlyViewerAdmin}>
            {t('complementaryOrganizationList.button.new')}
            {t('complementaryOrganizationList.companyType.group')}
          </StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
};
