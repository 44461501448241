import { Alert, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CatsCustomer } from 'src/api/useCustomerManagementApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import RHFSelectAutocomplete from 'src/components/react-hook-form/RHFSelectAutocomplete';
import { closeApplyA1CodeModal } from 'src/features/manage/customer-management/store/customerManagementSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';
import { useApplyA1CodeModal } from '../hooks/useApplyA1CodeModal';
import { useValidator } from '../hooks/useValidator';

type ApplyA1CodeModalProps = {
  catsCustomers: CatsCustomer[];
};

const ApplyA1CodeModal = React.memo(({ catsCustomers }: ApplyA1CodeModalProps) => {
  const methods = useForm({ mode: 'onChange' });
  const { t } = useTranslation();
  const sx = { wordBreak: 'keep-all', textAlign: 'center' };
  const leftSx = { fontSize: 'h6.fontSize', textAlign: 'center' };
  const { rulesA1Code } = useValidator();
  const { isApplyA1CodeModalOpen, editCustomer } = useAppSelector((state: RootState) => state.customerManagement);
  const { inProgress, onApplyA1CodeClick, errorMessage, clear } = useApplyA1CodeModal(methods, catsCustomers);
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';

  return (
    <StyledModal isOpen={isApplyA1CodeModalOpen} modalTitle={t('customerManagement.applyA1CodeModal.title')} onCloseFunc={() => {}}>
      <Grid container>
        <Grid item container spacing={2}>
          <Grid item paddingX={0}>
            <Alert severity="info">{t('customerManagement.applyA1CodeModal.description')}</Alert>
          </Grid>
          {errorMessage && (
            <Grid item paddingX={0}>
              <Alert severity="error">{errorMessage} </Alert>
            </Grid>
          )}
        </Grid>
        {/* 取引先名(日本語) */}
        <Grid item container alignItems="center" margin={4} sx={{ boxShadow: 3 }}>
          <Grid item container alignItems="center" p={2} sx={{ borderBottom: 1, borderColor: '#F5B090' }}>
            <Grid item md={4}>
              <Typography variant="body1" sx={leftSx} children={t('customerManagement.editModal.field.customerNameJpn')} />
            </Grid>
            <Grid item md={8}>
              <Typography variant="body1" sx={sx} children={editCustomer?.customerNameJpn ?? ''} />
            </Grid>
          </Grid>
          {/* 取引先名(英語) */}
          <Grid item container alignItems="center" p={2} sx={{ borderBottom: 1, borderColor: '#FCD7A1' }}>
            <Grid item md={4}>
              <Typography variant="body1" sx={leftSx} children={t('customerManagement.editModal.field.customerNameEng')} />
            </Grid>
            <Grid item md={8}>
              <Typography variant="body1" sx={sx} children={editCustomer?.customerNameEng ?? ''} />
            </Grid>
          </Grid>
          {/* 親取引先 */}
          <Grid item container alignItems="center" p={2} sx={{ borderBottom: 1, borderColor: '#A5D4AD' }}>
            <Grid item md={4}>
              <Typography variant="body1" sx={leftSx} children={t('common.parentCustomer')} />
            </Grid>
            <Grid item md={8}>
              <Typography variant="body1" sx={sx} children={isJapanese ? editCustomer?.parentCustomerNameJpn : editCustomer?.parentCustomerNameEng} />
            </Grid>
          </Grid>
          {/* 最上位取引先コード */}
          <Grid item container alignItems="center" p={2} sx={{ borderBottom: 1, borderColor: '#A3BCE2' }}>
            <Grid item md={4}>
              <Typography variant="body1" sx={leftSx} children={t('common.topParentCustomer')} />
            </Grid>
            <Grid item md={8}>
              <Typography
                variant="body1"
                sx={sx}
                children={isJapanese ? editCustomer?.topGlobalCustomerNameJpn : editCustomer?.topGlobalCustomerNameEng}
              />
            </Grid>
          </Grid>
          {/* 極 */}
          <Grid item container alignItems="center" p={2} sx={{ borderBottom: 1, borderColor: '#A59ACA' }}>
            <Grid item md={4}>
              <Typography variant="body1" sx={leftSx} children={t('common.region')} />
            </Grid>
            <Grid item md={8}>
              <Typography variant="body1" sx={sx} children={isJapanese ? editCustomer?.regionNameJpn : editCustomer?.regionNameEng} />
            </Grid>
          </Grid>
          {/* 国/地域 */}
          <Grid item container alignItems="center" p={2} sx={{ borderBottom: 1, borderColor: '#CFA7CD' }}>
            <Grid item md={4}>
              <Typography variant="body1" sx={leftSx} children={t('common.country')} />
            </Grid>
            <Grid item md={8}>
              <Typography variant="body1" sx={sx} children={isJapanese ? editCustomer?.countryNameJpn : editCustomer?.countryNameEng} />
            </Grid>
          </Grid>
        </Grid>
        {/* A1コード */}
        <Grid item container alignItems="center" p={2} mr={2} ml={4}>
          <Grid item md={4}>
            <Typography variant="body1" sx={sx} children={t('customerManagement.applyA1CodeModal.field.a1Code')} />
          </Grid>
          <Grid item md={8}>
            <RHFSelectAutocomplete
              name={'a1Code'}
              label={t('customerManagement.applyA1CodeModal.field.a1Code')}
              options={catsCustomers.map<{ key: string; label: string }>((item) => {
                const label = isJapanese ? item.labelJpn : item.labelEng;
                return { key: item.customerCode, label };
              })}
              defaultValue={{ key: '', label: '' }}
              methods={methods}
              rules={rulesA1Code}
            />
          </Grid>
        </Grid>
        <Grid item sx={{ mr: 2, width: '100%', textAlign: 'right' }}>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} mr={2}>
            <StyledButton
              variant={'contained'}
              onClick={() => {
                dispatch(closeApplyA1CodeModal());
                clear();
              }}
              color="error">
              {t('button.cancel')}
            </StyledButton>
            <StyledButton
              variant={'contained'}
              onClick={async () => {
                await methods.handleSubmit(onApplyA1CodeClick)();
                if (!isApplyA1CodeModalOpen) {
                  clear();
                }
              }}
              isDisabled={!methods.formState.isValid || inProgress}>
              {t('customerManagement.applyA1Code')}
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </StyledModal>
  );
});

export default ApplyA1CodeModal;
