import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeriesImportResult } from 'src/api/useEnqueteAnswerInputApi';
import { ErrorMessageEntity } from 'src/api/useEnqueteStatusPageApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { FORM_TYPE, FormType } from 'src/constants';
import useImportExcelValidate from '../../../../../hooks/useImportExcelValidate';

type ErrorReport = ErrorMessageEntity[] | SeriesImportResult[] | [] | string;

interface ErrorReportContentEntity {
  errorReport: ErrorReport;
  formType?: FormType;
  onCloseFunc: () => void;
}

interface GenerateErrorMessageEntity {
  errorReport: ErrorReport;
  formType?: FormType;
}

/**
 * エラーレポート詳細コンポーネント
 *
 * @param param0
 * @returns
 */
const ErrorReportContent: React.FC<ErrorReportContentEntity> = ({ errorReport, formType, onCloseFunc }) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack>
        {errorReport !== undefined && errorReport.length === 0 ? (
          <Typography variant={'body1'} sx={{ textAlign: 'center' }} children={t('errorReport.message.emptyError')} />
        ) : (
          <GenerateErrorMessage errorReport={errorReport} formType={formType} />
        )}
      </Stack>
      <Stack sx={{ mt: 5 }}>
        <StyledButton onClick={onCloseFunc} children={t('button.close')} />
      </Stack>
    </>
  );
};

export default ErrorReportContent;

/**
 * エラー内容出力コンポーネント
 *
 * @param param0
 * @returns
 */
const GenerateErrorMessage: React.FC<GenerateErrorMessageEntity> = ({ errorReport, formType }) => {
  const validateHooks = useImportExcelValidate();

  //エラーメッセージ取得
  let errorMessageList: React.ReactNode[] = [];
  if (!errorReport[0].hasOwnProperty('detail')) {
    // 系列別特有のエラーでない場合
    errorMessageList = validateHooks.getErrorMessageList(errorReport as []);
  } else if (typeof errorReport === 'string') {
    errorMessageList = validateHooks.getErrorMessageList(errorReport);
  } else if (formType === FORM_TYPE.SERIES) {
    // 系列別特有のエラー
    errorMessageList = validateHooks.getSeriesErrorMessageList(errorReport as []);
  }

  //スタイル
  const styles = {
    px: 3,
    py: 2,
    overflowY: 'scroll',
    maxHeight: '30ch',
  };

  return (
    <Box sx={styles}>
      {errorMessageList.map((v, i) => (
        <Typography key={i} variant={'body1'} children={v} />
      ))}
    </Box>
  );
};
