import { useCallback, useEffect, useState } from 'react';
import { CountryEntity, useCountryApi } from 'src/api/useCountryApi';
import { CatsCustomer, ParentCustomer, useCustomerManagementApi } from 'src/api/useCustomerManagementApi';
import { RegionEntity, useRegionApi } from 'src/api/useRegionApi';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';
import { setBonsaiAddedCustomers, setIsCustomerLoading } from '../store/customerManagementSlice';
import { CountryRegionEntity, useCountryRegionApi } from 'src/api/useCountryRegionApi';

export const useCustomerManagementList = () => {
  const { bonsaiAddedCustomers } = useAppSelector((state: RootState) => state.customerManagement);
  const [parentCustomers, setParentCustomers] = useState<ParentCustomer[]>([]);
  const [catsCustomers, setCatsCustomers] = useState<CatsCustomer[]>([]);
  const [regions, setRegions] = useState<RegionEntity[]>([]);
  const [countries, setCountries] = useState<CountryEntity[]>([]);
  const [countryRegions, setCountryRegions] = useState<CountryRegionEntity[]>([]);
  const { getCustomerManagement } = useCustomerManagementApi();
  const { getAll: getAllRegions } = useRegionApi();
  const { get: getCountries } = useCountryApi();
  const { getAll: getAllCountryRegions } = useCountryRegionApi();

  const getAllRegionsCallback = useCallback(async () => {
    return await getAllRegions();
  }, [getAllRegions]);

  const getCountriesCallback = useCallback(async () => {
    return await getCountries({ where: { NOT: { AND: [{ country_name: '', country_eng_name: '' }] } }, orderBy: [{ country_iso_cd: 'asc' }] });
  }, [getCountries]);

  const getAllCountryRegionsCallback = useCallback(async () => {
    return await getAllCountryRegions();
  }, [getAllCountryRegions]);

  const fetch = useCallback(async () => {
    try {
      dispatch(setIsCustomerLoading(true));
      const [result, regions, countries, countryRegions] = await Promise.all([
        getCustomerManagement(),
        getAllRegionsCallback(),
        getCountriesCallback(),
        getAllCountryRegionsCallback(),
      ]);
      dispatch(setBonsaiAddedCustomers(result.bonsaiAddedCustomers));
      setParentCustomers(result.parentCustomers);
      setCatsCustomers(result.catsCustomers);
      setRegions(regions ?? []);
      setCountryRegions(countryRegions ?? []);

      // 国/地域（日本語）が重複している場合は国名（日本語）の後ろにカッコ付きで国名（英語）を表示
      const processedCountries: CountryEntity[] = [];
      const duplicatedCountryNames: string[] = [];
      countries?.forEach((country) => {
        if (duplicatedCountryNames.includes(country.countryNameJpn)) {
          const duplicateCountry = processedCountries.find((c) => c.countryNameJpn === country.countryNameJpn);
          if (duplicateCountry) {
            duplicateCountry.countryNameJpn += `（${duplicateCountry.countryNameEng}）`;
          }
          country.countryNameJpn += `（${country.countryNameEng}）`;
        } else {
          duplicatedCountryNames.push(country.countryNameJpn);
        }
        processedCountries.push(country);
      });
      setCountries(countries ?? []);
    } finally {
      dispatch(setIsCustomerLoading(false));
    }
  }, [getCustomerManagement, getAllRegionsCallback, getCountriesCallback, getAllCountryRegionsCallback]);

  useEffect(() => {
    if (bonsaiAddedCustomers.length > 0) {
      return;
    }
    fetch();
    return () => {
      dispatch(setBonsaiAddedCustomers([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    countries,
    parentCustomers,
    regions,
    catsCustomers,
    countryRegions,
  };
};
