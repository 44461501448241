import { useEffect, useState } from 'react';
import { useBaseApi } from 'src/api/useBaseApi';
import { useCountryApi } from 'src/api/useCountryApi';
import { useGradeApi } from 'src/api/useGradeApi';
import { useJobTitleApi } from 'src/api/useJobTitleApi';
import { OrganizationEntity, useOrganizationApi } from 'src/api/useOrganizationApi';
import { usePositionApi } from 'src/api/usePositionApi';
import { useRankApi } from 'src/api/useRankApi';
import { useRegionApi } from 'src/api/useRegionApi';
import useLocales from 'src/hooks/useLocales';
import { addSuffix, switchLabel } from 'src/utils/labelUtils';

export type SearchConditionList = {
  key: string;
  label: string;
};

const baseOrgLevelTypes = [
  { key: 'COMPANY', label: { jpn: '会社', eng: 'Company' } },
  { key: 'DIVISION', label: { jpn: '本部', eng: 'Division' } },
  { key: 'SBU', label: { jpn: 'SBU', eng: 'SBU' } },
  { key: 'DEPARTMENT', label: { jpn: '部', eng: 'Department' } },
  { key: 'GROUP', label: { jpn: 'グループ', eng: 'Group' } },
];

interface Props {
  surveyDetailId: string;
}

export const useSearchConditionList = (props: Props) => {
  const { surveyDetailId } = props;
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const { getOrganizationsByFilter } = useOrganizationApi();
  const { getOnlySetNumberCountryCode } = useCountryApi();
  const { getAll: getAllRegions } = useRegionApi();
  const { getAll: getAllJobTitles } = usePositionApi();
  const { getByFilter } = useJobTitleApi();
  const { getAllGradeJobTitles } = useGradeApi();
  const { getByFilter: getBasesByFilter } = useBaseApi();
  const { getAll: getAllRanks } = useRankApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ranks, setRanks] = useState<SearchConditionList[]>([]);
  const [companies, setCompanies] = useState<SearchConditionList[]>([]);
  const [divisions, setDivisions] = useState<SearchConditionList[]>([]);
  const [sbus, setSbus] = useState<SearchConditionList[]>([]);
  const [departments, setDepartments] = useState<SearchConditionList[]>([]);
  const [groups, setGroups] = useState<SearchConditionList[]>([]);
  const [countries, setCountries] = useState<SearchConditionList[]>([]);
  const [regions, setRegions] = useState<SearchConditionList[]>([]);
  const [bases, setBases] = useState<SearchConditionList[]>([]);
  const [jobTitles, setJobTitles] = useState<SearchConditionList[]>([]);
  const [gradeJobTitles, setGradeJobTitles] = useState<SearchConditionList[]>([]);

  const orgLevelTypes = baseOrgLevelTypes.map((orgLevelType) => {
    const label = isJapanese ? orgLevelType.label.jpn : orgLevelType.label.eng;
    return { key: orgLevelType.key, label: label };
  });

  const makeCompanies = async () => {
    if (!surveyDetailId) return;
    const companies = (await getOrganizationsByFilter(surveyDetailId, { orgLevelType: 'COMPANY', isDeleted: false, isMultiSegment: false })) ?? [];
    let sortedCompanies: OrganizationEntity[];
    const index = companies.findIndex((company) => company.companyCode === 'S500');
    if (index !== -1) {
      sortedCompanies = [companies[index], ...companies.slice(0, index), ...companies.slice(index + 1)];
    } else {
      sortedCompanies = [...companies];
    }

    const conditionMap = new Map(
      sortedCompanies
        .filter(({ companyCode }) => companyCode && companyCode !== '')
        .map(({ companyCode, companyNameJpn, companyNameEng }) => [
          companyCode,
          {
            key: companyCode,
            label: switchLabel(companyNameJpn, companyNameEng, isJapanese),
          },
        ]),
    );
    setCompanies(Array.from(conditionMap.values()));
  };

  const makeRanks = async () => {
    const ranks = (await getAllRanks()) ?? [];
    const conditions = ranks.map((e) => {
      return { key: e.rank, label: e.rank };
    });
    setRanks(conditions);
  };

  const makeCountries = async () => {
    const countries = (await getOnlySetNumberCountryCode()) ?? [];
    const conditions = countries.map((e) => {
      return {
        key: e.countryCode ?? '',
        label: isJapanese ? e.countryNameJpn ?? '' : e.countryNameEng ?? '',
      };
    });
    setCountries(conditions);
  };

  const makeRegions = async () => {
    const regions = (await getAllRegions()) ?? [];
    const conditions = regions.map((e) => {
      return {
        key: e.regionCode ?? '',
        label: isJapanese ? e.regionNameJpn ?? '' : e.regionNameEng ?? '',
      };
    });
    setRegions(conditions);
  };

  const makeGradeJobTitles = async () => {
    const gradeJobTitles = (await getAllGradeJobTitles()) ?? [];
    const conditions = gradeJobTitles.map((e) => {
      return {
        key: e.gradeJobTitleCode ?? '',
        label: isJapanese ? e.gradeJobTitleNameJpn ?? '' : e.gradeJobTitleNameEng ?? '',
      };
    });
    setGradeJobTitles(conditions);
  };

  const makeDivisions = async () => {
    if (!surveyDetailId) return;
    const divisions = (await getOrganizationsByFilter(surveyDetailId, { orgLevelType: 'DIVISION', isDeleted: false })) ?? [];
    const conditionMap = new Map(
      divisions
        .filter(({ divisionCode }) => divisionCode && divisionCode !== '')
        .map(({ divisionCode, divisionNameJpn, divisionNameEng }) => {
          return [
            divisionCode,
            {
              key: divisionCode ? divisionCode : '',
              label: addSuffix(switchLabel(divisionNameJpn, divisionNameEng, isJapanese), divisionCode),
            },
          ];
        }),
    );
    setDivisions(Array.from(conditionMap.values()));
  };

  const makeSbus = async (divisionCode?: string) => {
    if (!surveyDetailId) return;
    const sbus = (await getOrganizationsByFilter(surveyDetailId, { orgLevelType: 'SBU', isDeleted: false, divisionCode })) ?? [];
    const conditionMap = new Map(
      sbus
        .filter(({ sbuCode }) => sbuCode && sbuCode !== '')
        .map(({ sbuCode, sbuNameJpn, sbuNameEng }) => {
          return [
            sbuCode,
            {
              key: sbuCode ? sbuCode : '',
              label: addSuffix(switchLabel(sbuNameJpn, sbuNameEng, isJapanese), sbuCode),
            },
          ];
        }),
    );
    setSbus(Array.from(conditionMap.values()));
  };

  const makeDepartments = async (divisionCode?: string, sbuCode?: string) => {
    if (!surveyDetailId) return;
    const departments =
      (await getOrganizationsByFilter(surveyDetailId, { orgLevelType: 'DEPARTMENT', isDeleted: false, divisionCode, sbuCode })) ?? [];
    const conditionMap = new Map(
      departments
        .filter(({ departmentCode }) => departmentCode && departmentCode !== '')
        .map(({ departmentCode, departmentNameJpn, departmentNameEng }) => {
          return [
            departmentCode,
            {
              key: departmentCode ?? '',
              label: addSuffix(switchLabel(departmentNameJpn, departmentNameEng, isJapanese), departmentCode),
            },
          ];
        }),
    );
    setDepartments(Array.from(conditionMap.values()));
  };

  const makeGroups = async (divisionCode?: string, sbuCode?: string, departmentCode?: string) => {
    if (!surveyDetailId) return;

    const groups =
      (await getOrganizationsByFilter(surveyDetailId, {
        orgLevelType: 'GROUP',
        isDeleted: false,
        divisionCode,
        sbuCode,
        departmentCode,
      })) ?? [];
    const conditionMap = new Map(
      groups
        .filter(({ groupCode }) => groupCode && groupCode !== '')
        .map(({ groupCode, groupNameJpn, groupNameEng }) => {
          return [
            groupCode,
            {
              key: groupCode ?? '',
              label: addSuffix(switchLabel(groupNameJpn, groupNameEng, isJapanese), groupCode),
            },
          ];
        }),
    );
    setGroups(Array.from(conditionMap.values()));
  };

  const init = async () => {
    await Promise.all([
      makeCompanies(),
      makeDivisions(),
      makeSbus(),
      makeDepartments(),
      makeGroups(),
      makeCountries(),
      makeRegions(),
      makeRanks(),
      makeGradeJobTitles(),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetailId]);

  const makeBases = async (companyCode?: string) => {
    if (companyCode === undefined || companyCode === '') {
      setBases([]);
      return;
    }

    const bases = (await getBasesByFilter({ companyCode: companyCode })) ?? [];
    const conditions = bases.map((e, i) => {
      return {
        key: e.baseCode ?? '',
        label: switchLabel(e.baseNameJpn, e.baseNameEng, isJapanese),
      };
    });
    setBases(conditions);
  };

  const makeJobTitles = async (companyCode?: string) => {
    if (companyCode === undefined || companyCode === '') {
      setJobTitles([]);
      return;
    }

    // memo: 職位情報は豊田通商かそうでないかで管理リソースが異なるため、リクエスト先を切り替える
    let jobTitles: { jobTitleCode: string; jobTitleNameJpn: string; jobTitleNameEng: string }[] = [];
    if (companyCode === 'S500') {
      jobTitles = (await getAllJobTitles()) ?? [];
    } else {
      jobTitles = (await getByFilter({ companyCode: companyCode })) ?? [];
    }
    const conditions = jobTitles.map((e, i) => {
      return {
        key: e.jobTitleCode ?? '',
        label: isJapanese ? e.jobTitleNameJpn ?? '' : e.jobTitleNameEng ?? '',
      };
    });
    setJobTitles(conditions);
  };

  return {
    isLoading,
    orgLevelTypes,
    ranks,
    companies,
    divisions,
    sbus,
    departments,
    groups,
    countries,
    regions,
    bases,
    jobTitles,
    gradeJobTitles,
    makeBases,
    makeJobTitles,
    makeSbus,
    makeDepartments,
    makeGroups,
  };
};
