import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type SearchConditionProps = {
  type: 'autocomplete' | 'text';
  id: string;
  label: string;
  style: {
    xs: number;
  };
  props: SearchConditionAutoCompleteProps | SearchConditionTextFieldProps;
};

type SearchConditionAutoCompleteProps = {
  value: string | null; // 現在選択されている値
  list: {
    key: string;
    label: string;
  }[]; // ドロップダウンリストに表示する候補情報
  onChange: (value: string | undefined) => void; // ドロップダウンリスト変更時のコールバック
};

type SearchConditionTextFieldProps = {
  value: string; // 現在入力されている値
  onBlur: (value: string) => void;
};

type SearchModalExtendedFormProps = {
  // memo: 検索条件を格納、二次元配列として定義し、画面描画時に横並びに配置される
  conditions: SearchConditionProps[][];
};

export const SearchModalExtendedForm = ({ conditions }: SearchModalExtendedFormProps) => {
  const { t } = useTranslation();
  const renderAutoComplete = (condition: SearchConditionProps) => {
    const props = condition.props as SearchConditionAutoCompleteProps;
    return (
      <Autocomplete
        id={condition.id}
        size="small"
        options={props.list}
        // memo: [Encountered two children with the same key] の発生を抑制するため、renderOptionを定義
        renderOption={(props, option) => (
          <li {...props} key={props.id}>
            {option.label}
          </li>
        )}
        value={props.list.find((e) => e.key === props.value) || { key: '', label: '' }}
        // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
        isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
        onChange={(_, v) => props.onChange(v?.key)}
        renderInput={(params) => <TextField {...params} label={condition.label} />}
      />
    );
  };

  const renderTextfield = (condition: SearchConditionProps) => {
    const props = condition.props as SearchConditionTextFieldProps;
    return (
      <TextField
        fullWidth
        size="small"
        label={condition.label}
        onBlur={(event) => props.onBlur(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            // memo: Enter Key 押下で blur させ、 onBlur を発火させることで、 Enter Key による検索等の処理を実行可能とする
            (event.target as HTMLInputElement).blur();
          }
        }}
      />
    );
  };

  return (
    <>
      <Grid item xs={12} sx={{ mt: 2 }}>
        {conditions.map((eachCondition, pIndex) => {
          if (pIndex === 0) {
            return (
              <Grid container key={pIndex} sx={{ mb: 1, mr: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {eachCondition.map((condition, cIindex) => (
                  <Grid key={cIindex} item xs={condition.style.xs}>
                    {condition.type === 'autocomplete' && renderAutoComplete(condition)}
                    {condition.type === 'text' && renderTextfield(condition)}
                  </Grid>
                ))}
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography sx={{ width: '30%' }}>{t('enqueteCreate.selectRespondentAndExportModal.condition.filterOption')}</Typography>
            <Typography variant="body2" sx={{ color: 'gray' }}>
              {t('enqueteCreate.selectRespondentAndExportModal.condition.descriptionOfFilterOptions')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              {conditions.map((eachCondition, pIndex) => {
                if (pIndex > 0) {
                  return (
                    <Grid container key={pIndex} sx={{ mb: 1, mr: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      {eachCondition.map((condition, cIindex) => (
                        <Grid key={cIindex} item xs={condition.style.xs}>
                          {condition.type === 'autocomplete' && renderAutoComplete(condition)}
                          {condition.type === 'text' && renderTextfield(condition)}
                        </Grid>
                      ))}
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
