import { EnqueteCreatePage } from '../pages/EnqueteCreatePage';
import { ResponseRequestPage } from '../pages/ResponseRequestPage';

//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteCreateRoute = {
  path: 'enquete-create',
  children: [
    {
      path: 'create/:enqueteType',
      element: <EnqueteCreatePage />,
    },
    {
      path: 'edit/:id',
      element: <EnqueteCreatePage />,
    },
    {
      path: 'send-response-request/:respondentRequestId',
      element: <ResponseRequestPage />,
    },
  ],
};
