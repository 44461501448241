import { TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

export const TextFieldControl = (props: UseControllerProps & TextFieldProps) => {
  const { field } = useController(props);
  const textFieldProps = props as TextFieldProps;

  return <TextField {...field} {...textFieldProps} />;
};
