import { useTranslation } from 'react-i18next';

export const MAX_LENGTH_CUSTOMER_NAME = 100;
export const useValidator = () => {
  const { t } = useTranslation();

  const rulesParentCustomer = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('common.parentCustomer') }),
    },
  };

  const rulesCustomerNameJpn = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('customerManagement.editModal.field.customerNameJpn') }),
    },
    maxLength: {
      value: MAX_LENGTH_CUSTOMER_NAME,
      message: t('validateError.maxLength', { max: MAX_LENGTH_CUSTOMER_NAME }),
    },
  };

  const rulesCustomerNameEng = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('customerManagement.editModal.field.customerNameEng') }),
    },
    maxLength: {
      value: MAX_LENGTH_CUSTOMER_NAME,
      message: t('validateError.maxLength', { max: MAX_LENGTH_CUSTOMER_NAME }),
    },
  };

  const rulesRegion = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('common.region') }),
    },
  };

  const rulesCountry = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('common.country') }),
    },
  };

  return { rulesParentCustomer, rulesCustomerNameJpn, rulesCustomerNameEng, rulesRegion, rulesCountry };
};
