import React from 'react';
import { Control, Controller, UseFormUnregister, FieldValues } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@mui/material';

type RHFRestFirldCheckBoxProps = {
  name: string;
  control: Control<any>;
  rules?: Object;
  defaultChecked?: boolean;
  label?: any;
  fieldName: string[];
  unregister: UseFormUnregister<FieldValues>;
};

const RHFUnRegisterCheckBox = ({ name, control, rules, defaultChecked, label, fieldName, unregister }: RHFRestFirldCheckBoxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControlLabel
          {...field}
          control={
            <Checkbox
              defaultChecked={defaultChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.checked);
                unregister(fieldName);
              }}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default RHFUnRegisterCheckBox;
