import { ReactNode, createContext } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// theme
import palette from '../theme/palette';
// @type
import { ThemeMode, ThemeDirection, ThemeColor, SettingsContextProps } from '../@types/settings';

// ----------------------------------------------------------------------

const PRIMARY_COLOR = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#1dd3b0',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#38b000',
    dark: '#0E77B7',
    darker: '#053D7A',
    //contrastText: palette.light.grey[800]
    contrastText: '#fff',
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#CCDFFF',
    light: '#6697FF',
    //main: '#0045FF',
    main: '#adc178',
    dark: '#0027B7',
    darker: '#00137A',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#4c956c',
    dark: '#B66816',
    darker: '#793908',
    contrastText: '#fff',
  },
  // RED
  {
    name: 'red',
    lighter: '#111111',
    light: '#FFC1AC',
    main: '#086375',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  // GREEN2
  {
    name: 'green2',
    lighter: '#111111',
    light: '#FFC1AC',
    main: '#2c6e49',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  {
    name: 'green3',
    lighter: '#111111',
    light: '#FFC1AC',
    main: '#004b23',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  {
    name: 'green4',
    lighter: '#111111',
    light: '#FFC1AC',
    main: '#9ef01a',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
];

function SetColor(themeColor: ThemeColor) {
  let color;
  // 色見本
  const DEFAULT = PRIMARY_COLOR[0];
  const PURPLE = PRIMARY_COLOR[1];
  const CYAN = PRIMARY_COLOR[2];
  const BLUE = PRIMARY_COLOR[3];
  const ORANGE = PRIMARY_COLOR[4];
  const RED = PRIMARY_COLOR[5];
  const GREEN2 = PRIMARY_COLOR[6];
  const GREEN3 = PRIMARY_COLOR[7];
  const GREEN4 = PRIMARY_COLOR[8];
  switch (themeColor) {
    case 'purple':
      color = PURPLE;
      break;
    case 'cyan':
      color = CYAN;
      break;
    case 'blue':
      color = BLUE;
      break;
    case 'orange':
      color = ORANGE;
      break;
    case 'red':
      color = RED;
      break;
    case 'green2':
      color = GREEN2;
      break;
    case 'green3':
      color = GREEN3;
      break;
    case 'green4':
      color = GREEN4;
      break;
    default:
      color = DEFAULT;
  }
  return color;
}

const initialState: SettingsContextProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColor: 'default',
  onChangeMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  setColor: PRIMARY_COLOR[0],
  colorOption: [],
  themeLayout: 'horizontal',
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColor: 'default',
  });

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as ThemeMode,
    });
  };

  const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
    });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColor: (event.target as HTMLInputElement).value as ThemeColor,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        setColor: SetColor(settings.themeColor),
        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main,
        })),
      }}>
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
