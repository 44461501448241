import { ColumnState } from 'ag-grid-community';
import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { NetworkAccessError } from 'src/errors/errors';
import { ConfirmRequestStatus } from '../features/general/enquete-confirm/store/enqueteConfirmSlice';
import { useBearerToken } from './useBearerToken';

export interface CreateConfirmRequest {
  answerId: string;
  requesterId: string;
  requesterName: string;
  requesterNameEng: string | null;
  requesterEmail: string;
  requesterBluePageEmailAddress: string | null;
  requestComment: string;
  confirmerId: string;
  confirmerName: string | null;
  confirmerNameEng: string | null;
  confirmerEmail: string;
  confirmerBluePageEmailAddress: string | null;
}

export interface EnqueteConfirmListEntity {
  id: string;
  status: string;
  labelJpn: string;
  labelEng: string;
  title: string;
  organizationId: string;
  companyCode: string;
  companyNameJpn: string;
  companyNameEng: string;
  departmentCode: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupCode?: string;
  groupNameJpn?: string;
  groupNameEng?: string;
  sbuCode: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  requesterId: string;
  requesterBluePageEmailAddress: string;
  requesterName: string;
  requesterNameEng: string;
  requestedDate: string;
  requestComment: string;
  confirmerName: string;
  confirmerNameEng: string;
  confirmerId: string;
  confirmerBluePageEmailAddress: string;
  confirmDate: string;
  confirmComment: string;
  respondentTargetId: string;
  answerId: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  isDeleted: boolean;
}

export const useConfirmRequestApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   *
   * @param status: ConfirmRequestStatus,
   * @param filterModel: { [key: string]: any },
   * @param currentLang: string,
   */
  const getCountLimitedList = async (
    status: ConfirmRequestStatus,
    filterModel: { [key: string]: any },
    currentLang: string,
  ): Promise<number | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<number>(apiGateway + '/confirm-request-page/count-limited-list?status=' + status, {
        headers: {
          Authorization: token,
        },
        params: {
          FilterModel: filterModel,
          CurrentLang: currentLang,
        },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useConfirmRequest.failedGetList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getLimitedList = async (
    status: ConfirmRequestStatus,
    blockSize: number,
    startRow: number,
    sortModel: ColumnState[],
    filterModel: { [key: string]: any },
    currentLang: string,
  ): Promise<EnqueteConfirmListEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<EnqueteConfirmListEntity[]>(apiGateway + '/confirm-request-page/limited-list?status=' + status, {
        headers: {
          Authorization: token,
        },
        params: {
          BlockSize: blockSize,
          StartRow: startRow,
          SortModel: sortModel,
          FilterModel: filterModel,
          CurrentLang: currentLang,
        },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useConfirmRequest.failedGetList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   *
   * @param data
   */
  const createMany = async (data: CreateConfirmRequest[]) => {
    try {
      const token = await getBearerToken();
      const response = await axios.post(apiGateway + `/enquete-answer-input/confirm-request/request`, data, {
        headers: { Authorization: token },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new NetworkAccessError(error.response?.status);
      }
      throw error;
    }
  };

  /**
   *
   * @param id
   */
  const deleteByConfirmRequestId = async (id: string) => {
    try {
      const token = await getBearerToken();
      await axios.delete(apiGateway + '/enquete-answer-input/confirm-request/' + id, {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new NetworkAccessError(error.response?.status);
      }
      throw error;
    }
  };

  return {
    getCountLimitedList,
    getLimitedList,
    createMany,
    deleteByConfirmRequestId,
  };
};
