import { format, isBefore, isValid, set, setSeconds } from 'date-fns';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useOverviewValidator = (methods: UseFormReturn) => {
  const { t } = useTranslation();

  const startDateRule: RegisterOptions = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('common.yearAndMonth') }),
    },
    validate: {
      isValidDate: (value: Date) => {
        return value instanceof Date && !isNaN(value.getTime()) ? true : `${t('validateError.format.common', { target: t('common.yearAndMonth') })}`;
      },
      isValidPast: (value: Date) => {
        const dateValues = { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
        return (
          isBefore(set(value, dateValues), set(new Date(), dateValues)) ||
          `${t('enqueteGeneralCommon.message.validationErrorInputFromIsValidPastDate')}`
        );
      },
      isValidPeriod: (value: Date) => {
        const endDate = setSeconds(methods.getValues().endDate, 0);
        methods.setValue('endDate', endDate);
        // 終了年月の入力がない場合はバリデーションをかけない
        if (!isValid(endDate)) {
          return true;
        }
        if (isSameMonthOrPastDate(value, endDate)) {
          // 相関チェックエラーのみクリアする
          if (methods.getFieldState('endDate').error?.type === 'isValidPeriod') {
            methods.clearErrors('endDate');
          }
          return true;
        } else {
          return `${t('enqueteGeneralCommon.message.varidationErrorInputFromIsValidPeriodMonth')}`;
        }
      },
    },
  };

  const endDateRule: RegisterOptions = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('common.yearAndMonth') }),
    },
    validate: {
      isValidDate: (value: Date) => {
        return value instanceof Date && !isNaN(value.getTime()) ? true : `${t('validateError.format.common', { target: t('common.yearAndMonth') })}`;
      },
      isValidPast: (value: Date) => {
        const dateValues = { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
        return (
          isBefore(set(value, dateValues), set(new Date(), dateValues)) ||
          `${t('enqueteGeneralCommon.message.validationErrorInputFromIsValidPastDate')}`
        );
      },
      isValidPeriod: (value: Date) => {
        const startDate = setSeconds(methods.getValues().startDate, 0);
        // 開始年月の入力がない場合はバリデーションをかけない
        if (!isValid(startDate)) {
          return true;
        }
        methods.setValue('startDate', startDate);
        if (isSameMonthOrPastDate(startDate, value)) {
          // 相関チェックエラーのみクリアする
          if (methods.getFieldState('startDate').error?.type === 'isValidPeriod') {
            methods.clearErrors('startDate');
          }
          return true;
        } else {
          return `${t('enqueteGeneralCommon.message.varidationErrorInputToIsValidPeriodMonth')}`;
        }
      },
    },
  };

  /**
   * 比較先が設定されていない or 比較元が比較先と同じ年月、過去の年月の場合true、違う場合falseを返却する
   * @param source 比較元
   * @param destination 比較先
   * @returns 比較先が設定されていない or 比較元が比較先と同じ年月、過去の年月の場合True
   */
  const isSameMonthOrPastDate = (source: Date, destination: Date) => {
    const startYearMonth = format(source, 'yyyy/MM');
    const endYearMonth = format(destination, 'yyyy/MM');
    // 文字列比較だが、フォーマット、文字数が同じため問題なし
    return startYearMonth <= endYearMonth;
  };

  return { startDateRule, endDateRule };
};
