import { isNaN } from 'lodash';
import {
  EnqueteAnswerLoadInputEntity,
  ManufyearPlanurEmploymentPriorityDomainTabAnswerData,
  ManufyearPlanurEmploymentPriorityDomainTabData,
  ManufyearPlanurEmploymentTabAnswerData,
  ManufyearPlanurEmploymentTabData,
  PersonnelEmploymentTabAnswerData,
  PersonnelEmploymentTabData,
  PersonnelFunctionTabAnswerData,
  PersonnelFunctionTabData,
  PersonnelPriorityDomainTabAnswerData,
  PersonnelPriorityDomainTabData,
  PersonnelSurveyDiffFormAnswerInputData,
  PersonnelSurveyPriorityDomainDiffFormAnswerInputData,
  SurveyFormAnswerData,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import { languageDataTypes } from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';

export const CreateSaveAnswerJsonData = () => {
  // 取得したSurveyDetailの情報を格納
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);
  const useJudg = useJudgmentCurrentLanguage();
  // アンケートの各項目の入力データ
  const answerDataEdit = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerDataEdit);

  const createData = () => {
    //-------------------------------------------------------------
    // 回答情報JSON作成
    //-------------------------------------------------------------
    let personnelEmploymentAnswerInputData: PersonnelEmploymentTabAnswerData | {};
    let personnelFunctionTabAnswerData: PersonnelFunctionTabAnswerData | {};
    let manufyearPlanurEmploymentTabAnswerData: ManufyearPlanurEmploymentTabAnswerData | {};
    // 人件費人員(重点分野)タブ回答情報
    let personnelPriorityDomainTabAnswerData: PersonnelPriorityDomainTabAnswerData | {};
    // 製造原価人員(重点分野)タブ回答情報
    let manufyearPlanurEmploymentPriorityDomainTabAnswerData: ManufyearPlanurEmploymentPriorityDomainTabAnswerData | {};

    //-------------------------------------------------------------
    // 編集データ取得
    //-------------------------------------------------------------
    const personnelEmploymentRowDataEdit: any = answerDataEdit.personnelEmploymentRowData;
    const personnelEmploymentResultAndPlanDiffFormEdit = (
      answerDataEdit.personnelEmploymentResultAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
    )?.map((row) => {
      return Object.assign({}, row, {
        diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
      });
    });
    const personnelEmploymentPlanAndPlanDiffFormEdit = (
      answerDataEdit.personnelEmploymentPlanAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
    )?.map((row) => {
      return Object.assign({}, row, {
        diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
      });
    });
    const personnelCorporateRowDataEdit: any = answerDataEdit.personnelCorporateRowData;
    const personnelCorporateOtherFormTextValueEdit = answerDataEdit.personnelCorporateOtherFormTextValue;
    const personnelSalesRowDataEdit: any = answerDataEdit.personnelSalesRowData;
    const manufyearPlanurEmploymentRowDataEdit: any = answerDataEdit.manufyearPlanurEmploymentRowData;
    const manufyearPlanurEmploymentResultAndPlanDiffFormEdit = (
      answerDataEdit.manufyearPlanurEmploymentResultAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
    )?.map((row) => {
      return Object.assign({}, row, {
        diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
      });
    });
    const manufyearPlanurEmploymentPlanAndPlanDiffFormEdit = (
      answerDataEdit.manufyearPlanurEmploymentPlanAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
    )?.map((row) => {
      return Object.assign({}, row, {
        diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
      });
    });
    // 人件費人員（重点分野別）1.正社員の編集データ
    const personnelPriorityDomainRegularEmployeeRowDataEdit: any = answerDataEdit.personnelPriorityDomainRegularEmployeeRowData;
    // 人件費人員（重点分野別）2.TTCからの出向受入の編集データ
    const personnelPriorityDomainSecondmentFromTTCRowDataEdit: any = answerDataEdit.personnelPriorityDomainSecondmentFromTTCRowData;
    // 人件費人員（重点分野別）3.2以外の出向受入の編集データ
    const personnelPriorityDomainSecondmentFromOtherRowDataEdit: any = answerDataEdit.personnelPriorityDomainSecondmentFromOtherRowData;
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの編集データ
    const personnelPriorityDomainDispatchEtcRowDataEdit: any = answerDataEdit.personnelPriorityDomainDispatchEtcRowData;
    // 人件費人員（重点分野別）年計-実見の編集データ
    const personnelPriorityDomainResultAndPlanDiffFormEdit = answerDataEdit.personnelPriorityDomainResultAndPlanDiffForm as
      | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
      | undefined;
    // 人件費人員（重点分野別）中計-年計の編集データ
    const personnelPriorityDomainPlanAndPlanDiffFormEdit = answerDataEdit.personnelPriorityDomainPlanAndPlanDiffForm as
      | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
      | undefined;

    // 製造原価人員（重点分野別）1.正社員の編集データ
    const manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit: any = answerDataEdit.manufyearPlanurPriorityDomainRegularEmployeeRowData;
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の編集データ
    const manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit: any = answerDataEdit.manufyearPlanurPriorityDomainOtherEmployeesRowData;
    // 製造原価人員（重点分野別）年計-実見の編集データ
    const manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit = answerDataEdit.manufyearPlanurPriorityDomainResultAndPlanDiffForm as
      | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
      | undefined;

    // 製造原価人員（重点分野別）中計-年計の編集データ
    const manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit = answerDataEdit.manufyearPlanurPriorityDomainPlanAndPlanDiffForm as
      | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
      | undefined;

    //-------------------------------------------------------------
    // 差異人数(重点分野)を文字から数値に変換する
    //-------------------------------------------------------------
    const isPriorityDomainDiffValueChangeNumber = (
      diffFormAnswerInputData: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined,
    ): PersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined => {
      if (diffFormAnswerInputData !== undefined) {
        const tempDiffFormAnswerInputData: PersonnelSurveyPriorityDomainDiffFormAnswerInputData[] = diffFormAnswerInputData.map((item, count) => {
          if (item !== undefined && item !== null) {
            // 文字列から数値に変換を行う
            const tempDiffMemberInputValue =
              item.diffMemberInputValue !== null && item.diffMemberInputValue !== '' ? Number(item.diffMemberInputValue) : null;
            return {
              // 行番号
              count: item.count,
              // 雇用形態
              employment: item.employment,
              // 重点分野
              priorityDomain: item.priorityDomain,
              // 差異人数
              diffMemberInputValue: isNaN(tempDiffMemberInputValue) || tempDiffMemberInputValue === 0 ? null : tempDiffMemberInputValue,
              // 差異理由
              diffMemberReasonInputValue: item.diffMemberReasonInputValue,
              diffMemberReasonSelectValue: item.diffMemberReasonSelectValue,
            };
          } else {
            return {
              // 行番号
              count: count,
              // 雇用形態
              employment: '',
              // 重点分野
              priorityDomain: '',
              // 差異人数
              diffMemberInputValue: null,
              // 差異理由
              diffMemberReasonInputValue: '',
              diffMemberReasonSelectValue: '',
            };
          }
        });
        return tempDiffFormAnswerInputData;
      }
      return undefined;
    };

    //-------------------------------------------------------------
    // 雇用形態別(人件費)
    //-------------------------------------------------------------
    if (
      personnelEmploymentRowDataEdit !== undefined ||
      personnelEmploymentResultAndPlanDiffFormEdit !== undefined ||
      personnelEmploymentPlanAndPlanDiffFormEdit !== undefined
    ) {
      const personnelEmploymentFormData = surveyDetailData.surveyFormData.formData.tabData[0] as PersonnelEmploymentTabData;
      const personnelEmploymentRowData =
        personnelEmploymentRowDataEdit !== undefined ? personnelEmploymentRowDataEdit.filter((x: any) => x.id !== 'sumTotal') : undefined;
      personnelEmploymentAnswerInputData = {
        tabId: 'personnelEmployment',
        tabTitle: useJudg.getText(personnelEmploymentFormData['tabTitle'] as languageDataTypes),
        tabInputData: [
          {
            id: 'personnelEmploymentGrid',
            title: useJudg.getText(personnelEmploymentFormData.tabFormData[0]['title'] as languageDataTypes),
            type: 'grid',
            data: personnelEmploymentRowData as EnqueteAnswerLoadInputEntity[],
          },
          {
            id: 'planVsAct',
            type: 'diffForm',
            data: personnelEmploymentResultAndPlanDiffFormEdit as PersonnelSurveyDiffFormAnswerInputData[],
          },
          {
            id: 'midTermPlanVsPlan',
            type: 'diffForm',
            data: personnelEmploymentPlanAndPlanDiffFormEdit as PersonnelSurveyDiffFormAnswerInputData[],
          },
        ],
      };
    } else {
      personnelEmploymentAnswerInputData = {};
    }

    //-------------------------------------------------------------
    // 機能別(人件費)
    //-------------------------------------------------------------
    if (personnelCorporateRowDataEdit !== undefined || personnelSalesRowDataEdit !== undefined) {
      const personnelFunctionTabFormData = surveyDetailData.surveyFormData.formData.tabData[1] as PersonnelFunctionTabData;
      // 機能別:コーポレート
      let personnelCorporateRowData =
        personnelCorporateRowDataEdit !== undefined ? personnelCorporateRowDataEdit.filter((x: any) => x.id !== 'sumTotal') : undefined;

      // 機能別:営業
      let personnelSalesRowData =
        personnelSalesRowDataEdit !== undefined ? personnelSalesRowDataEdit.filter((x: any) => x.id !== 'sumTotal') : undefined;

      personnelFunctionTabAnswerData = {
        tabId: 'personnelCorporate',
        tabTitle: useJudg.getText(personnelFunctionTabFormData['tabTitle'] as languageDataTypes),
        tabInputData: [
          {
            id: 'personnelCorporateGrid',
            title: useJudg.getText(personnelFunctionTabFormData.tabFormData[0]['title'] as languageDataTypes),
            type: 'grid',
            data: personnelCorporateRowData as EnqueteAnswerLoadInputEntity[],
          },
          {
            id: 'personnelCorporateText',
            type: 'text',
            data: personnelCorporateOtherFormTextValueEdit !== undefined ? personnelCorporateOtherFormTextValueEdit : ('' as string),
          },
          {
            id: 'personnelSalesGrid',
            title: useJudg.getText(personnelFunctionTabFormData.tabFormData[2]['title'] as languageDataTypes),
            type: 'grid',
            data: personnelSalesRowData as EnqueteAnswerLoadInputEntity[],
          },
        ],
      };
    } else {
      personnelFunctionTabAnswerData = {};
    }
    //-------------------------------------------------------------
    // 人件費人員(重点分野)
    //-------------------------------------------------------------
    if (
      personnelPriorityDomainRegularEmployeeRowDataEdit !== undefined ||
      personnelPriorityDomainSecondmentFromTTCRowDataEdit !== undefined ||
      personnelPriorityDomainSecondmentFromOtherRowDataEdit !== undefined ||
      personnelPriorityDomainDispatchEtcRowDataEdit !== undefined ||
      personnelPriorityDomainResultAndPlanDiffFormEdit !== undefined ||
      personnelPriorityDomainPlanAndPlanDiffFormEdit !== undefined
    ) {
      // 人件費人員(重点分野)設問回答情報
      const personnelPriorityDomainTabFormData = surveyDetailData.surveyFormData.formData.tabData[3] as PersonnelPriorityDomainTabData;
      // 人件費人員(重点分野)：1.正社員 回答情報
      let personnelPriorityDomainRegularEmployeeRowData =
        personnelPriorityDomainRegularEmployeeRowDataEdit !== undefined
          ? personnelPriorityDomainRegularEmployeeRowDataEdit.filter((x: any) => x.id !== 'sumTotal')
          : undefined;
      // 人件費人員(重点分野)：2.TTCからの出向受入 回答情報
      let personnelPriorityDomainSecondmentFromTTCRowData =
        personnelPriorityDomainSecondmentFromTTCRowDataEdit !== undefined
          ? personnelPriorityDomainSecondmentFromTTCRowDataEdit.filter((x: any) => x.id !== 'sumTotal')
          : undefined;
      // 人件費人員(重点分野)：3.2.以外の出向受入 回答情報
      let personnelPriorityDomainSecondmentFromOtherRowData =
        personnelPriorityDomainSecondmentFromOtherRowDataEdit !== undefined
          ? personnelPriorityDomainSecondmentFromOtherRowDataEdit.filter((x: any) => x.id !== 'sumTotal')
          : undefined;
      // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 回答情報
      let personnelPriorityDomainDispatchEtcRowData =
        personnelPriorityDomainDispatchEtcRowDataEdit !== undefined
          ? personnelPriorityDomainDispatchEtcRowDataEdit.filter((x: any) => x.id !== 'sumTotal')
          : undefined;

      // 人件費人員(重点分野)の回答情報を組み合わせてタブ全体の回答情報を作成する
      personnelPriorityDomainTabAnswerData = {
        tabId: 'personnelPriorityDomain',
        tabTitle: useJudg.getText(personnelPriorityDomainTabFormData['tabTitle'] as languageDataTypes),
        tabInputData: [
          {
            // 人件費人員(重点分野)：1.正社員 回答情報
            id: 'personnelPriorityDomainFullTimeEmployeeGrid',
            // タイトル
            title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[0]['title'] as languageDataTypes),
            // タイプ
            type: 'grid',
            // データ
            data: personnelPriorityDomainRegularEmployeeRowData as EnqueteAnswerLoadInputEntity[],
          },
          {
            // 人件費人員(重点分野)：2.TTCからの出向受入 回答情報
            id: 'personnelPriorityDomainExpatFromTtcGrid',
            // タイトル
            title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[1]['title'] as languageDataTypes),
            // タイプ
            type: 'grid',
            data: personnelPriorityDomainSecondmentFromTTCRowData as EnqueteAnswerLoadInputEntity[],
          },
          {
            // 人件費人員(重点分野)：3.2.以外の出向受入 回答情報
            id: 'personnelPriorityDomainExpatOtherGrid',
            // タイトル
            title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[2]['title'] as languageDataTypes),
            // タイプ
            type: 'grid',
            // データ
            data: personnelPriorityDomainSecondmentFromOtherRowData as EnqueteAnswerLoadInputEntity[],
          },
          {
            // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 回答情報
            id: 'personnelPriorityDomainOtherGrid',
            // タイトル
            title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[3]['title'] as languageDataTypes),
            // タイプ
            type: 'grid',
            // データ
            data: personnelPriorityDomainDispatchEtcRowData as EnqueteAnswerLoadInputEntity[],
          },
          // 人件費人員(重点分野)：年計-実見 回答情報
          {
            id: 'planVsAct',
            // タイプ
            type: 'diffForm',
            // データ
            data: isPriorityDomainDiffValueChangeNumber(
              personnelPriorityDomainResultAndPlanDiffFormEdit,
            ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
          },
          // 人件費人員(重点分野)：中計-年計 回答情報
          {
            id: 'midTermPlanVsPlan',
            // タイプ
            type: 'diffForm',
            // データ
            data: isPriorityDomainDiffValueChangeNumber(
              personnelPriorityDomainPlanAndPlanDiffFormEdit,
            ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
          },
        ],
      };
    } else {
      personnelPriorityDomainTabAnswerData = {};
    }
    //-------------------------------------------------------------
    // 製造原価人員(雇用形態別)
    //-------------------------------------------------------------
    if (
      manufyearPlanurEmploymentRowDataEdit !== undefined ||
      manufyearPlanurEmploymentResultAndPlanDiffFormEdit !== undefined ||
      manufyearPlanurEmploymentPlanAndPlanDiffFormEdit !== undefined
    ) {
      const manufyearPlanurEmploymentTabFormData = surveyDetailData.surveyFormData.formData.tabData[2] as ManufyearPlanurEmploymentTabData;
      const manufyearPlanurEmploymentStatusRowData =
        undefined !== manufyearPlanurEmploymentRowDataEdit ? manufyearPlanurEmploymentRowDataEdit.filter((x: any) => x.id !== 'sumTotal') : undefined;
      manufyearPlanurEmploymentTabAnswerData = {
        tabId: 'manufyearPlanurEmployment',
        tabTitle: useJudg.getText(manufyearPlanurEmploymentTabFormData['tabTitle'] as languageDataTypes),
        tabInputData: [
          {
            id: 'manufyearPlanurEmploymentGrid',
            title: useJudg.getText(manufyearPlanurEmploymentTabFormData.tabFormData[0]['tabTitle'] as languageDataTypes),
            type: 'grid',
            data: manufyearPlanurEmploymentStatusRowData as EnqueteAnswerLoadInputEntity[],
          },
          {
            id: 'planVsAct',
            type: 'diffForm',
            data: manufyearPlanurEmploymentResultAndPlanDiffFormEdit as PersonnelSurveyDiffFormAnswerInputData[],
          },
          {
            id: 'midTermPlanVsPlan',
            type: 'diffForm',
            data: manufyearPlanurEmploymentPlanAndPlanDiffFormEdit as PersonnelSurveyDiffFormAnswerInputData[],
          },
        ],
      };
    } else {
      manufyearPlanurEmploymentTabAnswerData = {};
    }
    //-------------------------------------------------------------
    // 製造原価人員(重点分野)
    //-------------------------------------------------------------
    if (
      manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit !== undefined ||
      manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit !== undefined ||
      manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit !== undefined ||
      manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit !== undefined
    ) {
      // 製造原価人員(重点分野)設問情報
      const manufyearPlanurEmploymentPriorityDomainTabFormData = surveyDetailData.surveyFormData.formData
        .tabData[4] as ManufyearPlanurEmploymentPriorityDomainTabData;
      // 製造原価人員(重点分野) 1.正社員：グリッド回答情報
      const manufyearPlanurPriorityDomainRegularEmployeeRowData =
        undefined !== manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit
          ? manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit.filter((x: any) => x.id !== 'sumTotal')
          : undefined;
      // 製造原価人員(重点分野) 2.1以外の従業員/派遣/その他：グリッド回答情報
      const manufyearPlanurPriorityDomainOtherEmployeesRowData =
        undefined !== manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit
          ? manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit.filter((x: any) => x.id !== 'sumTotal')
          : undefined;
      // 製造原価人員(重点分野)の回答情報を組み合わせてタブ全体の回答情報を作成する
      manufyearPlanurEmploymentPriorityDomainTabAnswerData = {
        tabId: 'manufyearPlanurEmploymentPriorityDomain',
        tabTitle: useJudg.getText(manufyearPlanurEmploymentPriorityDomainTabFormData['tabTitle'] as languageDataTypes),
        tabInputData: [
          // 製造原価人員(重点分野)：1.正社員 回答情報
          {
            id: 'manufyearPlanurPriorityDomainFullTimeEmployeeGrid',
            // タイトル
            title: useJudg.getText(manufyearPlanurEmploymentPriorityDomainTabFormData.tabFormData[0]['tabTitle'] as languageDataTypes),
            // タイプ
            type: 'grid',
            // データ
            data: manufyearPlanurPriorityDomainRegularEmployeeRowData as EnqueteAnswerLoadInputEntity[],
          },
          // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 回答情報
          {
            id: 'manufyearPlanurPriorityDomainOtherGrid',
            // タイトル
            title: useJudg.getText(manufyearPlanurEmploymentPriorityDomainTabFormData.tabFormData[1]['tabTitle'] as languageDataTypes),
            // タイプ
            type: 'grid',
            // データ
            data: manufyearPlanurPriorityDomainOtherEmployeesRowData as EnqueteAnswerLoadInputEntity[],
          },
          // 製造原価人員(重点分野)：年計-実見 回答情報
          {
            id: 'planVsAct',
            // タイプ
            type: 'diffForm',
            // データ
            data: isPriorityDomainDiffValueChangeNumber(
              manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit,
            ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
          },
          // 製造原価人員(重点分野)：中計-年計 回答情報
          {
            id: 'midTermPlanVsPlan',
            // タイプ
            type: 'diffForm',
            // データ
            data: isPriorityDomainDiffValueChangeNumber(
              manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit,
            ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
          },
        ],
      };
    } else {
      manufyearPlanurEmploymentPriorityDomainTabAnswerData = {};
    }
    // 保存する回答情報を作成する
    const surveyFormAnswerData: SurveyFormAnswerData = {
      enqueteType: 'personnelSurvey',
      answerInputData: {
        tabData: [
          personnelEmploymentAnswerInputData,
          personnelFunctionTabAnswerData,
          manufyearPlanurEmploymentTabAnswerData,
          personnelPriorityDomainTabAnswerData,
          manufyearPlanurEmploymentPriorityDomainTabAnswerData,
        ],
      },
    };
    return surveyFormAnswerData;
  };
  return { createData };
};
