import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccessRoleType } from 'src/@types/accessRoleType';

export const accessRoleTypeSlice = createSlice({
  name: 'accessRoleType',
  initialState: {
    // 本部/極のアクセス権限リスト
    accessRoleTypeList: [] as AccessRoleType[],
  },
  reducers: {
    setAccessRoleType: (state, action: PayloadAction<AccessRoleType[]>) => {
      state.accessRoleTypeList = action.payload;
    },
  },
});

export const { setAccessRoleType } = accessRoleTypeSlice.actions;

export default accessRoleTypeSlice.reducer;
