import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { VisibleType } from 'src/constants';
import { OrganizationEntity } from './useOrganizationApi';
import { useBearerToken } from './useBearerToken';

const apiEndPoint: string = '/survey-edit/visible-conditions';

/****************************************************************************************
 *
 * Survey-edit関連のAPIを使用しているが、ソースコード行数が肥大するため、仮組織はここに記載する
 *
 ***************************************************************************************/

// 本部極担当 レスポンスインターフェース
export interface ResponseVisibleConditionTypes {
  id: string;
  email: string;
  companyCode: string;
  divisionCode: string;
  sbuCode: string;
  departmentCode: string;
  groupCode: string;
  regionCode: string;
  countryCode: string;
  accessRoleId: string;
  visibleType: VisibleType;
  createdAt?: string;
}

// 本部/極 データインターフェース
type OmitField = 'companyCode' | 'companyNameJpn' | 'companyNameEng' | 'rank' | 'companyAbbreviation' | 'orgLevelType' | 'isFuture' | 'isDeleted';
export interface DivisionRegionEditorDataTypes extends Omit<OrganizationEntity, OmitField> {
  id: string;
  email: string;
  bluePageEmailAddress: string;
  userNameJpn: string;
  userNameEng: string;
  userCompanyCode: string;
  userCompanyNameJpn: string;
  userCompanyNameEng: string;
  userCompanyAbbreviation: string;
  assignedCompanyNameJpn: string;
  assignedCompanyNameEng: string;
  userDivisionCode: string;
  userDivisionNameJpn: string;
  userDivisionNameEng: string;
  userSbuCode: string;
  userSbuNameJpn: string;
  userSbuNameEng: string;
  userDepartmentCode: string;
  userDepartmentNameJpn: string;
  userDepartmentNameEng: string;
  userGroupCode: string;
  userGroupNameJpn: string;
  userGroupNameEng: string;
  accessRoleId: string;
  visibleType: VisibleType;
}

// 本部極担当 リクエストインターフェース
export interface RequestVisibleConditionTypes extends Omit<ResponseVisibleConditionTypes, 'id'> {}

export const useVisibleConditionsApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 一覧取得
   *
   * @param surveyDetailId
   * @returns
   */
  const getVisibleConditionList = async (surveyDetailId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<ResponseVisibleConditionTypes[]>(apiGateway + `${apiEndPoint}/${surveyDetailId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.visibleCondition') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 登録
   *
   * @param surveyDetailId
   * @param data
   * @returns
   */
  const createVisibleCondition = async (surveyDetailId: string, data: RequestVisibleConditionTypes[]) => {
    try {
      const token = await getBearerToken();
      const result = await axios.post<ResponseVisibleConditionTypes>(
        apiGateway + `${apiEndPoint}/${surveyDetailId}`,
        { conditionList: data },
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('common.visibleCondition') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 更新
   *
   * @param surveyDetailId
   * @param surveyVisibleConditionId
   * @param data
   * @returns
   */
  const updateVisibleCondition = async (surveyDetailId: string, surveyVisibleConditionId: string, data: RequestVisibleConditionTypes) => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<RequestVisibleConditionTypes>(
        apiGateway + `${apiEndPoint}/${surveyDetailId}/${surveyVisibleConditionId}`,
        data,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('common.visibleCondition') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 削除
   *
   * @param surveyDetailId
   * @param surveyVisibleConditionId
   * @returns
   */
  const deleteVisibleCondition = async (surveyDetailId: string, surveyVisibleConditionId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.delete<ResponseVisibleConditionTypes>(apiGateway + `${apiEndPoint}/${surveyDetailId}/${surveyVisibleConditionId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedDelete', { target: t('common.visibleCondition') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getVisibleConditionList, createVisibleCondition, updateVisibleCondition, deleteVisibleCondition };
};
