import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverviewEnquete, TARGET_OLG_LEVEL_TYPE } from 'src/@types/overviewEnquete';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { EnqueteParentCustomerGrid } from 'src/components/app-components/bonsai/type-overview/EnqueteParentCustomerGrid';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';

const EnqueteAnswerFormHeader: React.FC = () => {
  const { isJapanese } = useLocales();
  const { t } = useTranslation();

  const { surveyDetailData, respondentTargetData } = useAppSelector((state) => state.enqueteAnswer);
  const overviewEnquete = surveyDetailData.surveyFormData.formData.tabData[0] as OverviewEnquete;

  return (
    <>
      <StyledBox>
        <Grid container direction="column" rowSpacing={2}>
          <EnqueteParentCustomerGrid
            customerCode={overviewEnquete.customerCode}
            parentCustomerCode={overviewEnquete.parentCustomerCode}
            parentCustomerNameJpn={overviewEnquete.parentCustomerNameJpn}
            parentCustomerNameEng={overviewEnquete.parentCustomerNameEng}
          />
          <Grid item xs={4}>
            <Grid container item direction="row">
              <Typography variant="subtitle1">{`${t('enqueteCreate.overviewEnqueteOption.customerName')}：`}</Typography>
              <Typography variant="subtitle1">
                {isJapanese ? trimStockCompany(overviewEnquete.customerNameJpn) : overviewEnquete.customerNameEng}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </StyledBox>
      <StyledBox>
        <Grid item sx={{ display: 'flex' }}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" paddingBottom={1}>
              {t('enqueteAnswerInput.overviewSurvey.answerPeriod')}
            </Typography>
            <Typography>
              {overviewEnquete?.reportingPeriod?.startDate}〜{overviewEnquete?.reportingPeriod?.endDate}
            </Typography>
          </Grid>
          {respondentTargetData.organization?.companyCode !== 'S500' && (
            <Grid item xs={6}>
              <Typography variant="subtitle1" paddingBottom={1}>
                {t('enqueteAnswerInput.overviewSurvey.targetAggregationUnit')}
              </Typography>
              <Typography>
                {overviewEnquete.targetOrgLevelType === TARGET_OLG_LEVEL_TYPE.COMPANY ? t('common.companyName') : t('common.sbuName')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </StyledBox>
    </>
  );
};

export default EnqueteAnswerFormHeader;
