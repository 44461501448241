import { ParentCustomer } from 'src/@types/seriesEnquete';
import { PartialCustomerData } from 'src/api/useCustomerApi';
import { ChildrenCustomer, CustomerGroup } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppSelector } from 'src/redux/store';
import { getCustomerStatus } from '../components/util/settingForTradingVolumeForm';

export const useProposalInitValue = () => {
  const { customers } = useAppSelector((state) => state.seriesAnswer);
  /**
   * グローバルステートにCustomerGroupを設定
   * @param parentCustomers
   * @param customersData
   * @returns
   */
  const setCustomerGroup = async (parentCustomers: ParentCustomer[], customersData?: PartialCustomerData[]) => {
    const customerList = customers.length === 0 ? customersData : customers;
    if (!customerList) return { defaultCurrency: '', customerGroups: [], arraySourceType: '' };
    let defaultCurrency = '';
    const customerGroups: CustomerGroup[] = [];
    const arraySourceType: string[] = [];

    parentCustomers.forEach((parentCustomer) => {
      const childrenCustomers: ChildrenCustomer[] = [];
      parentCustomer.customers.forEach((customer) => {
        const customerStatus = getCustomerStatus(customer.customerCode, customerList, parentCustomer.parentCustomerCode);
        const children: ChildrenCustomer = {
          ...customer,
          customerStatus,
        };
        childrenCustomers.push(children);
        defaultCurrency = customer.currencyUnit;
        arraySourceType.push(customer.previousYear.sourceType);
      });
      const parent: CustomerGroup = {
        parentCustomerCode: parentCustomer.parentCustomerCode,
        parentCustomerName: parentCustomer.parentCustomerName,
        parentCustomerNameEng: parentCustomer.parentCustomerNameEng,
        childrenCustomer: childrenCustomers,
        isOpen: true,
      };
      customerGroups.push(parent);
    });
    return { defaultCurrency, customerGroups, arraySourceType };
  };
  return { setCustomerGroup };
};
