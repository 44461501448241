import { useSnackbar } from 'notistack5';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnqueteAnswersListGrid } from 'src/features/general/enquete-answer/hooks/useEnqueteAnswersListGrid';
import { RootState, useAppSelector } from 'src/redux/store';

export const usePreviousAnswersList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { loadList } = useEnqueteAnswersListGrid();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const answerList = useAppSelector((state: RootState) => state.enqueteAnswer.enqueteAnswerslistData);

  const getSurveyAnswerList = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      await loadList();
    } catch (error) {
      setIsError(true);
      enqueueSnackbar(t('apiExceptionMessage.useAnswerApi.failedGetData'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [loadList, enqueueSnackbar, t]);

  useEffect(() => {
    if (answerList.length > 0) {
      return;
    }
    getSurveyAnswerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    isError,
  };
};
