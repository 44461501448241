import { GetContextMenuItems, GetContextMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'src/hooks/usePermission';
import { dispatch } from 'src/redux/store';
import { openApplyA1CodeModal, openEditCustomerModal } from '../store/customerManagementSlice';

export const useBonsaiAddedCustomerListGrid = () => {
  const { isViewerAdmin } = usePermission();
  const { t } = useTranslation();
  const contextMenu: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    const menuItems = [];
    menuItems.push({
      name: t('button.edit'),
      action: () => {
        dispatch(openEditCustomerModal(params.node?.data));
      },
    });
    menuItems.push({
      name: t('customerManagement.applyA1Code'),
      action: () => {
        dispatch(openApplyA1CodeModal(params.node?.data));
      },
      disabled: isViewerAdmin,
    });
    return menuItems;
  };

  const onRowDoubleClickedCallback = (e: RowDoubleClickedEvent) => {
    dispatch(openEditCustomerModal(e.data));
  };

  return {
    contextMenu,
    onRowDoubleClickedCallback,
  };
};
