import { Grid, Typography } from '@mui/material';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';

export interface TextFieldProps {
  methods: UseFormReturn<FieldValues, any>;
  name: string;
  displayName: string;
  defaultValue: string | null;
  size: number;
  label: string;
}

const TextField: React.FC<TextFieldProps> = (props) => {
  const { methods, name, displayName, defaultValue, size, label } = props;
  return (
    <>
      <Grid item xs={2}>
        <Typography variant="subtitle1"> {displayName}</Typography>
      </Grid>
      <Grid item xs={size}>
        <RHFTextField name={name} defaultValue={defaultValue} control={methods.control} label={label} size="small" variant="outlined" fullWidth />
      </Grid>
    </>
  );
};

export default TextField;
