import { useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { AggregateCustomer } from 'src/@types/seriesAggregateSupplement';
import { useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import {
  setCurrentYear,
  setDefaultAggregateSupplement,
  setPreviousYear,
} from 'src/features/general/enquete-status/store/seriesAggregateSupplementSlice';
import { dispatch } from 'src/redux/store';

export const useAggregateSupplement = (respondentRequestId?: string) => {
  const [aggregateSupplement, setAggregateSupplement] = useState<AggregateCustomer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getSeriesAggregateSupplement, updateSeriesAggregateSupplement } = useEnqueteStatusPageApi();
  const [isSaving, setIsSaving] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const surveyDetailId: string = String(params.get('surveyDetailId'));

  const onSaveClick = useCallback(
    async (data: FieldValues) => {
      try {
        setIsSaving(true);
        const result = await updateSeriesAggregateSupplement(surveyDetailId, data.aggregateCustomers);
        setAggregateSupplement(result.aggregateSupplementData.aggregateCustomers);
      } finally {
        setIsSaving(false);
      }
    },
    [updateSeriesAggregateSupplement, surveyDetailId],
  );

  const setData = useCallback(
    async (respondentRequestId: string) => {
      try {
        setIsLoading(true);
        const result = await getSeriesAggregateSupplement(respondentRequestId);
        if (result) {
          dispatch(setPreviousYear(result.previousYear));
          dispatch(setCurrentYear(result.currentYear));
          setAggregateSupplement(result.answeredAggregateSupplement.aggregateCustomers);
          dispatch(setDefaultAggregateSupplement(result.defaultAggregateSupplement.aggregateCustomers));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [getSeriesAggregateSupplement],
  );

  useEffect(() => {
    respondentRequestId && setData(respondentRequestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    aggregateSupplement,
    isLoading,
    isSaving,
    onSaveClick,
  };
};
