import { Grid, Typography } from '@mui/material';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';

export interface ArrayNumericFieldProps {
  methods: UseFormReturn<FieldValues>;
  name: string;
  displayName: string | null;
  defaultValue: (number | null)[];
  length: number;
  label: string;
  rules?: object;
}

const ArrayNumericField: React.FC<ArrayNumericFieldProps> = (props) => {
  const { methods, name, displayName, defaultValue, length, label, rules } = props;
  return (
    <>
      <Grid item xs={2}>
        <Typography variant="subtitle1"> {displayName}</Typography>
      </Grid>
      {defaultValue.slice(0, length).map((value, index) => {
        return (
          <Grid item xs={2} id={`${name}${index}`} key={`${name}${index}`}>
            <RHFNumericField
              control={methods.control}
              name={`${name}${index}`}
              rules={rules}
              defaultValue={value}
              textFieldProps={{
                size: 'small',
                label: `${label}${index + 1}`,
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default ArrayNumericField;
