import { Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { ParentPanelComponent } from './common/components/ParentPanelComponent';
import { TabHeader } from './common/components/TabHeader';
import { useGetDefaultValue } from './common/hooks/useGetDefaultValue';
import { EnqueteAnswerSeriesDescriptionBox } from './EnqueteAnswerSeriesDescriptionBox';

export const EnqueteAnswerSeriesSurveyTab: React.FC = () => {
  const methods = useForm({ mode: 'onChange' });
  const { isLoading } = useGetDefaultValue();
  return (
    <LoadableComponent isLoading={isLoading} zIndex={1500}>
      {!isLoading && (
        <Stack component="form" sx={{ width: '100%' }}>
          <Grid container direction="column" justifyContent="flex-start">
            <EnqueteAnswerSeriesDescriptionBox />
            <TabHeader methods={methods} />
            <Grid item xs={12} md={12}>
              <ParentPanelComponent methods={methods} />
            </Grid>
          </Grid>
        </Stack>
      )}
    </LoadableComponent>
  );
};
