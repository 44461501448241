// 仕入高の差額の判定用に使う金額、単位は円
export const CONDITIONNUMBER = 1000000000;

// 入力制限 上限値
export const MAXINPUTAMOUNT = 999999999999;

// 増減比率　上限値
export const MAXYOY = 999;

// 債権債務回転日数　上限値
export const MAXDAYS = 999;
