import { RequestPersonDataListEntity, SurveyEditEntity, useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { store } from 'src/redux/store';
import { SelectRespondentModalResultRowData } from '../components/modals/select-respondent/components/SelectRespondentModal';
import { useEnqueteCreateGrid } from '../hooks/useEnqueteCreateGrid';

type PersonEntity = {
  email: string | null;
  bluePageEmailAddress: string | null;
  userNameJpn: string | null;
  userNameEng: string | null;
  companyCode: string | null;
  assignedCompanyNameJpn: string | null;
  assignedCompanyNameEng: string | null;
  sbuCode: string | null;
  divisionCode: string | null;
  departmentCode: string | null;
  groupCode: string | null;
};

export const useEnqueteSelectAddress = () => {
  const surveyEditApi = useSurveyEditApi();
  const { getRequestAnswerList } = useEnqueteCreateGrid();

  /**
   * 登録済み宛先一覧に同じ宛先がないか検証
   */
  const validateRequestListData = (selectedRowData: SelectRespondentModalResultRowData[]) => {
    if (selectedRowData.length === 0) return false;

    const requestListData = store.getState().enqueteCreate.requestListData;
    const exchangedSelectedRowData = exchangeParames(selectedRowData);

    // 重複があるか検証
    const duplicateSerialNumberList = getDuplicateSerialNumberList(exchangedSelectedRowData, requestListData);

    //重複が見つかったら、trueを返却
    return duplicateSerialNumberList.length > 0;
  };

  /**
   * 宛先一覧に宛先追加候補追加
   */
  const addRequestListData = async (respondentRequestId: string, selectedRowData: SelectRespondentModalResultRowData[]) => {
    const requestPersonDataList: RequestPersonDataListEntity[] = setRequestPersonDataList(selectedRowData);
    const isSuccess = await surveyEditApi.addRequestPersonDataList(respondentRequestId, { respondentTargets: requestPersonDataList });

    if (isSuccess) {
      getRequestAnswerList(respondentRequestId);
    }
  };

  /**
   * 重複している回答会社、担当者を抽出
   *
   * @param targetList
   * @param requestListData
   * @returns
   */
  const getDuplicateSerialNumberList = (targetList: SurveyEditEntity[], requestListData: SurveyEditEntity[]) => {
    let duplicateSerialNumberList: number[] = [];

    if (requestListData.length === 0) return duplicateSerialNumberList;

    for (let x = 0; x < targetList.length; x++) {
      if (isDuplicateRequestData(targetList[x], requestListData)) {
        duplicateSerialNumberList.push(x + 1);
      }
    }

    return duplicateSerialNumberList;
  };

  /**
   * 重複したデータがあるか真偽を返却
   */
  const isDuplicateRequestData = (targetData: SurveyEditEntity, requestListData: SurveyEditEntity[]) => {
    let isDuplicate = false;
    if (requestListData.length === 0) return isDuplicate;

    for (const requestList of requestListData) {
      if (
        targetData.companyCode === requestList.companyCode &&
        targetData.targetDivisionCode === requestList.targetDivisionCode &&
        targetData.targetSbuCode === requestList.targetSbuCode &&
        targetData.targetDepartmentCode === requestList.targetDepartmentCode &&
        targetData.personCompanyCode === requestList.personCompanyCode &&
        targetData.personDivisionCode === requestList.personDivisionCode &&
        targetData.personSbuCode === requestList.personSbuCode &&
        targetData.personDepartmentCode === requestList.personDepartmentCode &&
        targetData.email === requestList.email
      ) {
        isDuplicate = true;
        break;
      }
    }

    return isDuplicate;
  };

  /**
   * パラメータ変換
   *
   * @param rowData
   * @returns
   */
  const exchangeParames = (rowData: SelectRespondentModalResultRowData[]) => {
    const exchangeKeyMappingList: any = {
      targetCompanyCode: 'companyCode',
      targetRank: 'rank',
      targetRegionNameJpn: 'regionNameJpn',
      targetRegionNameEng: 'regionNameEng',
      targetCountryNameJpn: 'countryNameJpn',
      targetCountryNameEng: 'countryNameEng',
      targetCountryCode: 'countryCode',
      respondentPersonFullNameJpn: 'userNameJpn',
      respondentPersonFullNameEng: 'userNameEng',
      respondentPersonCompanyNameJpn: 'personCompanyNameJpn',
      respondentPersonCompanyNameEng: 'personCompanyNameEng',
      respondentPersonDivisionNameJpn: 'personDivisionNameJpn',
      respondentPersonDivisionNameEng: 'personDivisionNameEng',
      respondentPersonSbuNameJpn: 'personSbuNameJpn',
      respondentPersonSbuNameEng: 'personSbuNameEng',
      respondentPersonDepartmentNameJpn: 'personDepartmentNameJpn',
      respondentPersonDepartmentNameEng: 'personDepartmentNameEng',
      respondentPersonEmail: 'email',
      respondentPersonCompanyCode: 'personCompanyCode',
      respondentPersonDivisionCode: 'personDivisionCode',
      respondentPersonSbuCode: 'personSbuCode',
      respondentPersonDepartmentCode: 'personDepartmentCode',
    };

    let renamedRowData: any = [];
    rowData.forEach((row: any, i: any) => {
      renamedRowData[i] = {};

      Object.keys(row).forEach((key) => {
        const newKey = exchangeKeyMappingList[key] || key;
        renamedRowData[i][newKey] = row[key];
      });
    });

    return renamedRowData;
  };

  /**
   * APIリクエスト用に整形
   */
  const setRequestPersonDataList = (selectedRowData: SelectRespondentModalResultRowData[]) => {
    const baseTargetDataList = selectedRowData.map((row) => {
      return {
        organizationId: row.targetId,
      };
    });

    const targetDataList = baseTargetDataList.filter((v, i, arr) => {
      return arr.findIndex((p) => p.organizationId === v.organizationId) === i;
    });

    const requestTargetDataList = targetDataList.map((org) => {
      let personList: PersonEntity[] = [];

      selectedRowData.forEach((row) => {
        const person: PersonEntity = {
          email: row.respondentPersonEmail,
          bluePageEmailAddress: row.respondentPersonBluePageEmailAddress,
          userNameJpn: row.respondentPersonFullNameJpn ?? '',
          userNameEng: row.respondentPersonFullNameEng ?? '',
          companyCode: row.respondentPersonCompanyCode ?? '',
          assignedCompanyNameJpn: row.respondentPersonAssignedCompanyNameJpn ?? '',
          assignedCompanyNameEng: row.respondentPersonAssignedCompanyNameEng ?? '',
          divisionCode: row.respondentPersonDivisionCode ?? '',
          sbuCode: row.respondentPersonSbuCode ?? '',
          departmentCode: row.respondentPersonDepartmentCode ?? '',
          groupCode: row.respondentPersonGroupCode ?? '',
        };

        if (org.organizationId === row.targetId) {
          personList.push(person);
        }
      });

      return { ...org, respondentPersons: personList };
    });

    return requestTargetDataList;
  };

  return { validateRequestListData, addRequestListData };
};
