import { HotTable } from '@handsontable/react';
import { RefObject, useEffect } from 'react';
import { ColWidths, MainTableType, MergeCells, NestedHeader, RowHeights, TableData } from 'src/@types/generalEnquete';
import { initialSettings } from 'src/components/app-components/bonsai/type-general-table/constants/initialSettings';
import { RootState, useSelector } from 'src/redux/store';
import { calcRowHeaderWidth } from 'src/utils/handsontable';

export const useNewAnswerTableData = (answerHotRef: RefObject<HotTable>[]) => {
  const totalMainTables = useSelector((state: RootState) => state.tableSetting.totalMainTables);

  // インタンス初期化共通処理
  const loadDataMainTable = (
    hr: RefObject<HotTable>,
    srcData: TableData,
    cellData: any[],
    mrgCells: MergeCells,
    cWidths: ColWidths,
    rHeights: RowHeights,
    ntdHeaders: (string | NestedHeader)[][] | undefined,
    rHeaders: string[] | undefined,
    fixCol: number | undefined,
    fixRow: number | undefined,
  ) => {
    if (hr.current) {
      const makeLoadData = srcData.map((row) => {
        return row.map((cell) => {
          return cell ?? '';
        });
      });

      hr.current.hotInstance?.loadData(makeLoadData);
      hr.current.hotInstance?.updateSettings({
        ...initialSettings,
        cell: cellData,
        mergeCells: mrgCells,
        rowHeights: rHeights,
        colWidths: Array.isArray(cWidths) && 0 < cWidths.length ? cWidths : 100,
        nestedHeaders: Array.isArray(ntdHeaders) && 0 < ntdHeaders.length ? ntdHeaders : undefined,
        rowHeaders: Array.isArray(rHeaders) ? rHeaders : true,
        rowHeaderWidth: calcRowHeaderWidth(rHeaders),
        fixedColumnsStart: fixCol,
        fixedRowsTop: fixRow,
      });
    }
  };

  // メイン表のインタンス初期化
  const loadDataAndUpdateSetting = () => {
    // 回答表インスタンス化
    totalMainTables.forEach((mainTable: MainTableType, index: number) => {
      loadDataMainTable(
        answerHotRef[index],
        mainTable.sourceData,
        mainTable.cell,
        mainTable.mergeCells,
        mainTable.colWidths,
        mainTable.rowHeights,
        mainTable.nestedHeaders,
        mainTable.rowHeaders,
        mainTable.fixedColumnsStart,
        mainTable.fixedRowsTop,
      );
    });
  };

  useEffect(() => {
    loadDataAndUpdateSetting();
  }, [totalMainTables]); // eslint-disable-line react-hooks/exhaustive-deps
};
