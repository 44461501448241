import { isAfter, isBefore, isValid } from 'date-fns';
import { RequestStatus, REQUEST_STATUS } from 'src/constants';

export const getRequestStatus = (openedAt: string, closedAt: string): RequestStatus | undefined => {
  if (!isValid(new Date(openedAt)) || !isValid(new Date(closedAt))) {
    return;
  }
  const now = new Date();
  if (isBefore(now, new Date(openedAt))) {
    return REQUEST_STATUS.BEFORE_REQUEST;
  }
  if (isAfter(now, new Date(closedAt))) {
    return REQUEST_STATUS.COMPLETE;
  }
  return REQUEST_STATUS.IN_PROGRESS;
};
