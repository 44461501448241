import { Box, Button, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { PERMISSION } from 'src/api/api-constants';
import { EnqueteThread } from 'src/api/getThreadsApi';
import useLocales from 'src/hooks/useLocales';
import { getOrganizationName, getUserName } from 'src/utils/getThreadUserInfo';
import { ThreadUserInfoBox } from '../../common/components/ThreadUserInfoBox';
import { useThreadCard } from '../hooks/useThreadCard';

interface Props {
  thread: EnqueteThread;
}

export const ThreadCard = ({ thread }: Props) => {
  const { id, title, description, isPrivate, updatedAt, permission, commentCount, categories } = thread;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const creatorName = getUserName(thread.creator, isJapanese);
  const creatorOrganizationName = getOrganizationName(thread.creator, isJapanese);

  const { editClick, showDetails } = useThreadCard({ id });
  const handleLinkClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    // aタグのクリック処理
    event.stopPropagation(); // イベントの伝播を阻止
  };

  return (
    <Card
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        width: '100%',
      }}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ paddingTop: 2 }}>
        <Box sx={{ ml: 2, display: 'flex', gap: 2 }}>
          <Chip label={isPrivate ? 'Private' : 'Public'} color={isPrivate ? 'primary' : 'error'} size="medium" sx={{ verticalAlign: 'middle' }} />
          {categories.map((category, index) => (
            <Chip label={isJapanese ? category.nameJpn : category.nameEng} key={index} />
          ))}
        </Box>
        {/*　memo: 解決済みは2次開発での開発対象外　将来のためコメントアウト */}
        {/* {isSolved && (
              <Grid item>
                <Chip label={t('enqueteThread.common.isSolved')} color="success" size="small" sx={{ ml: 2 }} />
              </Grid>
            )} */}
        <Box sx={{ paddingRight: 2 }}>
          <Button variant="outlined" sx={{ ml: 2 }} color="info" onClick={showDetails}>
            {t('enqueteThread.commentList')}
          </Button>
          {permission === PERMISSION.SUPER_USER && (
            <>
              <Button variant="outlined" sx={{ ml: 2 }} onClick={editClick}>
                {t('enqueteAnswerInput.editButton.edit')}
              </Button>
              {/* 削除　memo: 2次開発での開発対象外　将来のためコメントアウト
                      <Button variant="outlined" sx={{ ml: 2 }} color="error">
                        {t('enqueteAnswerInput.editButton.delete')}
                      </Button> */}
            </>
          )}
        </Box>
      </Grid>
      <ThreadUserInfoBox userName={creatorName} organizationName={creatorOrganizationName} updatedAt={updatedAt} sx={{ p: 2 }} />
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="h5" component="h5" sx={{ mb: 1 }}>
          {title}
          <Typography variant="body2" color="textSecondary" component="span" sx={{ ml: 2 }}>
            {t('enqueteThread.common.commentCount', { variable: commentCount })}
          </Typography>
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
          onClick={handleLinkClick}
          sx={{
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
        />
      </CardContent>
    </Card>
  );
};
