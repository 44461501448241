import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface BaseEntity {
  baseCode: string;
  baseNameJpn: string;
  baseNameEng: string;
}

interface PortalBaseEntity {
  company_s_cd: string;
  country_cd: string;
  base_cd: string;
  base_name: string;
  base_eng_name: string;
}

// getByFilter で指定可能な検索条件
interface SearchCondition {
  companyCode?: string;
}

const BASE_URL = `${portalApi}/master/base`;

export const useBaseApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getByFilter = async (condition: SearchCondition) => {
    const where = { company_s_cd: condition.companyCode };
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalBaseEntity[]>(BASE_URL, {
        headers: { Authorization: token },
        params: { filter: { where: where, orderBy: [{ base_cd: 'asc' }] } },
      });

      return result.data.map<BaseEntity>((master) => ({
        baseCode: master.base_cd,
        baseNameJpn: master.base_name,
        baseNameEng: master.base_eng_name,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          enqueueSnackbar(t('message.notfound', { data: t('master.base') }), { variant: 'warning', autoHideDuration: 2000 });
          return;
        }
        enqueueSnackbar(t('apiExceptionMessage.useBaseApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getByFilter };
};
