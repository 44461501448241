import { useEffect } from 'react';
import { GeneralEnquete, TableType } from 'src/@types/generalEnquete';
import { setQuestionDescriptionEng, setQuestionDescriptionJpn } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import {
  addSheetNames,
  addTotalMainTables,
  initSettings,
  initValidSheetNames,
  setMainTableSheetNames,
  setSubData,
  setTotalMainTables,
} from 'src/features/general/enquete-create/store/tableSettingSlice';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store/index';
import { createMainTableInitialData } from 'src/utils/handsontable';
import { useSurveyTableCreateDataService } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useSurveyTableCreateDataService';

export const useTableData = () => {
  const dispatch = useDispatch();
  const { makeTotalMainTables, makeMainTableSheetNames } = useSurveyTableCreateDataService();

  // ストア
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const tabData = enqueteData.surveyDetail?.surveyFormData?.formData.tabData[0] as GeneralEnquete;

  useEffect(() => {
    if (!enqueteData.surveyDetailId) {
      // 新規作成時
      dispatch(setQuestionDescriptionJpn(''));
      dispatch(setQuestionDescriptionEng(''));
      dispatch(initSettings());
      dispatch(addSheetNames());
      dispatch(addTotalMainTables(createMainTableInitialData(5, 5)));
      dispatch(initValidSheetNames(1)); // シート名称検証管理配列を1で初期化
    } else {
      // 保存データ存在時
      const totalMainTables = makeTotalMainTables(tabData);
      const mainTableSheetNames = makeMainTableSheetNames(tabData);

      dispatch(setQuestionDescriptionJpn(tabData.questionDescriptionJpn));
      dispatch(setQuestionDescriptionEng(tabData.questionDescriptionEng));
      dispatch(setMainTableSheetNames(mainTableSheetNames));
      dispatch(setTotalMainTables(totalMainTables));
      dispatch(setSubData((tabData.tables as TableType).subDatas));
      dispatch(initValidSheetNames(totalMainTables.length)); // シート名称検証管理配列を既存データ数で初期化
    }

    // アンマウント時
    return () => {
      dispatch(setQuestionDescriptionJpn(''));
      dispatch(setQuestionDescriptionEng(''));
      dispatch(initSettings());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
