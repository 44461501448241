import { Box, Button, Chip, Grid, Link, Typography } from '@mui/material';
import { format, subWeeks } from 'date-fns';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useLocales from 'src/hooks/useLocales';
import { StyledBox } from '../../../StyledBox';
import { StyledModal } from '../../../StyledModal';
import { EXCLUDE_ROUTE_LIST } from '../constants/exclude_routes';
import { useNotification } from '../hooks/useNotification';

type NotificationProps = {
  calledByClick?: boolean;
};

export const NotificationModal = ({ calledByClick = false }: NotificationProps) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { currentLang } = useLocales();
  const location = useLocation();
  const { notifications, isVisible, handleClose, setIsVisible } = useNotification();
  const modalRef = useRef<HTMLDivElement>(null);

  // お知らせ管理関連のページは表示対象から除外/ベルのアイコンのクリックで呼ばれた場合以外
  if (!calledByClick && EXCLUDE_ROUTE_LIST.some((item) => location.pathname.includes(item))) return null;

  let title: string;
  let content: string;
  if (notifications && notifications.length !== 0) {
    title = currentLang.value === 'ja' ? notifications[selectedIndex].titleJpn : notifications[selectedIndex].titleEng;
    content = currentLang.value === 'ja' ? notifications[selectedIndex].contentJpn : notifications[selectedIndex].contentEng;
  }

  const isWithinAWeek = (givenDate: string): boolean => {
    const oneWeekAgo = subWeeks(new Date(), 1);
    return new Date(givenDate) > new Date(oneWeekAgo);
  };

  const conditionalRender = () => {
    if (notifications && notifications?.length !== 0) {
      return (
        <StyledModal
          ref={modalRef}
          isOpen={isVisible}
          onCloseFunc={() => {
            if (calledByClick) return;
            setIsVisible(false);
          }}
          modalTitle={t('notifications.modal.pageTitle')}>
          <Box
            sx={{
              position: 'relative',
              width: '80vw',
            }}>
            <Grid container>
              <Grid item xs={12} sx={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {title}
                  </Typography>
                </Box>
                <StyledBox>
                  <div dangerouslySetInnerHTML={{ __html: content }} data-testid="notification-content" />
                </StyledBox>
                {notifications.length > 1 && (
                  <StyledBox>
                    {notifications.map((item, index) => (
                      <Box
                        sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 2, m: 2, pr: 2 }}
                        key={item.id}
                        data-testid="notification-list">
                        {isWithinAWeek(item.publishedAt) && <Chip color="error" label="New" size="small" />}
                        <Chip label={t(item.category)} size="small" />
                        <Box sx={{ color: 'gray' }}>{format(new Date(item.publishedAt), 'yyyy/MM/dd')}</Box>
                        <Link
                          onClick={() => {
                            setSelectedIndex(index);
                            if (modalRef.current) {
                              modalRef.current.scrollTop = 0;
                            }
                          }}
                          sx={{ cursor: 'pointer' }}>
                          {currentLang.value === 'ja' ? item.titleJpn : item.titleEng}
                        </Link>
                      </Box>
                    ))}
                  </StyledBox>
                )}
              </Grid>
              <Grid item xs={12} sx={{ position: 'sticky', bottom: 2 }}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                  <Button color="primary" variant="contained" size="large" onClick={handleClose}>
                    {calledByClick ? t('button.close') : t('button.confirmed')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </StyledModal>
      );
    }
  };

  return (
    <>
      {isVisible && (
        <Box
          sx={{
            position: 'relative',
            maxWidth: '80vw',
          }}>
          {conditionalRender()}
        </Box>
      )}
    </>
  );
};
