import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { currentEnv } from 'src/config';

const Snow = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  zIndex: 99999,
  '& canvas': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const Snowfall: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let flakes: { x: number; y: number; size: number; speed: number }[] = [];

    const createFlake = () => {
      const x = Math.random() * canvas.width;
      const y = -5;
      const size = Math.random() * 3 + 2;
      const speed = Math.random() * 1 + 0.5;
      flakes.push({ x, y, size, speed });
    };

    const updateFlakes = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      flakes.forEach((flake, index) => {
        flake.y += flake.speed;

        if (flake.y > canvas.height) {
          flakes.splice(index, 1);
        }

        ctx.beginPath();
        ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
        ctx.fillStyle = 'white';
        ctx.fill();
      });

      requestAnimationFrame(updateFlakes);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    setInterval(createFlake, 100);
    updateFlakes();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isProduction = currentEnv === 'production';
  const month = new Date().getMonth();
  if (isProduction || month !== 11) return null; // 本番環境では表示しない

  return (
    <Snow>
      <canvas ref={canvasRef} />
    </Snow>
  );
};

export default Snowfall;
