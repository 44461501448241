import { GetContextMenuItems, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { searchDataEntity } from 'src/api/useOrganizationApi';
import { useSelectUserAgGrid } from 'src/components/app-components/bonsai/hooks/useSelectUserAgGrid';
import { ConditionProps } from 'src/components/app-components/bonsai/SelectModal';
import { enqueteThreadCreateSlice } from 'src/features/general/enquete-threads/store/enqueteThreadCreateSlice';
import useSuggestUser from 'src/hooks/useSuggestUser';
import { useAppSelector } from 'src/redux/store';

export const useMemberListCreateModal = () => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { userSuggest, updateUserSuggest } = useSuggestUser();
  const dispatch = useDispatch();
  // selector  values
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  // grid api
  const gridApi = useRef<GridApi | null>(null);
  // グリッド定義
  const { columnDefs } = useSelectUserAgGrid();

  //　スレッドユーザー取得
  const threadUsers = useAppSelector((state) => state.enqueteThreadCreate.threadUsers);
  // condition define
  const conditions: ConditionProps[] = [
    {
      label: t('enqueteThread.create.createModalTextFieldPlaceholder'),
      value: selectedUser,
      list: userSuggest.map((item) => item.key),
      display: true,
      onChange: (value) => setSelectedUser(value),
      onInputChange: (v, r) => {
        if (r !== 'reset') updateUserSuggest(v);
      },
    },
  ];
  //-------------------------------------------------------------
  // グリッドの表示準備完了時の処理
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // 追加ボタンが押された時の処理
  //-------------------------------------------------------------
  const onSelected = async () => {
    // check input string
    const user = userSuggest.find((user) => user.key === selectedUser);
    if (!user) return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.addRespondentsModal.message.noUserSelected'), { variant: 'error' });
    // 検索条件定義
    const condition: searchDataEntity = {
      companyCode: user.entity.companyCode ?? '',
      divisionCode: user.entity.divisionCode ?? '',
      sbuCode: user.entity.sbuCode ?? '',
      departmentCode: user.entity.departmentCode ?? '',
      groupCode: user.entity.groupCode ?? '',
      isFuture: false,
      isMultiSegment: false,
    };
    const addRowData = {
      ...user.entity,
      divisionCode: condition.divisionCode,
      sbuCode: condition.sbuCode,
      departmentCode: condition.departmentCode,
    };
    if (threadUsers.find((v) => v.email === user.entity.email)) {
      enqueueSnackbar(t('message.alreadyRegisteredSelected'), { variant: 'error' });
      return;
    }
    dispatch(enqueteThreadCreateSlice.actions.addThreadUsers(addRowData));
  };

  //-------------------------------------------------------------
  // 削除ボタンが押された時の処理
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];
    const resetThreadUsers = threadUsers.filter((_, i) => i !== params.node?.rowIndex);
    const menuItems = [
      {
        name: t('button.delete'),
        action: () => dispatch(enqueteThreadCreateSlice.actions.deleteThreadUser(resetThreadUsers)),
      },
    ];
    return menuItems;
  };

  return { contextMenu, onSelected, onGridReady, conditions, columnDefs, updateUserSuggest, threadUsers };
};
