import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { FORM_TYPE, FormType, SURVEY_TYPE, SurveyType } from 'src/constants';
import { AlertPersonalDataModal } from '../../alert-personal-data/components/AlertPersonalDataModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectEnqueteTypeModal = (props: Props) => {
  const { t } = useTranslation();

  const general: SurveyType = SURVEY_TYPE.GENERAL;
  const overview: FormType = FORM_TYPE.OVERVIEW;
  const [isOpen, setIsOpen] = useState(false);
  const [enqueteType, setEnqueteType] = useState<SurveyType | FormType>(general);

  const translationKey = 'enqueteRequest.selectEnqueteTypeModal';
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnqueteType((event.target as HTMLInputElement).value as SurveyType | FormType);
  };
  return (
    <>
      <StyledModal isOpen={props.isOpen} onCloseFunc={props.onClose} modalTitle={t(`${translationKey}.title`)}>
        <RadioGroup defaultValue={general} onChange={handleChange}>
          <FormControlLabel control={<Radio />} value={general} label={t(`${translationKey}.${general.toLocaleLowerCase()}`)} />
          <FormControlLabel control={<Radio />} value={overview} label={t(`${translationKey}.${overview.toLocaleLowerCase()}`)} />
        </RadioGroup>
        <Box textAlign="center" sx={{ m: 2 }}>
          <StyledButton
            onClick={() => {
              props.onClose();
              setIsOpen(true);
            }}>
            {t('button.next')}
          </StyledButton>
        </Box>
      </StyledModal>
      <AlertPersonalDataModal isOpen={isOpen} onClose={() => setIsOpen(false)} enqueteType={enqueteType} />
    </>
  );
};
