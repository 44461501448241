class BaseError extends Error {
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;
  }
}

export class NetworkAccessError extends BaseError {
  constructor(public statusCode: number | undefined, e?: string) {
    super(e);
  }

  isForbidden() {
    return this.statusCode === 403;
  }
}
