import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, Tab } from '@mui/material';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SeriesEnqueteOptionData } from 'src/@types/seriesEnquete';
import { DivisionRegionEditorTab } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/division-region-editor-tab/components/DivisionRegionEditorTab';
import { SurveyOwnerTab } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/survey-owner-tab/components/SurveyOwnerTab';
import { TAB_VALUE, TabValue } from 'src/features/general/enquete-create/constants';
import { useTabs } from 'src/features/general/enquete-create/hooks/useTabs';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector } from 'src/redux/store';
import { RespondentManagerTab } from '../../shared-tabs/respondent-manager-tab/RespondentManagerTab';
import { RespondentTab } from '../../shared-tabs/respondent-tab/components/RespondentTab';
import { TemporaryOrganizationTab } from '../../shared-tabs/temporary-organization/components/TemporaryOrganizationTab';
import EnqueteCreateTab from './tabs/create-survey/components/EnqueteCreateTab';
import EnqueteAnswerOption from './tabs/option/components/EnqueteAnswerOption';

interface SeriesSurveyTabContainerProps {
  id: string | undefined;
  selectedTabValue: TabValue;
}

export const SeriesSurveyTabContainer: React.FC<SeriesSurveyTabContainerProps> = (props) => {
  const { id, selectedTabValue } = props;
  const { t } = useTranslation();
  const { tabValue, handleChangeTab } = useTabs(selectedTabValue);
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const { isRespondentManagerOnly } = usePermission(enqueteData.surveyDetailId);

  // アンケートクローズ状態の確認
  const isClosed = isAfter(new Date(), new Date(enqueteData.closedAt));

  return (
    <TabContext value={tabValue}>
      <TabList onChange={handleChangeTab}>
        <Tab label={t('enqueteCreate.tab.question')} value={TAB_VALUE.QUESTION} disabled={isRespondentManagerOnly} />
        <Tab label={t('enqueteCreate.tab.respondentList')} value={TAB_VALUE.RESPONDENT} disabled={id === undefined} />
        <Tab label={t('enqueteCreate.tab.ownerList')} value={TAB_VALUE.OWNER} disabled={id === undefined || isRespondentManagerOnly} />
        <Tab
          label={t('enqueteCreate.tab.respondentManagerList')}
          value={TAB_VALUE.RESPONDENT_MANAGER}
          disabled={id === undefined || isRespondentManagerOnly}
        />
        <Tab
          label={t('enqueteCreate.tab.divisionRegionEditor')}
          value={TAB_VALUE.DIVISION_REGION_EDITOR}
          disabled={id === undefined || isRespondentManagerOnly}
        />
        <Tab
          label={t('enqueteCreate.tab.temporaryOrganizationManagement')}
          value={TAB_VALUE.TEMPORARY_ORGANIZATION}
          disabled={id === undefined || isRespondentManagerOnly}
        />
        <Tab label={t('enqueteCreate.tab.option')} value={TAB_VALUE.OPTION} />
      </TabList>
      <TabPanel value={TAB_VALUE.QUESTION}>
        <Stack spacing={2}>
          <EnqueteCreateTab />
        </Stack>
      </TabPanel>
      <TabPanel value={TAB_VALUE.RESPONDENT}>
        <Stack spacing={1}>
          <RespondentTab surveyDetailId={enqueteData.surveyDetailId} answerUnit={enqueteData.surveyDetail?.surveyHeader?.answerUnit} />
        </Stack>
      </TabPanel>
      <TabPanel value={TAB_VALUE.OWNER}>
        <Stack spacing={1}>
          <SurveyOwnerTab respondentRequestId={enqueteData.id} surveyDetailId={enqueteData.surveyDetailId} />
        </Stack>
      </TabPanel>
      <TabPanel value={TAB_VALUE.RESPONDENT_MANAGER}>
        <Stack spacing={1}>
          <RespondentManagerTab surveyDetailId={enqueteData.surveyDetailId} />
        </Stack>
      </TabPanel>
      <TabPanel value={TAB_VALUE.DIVISION_REGION_EDITOR}>
        <Stack spacing={1}>
          <DivisionRegionEditorTab surveyDetailId={enqueteData.surveyDetailId} />
        </Stack>
      </TabPanel>
      <TabPanel value={TAB_VALUE.TEMPORARY_ORGANIZATION}>
        <Stack spacing={1}>
          <TemporaryOrganizationTab surveyDetailId={enqueteData.surveyDetailId} />
        </Stack>
      </TabPanel>
      <TabPanel value={TAB_VALUE.OPTION}>
        <Stack spacing={2}>
          <EnqueteAnswerOption
            surveyDetailId={enqueteData.surveyDetailId}
            isClosed={isClosed}
            seriesEnqueteOptionData={enqueteData.surveyDetail.surveyFormOptionData as SeriesEnqueteOptionData}
          />
        </Stack>
      </TabPanel>
    </TabContext>
  );
};
