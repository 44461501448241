import { Box } from '@material-ui/system';
import { Grid, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { setHasError } from 'src/features/general/enquete-answer/store/enqueteOverviewAnswerSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import EnqueteAnswerFormHeader from './EnqueteAnswerFormHeader';
import EnqueteAnswerOtherForm from './EnqueteAnswerOtherForm';
import EnqueteAnswerPurchaseForm from './EnqueteAnswerPurchaseForm';
import EnqueteAnswerSalesForm from './EnqueteAnswerSalesForm';

export const EnqueteAnswerForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const methods = useForm({ mode: 'onChange' });
  const { t } = useTranslation();
  const purchaseFixedAmount = useAppSelector((state) => state.enqueteOverviewAnswer.purchaseFixedAmount);
  const salesFixedAmount = useAppSelector((state) => state.enqueteOverviewAnswer.salesFixedAmount);

  // アンケート回答入力時のバリデーション状態を保存しておく
  useEffect(() => {
    dispatch(setHasError(!methods.formState.isValid));
  }, [methods.formState.isValid, dispatch]);

  return (
    <Stack component="form" sx={{ width: '100%' }}>
      <Grid item xs={12} md={12}>
        <EnqueteAnswerFormHeader />
      </Grid>
      <Grid item xs={12} md={12}>
        {(purchaseFixedAmount.isAnswerTarget || salesFixedAmount.isAnswerTarget) && (
          <Label color="info" sx={{ whiteSpace: 'pre-wrap', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}>
            <Box style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{t('enqueteAnswerInput.overviewSurvey.message')}</Box>
          </Label>
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        <EnqueteAnswerPurchaseForm methods={methods} />
      </Grid>
      <Grid item xs={12} md={12}>
        <EnqueteAnswerSalesForm methods={methods} />
      </Grid>
      <Grid item xs={12} md={12}>
        <EnqueteAnswerOtherForm methods={methods} />
      </Grid>
    </Stack>
  );
};
