import { useCallback } from 'react';
import { GeneralEnquete, MainTableType } from 'src/@types/generalEnquete';
import { MainTableSheetNamesType, TotalMainTablesType } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { RootState, useAppSelector } from 'src/redux/store';

export const useSurveyTableCreateDataService = () => {
  // ストア
  const mainTableSheetNames = useAppSelector((state: RootState) => state.tableSetting.mainTableSheetNames);

  // 渡されたデータからtotalMainTablesを作成
  const makeTotalMainTables = useCallback((tabData: GeneralEnquete) => {
    if (!tabData.tables) return [];

    // メイン表を格納
    const data: TotalMainTablesType[] = [
      {
        sourceData: tabData.tables.sourceData,
        answerData: [],
        cell: tabData.tables.cell,
        mergeCells: tabData.tables.mergeCells,
        colWidths: tabData.tables.colWidths,
        rowHeights: tabData.tables.rowHeights,
        rowHeaders: tabData.tables.rowHeaders ?? [],
        nestedHeaders: tabData.tables.nestedHeaders ?? [],
        fixedColumnsStart: tabData.tables.fixedColumnsStart,
        fixedRowsTop: tabData.tables.fixedRowsTop,
      },
    ];

    // 追加分回答表データがあれば、追加分の回答表を結合
    const additionalTables = tabData.tables.additionalTables ?? [];
    if (additionalTables.length > 0) data.push(...additionalTables);

    return data;
  }, []);

  // 渡されたデータからmainTableSheetNamesを作成
  const makeMainTableSheetNames = useCallback((tabData: GeneralEnquete) => {
    if (!tabData.tables) return [];

    // メイン表を格納
    const data: MainTableSheetNamesType[] = [
      {
        sheetNameJpn: tabData.tables.sheetNameJpn ?? TABLE_SETTINGS.DEFAULT_SHEET_NAME_JPN + 1,
        sheetNameEng: tabData.tables.sheetNameEng ?? TABLE_SETTINGS.DEFAULT_SHEET_NAME_ENG + 1,
      },
    ];

    // 追加分回答表データがあれば、追加分の回答表シート名を結合
    const originalAdditionalTables = tabData.tables.additionalTables ?? [];
    if (originalAdditionalTables.length > 0) {
      const additionalTables = originalAdditionalTables.map((table: MainTableType, index: number) => ({
        sheetNameJpn: table.sheetNameJpn ?? TABLE_SETTINGS.DEFAULT_SHEET_NAME_JPN + (index + 2),
        sheetNameEng: table.sheetNameEng ?? TABLE_SETTINGS.DEFAULT_SHEET_NAME_ENG + (index + 2),
      }));

      data.push(...additionalTables);
    }

    return data;
  }, []);

  // APIリクエスト前に、ストアデータをアンケート構造に習い整形
  const formatSurveyData = useCallback(
    (totalMainTables: TotalMainTablesType[]) => {
      // 1つ目の回答表を定義
      const mainTableData = {
        sourceData: totalMainTables[0].sourceData,
        answerData: totalMainTables[0].answerData,
        cell: totalMainTables[0].cell,
        mergeCells: totalMainTables[0].mergeCells,
        colWidths: totalMainTables[0].colWidths,
        rowHeights: totalMainTables[0].rowHeights,
        rowHeaders: totalMainTables[0].rowHeaders,
        nestedHeaders: totalMainTables[0].nestedHeaders,
        sheetNameJpn: mainTableSheetNames[0].sheetNameJpn,
        sheetNameEng: mainTableSheetNames[0].sheetNameEng,
        fixedColumnsStart: totalMainTables[0].fixedColumnsStart,
        fixedRowsTop: totalMainTables[0].fixedRowsTop,
      };

      // 2つ目以降の回答表
      const currentAdditionalTables: MainTableType[] = totalMainTables.filter((_, index) => index !== 0);
      const additionalTables = currentAdditionalTables.map((table, index) => {
        return {
          ...table,
          ...{ sheetNameJpn: mainTableSheetNames[index + 1].sheetNameJpn, sheetNameEng: mainTableSheetNames[index + 1].sheetNameEng },
        };
      });

      return { ...mainTableData, additionalTables };
    },
    [mainTableSheetNames],
  );

  return { makeTotalMainTables, makeMainTableSheetNames, formatSurveyData };
};
