import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { useEnqueteAnswersListGrid } from 'src/features/general/enquete-answer/hooks/useEnqueteAnswersListGrid';
import { useAppSelector } from 'src/redux/store';

export const ToggleButtonValue = {
  all: 'all',
  forMyselfOnly: 'forMyselfOnly',
};

export default function EnqueteAnswerListToggleButton() {
  const { t } = useTranslation();
  const hook = useEnqueteAnswersListGrid();
  const { isAll } = useAppSelector((state) => state.enqueteAnswer);
  const toggleButtonStyle = { marginLeft: { xs: '1px', sm: '1em' }, marginRight: { xs: '1px', sm: '1em' }, overflow: 'hidden' };

  return (
    <ToggleButtonGroup
      value={isAll ? ToggleButtonValue.all : ToggleButtonValue.forMyselfOnly}
      exclusive
      aria-label="text alignment"
      onChange={hook.changeToggle}
      sx={{ marginTop: 1, marginBottom: 1, height: 30, whiteSpace: 'nowrap' }}>
      <ToggleButton color="primary" value={ToggleButtonValue.all} aria-label="left aligned">
        <Box sx={toggleButtonStyle} style={{ overflow: 'hidden', fontSize: '11px' }}>
          {t('enqueteAnswerList.toggleButtonGroup.all')}
        </Box>
      </ToggleButton>
      <ToggleButton value={ToggleButtonValue.forMyselfOnly} aria-label="left aligned">
        <Box sx={toggleButtonStyle} style={{ overflow: 'hidden', fontSize: '11px' }}>
          {t('enqueteAnswerList.toggleButtonGroup.forMyselfOnly')}
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
