import { EnqueteAnswererGroupListPage } from '../pages/EnqueteAnswererGroupListPage';

//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteAnswererGroupRoute = {
  path: 'enquete-answerer-group',
  children: [
    {
      path: '',
      element: <EnqueteAnswererGroupListPage />,
    },
  ],
};
