import { Stack } from '@material-ui/core';
import React from 'react';
import { RequestTargetDataGrid } from './RequestTargetDataGrid';

const EnqueteStatusListTab: React.FC = React.memo(() => {
  return (
    <Stack direction="column" spacing={2}>
      <RequestTargetDataGrid />
    </Stack>
  );
});

export default EnqueteStatusListTab;
