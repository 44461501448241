import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { EnqueteConfirmListLayout } from '../components/pages/list/EnqueteConfirmListLayout';

export const EnqueteConfirmListPage = () => {
  const { t } = useTranslation();

  const title = t('enqueteConfirm.pageTitle');
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };
  return <EnqueteConfirmListLayout title={title} breadcrumbs={breadcrumbs} />;
};
