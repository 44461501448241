import React from 'react';
import Label from 'src/components/Label';
import { Box, Button, Container, Grid, Pagination, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { useNotificationEditList } from '../hooks/useNotificationEditList';

type NotificationEditListLayoutProps = {
  pageTitle: string;
  breadCrumbs: HeaderBreadcrumbsProps;
};

export const NotificationEditListLayout = ({ pageTitle, breadCrumbs }: NotificationEditListLayoutProps) => {
  const { t } = useTranslation();
  const { isLoading, hasError, notifications, page, setPage, count, deleteNotification } = useNotificationEditList();
  const { isAdmin, isViewerAdmin } = usePermission();
  const navigate = useNavigate();
  const { currentLang } = useLocales();

  // todo: Conditional Rendering の記述の仕方は工夫の余地あり
  const conditionalRender = () => {
    if (!isAdmin && !isViewerAdmin) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.noPermissions')}</Typography>
        </Grid>
      );
    } else if (isLoading) {
      return <StyledLoading isOpen={isLoading} />;
    } else if (hasError || !notifications) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.failedToRetrieve')}</Typography>
        </Grid>
      );
    } else if (notifications && notifications?.length === 0) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.noUpdates')}</Typography>
        </Grid>
      );
    } else {
      return (
        <>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
            {notifications?.length === 0 && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body1">{t('editListPage.message.noUpdates')}</Typography>
              </Grid>
            )}
            {notifications?.map((item) => (
              <Grid item container xs={12} key={item.id}>
                <StyledBox>
                  <Box sx={{ position: 'relative', wordWrap: 'break-word', height: '100%', whiteSpace: 'normal' }}>
                    <Stack direction="row" justifyContent="flex-start" spacing={1}>
                      {item.isDeleted && (
                        <Label color="error" sx={{ p: 1 }}>
                          {t('common.deleted')}
                        </Label>
                      )}
                      <Typography>{currentLang.value === 'ja' ? item.titleJpn : item.titleEng}</Typography>
                    </Stack>

                    <Typography variant="caption" sx={{ color: 'gray' }}>
                      {format(new Date(item.publishedAt), 'yyyy/MM/dd HH:mm:ss')}
                    </Typography>

                    <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {/*通常用*/}
                      {!item.isDeleted && (
                        <>
                          <Button variant="contained" sx={{ mr: 1 }} onClick={() => navigate(`/manage/notification/${item.id}/edit`)}>
                            {t('button.edit')}
                          </Button>
                          <Button color="error" variant="contained" onClick={() => deleteNotification(item.id.toString())}>
                            {t('button.delete')}
                          </Button>
                        </>
                      )}

                      {/*削除お知らせ用*/}
                      {item.isDeleted && (
                        <Button variant="contained" onClick={() => navigate(`/manage/notification/${item.id}/reRegister`)}>
                          {t('button.reRegister')}
                        </Button>
                      )}
                    </Grid>
                  </Box>
                </StyledBox>
              </Grid>
            ))}
          </Grid>
          <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
            <Pagination count={count} page={page} onChange={(_, value) => setPage(value)} />
          </Grid>
        </>
      );
    }
  };

  return (
    <PageLayout title={pageTitle} breadcrumbs={breadCrumbs}>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        {(isAdmin || isViewerAdmin) && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button variant="contained" onClick={() => navigate('/manage/notification/create')}>
              {t('button.create')}
            </Button>
          </Box>
        )}
        {conditionalRender()}
      </Container>
    </PageLayout>
  );
};
