import React, { RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Tab, Tabs } from '@mui/material';
import { HotTable } from '@handsontable/react';
import { HyperFormula } from 'hyperformula';
import { MainTableSheetNamesType } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { RootState, useAppSelector } from 'src/redux/store';
import EnqueteMainTableForm from 'src/components/app-components/bonsai/type-general-table/components/EnqueteMainTableForm';
import { useNewTableData } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useNewTableData';
import { useTableSave } from '../hooks/useTableSave';
import { useCreateMainTable } from '../hooks/useCreateMainTable';
import MainSheetNamesForm from './tabs/main-tables/components/MainSheetNamesForm';
import Label from 'src/components/Label';
import { useTranslation } from 'react-i18next';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { DeleteConfirmMainTable } from 'src/features/general/enquete-create/components/modals/delete-main-table/components/DeleteConfirmMainTable';
import useLocales from 'src/hooks/useLocales';

interface PropsEntity {
  hotRef: RefObject<HotTable>[];
  hotRefForSubSheet: RefObject<HotTable>[];
  hyperformulaInstance: HyperFormula;
  isPreview: boolean;
}

export const MainTablesTabContainer: React.FC<PropsEntity> = (props) => {
  const { hotRef, hotRefForSubSheet, hyperformulaInstance, isPreview } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();

  // ストア
  const { totalMainTables, mainTableSheetNames } = useAppSelector((state: RootState) => state.tableSetting);

  // Hooks
  const {
    tabNumber,
    isMaxTotalTableCount,
    isOpenDeleteConfirm,
    handleTabClick,
    handleAddMainClick,
    handleDeleteMainClick,
    deleteTargetMainTable,
    disagreeCallback,
  } = useCreateMainTable();

  // メイン表データ読み込み
  useNewTableData(hotRef);
  // 表データ保存関数読み込み
  const { saveAllSettings } = useTableSave(hotRef, hotRefForSubSheet);

  return (
    <>
      <Tabs
        value={mainTableSheetNames.length > 0 ? tabNumber : false} // mainTableSheetNamesが初回レンダリング時空配列によるエラー対応
        onChange={async (e, tabNumber) => {
          // グローバルステートを保存
          await saveAllSettings('nodb');
          handleTabClick(e, tabNumber);
        }}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="main-table-tab">
        {mainTableSheetNames.map((sheetNames: MainTableSheetNamesType, index: number) => (
          <Tab
            label={
              <Box>
                {isJapanese ? sheetNames.sheetNameJpn : sheetNames.sheetNameEng}
                {mainTableSheetNames.length > 1 && !isPreview && (
                  <Box
                    component={'span'}
                    onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => handleDeleteMainClick(e, index)}
                    sx={{ ml: 1 }}
                    data-testid="delete-button">
                    <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                  </Box>
                )}
              </Box>
            }
            value={index + 1}
            key={index + 1}
            sx={{ textTransform: 'none' }}
            data-testid="sheet-name-tab"
          />
        ))}

        {!isMaxTotalTableCount && !isPreview && (
          <Tab
            value={mainTableSheetNames.length + 1}
            label={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '1rem' }} />}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => handleAddMainClick(event, saveAllSettings)}
            data-testid="add-tab"
          />
        )}
      </Tabs>

      {totalMainTables.map((_, index) => (
        <Box key={index} sx={{ display: tabNumber === index + 1 ? 'block' : 'none', p: 2 }}>
          <Label sx={{ px: 1, py: 2, mb: 2 }}>{t('enqueteGeneralCommon.answerTableSheetReferenceWord', { tableNumber: index + 1 })}</Label>
          {!isPreview && <MainSheetNamesForm index={index} />}
          <EnqueteMainTableForm
            hotRef={hotRef[index]}
            hyperformulaInstance={hyperformulaInstance}
            sheetName={TABLE_SETTINGS.MAIN_TABLE_REFERENCE + String(index + 1)}
            isPreview={isPreview}
            isVisibleContextMenu={true}
          />
        </Box>
      ))}

      {/* 削除ダイアログ */}
      <DeleteConfirmMainTable
        isOpen={isOpenDeleteConfirm}
        disagreeCallback={disagreeCallback}
        agreeCallback={() => deleteTargetMainTable(hotRef, saveAllSettings)}
      />
    </>
  );
};
