import { HotTable } from '@handsontable/react';
import { CellCoords } from 'handsontable';
import { Action } from 'handsontable/plugins/undoRedo';
import { CellProperties } from 'handsontable/settings';
import { RefObject, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NestedHeader } from 'src/@types/generalEnquete';
import { checkIsAllSelected, deleteHeaderCol, removeCellMeta, setStartEnd, updateMergeCells, updateNestedHeaders } from '../utils/handsOnTableUtils';
import { useNestedHeadersHistory } from './useNestedHeadersHistory';

export const useTableHandler = (hotRef: RefObject<HotTable>) => {
  const { t } = useTranslation();
  const { updateNestedHeadersHistory, initNestedHeadersHistory, setIsUndoRedoAction, applyNestedHeaders } = useNestedHeadersHistory();
  const holder = useRef<
    {
      row: number;
      col: number;
      rowspan?: number;
      colspan?: number;
      type: string | undefined;
      cellSource: string[] | number[] | ((this: CellProperties, query: string, callback: (items: string[]) => void) => void) | undefined;
      className: string | string[] | undefined;
    }[]
  >([]);

  // Handlers for HandsOnTable
  const afterRemoveColHandler = (index: number, amount: number) => {
    const settings = hotRef.current?.hotInstance?.getSettings();
    const newNestedHeaders: (string | NestedHeader)[][] = [];
    if (settings?.nestedHeaders) {
      initNestedHeadersHistory(settings?.nestedHeaders);

      settings?.nestedHeaders.forEach((nestedHeader: (string | NestedHeader)[]) => {
        const arry = deleteHeaderCol(nestedHeader, index, amount);
        const result = arry.map((el) => {
          const colspan = el.index.length;
          const labelValue = el.label;
          if (colspan === 1) {
            return labelValue;
          } else {
            return { label: labelValue, colspan: colspan };
          }
        });
        newNestedHeaders.push(result);
      });
      hotRef.current?.hotInstance?.updateSettings({ nestedHeaders: newNestedHeaders });
      hotRef.current?.hotInstance?.render();
      updateNestedHeadersHistory(newNestedHeaders);
    }
  };

  const afterRemoveRowHandler = () => {
    if (hotRef.current?.hotInstance?.getSourceData().length === 0) {
      hotRef.current?.hotInstance?.updateSettings({ nestedHeaders: undefined });
    }
  };

  const afterCreateColHandler = (index: number, amount: number) => {
    const colWidths: number[] = [];
    const colCounts = hotRef?.current?.hotInstance?.countCols();
    if (!colCounts) return;
    for (let i = 0; i < colCounts; i++) {
      const colWidth = hotRef?.current?.hotInstance?.getColWidth(i);
      colWidth ? colWidths.push(Math.max(colWidth, 20)) : colWidths.push(20);
    }
    hotRef?.current?.hotInstance?.updateSettings({ colWidths: colWidths });
  };

  const beforeUndoHandler = () => {
    setIsUndoRedoAction(true);
  };

  const beforeRedoHandler = () => {
    setIsUndoRedoAction(true);
  };

  const afterUndoHandler = (action: Action) => {
    if (action.actionType === 'remove_col' || 'insert_col') {
      if (hotRef?.current?.hotInstance?.updateSettings) applyNestedHeaders('undo', hotRef?.current?.hotInstance?.updateSettings);
    }
    setIsUndoRedoAction(false);
  };

  const afterRedoHandler = (action: Action) => {
    if (action.actionType === 'remove_col' || 'insert_col') {
      if (hotRef?.current?.hotInstance?.updateSettings) applyNestedHeaders('redo', hotRef?.current?.hotInstance?.updateSettings);
    }
    setIsUndoRedoAction(false);
  };

  // memo: コピー前コピー対象のSourceDataを保持しておく
  const beforeCopyHandler = useCallback(
    (data: string[][], coords: { startRow: number; startCol: number; endRow: number; endCol: number }[]) => {
      for (let i = coords[0].startRow; i <= coords[0].endRow; i++) {
        for (let j = coords[0].startCol; j <= coords[0].endCol; j++) {
          data[i - coords[0].startRow][j - coords[0].startCol] = hotRef.current?.hotInstance?.getSourceDataAtCell(i, j);
        }
      }
    },
    [hotRef],
  );

  const afterCopyHandler = useCallback(
    (_data: string[][], coords: { startRow: number; startCol: number; endRow: number; endCol: number }[]) => {
      // hold the className of the all cells in selection range
      const sourceDataMetaToHold = [];

      for (let row = coords[0].startRow; row <= coords[0].endRow; row++) {
        for (let col = coords[0].startCol; col <= coords[0].endCol; col++) {
          const className = hotRef?.current?.hotInstance?.getCellMeta(row, col).className || '';
          const rowspan = hotRef?.current?.hotInstance?.getCellMeta(row, col).rowspan || 1;
          const colspan = hotRef?.current?.hotInstance?.getCellMeta(row, col).colspan || 1;
          const type = hotRef?.current?.hotInstance?.getCellMeta(row, col).type || undefined;
          const cellSource = hotRef?.current?.hotInstance?.getCellMeta(row, col).source || undefined;
          if (rowspan > 1 || colspan > 1) {
            sourceDataMetaToHold.push({
              row: row - coords[0].startRow,
              col: col - coords[0].startCol,
              rowspan,
              colspan,
              type,
              cellSource,
              className,
            });
          } else {
            sourceDataMetaToHold.push({ row: row - coords[0].startRow, col: col - coords[0].startCol, type, cellSource, className });
          }
        }
      }

      holder.current = sourceDataMetaToHold;
    },
    [hotRef],
  );

  const afterAutofillHandler = useCallback(
    (
      _fillData: string[][],
      sourceRange: { from: CellCoords; to: CellCoords; highlight: CellCoords },
      targetRange: { from: CellCoords; to: CellCoords; highlight: CellCoords },
      direction: 'down' | 'up' | 'right' | 'left',
    ) => {
      // judge if the autofill is vertical or horizontal
      const classNameToHold = [];
      switch (direction) {
        case 'down':
        case 'up':
          // push the className from the first col to the last col
          for (let col = sourceRange.from.col; col <= sourceRange.to.col; col++) {
            classNameToHold.push({
              row: sourceRange.from.row,
              col,
              className: hotRef?.current?.hotInstance?.getCellMeta(sourceRange.from.row, col).className,
              type: hotRef?.current?.hotInstance?.getCellMeta(sourceRange.from.row, col).type,
              source: hotRef?.current?.hotInstance?.getCellMeta(sourceRange.from.row, col).source,
            });
          }
          break;
        case 'right':
        case 'left':
          // push the className from the first row to the last row
          for (let row = sourceRange.from.row; row <= sourceRange.to.row; row++) {
            classNameToHold.push({
              row,
              col: sourceRange.from.col,
              className: hotRef?.current?.hotInstance?.getCellMeta(row, sourceRange.from.col).className,
              type: hotRef?.current?.hotInstance?.getCellMeta(row, sourceRange.from.col).type,
              source: hotRef?.current?.hotInstance?.getCellMeta(row, sourceRange.from.col).source,
            });
          }
          break;
        default:
          break;
      }

      switch (direction) {
        case 'down':
        case 'up':
          // set the className from the same col of holder to the targetRange
          for (let col = targetRange.from.col; col <= targetRange.to.col; col++) {
            for (let row = targetRange.from.row; row <= targetRange.to.row; row++) {
              classNameToHold.forEach((cell) => {
                if (cell.col === col) {
                  removeCellMeta(hotRef, row, col);
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'type', cell.type ?? 'text');
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'className', cell.className);
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'source', cell.source);
                }
              });
            }
          }
          break;
        case 'right':
        case 'left':
          // set the className from the same row of holder to the targetRange
          for (let row = targetRange.from.row; row <= targetRange.to.row; row++) {
            for (let col = targetRange.from.col; col <= targetRange.to.col; col++) {
              classNameToHold.forEach((cell) => {
                if (cell.row === row) {
                  removeCellMeta(hotRef, row, col);
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'type', cell.type ?? 'text');
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'className', cell.className);
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'source', cell.source);
                }
              });
            }
          }
          break;
        default:
          break;
      }
      hotRef?.current?.hotInstance?.render();
    },
    [hotRef],
  );

  const afterUnmergeCellsHandler = useCallback(
    (cellRange: { from: CellCoords; to: CellCoords; highlight: CellCoords }) => {
      const { from, to } = cellRange;
      for (let row = from.row; row <= to.row; row++) {
        for (let col = from.col; col <= to.col; col++) {
          hotRef?.current?.hotInstance?.setCellMeta(row, col, 'rowspan', 1);
          hotRef?.current?.hotInstance?.setCellMeta(row, col, 'colspan', 1);
        }
      }
      hotRef?.current?.hotInstance?.render();
    },
    [hotRef],
  );

  const afterPasteHandler = useCallback(
    (data: string[][], coords: { startRow: number; startCol: number; endRow: number; endCol: number }[]) => {
      const dataRowLength = data.length;
      const dataColLength = data[0].length;

      for (let row = coords[0].startRow; row <= coords[0].endRow; row++) {
        for (let col = coords[0].startCol; col <= coords[0].endCol; col++) {
          holder.current.forEach((cell) => {
            if (cell.row === (row - coords[0].startRow) % dataRowLength && cell.col === (col - coords[0].startCol) % dataColLength) {
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'className', cell.className);
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'type', cell.type);
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'source', cell.cellSource);
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'rowspan', cell.rowspan);
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'colspan', cell.colspan);
            }
          });
        }
      }
      updateMergeCells(hotRef);
      hotRef?.current?.hotInstance?.render();
    },
    [hotRef, holder],
  );

  // Callbacks for context menu
  const insertRowCol = (key: string) => {
    // memo : getSelected returns array [[startRow, startCol, endRow, endCol], ...]
    const selected = hotRef?.current?.hotInstance?.getSelected();
    if (!selected) {
      return;
    }
    // 複数選択された場合に追加する行列の差分
    let rowDiff = 0;
    let colDiff = 0;
    // 選択セルのエリアが複数ある場合の選択エリアの数分処理をおこなう。
    selected.forEach((selectCells) => {
      let [selectedStartRow, selectedStartCol, selectedEndRow, selectedEndCol] = selectCells;
      // memo: ヘッダーを含む場合(インデックス-1)の時は0に置き換える
      if (selectedStartCol < 0) selectedStartCol = 0;
      if (selectedStartRow < 0) selectedStartRow = 0;
      if (selectedEndCol < 0) selectedEndCol = 0;
      if (selectedEndRow < 0) selectedEndRow = 0;

      // 選択セルのstart endの大小を比較して入れ替える。
      const [startRow, endRow] = setStartEnd(selectedStartRow, selectedEndRow);
      const [startCol, endCol] = setStartEnd(selectedStartCol, selectedEndCol);
      let newNestedHeaders: (string | NestedHeader)[][] | undefined;
      let nestedHeader: (string | NestedHeader)[][] | undefined;
      // ウインドウ枠を固定する場合、固定エリアを調整
      const settings = hotRef.current?.hotInstance?.getSettings();
      const oldFixedRowsTop = settings?.fixedRowsTop || 0;
      const oldFixedColumnsStart = settings?.fixedColumnsStart || 0;
      switch (key) {
        case 'row_insert_below':
          rowDiff = rowDiff + endRow - startRow + 1;
          hotRef?.current?.hotInstance?.alter('insert_row', selectedStartRow + rowDiff, endRow - startRow + 1);
          // ウィンドウ固定エリアを調整
          if (oldFixedRowsTop > selectedEndRow) {
            hotRef.current?.hotInstance?.updateSettings({
              fixedRowsTop: oldFixedRowsTop + endRow - startRow + 1,
            });
          }
          break;
        case 'row_insert_above':
          hotRef?.current?.hotInstance?.alter('insert_row', selectedStartRow, endRow - startRow + 1);
          // ウィンドウ固定エリアを調整
          if (oldFixedRowsTop > selectedStartRow) {
            hotRef.current?.hotInstance?.updateSettings({
              fixedRowsTop: oldFixedRowsTop + endRow - startRow + 1,
            });
          }
          break;
        case 'col_insert_left':
          hotRef?.current?.hotInstance?.alter('insert_col', selectedStartCol, endCol - startCol + 1);

          // ウィンドウ固定エリアを調整
          if (oldFixedColumnsStart > selectedStartCol) {
            hotRef.current?.hotInstance?.updateSettings({
              fixedColumnsStart: oldFixedColumnsStart + endCol - startCol + 1,
            });
          }
          // memo: nestedHeadersへの挿入
          nestedHeader = hotRef.current?.hotInstance?.getSettings().nestedHeaders;
          if (!nestedHeader) return;
          initNestedHeadersHistory(nestedHeader);
          newNestedHeaders = updateNestedHeaders(hotRef, selectedStartCol, endCol - startCol + 1, 'left');
          if (newNestedHeaders) {
            hotRef.current?.hotInstance?.updateSettings({ nestedHeaders: newNestedHeaders });
            updateNestedHeadersHistory(newNestedHeaders);
          }
          break;
        case 'col_insert_right':
          hotRef?.current?.hotInstance?.alter('insert_col', selectedStartCol + colDiff + endCol - startCol + 1, endCol - startCol + 1);

          // ウィンドウ固定エリアを調整
          if (oldFixedColumnsStart > selectedEndCol) {
            hotRef.current?.hotInstance?.updateSettings({
              fixedColumnsStart: oldFixedColumnsStart + endCol - startCol + 1,
            });
          }
          // memo: nestedHeadersへの挿入
          nestedHeader = hotRef.current?.hotInstance?.getSettings().nestedHeaders;
          if (!nestedHeader) return;
          initNestedHeadersHistory(nestedHeader);
          newNestedHeaders = updateNestedHeaders(hotRef, selectedStartCol + colDiff + endCol - startCol + 1, endCol - startCol + 1, 'right');
          if (newNestedHeaders) {
            hotRef.current?.hotInstance?.updateSettings({ nestedHeaders: newNestedHeaders });
            updateNestedHeadersHistory(newNestedHeaders);
          }
          break;
        default:
          break;
      }
    });
  };

  const setStyle = (key: string) => {
    // memo: getSelected returns array like [[startRow, startCol, endRow, endCol], ...]
    const selected = hotRef?.current?.hotInstance?.getSelected();
    if (!selected) {
      return;
    }
    const newClassName = key.split(':')[1];
    const [prefix, suffix] = newClassName.split('-');

    // memo: 選択セルのエリアが複数ある場合の選択エリアの数分処理をおこなう。
    selected.forEach((selectCells) => {
      let [selectedStartRow, selectedStartCol, selectedEndRow, selectedEndCol] = selectCells;
      let row = 0;
      let col = 0;

      // memo: ヘッダーを含む場合(インデックス-1)の時は0に置き換える
      if (selectedStartCol < 0) selectedStartCol = 0;
      if (selectedStartRow < 0) selectedStartRow = 0;

      // memo: 選択セルのstart endの大小を比較して入れ替える。
      const [startRow, endRow] = setStartEnd(selectedStartRow, selectedEndRow);
      const [startCol, endCol] = setStartEnd(selectedStartCol, selectedEndCol);

      // memo: for each cell in selection range set cell meta
      for (row = startRow; row <= endRow; row++) {
        for (col = startCol; col <= endCol; col++) {
          // memo: get cell meta and if it has a className property, add key to it if not has a same key
          const className = hotRef?.current?.hotInstance?.getCellMeta(row, col).className as string;
          if (className && typeof className === 'string') {
            // memo: if the key has a same prefix as a className, remove it from a className
            const classNameArray = className.split(' ').filter((element) => element.split('-')[0] !== prefix);
            // memo: suffixがn（Normal：デフォルトスタイル設定）の場合はスタイルを設定しない
            if (suffix !== 'n') {
              hotRef?.current?.hotInstance?.setCellMeta(
                row,
                col,
                'className',
                classNameArray.length === 0 ? newClassName : classNameArray?.join(' ') + ' ' + newClassName,
              );
            } else {
              if (classNameArray.length === 0) {
                // memo: classNameが0件の場合はclassNameを削除
                hotRef?.current?.hotInstance?.removeCellMeta(row, col, 'className');
              } else {
                hotRef?.current?.hotInstance?.setCellMeta(row, col, 'className', classNameArray?.join(' '));
              }
            }
          } else {
            if (suffix !== 'n') {
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'className', newClassName);
            }
          }
        }
      }
    });
    hotRef?.current?.hotInstance?.render();
  };

  const setType = (key: string) => {
    const newType = key.split(':')[1];
    const selected = hotRef?.current?.hotInstance?.getSelected();
    if (!selected) {
      return;
    }

    let [startRow, startCol] = selected[0];
    // memo: ヘッダーを含む場合(インデックス-1)の時は0に置き換える
    if (startCol < 0) startCol = 0;
    if (startRow < 0) startRow = 0;
    const startCellType = hotRef?.current?.hotInstance?.getCellMeta(startRow, startCol).type as string;

    // memo: 選択セルのエリアが複数ある場合の選択エリアの数分処理をおこなう。
    selected.forEach((selectCells) => {
      let [selectedStartRow, selectedStartCol, selectedEndRow, selectedEndCol] = selectCells;
      let row = 0;
      let col = 0;

      // memo: ヘッダーを含む場合(インデックス-1)の時は0に置き換える
      if (selectedStartCol < 0) selectedStartCol = 0;
      if (selectedStartRow < 0) selectedStartRow = 0;

      // memo: 選択セルのstart endの大小を比較して入れ替える。
      const [startRow, endRow] = setStartEnd(selectedStartRow, selectedEndRow);
      const [startCol, endCol] = setStartEnd(selectedStartCol, selectedEndCol);

      // memo: for each cell in selection range, set cell meta
      for (row = startRow; row <= endRow; row++) {
        for (col = startCol; col <= endCol; col++) {
          switch (newType) {
            case 'text':
              // memo: 以下のremoveCellMetaしないとtype：text（デフォルトがtextなのでtypeを削除）が再設定できないためremoveCellMetaを実行
              removeCellMeta(hotRef, row, col);
              break;
            case 'numeric':
            case 'date':
              // memo: 不要なメタ情報をremoveCellMetaしておく（処理性能改善のため）
              removeCellMeta(hotRef, row, col);
              hotRef?.current?.hotInstance?.setCellMeta(row, col, 'type', newType);
              break;
            case 'dropdown':
              // memo: dropdown用の処理
              if (startCellType !== 'dropdown') {
                if (hotRef?.current?.hotInstance?.getCellMeta(row, col).type !== 'dropdown') {
                  if (hotRef?.current?.hotInstance?.getSourceDataAtCell(row, col) === '') {
                    break;
                  }
                  removeCellMeta(hotRef, row, col);
                  hotRef?.current?.hotInstance?.setCellMeta(row, col, 'type', newType);
                  hotRef?.current?.hotInstance?.setCellMeta(
                    row,
                    col,
                    'source',
                    hotRef?.current?.hotInstance?.getSourceDataAtCell(row, col).split(','),
                  );
                  hotRef?.current?.hotInstance?.setSourceDataAtCell(row, col, '');
                }
              } else {
                // memo: Undropdown
                if (hotRef?.current?.hotInstance?.getCellMeta(row, col).type === 'dropdown') {
                  let cellSource = '';
                  const cellSourceArr = hotRef?.current?.hotInstance?.getCellMeta(row, col).source;
                  if (Array.isArray(cellSourceArr)) {
                    cellSource = cellSourceArr.join(',');
                  }
                  removeCellMeta(hotRef, row, col);
                  hotRef?.current?.hotInstance?.setSourceDataAtCell(row, col, cellSource);
                }
              }
              break;
            default:
              break;
          }
        }
      }
    });
    hotRef?.current?.hotInstance?.render();
  };

  const getDropdownCondition = (): string => {
    const selected = hotRef?.current?.hotInstance?.getSelected();
    const key = 'enqueteCreate.table.constextMenu.Type';
    let type: string = '';
    if (!selected) {
      return t(`${key}.Dropdown`);
    } else {
      let [startRow, startCol] = selected[0];
      // memo: ヘッダーを含む場合(インデックス-1)の時は0に置き換える
      if (startRow < 0) startRow = 0;
      if (startCol < 0) startCol = 0;

      type = hotRef?.current?.hotInstance?.getCellMeta(startRow, startCol).type as string;
    }
    return type === 'dropdown' ? t(`${key}.UnDropdown`) : t(`${key}.Dropdown`);
  };

  // ウインドウ枠固定の設定/解除
  const handleFreezeWindow = () => {
    const settings = hotRef.current?.hotInstance?.getSettings();
    const fixedRowsTop = settings?.fixedRowsTop || 0;
    const fixedColumnsStart = settings?.fixedColumnsStart || 0;
    const isFreezed = fixedRowsTop > 0 || fixedColumnsStart > 0;

    if (isFreezed) {
      // ウインドウ枠を解除
      hotRef.current?.hotInstance?.updateSettings({
        fixedRowsTop: 0,
        fixedColumnsStart: 0,
      });
    } else {
      const selected = hotRef?.current?.hotInstance?.getSelected();
      if (!selected) {
        return;
      }
      let [startRow, startCol] = selected[0];
      // memo: ヘッダーを含む場合(インデックス-1)の時は0に置き換える
      if (startCol < 0) startCol = 0;
      if (startRow < 0) startRow = 0;
      // ウインドウ枠を固定
      hotRef.current?.hotInstance?.updateSettings({
        fixedRowsTop: startRow,
        fixedColumnsStart: startCol,
      });
    }
  };

  const getFreezeWindowCondition = (): string => {
    const settings = hotRef.current?.hotInstance?.getSettings();
    const fixedRowsTop = settings?.fixedRowsTop || 0;
    const fixedColumnsStart = settings?.fixedColumnsStart || 0;
    const isFreezed = fixedRowsTop > 0 || fixedColumnsStart > 0;
    return isFreezed ? t('enqueteCreate.table.constextMenu.freezeWindow.Unfreeze') : t('enqueteCreate.table.constextMenu.freezeWindow.Freeze');
  };

  // ContextMenu定義
  const constextMenuMain = {
    items: {
      insertrow_above: {
        key: 'row_insert_above',
        name: t('enqueteCreate.table.constextMenu.insertRowCol.row_above'),
        callback: insertRowCol,
      },
      insertrow_below: {
        key: 'row_insert_below',
        name: t('enqueteCreate.table.constextMenu.insertRowCol.row_below'),
        callback: insertRowCol,
      },
      insertcol_left: {
        key: 'col_insert_left',
        name: t('enqueteCreate.table.constextMenu.insertRowCol.col_left'),
        callback: insertRowCol,
      },
      insertcol_right: {
        key: 'col_insert_right',
        name: t('enqueteCreate.table.constextMenu.insertRowCol.col_right'),
        callback: insertRowCol,
      },
      remove_row: {},
      remove_col: {},
      alignment: {},
      mergeCells: {},
      color_subitems: {
        disabled: () => checkIsAllSelected(hotRef),
        key: 'color_subitems',
        name: t('enqueteCreate.table.constextMenu.color.subMenu'),
        submenu: {
          items: [
            {
              key: 'color_subitems:c-n',
              name: t('enqueteCreate.table.constextMenu.color.White'),
              callback: setStyle,
            },
            {
              key: 'color_subitems:c-r',
              name: t('enqueteCreate.table.constextMenu.color.Red'),
              callback: setStyle,
            },
            {
              key: 'color_subitems:c-g',
              name: t('enqueteCreate.table.constextMenu.color.Green'),
              callback: setStyle,
            },
            {
              key: 'color_subitems:c-b',
              name: t('enqueteCreate.table.constextMenu.color.Blue'),
              callback: setStyle,
            },
            {
              key: 'color_subitems:c-h',
              name: t('enqueteCreate.table.constextMenu.color.Grey'),
              callback: setStyle,
            },
            {
              key: 'color_subitems:c-a',
              name: t('enqueteCreate.table.constextMenu.color.Answer'),
              callback: setStyle,
            },
          ],
        },
      },
      font_size_subitems: {
        disabled: () => checkIsAllSelected(hotRef),
        key: 'font_size_subitems',
        name: t('enqueteCreate.table.constextMenu.size.subMenu'),
        submenu: {
          items: [
            {
              key: 'font_size_subitems:s-s',
              name: t('enqueteCreate.table.constextMenu.size.Small'),
              callback: setStyle,
            },
            {
              key: 'font_size_subitems:s-n',
              name: t('enqueteCreate.table.constextMenu.size.Medium'),
              callback: setStyle,
            },
            {
              key: 'font_size_subitems:s-l',
              name: t('enqueteCreate.table.constextMenu.size.Large'),
              callback: setStyle,
            },
          ],
        },
      },
      font_stylebold_subitems: {
        disabled: () => checkIsAllSelected(hotRef),
        key: 'font_bold_subitems',
        name: t('enqueteCreate.table.constextMenu.bold.subMenu'),
        submenu: {
          items: [
            {
              key: 'font_bold_subitems:b-n',
              name: t('enqueteCreate.table.constextMenu.bold.Normal'),
              callback: setStyle,
            },
            {
              key: 'font_bold_subitems:b-b',
              name: t('enqueteCreate.table.constextMenu.bold.Bold'),
              callback: setStyle,
            },
          ],
        },
      },
      underline_subitems: {
        disabled: () => checkIsAllSelected(hotRef),
        key: 'underline_subitems',
        name: t('enqueteCreate.table.constextMenu.underline.subMenu'),
        submenu: {
          items: [
            {
              key: 'underline_subitems:u-n',
              name: t('enqueteCreate.table.constextMenu.underline.None'),
              callback: setStyle,
            },
            {
              key: 'underline_subitems:u-u',
              name: t('enqueteCreate.table.constextMenu.underline.Underline'),
              callback: setStyle,
            },
          ],
        },
      },
      font_styleitalic_subitems: {
        disabled: () => checkIsAllSelected(hotRef),
        key: 'font_italic_subitems',
        name: t('enqueteCreate.table.constextMenu.italic.subMenu'),
        submenu: {
          items: [
            {
              key: 'font_italic_subitems:i-n',
              name: t('enqueteCreate.table.constextMenu.italic.Normal'),
              callback: setStyle,
            },
            {
              key: 'font_italic_subitems:i-i',
              name: t('enqueteCreate.table.constextMenu.italic.Italic'),
              callback: setStyle,
            },
          ],
        },
      },
      type_subitems: {
        disabled: () => checkIsAllSelected(hotRef),
        key: 'type_subitems',
        name: t('enqueteCreate.table.constextMenu.Type.subMenu'),
        submenu: {
          items: [
            {
              key: 'type_subitems:text',
              name: t('enqueteCreate.table.constextMenu.Type.Text'),
              callback: setType,
            },
            {
              key: 'type_subitems:numeric',
              name: t('enqueteCreate.table.constextMenu.Type.Numeric'),
              callback: setType,
            },
            {
              key: 'type_subitems:date',
              name: t('enqueteCreate.table.constextMenu.Type.Date'),
              callback: setType,
            },
            {
              key: 'type_subitems:dropdown',
              name: getDropdownCondition,
              callback: setType,
            },
          ],
        },
      },
      freeze_window: {
        key: 'freeze_window',
        name: getFreezeWindowCondition,
        callback: handleFreezeWindow,
      },
    },
  };

  return {
    constextMenuMain,
    beforeCopyHandler,
    afterCopyHandler,
    afterPasteHandler,
    afterAutofillHandler,
    afterUnmergeCellsHandler,
    afterRemoveColHandler,
    afterCreateColHandler,
    afterRemoveRowHandler,
    afterUndoHandler,
    afterRedoHandler,
    beforeUndoHandler,
    beforeRedoHandler,
  };
};
