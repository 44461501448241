import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { EnqueteThreadCreateLayout } from '../components/pages/create/components/EnqueteThreadCreateLayout';

export const EnqueteThreadCreatePage = () => {
  const { t } = useTranslation();
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('enqueteThread.create.pageTitle'),
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('enqueteThread.pageTitle'), href: '/general/enquete-threads/' },
    ],
    activeLast: true,
  };
  return (
    <PageLayout title={t('enqueteThread.create.pageTitle')} breadcrumbs={breadcrumbs}>
      <EnqueteThreadCreateLayout />
    </PageLayout>
  );
};
