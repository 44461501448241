import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

//添付ファイル名表示用コンポーネントインターフェース
export interface attachedFileComponent {
  attachedFiles: File[];
  setAttachedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

//添付ファイル名表示用コンポーネント
const ShowAttachedFileComponent: React.FC<attachedFileComponent> = ({ attachedFiles, setAttachedFiles }) => {
  const { t } = useTranslation();

  //指定のファイルデータを削除
  const deleteFile = (index: number) => {
    setAttachedFiles((currentFilesList: File[]) => {
      return currentFilesList.filter((fileData: File, i: number) => index !== i && fileData);
    });
  };

  return (
    <>
      {attachedFiles.map((fileData: File, index: number) => {
        return (
          <Grid item key={index}>
            <Box component="span" children={fileData.name} />
            <Box
              component="span"
              onClick={() => deleteFile(index)}
              children={
                <Tooltip title={t('button.detachedFile') ?? ''} placement="bottom" arrow>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
            />
          </Grid>
        );
      })}
    </>
  );
};

export default ShowAttachedFileComponent;
