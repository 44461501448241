import { ArrowUpward } from '@mui/icons-material';
import { StyledButton } from '../StyledButton';

export const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledButton color="primary" onClick={scrollToTop}>
      <ArrowUpward />
    </StyledButton>
  );
};
