import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { FORM_TYPE, SURVEY_TYPE } from 'src/constants';

export const setEditEnqueteData = (enqueteData: RespondentRequestEntity) => {
  return {
    respondentRequestId: enqueteData.id,
    surveyDetailId: enqueteData.surveyDetailId,
    disclosureLevel: enqueteData.surveyDetail?.disclosureLevel ?? null,
    titleJpn: enqueteData.titleJpn,
    titleEng: enqueteData.titleEng,
    targetDate: enqueteData.targetDate,
    openedAt: enqueteData.openedAt,
    closedAt: enqueteData.closedAt,
    labelJpn: enqueteData.surveyDetail?.surveyHeader?.labelJpn ?? '',
    labelEng: enqueteData.surveyDetail?.surveyHeader?.labelEng ?? '',
    type: enqueteData.surveyDetail.surveyHeader?.type ?? SURVEY_TYPE.GENERAL,
    formType: enqueteData.surveyDetail.surveyHeader?.formType ?? FORM_TYPE.QA,
  };
};
