import HelpIcon from '@mui/icons-material/Help';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OverviewEnquete, TARGET_OLG_LEVEL_TYPE } from 'src/@types/overviewEnquete';
import RHFRadioGroup from 'src/components/react-hook-form/RHFRadioGroup';
import { REQUEST_STATUS } from 'src/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';

interface Props {
  methods: UseFormReturn;
}

export const OverviewSetTargetOrgLevelTypeBox = (props: Props) => {
  const { methods } = props;
  const { t } = useTranslation();

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const tabData = enqueteData?.surveyDetail?.surveyFormData?.formData.tabData[0] as OverviewEnquete;
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const questionItem = [
    { label: t('common.companyName'), id: TARGET_OLG_LEVEL_TYPE.COMPANY },
    { label: t('common.sbuName'), id: TARGET_OLG_LEVEL_TYPE.SBU },
  ];
  const aggregationUnitHelpText = (
    <>
      <Box>{t('enqueteCreate.overviewSurvey.AggregationUnitHelpText1')}</Box>
      <Box>{t('enqueteCreate.overviewSurvey.AggregationUnitHelpText2')}</Box>
    </>
  );

  return (
    <Grid container direction="column" alignItems="right" justifyContent="center" spacing={2}>
      <Grid container item direction="row" alignItems="flex-start" spacing={2}>
        <Grid item sx={{ marginTop: '8px', display: 'flex' }}>
          <Typography variant="body1">{t('enqueteCreate.overviewSurvey.AggregationUnit')}</Typography>
          <Tooltip title={aggregationUnitHelpText}>
            <IconButton sx={{ pt: '0px', pb: '0px' }}>
              <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <RHFRadioGroup
            sx={{ display: 'flex', flexDirection: 'row' }}
            name={'targetOrgLevelType'}
            control={methods.control}
            defaultValue={tabData?.targetOrgLevelType ?? TARGET_OLG_LEVEL_TYPE.COMPANY}
            questionItem={questionItem}
            handleOnChange={() => {}}
            disabled={requestStatus !== REQUEST_STATUS.BEFORE_REQUEST}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
