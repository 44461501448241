import { useMemo, useState } from 'react';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { switchLabel } from 'src/utils/labelUtils';
import { createRespondentTargetOrgData } from '../utils/createRespondentTargetOrgData';

type TargetOrgAndPerson = {
  organizationInfo: TargetOrganization;
  respondentPersonsInfo: TargetPerson[];
};

export type TargetOrganization = {
  id: string;
  companyName?: string | null;
  companyCode: string | null;
  departmentName?: string | null;
  departmentCode: string | null;
  sbuName?: string | null;
  sbuCode: string | null;
  divisionName?: string | null;
  divisionCode: string | null;
  groupName?: string | null;
  groupCode: string | null;
};

export type TargetPerson = {
  id: string;
  name: string;
  email: string;
  bluePageAddress: string | null;
};

type UseChangeRespondentModalProps = {
  respondentTarget: RespondentsListEntity;
  newRespondentTargetOrg: OrganizationEntity;
};

export const useChangeRespondentModal = ({ respondentTarget, newRespondentTargetOrg }: UseChangeRespondentModalProps) => {
  const listData = useAppSelector((state) => state.enqueteCreate.requestListData);
  const { updateRespondentTarget } = useSurveyEditApi();
  const { getRequestAnswerList } = useEnqueteCreateGrid();

  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isUpdateAll, setIsUpdateAll] = useState<boolean>(false);

  const onClick = async (respondentRequestId: string, respondentTargetId: string) => {
    const dataForApi = () => {
      return {
        organizationId: updatedRespondentData[0].organizationInfo.id,
        respondentPersonIds: updatedRespondentData[0].respondentPersonsInfo.map((person) => person.id),
      };
    };
    setIsProcessing(true);
    await updateRespondentTarget(respondentTargetId, dataForApi());
    await getRequestAnswerList(respondentRequestId);
    setIsProcessing(false);
  };

  // 変更対象の RespondentPerson の情報
  const selectedPersonInfo = useMemo(
    () =>
      listData
        .filter(
          (data) =>
            data.respondentPersonId === respondentTarget.respondentPersonId && data.respondentTargetId === respondentTarget.respondentTargetId,
        )
        .map((person) => ({
          id: person.respondentPersonId,
          email: person.email,
          bluePageAddress: person.bluePageEmailAddress,
          name: switchLabel(person.userNameJpn, person.userNameEng, isLangJpn),
        })),
    [isLangJpn, listData, respondentTarget.respondentPersonId, respondentTarget.respondentTargetId],
  );

  // 変更対象の RespondentPerson と同じ RespondentTarget の Person の情報を含むすべて
  const allPersonInfo = useMemo(
    () =>
      listData
        .filter((data) => data.respondentTargetId === respondentTarget.respondentTargetId)
        .map((person) => ({
          id: person.respondentPersonId,
          email: person.email,
          bluePageAddress: person.bluePageEmailAddress,
          name: switchLabel(person.userNameJpn, person.userNameEng, isLangJpn),
        })),
    [isLangJpn, listData, respondentTarget.respondentTargetId],
  );

  // memo : 現在選択されている組織情報
  const currentRespondentTargetOrg = createRespondentTargetOrgData(respondentTarget, isLangJpn);

  // memo : 現在選択されている情報を表示用に変換
  const currentRespondentData: TargetOrgAndPerson = useMemo(() => {
    return {
      organizationInfo: {
        id: '',
        companyName: currentRespondentTargetOrg.companyName,
        companyCode: currentRespondentTargetOrg.companyCode,
        departmentName: currentRespondentTargetOrg.departmentName,
        departmentCode: currentRespondentTargetOrg.departmentCode,
        sbuName: currentRespondentTargetOrg.sbuName,
        sbuCode: currentRespondentTargetOrg.sbuCode,
        divisionName: currentRespondentTargetOrg.divisionName,
        divisionCode: currentRespondentTargetOrg.divisionCode,
        groupName: currentRespondentTargetOrg.groupName,
        groupCode: currentRespondentTargetOrg.groupCode,
      },
      respondentPersonsInfo: allPersonInfo,
    };
  }, [allPersonInfo, currentRespondentTargetOrg]);

  // memo : 更新後の情報 (他の回答者も含めるかどうかで違ってくる)
  const updatedRespondentData = useMemo(() => {
    if (!currentRespondentData) return [];
    const newOrganizationInfo = {
      id: newRespondentTargetOrg.id,
      companyName: switchLabel(newRespondentTargetOrg.companyNameJpn, newRespondentTargetOrg.companyNameEng, isLangJpn),
      companyCode: newRespondentTargetOrg.companyCode,
      departmentName: switchLabel(newRespondentTargetOrg.departmentNameJpn, newRespondentTargetOrg.departmentNameEng, isLangJpn),
      departmentCode: newRespondentTargetOrg.departmentCode,
      sbuName: switchLabel(newRespondentTargetOrg.sbuNameJpn, newRespondentTargetOrg.sbuNameEng, isLangJpn),
      sbuCode: newRespondentTargetOrg.sbuCode,
      divisionName: switchLabel(newRespondentTargetOrg.divisionNameJpn, newRespondentTargetOrg.divisionNameEng, isLangJpn),
      divisionCode: newRespondentTargetOrg.divisionCode,
      groupName: switchLabel(newRespondentTargetOrg.groupNameJpn, newRespondentTargetOrg.groupNameEng, isLangJpn),
      groupCode: newRespondentTargetOrg.groupCode,
    };
    if (isUpdateAll) {
      return [
        {
          ...currentRespondentData,
          organizationInfo: newOrganizationInfo,
        },
      ];
    }
    const newData: TargetOrgAndPerson[] = [];
    if (newRespondentTargetOrg) {
      newData.unshift({
        organizationInfo: newOrganizationInfo,
        respondentPersonsInfo: currentRespondentData.respondentPersonsInfo.filter((p) => p.id === respondentTarget.respondentPersonId),
      });

      if (currentRespondentData.respondentPersonsInfo.length > 1) {
        newData.push({
          organizationInfo: currentRespondentData.organizationInfo,
          respondentPersonsInfo: currentRespondentData.respondentPersonsInfo.filter((p) => p.id !== respondentTarget.respondentPersonId),
        });
      }
    }
    return newData;
  }, [currentRespondentData, isLangJpn, isUpdateAll, newRespondentTargetOrg, respondentTarget.respondentPersonId]);

  return {
    isProcessing,
    onClick,
    isUpdateAll,
    setIsUpdateAll,
    updatedRespondentData,
    currentRespondentData,
    selectedPersonInfo,
  };
};
