import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { useAppDispatch } from 'src/redux/store';
import { ENQUETE_REQUEST_PATH } from '../../enquete-request/routes/path';
import ResponseRequestMailLayout from '../components/pages/response-request-mail/components/ResponseRequestMailLayout';
import { TAB_VALUE } from '../constants';
import { ENQUETE_CREATE_PATH } from '../routes/path';
import { setEnqueteData } from '../store/enqueteCreateSlice';
import { SelectedRespondentListEntity, setSelectedRespondentListEntity } from '../store/respondentSettingSlice';

/***********************************************************************************************************************************
 * ResponseRequestPage Component
 ***********************************************************************************************************************************/
export const ResponseRequestPage = () => {
  const locationState = useLocation().state as { enqueteData: RespondentRequestEntity; tempSelectedRespondentList: SelectedRespondentListEntity };
  const { respondentRequestId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  /***********************************************************************************
   * useState
   ***********************************************************************************/
  const [respondentList, setRespondentList] = useState<SelectedRespondentListEntity>({});

  /***********************************************************************************
   * Set Title
   ***********************************************************************************/
  const pageTitle = t('enqueteCreate.responseRequest.pageTitle');
  const requestListPageTitle = t('enqueteRequest.pageTitle');
  const createPageTitle = t('enqueteCreate.addNewEnquete');
  const editPageTitle = t('enqueteCreate.pageTitle');
  const title = respondentRequestId ? editPageTitle : createPageTitle;

  /***********************************************************************************
   * Function
   ***********************************************************************************/
  const setLocationState = () => {
    if (locationState) {
      if ('tempSelectedRespondentList' in locationState) {
        setRespondentList(locationState.tempSelectedRespondentList as SelectedRespondentListEntity);
      }
      // todo:
      //   アンケート編集画面で利用する slice をここで使い回す形にしてしまっているが、
      //   slice の用途／スコープを考えると適切ではない
      //   tempSelectedRespondentList を含めて、 useState / Redux / LocalStorage まで利用しているので、
      //   棲み分けを設計し、最適な形にすることが望まれる
      if ('enqueteData' in locationState) {
        dispatch(setEnqueteData(locationState.enqueteData as RespondentRequestEntity));
      }
    } else {
      enqueueSnackbar(t('enqueteCreate.responseRequest.message.emptyRespondentList'), { variant: 'error' });
    }
  };

  //ページ離脱時に、選択行をStoreに保存し直す
  const setSelectedRowDataToStore = () => {
    dispatch(setSelectedRespondentListEntity(respondentList));
  };

  //パンくず
  const breadCrumbs: HeaderBreadcrumbsProps = {
    heading: pageTitle,
    links: [
      { name: 'TOP', href: '/top' },
      { name: requestListPageTitle, href: ENQUETE_REQUEST_PATH.INDEX },
      { name: title, href: `${ENQUETE_CREATE_PATH.EDIT}/${respondentRequestId}?tab=${TAB_VALUE.RESPONDENT}`, onClick: setSelectedRowDataToStore },
    ],
    activeLast: true,
  };

  /***********************************************************************************
   * useEffect
   ***********************************************************************************/
  useEffect(() => setLocationState(), []); //eslint-disable-line

  /***********************************************************************************
   * Design Template
   ***********************************************************************************/
  return (
    <PageLayout title={pageTitle} breadcrumbs={breadCrumbs}>
      <ResponseRequestMailLayout respondentList={respondentList} setSelectedRowDataToStore={setSelectedRowDataToStore} />
    </PageLayout>
  );
};
