/**
 * UserIdメールアドレスとBluePageメールアドレスを整形し出力
 *
 * @param email
 * @param bluePageEmailAddress
 * @return
 */
export const joinEmails = (email: string, bluePageEmailAddress: string | null) => {
  // email(=userId)がなければ、処理停止し空文字を返却
  if (!email) return '';

  // bluePageEmailAddressが空文字 or Nullであった場合は、emailのみを表示
  // bluePageEmailAddressが存在し、“emailと不一致ケース”で表示形式を変更
  if (bluePageEmailAddress && bluePageEmailAddress !== email) return `${bluePageEmailAddress}(${email})`;

  return email;
};
