import { SxProps } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DivisionRegionEditorDataTypes } from 'src/api/useVisibleConditionsApi';
import { SelectEditorModal } from 'src/components/app-components/bonsai/SelectEditorModal';
import { VISIBLE_TYPE } from 'src/constants';
import { useDivisionRegionEditor } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/division-region-editor-tab/hooks/useDivisionRegionEditor';
import { useDivisionRegionEditorDetail } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/division-region-editor-tab/hooks/useDivisionRegionEditorDetail';
import { useDivisionRegionGrid } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/division-region-editor-tab/hooks/useDivisionRegionGrid';
import { useAppSelector } from 'src/redux/store';

export interface Props {
  surveyDetailId: string;
  registeredData: DivisionRegionEditorDataTypes[] | undefined;
  targetData: DivisionRegionEditorDataTypes;
}

export const SelectDivisionRegionEditorModal: React.FC<Props> = memo((props) => {
  const { surveyDetailId, registeredData, targetData } = props;
  const { t } = useTranslation();

  const { closeEditModal } = useDivisionRegionEditor();

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isOpenEditModal = useAppSelector((state) => state.divisionRegionEditor.isOpenEditModal);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // フォーム、選択行データ作成
  const {
    conditionsForOrganization,
    conditionsForPerson,
    accessRoleConditions,
    selectedRowData,
    isLoading,
    isSearchLoading,
    isEnableSelect,
    isSelected,
    isTargetSelect,
    setTargetSelect,
    onSelected,
    onRegister,
    onUpdate,
    setSelectedRowData,
    visibleCondition,
  } = useDivisionRegionEditorDetail({ isEdit, targetData, surveyDetailId, registeredData });

  // グリッド整形定義
  const { defaultColDef, grid } = useDivisionRegionGrid({ selectedRowData, setSelectedRowData });

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    setIsEdit(targetData.id !== '');
    setTargetSelect(targetData.visibleType !== VISIBLE_TYPE.ALL);
  }, [setTargetSelect, targetData]);

  /***********************************************************************************
   * Constant
   ***********************************************************************************/
  // 選択追加ボタン
  const addButton = isEdit
    ? {
        name: t('button.update'),
        onClick: onUpdate,
        disabled: !isEnableSelect,
      }
    : {
        name: t('button.add'),
        onClick: onSelected,
        disabled: !isEnableSelect,
      };

  // 保存ボタン
  const saveButtons = {
    name: t('button.save'),
    onClick: onRegister,
    disabled: !isSelected,
  };
  // モーダルタイトル
  const modalTitle = isEdit
    ? t('enqueteCreate.divisionRegionEditor.selectEditorModal.editTitle')
    : t('enqueteCreate.divisionRegionEditor.selectEditorModal.createTitle');

  // モーダルのスタイルを設定
  const modalStyle: SxProps = {
    overflowY: 'auto',
    maxHeight: '100%',
  };

  return (
    <SelectEditorModal
      isLoading={isLoading || isSearchLoading}
      isOpen={isOpenEditModal}
      isEdit={isEdit}
      isTargetSelect={isTargetSelect}
      title={modalTitle}
      middleButton={addButton}
      button={saveButtons}
      onClose={closeEditModal}
      conditionsForOrganizaion={conditionsForOrganization}
      conditionsForPerson={conditionsForPerson}
      accessRoleConditions={accessRoleConditions}
      visibleCondition={visibleCondition}
      grid={grid}
      defaultColDef={defaultColDef}
      modalStyleSxProps={modalStyle}
    />
  );
});
