import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    zIndex: 1200,
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 200,
    },
    [`& .${tooltipClasses.arrow}::before`]: {
      color: theme.palette.error.main,
    },
  }),
);
