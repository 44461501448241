import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setPermission } from 'src/features/general/enquete-status/store/enqueteStatusSlice';
import { usePermission } from 'src/hooks/usePermission';
import EnqueteStatusListTab from '../../shared-tabs/enquete-status-list/components/EnqueteStatusListTab';
import TypeSeriesSummaryAggregateTab from './tabs/summary-aggregate/components/TypeSeriesSummaryAggregateTab';
import SeriesAggregateSupplementTab from './tabs/aggregate-supplement/components/SeriesAggregateSupplementTab';
import { dispatch } from 'src/redux/store';

export const SeriesEnqueteStatusTabContainer: React.FC = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const surveyDetailId: string = String(params.get('surveyDetailId'));

  //タブ
  const [value, setValue] = useState('1');

  //タブ切り替え関数
  const handleChange = (e: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  // 権限情報を取得し、ストアに格納
  const { isAdmin, isViewerAdmin, isSurveyOwner } = usePermission(surveyDetailId);
  useEffect(() => {
    dispatch(setPermission({ isAdmin, isSurveyOwner }));
  }, [isAdmin, isSurveyOwner]);

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange}>
        <Tab label={t('enqueteStatus.tab.answerStatus')} value="1" />
        <Tab label={t('enqueteStatus.tab.summaryAggregate')} value="2" />
        {(isAdmin || isSurveyOwner || isViewerAdmin) && <Tab label={t('enqueteStatus.tab.aggregateSupplement')} value="3" />}
      </TabList>
      <TabPanel value="1">
        <EnqueteStatusListTab />
      </TabPanel>
      <TabPanel value="2">
        <TypeSeriesSummaryAggregateTab />
      </TabPanel>
      <TabPanel value="3">
        <SeriesAggregateSupplementTab />
      </TabPanel>
    </TabContext>
  );
};
