import { ColumnGroupOpenedEvent, GetContextMenuItems, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationEntity, searchDataEntity, useOrganizationApi } from 'src/api/useOrganizationApi'; //eslint-disable-line
import { ConditionProps, SelectModal } from 'src/components/app-components/bonsai/SelectModal';
import useLocales from 'src/hooks/useLocales';
import useSuggestUser from 'src/hooks/useSuggestUser';
import useSelectRespondentGrid from '../hooks/useSelectRespondentGrid';

export interface SelectRespondentModalResultRowData {
  targetId: string;
  targetCompanyCode: string | null;
  targetCompanyNameJpn: string | null;
  targetCompanyNameEng: string | null;
  targetCompanyAbbreviation: string | null;
  targetDivisionCode: string | null;
  targetDivisionNameJpn: string | null;
  targetDivisionNameEng: string | null;
  targetSbuCode: string | null;
  targetSbuNameJpn: string | null;
  targetSbuNameEng: string | null;
  targetDepartmentCode: string | null;
  targetDepartmentNameJpn: string | null;
  targetDepartmentNameEng: string | null;
  targetGroupCode: string | null;
  targetGroupNameJpn: string | null;
  targetGroupNameEng: string | null;
  targetRegionNameJpn: string | null;
  targetRegionNameEng: string | null;
  targetCountryCode: string | null;
  targetCountryNameJpn: string | null;
  targetCountryNameEng: string | null;
  targetRank: string | null;
  targetOrgLevelTeyp: 'COMPANY' | 'DIVISION' | 'SBU' | 'DEPARTMENT' | 'GROUP' | null;
  targetisFuture: boolean;
  targetIsDeleted: boolean;
  respondentPersonFullNameJpn: string | null;
  respondentPersonFullNameEng: string | null;
  respondentPersonEmail: string | null;
  respondentPersonBluePageEmailAddress: string | null;
  respondentPersonAssignedCompanyNameJpn: string | null;
  respondentPersonAssignedCompanyNameEng: string | null;
  respondentPersonCompanyCode: string | null;
  respondentPersonCompanyNameJpn: string | null;
  respondentPersonCompanyNameEng: string | null;
  respondentPersonCompanyAbbreviation: string | null;
  respondentPersonDivisionCode: string | null;
  respondentPersonDivisionNameJpn: string | null;
  respondentPersonDivisionNameEng: string | null;
  respondentPersonSbuCode: string | null;
  respondentPersonSbuNameJpn: string | null;
  respondentPersonSbuNameEng: string | null;
  respondentPersonDepartmentCode: string | null;
  respondentPersonDepartmentNameJpn: string | null;
  respondentPersonDepartmentNameEng: string | null;
  respondentPersonGroupCode: string | null;
  respondentPersonGroupNameJpn: string | null;
  respondentPersonGroupNameEng: string | null;
}
export interface SelectRespondentModalResultRowDataWithValidationError extends SelectRespondentModalResultRowData {
  validateError: boolean;
}

interface KeyEntity<T> {
  key: string;
  entity: T;
}

export interface Props {
  surveyDetailId: string;
  open: boolean;
  onClose: () => void;
  onOk: (values: SelectRespondentModalResultRowData[]) => void;
  onValidation: (values: SelectRespondentModalResultRowData[]) => boolean;
}

export const SelectRespondentModal: React.FC<Props> = (props) => {
  const { currentLang } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { userSuggest, updateUserSuggest } = useSuggestUser();
  const { getResolveOrganizationData, getOrganizations } = useOrganizationApi();
  const { columnDefs, setOpenOrganizationColumnGroup, setOpenRespondentColumnGroup } = useSelectRespondentGrid();

  // グリッドの行データ
  const [rowData, setRowData] = useState<SelectRespondentModalResultRowDataWithValidationError[]>([]);
  // 各コンポーネントの選択中の状態(文字列)
  const [selectedTargetOrganization, setSelectedTargetOrganization] = useState<string | null>(null);
  const [selectedRespondent, setSelectedRespondent] = useState<string | null>(null);
  // 各コンポーネントの候補リスト
  const [targetOrganizationList, setTargetOrganizationList] = useState<KeyEntity<OrganizationEntity>[]>([]);
  // 表示フラグ
  const [displayTargetOrganization, setDisplayTargetOrganization] = useState<boolean>(true);
  const [displayRespondent, setDisplayRespondent] = useState<boolean>(true);
  // グリッドAPI
  const gridApi = useRef<GridApi | null>(null);

  //-------------------------------------------------------------
  // Organization情報の初期化処理
  //-------------------------------------------------------------
  const updateOrganizatioinList = useCallback(
    async (orgFilter?: string | null) => {
      //全角スペースを半角に変換
      const filter = orgFilter?.replace(/　/g, ' ');

      // 組織情報の取得
      const orgs = await getOrganizations(props.surveyDetailId, filter ?? undefined);
      if (!orgs) return setTargetOrganizationList([]);

      // 表示用文字列作成
      const targetOrganizationList: KeyEntity<OrganizationEntity>[] = [];
      for (const org of orgs) {
        let strJpn = `${org.companyCode ?? ''}`;
        if (org.companyNameJpn) strJpn += ` - ${org.companyNameJpn}`;
        if (org.companyAbbreviation) strJpn += ` - ${org.companyAbbreviation}`;
        if (org.divisionNameJpn) strJpn += ` - ${org.divisionNameJpn}`;
        if (org.sbuNameJpn) strJpn += ` - ${org.sbuNameJpn}`;
        if (org.departmentNameJpn) strJpn += ` - ${org.departmentNameJpn}`;
        if (org.groupNameJpn) strJpn += ` - ${org.groupNameJpn}`;
        let strEng = `${org.companyCode ?? ''}`;
        if (org.companyNameEng) strEng += ` - ${org.companyNameEng}`;
        if (org.companyAbbreviation) strEng += ` - ${org.companyAbbreviation}`;
        if (org.divisionNameEng) strEng += ` - ${org.divisionNameEng}`;
        if (org.sbuNameEng) strEng += ` - ${org.sbuNameEng}`;
        if (org.departmentNameEng) strEng += ` - ${org.departmentNameEng}`;
        if (org.groupNameEng) strEng += ` - ${org.groupNameEng}`;
        const key = currentLang.value === 'ja' ? strJpn : strEng;
        targetOrganizationList.push({ key, entity: org });
      }

      // 保存
      setTargetOrganizationList(targetOrganizationList);
    },
    [currentLang.value, getOrganizations, props.surveyDetailId],
  );

  //-------------------------------------------------------------
  // 回答対象会社が変更された時の処理
  //-------------------------------------------------------------
  const onChangeTargetOrganization = (value: string | null) => {
    // 選択されている文字列を更新
    setSelectedTargetOrganization(value);
  };

  //-------------------------------------------------------------
  // 回答担当者が変更された時の処理
  //-------------------------------------------------------------
  const onChangeRespondent = (value: string | null) => {
    // 選択されている文字列を更新
    setSelectedRespondent(value);
  };

  //-------------------------------------------------------------
  // OKボタンが押された時の処理
  //-------------------------------------------------------------
  const onOk = async () => {
    const copyRowData = [...rowData];
    props.onOk(copyRowData);
    reset();
  };

  //-------------------------------------------------------------
  // 状態のリセット
  //-------------------------------------------------------------
  const reset = () => {
    setRowData([]);
    setSelectedTargetOrganization(null);
    setSelectedRespondent(null);
    setDisplayTargetOrganization(true);
    setDisplayRespondent(true);
    setOpenOrganizationColumnGroup(false);
    setOpenRespondentColumnGroup(false);
  };

  //-------------------------------------------------------------
  // 追加ボタンが押された時の処理
  //-------------------------------------------------------------
  const onAdd = async () => {
    // 入力値のプロパティを取得
    const targetOrganization = targetOrganizationList.find((org) => org.key === selectedTargetOrganization);
    // const targetSbu = targetSbuList.find((sbu) => sbu.key === selectedTargetSbu);
    const respondentPerson = userSuggest.find((person) => person.key === selectedRespondent);
    // 入力値の確認
    if (!targetOrganization) return enqueueSnackbar(t('enqueteCreate.selectRespondentModal.message.noCompanySelected'), { variant: 'error' });
    if (!respondentPerson) return enqueueSnackbar(t('enqueteCreate.selectRespondentModal.message.noRespondentSelected'), { variant: 'error' });

    //検索条件定義
    const condition: searchDataEntity = {
      companyCode: respondentPerson.entity.companyCode ?? '',
      divisionCode: respondentPerson.entity.divisionCode ?? '',
      sbuCode: respondentPerson.entity.sbuCode ?? '',
      departmentCode: respondentPerson.entity.departmentCode ?? '',
      groupCode: respondentPerson.entity.groupCode ?? '',
      isFuture: false,
      isMultiSegment: false,
    };

    const result = await getResolveOrganizationData(condition);
    if (result === undefined) return;

    // グリッド用のフォーマットへ変換

    const addRowData: SelectRespondentModalResultRowDataWithValidationError = {
      // target organization entity
      targetId: targetOrganization.entity.id,
      targetCompanyCode: targetOrganization.entity.companyCode,
      targetCompanyNameJpn: targetOrganization.entity.companyNameJpn,
      targetCompanyNameEng: targetOrganization.entity.companyNameEng,
      targetCompanyAbbreviation: targetOrganization.entity.companyAbbreviation,
      targetDivisionCode: targetOrganization.entity.divisionCode,
      targetDivisionNameJpn: targetOrganization.entity.divisionNameJpn,
      targetDivisionNameEng: targetOrganization.entity.divisionNameEng,
      targetSbuCode: targetOrganization.entity.sbuCode,
      targetSbuNameJpn: targetOrganization.entity.sbuNameJpn,
      targetSbuNameEng: targetOrganization.entity.sbuNameEng,
      targetDepartmentCode: targetOrganization.entity.departmentCode,
      targetDepartmentNameJpn: targetOrganization.entity.departmentNameJpn,
      targetDepartmentNameEng: targetOrganization.entity.departmentNameEng,
      targetGroupCode: targetOrganization.entity.groupCode,
      targetGroupNameJpn: targetOrganization.entity.groupNameJpn,
      targetGroupNameEng: targetOrganization.entity.groupNameEng,
      targetRegionNameJpn: targetOrganization.entity.regionNameJpn,
      targetRegionNameEng: targetOrganization.entity.regionNameEng,
      targetCountryCode: targetOrganization.entity.countryCode,
      targetCountryNameJpn: targetOrganization.entity.countryNameJpn,
      targetCountryNameEng: targetOrganization.entity.countryNameEng,
      targetRank: targetOrganization.entity.rank,
      targetOrgLevelTeyp: targetOrganization.entity.orgLevelType,
      targetisFuture: targetOrganization.entity.isFuture,
      targetIsDeleted: targetOrganization.entity.isDeleted,
      // respondent person entity
      respondentPersonFullNameJpn: respondentPerson.entity.fullNameJpn,
      respondentPersonFullNameEng: respondentPerson.entity.fullNameEng,
      respondentPersonEmail: respondentPerson.entity.email,
      respondentPersonBluePageEmailAddress: respondentPerson.entity.bluePageEmailAddress,
      respondentPersonAssignedCompanyNameJpn: respondentPerson.entity.assignedCompanyNameJpn,
      respondentPersonAssignedCompanyNameEng: respondentPerson.entity.assignedCompanyNameEng,
      respondentPersonCompanyCode: result[0].companyCode,
      respondentPersonCompanyNameJpn: result[0].companyNameJpn,
      respondentPersonCompanyNameEng: result[0].companyNameEng,
      respondentPersonCompanyAbbreviation: result[0].companyAbbreviation,
      respondentPersonDivisionCode: result[0].divisionCode,
      respondentPersonDivisionNameJpn: result[0].divisionNameJpn,
      respondentPersonDivisionNameEng: result[0].divisionNameEng,
      respondentPersonSbuCode: result[0].sbuCode,
      respondentPersonSbuNameJpn: result[0].sbuNameJpn,
      respondentPersonSbuNameEng: result[0].sbuNameEng,
      respondentPersonDepartmentCode: result[0].departmentCode,
      respondentPersonDepartmentNameJpn: result[0].departmentNameJpn,
      respondentPersonDepartmentNameEng: result[0].departmentNameEng,
      respondentPersonGroupCode: result[0].groupCode,
      respondentPersonGroupNameJpn: result[0].groupNameJpn,
      respondentPersonGroupNameEng: result[0].groupNameEng,
      validateError: false,
    };

    // 重複確認
    const addRowDataJsonString = JSON.stringify(addRowData);
    const exists = rowData.filter((rowdata) => JSON.stringify(rowdata) === addRowDataJsonString);
    if (exists.length !== 0) {
      return enqueueSnackbar(t('enqueteCreate.selectRespondentModal.message.alreadySelectedRespondent'), { variant: 'warning' });
    }

    // ページ画面に既に登録されていないか確認
    if (props.onValidation([addRowData])) {
      return enqueueSnackbar(t('enqueteCreate.selectRespondentModal.message.alreadyRegisteredRespondent'), { variant: 'warning' });
    }
    // 登録
    setRowData([...rowData, addRowData]);
  };

  //-------------------------------------------------------------
  // 削除ボタンが押された時の処理
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('button.delete'),
        action: () => setRowData(rowData.filter((row, i) => i !== params.node?.rowIndex)),
      },
    ];

    return menuItems;
  };

  //-------------------------------------------------------------
  // グルーピングの表示/非表示更新処理
  //-------------------------------------------------------------
  const onColumnGroupOpened = (event: ColumnGroupOpenedEvent) => {
    const states = event.columnApi.getColumnGroupState();
    setOpenOrganizationColumnGroup(states[0].open);
    setOpenRespondentColumnGroup(states[1].open);
  };

  //-------------------------------------------------------------
  // グリッドの表示準備完了時の処理
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // グリッド追加条件の定義
  //-------------------------------------------------------------
  const conditions: ConditionProps[] = [
    {
      label: t('enqueteCreate.selectRespondentModal.textfield.selectCompany.placeholder'),
      value: selectedTargetOrganization,
      list: targetOrganizationList.map((org) => org.key),
      display: displayTargetOrganization,
      onChange: onChangeTargetOrganization,
      onInputChange: (v, r) => {
        if (r !== 'reset') updateOrganizatioinList(v);
      },
    },
    {
      label: t('enqueteCreate.selectRespondentModal.textfield.selectRespondent.placeholder'),
      value: selectedRespondent,
      list: userSuggest.map((resp) => resp.key),
      display: displayRespondent,
      onChange: onChangeRespondent,
      onInputChange: (v, r) => {
        if (r !== 'reset') updateUserSuggest(v);
      },
    },
  ];

  //-------------------------------------------------------------
  // 初期化処理
  //-------------------------------------------------------------
  useEffect(() => {
    if (props.open) {
      updateOrganizatioinList();
      updateUserSuggest();
    }
  }, [props.open]); // eslint-disable-line

  //-------------------------------------------------------------
  // コンポーネントデザイン
  //-------------------------------------------------------------
  return (
    <SelectModal
      title={t('enqueteCreate.selectRespondentModal.title')}
      open={props.open}
      conditions={conditions}
      table={{
        columnDefs: columnDefs,
        rowData: rowData,
        onColumnGroupOpened,
        onGridReady,
        contextMenu: contextMenu,
      }}
      onClose={props.onClose}
      onOk={onOk}
      onAdd={onAdd}
    />
  );
};
