import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GeneralEnquete, Question } from 'src/@types/generalEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { useTranslationUpload } from 'src/features/general/enquete-create/components/modals/enquete-translation-support/hooks/useTranslationUpload';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { ENQUETE_CREATE_PATH } from 'src/features/general/enquete-create/routes/path';
import { MAX_DATA_SIZE } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { setEnqueteData, setQuestions, setQuestionsEng } from '../store/enqueteCreateSlice';

export const useEnqueteSave = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const questions = useSelector((state: RootState) => state.enqueteCreate.questions);
  const questionsTranslationData = useSelector((state: RootState) => state.enqueteCreate.questionsTranslationData);
  const questionDescriptionJpn = useSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);
  const nav = useNavigate();
  const enqueteCreateGrid = useEnqueteCreateGrid();
  const { replaceTranslationQuestionsData, convertFileArrayToTranslationTable } = useTranslationUpload();

  const onClickSaveData = async (enqueteData: RespondentRequestEntity, logicQuestions?: Question[]) => {
    const newEnqueteData: RespondentRequestEntity = JSON.parse(JSON.stringify(enqueteData));
    const tabData: GeneralEnquete = newEnqueteData.surveyDetail?.surveyFormData?.formData.tabData[0] as GeneralEnquete;

    const currentTranslationTable = convertFileArrayToTranslationTable(questionsTranslationData);

    if (logicQuestions) {
      tabData.questionsJpn = logicQuestions;
      tabData.questionsEng = replaceTranslationQuestionsData(logicQuestions, currentTranslationTable);
    } else {
      tabData.questionsJpn = questions;
      tabData.questionsEng = replaceTranslationQuestionsData(questions, currentTranslationTable);
    }

    tabData.questionsTranslationData = questionsTranslationData;
    tabData.questionDescriptionJpn = questionDescriptionJpn;
    tabData.questionDescriptionEng = questionDescriptionEng;

    if (JSON.stringify(tabData).length > MAX_DATA_SIZE.SURVEY_FORM) {
      enqueueSnackbar(t('message.exceptionDataSizeOver', { target: t('common.question') }), { variant: 'error' });
    } else {
      if (!newEnqueteData.surveyDetailId) {
        const result = await enqueteCreateGrid.saveNewEnqueteData(newEnqueteData, 'general');
        if (result) {
          dispatch(setEnqueteData({ ...result.respondentRequest, surveyDetail: { ...result.surveyDetail, surveyHeader: result.surveyHeader } }));
          nav(ENQUETE_CREATE_PATH.EDIT + '/' + result.respondentRequest.id);
        }
      } else {
        const survayDetail = newEnqueteData.surveyDetail;
        const result = await enqueteCreateGrid.saveSurveyDetailData(survayDetail as SurveyDetailEntity);
        if (result) {
          newEnqueteData.surveyDetail = {
            ...result.data,
            surveyHeader: newEnqueteData.surveyDetail?.surveyHeader, // memo: 更新後の結果に SurveyHeader が含まれないため、明示的にセット
          } as SurveyDetailEntity;
          dispatch(setEnqueteData({ ...newEnqueteData }));
          dispatch(setQuestions(tabData.questionsJpn as Question[]));
          dispatch(setQuestionsEng(tabData.questionsEng as Question[]));
        }
      }
    }
  };

  return {
    onClickSaveData,
  };
};
