import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { setCustomerGroups } from '../../../../../../../../store/seriesAnswerSlice';

export const useCustomerDelete = () => {
  const dispatch = useAppDispatch();
  const customerGroups = useAppSelector((state) => state.seriesAnswer.customerGroups);

  const customerDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, parentCustomerCode: string) => {
    const splitResult = event.currentTarget.value.split(',');
    // 削除したい取引先コード取得
    const removeCustomerCode = splitResult[0];
    // 削除したい親取引先コード取得
    const removeParentCustomerCode = splitResult[1];
    // 削除したい取引先があるcustomerGroupを取得
    const parentCustomer = customerGroups.find((customer) => customer.parentCustomerCode === removeParentCustomerCode);

    // 上で取得したcustomerGroupで削除する取引先コードと一致しない取引先を取得
    const newChildrenCustomer = parentCustomer?.childrenCustomer.filter((customer) => customer.customerCode !== removeCustomerCode);
    if (newChildrenCustomer?.length === 0 || !newChildrenCustomer) {
      const newCustomerGroups = customerGroups.filter((customer) => customer.parentCustomerCode !== removeParentCustomerCode);
      dispatch(setCustomerGroups([...newCustomerGroups]));
    } else {
      const newCustomerGroups = customerGroups.map((customerGroup) => {
        if (customerGroup.parentCustomerCode === parentCustomerCode) {
          return {
            ...customerGroup,
            childrenCustomer: newChildrenCustomer,
          };
        }
        return customerGroup;
      });
      dispatch(setCustomerGroups([...newCustomerGroups]));
    }
  };
  return { customerDelete };
};
