import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputRadioGroup from 'src/components/app-components/bonsai/survey-information/components/InputRadioGroup';
import { DISCLOSURE_LEVEL_UPDATE_CONFIRM } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';

type DisclousureLevelUpdateConfirmFormProps = {
  methods: UseFormReturn;
};

export const DisclousureLevelUpdateConfirmForm = ({ methods }: DisclousureLevelUpdateConfirmFormProps) => {
  const { rulesDisclosureLevelUpdateConfirm } = useValidator(methods);
  const { t } = useTranslation();

  return (
    <InputRadioGroup
      name={'disclosureLevelUpdateConfirm'}
      defaultValue={DISCLOSURE_LEVEL_UPDATE_CONFIRM.NO}
      control={methods.control}
      rules={rulesDisclosureLevelUpdateConfirm}
      getValues={methods.getValues}
      list={[
        { value: DISCLOSURE_LEVEL_UPDATE_CONFIRM.NO, label: t('enqueteGeneralCommon.disclosureLevelUpdateConfirmNo') },
        { value: DISCLOSURE_LEVEL_UPDATE_CONFIRM.YES, label: t('enqueteGeneralCommon.disclosureLevelUpdateConfirmYes') },
      ]}
      disabled={false}
      toolTip={
        <Tooltip
          title={t('enqueteGeneralCommon.tooltip.disclosureLevelUpdateConfirm')
            .split('\n')
            .map((text: string) => (
              <div key={text}>{text}</div>
            ))}>
          <IconButton>
            <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
          </IconButton>
        </Tooltip>
      }
    />
  );
};
