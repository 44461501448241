import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserEntity, useUserApi } from 'src/api/useUserApi';
import {
  EnqueteAnswerEntity,
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useAuth from 'src/hooks/useAuth';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../hooks/useEnqueteAnswerGrid';
import { CreateSaveAnswerJsonData } from '../../confirm-comments/hooks/CreateSaveAnswerJsonData';

//-------------------------------------------------------------
// 「保存」処理
//-------------------------------------------------------------

export const useInputSubmitHandler = () => {
  const userApi = useUserApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  // ユーザー情報宣言
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState<UserEntity>();
  const { createData } = CreateSaveAnswerJsonData();
  const getUserInfo = async () => {
    const userEmail = null !== user ? user.email : '';

    //ユーザー名取得
    const myInfo = await userApi.getByEmail(userEmail);
    if (!myInfo || myInfo.length === 0) {
      throw Error(t('message.exceptionNotFoundUserData'));
    }

    setUserInfo(myInfo[0]);
  };
  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    getUserInfo();
  }, []); //eslint-disable-line

  const {
    saveAnswer,
    updateAnswer,
    setHasErrorOtherTextForm,
    setHasErrorPersonnelGridNotMatchTotal,
    setHasErrorPersonnelCorporateGridTotalOver,
    setHasErrorPersonnelSalesGridTotalOver,
    setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm,
    setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm,
    setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm,
    setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 人件費人員(重点分野)：1.正社員の人員合計と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 人件費人員(重点分野)：2.TTCからの出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
    // 人件費人員(重点分野)：3.2.以外の出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトと（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
    // 製造原価人員(重点分野)：1.正社員の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
    // 人件費人員（重点分野別）年計-実見　雇用形態、重点分野、差異理由、差異人数が全部入力しないエラーの設定
    setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別）中計-年計　雇用形態、重点分野、差異理由、差異人数が全部入力しないエラーの設定
    setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別）年計-実見　雇用形態、重点分野、差異理由、差異人数が全部入力しないエラーの設定
    setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別）中計-年計　雇用形態、重点分野、差異理由、差異人数が全部入力しないエラーの設定
    setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm,
  } = useEnqueteAnswerGrid();

  // 取得したRespondentTargetの情報を格納
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  // 取得したAnswerの情報を格納
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);

  // アンケートの各項目の入力データ
  const answerDataEdit = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerDataEdit);
  // 人件費人員(雇用形態別)のTotalを格納
  const personnelEmploymentTotal = useAppSelector((state) => state.enqueteAnswer.personnelEmploymentTotal);
  // コーポレート人員のTotalを格納
  const corporateGridTotal = useAppSelector((state) => state.enqueteAnswer.corporateGridTotal);
  // 営業人員のTotalを格納
  const salesGridTotal = useAppSelector((state) => state.enqueteAnswer.salesGridTotal);
  // 人件費人員(重点分野)：1.正社員のTotalを格納
  const personnelPriorityDomainRegularEmployeeGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.personnelPriorityDomainRegularEmployeeGridTotal,
  );
  // 人件費人員(重点分野)：2.TTCからの出向受入のTotalを格納
  const personnelPriorityDomainSecondmentFromTTCGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.personnelPriorityDomainSecondmentFromTTCGridTotal,
  );
  // 人件費人員(重点分野)：3.2.以外の出向受入のTotalを格納
  const personnelPriorityDomainSecondmentFromOtherGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.personnelPriorityDomainSecondmentFromOtherGridTotal,
  );
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトのTotalを格納
  const personnelPriorityDomainDispatchEtcGridTotal = useAppSelector((state) => state.enqueteAnswer.personnelPriorityDomainDispatchEtcGridTotal);
  // 製造原価人員(重点分野)：1.正社員のTotalを格納
  const manufyearPlanurPriorityDomainRegularEmployeeGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.manufyearPlanurPriorityDomainRegularEmployeeGridTotal,
  );
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他のTotalを格納
  const manufyearPlanurPriorityDomainOtherEmployeesGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.manufyearPlanurPriorityDomainOtherEmployeesGridTotal,
  );

  // タブ：人件費人員(雇用形態別)の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorPersonnelEmploymentResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelEmploymentResultAndPlanDiffForm,
  );
  // タブ：人件費人員(雇用形態別)の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorPersonnelEmploymentPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelEmploymentPlanAndPlanDiffForm,
  );
  // タブ：製造原価人員（雇用形態別）の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm,
  );
  // タブ：製造原価人員（雇用形態別）の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm,
  );
  // タブ：人件費人員（重点分野別）の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorPersonnelPriorityDomainResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainResultAndPlanDiffForm,
  );
  // タブ：人件費人員（重点分野別）の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm,
  );
  // タブ：製造原価人員（重点分野別）の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm,
  );
  // タブ：製造原価人員（重点分野別）の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
  );
  //-------------------------------------------------------------
  // 編集データ取得
  //-------------------------------------------------------------
  // 人件費人員（雇用形態別）の編集データ
  const personnelEmploymentRowDataEdit: any = answerDataEdit.personnelEmploymentRowData;
  const personnelEmploymentResultAndPlanDiffFormEdit = (
    answerDataEdit.personnelEmploymentResultAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const personnelEmploymentPlanAndPlanDiffFormEdit = (
    answerDataEdit.personnelEmploymentPlanAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const personnelCorporateRowDataEdit: any = answerDataEdit.personnelCorporateRowData;
  const personnelCorporateOtherFormTextValueEdit = answerDataEdit.personnelCorporateOtherFormTextValue;
  // 製造原価人員（雇用形態別）の編集データ
  const manufyearPlanurEmploymentRowDataEdit: any = answerDataEdit.manufyearPlanurEmploymentRowData;
  const manufyearPlanurEmploymentResultAndPlanDiffFormEdit = (
    answerDataEdit.manufyearPlanurEmploymentResultAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const manufyearPlanurEmploymentPlanAndPlanDiffFormEdit = (
    answerDataEdit.manufyearPlanurEmploymentPlanAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  // 人件費人員（重点分野別）年計-実見の編集データ
  const personnelPriorityDomainResultAndPlanDiffFormEdit = answerDataEdit.personnelPriorityDomainResultAndPlanDiffForm as
    | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
    | undefined;

  // 人件費人員（重点分野別）中計-年計の編集データ
  const personnelPriorityDomainPlanAndPlanDiffFormEdit = answerDataEdit.personnelPriorityDomainPlanAndPlanDiffForm as
    | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
    | undefined;

  // 製造原価人員（重点分野別）年計-実見の編集データ
  const manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit = answerDataEdit.manufyearPlanurPriorityDomainResultAndPlanDiffForm as
    | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
    | undefined;

  // 製造原価人員（重点分野別）中計-年計の編集データ
  const manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit = answerDataEdit.manufyearPlanurPriorityDomainPlanAndPlanDiffForm as
    | TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[]
    | undefined;

  // ユーザー情報宣言
  const userId = null !== user ? user.username : '';
  const respondentTargetId = undefined !== respondentTargetData ? respondentTargetData.id : undefined;

  //-------------------------------------------------------------
  // 差異人数と差異理由に未入力が無いかをチェックする
  //-------------------------------------------------------------
  const isEmptyCheckDiffInputValue = (diffFormAnswerInputData: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined): boolean => {
    if (diffFormAnswerInputData !== undefined) {
      let errorFlag = false;
      diffFormAnswerInputData.forEach((item) => {
        const checkTargetValue = item.diffMemberInputValue !== null ? item.diffMemberInputValue.toString() : null;
        const checkTargetInputReasonValue = item.diffMemberReasonInputValue !== null ? item.diffMemberReasonInputValue : null;
        // 差異理由が存在する
        if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue !== '') {
          if (checkTargetValue === null || checkTargetValue.length === 0) {
            errorFlag = true;
          }
        }
        // 差異人数が存在する
        if (checkTargetValue !== null && checkTargetValue !== '') {
          if (checkTargetInputReasonValue === null || checkTargetInputReasonValue.length === 0) {
            errorFlag = true;
          }
        }
      });
      if (errorFlag) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  //-------------------------------------------------------------
  // 差異人数、差異理由、雇用形態と重点分野に未入力が無いかチェックする
  //-------------------------------------------------------------
  const isEmptyCheckPriorityDomainDiffInputValue = (
    diffFormAnswerInputData: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined,
  ): boolean => {
    if (diffFormAnswerInputData !== undefined) {
      let errorFlag = false;
      diffFormAnswerInputData.forEach((item) => {
        // 差異人数
        const checkTargetValue = item !== undefined && item.diffMemberInputValue ? item.diffMemberInputValue : null;
        // 差異理由
        const checkTargetInputReasonValue = item !== undefined && item.diffMemberReasonInputValue ? item.diffMemberReasonInputValue : null;
        // 雇用形態
        const checkEmployment = item !== undefined && item.employment ? item.employment : null;
        // 重点分野
        const checkPriorityDomain = item !== undefined && item.priorityDomain ? item.priorityDomain : null;
        // 差異理由が存在する場合に他の項目が入力されているか確認する
        if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue !== '') {
          if (
            checkTargetValue === null ||
            checkTargetValue.length === 0 ||
            checkEmployment === null ||
            checkEmployment.length === 0 ||
            checkPriorityDomain === null ||
            checkPriorityDomain.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
        // 差異人数が存在する場合に他の項目が入力されているか確認する
        if (checkTargetValue !== null && checkTargetValue !== '') {
          if (
            checkTargetInputReasonValue === null ||
            checkTargetInputReasonValue.length === 0 ||
            checkEmployment === null ||
            checkEmployment.length === 0 ||
            checkPriorityDomain === null ||
            checkPriorityDomain.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
        //雇用形態が存在する場合に他の項目が入力されているか確認する
        if (checkEmployment !== null && checkEmployment !== '') {
          if (
            checkTargetInputReasonValue === null ||
            checkTargetInputReasonValue.length === 0 ||
            checkTargetValue === null ||
            checkTargetValue.length === 0 ||
            checkPriorityDomain === null ||
            checkPriorityDomain.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
        //重点分野が存在する場合に他の項目が入力されているか確認する
        if (checkPriorityDomain !== null && checkPriorityDomain !== '') {
          if (
            checkTargetInputReasonValue === null ||
            checkTargetInputReasonValue.length === 0 ||
            checkTargetValue === null ||
            checkTargetValue.length === 0 ||
            checkEmployment === null ||
            checkEmployment.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
      });
      if (errorFlag) {
        // エラーがある
        return true;
      } else {
        // エラーがない
        return false;
      }
    }
    // エラーがない
    return false;
  };
  //-------------------------------------------------------------
  // コーポレート人員+営業人員グリッド合計値チェック
  //-------------------------------------------------------------
  const isGridTotalError = (): boolean => {
    const corporateResultForecastTotal = isNaN(Number(corporateGridTotal['resultForecast'])) ? 0 : Number(corporateGridTotal['resultForecast']);
    const salesResultForecastTotal = isNaN(Number(salesGridTotal['resultForecast'])) ? 0 : Number(salesGridTotal['resultForecast']);
    const personnelResultForecastTotal = isNaN(Number(personnelEmploymentTotal.resultForecast)) ? 0 : Number(personnelEmploymentTotal.resultForecast);
    if (personnelResultForecastTotal !== corporateResultForecastTotal + salesResultForecastTotal) {
      setHasErrorPersonnelGridNotMatchTotal(true);
      return true;
    }
    const corporateYearPlanTotal = isNaN(Number(corporateGridTotal['yearPlan'])) ? 0 : Number(corporateGridTotal['yearPlan']);
    const salesYearPlanTotal = isNaN(Number(salesGridTotal['yearPlan'])) ? 0 : Number(salesGridTotal['yearPlan']);
    const personnelYearPlanTotal = isNaN(Number(personnelEmploymentTotal.yearPlan)) ? 0 : Number(personnelEmploymentTotal.yearPlan);
    if (personnelYearPlanTotal !== corporateYearPlanTotal + salesYearPlanTotal) {
      setHasErrorPersonnelGridNotMatchTotal(true);
      return true;
    }
    const corporateMiddlePlanTotal = isNaN(Number(corporateGridTotal['midTermPlan'])) ? 0 : Number(corporateGridTotal['midTermPlan']);
    const salesMiddlePlanTotal = isNaN(Number(salesGridTotal['midTermPlan'])) ? 0 : Number(salesGridTotal['midTermPlan']);
    const personnelMiddlePlanTotal = isNaN(Number(personnelEmploymentTotal.midTermPlan)) ? 0 : Number(personnelEmploymentTotal.midTermPlan);
    if (personnelMiddlePlanTotal !== corporateMiddlePlanTotal + salesMiddlePlanTotal) {
      setHasErrorPersonnelGridNotMatchTotal(true);
      return true;
    }
    setHasErrorPersonnelGridNotMatchTotal(false);
    return false;
  };

  //-------------------------------------------------------------
  // コーポレート人員グリッド合計値チェック
  //-------------------------------------------------------------
  const isCorporateGridTotalOverError = (): boolean => {
    const corporateResultForecastTotal = isNaN(Number(corporateGridTotal['resultForecast'])) ? 0 : Number(corporateGridTotal['resultForecast']);
    const personnelEmploymentResultForecastTotal = isNaN(Number(personnelEmploymentTotal['resultForecast']))
      ? 0
      : Number(personnelEmploymentTotal['resultForecast']);
    if (personnelEmploymentResultForecastTotal < corporateResultForecastTotal) {
      setHasErrorPersonnelCorporateGridTotalOver(true);
      return true;
    }
    const corporateYearPlanTotal = isNaN(Number(corporateGridTotal['yearPlan'])) ? 0 : Number(corporateGridTotal['yearPlan']);
    const personnelEmploymentYearPlanTotal = isNaN(Number(personnelEmploymentTotal['yearPlan'])) ? 0 : Number(personnelEmploymentTotal['yearPlan']);
    if (personnelEmploymentYearPlanTotal < corporateYearPlanTotal) {
      setHasErrorPersonnelCorporateGridTotalOver(true);
      return true;
    }
    const corporateMiddlePlanTotal = isNaN(Number(corporateGridTotal['midTermPlan'])) ? 0 : Number(corporateGridTotal['midTermPlan']);
    const personnelEmploymentMidTermPlanTotal = isNaN(Number(personnelEmploymentTotal['midTermPlan']))
      ? 0
      : Number(personnelEmploymentTotal['midTermPlan']);
    if (personnelEmploymentMidTermPlanTotal < corporateMiddlePlanTotal) {
      setHasErrorPersonnelCorporateGridTotalOver(true);
      return true;
    }
    setHasErrorPersonnelCorporateGridTotalOver(false);
    return false;
  };

  //-------------------------------------------------------------
  // 営業人員グリッド合計値チェック
  //-------------------------------------------------------------
  const isSalesGridTotalOverError = (): boolean => {
    const salesResultForecastTotal = isNaN(Number(salesGridTotal['resultForecast'])) ? 0 : Number(salesGridTotal['resultForecast']);
    const personnelEmploymentResultForecastTotal = isNaN(Number(personnelEmploymentTotal['resultForecast']))
      ? 0
      : Number(personnelEmploymentTotal['resultForecast']);
    if (personnelEmploymentResultForecastTotal < salesResultForecastTotal) {
      setHasErrorPersonnelSalesGridTotalOver(true);
      return true;
    }
    const salesYearPlanTotal = isNaN(Number(salesGridTotal['yearPlan'])) ? 0 : Number(salesGridTotal['yearPlan']);
    const personnelEmploymentYearPlanTotal = isNaN(Number(personnelEmploymentTotal['yearPlan'])) ? 0 : Number(personnelEmploymentTotal['yearPlan']);
    if (personnelEmploymentYearPlanTotal < salesYearPlanTotal) {
      setHasErrorPersonnelSalesGridTotalOver(true);
      return true;
    }
    const corporateMiddlePlanTotal = isNaN(Number(salesGridTotal['midTermPlan'])) ? 0 : Number(salesGridTotal['midTermPlan']);
    const personnelEmploymentMidTermPlanTotal = isNaN(Number(personnelEmploymentTotal['midTermPlan']))
      ? 0
      : Number(personnelEmploymentTotal['midTermPlan']);
    if (personnelEmploymentMidTermPlanTotal < corporateMiddlePlanTotal) {
      setHasErrorPersonnelSalesGridTotalOver(true);
      return true;
    }
    setHasErrorPersonnelSalesGridTotalOver(false);
    return false;
  };

  //-------------------------------------------------------------
  // 「その他」詳細入力チェック
  //-------------------------------------------------------------
  const shouldOtherDetailTextFormInput = (): boolean => {
    const tempOtherValue =
      personnelCorporateRowDataEdit !== undefined ? personnelCorporateRowDataEdit.filter((x: any) => x.id === 'Other') : undefined;
    const otherYearPlanValue = undefined !== tempOtherValue ? tempOtherValue[0].yearPlan : 0;
    const otherDetail = personnelCorporateOtherFormTextValueEdit;
    const checkYearPlanValue = 100;
    if (otherYearPlanValue !== undefined) {
      // 「その他」の年計が100を超えているか？
      if (otherYearPlanValue >= checkYearPlanValue) {
        // 「その他」詳細が記入してあるか？
        if (otherDetail) {
          setHasErrorOtherTextForm(false);
        } else {
          setHasErrorOtherTextForm(true);
          return true;
        }
      } else {
        setHasErrorOtherTextForm(false);
      }
    } else {
      setHasErrorOtherTextForm(false);
    }
    return false;
  };

  //-------------------------------------------------------------
  // 重点分野別 グリッド合計値チェック
  //-------------------------------------------------------------
  const isPriorityDomainTotalOverError = (
    // 重点分野の合計値
    totalRowData: EnqueteAnswerInputEntity,
    // 雇用形態別で入力されたデータ
    personnelEmploymentRowData: EnqueteAnswerLoadInputEntity,
  ): boolean => {
    // 入力された実見の合計
    const resultForecastTotal = isNaN(Number(totalRowData['resultForecast'])) ? 0 : Number(totalRowData['resultForecast']);
    // 人件費人員（雇用形態別）で入力した実見
    const personnelEmploymentResultForecast = isNaN(Number(personnelEmploymentRowData['resultForecast']))
      ? 0
      : Number(personnelEmploymentRowData['resultForecast']);
    // 合計値が人件費人員（雇用形態別）と違った場合
    if (resultForecastTotal !== personnelEmploymentResultForecast) {
      // エラーになる
      return true;
    }
    // 入力された年計の合計
    const yearPlanTotal = isNaN(Number(totalRowData['yearPlan'])) ? 0 : Number(totalRowData['yearPlan']);
    // 人件費人員（雇用形態別）で入力した年計
    const personnelEmploymentYearPlan = isNaN(Number(personnelEmploymentRowData['yearPlan'])) ? 0 : Number(personnelEmploymentRowData['yearPlan']);
    // 合計値が人件費人員（雇用形態別）と違った場合
    if (yearPlanTotal !== personnelEmploymentYearPlan) {
      // エラーになる
      return true;
    }
    // 入力された中計の合計
    const middlePlanTotal = isNaN(Number(totalRowData['midTermPlan'])) ? 0 : Number(totalRowData['midTermPlan']);
    // 人件費人員（雇用形態別）で入力した中計
    const personnelEmploymentMidTermPlan = isNaN(Number(personnelEmploymentRowData['midTermPlan']))
      ? 0
      : Number(personnelEmploymentRowData['midTermPlan']);
    // 合計値が人件費人員（雇用形態別）のと違った場合
    if (middlePlanTotal !== personnelEmploymentMidTermPlan) {
      // エラーになる
      return true;
    }
    // エラーがない
    return false;
  };

  const submitHandler = () => {
    const answerJsonData = createData();
    //-------------------------------------------------------------
    // エラーチェック：人件費人員(雇用形態別)
    //-------------------------------------------------------------
    let tempNotErrorPersonnelEmploymentTab = true;
    let tempPersonnelEmploymentResultAndPlanDiffForm = false;
    let tempPersonnelEmploymentPlanAndPlanDiffForm = false;

    if (personnelEmploymentResultAndPlanDiffFormEdit !== undefined) {
      setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(false);
      tempPersonnelEmploymentResultAndPlanDiffForm = isEmptyCheckDiffInputValue(personnelEmploymentResultAndPlanDiffFormEdit);
      if (tempPersonnelEmploymentResultAndPlanDiffForm) {
        setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(true);
      }
    }
    if (personnelEmploymentPlanAndPlanDiffFormEdit) {
      setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(false);
      tempPersonnelEmploymentPlanAndPlanDiffForm = isEmptyCheckDiffInputValue(personnelEmploymentPlanAndPlanDiffFormEdit);
      if (tempPersonnelEmploymentPlanAndPlanDiffForm) {
        setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(true);
      }
    }
    if (
      hasErrorPersonnelEmploymentResultAndPlanDiffForm ||
      hasErrorPersonnelEmploymentPlanAndPlanDiffForm ||
      tempPersonnelEmploymentResultAndPlanDiffForm ||
      tempPersonnelEmploymentPlanAndPlanDiffForm
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_1'), { variant: 'error' });
      tempNotErrorPersonnelEmploymentTab = false;
    }
    //-------------------------------------------------------------
    // エラーチェック：人件費人員(機能別)
    //-------------------------------------------------------------
    let tempNotErrorPersonnelCorporateTab = true;

    // 人件費人員(雇用形態別)Total === コーポレート人員Total + 営業人員Total をチェック
    if (isGridTotalError()) {
      setHasErrorPersonnelGridNotMatchTotal(true);
    }
    if (isCorporateGridTotalOverError()) {
      tempNotErrorPersonnelCorporateTab = false;
    }
    if (isSalesGridTotalOverError()) {
      tempNotErrorPersonnelCorporateTab = false;
    }
    // その他詳細の入力チェック：人件費人員(機能別)
    if (shouldOtherDetailTextFormInput()) {
      tempNotErrorPersonnelCorporateTab = false;
    }

    if (!tempNotErrorPersonnelCorporateTab) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_2'), { variant: 'error' });
    }
    //-------------------------------------------------------------
    // エラーチェック：人件費人員（重点分野別）
    //-------------------------------------------------------------
    // 人件費人員（重点分野別）：グリッドにエラー無しフラグ
    let tempNotErrorPersonnelPriorityDomainTab = true;
    // 人件費人員（重点分野別）：年計-実見入力内容のエラーフラグ
    let tempPersonnelPriorityDomainResultAndPlanDiffForm = false;
    // 人件費人員（重点分野別）：中計-年計入力内容のエラーフラグ
    let tempPersonnelPriorityDomainPlanAndPlanDiffForm = false;
    // ディフォルト値
    let personnelEmploymentRowData: EnqueteAnswerLoadInputEntity = { id: 'rowData' };
    // 人件費人員(重点分野)正社員の合計チェック
    const hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainRegularEmployeeGridTotal,
      personnelEmploymentRowDataEdit !== undefined ? personnelEmploymentRowDataEdit[0] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)正社員のエラー状態を設定する
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal(hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal);
    // 人件費人員(重点分野)TTCからの出向受入の合計チェック
    const hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainSecondmentFromTTCGridTotal,
      personnelEmploymentRowDataEdit !== undefined ? personnelEmploymentRowDataEdit[1] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)TTCからの出向受入のエラー状態を設定する
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal(hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal);
    // 人件費人員(重点分野)2以外の出向受入の合計チェック
    const hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainSecondmentFromOtherGridTotal,
      personnelEmploymentRowDataEdit !== undefined ? personnelEmploymentRowDataEdit[2] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)2以外の出向受入のエラー状態を設定する
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal(hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal);
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトの合計チェック
    const hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainDispatchEtcGridTotal,
      personnelEmploymentRowDataEdit !== undefined ? personnelEmploymentRowDataEdit[3] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトのエラー状態を設定する
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal(hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal);
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトの合計チェック
    // 人件費人員（重点分野別）に人員合計と（参考）人件費人員（雇用形態別）で入力した人件費に紐づく人員数が同じではないタブがある場合
    if (
      hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal ||
      hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal ||
      hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal ||
      hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal
    ) {
      // 人件費人員（重点分野別）：グリッドにエラーがある
      tempNotErrorPersonnelPriorityDomainTab = false;
    }
    if (personnelPriorityDomainResultAndPlanDiffFormEdit !== undefined) {
      // データ全部入力されているチェック
      tempPersonnelPriorityDomainResultAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(personnelPriorityDomainResultAndPlanDiffFormEdit);
      // エラーがある場合
      if (tempPersonnelPriorityDomainResultAndPlanDiffForm) {
        // データ全部入力されていない
        setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(true);
      } else {
        // データ全部入力されている
        setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(false);
      }
    }
    if (personnelPriorityDomainPlanAndPlanDiffFormEdit !== undefined) {
      // データ全部入力されているチェック
      tempPersonnelPriorityDomainPlanAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(personnelPriorityDomainPlanAndPlanDiffFormEdit);
      // エラーがある場合
      if (tempPersonnelPriorityDomainPlanAndPlanDiffForm) {
        // データ全部入力されていない
        setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(true);
      } else {
        // データ全部入力されている
        setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(false);
      }
    }
    if (
      hasErrorPersonnelPriorityDomainResultAndPlanDiffForm ||
      hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm ||
      tempPersonnelPriorityDomainResultAndPlanDiffForm ||
      tempPersonnelPriorityDomainPlanAndPlanDiffForm ||
      !tempNotErrorPersonnelPriorityDomainTab
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_4'), { variant: 'error' });
      // グリッドにエラーがある
      tempNotErrorPersonnelPriorityDomainTab = false;
    }
    //-------------------------------------------------------------
    // エラーチェック：製造原価人員（雇用形態別）
    //-------------------------------------------------------------
    let tempNotErrorManufyearPlanurEmploymentTab = true;
    let tempManufyearPlanurEmploymentResultAndPlanDiffForm = false;
    let tempManufyearPlanurEmploymentPlanAndPlanDiffForm = false;

    if (manufyearPlanurEmploymentResultAndPlanDiffFormEdit !== undefined) {
      setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm(false);
      tempManufyearPlanurEmploymentResultAndPlanDiffForm = isEmptyCheckDiffInputValue(manufyearPlanurEmploymentResultAndPlanDiffFormEdit);
      if (tempManufyearPlanurEmploymentResultAndPlanDiffForm) {
        setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm(true);
      }
    }
    if (manufyearPlanurEmploymentPlanAndPlanDiffFormEdit !== undefined) {
      setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(false);
      tempManufyearPlanurEmploymentPlanAndPlanDiffForm = isEmptyCheckDiffInputValue(manufyearPlanurEmploymentPlanAndPlanDiffFormEdit);
      if (tempManufyearPlanurEmploymentPlanAndPlanDiffForm) {
        setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(true);
      }
    }

    if (
      hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm ||
      hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm ||
      tempManufyearPlanurEmploymentResultAndPlanDiffForm ||
      tempManufyearPlanurEmploymentPlanAndPlanDiffForm
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_3'), { variant: 'error' });
      tempNotErrorManufyearPlanurEmploymentTab = false;
    }

    //-------------------------------------------------------------
    // エラーチェック：製造原価人員（重点分野別）
    //-------------------------------------------------------------
    // 製造原価人員（重点分野別）：グリッドにエラー無しフラグ
    let tempNotErrorManufyearPlanurPriorityDomainTab = true;
    // 製造原価人員（重点分野別）：年計-実見入力内容のエラーフラグ
    let tempManufyearPlanurPriorityDomainResultAndPlanDiffForm = false;
    // 製造原価人員（重点分野別）：中計-年計入力内容のエラーフラグ
    let tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm = false;
    // ディフォルト値
    let manufyearPlanurEmploymentStatusRowData: EnqueteAnswerLoadInputEntity = { id: 'rowData' };
    // 製造原価人員(重点分野)正社員の合計チェック
    const hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal = isPriorityDomainTotalOverError(
      manufyearPlanurPriorityDomainRegularEmployeeGridTotal,
      manufyearPlanurEmploymentRowDataEdit !== undefined ? manufyearPlanurEmploymentRowDataEdit[0] : manufyearPlanurEmploymentStatusRowData,
    );
    // 製造原価人員(重点分野)正社員のエラー状態を設定する
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal(hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal);
    // 製造原価人員(重点分野)1以外の従業員/派遣/その他の合計チェック
    const hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal = isPriorityDomainTotalOverError(
      manufyearPlanurPriorityDomainOtherEmployeesGridTotal,
      manufyearPlanurEmploymentRowDataEdit !== undefined ? manufyearPlanurEmploymentRowDataEdit[1] : manufyearPlanurEmploymentStatusRowData,
    );
    // 製造原価人員(重点分野)正社員のエラー状態を設定する
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal(hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal);
    // 製造原価人員(重点分野別)に人員合計と（参考）製造原価人員（雇用形態別）で入力した人件費に紐づく人員数が同じではないタブがある場合
    if (
      hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal ||
      hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal
    ) {
      // 製造原価人員（重点分野別）：グリッドにエラーがある
      tempNotErrorManufyearPlanurPriorityDomainTab = false;
    }
    if (manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit !== undefined) {
      // データ全部入力されているチェック
      tempManufyearPlanurPriorityDomainResultAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(
        manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit,
      );
      // エラーがある場合
      if (tempManufyearPlanurPriorityDomainResultAndPlanDiffForm) {
        // データ全部入力されていない
        setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(true);
      } else {
        // データ全部入力されている
        setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(false);
      }
    }
    if (manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit !== undefined) {
      // データ全部入力されているチェック
      tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(
        manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit,
      );
      // エラーがある場合
      if (tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm) {
        // データ全部入力されていない
        setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(true);
      } else {
        // データ全部入力されている
        setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(false);
      }
    }
    if (
      hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm ||
      hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm ||
      tempManufyearPlanurPriorityDomainResultAndPlanDiffForm ||
      tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm ||
      !tempNotErrorManufyearPlanurPriorityDomainTab
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_5'), { variant: 'error' });
      // グリッドにエラーがある
      tempNotErrorManufyearPlanurPriorityDomainTab = false;
    }
    const enqueteAnswerId = enqueteAnswerData?.id;

    if (userInfo === undefined) return;
    let params: EnqueteAnswerEntity = {
      userId: userId,
      userName: userInfo?.fullNameJpn,
      userNameEng: userInfo?.fullNameEng,
      status: 'ANSWERED',
      respondentTargetId: respondentTargetId ? respondentTargetId : '',
      answerData: answerJsonData,
      isDeleted: false,
    };

    // エラーが無い場合に保存処理を行う
    if (
      tempNotErrorPersonnelEmploymentTab &&
      tempNotErrorPersonnelCorporateTab &&
      tempNotErrorManufyearPlanurEmploymentTab &&
      tempNotErrorPersonnelPriorityDomainTab &&
      tempNotErrorManufyearPlanurPriorityDomainTab
    ) {
      if (enqueteAnswerId) {
        // update
        updateAnswer(params, enqueteAnswerId);
      } else {
        // create
        saveAnswer(params);
      }
      return true;
    } else {
      return false;
    }
  };
  return { submitHandler };
};
