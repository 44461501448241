import { Stack } from '@material-ui/core';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { EnqueteInfoForm } from '../../../tab-containers/shared-tabs/enquete-status-list/components/EnqueteInfoForm';
import { EnqueteStatusFormTypeSwitcher } from './EnqueteStatusFormTypeSwitcher';
import { useEnqueteInfoData } from './hooks/useEnqueteInfoData';
export interface EnqueteStatusListPageLayoutProps {
  pageTitle: string;
  breadCrumbs: HeaderBreadcrumbsProps;
}

export const EnqueteStatusListPageLayout: React.FC<EnqueteStatusListPageLayoutProps> = (props) => {
  const { t } = useTranslation();
  const { isLoading, isAllowed, surveyDetailId, respondentRequestId, enqueteInfoData, refreshData } = useEnqueteInfoData();

  const render = () => {
    if (isLoading) {
      return <StyledLoading isOpen={isLoading} />;
    } else if (!isAllowed) {
      return (
        <Typography variant={'h4'} sx={{ mt: 3, textAlign: 'center' }}>
          {t('enqueteStatus.message.haveNotSurveyOwnerAuthority')}
        </Typography>
      );
    } else if (surveyDetailId && enqueteInfoData && enqueteInfoData?.requestedCount) {
      return (
        <Stack direction="column" spacing={2}>
          <EnqueteInfoForm
            respondentRequestId={respondentRequestId ?? null}
            surveyDetailId={surveyDetailId}
            enqueteInfoData={enqueteInfoData}
            refreshData={refreshData}
          />
          <EnqueteStatusFormTypeSwitcher formType={enqueteInfoData.formType} />
        </Stack>
      );
    } else {
      return (
        <Typography variant={'h4'} sx={{ mt: 3, textAlign: 'center' }}>
          {t('enqueteStatus.message.haveNotAnswerRequest')}
        </Typography>
      );
    }
  };

  return (
    <PageLayout title={t('enqueteStatus.pageTitle')} breadcrumbs={props.breadCrumbs}>
      {render()}
    </PageLayout>
  );
};
