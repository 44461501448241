import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { ErrorTooltip } from 'src/components/app-components/StyledTooltip';
import { AnswerStatus, ANSWER_STATUS, FORM_TYPE } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/store';
import { useCreateSeriesAnswerData } from '../hooks/useCreateSeriesAnswerData';
import useEnqueteAnswerSave from '../hooks/useEnqueteAnswerSave';
import { SwitchReversionModalLayout } from './common/components/SwitchReversionModalLayout';
import { REVERSION, REVERSION_REQUEST } from './common/constants';
import { useReturnedHandleClick } from './common/hooks/useReturnedHandleClick';
export interface Props {
  respondentTargetId: string;
  confirmRequestId: string | null;
}

export const SeriesSurveySaveButtons: React.FC<Props> = (props): React.ReactElement => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isAnswered, commonSaveHandler } = useEnqueteAnswerSave(props.respondentTargetId);

  const { confirmRequestData, enqueteAnswerData, isEnqueteAnswerOpen, surveyDetailData } = useAppSelector((state) => state.enqueteAnswer);
  const { hasError, currency } = useAppSelector((state) => state.seriesAnswer);

  const { isAdmin, isViewerAdmin, isSurveyOwner, isRespondentPerson, isAnswerReader, isConfirmor } = usePermission(
    surveyDetailData.id,
    props.respondentTargetId,
  );
  const isOnlyAnswerReader = isAnswerReader && !isSurveyOwner && !isAdmin && !isConfirmor && !isRespondentPerson;
  const isOnlyViewerAdmin = isViewerAdmin && !isSurveyOwner && !isAdmin && !isRespondentPerson;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleReversionType, isReversionRequest } = useReturnedHandleClick(setIsOpen);
  const userId = null !== user ? user.email : '';

  const marginTopValue = props.confirmRequestId && confirmRequestData.confirmerId === userId ? '20px' : '50px';
  const seriesAnswerData = useCreateSeriesAnswerData();
  const formType = surveyDetailData.surveyHeader?.formType;
  const cantToSubmission =
    !isEnqueteAnswerOpen || enqueteAnswerData.status === ANSWER_STATUS.ANSWERED || isOnlyAnswerReader || isConfirmor || isOnlyViewerAdmin;
  const submitHandler = async (answerStatus: AnswerStatus) => {
    await commonSaveHandler(seriesAnswerData, answerStatus);
  };

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ marginTop: marginTopValue }}>
        {(isSurveyOwner || isAdmin || isViewerAdmin) && (
          <StyledButton
            onClick={() => handleReversionType(REVERSION)}
            isDisabled={!isEnqueteAnswerOpen || enqueteAnswerData.status !== ANSWER_STATUS.ANSWERED || !(isSurveyOwner || isAdmin)}>
            {t('button.reversion')}
          </StyledButton>
        )}
        {(isRespondentPerson || isAdmin || isViewerAdmin) && (
          <StyledButton
            onClick={() => handleReversionType(REVERSION_REQUEST)}
            isDisabled={!isEnqueteAnswerOpen || enqueteAnswerData.status !== ANSWER_STATUS.ANSWERED || !(isRespondentPerson || isAdmin)}>
            {t('button.requestReversion')}
          </StyledButton>
        )}
        <StyledButton
          onClick={() => submitHandler(ANSWER_STATUS.SAVED)}
          isDisabled={isAnswered || isOnlyAnswerReader || isConfirmor || (formType === FORM_TYPE.SERIES && currency === '') || isOnlyViewerAdmin}>
          {t('button.temporarilySave')}
        </StyledButton>
        <ErrorTooltip title={t('message.invalid') ?? ''} open={!cantToSubmission && hasError} placement="top" color="secondary" arrow>
          <span>
            <StyledButton onClick={() => submitHandler(ANSWER_STATUS.ANSWERED)} isDisabled={cantToSubmission || hasError}>
              {t(enqueteAnswerData.status === ANSWER_STATUS.RETURNED ? 'button.resubmission' : 'button.submit')}
            </StyledButton>
          </span>
        </ErrorTooltip>
      </Stack>
      <SwitchReversionModalLayout isOpen={isOpen} setIsOpen={setIsOpen} isRequest={isReversionRequest} />
    </>
  );
};
