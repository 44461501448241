import type { MouseEvent } from 'react';
import { Box, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { ICellRendererParams } from 'ag-grid-community';

//小麦粉からコピーして作成
const OrderListContextMenu = (params: ICellRendererParams) => {


  const handleToggleContextMenu = (event: MouseEvent): void => {
    const { node, column, value } = params;
    // @ts-ignore because contextMenuFactory is private
    const enquete = params.api.contextMenuFactory;
    enquete.showMenu(node, column, value, event);
  };


  return (
    <Box sx={{ textAlign: 'center' }}>
      <IconButton
        onClick={handleToggleContextMenu}
      >
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
}

export default OrderListContextMenu;