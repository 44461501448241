import { InputAdornment, InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type RHFAmountFieldProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> =
  UseControllerProps<TFieldValues, TName> & {
    textFieldProps?: TextFieldProps;
    handleOnChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    startAdornment?: string;
    disabled?: boolean;
  };

/**
 * Input of react-number-format
 */
const AmountFormatCustom = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => {
  // 不要なプロパティを除くためdestructureする
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultValue, onChange, ...others } = props;

  return (
    <NumericFormat
      {...others}
      getInputRef={ref}
      thousandSeparator={true}
      defaultValue={defaultValue as number}
      onValueChange={(values, sourceInfo) => {
        if (!onChange || !sourceInfo.event) return;
        const event = {
          ...sourceInfo.event,
          target: {
            ...sourceInfo.event.target,
            name: props.name,
            value: values.value ?? null,
          },
        };
        onChange(event);
      }}
    />
  );
});

const RHFAmountField: <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  props: RHFAmountFieldProps<TFieldValues, TName>,
) => JSX.Element = (props) => {
  const { textFieldProps, defaultValue, handleOnChange, startAdornment, disabled, ...others } = props;

  return (
    <Controller
      defaultValue={defaultValue !== null ? defaultValue : undefined}
      name={others.name}
      control={others.control}
      rules={others.rules}
      render={({ field, fieldState }) => (
        <TextField
          {...textFieldProps}
          name={field.name}
          value={field.value}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(e) => {
            field.onChange(e);
            handleOnChange && handleOnChange(e);
          }}
          onBlur={field.onBlur}
          InputProps={{
            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            inputComponent: AmountFormatCustom,
            inputProps: {
              ...field,
              style: { textAlign: 'right' },
            },
          }}
          disabled={disabled}
        />
      )}
    />
  );
};

export default RHFAmountField;
