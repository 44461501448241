import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type CloseButtonType = {
  onClose: () => void;
  color: 'primary' | 'error';
  disabled?: boolean;
};

const CloseButton: React.FC<CloseButtonType> = (props) => {
  const { t } = useTranslation();
  const { onClose, color, disabled } = props;
  return (
    <Button variant="contained" onClick={onClose} color={color} disabled={disabled}>
      {t('button.close')}
    </Button>
  );
};

export default CloseButton;
