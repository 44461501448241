import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface CreateSurveyOwnerData {
  userId: string;
  bluePageEmailAddress: string | null;
}

export interface SurveyOwnerEntity {
  id: string;
  userId: string;
  bluePageEmailAddress: string | null;
  surveyDetailId: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
}

export const useSurveyOwnerApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * アンケート管理者検索用のAPI
   * @param surveyDetailId
   * @param userId
   * @returns
   */
  const get = async (surveyDetailId: string, userId?: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<SurveyOwnerEntity[]>(`${apiGateway}/survey-edit/survey-owners/${surveyDetailId}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        params: { userId },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.surveyOwner') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * surveyDetailIdによる検索
   * @param surveyDetailId
   * @returns
   */
  const getBySurveyDetailId = async (surveyDetailId: string) => {
    return await get(surveyDetailId);
  };

  /**
   * Uniqueキーによる検索
   * @param surveyDetailId
   * @param userId
   * @returns
   */
  const getByUnique = async (surveyDetailId: string, userId: string) => {
    return await get(surveyDetailId, userId);
  };

  /**
   * アンケート管理者追加用のAPI
   * @param surveyDetailId
   * @param data
   * @returns
   */
  const add = async (surveyDetailId: string, data: CreateSurveyOwnerData[]) => {
    try {
      const token = await getBearerToken();
      return await axios.post(apiGateway + `/survey-edit/survey-owners/${surveyDetailId}`, data, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('common.surveyOwner') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * アンケート管理者削除用のAPI
   * @param surveyDetailId
   * @param ownerId
   * @returns
   */
  const removeById = async (surveyDetailId: string, ownerId: string) => {
    try {
      const token = await getBearerToken();
      return await axios.delete(apiGateway + `/survey-edit/survey-owners/${surveyDetailId}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        params: {
          ownerId: ownerId,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedDelete', { target: t('common.surveyOwner') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getBySurveyDetailId,
    getByUnique,
    add,
    removeById,
  };
};
