import { createSlice } from '@reduxjs/toolkit';

const initialStates = {
  // 質問編集パネルの表示非表示制御フラグ
  isOpenEditor: false,
  // 質問編集モード（編集、削除、コピー）
  mode: '',
  // 質問編集時に選択した質問のID
  selectQuestionId: '',
};

export const generalPurposeQuestionPreviewEditSlice = createSlice({
  name: 'generalPurposeQuestionPreviewEdit',
  initialState: initialStates,
  reducers: {
    initializePreviewEdit: (state) => {
      state.isOpenEditor = initialStates.isOpenEditor;
      state.mode = initialStates.mode;
      state.selectQuestionId = initialStates.selectQuestionId;
    },
    setIsOpenEditor: (state, action) => {
      state.isOpenEditor = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    removeMode: (state) => {
      state.mode = '';
    },
    setSelectQuestionId: (state, action) => {
      state.selectQuestionId = action.payload;
    },
  },
});

export const { initializePreviewEdit, setIsOpenEditor, setMode, removeMode, setSelectQuestionId } = generalPurposeQuestionPreviewEditSlice.actions;
export default generalPurposeQuestionPreviewEditSlice.reducer;
