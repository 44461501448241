import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, TextFieldProps, Typography } from '@mui/material';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledBox } from 'src/components/app-components/StyledBox';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';
import RHFTextAutoComplete from 'src/components/react-hook-form/RHFTextAutoComplete';
import { AmountSourceType, CustomerStatus, DefaultValue } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useCalculateFiscalYear } from '../hooks/useCalculateFiscalYear';
import { useCustomerDelete } from '../hooks/useCustomerDelete';
import { useDebounceHandleChange } from '../hooks/useDebounceHandleChange';
import { useValidationRule } from '../hooks/useValidationRule';
import { SwitchLabel } from './util/SwitchLabel';
import {
  isAnswered,
  isDisabled,
  isNotCustomerExist,
  isPreAnswered,
  returnInputValue,
  toStringDeptAndCredit,
  toStringDiffPreYear,
  toStringForYoY,
} from './util/settingForTradingVolumeForm';

type Props = {
  customerCode: string;
  customerName: string;
  parentCustomerCode: string;
  region: string;
  country: string;
  purchasePreYear: number | null;
  purchaseCurrentYear: number | null;
  salesPreYear: number | null;
  salesCurrentYear: number | null;
  salesComment: string;
  purchaseComment: string;
  methods: UseFormReturn<FieldValues, any>;
  receivableBalance: number | null;
  payableBalance: number | null;
  previousSourceType: AmountSourceType;
  currentSourceType: AmountSourceType;
  customerStatus: CustomerStatus;
  isDisplay: boolean;
  defaultValue?: DefaultValue;
};

export const TradingVolumeForm: React.FC<Props> = (props) => {
  const {
    customerCode,
    customerName,
    parentCustomerCode,
    region,
    country,
    purchasePreYear,
    purchaseCurrentYear,
    salesPreYear,
    salesCurrentYear,
    salesComment,
    purchaseComment,
    methods,
    receivableBalance,
    payableBalance,
    previousSourceType,
    currentSourceType,
    customerStatus,
    isDisplay,
  } = props;
  const { t } = useTranslation();
  const { numericFieldValidationRule, commentFieldValidationRule } = useValidationRule();
  const { switchLabelForCustomerStatus, switchLabelForSourceType, switchLabelForReceivablePayable } = SwitchLabel();
  const { customerDelete } = useCustomerDelete();
  const { currentYear, previousYear } = useCalculateFiscalYear();
  const { debouncehandleChange, setCommentValue } = useDebounceHandleChange({
    customerCode,
    parentCustomerCode,
    methods,
  });
  const { themeMode } = useSettings();
  const options = [
    { key: 'newOrder', labelJpn: '新規受注・打切り(失注)', labelEng: 'NEW ORDER RECEIVED・ORDER CANCELLED' },
    { key: 'newProject', labelJpn: '新規プロジェクト・プロジェクト終了(撤退)', labelEng: 'NEW PROJECT・PROJECT CLOSED' },
    { key: 'business', labelJpn: '移管(社内・関係会社の窓口変更)', labelEng: 'BUSINESS TRANSFERRED (DEPARTMENT IN CHARGE CHANGED)' },
    { key: 'production', labelJpn: '生産変動', labelEng: 'PRODUCTION FLUCTUATION' },
    { key: 'others', labelJpn: 'その他', labelEng: 'OTHERS' },
  ];
  // 小数点以下桁数の取得
  const numberOfDecimalPlaces = useAppSelector((state) => state.seriesAnswer.numberOfDecimalPlaces);
  const makeTextFieldProps = (label: string, disabled: boolean) => {
    return { fullWidth: false, label, variant: 'outlined', disabled } as TextFieldProps;
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    customerDelete(event, parentCustomerCode);
    methods.unregister([
      `salesPreYear-${customerCode}`,
      `salesCurrentYear-${customerCode}`,
      `purchasePreYear-${customerCode}`,
      `purchaseCurrentYear-${customerCode}`,
      `salesComment-${customerCode}`,
      `purchaseComment-${customerCode}`,
    ]);
  };

  // 売上高、仕入れ高の値を更新
  const handleChangeVolumeAccount = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncehandleChange(e);
  };

  const isNotExist = isNotCustomerExist(customerStatus);
  const isPreAnsweredForPrevYear = isPreAnswered(previousSourceType);

  // inputFormのdisabledの設定
  const setIsDisabled = (isPrevYear: boolean, amount: number | null, sourceType?: AmountSourceType) => {
    return isDisabled(isPrevYear, customerStatus, amount, sourceType);
  };

  // バリーデーションの設定
  const setValidationRule = (isPrevYear: boolean, amount: number | null, sourceType?: AmountSourceType) => {
    const isDisable = isDisabled(isPrevYear, customerStatus, amount, sourceType);
    return numericFieldValidationRule(customerStatus, isDisable, sourceType);
  };

  // input Formに設定するvalueを返却する
  const setReturnInputValue = (amount: number | null, sourceType?: AmountSourceType) => {
    return returnInputValue(isNotExist, amount, sourceType);
  };

  const defaultCollar = themeMode === 'light' ? 'dark' : 'white';

  return (
    <StyledBox>
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item marginRight={0.5}>
            {isDisplay && switchLabelForReceivablePayable(payableBalance, receivableBalance, props.defaultValue)}
          </Grid>
          {(!isAnswered(previousSourceType) || !isAnswered(currentSourceType)) && (
            <>
              <Grid item>{switchLabelForSourceType(previousSourceType, currentSourceType)}</Grid>
              <Grid item sx={{ paddingTop: 0 }}>
                {isNotExist && switchLabelForCustomerStatus(customerStatus)}
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={5}>
          <Typography variant="subtitle2">
            {t('common.customer')}：{customerName}({customerCode})
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            {t('common.region')}：{region}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            {t('common.country')}：{country}
          </Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={1}>
          <Button
            color="error"
            onClick={handleDelete}
            variant={'contained'}
            value={[customerCode, parentCustomerCode]}
            disabled={isPreAnsweredForPrevYear}
            sx={{ justifyContent: 'center', m: 'auto' }}>
            <DeleteIcon />
          </Button>
        </Grid>
        {/* 前年度売上高 */}
        <Grid item xs={3}>
          <RHFNumericField
            textFieldProps={makeTextFieldProps(
              `FY${previousYear} ${t('enqueteAnswerInput.seriesSurvey.tradeVolume.salesAmount')}`,
              setIsDisabled(true, salesPreYear, previousSourceType),
            )}
            control={methods.control}
            name={`salesPreYear-${customerCode}`}
            key={`salesPreYear-${customerCode}`}
            defaultValue={setReturnInputValue(salesPreYear, previousSourceType)}
            handleOnChange={handleChangeVolumeAccount}
            rules={setValidationRule(true, salesPreYear, previousSourceType)}
          />
        </Grid>
        {/* 今年度売上高 */}
        <Grid item xs={3}>
          <RHFNumericField
            textFieldProps={makeTextFieldProps(
              `FY${currentYear} ${t('enqueteAnswerInput.seriesSurvey.tradeVolume.salesAmount')}`,
              setIsDisabled(false, salesCurrentYear, currentSourceType),
            )}
            control={methods.control}
            name={`salesCurrentYear-${customerCode}`}
            key={`salesCurrentYear-${customerCode}`}
            defaultValue={setReturnInputValue(salesCurrentYear, currentSourceType)}
            handleOnChange={handleChangeVolumeAccount}
            rules={setValidationRule(false, salesCurrentYear, currentSourceType)}
          />
        </Grid>
        {/* 売上高前年度差異 */}
        <Grid item xs={3.5} sx={{ display: 'flex' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.diffFromPreYear')}：
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ marginBottom: '20px', marginTop: '15px' }}
            color={(salesCurrentYear ?? 0) - (salesPreYear ?? 0) < 0 ? 'red' : defaultCollar}>
            {toStringDiffPreYear(salesCurrentYear, salesPreYear, numberOfDecimalPlaces)}
          </Typography>
        </Grid>
        {/* 売上高増減率 */}
        <Grid item xs={2.5} sx={{ display: 'flex' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.salesYoY')}：
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ marginBottom: '20px', marginTop: '15px' }}
            color={Math.ceil(((salesCurrentYear ?? 0) / (salesPreYear ?? 0) - 1) * 100) < 0 ? 'red' : defaultCollar}>
            {toStringForYoY(salesCurrentYear, salesPreYear)}%
          </Typography>
        </Grid>
        {isDisplay && (
          <>
            {/* 債権残高 */}
            <Grid item xs={3}>
              <RHFNumericField
                textFieldProps={makeTextFieldProps(
                  `FY${currentYear} ${t('enqueteAnswerInput.seriesSurvey.tradeVolume.receivableBalance')}`,
                  setIsDisabled(false, receivableBalance),
                )}
                control={methods.control}
                name={`receivableBalance-${customerCode}`}
                key={`receivableBalance-${customerCode}`}
                defaultValue={setReturnInputValue(receivableBalance)}
                handleOnChange={handleChangeVolumeAccount}
                rules={setValidationRule(false, receivableBalance)}
              />
            </Grid>
            {/* 債権回転日数 */}
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
                {t('enqueteAnswerInput.seriesSurvey.tradeVolume.debtorTurnoverDays')}：
              </Typography>
              <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
                {toStringDeptAndCredit(receivableBalance, salesCurrentYear)}
              </Typography>
            </Grid>
            <Grid item xs={6} />
          </>
        )}
        {/* 前年度仕入れ高 */}
        <Grid item xs={3}>
          <RHFNumericField
            textFieldProps={makeTextFieldProps(
              `FY${previousYear} ${t('enqueteAnswerInput.seriesSurvey.tradeVolume.purchaseAmount')}`,
              setIsDisabled(true, purchasePreYear, previousSourceType),
            )}
            control={methods.control}
            name={`purchasePreYear-${customerCode}`}
            key={`purchasePreYear-${customerCode}`}
            defaultValue={setReturnInputValue(purchasePreYear, previousSourceType)}
            handleOnChange={handleChangeVolumeAccount}
            rules={setValidationRule(true, purchasePreYear, previousSourceType)}
          />
        </Grid>
        {/* 今年度仕入れ高 */}
        <Grid item xs={3}>
          <RHFNumericField
            textFieldProps={makeTextFieldProps(
              `FY${currentYear} ${t('enqueteAnswerInput.seriesSurvey.tradeVolume.purchaseAmount')}`,
              setIsDisabled(false, purchaseCurrentYear, currentSourceType),
            )}
            control={methods.control}
            name={`purchaseCurrentYear-${customerCode}`}
            key={`purchaseCurrentYear-${customerCode}`}
            defaultValue={setReturnInputValue(purchaseCurrentYear, currentSourceType)}
            handleOnChange={handleChangeVolumeAccount}
            rules={setValidationRule(false, purchaseCurrentYear, currentSourceType)}
          />
        </Grid>
        {/* 仕入れ高前年度差異 */}
        <Grid item xs={3.5} sx={{ display: 'flex' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.diffFromPreYear')}：
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ marginBottom: '20px', marginTop: '15px' }}
            color={(purchaseCurrentYear ?? 0) - (purchasePreYear ?? 0) < 0 ? 'red' : defaultCollar}>
            {toStringDiffPreYear(purchaseCurrentYear, purchasePreYear, numberOfDecimalPlaces)}
          </Typography>
        </Grid>
        {/* 仕入高高増減率 */}
        <Grid item xs={2.5} sx={{ display: 'flex' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.purchaseYoY')}：
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ marginBottom: '20px', marginTop: '15px' }}
            color={Math.ceil(((purchaseCurrentYear ?? 0) / (purchasePreYear ?? 0) - 1) * 100) < 0 ? 'red' : defaultCollar}>
            {toStringForYoY(purchaseCurrentYear, purchasePreYear)}%
          </Typography>
        </Grid>
        {isDisplay && (
          <>
            {/* 債務残高 */}
            <Grid item xs={3}>
              <RHFNumericField
                textFieldProps={makeTextFieldProps(
                  `FY${currentYear} ${t('enqueteAnswerInput.seriesSurvey.tradeVolume.payableBalance')}`,
                  setIsDisabled(false, payableBalance),
                )}
                control={methods.control}
                name={`payableBalance-${customerCode}`}
                key={`payableBalance-${customerCode}`}
                defaultValue={setReturnInputValue(payableBalance)}
                handleOnChange={handleChangeVolumeAccount}
                rules={setValidationRule(false, payableBalance)}
              />
            </Grid>
            {/* 債務回転日数 */}
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
                {t('enqueteAnswerInput.seriesSurvey.tradeVolume.creditorTurnoverDays')}：
              </Typography>
              <Typography variant="subtitle2" sx={{ marginBottom: '20px', marginTop: '15px' }}>
                {toStringDeptAndCredit(payableBalance, purchaseCurrentYear)}
              </Typography>
            </Grid>
            <Grid item xs={6} />
          </>
        )}
        {/* 売上高コメント */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mt: '16px', mb: '8px' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.salesComment')}：
          </Typography>
          <RHFTextAutoComplete
            defaultValue={salesComment}
            methods={methods}
            name={`salesComment-${customerCode}`}
            key={`salesComment-${customerCode}`}
            label={t('enqueteAnswerInput.seriesSurvey.tradeVolume.commentPlaceholder')}
            disabled={isNotExist}
            options={options}
            rules={commentFieldValidationRule(salesCurrentYear, salesPreYear)}
            settingGlobalState={setCommentValue}
          />
        </Grid>
        {/* 仕入高コメント */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mt: '16px', mb: '8px' }}>
            {t('enqueteAnswerInput.seriesSurvey.tradeVolume.purchaseComment')}：
          </Typography>
          <RHFTextAutoComplete
            defaultValue={purchaseComment}
            methods={methods}
            name={`purchaseComment-${customerCode}`}
            key={`purchaseComment-${customerCode}`}
            label={t('enqueteAnswerInput.seriesSurvey.tradeVolume.commentPlaceholder')}
            disabled={isNotExist}
            options={options}
            rules={commentFieldValidationRule(purchaseCurrentYear, purchasePreYear)}
            settingGlobalState={setCommentValue}
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
};
