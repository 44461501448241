import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { useAppSelector } from 'src/redux/store';
import { ConfirmDialog } from '../../../../components/app-components/bonsai/ConfirmDialog';
import { ENQUETE_CONFIRM_PATH } from '../../enquete-confirm/routes/path';
import { ENQUETE_REQUEST_PATH } from '../../enquete-request/routes/path';
import { ENQUETE_STATUS_PATH } from '../../enquete-status/routes/path';
import { EnqueteAnswerInputLayout } from '../components/pages/input/components/EnqueteAnswerInputLayout';
import { ENQUETE_ANSWER_PATH } from '../routes/path';

export const EnqueteAnswerPage = () => {
  const { t } = useTranslation();
  const title = t('enqueteAnswerInput.pageTitle');
  const search = useLocation().search;
  const confirmRequestId = new URLSearchParams(search).get('confirmRequestId');
  const respondentRequestId = new URLSearchParams(search).get('respondentRequestId');
  const surveyDetailId = new URLSearchParams(search).get('surveyDetailId');

  // アンケートが編集されているかを取得
  const hasEdited = useAppSelector((state) => state.enqueteAnswer.hasEdited);

  const getPreviousBreadCrumbs = () => {
    let breadCrumbs = [];
    // Topをパンくずに仕込む
    breadCrumbs.push({ name: 'TOP', href: '/top' });
    // confirmRequestId/respondentRequestIdの有無でオブジェクト変更
    if (confirmRequestId !== null) {
      breadCrumbs.push({ name: t('enqueteConfirm.pageTitle'), href: ENQUETE_CONFIRM_PATH.INDEX });
    } else if (respondentRequestId !== null) {
      breadCrumbs.push(
        { name: t('enqueteRequest.pageTitle'), href: ENQUETE_REQUEST_PATH.INDEX },
        { name: t('enqueteStatus.pageTitle'), href: ENQUETE_STATUS_PATH.INDEX + '/' + respondentRequestId + '?surveyDetailId=' + surveyDetailId },
      );
    } else {
      breadCrumbs.push({ name: t('enqueteAnswerList.pageTitle'), href: ENQUETE_ANSWER_PATH.INDEX });
    }
    return breadCrumbs;
  };

  const breadCrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: getPreviousBreadCrumbs(),
    activeLast: true,
  };
  return (
    <>
      <EnqueteAnswerInputLayout pageTitle={title} breadCrumbs={breadCrumbs} />
      <ConfirmDialog title={t('message.confirmToLeave')} messages={[t('message.confirmToLeave1'), t('message.confirmToLeave2')]} when={hasEdited} />
    </>
  );
};
