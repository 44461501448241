import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { enqueteAnswerSlice } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { enqueteGeneralAnswerSlice } from 'src/features/general/enquete-answer/store/enqueteGeneralAnswerSlice';
import { enqueteOverviewAnswerSlice } from 'src/features/general/enquete-answer/store/enqueteOverviewAnswerSlice';
import { selectConfirmerModalSlice } from 'src/features/general/enquete-answer/store/selectConfirmerModalSlice';
import { sendTextModalSlice } from 'src/features/general/enquete-answer/store/sendTextModalSlice';
import { seriesAnswerSlice } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { enqueteConfirmSlice } from 'src/features/general/enquete-confirm/store/enqueteConfirmSlice';
import { divisionRegionEditorSlice } from 'src/features/general/enquete-create/store/divisionRegionEditorSlice';
import { enqueteCreateSlice } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { enqueteInfoSlice } from 'src/features/general/enquete-create/store/enqueteInfoSlice';
import { enqueteOptionSlice } from 'src/features/general/enquete-create/store/enqueteOptionSlice';
import { respondentSettingSlice } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import { tableSettingSlice } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { temporaryOrganizationSlice } from 'src/features/general/enquete-create/store/temporaryOrganizationSlice';
import { enqueteRequestSlice } from 'src/features/general/enquete-request/store/enqueteRequestSlice';
import { enqueteStatusSlice } from 'src/features/general/enquete-status/store/enqueteStatusSlice';
import { overviewAggregateSupplementSlice } from 'src/features/general/enquete-status/store/overviewAggregateSupplementSlice';
import { seriesAggregateSupplementSlice } from 'src/features/general/enquete-status/store/seriesAggregateSupplementSlice';
import { enqueteCommentslice } from 'src/features/general/enquete-threads/store/enqueteCommentsSlice';
import { enqueteThreadCreateSlice } from 'src/features/general/enquete-threads/store/enqueteThreadCreateSlice';
import { enqueteThreadSlice } from 'src/features/general/enquete-threads/store/enqueteThreadSlice';
import { enqueteThreadsSlice } from 'src/features/general/enquete-threads/store/enqueteThreadsSlice';
import { accessRoleTypeSlice } from 'src/features/general/store/accessRoleTypeSlice';
import { generalPurposeQuestionPreviewEditSlice } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { complementaryOrganizationSlice } from 'src/features/manage/complementary-organization/stores/complementaryOrganizationSlice';
import { customerManagementSlice } from 'src/features/manage/customer-management/store/customerManagementSlice';
import { loginSlice } from 'src/redux/slices/loginSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  login: loginSlice.reducer,
  enqueteConfirm: enqueteConfirmSlice.reducer,
  enqueteAnswer: enqueteAnswerSlice.reducer,
  enqueteCreate: enqueteCreateSlice.reducer,
  enqueteInfo: enqueteInfoSlice.reducer,
  enqueteRequest: enqueteRequestSlice.reducer,
  enqueteStatus: enqueteStatusSlice.reducer,
  respondentSetting: respondentSettingSlice.reducer,
  enqueteGeneralAnswer: enqueteGeneralAnswerSlice.reducer,
  enqueteOverviewAnswer: enqueteOverviewAnswerSlice.reducer,
  enqueteOption: enqueteOptionSlice.reducer,
  generalPurposeQuestionPreviewEdit: generalPurposeQuestionPreviewEditSlice.reducer,
  tableSetting: tableSettingSlice.reducer,
  complementaryOrganization: complementaryOrganizationSlice.reducer,
  temporaryOrganization: temporaryOrganizationSlice.reducer,
  divisionRegionEditor: divisionRegionEditorSlice.reducer,
  selectConfirmerModal: selectConfirmerModalSlice.reducer,
  seriesAnswer: seriesAnswerSlice.reducer,
  accessRoleType: accessRoleTypeSlice.reducer,
  sendTextModal: sendTextModalSlice.reducer,
  customerManagement: customerManagementSlice.reducer,
  seriesAggregateSupplement: seriesAggregateSupplementSlice.reducer,
  overviewAggregateSupplement: overviewAggregateSupplementSlice.reducer,
  enqueteThreads: enqueteThreadsSlice.reducer,
  enqueteThread: enqueteThreadSlice.reducer,
  enqueteComments: enqueteCommentslice.reducer,
  enqueteThreadCreate: enqueteThreadCreateSlice.reducer,
});

export { rootPersistConfig, rootReducer };
