import { useEffect, useState } from 'react';
import { CountryEntity, useCountryApi } from 'src/api/useCountryApi';
import { useOrganizationApi } from 'src/api/useOrganizationApi';
import { useRankApi } from 'src/api/useRankApi';
import { RegionEntity, useRegionApi } from 'src/api/useRegionApi';

export type MultiLanguageTypes = {
  jpn: string | null;
  eng: string | null;
};

export type SearchOrganizationMultiConditionTypes = {
  key: string;
  label: MultiLanguageTypes;
};

export type SearchOrganizationAdditionalConditionTypes = {
  key: string;
  label: MultiLanguageTypes;
  id: string;
};

export type SearchOrganizationConditionTypes = {
  key: string;
  label: string;
};

interface Props {
  surveyDetailId: string;
  isOmitTemporaryOrganization?: boolean;
}

export const useSearchOrganizationConditions = (props: Props) => {
  const { surveyDetailId, isOmitTemporaryOrganization = false } = props;
  const { getOrganizationsByFilter } = useOrganizationApi();
  const { getOnlySetNumberCountryCode, getCountriesByRegionCode } = useCountryApi();
  const { getAll: getAllRegions, getRegions } = useRegionApi();
  const { getAll: getAllRanks } = useRankApi();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companies, setCompanies] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [divisions, setDivisions] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [sbus, setSbus] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [departments, setDepartments] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [groups, setGroups] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [countries, setCountries] = useState<SearchOrganizationMultiConditionTypes[]>([]);
  const [bonsaiRegions, setBonsaiRegions] = useState<SearchOrganizationMultiConditionTypes[]>([]);
  const [bonsaiCountries, setBonsaiCountries] = useState<SearchOrganizationMultiConditionTypes[]>([]);
  const [regions, setRegions] = useState<SearchOrganizationMultiConditionTypes[]>([]);
  const [ranks, setRanks] = useState<SearchOrganizationConditionTypes[]>([]);

  // 会社レベル情報取得
  const makeCompanies = async () => {
    if (!surveyDetailId) return;

    const whereCondition = { orgLevelType: 'COMPANY', isDeleted: false, isTemporary: isOmitTemporaryOrganization ? false : undefined };
    const companies = (await getOrganizationsByFilter(surveyDetailId, whereCondition)) ?? [];

    const conditions = companies.map((e) => {
      return {
        key: e.companyCode,
        label: { jpn: e.companyNameJpn, eng: e.companyNameEng },
        id: e.id,
      };
    });
    setCompanies(conditions);
  };

  // 本部レベル情報取得
  const makeDivisions = async () => {
    if (!surveyDetailId) return;

    const whereCondition = { orgLevelType: 'DIVISION', isDeleted: false, isTemporary: isOmitTemporaryOrganization ? false : undefined };
    const divisions = (await getOrganizationsByFilter(surveyDetailId, whereCondition)) ?? [];
    const conditions = divisions.map((e, i) => {
      return {
        key: e.divisionCode ?? '',
        label: { jpn: e.divisionNameJpn, eng: e.divisionNameEng },
        id: e.id,
      };
    });
    setDivisions(conditions);
  };

  // SBUレベル情報を取得
  const makeSbus = async (divisionCode?: string) => {
    if (!surveyDetailId) return;

    const whereCondition = {
      orgLevelType: 'SBU',
      isDeleted: false,
      isTemporary: isOmitTemporaryOrganization ? false : undefined,
      divisionCode: divisionCode ?? undefined,
    };

    const sbus = (await getOrganizationsByFilter(surveyDetailId, whereCondition)) ?? [];
    const conditions = sbus.map((e, i) => {
      return {
        key: e.sbuCode ?? '',
        label: { jpn: e.sbuNameJpn, eng: e.sbuNameEng },
        id: e.id,
      };
    });
    setSbus(conditions);
  };

  // 部レベル情報取得
  const makeDepartments = async (divisionCode?: string, sbuCode?: string) => {
    if (!surveyDetailId) return;

    const whereCondition = {
      orgLevelType: 'DEPARTMENT',
      isDeleted: false,
      isTemporary: isOmitTemporaryOrganization ? false : undefined,
      divisionCode: divisionCode ?? undefined,
      sbuCode: sbuCode ?? undefined,
    };

    const departments = (await getOrganizationsByFilter(surveyDetailId, whereCondition)) ?? [];
    const conditions = departments.map((e, i) => {
      return {
        key: e.departmentCode ?? '',
        label: { jpn: e.departmentNameJpn, eng: e.departmentNameEng },
        id: e.id,
      };
    });
    setDepartments(conditions);
  };

  // グループレベル情報取得
  const makeGroups = async (divisionCode?: string, sbuCode?: string, departmentCode?: string) => {
    if (!surveyDetailId) return;

    const whereCondition = {
      orgLevelType: 'GROUP',
      isDeleted: false,
      isTemporary: isOmitTemporaryOrganization ? false : undefined,
      divisionCode: divisionCode ?? undefined,
      sbuCode: sbuCode ?? undefined,
      departmentCode: departmentCode ?? undefined,
    };

    const groups = (await getOrganizationsByFilter(surveyDetailId, whereCondition)) ?? [];
    const conditions = groups.map((e, i) => {
      return {
        key: e.groupCode ?? '',
        label: { jpn: e.groupNameJpn, eng: e.groupNameEng },
        id: e.id,
      };
    });
    setGroups(conditions);
  };

  // Bonsai 極情報取得
  const makeBonsaiRegions = async () => {
    const regions = (await getRegions()) ?? [];
    const conditions = regions.map((e: RegionEntity) => {
      return {
        key: e.regionCode ?? '',
        label: { jpn: e.regionNameJpn, eng: e.regionNameEng },
      };
    });
    setBonsaiRegions(conditions);
  };

  // Bonsai 国情報取得
  const makeBonsaiCountries = async (regionCode?: string) => {
    const countries = (await getCountriesByRegionCode(regionCode)) ?? [];
    const conditions = countries.map((e: CountryEntity) => {
      return {
        key: e.countryCode ?? '',
        label: { jpn: e.countryNameJpn, eng: e.countryNameEng },
      };
    });
    setBonsaiCountries(conditions);
  };

  // Portal 極情報取得
  const makeRegions = async () => {
    const regions = (await getAllRegions()) ?? [];
    const conditions = regions.map((e, i) => {
      return {
        key: e.regionCode ?? '',
        label: { jpn: e.regionNameJpn, eng: e.regionNameEng },
      };
    });
    setRegions(conditions);
  };

  // Portal 国情報取得
  const makeCountries = async () => {
    const countries = (await getOnlySetNumberCountryCode()) ?? [];
    const conditions = countries.map((e, i) => {
      return {
        key: e.countryCode ?? '',
        label: { jpn: e.countryNameJpn, eng: e.countryNameEng },
      };
    });
    setCountries(conditions);
  };

  // 格付情報取得
  const makeRanks = async () => {
    const ranks = (await getAllRanks()) ?? [];
    const conditions = ranks
      .filter((v) => {
        return v.rank !== 'ZZ';
      })
      .map((e) => {
        return { key: e.rank, label: e.rank };
      });
    setRanks(conditions);
  };

  //初期化
  const init = async () => {
    await Promise.all([
      makeCompanies(),
      makeDivisions(),
      makeSbus(),
      makeDepartments(),
      makeGroups(),
      makeRanks(),
      makeRegions(),
      makeCountries(),
      makeBonsaiRegions(),
      makeBonsaiCountries(),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    companies,
    divisions,
    sbus,
    departments,
    groups,
    regions,
    countries,
    ranks,
    bonsaiRegions,
    bonsaiCountries,
    makeSbus,
    makeDepartments,
    makeGroups,
    makeBonsaiCountries,
  };
};
