import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { AggregateCustomer } from 'src/@types/seriesAggregateSupplement';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import AggregateSupplementCustomerChildren from './AggregateSupplementCustomerChildren';
import AggregateSupplementPanelHeader from './AggregateSupplementPanelHeader';

interface Props {
  type: 'division' | 'region';
  customers: AggregateCustomer[];
  methods: UseFormReturn<FieldValues, any>;
}

const AggregateSupplementTabByUnit: React.FC<Props> = (props) => {
  const { type, customers, methods } = props;
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const isDivision = type === 'division';

  const renderAggregateSupplementPanel = (item: AggregateCustomer, index: number) => (
    <div key={`aggregate-customer-${index}`}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{isJapanese ? trimStockCompany(item.customerNameJpn) : item.customerNameEng}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 4 }}>
          <AggregateSupplementPanelHeader
            previousSalesAggregate={item.previousSalesAggregate}
            previousPurchaseAggregate={item.previousPurchaseAggregate}
            currentSalesAggregate={item.currentSalesAggregate}
            currentPurchaseAggregate={item.currentPurchaseAggregate}
          />
          <AggregateSupplementCustomerChildren
            aggregateDetails={isDivision ? item.aggregateDivisions : item.aggregateRegions}
            methods={methods}
            index={index}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <Stack component="form" gap={2}>
      {customers.map((item, n) => renderAggregateSupplementPanel(item, n))}
    </Stack>
  );
};

export default AggregateSupplementTabByUnit;
