import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-enterprise';
import { useTranslation } from 'react-i18next';
import { FixedQuestions, OverviewDivisionAggregateSupplement } from 'src/@types/overviewAggregateSupplement';
import { AdditionalQuestion } from 'src/@types/overviewEnquete';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { currencyFormat } from 'src/utils/currencyFormat';
import { switchLabel } from 'src/utils/labelUtils';

export const useDivisionTabColdef = (aggregateSupplement: OverviewDivisionAggregateSupplement) => {
  const { isJapanese } = useLocales();
  const { t } = useTranslation();

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
  };

  const defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    width: 240,
  };

  /**
   * 固定項目の列定義を生成する
   * @param fixedQuestions 固定項目
   * @param fieldPrefix 固定項目の物理名
   * @returns 列定義配列
   */
  const genFixedQuestionsColdef = (fixedQuestions: FixedQuestions, fieldPrefix: string): ColDef[] => {
    const coldef: ColDef[] = [];
    if (fixedQuestions.product) {
      coldef.push({
        field: `${fieldPrefix}.product`,
        headerName: t('enqueteStatus.aggregateSupplement.aggrid.product'),
        columnGroupShow: 'open',
      });
    }
    if (fixedQuestions.mainCustomer) {
      coldef.push({
        field: `${fieldPrefix}.mainCustomer`,
        headerName: t('enqueteStatus.aggregateSupplement.aggrid.mainCustomer'),
        columnGroupShow: 'open',
      });
    }
    if (fixedQuestions.commercialFlow) {
      coldef.push({
        field: `${fieldPrefix}.commercialFlow`,
        headerName: t('enqueteStatus.aggregateSupplement.aggrid.commercialFlow'),
        columnGroupShow: 'open',
      });
    }
    return coldef;
  };

  /**
   * 追加項目の列定義を生成する
   * @param additionalQuestions 追加項目
   * @param fieldPrefix 追加項目の物理名
   * @returns 列定義配列
   */
  const genAdditionalItemColdef = (additionalQuestions: AdditionalQuestion[], fieldPrefix: string): ColDef[] => {
    return additionalQuestions.map<ColDef>((additionalQuestion, index) => ({
      headerName: isJapanese ? additionalQuestion.titleJpn : additionalQuestion.titleEng,
      field: `${fieldPrefix}.${index}.answer`,
      cellStyle: { whiteSpace: 'pre-wrap' },
      columnGroupShow: 'open',
    }));
  };

  // 買いの2段目の列
  const purchaseChildren: ColDef[] = [
    {
      field: 'purchaseFixedAmount',
      headerName: t('common.amount'),
      valueFormatter: (params) => (params.value ? currencyFormat(params.value) : ''),
      cellStyle: { textAlign: 'right' },
    },
  ];
  // 売りの2段目の列
  const salesChildren: ColDef[] = [
    {
      field: 'salesFixedAmount',
      headerName: t('common.amount'),
      valueFormatter: (params) => (params.value ? currencyFormat(params.value) : ''),
      cellStyle: { textAlign: 'right' },
    },
  ];
  // その他の2段目の列
  const otherChildren: ColDef[] = [];

  // 回答があれば項目を設定する
  if (aggregateSupplement.companyAggregateSupplements.length > 0) {
    const company = aggregateSupplement.companyAggregateSupplements[0];
    purchaseChildren.push(...genFixedQuestionsColdef(company.purchaseFixedQuestions, 'purchaseFixedQuestions'));
    purchaseChildren.push(...genAdditionalItemColdef(company.purchaseAdditionalQuestions, 'purchaseAdditionalQuestions'));
    salesChildren.push(...genFixedQuestionsColdef(company.salesFixedQuestions, 'salesFixedQuestions'));
    salesChildren.push(...genAdditionalItemColdef(company.salesAdditionalQuestions, 'salesAdditionalQuestions'));
    if (company.otherFixedTopic) {
      otherChildren.push({
        field: 'otherFixedTopic',
        headerName: t('enqueteStatus.aggregateSupplement.aggrid.topic'),
        cellStyle: { whiteSpace: 'pre-wrap' },
      });
    }
    otherChildren.push(...genAdditionalItemColdef(company.otherAdditionalQuestions, 'otherAdditionalQuestions'));
  }

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      pinned: 'left',
      lockPosition: 'left',
      width: 52,
    },
    {
      field: isJapanese ? 'sbuNameJpn' : 'sbuNameEng',
      headerName: t('common.sbuName'),
      sort: 'asc',
      pinned: 'left',
      valueGetter: (params): string => switchLabel(params.data.sbuNameJpn, params.data.sbuNameEng, isJapanese),
    },
    {
      field: isJapanese ? 'departmentNameJpn' : 'departmentNameEng',
      headerName: t('common.departmentName'),
      pinned: 'left',
      valueGetter: (params): string => switchLabel(params.data.departmentNameJpn, params.data.departmentNameEng, isJapanese),
    },
    {
      field: isJapanese ? 'companyNameJpn' : 'companyNameEng',
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.company'),
      valueFormatter: (params): string => (isJapanese ? trimStockCompany(params.value) : params.value),
      pinned: 'left',
      valueGetter: (params): string => switchLabel(params.data.companyNameJpn, params.data.companyNameEng, isJapanese),
    },
    {
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.purchase'),
      headerClass: 'purchase',
      headerTooltip: t('enqueteStatus.aggregateSupplement.aggrid.tooltip'),
      marryChildren: true,
      children: purchaseChildren,
    },
    {
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.sales'),
      headerClass: 'sales',
      headerTooltip: t('enqueteStatus.aggregateSupplement.aggrid.tooltip'),
      marryChildren: true,
      children: salesChildren,
    },
    {
      headerName: t('enqueteStatus.aggregateSupplement.aggrid.other'),
      headerClass: 'other',
      headerTooltip: t('enqueteStatus.aggregateSupplement.aggrid.tooltip'),
      marryChildren: true,
      children: otherChildren,
    },
  ];

  return {
    defaultColDef,
    colDef,
    gridOptions,
  };
};
