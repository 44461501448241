import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { FORM_TYPE, FormType, SURVEY_TYPE, SurveyType } from 'src/constants';
import { usePermission } from 'src/hooks/usePermission';
import { replaceURLWithHTMLLinks } from 'src/utils/replaceURLWithHTMLLinks';
import { ENQUETE_CREATE_PATH } from '../../../../../enquete-create/routes/path';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  enqueteType?: SurveyType | FormType;
}

export const AlertPersonalDataModal = (props: Props) => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const { isCreator } = usePermission();
  let createPath = ENQUETE_CREATE_PATH.CREATE;
  // アンケート作成種別を判断する
  if (props.enqueteType) {
    createPath += `/${props.enqueteType.toLowerCase()}`;
  } else if (isCreator) {
    createPath += `/${SURVEY_TYPE.GENERAL.toLowerCase()}`;
  } else {
    createPath += `/${FORM_TYPE.OVERVIEW.toLowerCase()}`;
  }

  const translationKey = 'enqueteRequest.alertPersonalDataModal';
  return (
    <StyledModal isOpen={props.isOpen} onCloseFunc={props.onClose} modalTitle={t(`${translationKey}.title`)}>
      <Typography sx={{ whiteSpace: 'pre-line' }}>{replaceURLWithHTMLLinks(t(`${translationKey}.warning`))}</Typography>
      <Box textAlign="center" sx={{ m: 2 }}>
        <StyledButton
          onClick={() => {
            nav(createPath);
          }}>
          {t('button.next')}
        </StyledButton>
      </Box>
    </StyledModal>
  );
};
