import { Autocomplete, TextField } from '@material-ui/core';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

type RHFSelectAutocompleteProps = {
  name: string;
  label: string;
  options: { key: string; label: string }[];
  defaultValue: { key: string; label: string };
  methods: UseFormReturn;
  rules: RegisterOptions;
  onChange?: (value: { key: string; label: string }) => void;
  disabled?: boolean;
};

const RHFSelectAutocomplete = ({ name, label, options, defaultValue, methods, rules, onChange, disabled }: RHFSelectAutocompleteProps) => {
  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          size="small"
          options={options}
          defaultValue={defaultValue ?? { key: '', label: '' }}
          value={methods.getValues(name) === undefined ? defaultValue : methods.getValues(name)}
          // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
          isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
          onChange={(_, newInputValue) => {
            methods.setValue(name, newInputValue);
            field.onChange(newInputValue);
            onChange && onChange(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" label={label} error={!!fieldState.error} helperText={fieldState.error?.message} />
          )}
          disabled={disabled}
        />
      )}
    />
  );
};

export default RHFSelectAutocomplete;
