import { RefObject, useEffect, useState } from 'react';

interface Options extends IntersectionObserverInit {}

export const useElementOnScreen = (ref: RefObject<Element>, options: Options): boolean => {
  const [isIntersectiong, setIsIntersectiong] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersectiong(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersectiong;
};
