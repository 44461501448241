import { Container, Grid, Typography } from '@mui/material';
import Page from 'src/components/Page';
import { NotificationModal } from 'src/components/app-components/bonsai/notification/components/NotificationModal';
import Snowfall from 'src/components/special/Snowfall';
import OverviewCard from '../components/OverviewCard';
import { useOnlyAnswerOverviewPage } from '../hooks/useOnlyAnswerOverviewPage';

export const OnlyAnswerOverviewPage: React.FC = () => {
  const { overviewCardList } = useOnlyAnswerOverviewPage();

  return (
    <Page title="TOP | BONSAI">
      <NotificationModal />
      <Snowfall />
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h3">
              TOP
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container justifyContent="space-between" style={{ padding: '10px' }}>
          <OverviewCard overviewCardList={overviewCardList} />
        </Grid>
      </Container>
    </Page>
  );
};
