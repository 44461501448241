import { EnqueteInfoEntity } from 'src/api/useEnqueteStatusPageApi';
import { TargetOrganization } from 'src/features/general/enquete-create/components/modals/change-respondent-target/hooks/useChangeRespondentModal';

export const getOrganizationString = (data: EnqueteInfoEntity | null, isLangJpn: boolean): string => {
  if (data == null) return '';
  return isLangJpn
    ? `${data.companyNameJpn ?? ''} ${data.divisionNameJpn ?? ''} ${data.sbuNameJpn ?? ''} ${data.departmentNameJpn ?? ''}`
    : `${data.companyNameEng ?? ''} ${data.divisionNameEng ?? ''} ${data.sbuNameEng ?? ''} ${data.departmentNameEng ?? ''}`;
};

export const getFullOrganizationString = (data: TargetOrganization): string => {
  if (!data) return '';
  return `${data.companyName ?? ''} ${data.divisionName ?? ''} ${data.sbuName ?? ''} ${data.departmentName ?? ''} ${data.groupName ?? ''}`;
};
