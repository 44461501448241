import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'src/constants';
import { getRequestStatus } from 'src/utils/getRequestStatus';

interface Permissions {
  isAdmin: boolean;
  isSurveyOwner: boolean;
}
export const enqueteStatusSlice = createSlice({
  name: 'enqueteStatus',
  initialState: {
    isLoading: false,
    updateCounter: 0,
    requestStatus: undefined as RequestStatus | undefined,
    isAdmin: false,
    isSurveyOwner: false,
  },
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    incrementUpdateCounter: (state) => {
      state.updateCounter++;
    },
    setRequestStatus: (state, action: PayloadAction<{ openedAt: string; closedAt: string }>) => {
      state.requestStatus = getRequestStatus(action.payload.openedAt, action.payload.closedAt);
    },
    setPermission(state, action: PayloadAction<Permissions>) {
      state.isAdmin = action.payload.isAdmin;
      state.isSurveyOwner = action.payload.isSurveyOwner;
    },
  },
});

export const { setIsLoading, incrementUpdateCounter, setRequestStatus, setPermission } = enqueteStatusSlice.actions;
