import { Container } from '@material-ui/core';

import Page from '../../components/Page';
import { HeaderBreadcrumbsProps } from '../HeaderBreadcrumbs';
import { PageLayoutBody } from './PageLayoutBody';
import { PageLayoutHeader } from './PageLayoutHeader';
import { NotificationModal } from './bonsai/notification/components/NotificationModal';

export interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  breadcrumbs: HeaderBreadcrumbsProps;
}

export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  return (
    <>
      <NotificationModal />
      <Page title={props.title}>
        <Container maxWidth="xl">
          <PageLayoutHeader title={props.title} breadcrumbs={props.breadcrumbs} />
          <PageLayoutBody>{props.children}</PageLayoutBody>
        </Container>
      </Page>
    </>
  );
};
