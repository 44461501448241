import { Grid, Stack, Typography } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { AggregateDivision, AggregateRegion } from 'src/@types/seriesAggregateSupplement';
import { StyledBox } from 'src/components/app-components/StyledBox';
import useLocales from 'src/hooks/useLocales';
import AggregateSupplementPanelComponent from './AggregateSupplementPanelComponent';
import AggregateSupplementPanelHeader from './AggregateSupplementPanelHeader';

interface Props {
  aggregateDetails: AggregateDivision[] | AggregateRegion[];
  methods: UseFormReturn<FieldValues, any>;
  index: number;
}

const AggregateSupplementCustomerChildren: React.FC<Props> = (props) => {
  const { aggregateDetails, methods, index } = props;
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';

  // typeを判定して名前を取得する
  const getName = (item: AggregateDivision | AggregateRegion) => {
    if ('divisionNameJpn' in item) {
      return isJapanese ? item.divisionNameJpn : item.divisionNameEng;
    }
    return isJapanese ? item.regionNameJpn : item.regionNameEng;
  };

  return (
    <Stack component="form" sx={{ width: '100%' }}>
      <Grid container direction="column" justifyContent="flex-start">
        {aggregateDetails.map((item, n) => (
          <StyledBox key={`aggregate-detail-${getName(item)}`}>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">{getName(item)}</Typography>
              <AggregateSupplementPanelHeader
                previousSalesAggregate={item.previousSalesAggregate}
                previousPurchaseAggregate={item.previousPurchaseAggregate}
                currentSalesAggregate={item.currentSalesAggregate}
                currentPurchaseAggregate={item.currentPurchaseAggregate}
              />
              <AggregateSupplementPanelComponent aggregate={item} methods={methods} customerIndex={index} detailIndex={n} />
            </Grid>
          </StyledBox>
        ))}
      </Grid>
    </Stack>
  );
};

export default AggregateSupplementCustomerChildren;
