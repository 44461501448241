import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  isLoading: boolean;
  children: ReactNode;
  zIndex?: number;
}

export const LoadableComponent: React.FC<Props> = (props) => {
  const style: SxProps = {
    position: 'relative',
    background: 'rgba(50,50,50,0.7)',
    width: '100%',
    height: '100%',
    top: 'calc(-100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backdropFilter: 'blur(5px)',
    zIndex: props.zIndex,
  };

  return (
    <>
      {props.children}
      {props.isLoading && (
        <Box sx={style}>
          <CircularProgress />
          <Box component={'div'} sx={{ pt: 1 }}>
            <Typography sx={{ color: '#eee' }}>Now loading...</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
