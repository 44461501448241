import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyEditEntity } from 'src/api/useSurveyEditApi';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import useEnqueteCreateAddressColDef from 'src/features/general/enquete-create/hooks/useEnqueteCreateAddressColDef';
import useSettings from 'src/hooks/useSettings';

interface RespondentListGridProps {
  rowData: SurveyEditEntity[];
}

const RespondentListGrid: React.FC<RespondentListGridProps> = ({ rowData }) => {
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  let gridHeight = '50vh';

  const { defaultColDef, colDef } = useEnqueteCreateAddressColDef();

  return (
    <Stack spacing={1}>
      <Typography variant="h6" children={t('enqueteCreate.responseRequest.respondentListTitle')} />
      <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: gridHeight, width: '100%', transition: 'height 0.5s' }}>
        <StyledAgGrid defaultColDef={defaultColDef} coldef={colDef} rows={rowData} />
      </Box>
    </Stack>
  );
};

export default RespondentListGrid;
