import { Grid, MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NUMBER_OF_ELEMENRS, Question } from 'src/@types/generalEnquete';
import RHFDropdown from 'src/components/react-hook-form/RHFDropdown';
import RHFNumericField from 'src/components/react-hook-form/RHFNumericField';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import RHFUnRegisterCheckBox from 'src/components/react-hook-form/RHFUnRegisterCheckBox';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';

type Props = {
  methods: UseFormReturn;
  existingQuestion?: Question;
};

const NumberOfElementsValidationForm = ({ methods, existingQuestion }: Props) => {
  const { rulesTypeValidationErrorMessage, rulesPatternRequire, rulesNumberOfElement } = useValidator(methods);
  const [isTypeChecked] = useState<boolean>(
    existingQuestion
      ? existingQuestion.validation?.minNumberOfElements !== undefined
        ? true
        : existingQuestion.validation?.maxNumberOfElements !== undefined
        ? true
        : false
      : false,
  );
  const { t } = useTranslation();

  useEffect(() => {
    methods.setValue(
      'element',
      existingQuestion
        ? existingQuestion.validation?.maxNumberOfElements
          ? NUMBER_OF_ELEMENRS.AT_MOST
          : existingQuestion.validation?.minNumberOfElements
          ? NUMBER_OF_ELEMENRS.AT_LEAST
          : undefined
        : undefined,
    );
    methods.setValue('typeCheck', isTypeChecked);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * 回答選択肢数の設定に応じた入力フォームを返却する。
   * @param choose (少なくとも（atLeast）、多くとも（atMost）)
   * @return RHFTextField
   */
  const switchInputTypeRender = () => {
    switch (methods.watch('element')) {
      case undefined:
        return (
          <RHFNumericField
            name="checkboxRange"
            control={methods.control}
            rules={rulesNumberOfElement}
            defaultValue={existingQuestion?.validation?.minNumberOfElements?.value}
          />
        );
      case NUMBER_OF_ELEMENRS.AT_LEAST:
        return (
          <RHFNumericField
            name="checkboxRange"
            control={methods.control}
            rules={rulesNumberOfElement}
            defaultValue={existingQuestion?.validation?.minNumberOfElements?.value}
          />
        );
      case NUMBER_OF_ELEMENRS.AT_MOST:
        return (
          <RHFNumericField
            name="checkboxRange"
            control={methods.control}
            rules={rulesNumberOfElement}
            defaultValue={existingQuestion?.validation?.maxNumberOfElements?.value}
          />
        );
      default:
        break;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <RHFUnRegisterCheckBox
          name={'typeCheck'}
          control={methods.control}
          defaultChecked={isTypeChecked}
          label={t('enqueteCreate.validation.numberOfElement')}
          fieldName={['element', 'checkboxRange', 'typeValidationErrorMessage']}
          unregister={methods.unregister}
        />
      </Grid>
      {methods.watch('typeCheck', '') ? (
        <>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <RHFDropdown
                  name="element"
                  label={t('enqueteCreate.validation.numberOfElementList')}
                  control={methods.control}
                  rules={rulesPatternRequire}
                  handleOnChange={() => {}}
                  defaultValue={
                    existingQuestion
                      ? existingQuestion.validation?.minNumberOfElements !== undefined
                        ? NUMBER_OF_ELEMENRS.AT_LEAST
                        : existingQuestion.validation?.maxNumberOfElements !== undefined
                        ? NUMBER_OF_ELEMENRS.AT_MOST
                        : ''
                      : ''
                  }>
                  <MenuItem value={NUMBER_OF_ELEMENRS.AT_LEAST}>{t('enqueteCreate.validation.numberOfElementMenu.atLeast')}</MenuItem>
                  <MenuItem value={NUMBER_OF_ELEMENRS.AT_MOST}>{t('enqueteCreate.validation.numberOfElementMenu.atMost')}</MenuItem>
                </RHFDropdown>
              </Grid>
              <Grid item>{switchInputTypeRender()}</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1">{t('enqueteCreate.validation.numberOfElementMessage')}</Typography>
            <RHFTextField
              name="typeValidationErrorMessage"
              defaultValue={
                existingQuestion
                  ? existingQuestion.validation?.minNumberOfElements
                    ? existingQuestion.validation.minNumberOfElements.errorMessage
                    : existingQuestion.validation?.maxNumberOfElements
                    ? existingQuestion.validation.maxNumberOfElements.errorMessage
                    : t('enqueteCreate.validation.numberOfElementDefultMessage')
                  : t('enqueteCreate.validation.numberOfElementDefultMessage')
              }
              control={methods.control}
              fullWidth
              margin="normal"
              variant="outlined"
              rules={rulesTypeValidationErrorMessage}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default NumberOfElementsValidationForm;
