import { Question } from 'src/@types/generalEnquete';

export const useLogicSettingCheck = () => {
  /**
   * 質問がロジックの設定対象質問に設定されているか？
   * @param questions
   * @param questionId　判定対象のquestionId
   * @returns false:設定対象質問になっていない true:設定対象質問になっている
   */
  const isLogicTagetQuestion = (questions: Question[], questionId: string) => {
    return questions.some((question) => {
      if (question.conditions) {
        return question.conditions.some((condition) => condition.targetQuestionId === questionId);
      }
      return false;
    });
  };

  /**
   * 質問にロジックが設定されているか？、質問がロジックの設定対象質問に設定されているか？
   * @param questions
   * @param checkQuestion　判定対象の質問
   * @returns false:ロジックが設定されていない質問 true:ロジックが設定されている質問
   */
  const isLogicQuestion = (questions: Question[], checkQuestion: Question) => {
    let result = false;
    if (checkQuestion.conditions !== undefined && checkQuestion.conditions.length > 0) {
      // 質問にロジックが設定されているか？
      result = true;
    } else {
      // 質問がロジックのターゲットに設定されているか？
      result = questions.some((question) => {
        if (question.conditions) {
          return question.conditions.some((condition) => condition.targetQuestionId === checkQuestion.id);
        }
        return false;
      });
    }
    return result;
  };

  /**
   * 回答選択肢にロジックが設定されているか？
   * @param questions
   * @param questionItemId　判定対象の回答選択肢
   * @returns false:回答選択肢にロジックが設定されていない true:回答選択肢にロジックが設定されている
   */
  const isLogicQuestionItem = (questions: Question[], questionItemId: string) => {
    return questions.some((question) => {
      if (question.conditions) {
        return question.conditions.some((condition) => condition.questionOptionId === questionItemId);
      }
      return false;
    });
  };

  return {
    isLogicTagetQuestion,
    isLogicQuestion,
    isLogicQuestionItem,
  };
};
