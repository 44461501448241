/* eslint-disable */
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import EnqueteFormDataEdit from './EnqueteFormDataEdit';

export interface PropsEntity {
  children?: React.ReactNode;
  enqueteData: RespondentRequestEntity;
  isAdminRoleUser: boolean;
}

export const PersonalSurveyTab: React.FC<PropsEntity> = React.memo((props) => {
  const { enqueteData, isAdminRoleUser } = props;
  const { t } = useTranslation();

  //State
  const [surveyFormData, setSurveyFormData] = useState<string>();

  //アンケートフォーム保存
  const onClickSave = () => {
    if (surveyFormData === undefined) return;
  };

  return (
    <>
      <Grid container justifyContent={'flex-end'}>
        <Grid item xs={12} sx={{ mt: 1, textAlign: 'right' }}></Grid>
      </Grid>

      {/** アンケートフォーム */}
      <EnqueteFormDataEdit enqueteData={enqueteData} surveyFormData={surveyFormData} setSurveyFormData={setSurveyFormData} isAdminRoleUser={false} />
    </>
  );
});
