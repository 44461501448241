import { SxProps, TextField, Theme } from '@mui/material';
import { CalendarPickerView, DesktopDatePicker, PickersDayProps } from '@mui/x-date-pickers';
import React from 'react';
import { Controller } from 'react-hook-form';
import { dateTimeFormat } from 'src/utils/formatDateTime';

type RHFDeskTopDatePickerProps = {
  // Required Props
  control: any;
  name: string;

  // Optional Props
  acceptRegex?: RegExp;
  allowSameDateSelection?: boolean;
  cancelText?: React.ReactNode;
  className?: string;
  clearable?: boolean;
  clearText?: React.ReactNode;
  defaultCalendarMonth?: any;
  defaultValue?: string | null | Date;
  desktopModeMediaQuery?: string;
  DialogProps?: object;
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  disableHighlightToday?: boolean;
  disableMaskedInput?: boolean;
  disableOpenPicker?: boolean;
  fullWidth?: boolean;
  getViewSwitchingButtonText?: (currentView: CalendarPickerView) => string;
  helperText?: string;
  inputAdornmentProps?: object;
  label?: string;
  leftArrowButtonText?: string;
  loading?: boolean;
  mask?: string;
  maxDate?: any;
  minDate?: any;
  okText?: React.ReactNode;
  onAccept?: (date: any) => void;
  onError?: (reason: any, value: any) => void;
  onMonthChange?: (month: Date) => void;
  onViewChange?: (view: CalendarPickerView) => void;
  onYearChange?: (year: Date) => void;
  open?: boolean;
  OpenPickerButtonProps?: object;
  openTo?: 'day' | 'month' | 'year';
  orientation?: 'landscape' | 'portrait';
  PaperProps?: object;
  PopperProps?: object;
  readOnly?: boolean;
  reduceAnimations?: boolean;
  renderDay?: (day: Date, selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => JSX.Element;
  renderLoading?: () => React.ReactNode;
  required?: boolean;
  rifmFormatter?: (str: string) => string;
  rightArrowButtonText?: string;
  rules?: object;
  showDaysOutsideCurrentMonth?: boolean;
  showTodayButton?: boolean;
  showToolbar?: boolean;
  sx?: SxProps<Theme>;
  todayText?: React.ReactNode;
  ToolbarComponent?: React.JSXElementConstructor<any>;
  toolbarFormat?: string;
  toolbarPlaceholder?: React.ReactNode;
  toolbarTitle?: React.ReactNode;
  TransitionComponent?: React.JSXElementConstructor<any>;
  variant?: 'standard' | 'filled' | 'outlined';
  views?: Array<'day' | 'month' | 'year'>;
  inputFormat?: string;
  type?: string;
  onChange?: () => void;
};

const RHFDeskTopDatePicker = ({
  control,
  name,
  defaultValue,
  fullWidth,
  helperText,
  required,
  rules,
  sx,
  variant,
  label,
  disabled,
  ...rest
}: RHFDeskTopDatePickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, ...fieldProps }, fieldState }) => (
        <DesktopDatePicker
          {...fieldProps}
          inputRef={ref}
          label={label}
          onChange={(date: Date | null) => {
            rest.onChange && rest.onChange();
            fieldProps.onChange(date);
          }}
          mask={rest.mask || '____/__/__ __:__'}
          views={rest.views || ['year', 'month', 'day']}
          openTo={rest.openTo || 'day'}
          inputFormat={rest.inputFormat || dateTimeFormat}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              type={rest.type || 'datetime-local'}
              error={Boolean(fieldState.error)}
              helperText={fieldState?.error?.message}
              disabled={disabled}
            />
          )}
        />
      )}
    />
  );
};

export default RHFDeskTopDatePicker;
