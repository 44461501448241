import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, Alert, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Button, Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { INITIAL_PATH } from '../../../constants';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'src/redux/store';
import { loginSlice } from 'src/redux/slices/loginSlice';
import { Auth } from 'aws-amplify';
// for icon informations, please see the url below
// https://material-ui.com/components/material-icons/

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

type LoginFormProps = {
  onGetUser: (user: any) => void;
};

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

//未ログイン時、Location URLをローカルストレージに保存
export const loginPathNameLocalStrageKey: string = 'callbackLoginPathName';

export default function LoginForm({ onGetUser }: LoginFormProps) {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  //アクセスされたPathName + GetParamを変数に格納
  const currentPathName: string = window.location.pathname + window.location.search;

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        localStorage.removeItem(loginPathNameLocalStrageKey);
        dispatch(loginSlice.actions.setLoadingState(false));

        const result = await login(values.email, values.password);

        if (result) {
          enqueueSnackbar(t('notification.login.success'), {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          if (isMountedRef.current) {
            setSubmitting(false);

            window.location.pathname !== '/'
              ? navigate(window.location.pathname + window.location.search, { replace: true })
              : navigate(INITIAL_PATH, { replace: true });
          }
        }
      } catch (err: any) {
        if (err.isNewPasswordRequired) {
          onGetUser(err.data);
        } else {
          console.error(err);
          resetForm();
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: err.data.message });
          }
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
          <Button
            fullWidth
            color="secondary"
            size="large"
            variant="contained"
            //startIcon={<FontAwesomeIcon icon={faWindows} size="xs" />}
            //loading={isSubmitting}
            onClick={() => {
              //Location URLをローカルストレージに保存
              localStorage.setItem(loginPathNameLocalStrageKey, currentPathName);
              // @ts-ignore
              Auth.federatedSignIn({ provider: 'mickey' });
            }}>
            {t('login.button.commonIdAtTSG')}
          </Button>
        </Stack>
        <Stack sx={{ height: '80px', alignSelf: 'center' }}>
          {/* <Grid>
            <Box width={200} sx={{ background: '#d1d1d1', height: '1px' }}>
              {' '}
            </Box>
          </Grid>
          <Grid>
            <Grid> */}
          <Box
            sx={{
              marginTop: '20px',
              alignSelf: 'center',
              fontSize: '20px',
              fontWeight: '10',
            }}>
            or
          </Box>
          {/* <Box width={200} sx={{ background: '#d1d1d1', height: '1px' }}>
                {' '}
              </Box>
            </Grid>
          </Grid> */}
        </Stack>
        <Stack spacing={2}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            size="medium"
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            size="medium"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 0 }}>
          <FormControlLabel control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />} label="Remember me" />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          //style={{ fontSize: '16px' }}
          //startIcon={<FontAwesomeIcon icon={faWindows} size="xs" />}
          className={classes.root}>
          {t('login.button.dataInsightId')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
