import { Autocomplete, Backdrop, Box, Button, CircularProgress, Grid, Modal, Stack, SxProps, TextField, Typography } from '@mui/material';
import { ColDef, ColGroupDef, GridReadyEvent, HeaderCheckboxSelectionCallbackParams, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ReactNode } from 'react';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';
import CloseButton from '../CloseButton';
import { restoreColumnStateFromLocalStorage, savedColumnStateOnLocalStorage } from '../StyledAgGrid';

export interface SearchConditionProps {
  type: 'autocomplete' | 'text';
  id: string;
  label: string;
  style: {
    xs: number;
  };
  props: SearchConditionAutoCompleteProps | SearchConditionTextFieldProps;
  placeholder?: string;
}

export interface SearchConditionAutoCompleteProps {
  value: string | null; // 現在選択されている値
  list: {
    key: string;
    label: string;
  }[]; // ドロップダウンリストに表示する候補情報
  onChange: (value: string | undefined) => Promise<void>; // ドロップダウンリスト変更時のコールバック
}

export interface SearchConditionTextFieldProps {
  value: string; // 現在入力されている値
  onBlur: (value: string) => void;
}

export interface GridProps<T> {
  colDefs: (ColDef | ColGroupDef)[] | null | undefined;
  rowData: T[] | null;
  onGridReady: (params: GridReadyEvent) => void;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
}

interface Props<T> {
  isLoading: boolean;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  buttons: {
    name: string;
    onclick: () => void;
    disabled?: boolean;
  }[];
  // memo: 検索条件を格納、二次元配列として定義し、画面描画時に横並びに配置される
  conditions: SearchConditionProps[][];
  grid: GridProps<T>;
  modalStyleSxProps?: SxProps;
  isSingleSelection?: boolean;
  children?: ReactNode;
}

export const SearchModal = <T,>(props: Props<T>) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { isJapanese } = useLocales();

  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80vw',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const gridStyle: SxProps = {
    height: '50vh',
    width: '100%',
    transition: 'height 0.5s',
  };

  const isFirstColumn = (params: HeaderCheckboxSelectionCallbackParams) => {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    editable: false,
    filter: true,
    headerCheckboxSelection: props.isSingleSelection ? false : isFirstColumn,
    checkboxSelection: isFirstColumn,
  };

  const renderAutoComplete = (condition: SearchConditionProps) => {
    const props = condition.props as SearchConditionAutoCompleteProps;
    return (
      <Autocomplete
        id={condition.id}
        size="small"
        options={props.list}
        // memo: [Encountered two children with the same key] の発生を抑制するため、renderOptionを定義
        renderOption={(props, option) => (
          <li {...props} key={props.id}>
            {option.label}
          </li>
        )}
        value={props.list.find((e) => e.key === props.value) || { key: '', label: '' }}
        // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
        isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
        onChange={(_, v) => props.onChange(v?.key)}
        renderInput={(params) => <TextField {...params} label={condition.label} />}
      />
    );
  };

  const renderTextfield = (condition: SearchConditionProps) => {
    const props = condition.props as SearchConditionTextFieldProps;
    const inputLabelProps = condition.placeholder ? { shrink: true } : {};
    return (
      <TextField
        fullWidth
        size="small"
        label={condition.label}
        defaultValue={props.value}
        placeholder={condition.placeholder}
        onBlur={(event) => props.onBlur(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            // memo: Enter Key 押下で blur させ、 onBlur を発火させることで、 Enter Key による検索等の処理を実行可能とする
            (event.target as HTMLInputElement).blur();
          }
        }}
        InputLabelProps={inputLabelProps}
      />
    );
  };

  const storageKey = 'SearchModal';

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <>
        {/* モーダル表示時に時間がかかる場合、isLoading が true の間は modal 操作不可となるように circularProgress を描画 */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box component={'div'} sx={{ ...modalStyle, ...props.modalStyleSxProps }}>
          {/* modal title */}
          <Typography variant="h4" component={'h2'}>
            {props.title}
          </Typography>
          {props.children ?? ''}

          {/* conditions */}
          <Grid container component={'div'} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              {props.conditions.map((conditions, pIndex) => (
                <Grid container key={pIndex} sx={{ mt: 1, mr: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {conditions.map((condition, cIindex) => (
                    <Grid key={cIindex} item xs={condition.style.xs}>
                      {condition.type === 'autocomplete' && renderAutoComplete(condition)}
                      {condition.type === 'text' && renderTextfield(condition)}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* modal grid table */}
          <Grid container sx={{ pt: 1.5 }}>
            <Grid item xs={12}>
              <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={gridStyle}>
                <AgGridReact
                  defaultColDef={defaultColDef}
                  columnDefs={props.grid.colDefs}
                  rowData={props.grid.rowData}
                  rowSelection={props.isSingleSelection ? 'single' : 'multiple'}
                  rowMultiSelectWithClick={true}
                  onGridReady={props.grid.onGridReady}
                  onColumnResized={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onColumnMoved={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onColumnVisible={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onSortChanged={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onSelectionChanged={(event) => {
                    props.grid.onSelectionChanged && props.grid.onSelectionChanged(event);
                  }}
                  onFirstDataRendered={(event) => {
                    restoreColumnStateFromLocalStorage(event, storageKey, isJapanese);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* modal bottom actions */}
          <Grid container mt={2}>
            <Grid item xs={6} justifyContent={'left'}>
              <Stack direction={'row'} spacing={1}>
                <CloseButton onClose={props.onClose} color="primary" />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={'row-reverse'}>
                {props.buttons.map((button, index) => (
                  <Button key={index} variant="contained" onClick={button.onclick} disabled={button.disabled}>
                    {button.name}
                  </Button>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </>
    </Modal>
  );
};
