import AuthGuardLayout from 'src/components/layouts/AuthGuardLayout';
import { OverviewSwitcher } from '../pages/OverviewSwitcher';

//コンテンツ一覧画面の描画
export const overviewRoute = {
  path: 'top',
  element: <AuthGuardLayout />,
  children: [
    {
      path: '',
      element: <OverviewSwitcher />,
    },
  ],
};

export default overviewRoute;
