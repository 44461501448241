import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OverviewDivisionAggregateSupplement } from 'src/@types/overviewAggregateSupplement';
import { MODE, Mode } from '../utils/constants';

interface Permissions {
  isAdmin: boolean;
  allowWriteDivisions: string[];
  isSurveyOwner: boolean;
}

export const overviewAggregateSupplementSlice = createSlice({
  name: 'overviewAggregateSupplement',
  initialState: {
    mode: null as Mode | null,
    isAdmin: false,
    allowWriteDivisions: [] as string[],
    isSurveyOwner: false,
    divisionAggregateSupplements: [] as OverviewDivisionAggregateSupplement[],
    topic: '' as string | undefined,
  },
  reducers: {
    setMode(state, action: PayloadAction<Mode>) {
      state.mode = action.payload;
    },
    changeMode(state) {
      state.mode = state.mode === MODE.DIVISION_REP ? MODE.OWNER : MODE.DIVISION_REP;
    },
    setPermission(state, action: PayloadAction<Permissions>) {
      state.isAdmin = action.payload.isAdmin;
      state.allowWriteDivisions = action.payload.allowWriteDivisions;
      state.isSurveyOwner = action.payload.isSurveyOwner;
    },
    setDivisionAggregateSupplements(state, action: PayloadAction<OverviewDivisionAggregateSupplement[]>) {
      state.divisionAggregateSupplements = action.payload;
    },
    setTopic(state, action: PayloadAction<string | undefined>) {
      state.topic = action.payload;
    },
  },
});
export const { setMode, changeMode, setPermission, setDivisionAggregateSupplements, setTopic } = overviewAggregateSupplementSlice.actions;
