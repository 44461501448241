import { useTranslation } from 'react-i18next';
import { AMOUNT_SOURCE_TYPE, AmountSourceType, CUSTOMER_STATUS } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppSelector } from 'src/redux/store';
import { CONDITIONNUMBER } from '../components/util/constants';

export const useValidationRule = () => {
  const { t } = useTranslation();
  const { rate } = useAppSelector((state) => state.seriesAnswer);

  // 小数点以下桁数の取得
  const numberOfDecimalPlaces = useAppSelector((state) => state.seriesAnswer.numberOfDecimalPlaces);

  const numericFieldValidationRule = (customerStatus: string, isDisabled: boolean, sourceType: AmountSourceType = AMOUNT_SOURCE_TYPE.ANSWERED) => {
    return {
      required: { value: true, message: t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.notEmptyValidation') },
      validate: {
        // プラスの場合は、15桁より大きい、マイナスの場合は「-」記号を含めて「1+15」桁より大きい場合エラー
        maxLength: (value: string | number | null) => {
          if (value) {
            const stringValue = value.toString().split('.')[0];
            const pattern = /^([1-9]\d*|0)(\.\d+)?$/;
            const digit = pattern.test(`${stringValue}`) ? 15 : 16;
            if (stringValue.toString().length > digit) return `${t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.maxDigitsValidation')}`;
          }
        },
        numberOfDecimalPlaces: (value: string | number | null) => {
          if (
            !isDisabled &&
            (value || `${value}` === '0') &&
            numberOfDecimalPlaces !== null &&
            sourceType !== AMOUNT_SOURCE_TYPE.PRE_ANSWERED &&
            customerStatus !== CUSTOMER_STATUS.OUTSIDES_COMPANY
          ) {
            // 小数点以下の桁数チェック。指定された桁数以外は許可しない
            const decimalPartPattern = `\\.\\d{${numberOfDecimalPlaces}}`;
            const integerPartPattern = `\\-?\\d*`;
            // 指定された桁数が0以外の場合、小数部のパターンをつける
            const pattern = `^${integerPartPattern}${numberOfDecimalPlaces !== 0 ? decimalPartPattern : ''}$`;
            if (!new RegExp(pattern).test(`${value}`)) {
              return `${t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.numberOfDecimalPlaces', { numberOfDecimalPlaces })}`;
            }
          }
        },
      },
    };
  };

  const commentFieldValidationRule = (currentYear: string | number | null, preYear: string | number | null) => {
    const current = typeof currentYear === 'string' ? parseInt(currentYear) : currentYear;
    const pre = typeof preYear === 'string' ? parseInt(preYear) : preYear;
    const isRequired = Math.abs(((current ?? 0) - (pre ?? 0)) * rate) >= CONDITIONNUMBER;
    return {
      required: {
        value: isRequired,
        message: t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.comment'),
      },
      validate: {
        emptyCheck: (value: string | number | null) => {
          if ((value === null || value?.toString().trim() === '') && isRequired) {
            return `${t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.comment')}`;
          }
        },
      },
    };
  };

  return { numericFieldValidationRule, commentFieldValidationRule };
};
