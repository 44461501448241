import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack5';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageEntity, ErrorReportEntity } from 'src/api/useEnqueteStatusPageApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { TemporaryOrganizationDataTypes } from 'src/api/useTemporaryOrganizationApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { REQUEST_STATUS } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import TemporaryOrganizationDetailModal from '../../../../modals/temporary-organization-detail/components/TemporaryOrganizationDetailModal';
import ErrorReportContent from '../../respondent-tab/components/ErrorReportContent';
import { useTemporaryOrganizationHooks } from '../hooks/useTemporaryOrganizationHooks';
import { useTemporaryOrganizationValidation } from '../hooks/useTemporaryOrganizationValidation';
import TemporaryOrganizationGrid from './TemporaryOrganizationGrid';

interface PageComponentTypes {
  surveyDetailId: string;
}

export enum CreateOrgTypeList {
  ttc = 'ttcCreate',
  group = 'groupCreate',
}

export const TemporaryOrganizationTab: React.FC<PageComponentTypes> = (props) => {
  const { surveyDetailId } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const inputRef = useRef<HTMLInputElement>(null);
  const { isViewerAdmin, isSurveyOwner } = usePermission(surveyDetailId);
  const surveyEditApit = useSurveyEditApi();
  const { enqueueSnackbar } = useSnackbar();

  const { updateRefreshCounter, openEditModal, closeEditModal } = useTemporaryOrganizationHooks();
  const { resetError } = useTemporaryOrganizationValidation();

  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData);
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const isStatusComplete = requestStatus === REQUEST_STATUS.COMPLETE;

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isOpenEditModal = useAppSelector((state) => state.temporaryOrganization.isOpenEditModal);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [isCodeHide, setIsCodeHide] = useState<boolean>(true);
  const [editRowData, setEditRowData] = useState<TemporaryOrganizationDataTypes | null>(null);
  const [createType, setCreateType] = useState<CreateOrgTypeList | null>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorReport, setErrorReport] = useState<ErrorMessageEntity[] | []>([]);
  const [isOpenErrorNotificationModal, setIsOpenErrorNotificationModal] = useState<boolean>(false);

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // 仮組織情報 編集処理
  const editTemporaryOrganization = (rowData: TemporaryOrganizationDataTypes) => {
    if (isStatusComplete || (isViewerAdmin && !isSurveyOwner)) return;

    // 作成タイプを指定
    setCreateType(null);
    // 編集する指定行IDを管理対象にする
    setEditRowData(rowData);
    // モーダルオープン
    openEditModal();
  };

  //エクスポート処理
  const onClickExportData = async () => {
    if (!surveyDetailId) return;

    setInProgress(true);
    await surveyEditApit.exportFileTemporaryOrganizations(surveyDetailId, isLangJpn ? 'jpn' : 'eng');
    setInProgress(false);
  };

  //インポート ファイル選択ダイアログ表示
  const onClickOpenImportData = () => {
    if (inputRef.current === null) return;
    inputRef.current.click();
  };

  // インポート処理
  const handleImportExcel = async (e: ChangeEvent<any>) => {
    //FormData定義
    let formData = new FormData();

    //ファイル情報が未定義であれば終了
    if (inputRef.current !== null && inputRef.current?.files?.length === 0 && inputRef.current?.files !== null) return;
    //Filesデータがあれば、FileDataに格納
    if (inputRef.current !== null && inputRef.current?.files !== null) {
      formData.append('file', inputRef.current?.files[0]);
    }

    //surveyDetailIdがなければ中断、あればインポート
    if (surveyDetailId === undefined) return;
    setInProgress(true);

    //インポート
    const res: AxiosResponse<any> | undefined = await surveyEditApit.importFileTemporaryOrganizations(surveyDetailId, formData);

    //エラー分岐
    const clientErrorPattern = /^4[0-9]{2}$/;
    const serverErrorPattern = /^5[0-9]{2}$/;
    if (res !== undefined && res.status !== undefined && res.status === 400) {
      const error: ErrorReportEntity = res.data;
      setErrorReport(error.message);
      setIsOpenErrorNotificationModal(true);
    } else if (res !== undefined && res.status !== undefined && res.status === 406) {
      enqueueSnackbar(t('message.exceptionInvalidId'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && clientErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && serverErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedServerSide'), { variant: 'error' });
    } else {
      //グリッド情報更新
      updateRefreshCounter();
      //エラーレスポンスリセット
      setErrorReport([]);
      //スナックバー通知
      enqueueSnackbar(t('errorReport.message.succeedImport'), { variant: 'success' });
    }

    setInProgress(false);
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  // モーダルクローズ監視
  useEffect(() => {
    if (!isOpenEditModal) {
      setEditRowData(null); // 指定編集データリセット
      resetError(); // エラーリセット
    }
  }, [isOpenEditModal]); // eslint-disable-line

  return (
    <>
      {/* ボタンエリア */}
      <Grid container sx={{ mb: 1 }} alignItems={'center'}>
        <Grid item xs={4}>
          {/** 組織コード表示 */}
          <FormControlLabel
            control={<Checkbox onChange={() => setIsCodeHide(!isCodeHide)} size="small" />}
            checked={!isCodeHide}
            sx={{ mt: '2px' }}
            label={t('enqueteCreate.temporaryOrganization.button.showOrganizationCode')}
          />
        </Grid>
        <Grid item container xs={8} direction={'row'} justifyContent={'flex-end'} spacing={1} textAlign="right">
          {/** 新規作成（単体） */}
          <Grid
            item
            children={
              <StyledButton
                onClick={() => {
                  // 作成タイプを指定
                  setCreateType(CreateOrgTypeList.ttc);
                  // モーダルオープン
                  openEditModal();
                }}
                children={t('enqueteCreate.temporaryOrganization.button.createOrganizationTypeTTC')}
                isDisabled={isStatusComplete || (isViewerAdmin && !isSurveyOwner)}
              />
            }
          />

          {/** 新規作成（グループ会社） */}
          <Grid
            item
            children={
              <StyledButton
                onClick={() => {
                  // 作成タイプを指定
                  setCreateType(CreateOrgTypeList.group);
                  // モーダルオープン
                  openEditModal();
                }}
                children={t('enqueteCreate.temporaryOrganization.button.createOrganizationTypeGroupCompany')}
                isDisabled={isStatusComplete || (isViewerAdmin && !isSurveyOwner)}
              />
            }
          />

          {/** Excelエクスポート */}
          <Grid
            item
            children={<StyledButton onClick={onClickExportData} isDisabled={isViewerAdmin && !isSurveyOwner} children={t('button.exportExcel')} />}
          />

          {/** Excelインポート */}
          <Grid
            item
            children={
              <StyledButton onClick={onClickOpenImportData} isDisabled={isStatusComplete || (isViewerAdmin && !isSurveyOwner)}>
                {t('button.importExcel')}
                <input
                  type="file"
                  value=""
                  hidden
                  ref={inputRef}
                  onChange={handleImportExcel}
                  onClick={(e) => {
                    if (inputRef.current !== null && inputRef.current?.files !== null) {
                      inputRef.current.files = null;
                    }
                  }}
                  accept={'.xlsx'}
                />
              </StyledButton>
            }
          />

          {/** エラーレポート詳細 */}
          <Grid
            item
            children={
              <StyledButton
                onClick={() => setIsOpenErrorNotificationModal(true)}
                children={t('errorReport.title')}
                isDisabled={isStatusComplete || (isViewerAdmin && !isSurveyOwner)}
              />
            }
          />
        </Grid>
      </Grid>

      {/* グリッドエリア */}
      <TemporaryOrganizationGrid
        isCodeHide={isCodeHide}
        surveyDetailId={surveyDetailId}
        editRowCallback={editTemporaryOrganization}
        isStatusComplete={isStatusComplete}
      />

      {/* 作成・編集モーダル */}
      <TemporaryOrganizationDetailModal
        editRowData={editRowData}
        surveyDetailId={surveyDetailId}
        createType={createType}
        closeModalCallback={closeEditModal}
      />

      {/** エラーレポートモーダル */}
      <StyledModal
        isOpen={isOpenErrorNotificationModal}
        onCloseFunc={() => setIsOpenErrorNotificationModal(false)}
        modalTitle={t('errorReport.title')}
        children={<ErrorReportContent errorReport={errorReport} onCloseFunc={() => setIsOpenErrorNotificationModal(false)} />}
      />

      {/** ローディング */}
      <StyledLoading isOpen={inProgress} />
    </>
  );
};
