import HotTable from '@handsontable/react';
import { RefObject } from 'react';
import { NestedHeader } from 'src/@types/generalEnquete';

// memo : 多段ヘッダー操作のための関数
export const transformNestedHeaders = (nestedHeaders: (string | NestedHeader)[]) => {
  let transformedObj: { label: string; index: number[] }[] = [];
  let currentIndex = 0;

  nestedHeaders.forEach((item: string | NestedHeader, index: number) => {
    const isObject = typeof item === 'object' && item !== null;
    const colspan = isObject ? (item as { label: string; colspan: number }).colspan : 1;
    const label = isObject ? (item as { label: string; colspan: number }).label : item;
    transformedObj[index] = {
      label,
      index: Array.from({ length: colspan }, (_, i) => currentIndex + i),
    };
    currentIndex += colspan;
  });
  return transformedObj;
};

// memo: start endの大小を比較して入れ替える。
export const setStartEnd = (start: number, end: number) => {
  return start <= end ? [start, end] : [end, start];
};

export const checkIsAllSelected = (hotRef: RefObject<HotTable>) => {
  const selected = hotRef?.current?.hotInstance?.getSelected();
  // 全セルが選択される場合、startRowとstartColがどちらも-1以下となる
  if (!selected) return true;
  if (selected[0][0] < 0 && selected[0][1] < 0) return true;
  return false;
};

export const updateNestedHeaders = (hotRef: RefObject<HotTable>, pos: number, range: number, direction: 'left' | 'right') => {
  const settings = hotRef.current?.hotInstance?.getSettings();
  const selected = hotRef?.current?.hotInstance?.getSelected();
  if (!selected) return;
  const newNestedHeaders: (string | NestedHeader)[][] = [];
  if (settings?.nestedHeaders) {
    // memo : ヘッダーデータを一行ずつ取り出して、列挿入後のヘッダーデータを作成する。
    settings?.nestedHeaders.forEach((row) => {
      let tempNestedHeaderRow: { label: string; index: number[] }[] = [];
      tempNestedHeaderRow = insertColumnIntoNestedHeaderRow(transformNestedHeaders(row), pos, range, direction);
      const upadatedNestedHeaderRow = tempNestedHeaderRow.map((el) => {
        const colspan = el.index.length;
        const labelValue = el.label;
        if (colspan === 1) {
          return labelValue;
        } else {
          return { label: labelValue, colspan: colspan };
        }
      });
      newNestedHeaders.push(upadatedNestedHeaderRow);
    });
  }
  return newNestedHeaders;
};

export const insertColumnIntoNestedHeaderRow = (
  headerRows: { label: string; index: number[] }[],
  insertPosition: number,
  insertRange: number,
  direction: 'right' | 'left',
) => {
  const tempUpdatedHeaderRow = [...headerRows];
  const isInsertLeft = direction === 'left';

  for (const [index, header] of headerRows.entries()) {
    const isTargetHeader = isInsertLeft ? header.index.includes(insertPosition) : header.index.includes(insertPosition - 1);

    if (isTargetHeader) {
      if (header.index.length > 1) {
        const boundaryIndex = isInsertLeft ? header.index[0] : header.index[header.index.length - 1];
        const isBoundaryConditionMet = isInsertLeft ? insertPosition - 1 < boundaryIndex : insertPosition - 1 >= boundaryIndex;

        if (isBoundaryConditionMet) {
          const newHeaders = new Array(insertRange).fill({ label: '', index: [1] });
          tempUpdatedHeaderRow.splice(index + (isInsertLeft ? 0 : 1), 0, ...newHeaders);
        } else {
          header.index.push(...new Array(insertRange).fill(1));
        }
      } else {
        const newHeaders = new Array(insertRange).fill({ label: '', index: [1] });
        tempUpdatedHeaderRow.splice(index + (isInsertLeft ? 0 : 1), 0, ...newHeaders);
      }
      return tempUpdatedHeaderRow;
    }
  }

  tempUpdatedHeaderRow.push({ label: '', index: [1] });
  return tempUpdatedHeaderRow;
};

export const deleteHeaderCol = (nestedHeaders: (string | NestedHeader)[], pos: number, range: number) => {
  const result: { label: string; index: number[] }[] = [];
  for (const item of transformNestedHeaders(nestedHeaders)) {
    const newColspan = item.index.filter((index: number) => index < pos || index > pos + range - 1);
    if (newColspan.length > 0) {
      result.push({ ...item, index: newColspan });
    }
  }
  return result;
};

// memo: type を設定する前に不要なメタ属性を削除する。
export const removeCellMeta = (hotRef: RefObject<HotTable>, row: number, col: number) => {
  const keys = ['source', 'valid', 'editor', 'renderer', 'validator', '_automaticallyAssignedMetaProps', 'type'];
  keys.forEach((key) => {
    hotRef?.current?.hotInstance?.removeCellMeta(row, col, key);
  });
};

export const updateMergeCells = (hotRef: RefObject<HotTable>) => {
  const mergedCells = [];
  const currentData = hotRef?.current?.hotInstance?.getData() as string[][];
  for (let row = 0; row < currentData.length; row++) {
    for (let col = 0; col < currentData[row].length; col++) {
      const cellMeta = hotRef?.current?.hotInstance?.getCellMeta(row, col);
      if (cellMeta?.rowspan > 1 || cellMeta?.colspan > 1) {
        mergedCells.push({ row, col, rowspan: cellMeta?.rowspan, colspan: cellMeta?.colspan });
      }
    }
  }
  hotRef?.current?.hotInstance?.updateSettings({ mergeCells: mergedCells });
  hotRef?.current?.hotInstance?.render();
};
