import { ListItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, List, ListItemText, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import useSettings from 'src/hooks/useSettings';
import { getFullOrganizationString } from 'src/utils/getOrganizationString';
import { useChangeRespondentModal } from '../hooks/useChangeRespondentModal';

type Props = {
  respondentRequestId: string;
  respondentTarget: RespondentsListEntity;
  newRespondentTargetOrg: OrganizationEntity;
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

export const ChangeRespondentConfirmationModal = ({
  respondentRequestId,
  respondentTarget,
  newRespondentTargetOrg,
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { themeMode } = useSettings();
  const colorItemeSelected = themeMode === 'light' ? theme.palette.info.lighter : theme.palette.info.dark;
  const colorItemNotSelected = themeMode === 'light' ? theme.palette.grey[300] : theme.palette.grey[500];

  const { isProcessing, onClick, isUpdateAll, setIsUpdateAll, updatedRespondentData, currentRespondentData } = useChangeRespondentModal({
    respondentTarget,
    newRespondentTargetOrg,
  });

  const handleUpdate = async () => {
    await onClick(respondentRequestId, respondentTarget.respondentTargetId);
    setIsConfirmationModalOpen(false);
    onClose();
  };

  return (
    <>
      <StyledLoading isOpen={isProcessing} />
      {isConfirmationModalOpen && (
        <StyledModal
          isOpen={isConfirmationModalOpen}
          onCloseFunc={() => {
            setIsConfirmationModalOpen(false);
          }}
          modalTitle={t('enqueteCreate.changeRespondentConfirmationMordal.title')}>
          <Box
            sx={{
              position: 'relative',
              width: '60vw',
              height: '50vh',
              overflowY: 'scroll',
            }}>
            <Grid container sx={{ width: '100%' }}>
              <Grid item xs={12}>
                <StyledBox>
                  <Typography variant="h4">{t('enqueteCreate.changeRespondentConfirmationMordal.textfield.beforeUpdate')}</Typography>
                  <Grid container spacing={2} m={2} sx={{ width: 'auto' }}>
                    <Grid item xs={2}>
                      {t('enqueteCreate.changeRespondentConfirmationMordal.textfield.organizationBeforeUpdate')}
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1" noWrap={false} sx={{ overflow: 'hidden', wordBreak: 'break-word', ml: 2, mr: 2 }}>
                        {currentRespondentData && getFullOrganizationString(currentRespondentData.organizationInfo)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>{t('enqueteCreate.changeRespondentConfirmationMordal.textfield.respondentPerson')}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Stack>
                        <List sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'baseline', m: 0, p: 0 }}>
                          {currentRespondentData &&
                            currentRespondentData.respondentPersonsInfo.length > 0 &&
                            currentRespondentData.respondentPersonsInfo.map((person, index: number) => (
                              <React.Fragment key={person.id}>
                                {index !== 0 && <Divider orientation="vertical" variant="middle" flexItem />}
                                <ListItem key={person.id} sx={{ m: 1, width: 'auto' }}>
                                  <ListItemText
                                    primary={<Typography sx={{ fontSize: '0.8rem' }}>{person.name}</Typography>}
                                    sx={{
                                      backgroundColor:
                                        isUpdateAll || respondentTarget.respondentPersonId === person.id ? colorItemeSelected : colorItemNotSelected,
                                      fontSize: '0.5rem',
                                      borderRadius: 1,
                                      p: 1,
                                      display: 'flex',
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      flexBasis: 'auto',
                                    }}
                                  />
                                </ListItem>
                              </React.Fragment>
                            ))}
                        </List>
                      </Stack>
                    </Grid>
                  </Grid>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h4">{t('enqueteCreate.changeRespondentConfirmationMordal.textfield.afterUpdate')}</Typography>
                  {updatedRespondentData.map((update, index: number) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <Divider orientation="horizontal" variant="middle" />}
                      <Grid container spacing={2} m={2} sx={{ width: 'auto' }}>
                        <Grid item xs={2}>
                          {t('enqueteCreate.changeRespondentConfirmationMordal.textfield.organizationAfterUpdate')}
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="body1" noWrap={false} sx={{ overflow: 'hidden', wordBreak: 'break-word', ml: 2, mr: 2 }}>
                            {getFullOrganizationString(update.organizationInfo)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography>{t('enqueteCreate.changeRespondentConfirmationMordal.textfield.respondentPerson')}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Stack>
                            <List
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'baseline',
                                flexDirection: 'row',
                                m: 0,
                                p: 0,
                              }}>
                              {update.respondentPersonsInfo.map((person, index: number) => (
                                <React.Fragment key={person.id}>
                                  {index !== 0 && <Divider orientation="vertical" variant="middle" flexItem />}
                                  <ListItem key={person.id} sx={{ m: 1, width: 'auto' }}>
                                    <ListItemText
                                      primary={<Typography sx={{ fontSize: '0.8rem' }}>{person.name}</Typography>}
                                      sx={{
                                        backgroundColor:
                                          isUpdateAll || respondentTarget.respondentPersonId === person.id
                                            ? colorItemeSelected
                                            : colorItemNotSelected,
                                        fontSize: '0.5rem',
                                        borderRadius: 1,
                                        p: 1,
                                        display: 'flex',
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        flexBasis: 'auto',
                                      }}
                                    />
                                  </ListItem>
                                </React.Fragment>
                              ))}
                            </List>
                          </Stack>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </StyledBox>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2} sx={{ position: 'sticky', bottom: 0, mt: 2 }}>
            <Grid item xs={6}>
              {currentRespondentData && currentRespondentData.respondentPersonsInfo.length > 1 && (
                <FormControlLabel
                  control={<Checkbox onChange={() => setIsUpdateAll(!isUpdateAll)} />}
                  checked={isUpdateAll}
                  sx={{ mt: '2px' }}
                  label={
                    <Typography variant="body1">
                      {t('enqueteCreate.changeRespondentConfirmationMordal.textfield.updateOtherPersonsCheckBox')}
                    </Typography>
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
                <Button color="error" variant="contained" size="large" onClick={() => setIsConfirmationModalOpen(false)}>
                  {t('button.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={async () => {
                    await handleUpdate();
                  }}>
                  {t('button.update')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </StyledModal>
      )}
    </>
  );
};
