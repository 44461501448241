import { useNavigate } from 'react-router-dom';
import { getThreadsApi } from 'src/api/getThreadsApi';
import { useUserApi } from 'src/api/useUserApi';
import { editThreadUsers } from 'src/features/general/enquete-threads/store/enqueteThreadCreateSlice';
import { useDispatch } from 'src/redux/store';

interface Props {
  id: string;
}

export const useThreadCard = (props: Props) => {
  const { id } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getThreadById } = getThreadsApi();
  const userApi = useUserApi();

  const getThreadUsersUtility = async (emails: string[]) => {
    const data = await userApi.getByEmail(emails);
    if (!data) {
      dispatch(editThreadUsers([]));
      return;
    }
    dispatch(editThreadUsers(data));
  };

  const editClick = async () => {
    const thread = await getThreadById(id);
    const threadUsers = [];
    for (let user of thread.threadUsers) {
      threadUsers.push(user.userId);
    }
    await getThreadUsersUtility(threadUsers);
    navigate(`edit/${id}`);
  };

  const showDetails = () => {
    navigate(`detail/${id}`);
  };

  return { editClick, showDetails };
};
