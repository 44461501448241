import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { CountryEntity, ComplementaryOrganizationEntity, RankEntity, RegionEntity } from '../../../../api/useComplementaryOrganizationApi';
export const complementaryOrganizationSlice = createSlice({
  name: 'complementaryOrganization',
  initialState: {
    listData: [] as ComplementaryOrganizationEntity[],
    detailData: {} as ComplementaryOrganizationEntity,
    ttcData: {} as OrganizationEntity,
    countryData: [] as CountryEntity[],
    regionData: [] as RegionEntity[],
    rankData: [] as RankEntity[],
  },
  reducers: {
    initListData: (state, action: PayloadAction<ComplementaryOrganizationEntity[]>) => {
      state.listData = action.payload;
    },
    initDetailData: (state, action: PayloadAction<ComplementaryOrganizationEntity>) => {
      state.detailData = action.payload;
    },
    deleteRowData: (state, action: PayloadAction<string>) => {
      const listData = current(state.listData);
      state.listData = listData.filter((rowData) => rowData.id !== action.payload);
    },
    ttcData: (state, action: PayloadAction<OrganizationEntity>) => {
      state.ttcData = action.payload;
    },
    countryData: (state, action: PayloadAction<CountryEntity[]>) => {
      state.countryData = action.payload;
    },
    regionData: (state, action: PayloadAction<RegionEntity[]>) => {
      state.regionData = action.payload;
    },
    rankData: (state, action: PayloadAction<RankEntity[]>) => {
      state.rankData = action.payload;
    },
  },
});
