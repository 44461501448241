import { SelectChangeEvent } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { setCurrency, setHasError, setNumberOfDecimalPlaces } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useCalculateFiscalYear } from './useCalculateFiscalYear';
import { useGetCurrency } from './useGetCurrency';

export const useSetCurrency = () => {
  const dispatch = useAppDispatch();
  const { currencies } = useAppSelector((state) => state.seriesAnswer);
  const { getRateForCurrency } = useGetCurrency();
  const { currentYear } = useCalculateFiscalYear();

  const handleChangeSetCurrency = async (event: SelectChangeEvent, methods: UseFormReturn<FieldValues, any>) => {
    // 選択している通貨を更新
    dispatch(setCurrency(event.target.value));
    // 小数点以下桁数を更新
    const currency = currencies.find((currency) => currency.currencyCode === event.target.value);
    await getRateForCurrency(currency ? currency?.currencyCode : '0', currentYear);
    dispatch(setNumberOfDecimalPlaces(currency ? currency.numberOfDecimalPlaces ?? 0 : 0));
    const isValid = await methods.trigger();
    dispatch(setHasError(!isValid));
  };
  return { handleChangeSetCurrency };
};
