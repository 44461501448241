import { Box, Grid, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { PersonnelSurveyDiffFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';

// TODO: 型定義を別の場所にまとめる
interface EnqueteAnswerCreateDiffFormProps {
  form: PersonnelSurveyDiffFormData;
  diffValue: number;
  diffFormData: any[] | undefined;
  isEmptyError: boolean;
  setDiffFormFunction: Function;
  setErrorDiffFormFunction: Function;
}

interface Row {
  count: number;
  diffMemberInputValue: string;
  diffMemberReasonSelectValue: string;
  diffMemberReasonInputValue: string;
}

interface EnqueteAnswerDiffFormProps {
  title: string;
  labelTitle: string;
  lines: number;
  diffValue: number;
  row: Row;
  diffFormData: any[] | undefined;
  setDiffFormFunction: Function;
  isEmptyError: boolean;
}

export const EnqueteAnswerCreateDiffForm: React.FC<EnqueteAnswerCreateDiffFormProps> = (props) => {
  const { form, diffValue, diffFormData, isEmptyError, setDiffFormFunction, setErrorDiffFormFunction } = props;
  const useJudg = useJudgmentCurrentLanguage();

  const [resultPlanRows, setResultPlanRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 差異フォーム行数
  let lines = 0;
  // 差異フォームタイトル
  const title = undefined !== form.title ? useJudg.getText(form.title as languageDataTypes) : '';
  // 差異フォームサブタイトル
  const labelTitle = undefined !== form.labelTitle ? useJudg.getText(form.labelTitle as languageDataTypes) : '';

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    refreshRowsData();
  }, [props]); //eslint-disable-line

  useEffect(() => {
    // バリデーションチェック(全体)
    setErrorDiffFormFunction(false);
    resultPlanRows.forEach((row) => {
      const checkTargetValue = row.diffMemberInputValue !== null ? row.diffMemberInputValue.toString() : null;
      const matchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/[+-][0-9]*/) : null;

      if (checkTargetValue !== null && checkTargetValue !== '' && checkTargetValue !== matchFieldValue?.input) {
        setErrorDiffFormFunction(true);
      } else {
        const secondMatchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]/) : null;
        if (secondMatchFieldValue === null && checkTargetValue !== null && checkTargetValue !== '') {
          setErrorDiffFormFunction(true);
        } else {
          // 先頭に半角の + または -のみ
          const checkHeadOnlyValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]$/) : null;
          if (checkHeadOnlyValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
            setErrorDiffFormFunction(true);
          } else {
            // 不正な値(+0 / -0)
            const checkZeroValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[0]+$/) : null;
            if (checkZeroValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
              setErrorDiffFormFunction(true);
            } else {
              // 不正な形式(+10++0 / ++100 / +010)
              const checkFormatValueError: RegExpMatchArray | null =
                checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[1-9]{1}[0-9]{0,4}$/) : null;
              if (checkFormatValueError === null && checkTargetValue !== null && checkTargetValue !== '') {
                setErrorDiffFormFunction(true);
              }
            }
          }
        }
      }
    });
  }, [resultPlanRows]); //eslint-disable-line

  //-------------------------------------------------------------
  // テーブルの更新
  //-------------------------------------------------------------

  const refreshRowsData = async () => {
    setIsLoading(true);

    lines = undefined !== form.defaultLine ? form.defaultLine : 0;
    // ◯◯vs◯◯入力フォーム のデータ作成
    const resultAndPlanRows =
      diffFormData === undefined || diffFormData.length === 0
        ? [...Array(lines)].map((_, i) => {
            return {
              count: i,
              diffMemberInputValue: '',
              diffMemberReasonSelectValue: '',
              diffMemberReasonInputValue: '',
            };
          })
        : [...Array(lines)].map((_, i) => {
            if (diffFormData[i]) {
              // データが存在する場合は読み込み
              return {
                count: i,
                diffMemberInputValue: diffFormData[i]['diffMemberInputValue'],
                diffMemberReasonSelectValue: diffFormData[i]['diffMemberReasonSelectValue'],
                diffMemberReasonInputValue: diffFormData[i]['diffMemberReasonInputValue'],
              };
            } else {
              // データが存在しない場合は空データを挿入
              return {
                count: i,
                diffMemberInputValue: '',
                diffMemberReasonSelectValue: '',
                diffMemberReasonInputValue: '',
              };
            }
          });

    await setResultPlanRows(resultAndPlanRows);

    setIsLoading(false);
  };

  return (
    <>
      <LoadableComponent isLoading={isLoading}>
        <Box sx={{ pb: '6px' }} style={{ whiteSpace: 'pre-line' }}>
          <h4>{useJudg.getText(props.form['headline'] as languageDataTypes)}</h4>
        </Box>

        {resultPlanRows.length &&
          resultPlanRows.map((row) => (
            <EnqueteAnswerDiffPlanAndPlanForm
              title={title}
              labelTitle={labelTitle}
              key={'diffResultAndPlanForm' + row.count}
              lines={lines}
              diffValue={diffValue}
              row={row}
              diffFormData={diffFormData}
              setDiffFormFunction={setDiffFormFunction}
              isEmptyError={isEmptyError}
            />
          ))}
      </LoadableComponent>
    </>
  );
};

interface InputText {
  value: string;
}

// TODO: Redux管理にする
const EnqueteAnswerDiffPlanAndPlanForm: React.FC<EnqueteAnswerDiffFormProps> = (props) => {
  const { title, labelTitle, lines, diffValue, row, diffFormData, setDiffFormFunction, isEmptyError } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  // nameを定義
  const diffMemberInputName = 'diffMemberInputValue';
  const diffMemberSelectReasonName = 'diffMemberReasonSelectValue';
  const diffMemberInputReasonName = 'diffMemberReasonInputValue';

  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [diffMemberInputValue, setDiffMemberInputValue] = useState<InputText>({ value: '' });
  const [diffMemberSelectReasonValue, setDiffMemberSelectReasonValue] = useState<InputText>({ value: '' }); //eslint-disable-line
  const [diffMemberInputReasonValue, setDiffMemberInputReasonValue] = useState<InputText>({ value: '' });
  const [isInputValueError, setIsInputValueError] = useState(false);
  const [isEmptyValueError, setIsEmptyValueError] = useState(false);
  const [isInputHeadError, setIsInputHeadError] = useState(false);
  const [isHeadOnlyValueError, setIsHeadOnlyValueError] = useState(false);
  const [isZeroValueError, setIsZeroValueError] = useState(false);
  const [isFormatValueError, setIsFormatValueError] = useState(false);
  const [isEmptyReasoneError, setIsEmptyReasoneError] = useState(false);

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    const checkTargetValue = row.diffMemberInputValue !== null ? row.diffMemberInputValue.toString() : null;
    // 半角の+-数値ではない
    const matchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/[+\-0-9]*/) : null;

    if (matchFieldValue !== null && matchFieldValue?.input !== '' && matchFieldValue[0] !== matchFieldValue?.input) {
      setIsInputValueError(true);
    } else {
      setIsInputValueError(false);

      // 先頭に半角の + または -が無い
      const secondMatchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]/) : null;

      if (secondMatchFieldValue === null && checkTargetValue !== null && checkTargetValue !== '') {
        setIsInputHeadError(true);
      } else {
        setIsInputHeadError(false);

        // 先頭に半角の + または -のみ
        const checkHeadOnlyValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]$/) : null;
        if (checkHeadOnlyValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
          setIsHeadOnlyValueError(true);
        } else {
          setIsHeadOnlyValueError(false);

          // 不正な値(+0 / -0)
          const checkZeroValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[0]+$/) : null;
          if (checkZeroValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
            setIsZeroValueError(true);
          } else {
            setIsZeroValueError(false);

            // 不正な形式(+10++0 / ++100 / +010)
            const checkFormatValueError: RegExpMatchArray | null =
              checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[1-9]{1}[0-9]{0,4}$/) : null;

            if (checkFormatValueError === null && checkTargetValue !== null && checkTargetValue !== '') {
              setIsFormatValueError(true);
            } else {
              setIsFormatValueError(false);
            }
          }
        }
      }
    }

    // 空白エラーが有効になっている場合、編集したらエラーを解除する
    if (isEmptyError) {
      const checkTargetInputReasonValue = row.diffMemberReasonInputValue !== null ? row.diffMemberReasonInputValue : null;
      // 差異理由が存在する
      if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue !== '') {
        if (checkTargetValue !== null && checkTargetValue.length !== 0) {
          setIsEmptyValueError(false);
        }
      } else {
        setIsEmptyValueError(false);
      }

      // 差異人数が存在する
      if (checkTargetValue !== null && checkTargetValue !== '') {
        if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue.length !== 0) {
          setIsEmptyReasoneError(false);
        }
      } else {
        setIsEmptyReasoneError(false);
      }
    }
    setDiffMemberInputValue({ value: row.diffMemberInputValue });
    setDiffMemberSelectReasonValue({ value: row.diffMemberReasonSelectValue });
    setDiffMemberInputReasonValue({ value: row.diffMemberReasonInputValue ? row.diffMemberReasonInputValue : '' });
  }, [row.diffMemberInputValue, row.diffMemberReasonSelectValue, row.diffMemberReasonInputValue]); //eslint-disable-line

  useEffect(() => {
    if (isEmptyError) {
      const checkTargetValue = row.diffMemberInputValue !== null ? row.diffMemberInputValue.toString() : null;
      const checkTargetInputReasonValue = row.diffMemberReasonInputValue !== null ? row.diffMemberReasonInputValue : null;

      // 差異理由が存在する
      if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue !== '') {
        if (checkTargetValue === null || checkTargetValue.length === 0) {
          setIsEmptyValueError(true);
        } else {
          setIsEmptyValueError(false);
        }
      } else {
        setIsEmptyValueError(false);
      }

      // 差異人数が存在する
      if (checkTargetValue !== null && checkTargetValue !== '') {
        if (checkTargetInputReasonValue === null || checkTargetInputReasonValue.length === 0) {
          setIsEmptyReasoneError(true);
        } else {
          setIsEmptyReasoneError(false);
        }
      } else {
        setIsEmptyReasoneError(false);
      }
    }
  }, [isEmptyError]); //eslint-disable-line

  // 入力内容を反映させる
  const handleInputDiffChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName: string = event.target.name;
    const fieldValue: string = event.target.value;

    // フィールドをアップデート
    if (fieldName === diffMemberInputName) {
      // 全角数字だと入力制限を突破できるので対応
      setDiffMemberInputValue({ value: fieldValue.substring(0, 6) });
    } else if (fieldName === diffMemberSelectReasonName) {
      setDiffMemberSelectReasonValue({ value: fieldValue });
    } else if (fieldName === diffMemberInputReasonName) {
      setDiffMemberInputReasonValue({ value: fieldValue });
    }

    // 親のstateをアップデート
    let newFormData =
      diffFormData && diffFormData.length
        ? _.cloneDeep(diffFormData)
        : [...Array(lines)].map((_, i) => {
            return {
              count: i,
              diffMemberInputValue: '',
              diffMemberReasonSelectValue: '',
              diffMemberReasonInputValue: '',
            };
          });

    let newResultAndPlan: Row = _.cloneDeep(row);

    // @ts-ignore
    if (fieldName === diffMemberInputName) {
      newResultAndPlan[fieldName] = fieldValue.substring(0, 6);
    } else {
      // @ts-ignore
      newResultAndPlan[fieldName] = fieldValue;
    }

    newFormData[row.count] = newResultAndPlan;

    setDiffFormFunction(newFormData);
  };

  return (
    <Box component={'div'} sx={{ mb: 1, pb: '3px' }}>
      <Grid item xs={12} md={12} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={1.3}>
          <Box style={currentLang.value === 'ja' ? {} : { whiteSpace: 'pre-line', fontSize: '10px' }}>{row.count === 0 ? title : ''}</Box>
        </Grid>

        <Grid item xs={12} md={1.7}>
          {row.count === 0 && <TextField disabled label={labelTitle} value={diffValue} size="small" variant="standard" />}
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={isInputValueError || isInputHeadError || isHeadOnlyValueError || isZeroValueError || isFormatValueError || isEmptyValueError}
            name={diffMemberInputName}
            label={t('enqueteAnswerInput.textfield.differenceCount.label')}
            size="small"
            fullWidth={true}
            inputProps={{ maxLength: 6 }}
            value={diffMemberInputValue.value}
            onChange={handleInputDiffChange}
            helperText={
              isInputValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText1')
                : isInputHeadError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText2')
                : isHeadOnlyValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText6')
                : isZeroValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText4')
                : isFormatValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText5')
                : isEmptyValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText6')
                : ''
            }
            disabled={!isEnqueteAnswerOpen}
          />
        </Grid>
        <Grid item xs={12} md={4.6}>
          <TextField
            error={isEmptyError && isEmptyReasoneError}
            name={diffMemberInputReasonName}
            fullWidth={true}
            label={t('enqueteAnswerInput.textfield.differenceReason.label')}
            size="small"
            inputProps={{ maxLength: 200 }}
            value={diffMemberInputReasonValue.value}
            onChange={handleInputDiffChange}
            helperText={isEmptyError && isEmptyReasoneError ? t('enqueteAnswerInput.textfield.differenceReason.helperText1') : ''}
            disabled={!isEnqueteAnswerOpen}
          />
        </Grid>

        <Grid item xs={12} md={1.4} />
      </Grid>
    </Box>
  );
};
