import { Box, Button, Grid, Modal, Stack, SxProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack5';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateConfirmRequest, useConfirmRequestApi } from 'src/api/useConfirmRequestApi';
import { useUserApi } from 'src/api/useUserApi';
import { NetworkAccessError } from 'src/errors/errors';
import { setComment, setRowData, setSelectedUser, setShowConfirm } from 'src/features/general/enquete-answer/store/selectConfirmerModalSlice';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState } from 'src/redux/store';
import palette from 'src/theme/palette';
interface Props {
  answerId: string | undefined;
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export const SubmitConfirmModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userApi = useUserApi();
  const confirmRequestApi = useConfirmRequestApi();
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { comment, rowData } = useSelector((state: RootState) => state.selectConfirmerModal);
  const { enqueueSnackbar } = useSnackbar();
  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [isDisableBtn, setIsDisableBtn] = useState<boolean>(false);

  //-------------------------------------------------------------
  // SxProps
  //-------------------------------------------------------------
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80vw',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  //-------------------------------------------------------------
  // 送信処理
  //-------------------------------------------------------------
  const onSend = async () => {
    if (!props.answerId) throw Error(t('answerConfirmStatus.selectConfirmerModal.message.exceptionMissingIdentificationNumber'));
    if (!user?.email) throw Error(t('answerConfirmStatus.selectConfirmerModal.message.exceptionNoEmailForTheLoginUser'));

    // 自分の情報を取得
    const myInfo = await userApi.getByEmail(user.email);
    if (!myInfo || myInfo.length === 0) {
      throw Error(t('message.exceptionNotFoundUserData'));
    }

    // Validation
    if (!myInfo[0].email) {
      throw Error(t('answerConfirmStatus.selectConfirmerModal.message.exceptionMissingRequesterEmail'));
    }
    if (!comment) {
      throw Error(t('answerConfirmStatus.selectConfirmerModal.message.exceptionNoComments'));
    }

    //OKボタンを無効にする
    setIsDisableBtn(true);

    // 送信用データを構築
    const data: CreateConfirmRequest[] = [];
    for (const row of rowData) {
      if (!row.email) {
        setIsDisableBtn(false);
        throw Error(t('answerConfirmStatus.selectConfirmerModal.message.exceptionNotExistRequestedEmail'));
      }

      data.push({
        answerId: props.answerId!,
        requesterId: myInfo[0].email,
        requesterName: myInfo[0].fullNameJpn ?? '',
        requesterNameEng: myInfo[0].fullNameEng,
        requesterEmail: myInfo[0].email,
        requesterBluePageEmailAddress: myInfo[0].bluePageEmailAddress,
        requestComment: comment!,
        confirmerId: row.email!,
        confirmerEmail: row.email!,
        confirmerName: row.fullNameJpn!,
        confirmerNameEng: row.fullNameEng!,
        confirmerBluePageEmailAddress: row.bluePageEmailAddress,
      });
    }

    // 保存
    try {
      await confirmRequestApi.createMany(data);
    } catch (error) {
      if (error instanceof NetworkAccessError) {
        enqueueSnackbar(t('apiExceptionMessage.useConfirmRequestApi.failedSendConfirmRequest'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
      }
    }

    // 状態をクリアする
    dispatch(setSelectedUser(null));
    dispatch(setRowData([]));
    dispatch(setComment(null));

    // 保存に成功したらモーダルを閉じる
    dispatch(setShowConfirm(false));
    props.onClose();

    // 呼び出し元画面の完了処理を行う
    props.onComplete();

    //OKボタンを有効にする
    setIsDisableBtn(false);
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={{ ...modalStyle, width: '400px' }}>
        <Stack spacing={4}>
          {/* modal title */}
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h4'} component={'h2'} children={t('answerConfirmStatus.sendingConfirmationModal.title')} />
            </Grid>
          </Grid>

          {/* message block */}
          <Grid container>
            <Grid item xs={12} justifyContent="center">
              <Typography>{t('answerConfirmStatus.sendingConfirmationModal.description')} </Typography>
            </Grid>
          </Grid>

          {/* action block */}
          <Grid container>
            <Grid item xs={12}>
              <Stack direction={'row-reverse'} spacing={1}>
                <Button variant={'contained'} onClick={onSend} children={t('button.ok')} disabled={isDisableBtn} />
                <Button variant={'contained'} onClick={() => dispatch(setShowConfirm(false))} children={t('button.cancel')} color="error" />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};
