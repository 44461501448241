import { RowClassParams, RowStyle } from 'ag-grid-community';

export const RowStyleByPersonnelSurvey = (params: RowClassParams): RowStyle => {
  if (
    undefined !== params.data &&
    params.data.inputItem.match('Total') &&
    params.data.inputItem !== 'Admin Person Total' &&
    params.data.inputItem !== 'Sales Person Total'
  ) {
    return {
      backgroundColor: '#F4F6F8',
      fontWeight: 'bold',
      textAlign: 'end',
    };
  }
  if (
    undefined !== params.data &&
    (params.data.inputItem.match('人員') || params.data.inputItem === 'Admin Person Total' || params.data.inputItem === 'Sales Person Total')
  ) {
    return {
      backgroundColor: '#F4F6F8',
      fontWeight: 'bold',
    };
  } else {
    return {};
  }
};

export const DarkRowStyleByPersonnelSurvey = (params: RowClassParams): RowStyle => {
  if (
    undefined !== params.data &&
    params.data.inputItem.match('Total') &&
    params.data.inputItem !== 'Admin Person Total' &&
    params.data.inputItem !== 'Sales Person Total'
  ) {
    return {
      backgroundColor: '#0b0907',
      fontWeight: 'bold',
      textAlign: 'end',
    };
  }
  if (params.data.inputItem.match('人員') || params.data.inputItem === 'Admin Person Total' || params.data.inputItem === 'Sales Person Total') {
    return {
      backgroundColor: '#0b0907',
      fontWeight: 'bold',
    };
  } else {
    return {};
  }
};

export const NotInputRowStyleByPersonnelSurvey = (params: RowClassParams): RowStyle => {
  if (
    undefined !== params.data &&
    params.data.inputItem.match('Total') &&
    params.data.inputItem !== 'Admin Person Total' &&
    params.data.inputItem !== 'Sales Person Total'
  ) {
    return {
      backgroundColor: '#F4F6F8',
      fontWeight: 'bold',
      textAlign: 'end',
      color: '#919EAB',
    };
  }
  if (params.data.inputItem.match('人員') || params.data.inputItem === 'Admin Person Total' || params.data.inputItem === 'Sales Person Total') {
    return {
      backgroundColor: '#F4F6F8',
      fontWeight: 'bold',
      color: '#919EAB',
    };
  } else {
    return {
      backgroundColor: '#F4F6F8',
      color: '#919EAB',
    };
  }
};

export const NotInputDarkRowStyleByPersonnelSurvey = (params: RowClassParams): RowStyle => {
  if (
    undefined !== params.data &&
    params.data.inputItem.match('Total') &&
    params.data.inputItem !== 'Admin Person Total' &&
    params.data.inputItem !== 'Sales Person Total'
  ) {
    return {
      backgroundColor: '#0b0907',
      fontWeight: 'bold',
      textAlign: 'end',
      color: '#919EAB',
    };
  }
  if (params.data.inputItem.match('人員') || params.data.inputItem === 'Admin Person Total' || params.data.inputItem === 'Sales Person Total') {
    return {
      backgroundColor: '#0b0907',
      fontWeight: 'bold',
      color: '#919EAB',
    };
  } else {
    return {
      backgroundColor: '#0b0907',
      color: '#919EAB',
    };
  }
};
