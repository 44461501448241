import { GridApi } from 'ag-grid-community';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack5';
import { MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerSearchCondition, PartialCustomerData, useCustomerApi } from 'src/api/useCustomerApi';
import { SearchConditionProps } from 'src/components/app-components/bonsai/SearchModal';
import { COMPLICATED_CUSTOMERS, FORM_TYPE } from 'src/constants';
import { useAppSelector } from 'src/redux/store';
import { SearchConditionList } from './useSearchConditionList';

interface Props {
  list: {
    parentCustomer: SearchConditionList[];
    countries: SearchConditionList[];
    regions: SearchConditionList[];
  };
  methods: {
    setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  };
  customerCondition: string[] | undefined;
  // 除外条件
  excludeCondition: {
    // 世界最上位取引先と取引先を指定して除外対象にする
    // 除外したい取引先コード 例）系列別の場合：トヨタ車体、日野、ダイハツが対象
    customerCode: string[];
    // 除外したい親取引先コード 例）系列別の場合：トヨタ車体、日野、ダイハツが対象
    parentCustomerCode: string[];
    // 除外したい世界最上位取引先コード 例）系列別の場合：トヨタ自動車が対象
    topGlobalCustomerCode: string[];
    // 除外条件を実施する取引先
    excludeCustomer: string[];
  };
  gridApi: MutableRefObject<GridApi<any> | null>;
  detailCondition?: {
    entryDate?: string;
  };
}
interface SearchCondition {
  keyword?: string;
  parentCustomerCode?: string;
  topGlobalCustomerCode?: string;
  countryCode?: string;
  regionCode?: string;
}

export interface CustomerEntity {
  customerCode: string;
  customerNameJpn: string;
  customerNameEng: string;
  topGlobalCustomerCode: string;
  topDomesticCustomerCode: string;
  parentCustomerCode?: string;
  parentCustomerNameJpn?: string;
  parentCustomerNameEng?: string;
  regionCode: string;
  regionNameJpn?: string;
  regionNameEng?: string;
  countryCode: string;
  countryNameJpn?: string;
  countryNameEng?: string;
  address?: string;
  estabDate?: Date;
  entryDate?: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  isDeleted: boolean;
}

export const useSearchCustomer = (props: Props) => {
  const { list, methods, customerCondition, excludeCondition, gridApi, detailCondition } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { surveyDetailData } = useAppSelector((state) => state.enqueteAnswer);
  const { getCustomersByFilter } = useCustomerApi();
  const [condition, setCondition] = useState<SearchCondition>({});
  const [orgs, setOrgs] = useState<PartialCustomerData[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(true);

  const isSeriesEnquete = surveyDetailData.surveyHeader?.formType === FORM_TYPE.SERIES;

  const onChange = async (key: string, value: string | undefined) => {
    setIsSearchLoading(true);
    const cond = condition;

    switch (key) {
      case 'keyword':
        cond.keyword = value === '' ? undefined : value;
        break;
      case 'parentCustomer':
        cond.parentCustomerCode = value;
        break;
      case 'country':
        cond.countryCode = value;
        break;
      case 'region':
        cond.regionCode = value;
        break;
    }

    let where: CustomerSearchCondition = {
      keyword: cond.keyword,
      parentCustomerCode: cond.parentCustomerCode ? [cond.parentCustomerCode] : undefined,
      topGlobalCustomerCode: customerCondition,
      countryCode: cond.countryCode,
      regionCode: cond.regionCode,
      isDeleted: false,
      limit: 100,
    };
    // 除外条件に指定された取引先が親取引先に指定された場合
    if (excludeCondition?.excludeCustomer?.includes(value ?? '')) {
      where = {
        ...where,
        not: [
          {
            and: {
              topGlobalCustomerCode: excludeCondition?.topGlobalCustomerCode,
              parentCustomerCode: excludeCondition?.parentCustomerCode,
            },
            or: {
              customerCode: excludeCondition?.customerCode,
            },
          },
        ],
      };
    }
    setCondition(cond);
    const res = await getCustomer(where);
    // 初期描画時以外で取引先の取得件数が100件以上の場合警告のスナックバーを5秒表示する。
    if (res?.length !== undefined && res?.length >= 100) {
      enqueueSnackbar(t('message.warningGettingMaximumNumber', { max: '100' }), { variant: 'warning', autoHideDuration: 5000 });
    }
    gridApi.current?.deselectAll();
    methods.setIsSelected(false);
  };

  const getCustomer = async (where: CustomerSearchCondition): Promise<PartialCustomerData[] | undefined> => {
    const response = await getCustomersByFilter(where);
    let result: PartialCustomerData[] = [];
    if (!response) return;

    for (const res of response) {
      const judgeParentCustomer = (customerValue: string, parentValue: string, topGlobalValue: string) => {
        // 系列別アンケートの場合は親取引先が日野、ダイハツ、トヨタ車体の3社を除いたすべての取引先において、世界最上位取引先を親取引先として、ダイアログで表示する
        if (isSeriesEnquete) return COMPLICATED_CUSTOMERS.includes(res.parentCustomerCode) ? parentValue : topGlobalValue;
        return parentValue || topGlobalValue || customerValue;
      };
      const entryDate = res.entryDate ? new Date(res.entryDate) : null;
      // ダイアログに表示する親取引先を設定
      const newResponse = {
        ...res,
        parentCustomerCode: judgeParentCustomer(res.customerCode, res.parentCustomerCode, res.topGlobalCustomerCode),
        parentCustomerNameJpn: judgeParentCustomer(res.customerNameJpn, res.parentCustomerNameJpn, res.topGlobalCustomerNameJpn),
        parentCustomerNameEng: judgeParentCustomer(res.customerNameEng, res.parentCustomerNameEng, res.topGlobalCustomerNameEng),
        entryDate: entryDate ? format(entryDate, 'yyyy-MM-dd') : '',
      };
      result.push(newResponse);
    }
    setOrgs(result ?? []);
    gridApi.current?.deselectAll();
    setIsSearchLoading(false);
    return result;
  };

  useEffect(() => {
    const where: CustomerSearchCondition = {
      topGlobalCustomerCode: customerCondition?.length === 0 ? undefined : customerCondition,
      entryDate: detailCondition?.entryDate,
      isDeleted: false,
      limit: 100,
    };
    getCustomer(where);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索条件を定義
  const labelPrefix = 'common';
  const makeSearchConditionProp = (
    key: string,
    style: { xs: number },
    value: string | undefined | null,
    list: { key: string; label: string }[],
  ): SearchConditionProps => {
    const selectedValue = value === undefined ? null : value;

    return {
      id: key,
      label: t(`${labelPrefix}.${key}`),
      style,
      type: 'autocomplete',
      props: {
        value: selectedValue,
        list,
        onChange: (value) => onChange(key, value),
      },
    };
  };

  const conditions: SearchConditionProps[][] = [
    list.parentCustomer.length !== 0 ? [makeSearchConditionProp('parentCustomer', { xs: 6 }, condition.parentCustomerCode, list.parentCustomer)] : [],
    [
      {
        type: 'text',
        id: 'keyword',
        label: t(`enqueteAnswerInput.modal.selectCustomer.condition.customerInfo`),
        placeholder: t(`enqueteAnswerInput.modal.selectCustomer.condition.placeholder`),
        style: { xs: 6 },
        props: {
          value: condition.keyword ?? '',
          onBlur: (value) => onChange('keyword', value),
        },
      },
      makeSearchConditionProp('region', { xs: 3 }, condition.regionCode, list.regions),
      makeSearchConditionProp('country', { xs: 3 }, condition.countryCode, list.countries),
    ],
  ];

  return { isSearchLoading, conditions, orgs };
};
