import { Button, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack5';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCommentsApi } from 'src/api/getCommentsApi';
import { fetchComments } from 'src/features/general/enquete-threads/store/enqueteCommentsActions';
import { dispatch } from 'src/redux/store';

type FormProps = {
  threadId: string;
};

export const CommentForm = ({ threadId }: FormProps) => {
  const methods = useForm({ mode: 'onChange', shouldUnregister: false });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { createComment } = getCommentsApi();

  const onSubmit = async (data: FieldValues) => {
    try {
      await createComment(data.comment, threadId);
    } catch (error) {
      enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('enqueteThread.common.comment') }), { variant: 'error' });
    }
    await dispatch(fetchComments(threadId));
  };

  const validationRules = {
    required: t('validateError.required', { target: t('enqueteThread.common.comment') }),
    maxLength: { value: 3000, message: t('validateError.maxLength', { max: '3,000' }) },
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="comment"
          control={methods.control}
          defaultValue=""
          rules={validationRules}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={t('enqueteThread.common.comment')}
              variant="outlined"
              fullWidth
              multiline
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              rows={5}
            />
          )}
        />
      </div>
      <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 1, mb: 3 }} disabled={!methods.formState.isValid}>
          {t('button.send')}
        </Button>
      </Grid>
    </form>
  );
};
