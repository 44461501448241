import { useSnackbar } from 'notistack5';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { ENQUETE_REQUEST_PATH } from '../../enquete-request/routes/path';
import { EnqueteStatusListPageLayout } from '../components/pages/list/components/EnqueteStatusListPageLayout';

export const EnqueteStatusListPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  //-------------------------------------------------------------
  // ぱんくず設定
  //-------------------------------------------------------------
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('enqueteStatus.pageTitle'),
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('enqueteRequest.pageTitle'), href: ENQUETE_REQUEST_PATH.INDEX },
    ],
    activeLast: true,
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    // RespondentRequestIDがクエリに含まれているか確認
    if (!id) {
      enqueueSnackbar(t('enqueteStatus.message.notFoundRespondentRequestId'), { variant: 'error' });
    }
  }, []); // eslint-disable-line

  return <EnqueteStatusListPageLayout pageTitle={t('enqueteStatus.pageTitle')} breadCrumbs={breadcrumbs} />;
};
