import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GeneralEnqueteJsonInputType, GENERAL_ENQUETE_JSON_INPUT_TYPE } from 'src/@types/generalEnquete';
import { clearQuestionItemOrValidation, setInputType } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useDispatch, useSelector } from 'src/redux/store/index';

const FormTypeButtons = () => {
  const question = useSelector((state: RootState) => state.enqueteCreate.question);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleButtonOnClick = (inputType: string) => {
    dispatch(clearQuestionItemOrValidation());
    dispatch(setInputType(inputType));
  };

  const render = (inputType: GeneralEnqueteJsonInputType) => {
    if (question.inputType === '') {
      return (
        <Grid item>
          <Tooltip title={t(`button.tooltip.${inputType}`) ?? ''}>
            <Button variant="outlined" sx={{ ml: 2 }} onClick={() => handleButtonOnClick(inputType)}>
              {t(`button.${inputType}`)}
            </Button>
          </Tooltip>
        </Grid>
      );
    }
    if (question.inputType === inputType) {
      return <Grid item>{t(`button.${inputType}`)}</Grid>;
    }
    return null;
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2, mt: 2 }} spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">{t('enqueteCreate.answerType')}</Typography>
      </Grid>
      {render(GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT)}
      {render(GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXTAREA)}
      {render(GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX)}
      {render(GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP)}
      {render(GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT)}
    </Grid>
  );
};

export default FormTypeButtons;
