import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateRespondentManagerEntityData, CreateRespondentManagersEntityData, useRespondentManagerApi } from 'src/api/useRespondentManagerApi';
import { UserEntity, useUserApi } from 'src/api/useUserApi';

export const useRespondentManagerTabGrid = (surveyDetailId: string) => {
  const { getByEmail } = useUserApi();
  const { enqueueSnackbar } = useSnackbar();
  const { getBySurveyDetailId, getByUnique, add, removeById } = useRespondentManagerApi();
  const { t } = useTranslation();

  const [rowData, setRowData] = useState<UserEntity[]>([]);
  //ローディング
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // grid api
  const gridApi = useRef<GridApi | null>(null);

  //-------------------------------------------------------------
  // GridApiを取得するための関数
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // GridApiを取得するための関数
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    if (!surveyDetailId) return;

    // ローディング開始
    setIsLoading(true);

    // アンケート依頼先管理者のリストを取得
    // この時点ではemailしかユーザー情報がわかっていないため、後で詳細をポータルから取得する
    const respondentManagers = await getBySurveyDetailId(surveyDetailId);
    if (!respondentManagers || respondentManagers.length === 0) {
      setRowData([]);
      // ローディング終了
      setIsLoading(false);
      return;
    }

    // 一人以上依頼先管理者がいる場合はポータルから詳細を取得する
    const emails: string[] = [];
    for (const respondentManager of respondentManagers) {
      emails.push(respondentManager.userId);
    }

    // ユーザーの詳細を取得
    const users = await getByEmail(emails, null);
    if (!users) {
      setRowData([]);
      // ローディング終了
      setIsLoading(false);
      return;
    }
    // RowDataの更新
    setRowData(users);
    setIsLoading(false);
  };

  //-------------------------------------------------------------
  // 追加するRowDataのバリデーションを行う処理
  //-------------------------------------------------------------
  const onValidation = (values: UserEntity[]) => {
    // 追加行を１つずつ走査
    for (const value of values) {
      const jsonValue = JSON.stringify(value);
      // 既にページ用のAgGridのRowDataに存在していないか確認
      for (const row of rowData) {
        const jsonRow = JSON.stringify(row);
        if (jsonValue === jsonRow) {
          return false;
        }
      }
    }
    return true;
  };

  //-------------------------------------------------------------
  // RowDataに行データの追加を行う処理
  //-------------------------------------------------------------
  const onModalOk = async (values: UserEntity[]) => {
    // 登録用データを作成
    const data: CreateRespondentManagerEntityData[] = [];
    if (surveyDetailId) {
      for (const value of values) {
        if (value.email) {
          data.push({
            userId: value.email,
            bluePageEmailAddress: value.bluePageEmailAddress,
          });
        } else {
          return enqueueSnackbar(t('enqueteCreate.message.canNotRegistered'), { variant: 'error' });
        }
      }
    } else {
      return enqueueSnackbar(t('enqueteCreate.message.notCreated'), { variant: 'error' });
    }
    const datas: CreateRespondentManagersEntityData = { createRespondentManagers: data };
    await add(surveyDetailId, datas);
    await refreshRowData();
  };

  //-------------------------------------------------------------
  // 管理者の情報を削除する処理
  //-------------------------------------------------------------
  const onRowDelete = async (params: UserEntity | undefined) => {
    if (!surveyDetailId) return enqueueSnackbar(t('enqueteCreate.message.notCreated'), { variant: 'error' });

    if (!params) return;
    if (!params.email) return enqueueSnackbar(t('enqueteCreate.message.canNotDeleted'), { variant: 'error' });

    // RespondentManagersのIDを取得
    const respondentManagers = await getByUnique(surveyDetailId, params.email);
    if (!respondentManagers) return;

    // 削除
    if (respondentManagers.length > 0) {
      await removeById(surveyDetailId, respondentManagers[0].id);
    }

    // 一覧のリフレッシュ
    await refreshRowData();
  };

  useEffect(() => {
    refreshRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetailId]);

  return { isLoading, rowData, onGridReady, onRowDelete, onModalOk, onValidation };
};
