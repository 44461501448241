import _ from 'lodash';
import { SeriesAggregationSbuInfo, SeriesSurveyFormAggregationData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

export const useEnqueteCommon = () => {
  /**
   * listにaddDataを追加し、本部コードの昇順にソートする.
   * 追加時にlist内に同じデータがある場合同じデータ階層の部分のみaddDataを追加する
   * @param list 系列別集約先設定データリスト
   * @param addData 追加を行う系列別集約先設定
   * @returns 本部コード順にソートされたリスト
   */
  const concatAndSort = (list: SeriesSurveyFormAggregationData[], addData: SeriesSurveyFormAggregationData) => {
    // 追加
    let result: SeriesSurveyFormAggregationData[] = [...list];
    // 前提としてSBU集約情報に同じ本部は最大１件のみ
    let registeredData: SeriesSurveyFormAggregationData | undefined = list.find((data) => data.divisionCode === addData.divisionCode);

    // 追加する本部情報が登録データに存在しない場合
    if (!registeredData) {
      result = result.concat([addData]);
    } else {
      // 登録データから追加される本部情報以外を作成
      result = list.filter((v) => v.divisionCode !== addData.divisionCode);

      // 登録データと追加データの集約先、集約元の情報の格納先を作成
      let addSbuInfo: SeriesAggregationSbuInfo[] = [];

      // 登録データと追加データを合わせた集約先、集約元の情報を作成する
      for (const addSbu of addData.sbu) {
        let isEdit = false; // 追加データを集約先として格納したか、フラグ
        for (const sbu of registeredData.sbu) {
          // 追加する集約先が、登録データとしてすでに集約先として存在するかチェック
          if (addSbu.destination.sbuCode === sbu.destination.sbuCode) {
            const newSbu: SeriesAggregationSbuInfo = {
              destination: addSbu.destination,
              source: [...sbu.source, ...addSbu.source],
            };
            addSbuInfo.push(newSbu);
            isEdit = true;
          } else {
            addSbuInfo.push(sbu);
          }
        }
        // 追加データが集約先として登録されていない場合
        if (!isEdit) {
          addSbuInfo.push(addSbu);
        }
      }

      // 本部情報を追加
      result.push({
        divisionCode: addData.divisionCode,
        divisionNameJpn: addData.divisionNameJpn,
        divisionNameEng: addData.divisionNameEng,
        organizationId: addData.organizationId,
        sbu: Array.from(new Map(addSbuInfo.map((sbu) => [sbu.destination, sbu])).values()), // addSbuInfoは同じ登録データが格納されているためユニーク化
      });
    }
    return sortSeriesSurveyFormDataList(result);
  };

  /**
   * 本部コード順にソートを行う
   * SBU集約先は、SBUコード順にソートを行う
   * SBU集約元は、SBUコード順にソートを行う
   * @param list ソートされるリスト
   * @returns ソートされたリスト
   */
  const sortSeriesSurveyFormDataList = (list: SeriesSurveyFormAggregationData[]): SeriesSurveyFormAggregationData[] => {
    const newList = _.cloneDeep(list);
    newList
      .sort((a, b) => {
        // 本部ソート
        if (a.divisionCode > b.divisionCode) {
          return 1;
        }
        if (a.divisionCode < b.divisionCode) {
          return -1;
        }
        return 0;
      })
      .forEach((data) => {
        data.sbu.sort((a, b) => {
          // SBU集約先ソート
          if (a.destination.sbuCode > b.destination.sbuCode) return 1;
          if (a.destination.sbuCode < b.destination.sbuCode) return -1;
          return 0;
        });

        data.sbu.forEach((s) => {
          s.source.sort((a, b) => {
            // SBU集約元ソート
            if (a.sbuCode > b.sbuCode) return 1;
            if (a.sbuCode < b.sbuCode) return -1;
            return 0;
          });
        });
      });

    return newList;
  };

  return { concatAndSort, sortSeriesSurveyFormDataList };
};
