import { Box, Button, Chip, Divider, Tooltip, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBearerToken } from 'src/api/useBearerToken';
import { MIconButton } from 'src/components/@material-extend';
import MenuPopover from 'src/components/MenuPopover';
import MyAvatar from 'src/components/MyAvatar';
import { portalApi } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import usePageTracking from 'src/utils/useTracking';

type responseType = {
  id: string;
  name: string;
};

type UserInfo = {
  company: responseType;
  sbu: responseType;
  department: responseType;
  headquarter: responseType;
  group: responseType;
  name: string;
  roleNameList: responseType[];
};

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      //if (isMountedRef.current) {
      navigate('/');
      handleClose();
      //}
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  const getInfo = async () => {
    let result;
    try {
      const token = await getBearerToken();
      result = await axios.get(portalApi + '/user/v1.1/userInfo', {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });
    } catch (e) {}
    setUserInfo(result?.data);
  };
  useEffect(() => {
    getInfo();
  }, []);

  usePageTracking(
    userInfo
      ? {
          companyCode: userInfo.company.id,
          divisionCode: userInfo.headquarter.id,
          sbuCode: userInfo.sbu.id,
          departmentCode: userInfo.department.id,
          groupCode: userInfo.group.id,
        }
      : { companyCode: '', departmentCode: '', sbuCode: '' },
  );

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}>
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 480 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" mt={1}>
            {t('user.name')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {userInfo?.name}
          </Typography>
          <Typography variant="subtitle2" mt={1}>
            {t('user.company')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {userInfo?.company.name} ({userInfo?.company.id})
          </Typography>
          {userInfo?.headquarter.id ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.division')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.headquarter.name} ({userInfo?.headquarter.id})
              </Typography>{' '}
            </>
          ) : (
            <></>
          )}
          {userInfo?.sbu.id ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.sbu')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.sbu.name} ({userInfo?.sbu.id})
              </Typography>{' '}
            </>
          ) : (
            <></>
          )}
          {userInfo?.department.id ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.department')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.department.name} ({userInfo?.department.id})
              </Typography>
            </>
          ) : (
            <></>
          )}
          {userInfo?.group.id ? (
            <>
              <Typography variant="subtitle2" mt={1}>
                {t('user.group')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userInfo?.group.name} ({userInfo?.group.id})
              </Typography>{' '}
            </>
          ) : (
            <></>
          )}
          {userInfo?.roleNameList ? (
            <Typography variant="subtitle2" mt={1}>
              {t('user.role')}
            </Typography>
          ) : (
            <></>
          )}
          {userInfo?.roleNameList.map((output, index) => {
            return (
              <Tooltip title={output.name} arrow key={output.name}>
                <Chip style={{ maxWidth: '180px' }} label={output.name} size="small" variant="filled" />
              </Tooltip>
            );
          })}
          {userInfo?.roleNameList ? (
            <Typography variant="subtitle2" mt={1}>
              {t('user.roleId')}
            </Typography>
          ) : (
            <></>
          )}
          {userInfo?.roleNameList.map((output, index) => {
            return (
              <Tooltip title={output.id} arrow key={output.id}>
                <Chip style={{ maxWidth: '180px' }} label={output.id} size="small" variant="filled" />
              </Tooltip>
            );
          })}
          <Typography variant="subtitle2" mt={1}>
            Email :
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t('button.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
