import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { switchLabel } from 'src/utils/labelUtils';
import { TargetOrganization } from '../hooks/useChangeRespondentModal';

export const createRespondentTargetOrgData = (respondent: RespondentsListEntity, isLangJpn: boolean): TargetOrganization => {
  return {
    id: '',
    companyName: switchLabel(respondent.targetCompanyNameJpn, respondent.targetCompanyNameEng, isLangJpn),
    companyCode: respondent.companyCode,
    departmentName: switchLabel(respondent.targetDepartmentNameJpn, respondent.targetDepartmentNameEng, isLangJpn),
    departmentCode: respondent.targetDepartmentCode,
    sbuName: switchLabel(respondent.targetSbuNameJpn, respondent.targetSbuNameEng, isLangJpn),
    sbuCode: respondent.targetSbuCode,
    divisionName: switchLabel(respondent.targetDivisionNameJpn, respondent.targetDivisionNameEng, isLangJpn),
    divisionCode: respondent.targetDivisionCode,
    groupName: switchLabel(respondent.targetGroupNameJpn, respondent.targetGroupNameEng, isLangJpn),
    groupCode: respondent.targetGroupCode,
  };
};
