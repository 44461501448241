import { Box, Button, Grid } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralEnquete, Question } from 'src/@types/generalEnquete';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { REQUEST_STATUS } from 'src/constants';
import EnqueteLogicModal from 'src/features/general/enquete-create/components/modals/enquete-logic/components/EnqueteLogicModal';
import EnquetePreviewModal from 'src/features/general/enquete-create/components/modals/enquete-preview/components/EnquetePreviewModal';
import EnqueteTranslationSupportModal from 'src/features/general/enquete-create/components/modals/enquete-translation-support/components/EnqueteTranslationSupportModal';
import LogicErrorMessageModal from 'src/features/general/enquete-create/components/modals/logic-error-message/components/LogicErrorMessageModal';
import { EnqueteDescriptionForm } from 'src/features/general/enquete-create/components/tab-containers/common/components/enquete-description-form/components/EnqueteDescriptionForm';
import { useEnqueteSave } from 'src/features/general/enquete-create/hooks/useEnqueteSave';
import {
  clearQuestionItemOrValidation,
  initQuestions,
  setIsModalOpenTranslationSupport,
  setIsQuestionOpen,
  setQuestionDescriptionEng,
  setQuestionDescriptionJpn,
  setQuestions,
  setQuestionsEng,
  setQuestionsTranslationData,
  setRespondentRequest,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { setIsOpenEditor } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useModalHandlers } from '../../../../../../../hooks/useModalHandlers';
import { useControlButton } from '../hooks/useControlButton';
import EnqueteCreateQuestionPreviewForm from './EnqueteCreateQuestionPreviewForm';
import EnqueteCreateQuestionSwitcher from './EnqueteCreateQuestionSwitcher';
import FormTypeButtons from './common/components/FormTypeButtons';

export const GeneralQuestionTab: React.FC = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onClickSaveData } = useEnqueteSave();

  // store selector
  const isQuestionOpen = useAppSelector((state: RootState) => state.enqueteCreate.isQuestionOpen);
  const isQuestionDescriptionJpnValid = useAppSelector((state: RootState) => state.enqueteCreate.isQuestionDescriptionJpnValid);
  const isQuestionDescriptionEngValid = useAppSelector((state: RootState) => state.enqueteCreate.isQuestionDescriptionEngValid);
  const isOpenEditor = useAppSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit.isOpenEditor);
  const questionsJpn = useAppSelector((state: RootState) => state.enqueteCreate.questions);
  const isOpenLogicErrorMessageModal = useAppSelector((state: RootState) => state.enqueteCreate.isOpenLogicErrorMessageModal);
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const { isDisabledSave, isDisabledLogicSetting, isDisabledAddQuestion, hasPermission } = useControlButton(questionsJpn, enqueteData.surveyDetailId);
  const { onModalOpenPreview, onClickModalOpenLogic, onClickModalOpenTranslationSupport, onClickModalCloseLogicError } = useModalHandlers();
  const isTranslationDisabled =
    REQUEST_STATUS.BEFORE_REQUEST !== requestStatus || isOpenEditor || (questionsJpn && questionsJpn.length === 0) || !hasPermission;

  // memo:アンケート情報編集ダイアログを表示したときでも、編集中の質問を保持するためのフラグ
  // 初期表示時に質問を設定した後は、再セットしない。
  const [isLoadQuestions, setIsLoadQuestions] = useState(false);

  useEffect(() => {
    if (!isLoadQuestions) {
      dispatch(initQuestions());
      if (!enqueteData.surveyDetailId) {
        dispatch(setQuestionDescriptionJpn(''));
        dispatch(setQuestionDescriptionEng(''));
      } else {
        // Memo setQuestionsの設定タイミングを見直しをしたほうがよい。
        const tabData = enqueteData.surveyDetail?.surveyFormData?.formData.tabData[0] as GeneralEnquete;
        dispatch(setQuestions(tabData.questionsJpn as Question[]));
        dispatch(setQuestionsEng(tabData.questionsEng as Question[]));
        dispatch(setQuestionsTranslationData(tabData.questionsTranslationData as string[][]));
        dispatch(setQuestionDescriptionJpn(tabData.questionDescriptionJpn));
        dispatch(setQuestionDescriptionEng(tabData.questionDescriptionEng));
        setIsLoadQuestions(true); // 読み込み済みを設定
      }
      dispatch(setIsModalOpenTranslationSupport(false));
      dispatch(setRespondentRequest(enqueteData));
    }
  }, [dispatch, enqueteData, isLoadQuestions]);

  return (
    <>
      <EnqueteTranslationSupportModal />
      <EnquetePreviewModal />
      <EnqueteLogicModal />
      {/** ロジック設定エラーメッセージ */}
      <StyledModal isOpen={isOpenLogicErrorMessageModal} onCloseFunc={onClickModalCloseLogicError} modalTitle="">
        <LogicErrorMessageModal onCloseFunc={onClickModalCloseLogicError} />
      </StyledModal>

      <Box component={'div'} sx={{ mb: 1 }}>
        <Grid container alignItems={'center'} sx={{ mb: 1 }}>
          <Grid item justifyContent={'flex-start'} flex={'50%'} xs={6}></Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={'flex-end'} flex={'50%'} sx={{ paddingRight: 2 }}>
              {/* プレビュー表示ボタン */}
              <Grid item>
                <StyledButton
                  fullwidth={true}
                  onClick={onModalOpenPreview}
                  isDisabled={isOpenEditor || !isQuestionDescriptionJpnValid || !isQuestionDescriptionEngValid}>
                  {t('button.preview')}
                </StyledButton>
              </Grid>

              {/* ロジック設定ボタン */}
              <Grid item>
                <StyledButton sx={{ marginLeft: 2 }} fullwidth={true} onClick={onClickModalOpenLogic} isDisabled={isDisabledLogicSetting}>
                  {t('button.logic')}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <EnqueteDescriptionForm />
        {questionsJpn && questionsJpn.length > 0 && <EnqueteCreateQuestionPreviewForm />}
        {isQuestionOpen ? (
          <Grid>
            <FormTypeButtons />
            <EnqueteCreateQuestionSwitcher />
          </Grid>
        ) : null}
        <Grid container alignItems={'center'}>
          <Grid item justifyContent={'flex-start'} flex={'50%'} xs={6}>
            <Button
              variant={'contained'}
              disabled={isDisabledAddQuestion}
              onClick={() => {
                dispatch(setIsOpenEditor(false));
                dispatch(clearQuestionItemOrValidation());
                dispatch(setIsQuestionOpen(true));
              }}>
              {t('button.addNewEnqueteGeneral')}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Grid container justifyContent={'flex-end'} flex={'50%'} sx={{ paddingRight: 2 }}>
              {/* 日英変換ダイアログ表示ボタン */}
              <Grid item>
                <StyledButton fullwidth={true} onClick={onClickModalOpenTranslationSupport} isDisabled={isTranslationDisabled}>
                  {t('button.translationSupport')}
                </StyledButton>
              </Grid>

              {/* 保存ボタン */}
              <Grid item>
                <StyledButton
                  sx={{ marginLeft: 2 }}
                  fullwidth={true}
                  onClick={async () => await onClickSaveData(enqueteData)}
                  isDisabled={isDisabledSave}>
                  {t('button.save')}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
