import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initRespondentRequestData } from 'src/api/useRespondentRequestApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { NetworkAccessError } from 'src/errors/errors';
import { useDispatch } from 'src/redux/store';
import { conversionDateWithoutLocalHour, dateToString } from 'src/utils/formatDateTime';
import { setEnqueteData } from '../../../../store/enqueteCreateSlice';

const useEnqueteCreateLayout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getListById } = useSurveyEditApi();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getEnqueteData = async (respondentRequestId: string) => {
    setIsLoading(true);

    try {
      const result = await getListById(respondentRequestId);
      const respondentRequestData = Object.keys(result).length > 0 ? result : initRespondentRequestData;

      //対象年月のタイムゾーン分付与された時間を削除（DBカラムのDate型対応）
      if (respondentRequestData !== null && respondentRequestData.targetDate !== null)
        respondentRequestData.targetDate = dateToString(conversionDateWithoutLocalHour(respondentRequestData.targetDate));

      dispatch(setEnqueteData(respondentRequestData));
    } catch (error) {
      if (error instanceof NetworkAccessError) {
        // memo: Forbidden の場合は、画面上に権限無しのメッセージを表示するため Snackbar は表示しない
        if (!error.isForbidden()) {
          enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: 'survey-edit' }), { variant: 'error' });
        }
      } else {
        enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getEnqueteData,
  };
};

export default useEnqueteCreateLayout;
