import { useTranslation } from 'react-i18next';

export const useValidator = () => {
  const { t } = useTranslation();

  const rulesA1Code = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('customerManagement.applyA1CodeModal.field.a1Code') }),
    },
  };

  return { rulesA1Code };
};
