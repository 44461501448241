import { Auth } from 'aws-amplify';
import axios from 'axios';
import { apiGateway } from 'src/config';
import { EnqueteThreadPriorInfo } from './getThreadCreatorApi';

export interface EnqueteThreadEditor {
  title: string;
  surveyDetailId: string;
  isPrivate: boolean;
  isSendMailToUser: boolean;
  description: string;
  categories: string[];
  threadUsers: string[];
}

export const getThreadEditorApi = () => {
  const getBearerToken = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return `Bearer ${token}`;
  };

  const getThreadPriorInfo = async () => {
    const token = await getBearerToken();
    const response = await axios.get<EnqueteThreadPriorInfo>(apiGateway + '/enquete-threads/create', {
      headers: {
        Authorization: token,
      },
      timeout: 60000,
    });
    return response.data;
  };

  const editThread = async (data: EnqueteThreadEditor, threadId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<EnqueteThreadEditor>(apiGateway + `/enquete-threads/${threadId}`, data, {
        headers: { Authorization: token },
        params: { threadId: threadId },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      } else {
        throw error;
      }
    }
  };

  return { getThreadPriorInfo, editThread };
};
