import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  respondentTargetId: string;
  label: string;
  indentPl: number;
}

export const PreviousAnswersLink: React.FC<Props> = (props: Props) => {
  const { respondentTargetId, label, indentPl } = props;

  return (
    <Link to={`/general/enquete-answer/input/${respondentTargetId}`} key={respondentTargetId} target="_blank" rel="noopener noreferrer">
      <ListItemButton sx={{ pl: indentPl }}>
        <ListItemText>
          {label}
          <ListItemIcon sx={{ pl: 1 }}>
            <FontAwesomeIcon icon={faUpRightFromSquare} style={{ fontSize: '16px' }} />
          </ListItemIcon>
        </ListItemText>
      </ListItemButton>
    </Link>
  );
};
