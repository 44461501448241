import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

export const useSaveEnqueteDescription = () => {
  const { saveSurveyDetailData } = useEnqueteCreateGrid();
  const dispatch = useAppDispatch();
  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData);

  const questionDescriptionJpn = useAppSelector((state) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useAppSelector((state) => state.enqueteCreate.questionDescriptionEng);
  const surveyDetailData = enqueteData.surveyDetail;
  const aggregationList = (surveyDetailData.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];

  const onSaveEnqueteDescription = async () => {
    const surveyFormData = {
      enqueteType: '',
      formData: {
        pastRespondentRequestId: '',
        tabData: [
          {
            questionDescriptionJpn: questionDescriptionJpn ?? '',
            questionDescriptionEng: questionDescriptionEng ?? '',
            aggregationList: aggregationList,
          },
        ],
      },
    };
    const saveEnquete: SurveyDetailEntity = {
      ...surveyDetailData,
      surveyFormData: surveyFormData,
    };

    const result = await saveSurveyDetailData(saveEnquete);

    if (result) {
      const surveyDetail = {
        ...saveEnquete,
        updatedAt: (result.data as SurveyDetailEntity).updatedAt,
      };
      dispatch(setEnqueteData({ ...enqueteData, surveyDetail }));
    }
  };
  return { onSaveEnqueteDescription };
};
