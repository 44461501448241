import { HotTable } from '@handsontable/react';
import { Box, Button, Grid, Typography } from '@mui/material';
import 'handsontable/dist/handsontable.full.min.css';
import { enUS, jaJP, registerLanguageDictionary } from 'handsontable/i18n';
import { HyperFormula } from 'hyperformula';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { HelpTooltip } from 'src/components/app-components/bonsai/HelpTooltip';
import EnqueteSubTableForm from 'src/components/app-components/bonsai/type-general-table/components/EnqueteSubTableForm';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import { hyperformulaLicenseKey } from 'src/config';
import { REQUEST_STATUS } from 'src/constants';
import { MainTablesTabContainer } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/components/MainTablesTabContainer';
import { useTableSave } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useTableSave';
import { TABLE_SETTINGS } from 'src/generalConstants';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useCreateMainTable } from '../hooks/useCreateMainTable';
import { useCreateNewTables } from '../hooks/useCreateNewTables';

const hyperformulaInstance = HyperFormula.buildEmpty({
  licenseKey: hyperformulaLicenseKey,
});
// define TRUE and FALSE constants
hyperformulaInstance.addNamedExpression('TRUE', '=TRUE()');
hyperformulaInstance.addNamedExpression('FALSE', '=FALSE()');

interface PropsEntity {
  hotRef: RefObject<HotTable>[];
  hotRefForSubSheet: RefObject<HotTable>[];
}

const EnqueteCreateNewTableForm: React.FC<PropsEntity> = React.memo((props) => {
  const { hotRef, hotRefForSubSheet } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  // Handsontable用言語ファイル読込み
  registerLanguageDictionary(isLangJpn ? jaJP : enUS);

  // ストア
  const isQuestionDescriptionJpnValid = useSelector((state: RootState) => state.enqueteCreate.isQuestionDescriptionJpnValid);
  const isQuestionDescriptionEngValid = useSelector((state: RootState) => state.enqueteCreate.isQuestionDescriptionEngValid);
  const subDatas = useSelector((state: RootState) => state.tableSetting.subDatas);
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const validSheetNames = useAppSelector((state: RootState) => state.tableSetting.validSheetNames);

  // Hooks
  const { saveAllSettings } = useTableSave(hotRef, hotRefForSubSheet);
  const { isMaxTotalTableCount, setOpenColumnHeaderModal, setOpenRowHeaderModal } = useCreateMainTable();
  const { isSaving, handleSaveClick, handleAddSubClick, handleColumnHeaderSettingClick, handleRowHeaderSettingClick } = useCreateNewTables(
    saveAllSettings,
    setOpenColumnHeaderModal,
    setOpenRowHeaderModal,
  );
  const { isAdmin, isSurveyOwner, isBonsaiCreator } = usePermission(enqueteData.surveyDetailId);

  // アンケート説明(日本語)データ有無、アンケート説明(英語)データ有無、依頼期間を検証
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const hasPermission = enqueteData.surveyDetailId ? isAdmin || isSurveyOwner : isAdmin || isBonsaiCreator;
  const isDisabledSaveButton =
    !isQuestionDescriptionJpnValid ||
    !isQuestionDescriptionEngValid ||
    (requestStatus && REQUEST_STATUS.BEFORE_REQUEST !== requestStatus) ||
    !hasPermission;
  // シート名検証が全てのコンポーネントでクリアになっているか検証
  const isInvalidSheetNames = validSheetNames.some((sheetNames: boolean[]) => sheetNames.includes(false));

  return (
    <>
      <Box className="App" component={'div'} sx={{ mb: 1 }}>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 1 }}>
            <Typography variant="h5">{t('enqueteCreate.table.main.title')}</Typography>
            <Label
              role="message"
              variant="ghost"
              sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
              {t('enqueteCreate.table.main.explanation')}
              {t('enqueteGeneralCommon.explanation.referenceAnswerTable')}
              <HelpTooltip
                contents={
                  t('enqueteGeneralCommon.tooltip.referenceAnswerTableValidSheetName') + t('enqueteGeneralCommon.tooltip.referenceAnswerTableValue')
                }
              />
            </Label>
          </Grid>

          <Grid item xs={12}>
            <MainTablesTabContainer
              hotRef={hotRef}
              hotRefForSubSheet={hotRefForSubSheet}
              hyperformulaInstance={hyperformulaInstance}
              isPreview={false}
            />

            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', gap: 2, mt: 2 }}>
                <Button variant="contained" onClick={handleColumnHeaderSettingClick} disabled={isDisabledSaveButton}>
                  {t('button.colHeaderSetting')}
                </Button>
                <Button variant="contained" onClick={handleRowHeaderSettingClick} disabled={isDisabledSaveButton}>
                  {t('button.rowHeaderSetting')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 2, mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5" sx={{ display: 'inline' }}>
                {t('enqueteCreate.table.sub.title')}
              </Typography>
              <Button
                variant="outlined"
                sx={{ ml: 5 }}
                onClick={handleAddSubClick}
                disabled={subDatas.length >= TABLE_SETTINGS.SUB.MAX_LENGTH || isMaxTotalTableCount}>
                {t('enqueteCreate.table.sub.addButton')}
              </Button>
            </Box>
            <Label
              role="message"
              variant="ghost"
              sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
              {t('enqueteCreate.table.sub.explanation', { max: TABLE_SETTINGS.MAX_TOTAL_TABLE_LENGTH })}
              <HelpTooltip contents={t('enqueteGeneralCommon.tooltip.referenceRefTableValue')} />
            </Label>
          </Grid>

          <EnqueteSubTableForm
            hotRef={hotRef}
            hotRefForSubSheet={hotRefForSubSheet}
            hyperformulaInstance={hyperformulaInstance}
            isPreview={false}
            isVisibleContextMenu={true}
          />
        </Grid>
        <Grid container justifyContent={'flex-end'} alignItems={'center'}>
          <Grid item>
            <Box sx={{ mt: 2 }}>
              <StyledButton fullwidth={true} onClick={handleSaveClick} isDisabled={isDisabledSaveButton || isInvalidSheetNames}>
                {t('button.save')}
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <StyledLoading isOpen={isSaving} />
    </>
  );
});
export default EnqueteCreateNewTableForm;
