import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface CountryRegionEntity {
  countryCode: string;
  regionCode: string;
}

interface PortalCountryRegionEntity {
  country_code: string;
  region_code: string;
}

const PORTAL_URL = `${portalApi}/master/countryRegion`;

export const useCountryRegionApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getAll = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalCountryRegionEntity[]>(PORTAL_URL, {
        headers: { Authorization: token },
        params: {
          filter: {},
        },
      });

      return result.data.map<CountryRegionEntity>((master) => ({
        countryCode: master.country_code,
        regionCode: master.region_code,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.countryRegion') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getAll };
};
