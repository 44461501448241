import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdditionalQuestion } from 'src/@types/overviewEnquete';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import { OverviewSurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { RootState, useAppSelector } from 'src/redux/store';
import {
  QUESTION_TYPE,
  QuestionType,
} from '../../../tab-containers/type-overview-survey/components/tabs/overview-question/components/OverviewInputEditBox';
import { QuestionPlaceInfo } from '../../../tab-containers/type-overview-survey/components/tabs/overview-question/components/OverviewQuestions';
import { useSetInputItem } from '../hooks/useSetInputItem';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  questionType: QuestionType;
  questionPlaceInfo?: QuestionPlaceInfo;
};

export const SetInputItemModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen, isEdit, setIsEdit, questionType, questionPlaceInfo } = props;
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const [selectedValue, setSelectedValue] = useState<QuestionType>(questionType);
  const defaultFormatValue: AdditionalQuestion = useMemo(() => {
    return {
      isAnswerTarget: true,
      titleJpn: '',
      titleEng: '',
      answer: '',
      inputType: 'text',
      isRequired: false,
    };
  }, []);
  const [formValues, setFormValues] = useState<AdditionalQuestion>(defaultFormatValue);

  const surveyDetail = enqueteData.surveyDetail ? Object.assign(enqueteData.surveyDetail) : {};
  const overviewSurveyFormData = surveyDetail?.surveyFormData?.formData as OverviewSurveyFormData;
  const tabData = overviewSurveyFormData?.tabData[0];
  const methods = useForm({ mode: 'onChange' });
  const questionIndex = questionPlaceInfo?.questionIndex;

  useEffect(() => {
    if (isEdit && questionIndex !== undefined) {
      let additionalQuestion: AdditionalQuestion = _.cloneDeep(defaultFormatValue);
      switch (questionType) {
        case QUESTION_TYPE.PURCHASE:
          additionalQuestion = [...tabData?.purchaseAdditionalQuestions][questionIndex];
          break;
        case QUESTION_TYPE.SALES:
          additionalQuestion = [...tabData?.salesAdditionalQuestions][questionIndex];
          break;
        case QUESTION_TYPE.OTHER:
          additionalQuestion = [...tabData?.otherAdditionalQuestions][questionIndex];
          break;
      }
      setFormValues({ ...additionalQuestion });
    }
  }, [
    isOpen,
    defaultFormatValue,
    isEdit,
    questionIndex,
    questionType,
    tabData?.otherAdditionalQuestions,
    tabData?.purchaseAdditionalQuestions,
    tabData?.salesAdditionalQuestions,
  ]);

  const { saveInputItemOnClick, handleInputAreaRadioChange, handleInputChange, handleTextTypeRadioChange, validationRule } = useSetInputItem({
    setIsOpen,
    setIsEdit,
    setSelectedValue,
    formValues,
    setFormValues,
  });

  return (
    <StyledModal
      isOpen={isOpen}
      onCloseFunc={() => {
        setIsOpen(false);
      }}
      modalTitle={t('enqueteCreate.setInputItemModal.setInputItem')}>
      <Grid container direction={'column'} justifyContent="flex-start" alignItems="center" spacing={2}>
        {/* 入力エリア */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={6}>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
              {t('enqueteCreate.setInputItemModal.questionType')}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <RadioGroup row aria-label="questionType" name="questionType" value={selectedValue} onChange={handleInputAreaRadioChange}>
              <FormControlLabel
                value={QUESTION_TYPE.PURCHASE}
                control={<Radio color="primary" />}
                label={t('enqueteCreate.overviewSurvey.purchaseTransactions')}
              />
              <FormControlLabel
                value={QUESTION_TYPE.SALES}
                control={<Radio color="primary" />}
                label={t('enqueteCreate.overviewSurvey.salesTransactions')}
              />
              <FormControlLabel value={QUESTION_TYPE.OTHER} control={<Radio color="primary" />} label={t('enqueteCreate.overviewSurvey.other')} />
            </RadioGroup>
          </Grid>
        </Grid>
        {/* 項目名（日本語） */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={6}>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
              {t('enqueteCreate.setInputItemModal.itemNameJpn')}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <RHFonChangeTextField
              control={methods.control}
              name={'titleJpn'}
              defaultValue={formValues.titleJpn}
              id={'titleJpn'}
              label={''}
              placeholder={''}
              rules={validationRule(t('enqueteCreate.setInputItemModal.itemNameJpn'))}
              key={'titleJpn'}
              handleOnChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Grid>
        </Grid>
        {/* 項目名（英語） */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={6}>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
              {t('enqueteCreate.setInputItemModal.itemNameEng')}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <RHFonChangeTextField
              control={methods.control}
              name={'titleEng'}
              defaultValue={formValues.titleEng}
              id={'titleEng'}
              label={''}
              placeholder={''}
              rules={validationRule(t('enqueteCreate.setInputItemModal.itemNameEng'))}
              key={'titleEng'}
              handleOnChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Grid>
        </Grid>
        {/* テキストタイプ */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={6}>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
              {t('enqueteCreate.setInputItemModal.textType')}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <RadioGroup row aria-label="inputType" name="inputType" value={formValues.inputType} onChange={handleTextTypeRadioChange}>
              <FormControlLabel value={'text'} control={<Radio color="primary" />} label={t('common.text')} />
              <FormControlLabel value={'textarea'} control={<Radio color="primary" />} label={t('common.textarea')} />
            </RadioGroup>
          </Grid>
        </Grid>
        {/* 入力必須 */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={6}>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
              {t('enqueteCreate.setInputItemModal.required')}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormControlLabel
              control={<Checkbox />}
              name="isRequired"
              label={t('enqueteCreate.setInputItemModal.setRequired')}
              labelPlacement="end"
              checked={formValues.isRequired}
              onChange={() => {
                setFormValues({
                  ...formValues,
                  isRequired: !formValues.isRequired,
                });
              }}
              sx={{ width: 'auto' }}
            />
          </Grid>
        </Grid>

        <Grid item sx={{ pt: 2 }}>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
            {/* キャンセルボタン */}
            <StyledButton
              onClick={() => {
                setIsOpen(false);
              }}
              color="error">
              {t('button.cancel')}
            </StyledButton>
            {/* 保存ボタン */}
            <StyledButton
              onClick={() => {
                saveInputItemOnClick(selectedValue, formValues, isEdit, questionPlaceInfo);
                methods.reset();
                setFormValues(defaultFormatValue);
                setSelectedValue(questionType);
              }}
              isDisabled={!methods.formState.isValid}>
              {t('button.save')}
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </StyledModal>
  );
};
