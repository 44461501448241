import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { CustomerGroup } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';

export const useCheckCustomer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';

  /**
   * 取引先が既にcustomerGroupsに登録されているかチェック
   * @param customerCode
   * @param parentCustomerCode
   * @param customerGroups
   * @returns boolean true: 取引先が存在しない, false: 取引先が存在する
   */
  const checkRegisteredCustomer = (customerCode: string, parentCustomerCode: string, customerGroups: CustomerGroup[]) => {
    // 親取引先の位置を取得
    const position: number = customerGroups.findIndex((customer) => customer.parentCustomerCode === parentCustomerCode);
    // 取得できなかったら存在しないためreturn
    if (position === -1) return true;
    // 既に登録しているかチェック
    const registeredCustomer = customerGroups[position]?.childrenCustomer.find((customer) => customer.customerCode === customerCode);
    // 登録されていたら
    if (registeredCustomer) {
      const existCustomerName = isJapanese ? trimStockCompany(registeredCustomer.customerName) : registeredCustomer.customerNameEng;
      enqueueSnackbar(t('enqueteAnswerInput.modal.selectCustomer.message.alreadyRegistered', { existCustomerName }), {
        variant: 'warning',
      });
      return false;
    }
    return true;
  };
  return { checkRegisteredCustomer };
};
