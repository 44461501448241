import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { usePermission } from 'src/hooks/usePermission';
import { SelectEnqueteTypeModal } from '../../select-enquete-type/components/SelectEnqueteTypeModal';
import { AlertPersonalDataModal } from './AlertPersonalDataModal';

export const EnqueteRequestListActionsForm: React.FC = () => {
  const { t } = useTranslation();
  const { isViewSelectModal } = usePermission();
  const [isSelectEnqueteModalOpen, setIsSelectEnqueteModalOpen] = useState(false);
  const [isAlertPersonalModalOpen, setIsAlertPersonalModalOpen] = useState(false);

  return (
    <>
      <Box component={'div'} sx={{ mb: 1 }}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={2}>
            <StyledButton
              fullwidth={true}
              onClick={() => {
                if (isViewSelectModal) {
                  setIsSelectEnqueteModalOpen(true);
                } else {
                  setIsAlertPersonalModalOpen(true);
                }
              }}>
              {t('button.createNew')}
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
      {isViewSelectModal ? (
        <SelectEnqueteTypeModal isOpen={isSelectEnqueteModalOpen} onClose={() => setIsSelectEnqueteModalOpen(false)} />
      ) : (
        <AlertPersonalDataModal isOpen={isAlertPersonalModalOpen} onClose={() => setIsAlertPersonalModalOpen(false)} />
      )}
    </>
  );
};
