import { useEffect, useState } from 'react';
import { BonsaiRole } from 'src/@types/role';
import { usePermissionApi } from 'src/api/usePermissionApi';
import { ResponseVisibleConditionTypes, useVisibleConditionsApi } from 'src/api/useVisibleConditionsApi';
import { ACCESS_ROLE_TYPE } from 'src/constants';
import { useAppSelector } from 'src/redux/store';
import { VisibleConditionUtils } from 'src/utils/visible-condition-utils';
import useAuth from './useAuth';

export const usePermission = (surveyDetailId?: string, respondentTargetId?: string) => {
  const { user } = useAuth();
  const { checkPermission } = usePermissionApi();
  const { getVisibleConditionList } = useVisibleConditionsApi();
  // 以下useAppSelectorを分割代入({hoge} = useAppSelector...)で記述すると人事側の画面においてinputFormを変更したタイミングで
  // memory leakを起こしてしまい、白画面になるため、分割代入は行わないようにしてください。具体例は以下
  // const { confirmRequestData, respondentTargetData } = useAppSelector((state) => state.enqueteAnswer);
  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);

  // [TODO] ロール以外の、アンケートに対する役割は Redux で管理してキャッシュ、個別アンケートのレイアウト画面で一度取得すればいいようにしたいところ。
  const [isSurveyOwner, setIsSurveyOwner] = useState<boolean>(false);
  const [isRespondentPerson, setIsRespondentPerson] = useState<boolean>(false);
  const [isAnswerReader, setIsAnswerReader] = useState<boolean>(false);
  const [isRespondentManager, setIsRespondentManager] = useState<boolean>(false);
  const [isDivisionReadRep, setIsDivisionReadRep] = useState<boolean>(false);
  const [allowWriteDivisions, setAllowWriteDivisions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userRole = user ? user.role : [];
  const userEmail = user ? user.email : '';
  const isAdmin = userRole.includes(BonsaiRole.ADMIN);
  const isViewerAdmin = userRole.includes(BonsaiRole.VIEWER_ADMIN);
  const isCreator = userRole.includes(BonsaiRole.CREATOR);
  const isOrganizationEditor = userRole.includes(BonsaiRole.ORGANIZATION_EDITOR);
  const isCustomerEditor = userRole.includes(BonsaiRole.CUSTOMER_EDITOR);
  const isOverViewCreator = userRole.includes(BonsaiRole.OVERVIEW_CREATOR);
  const isViewSelectModal: boolean = isAdmin || isViewerAdmin || (isCreator && isOverViewCreator); // アンケート作成時のモーダル振り分けで使用
  const isBonsaiAdmin: boolean = isAdmin || isViewerAdmin;
  const isBonsaiCreator: boolean = isCreator || isOverViewCreator;

  const isConfirmor = confirmRequestData?.confirmerEmail === userEmail;
  const isOwnerOrAdmin = isAdmin || isViewerAdmin || isSurveyOwner;
  const isRespondentManagerOnly = !isAdmin && !isViewerAdmin && !isSurveyOwner && isRespondentManager;

  // アンケートレベルでの役割の確認
  const checkSurveyPermission = async () => {
    // 管理者か確認
    const surveyPermission = surveyDetailId
      ? await checkPermission(surveyDetailId, respondentTargetId)
      : {
          userId: userEmail,
          surveyDetailId: '',
          respondentRequestId: '',
          respondentTargetId: '',
          isOwner: false,
          isRespondentManager: false,
          isRespondentPerson: false,
        };

    if (surveyPermission !== undefined) {
      setIsSurveyOwner(surveyPermission.isOwner);
      setIsRespondentManager(surveyPermission.isRespondentManager);
      setIsRespondentPerson(surveyPermission.isRespondentPerson);
    } else {
      setIsSurveyOwner(false);
      setIsRespondentManager(false);
      setIsRespondentPerson(false);
    }
  };

  const checkIsAnswerReader = async () => {
    if (!respondentTargetData.organization) return;
    const organization = respondentTargetData.organization;
    // 本部極担当者かつ閲覧権限のみのユーザーか確認
    const conditions: ResponseVisibleConditionTypes[] | undefined = surveyDetailId ? await getVisibleConditionList(surveyDetailId) : [];
    const matchCondition = conditions?.find(
      (condition) =>
        condition.email === userEmail &&
        VisibleConditionUtils.isMatchOrganization(organization, condition) &&
        condition.accessRoleId === ACCESS_ROLE_TYPE.ANSWER_READ,
    );
    setIsAnswerReader(!!matchCondition);
  };

  const ALLOW_ACCESS_ROLE: string[] = [ACCESS_ROLE_TYPE.ANSWER_READ, ACCESS_ROLE_TYPE.FULL];
  const checkIsDivisionRep = async () => {
    // 本部極担当者かつ本部権限を割り当てられたユーザーか確認
    const coditions: ResponseVisibleConditionTypes[] | undefined = surveyDetailId ? await getVisibleConditionList(surveyDetailId) : [];
    const divisionRep = coditions?.filter(
      (condition) =>
        condition.email === userEmail &&
        condition.divisionCode &&
        !condition.companyCode &&
        !condition.sbuCode &&
        !condition.departmentCode &&
        !condition.groupCode &&
        !condition.regionCode &&
        !condition.countryCode,
    );
    const divisionReadRep = divisionRep?.find((condition) => ALLOW_ACCESS_ROLE.includes(condition.accessRoleId));
    const allowWriteDivisions = divisionRep
      ?.filter((condition) => condition.accessRoleId === ACCESS_ROLE_TYPE.FULL)
      .map((condition) => condition.divisionCode);
    setIsDivisionReadRep(!!divisionReadRep);
    allowWriteDivisions && setAllowWriteDivisions(allowWriteDivisions);
  };

  useEffect(() => {
    const checkPermisionsForEnquete = async () => {
      setIsLoading(true);
      await Promise.all([checkSurveyPermission(), checkIsAnswerReader(), checkIsDivisionRep()]);
      setIsLoading(false);
    };
    checkPermisionsForEnquete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetailId, respondentTargetId]);

  return {
    isAdmin,
    isViewerAdmin,
    isCreator,
    isOrganizationEditor,
    isCustomerEditor,
    isOverViewCreator,
    isSurveyOwner,
    isLoadingPermission: isLoading,
    isRespondentPerson,
    isAnswerReader,
    isConfirmor,
    isViewSelectModal,
    isBonsaiAdmin,
    isBonsaiCreator,
    isOwnerOrAdmin,
    isRespondentManager,
    isRespondentManagerOnly,
    isDivisionReadRep,
    allowWriteDivisions,
  };
};
