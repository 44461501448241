import { Alert, AlertTitle, Button, Container, Stack, styled } from '@mui/material';
import React, { useContext } from 'react';
import { Link, Navigate, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import { AuthContext } from 'src/contexts/AwsCognitoContext';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export function RootErrorBoundary() {
  let error = useRouteError() as { status: number; statusText: string };
  const context = useContext(AuthContext);

  if (isRouteErrorResponse(error)) {
    if (error.status === 500) {
      return <Navigate to="/500" replace />;
    }
    if (error.status === 404) {
      return <Navigate to="/404" replace />;
    }
  }

  return (
    <RootStyle>
      <Container>
        <Stack direction="column" alignItems="center" spacing={4}>
          <Logo sx={{ width: '100px', height: '100px' }} />
          <Alert severity="error">
            {error.status && <AlertTitle>{error.status}</AlertTitle>}
            {error.statusText || 'Something went wrong.'}
          </Alert>
          <Button
            to="/auth/login"
            onClick={() => {
              if (context?.logout) {
                context.logout();
              }
            }}
            component={Link}>
            Logout
          </Button>
          <Button to="/top" size="large" variant="contained" component={Link}>
            Go to Top
          </Button>
        </Stack>
      </Container>
    </RootStyle>
  );
}
