import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { SeriesSurveyFormAggregationData, SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { SeriesEnqueteRowData } from './useEnqueteModal';

export const useEnqueteGrid = () => {
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const dispatch = useDispatch();
  const { saveSurveyDetailData } = useEnqueteCreateGrid();

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: false,
    resizable: true,
    editable: false,
    filter: false,
  };

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      field: isJapanese ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('enqueteCreate.seriesEnquete.division'),
      minWidth: 150,
    },
    {
      field: isJapanese ? 'destinationSbuNameJpn' : 'destinationSbuNameEng',
      headerName: t('enqueteCreate.seriesEnquete.destination'),
      minWidth: 150,
    },
    {
      field: isJapanese ? 'sourceSbuNameJpn' : 'sourceSbuNameEng',
      headerName: t('enqueteCreate.seriesEnquete.source'),
      minWidth: 150,
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  /**
   * データの削除処理
   * @param data 削除されるデータ
   */
  const onDelete = async (data: SeriesEnqueteRowData) => {
    const surveyDetail = enqueteData.surveyDetail;
    const surveyDetailData = surveyDetail.surveyFormData?.formData.tabData[0] as SeriesSurveyFormTabData;
    // 登録データをコピーする
    const newAggregationList: SeriesSurveyFormAggregationData[] = JSON.parse(JSON.stringify(surveyDetailData.aggregationList));

    // 変更前SBU集約先を削除し、変更SBU集約先を設定する
    for (const [divisionIndex, aggregation] of newAggregationList.entries()) {
      if (aggregation.divisionCode === data.divisionCode) {
        for (const [sbuIndex, sbu] of aggregation.sbu.entries()) {
          if (sbu.destination.sbuCode === data.destinationSbuCode) {
            // 登録集約元が1件の時は、集約先がなくなるため、集約情報を削除
            if (sbu.source.length === 1) {
              // 集約情報を削除
              aggregation.sbu.splice(sbuIndex, 1);
              break;
            }
            for (const [sourceIndex, source] of sbu.source.entries()) {
              if (source.sbuCode === data.sourceSbuCode) {
                // 削除
                sbu.source.splice(sourceIndex, 1);
                break;
              }
            }
            break;
          }
        }
        // SBU情報を削除した場合、同じ本部に紐づく他のSBU情報がない場合削除する
        if (aggregation.sbu.length < 1) {
          // 削除
          newAggregationList.splice(divisionIndex, 1);
        }

        break;
      }
    }

    const surveyFormData = {
      enqueteType: '',
      formData: {
        pastRespondentRequestId: '',
        tabData: [
          {
            questionDescriptionJpn: surveyDetailData.questionDescriptionJpn,
            questionDescriptionEng: surveyDetailData.questionDescriptionEng,
            aggregationList: newAggregationList,
          },
        ],
      },
    };

    const saveEnquete: SurveyDetailEntity = {
      ...surveyDetail,
      surveyFormData: surveyFormData,
    };

    // 成功の場合、stateの更新
    const result = await saveSurveyDetailData(saveEnquete, 'message.deleted');
    if (result) {
      const surveyDetail = {
        ...saveEnquete,
        updatedAt: (result.data as SurveyDetailEntity).updatedAt,
      };
      dispatch(setEnqueteData({ ...enqueteData, surveyDetail }));
    }
  };

  return { defaultColDef, colDef, onDelete };
};
