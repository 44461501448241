import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { Question } from 'src/@types/generalEnquete';

export const useEnqueteAnswerValidator = (methods: UseFormReturn, question: Question) => {
  const { validation } = question;
  const rulesEnqueteAnswer = {
    required: { value: validation?.required.isRequired, message: validation?.required.errorMessage },
    pattern: {
      value:
        validation?.pattern?.value === 'number' ? /^[+,-]?([1-9]\d*|0)(\.\d+)?$/ : validation?.pattern?.value === 'string' ? undefined : undefined,
      message: validation?.pattern?.errorMessage,
    },
    max: validation?.max ? { value: validation.max.value, message: validation.max.errorMessage } : undefined,
    min: validation?.min ? { value: validation.min.value, message: validation.min.errorMessage } : undefined,
    minLength: validation?.minLength ? { value: validation.minLength.value, message: validation.minLength.errorMessage } : undefined,
    maxLength: validation?.maxLength ? { value: validation.maxLength.value, message: validation.maxLength.errorMessage } : undefined,
    validate: {
      numberOfDecimalPlaces: (value: string | number | null) => {
        const numberOfDecimalPlaces = validation?.numberOfDecimalPlaces?.value;
        if ((value || `${value}` === '0') && numberOfDecimalPlaces !== undefined) {
          // 小数点以下の桁数チェック。指定された桁数以外は許可しない
          const decimalPartPattern = `\\.\\d{${validation?.numberOfDecimalPlaces?.value}}`;
          const integerPartPattern = `\\-?\\d*`;
          // 指定された桁数が0以外の場合、小数部のパターンをつける
          const pattern = `^${integerPartPattern}${numberOfDecimalPlaces !== 0 ? decimalPartPattern : ''}$`;
          if (!new RegExp(pattern).test(`${value}`)) {
            return validation?.numberOfDecimalPlaces?.errorMessage;
          }
        }
      },
    },
  };

  const rulesEnqueteAnswerCheckbox = {
    validate: {
      numberOfElementsValidate: (values: string[]) => {
        if (validation?.required.isRequired && values.length === 0) {
          return validation.required.errorMessage;
        }

        if (validation?.minNumberOfElements && (values.length === 0 || values.length < validation.minNumberOfElements.value)) {
          return validation.minNumberOfElements.errorMessage;
        }

        if (validation?.maxNumberOfElements && values.length > validation.maxNumberOfElements.value) {
          return validation.maxNumberOfElements.errorMessage;
        }
      },
    },
  };

  return {
    rulesEnqueteAnswer,
    rulesEnqueteAnswerCheckbox,
  };
};
