import { PageLayout } from 'src/components/app-components/PageLayout';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { EnqueteConfirmListGrid } from './components/EnqueteConfirmListGrid';

export interface EnqueteConfirmListLayoutProps {
  title: string;
  breadcrumbs: HeaderBreadcrumbsProps;
}

export const EnqueteConfirmListLayout: React.FC<EnqueteConfirmListLayoutProps> = (props) => {
  const { title, breadcrumbs } = props;
  return (
    <PageLayout title={title} breadcrumbs={breadcrumbs}>
      <EnqueteConfirmListGrid />
    </PageLayout>
  );
};
