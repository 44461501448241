import {
  AgGridEvent,
  CellRendererSelectorFunc,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ColumnGroupOpenedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItems,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  RowClickedEvent,
  RowDoubleClickedEvent,
  RowStyle,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import { LIST_STATE_KEY_PREFIX } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import ThreeDotMenuRenderer from './ThreeDotMenuRenderer';
export interface StyledAgGridProps {
  defaultColDef?: ColDef;
  coldef: (ColDef | ColGroupDef)[] | null | undefined;
  rows: any;
  contextMenu?: GetContextMenuItems;
  gridOptions?: GridOptions;
  onCellValueChanged?: (params: CellValueChangedEvent) => void;
  onRowClickedCallback?: (e: RowClickedEvent) => void;
  getRowStyle?: (params: RowClassParams) => RowStyle;
  onRowDoubleClickedCallback?: (e: RowDoubleClickedEvent) => void;
  cellRenderers?: CellRendererSelectorFunc[] | undefined;
  /**
   * AGGrid State を保存／復元する際のLocalStorageKey名を指定します
   * 未設定の場合は、State は保存されません
   * State を保存／復元することで、ユーザごとに一覧のSort順等を都度設定する必要がなくなります
   */
  storageKey?: string;
  suppressAutoSize?: boolean;
  tooltipShowDelay?: number;
  rowSelection?: 'multiple' | 'single';
  onGridReady?: (e: GridReadyEvent) => void;
  onColumnGroupOpened?: (e: ColumnGroupOpenedEvent) => void;
  onSortChangedCallback?: (e: AgGridEvent) => void;
  onFilterChangedCallback?: (e: AgGridEvent) => void;
  onFirstDataRendered?: (e: FirstDataRenderedEvent) => void;
}

export const commonColDef: ColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
  // 選択箇所がわからなくなるのでコメントアウト
  // cellStyle: { border: 'none', 'background-color': 'inherit' },
};

type ConumnState = {
  colId: string;
  [key: string]: string | boolean | undefined;
};

// AGGrid ColumnState を LocalStorage 上に保存
export const savedColumnStateOnLocalStorage = (event: AgGridEvent, storageKey: string | undefined) => {
  if (storageKey === undefined || storageKey === '') {
    return;
  }
  localStorage.setItem(`${LIST_STATE_KEY_PREFIX.COLUMN}${storageKey}`, JSON.stringify(event.columnApi.getColumnState()));
};

// AGGrid ColumnState を LocalStorage から復元
export const restoreColumnStateFromLocalStorage = (event: AgGridEvent, storageKey: string | undefined, isJapanese: boolean) => {
  if (storageKey === undefined || storageKey === '') {
    return;
  }
  const columnStatesString = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.COLUMN}${storageKey}`);
  if (columnStatesString === null) {
    return;
  }
  const columnState: ConumnState[] = JSON.parse(columnStatesString);
  if (!columnState) {
    return;
  }
  const adjustedColumnState = columnState.map((column: ConumnState) => {
    let colId = column.colId;
    if (isJapanese && colId.endsWith('Eng')) {
      colId = colId.replace('Eng', 'Jpn');
    } else if (!isJapanese && colId.endsWith('Jpn')) {
      colId = colId.replace('Jpn', 'Eng');
    }
    return { ...column, colId };
  });

  event.columnApi.applyColumnState({ state: adjustedColumnState, applyOrder: true });
};

// AGGrid FilterState を LocalStorage 上に保存
const savedFilterStateOnLocalStorage = (event: AgGridEvent, storageKey: string | undefined) => {
  if (storageKey === undefined || storageKey === '') {
    return;
  }
  localStorage.setItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`, JSON.stringify(event.api.getFilterModel()));
};

// AGGrid FilterState を LocalStorage から復元
// filterState の形式は以下の通り、columnName のFilterInstanceを取得し、
// そこにvalueを設定することで、フィルタを元に戻すようにしている
// ex) {"columnName1": {"values": ["aaa", "bbb"]}, "columnName2": {"values": ["ccc", "ddd"]}}
const restoreFilterStateFromLocalStorage = (event: AgGridEvent, storageKey: string | undefined) => {
  if (storageKey === undefined || storageKey === '') {
    return;
  }
  const filterStatesString = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
  if (filterStatesString === null) {
    return;
  }
  const filterState = JSON.parse(filterStatesString);
  if (!filterState) {
    return;
  }
  for (const [key, value] of Object.entries(filterState)) {
    const filter = event.api.getFilterInstance(key);
    if (filter === undefined || filter === null) {
      return;
    }
    filter.setModel(value);
    event.api.onFilterChanged();
  }
};

/**
 * ColDefへの共通設定処理
 * @param coldef
 */
const settingColDef = (coldef: (ColDef | ColGroupDef)[] | null | undefined): void => {
  // memo:  3点リーダーを常に表示にする設定のための処理
  //        threeDotMenuRendererはすべてColDefで定義されてるのでColGroupDefの階層チェックは行ってません
  coldef
    ?.filter((col) => {
      // memo:  interfaceはinstanceofが使用できないのでpropertyで判断する
      //        childrenはColGroupDefにのみ存在するproperty
      return !('children' in col) && col.cellRenderer === ThreeDotMenuRenderer;
    })
    .forEach((col) => {
      // ColGroupDefにlockVisibleの定義がなく警告が出てしまうのでColDefにキャストしてます
      (col as ColDef).lockVisible = true;
    });
};

export const StyledAgGrid: React.FC<StyledAgGridProps> = (props) => {
  const { coldef, storageKey } = props;
  const { isJapanese } = useLocales();

  settingColDef(coldef);
  return (
    <AgGridReact
      gridOptions={props.gridOptions}
      defaultColDef={props.defaultColDef ? props.defaultColDef : commonColDef}
      getContextMenuItems={
        props.contextMenu
          ? props.contextMenu
          : () => {
              return [];
            }
      }
      columnDefs={props.coldef}
      rowData={props.rows}
      getRowStyle={props.getRowStyle}
      enableRangeSelection={true}
      tooltipShowDelay={props.tooltipShowDelay ? props.tooltipShowDelay : 0}
      onRowClicked={props.onRowClickedCallback}
      onRowDoubleClicked={props.onRowDoubleClickedCallback}
      components={{ threeDotMenuRenderer: ThreeDotMenuRenderer }}
      onCellValueChanged={props.onCellValueChanged}
      onSortChanged={(event) => {
        savedColumnStateOnLocalStorage(event, storageKey);
        props.onSortChangedCallback && props.onSortChangedCallback(event);
      }}
      onColumnPinned={(event) => {
        savedColumnStateOnLocalStorage(event, storageKey);
      }}
      onFilterChanged={(event) => {
        savedFilterStateOnLocalStorage(event, storageKey);
        props.onFilterChangedCallback && props.onFilterChangedCallback(event);
      }}
      onFirstDataRendered={(event) => {
        props.onFirstDataRendered && props.onFirstDataRendered(event);
      }}
      suppressAutoSize={props.suppressAutoSize}
      rowSelection={props.rowSelection}
      onGridReady={(event) => {
        restoreColumnStateFromLocalStorage(event, storageKey, isJapanese);
        restoreFilterStateFromLocalStorage(event, storageKey);
        props.onGridReady && props.onGridReady(event);
      }}
      onColumnGroupOpened={props.onColumnGroupOpened}
    />
  );
};
