import { Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralEnquete, Question } from 'src/@types/generalEnquete';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { ErrorTooltip } from 'src/components/app-components/StyledTooltip';
import { ANSWER_STATUS, AnswerStatus } from 'src/constants';
import { setInitGeneralQuestions } from 'src/features/general/enquete-answer/store/enqueteGeneralAnswerSlice';
import { createAnswerQuestions, createTranslationAnswerQuestions } from 'src/features/general/enquete-answer/utils/createAnswerQuestions';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { dispatch, useAppSelector } from 'src/redux/store';
import useEnqueteAnswerSave from '../hooks/useEnqueteAnswerSave';
import { SwitchReversionModalLayout } from './common/components/SwitchReversionModalLayout';
import { REVERSION, REVERSION_REQUEST } from './common/constants';
import { useReturnedHandleClick } from './common/hooks/useReturnedHandleClick';
export interface EnqueteAnswerSaveButtonProps {
  respondentTargetId: string;
  confirmRequestId: string | null;
}

export const EnqueteAnswerGeneralQuestionSaveButtons: React.FC<EnqueteAnswerSaveButtonProps> = (props): React.ReactElement => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  const { isAnswered, commonSaveHandler } = useEnqueteAnswerSave(props.respondentTargetId);

  const hasError = useAppSelector((stateGeneral) => stateGeneral.enqueteGeneralAnswer.hasError);
  const questions = useAppSelector((stateGeneral) => stateGeneral.enqueteGeneralAnswer.questions);
  const questionStates = useAppSelector((stateGeneral) => stateGeneral.enqueteGeneralAnswer.questionStates);
  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  const surveyDetailData = useAppSelector((stateGeneral) => stateGeneral.enqueteAnswer.surveyDetailData);
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isAdmin, isViewerAdmin, isSurveyOwner, isRespondentPerson, isAnswerReader, isConfirmor } = usePermission(
    surveyDetailData.id,
    props.respondentTargetId,
  );
  const isOnlyAnswerReader = isAnswerReader && !isSurveyOwner && !isAdmin && !isConfirmor && !isRespondentPerson;
  const isOnlyViewerAdmin = isViewerAdmin && !isSurveyOwner && !isAdmin && !isRespondentPerson;

  const { handleReversionType, isReversionRequest } = useReturnedHandleClick(setIsOpen);

  const userId = null !== user ? user.username : '';

  const marginTopValue = props.confirmRequestId && confirmRequestData.confirmerId === userId ? '20px' : '50px';
  const memolizedEnqueteFormData = useMemo(() => surveyDetailData.surveyFormData.formData.tabData[0], [surveyDetailData]) as GeneralEnquete;
  const memolizedQuestions = useMemo(() => questions, [questions]) as Question[];
  const canNotSubmit = enqueteAnswerData.status === ANSWER_STATUS.ANSWERED || isOnlyAnswerReader || isConfirmor || isOnlyViewerAdmin;
  const errorToolTipMessage = !isEnqueteAnswerOpen ? t('message.outOfSurveyTerm') : hasError ? t('message.invalid') : '';

  useEffect(() => {
    if (memolizedQuestions.length === 0 || memolizedEnqueteFormData.questionsJpn?.length === 0) return;
    const enqueteAnswerQuestionsEmptyValue =
      currentLang.value === 'ja' ? memolizedEnqueteFormData.questionsJpn : memolizedEnqueteFormData.questionsEng;
    const convetedEnqueteAnswer = createTranslationAnswerQuestions(memolizedQuestions, JSON.parse(JSON.stringify(enqueteAnswerQuestionsEmptyValue)));
    dispatch(setInitGeneralQuestions(convetedEnqueteAnswer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang.value, dispatch]);

  const submitHandler = async (answerStatus: AnswerStatus) => {
    const answerQuestions = createAnswerQuestions(currentLang.value, surveyDetailData, questions, questionStates);
    const surveyFormAnswerData = {
      questionsJpn: answerQuestions.jpn,
      questionsEng: answerQuestions.eng,
    } as GeneralEnquete;
    await commonSaveHandler(surveyFormAnswerData, answerStatus);
  };

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ marginTop: marginTopValue }}>
        {(isSurveyOwner || isAdmin || isViewerAdmin) && (
          // 以下差戻機能の時に実装します
          <StyledButton
            onClick={() => handleReversionType(REVERSION)}
            isDisabled={!(isEnqueteAnswerOpen && !hasError && enqueteAnswerData.status === ANSWER_STATUS.ANSWERED) || !(isSurveyOwner || isAdmin)}>
            {t('button.reversion')}
          </StyledButton>
        )}
        {(isRespondentPerson || isAdmin || isViewerAdmin) && (
          <StyledButton
            onClick={() => handleReversionType(REVERSION_REQUEST)}
            isDisabled={
              !(isEnqueteAnswerOpen && !hasError && enqueteAnswerData.status === ANSWER_STATUS.ANSWERED) || !(isRespondentPerson || isAdmin)
            }>
            {t('button.requestReversion')}
          </StyledButton>
        )}
        <StyledButton
          onClick={() => submitHandler(enqueteAnswerData.status === ANSWER_STATUS.RETURNED ? ANSWER_STATUS.RETURNED : ANSWER_STATUS.SAVED)}
          isDisabled={isAnswered || isOnlyAnswerReader || isConfirmor || isOnlyViewerAdmin}>
          {t('button.temporarilySave')}
        </StyledButton>
        <ErrorTooltip title={errorToolTipMessage} open={!canNotSubmit && (!isEnqueteAnswerOpen || hasError)} placement="top" color="secondary" arrow>
          <span>
            <StyledButton onClick={() => submitHandler(ANSWER_STATUS.ANSWERED)} isDisabled={canNotSubmit || !isEnqueteAnswerOpen || hasError}>
              {t(enqueteAnswerData.status === ANSWER_STATUS.RETURNED ? 'button.resubmission' : 'button.submit')}
            </StyledButton>
          </span>
        </ErrorTooltip>
      </Stack>
      <SwitchReversionModalLayout isOpen={isOpen} setIsOpen={setIsOpen} isRequest={isReversionRequest} />
    </>
  );
};
