//言語に応じてtabのスタイルを返却する
export const tabStyles = (lang: string): React.CSSProperties => {
  if (lang === 'ja') {
    return {};
  }
  return { whiteSpace: 'pre-line', fontSize: '10px', textTransform: 'none' };
};

//-------------------------------------------------------------
// タブ（上）タブスタイル設定
//-------------------------------------------------------------
export const topTabCustom = (style: any, isLight: any): React.CSSProperties | undefined => {
  let tabStyle = {
    paddingRight: '16px',
    margin: '0px',
    borderRadius: '0px',
  };
  if (style !== undefined) {
    if (style.backgroundColorLight && style.backgroundColorDark) {
      return {
        ...tabStyle,
        backgroundColor: isLight ? style.backgroundColorLight : style.backgroundColorDark,
        borderColor: 'black',
        borderTop: 'solid 1px',
      };
    } else if (style.backgroundColor) {
      return {
        ...tabStyle,
        backgroundColor: style.backgroundColor,
      };
    }
  }
  return tabStyle;
};

//-------------------------------------------------------------
// タブ（上）コンポーネントスタイル設定
//-------------------------------------------------------------
export const topGridStyleSet = (type: string, surveyDetailData: any, isJapanese: any, isLight: any): React.CSSProperties | undefined => {
  const formData: any = surveyDetailData.surveyFormData.formData ? surveyDetailData.surveyFormData.formData : {};
  // スタイルのデフォルト値
  let tabStyle = {
    width: 300,
    backgroundColor: '#E6C0B7',
    paddingTop: '6px',
    paddingLeft: '0px',
    color: isLight ? '#637381' : '#8e99a6',
    fontWeight: '600',
    fontSize: isJapanese ? '15px' : '12px',
  };
  // 人件費人員の場合
  if ('personnel' === type) {
    tabStyle = {
      ...tabStyle,
      width: isJapanese ? formData.personnelTabWidth?.ja : formData.personnelTabWidth?.en,
      backgroundColor: isLight ? formData.personnelTabStyle?.backgroundColorLight : formData.personnelTabStyle?.backgroundColorDark,
    };
    // 製造原価人員の場合
  } else if ('manufyearPlanur' === type) {
    tabStyle = {
      ...tabStyle,
      width: isJapanese ? formData.manufyearPlanurTabWidth?.ja : formData.manufyearPlanurTabWidth?.en,
      backgroundColor: isLight ? formData.manufyearPlanurTabStyle?.backgroundColorLight : formData.manufyearPlanurTabStyle?.backgroundColorDark,
    };
  }

  return tabStyle;
};

//-------------------------------------------------------------
// タブスタイル設定
//-------------------------------------------------------------
export const tabCustom = (style: any, isLight: any): React.CSSProperties | undefined => {
  let tabStyle = {
    paddingRight: '16px',
    marginRight: '25px',
  };
  if (style !== undefined) {
    if (style.backgroundColorLight && style.backgroundColorDark) {
      return {
        ...tabStyle,
        backgroundColor: isLight ? style.backgroundColorLight : style.backgroundColorDark,
      };
    } else if (style.backgroundColor) {
      return {
        ...tabStyle,
        backgroundColor: style.backgroundColor,
      };
    }
  }
  return tabStyle;
};
