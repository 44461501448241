import { LicenseManager } from 'ag-grid-enterprise';
import ReactGA from 'react-ga4';
import { aggridLicenceKey, googleAnalyticsId, useGa } from './config';

import { RouterProvider } from 'react-router-dom';

import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import RtlLayout from './components/layouts/RtlLayout';
import useAuth from './hooks/useAuth';
import { router } from './routes';
import ThemeConfig from './theme';

export default function App() {
  LicenseManager.setLicenseKey(aggridLicenceKey);

  setLocalstrage();
  function setLocalstrage() {
    let urlParamStr = window.location.search;
    if (urlParamStr) {
      urlParamStr = urlParamStr.substring(1);
      urlParamStr.split('&').forEach((param) => {
        const temp = param.split('=');
        localStorage.setItem(temp[0], temp[1]);
      });
    }
  }

  const { isInitialized } = useAuth();

  // Google Analyticsの解析を行うか
  if (useGa) {
    ReactGA.initialize(googleAnalyticsId);
  }

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            {isInitialized ? <RouterProvider router={router} fallbackElement={<LoadingScreen />} /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
