import { useMenuSwitcher } from 'src/hooks/useMenuSwitcher';
import { OnlyAnswerOverviewPage } from './OnlyAnswerOverviewPage';
import { OverviewPage } from './OverviewPage';
export const OverviewSwitcher: React.FC = () => {
  const { isDisplayOnlyAnswer } = useMenuSwitcher();
  // memo:
  // Data-Insight IDかつ回答メニューのみ表示するロールの場合は、OnlyAnswerOverviewPageを表示する
  if (isDisplayOnlyAnswer) {
    return <OnlyAnswerOverviewPage />;
  }
  return <OverviewPage />;
};
