import { Box, Grid, Pagination } from '@mui/material';
import type { AgGridEvent, ColDef, ColGroupDef, ColumnState, GetContextMenuItems, ITooltipParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { ANSWER_UNIT, FORM_TYPE, LIST_STATE_KEY_PREFIX } from 'src/constants';
import { enqueteAnswerSlice } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import useStatus from 'src/hooks/useStatus';
import { useAppSelector, useDispatch } from 'src/redux/store';
import { switchLabel } from 'src/utils/labelUtils';
import { useEnqueteAnswersListGrid } from '../../../../hooks/useEnqueteAnswersListGrid';
import EnqueteAnswerListToggleButton from './components/EnqueteAnswerListToggleButton';

export const EnqueteAnswersListGridLayout: React.FC = (): React.ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const listData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerslimitedlistData);
  const hook = useEnqueteAnswersListGrid();
  const { currentLang } = useLocales();
  const { t } = useTranslation();
  const { toStringAnswerStatus } = useStatus();
  const { page } = useAppSelector((state) => state.enqueteAnswer);
  const { isAll } = useAppSelector((state) => state.enqueteAnswer);
  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  //bonsai-adminユーザーか
  const { isBonsaiAdmin } = usePermission();

  const startRowPosition: number = 0;
  const startPagePosition: number = 1;

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [useSortModel, setUseSortModel] = useState<ColumnState[]>([]);
  const [blockSize, _setBlockSize] = useState<number>(100); //eslint-disable-line
  const [maxRow, setMaxRow] = useState<number>(1);
  const [isDestroyed, setIsDestroyed] = useState(false);

  const storageKey = 'EnqueteAnswersListGridLayout';

  const dispatch = useDispatch();

  // TODO: hooksへ外出しし、テストを充実させたい
  const setCountLimitedRowData = async (useFileterModel: { [key: string]: string[] }, currentLang: string, isAll: boolean) => {
    setIsLoading(true);
    try {
      //件数データ取得
      const rowCount = await hook.countLimitedLoadList(useFileterModel, currentLang, isAll);
      setMaxRow(rowCount);
    } finally {
      setIsLoading(false);
    }
  };

  const setLimitedRowData = async (
    blockSize: number,
    startRow: number,
    useSortModel: ColumnState[],
    useFiletrModel: { [key: string]: string[] },
    currentLang: string,
    isAll: boolean,
  ) => {
    setIsLoading(true);
    try {
      //データ取得
      await hook.limitedLoadList(blockSize, startRow, useSortModel, useFiletrModel, currentLang, isAll);
    } finally {
      setIsLoading(false);
    }
  };

  const setChangePgae = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(enqueteAnswerSlice.actions.setPage(value));
  };
  const coldef: (ColDef | ColGroupDef)[] = [
    {
      valueGetter: function (params) {
        return toStringAnswerStatus(params.data.answerStatus);
      },
      field: 'answerStatus',
      headerName: t('enqueteAnswerList.answerGridColDef.answerStatus'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.answerStatus'),
      tooltipValueGetter: function (params: ITooltipParams) {
        return toStringAnswerStatus(params.data.answerStatus);
      },
      minWidth: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
        values: [t('answerStatus.unAnswered'), t('answerStatus.saved'), t('answerStatus.answered'), t('answerStatus.returned')],
      },
      floatingFilter: true,
    },
    {
      field: 'isRequestStatusOpen',
      tooltipField: 'isRequestStatusOpen',
      headerName: t('enqueteRequest.gridColDef.requestStatus'),
      headerTooltip: t('enqueteRequest.gridColDef.requestStatus'),
      valueGetter: (params) => {
        const currentDateMilliSecond = new Date().getTime();
        const opendAtMilliSecond = new Date(params.data.openedAt).getTime();
        const closedAtMilliSecond = new Date(params.data.closedAt).getTime();
        let requestStatusMessage: string = '';

        if (currentDateMilliSecond < opendAtMilliSecond) {
          //現在日時よりも開始日時が先の場合
          requestStatusMessage = t('enqueteRequest.requestStatus.beforeRequest');
        } else if (closedAtMilliSecond < currentDateMilliSecond) {
          //終了日時よりも現在日時が先の場合
          requestStatusMessage = t('enqueteRequest.requestStatus.complete');
        } else {
          //現在日時が、開始日時以上 - 終了日時以内の場合
          requestStatusMessage = t('enqueteRequest.requestStatus.inProgress');
        }

        return requestStatusMessage;
      },
      width: 120,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
        values: [
          t('enqueteRequest.requestStatus.beforeRequest'),
          t('enqueteRequest.requestStatus.complete'),
          t('enqueteRequest.requestStatus.inProgress'),
        ],
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: currentLang.value === 'ja' ? 'titleJpn' : 'titleEng',
      headerName: t('enqueteAnswerList.answerGridColDef.title'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.title'),
      tooltipField: currentLang.value === 'ja' ? 'titleJpn' : 'titleEng',
      minWidth: 300,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => {
        return switchLabel(params.data.titleJpn ?? '', params.data.titleEng ?? '', currentLang.value === 'ja');
      },
    },
    {
      field: currentLang.value === 'ja' ? 'companyNameJpn' : 'companyNameEng',
      headerName: t('enqueteAnswerList.answerGridColDef.companyName'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.companyName'),
      tooltipField: currentLang.value === 'ja' ? 'companyNameJpn' : 'companyNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => {
        return switchLabel(params.data.companyNameJpn ?? '', params.data.companyNameEng ?? '', currentLang.value === 'ja');
      },
    },
    {
      field: currentLang.value === 'ja' ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('enqueteAnswerList.answerGridColDef.divisionName'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.divisionName'),
      tooltipField: currentLang.value === 'ja' ? 'divisionNameJpn' : 'divisionNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => {
        return switchLabel(params.data.divisionNameJpn ?? '', params.data.divisionNameEng ?? '', currentLang.value === 'ja');
      },
    },
    {
      field: currentLang.value === 'ja' ? 'sbuNameJpn' : 'sbuNameEng',
      headerName: t('enqueteAnswerList.answerGridColDef.sbuName'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.sbuName'),
      tooltipField: currentLang.value === 'ja' ? 'sbuNameJpn' : 'sbuNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => {
        return switchLabel(params.data.sbuNameJpn ?? '', params.data.sbuNameEng ?? '', currentLang.value === 'ja');
      },
    },
    {
      field: currentLang.value === 'ja' ? 'departmentNameJpn' : 'departmentNameEng',
      headerName: t('enqueteAnswerList.answerGridColDef.departmentName'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.departmentName'),
      tooltipField: currentLang.value === 'ja' ? 'departmentNameJpn' : 'departmentNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => {
        return switchLabel(params.data.departmentNameJpn ?? '', params.data.departmentNameEng ?? '', currentLang.value === 'ja');
      },
    },
    {
      field: currentLang.value === 'ja' ? 'groupNameJpn' : 'groupNameEng',
      headerName: t('enqueteAnswerList.answerGridColDef.groupName'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.groupName'),
      tooltipField: currentLang.value === 'ja' ? 'groupNameJpn' : 'groupNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => {
        return switchLabel(params.data.groupNameJpn ?? '', params.data.groupNameEng ?? '', currentLang.value === 'ja');
      },
    },
    {
      field: 'comNDivName',
      valueGetter: function (params) {
        const companyName = switchLabel(params.data.createdCompanyNameJpn ?? '', params.data.createdCompanyNameEng ?? '', currentLang.value === 'ja');
        const divisionName = switchLabel(
          params.data.createdDivisionNameJpn ?? '',
          params.data.createdDivisionNameEng ?? '',
          currentLang.value === 'ja',
        );
        const sbuName = switchLabel(params.data.createdSbuNameJpn ?? '', params.data.createdSbuNameEng ?? '', currentLang.value === 'ja');
        const departmentName = switchLabel(
          params.data.createdDepartmentNameJpn ?? '',
          params.data.createdDepartmentNameEng ?? '',
          currentLang.value === 'ja',
        );

        return (
          companyName +
          (divisionName !== null ? ` ${divisionName}` : '') +
          (sbuName !== null ? ` ${sbuName}` : '') +
          (departmentName !== null ? ` ${departmentName}` : '')
        );
      },
      headerName: t('enqueteAnswerList.answerGridColDef.requester'),
      headerTooltip: t('enqueteAnswerList.answerGridColDef.requester'),
      /* tooltipValueGetter
      https://www.ag-grid.com/javascript-data-grid/component-tooltip/
      */
      tooltipValueGetter: function (params: ITooltipParams) {
        return params.value;
      },
      minWidth: 350,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];
    //依頼期間判定
    const nowMillis = DateTime.fromJSDate(new Date()).toUTC().toMillis();
    const openMilles = DateTime.fromJSDate(new Date(params.node.data.openedAt)).toUTC().toMillis();
    const closeMilles = DateTime.fromJSDate(new Date(params.node.data.closedAt)).toUTC().toMillis();

    // 回答単位判定
    const isPersonalEnquete = params.node.data.answerUnit === ANSWER_UNIT.PERSONAL;
    const isSeries = params.node.data.formType === FORM_TYPE.SERIES;

    const menuItems = [];

    //アンケート回答入力 遷移メニュー(開催中、完了)
    if (isBonsaiAdmin || openMilles <= nowMillis)
      menuItems.unshift({
        name: t('enqueteAnswerList.contextMenu.reference'),
        action: () => {
          navigate(pathname + '/input/' + params.node?.data.respondentTargetId);
        },
      });

    //回答者変更 遷移メニュー（依頼前、開催中）
    if (!isPersonalEnquete && !isSeries && (isBonsaiAdmin || nowMillis <= closeMilles))
      menuItems.push({
        name: t('enqueteAnswerList.contextMenu.changeRespondent'),
        action: () => {
          navigate(pathname + '/input/' + params.node?.data.respondentTargetId + '?tab=ChangeRespondents');
        },
      });

    return menuItems;
  };

  const onRowDoubleClickedCallback = (event: RowDoubleClickedEvent) => {
    //依頼期間判定
    const nowMillis = DateTime.fromJSDate(new Date()).toUTC().toMillis();
    const openMilles = DateTime.fromJSDate(new Date(event.node?.data.openedAt)).toUTC().toMillis();

    if (isBonsaiAdmin || openMilles <= nowMillis) {
      navigate(pathname + '/input/' + event.node?.data.respondentTargetId);
    } else {
      navigate(pathname + '/input/' + event.node?.data.respondentTargetId + '?tab=ChangeRespondents');
    }
  };

  const setFilterSortLimitedRowData = () => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setCountLimitedRowData(filter, currentLang.code, isAll);
    hook.handlePage(startPagePosition);
    setLimitedRowData(blockSize, startRowPosition, useSortModel, filter, currentLang.code, isAll);
  };

  const onFilterChangedCallback = (event: AgGridEvent) => {
    setFilterSortLimitedRowData();
  };

  const onSortChangedCallback = (event: AgGridEvent) => {
    setUseSortModel(event.columnApi.getColumnState());
    setFilterSortLimitedRowData();
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setCountLimitedRowData(filter, currentLang.code, isAll);
  }, []); //eslint-disable-line

  useEffect(() => {
    destroyGrid();
  }, [currentLang]); //eslint-disable-line

  useEffect(() => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setLimitedRowData(blockSize, (page - startPagePosition) * blockSize, useSortModel, filter, currentLang.code, isAll);
    setCountLimitedRowData(filter, currentLang.code, isAll);
  }, [page, isAll, currentLang]); //eslint-disable-line

  //-------------------------------------------------------------
  // 選択肢の言語変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    setIsDestroyed(true);
    setTimeout(() => recreateGrid(), 0);
  };

  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        {isBonsaiAdmin && (
          <Box style={{ textAlign: 'right' }}>
            <EnqueteAnswerListToggleButton />
          </Box>
        )}
        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: '95vh',
            width: '100%',
          }}>
          <LoadableComponent isLoading={isLoading}>
            {!isDestroyed && (
              <StyledAgGrid
                coldef={coldef}
                rows={listData}
                contextMenu={contextMenu}
                onRowDoubleClickedCallback={onRowDoubleClickedCallback}
                onSortChangedCallback={onSortChangedCallback}
                onFilterChangedCallback={onFilterChangedCallback}
                storageKey={storageKey}
              />
            )}
            <Grid item xs={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination count={maxRow <= blockSize ? 1 : Math.ceil(maxRow / blockSize)} page={page} onChange={setChangePgae} />
              </div>
            </Grid>
          </LoadableComponent>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
};
