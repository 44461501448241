import { useCallback, useState } from 'react';
import { addSubData } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { dispatch } from 'src/redux/store';
import { createTableInitialData } from 'src/utils/handsontable';

export const useCreateNewTables = (
  saveAllSettingsCallback: (saveMode: string) => Promise<void>,
  setOpenColumnHeaderModalCallback: (isOpen: boolean) => void,
  setOpenRowHeaderModalCallback: (isOpen: boolean) => void,
) => {
  // useState
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // 保存ボタンクリック
  const handleSaveClick = useCallback(async () => {
    setIsSaving(true);
    try {
      await saveAllSettingsCallback('db');
    } finally {
      setIsSaving(false);
    }
  }, [saveAllSettingsCallback]);

  // 参照表を追加ボタンクリック
  const handleAddSubClick = useCallback(async () => {
    // 追加前にグローバルステートを保存
    await saveAllSettingsCallback('nodb');
    dispatch(addSubData(createTableInitialData(5, 5)));
  }, [saveAllSettingsCallback]);

  // 列ヘッダ定義ボタンクリック
  const handleColumnHeaderSettingClick = useCallback(async () => {
    await saveAllSettingsCallback('nodb');
    setOpenColumnHeaderModalCallback(true);
  }, [saveAllSettingsCallback, setOpenColumnHeaderModalCallback]);

  // 行ヘッダ定義ボタンクリック
  const handleRowHeaderSettingClick = useCallback(async () => {
    await saveAllSettingsCallback('nodb');
    setOpenRowHeaderModalCallback(true);
  }, [saveAllSettingsCallback, setOpenRowHeaderModalCallback]);

  return { isSaving, handleSaveClick, handleAddSubClick, handleColumnHeaderSettingClick, handleRowHeaderSettingClick };
};
