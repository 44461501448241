import { useReceivablePayableApi } from 'src/api/useReceivablePayableApi';
import { ACCOUNTS_TYPE, CustomerGroup, setDefaultValues } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppDispatch } from 'src/redux/store';

export const useTradingVolumeForm = () => {
  const { getReceivablePayable } = useReceivablePayableApi();
  const dispatch = useAppDispatch();

  const initDefaultValues = async (respondentTargetId: string, customerGroups: CustomerGroup[]) => {
    // 債権債務残高の取得
    const responseReceivablePayable = await getReceivablePayable(respondentTargetId);
    const childrenCustomerCodes = customerGroups.reduce(
      (acc, cur) => [...acc, ...cur.childrenCustomer.map((customer) => customer.customerCode)],
      [] as string[],
    );
    if (responseReceivablePayable && responseReceivablePayable.length !== 0) {
      const defaultValues = childrenCustomerCodes.map((customerCode) => {
        const receivable = responseReceivablePayable.find((elm) => elm.customerCode === customerCode && elm.type === ACCOUNTS_TYPE.RECEIVABLE);
        const payable = responseReceivablePayable.find((elm) => elm.customerCode === customerCode && elm.type === ACCOUNTS_TYPE.PAYABLE);
        return { customerCode, receivableBalance: receivable?.amount ?? null, payableBalance: payable?.amount ?? null };
      });
      dispatch(setDefaultValues(defaultValues));
    }
  };
  return { initDefaultValues };
};
