import { NotificationData, NotificationRequestData } from 'src/api/useNotificationApi';

export const useNotificationEditTabContainer = (
  id: string,
  notification: NotificationData,
  duplicateNotification: (data: NotificationRequestData) => Promise<void>,
  updateNotification: (id: string, data: NotificationRequestData) => Promise<void>,
) => {
  // 複製処理
  const handlerDuplicate = async (data: NotificationRequestData) => {
    // memo: 英語タブを一度も表示しない場合に各要素はundefinedとなってしまう。その場合は元の値か空を入力する。
    if (data.titleEng === undefined) {
      data.titleEng = notification?.titleEng ? notification?.titleEng : '';
    }
    if (data.contentEng === undefined) {
      data.contentEng = notification?.contentEng ? notification?.contentEng : '';
    }
    await duplicateNotification(data);
  };

  // 更新処理
  const handlerSave = async (data: NotificationRequestData) => {
    if (!id) return;
    // memo: 英語タブを一度も表示しない場合に各要素はundefinedとなってしまう。その場合は元の値か空を入力する。
    if (data.titleEng === undefined) {
      data.titleEng = notification?.titleEng ? notification?.titleEng : '';
    }
    if (data.contentEng === undefined) {
      data.contentEng = notification?.contentEng ? notification?.contentEng : '';
    }
    await updateNotification(id, data);
  };

  return { handlerDuplicate, handlerSave };
};
