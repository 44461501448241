import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  AnswerItem,
  GENERAL_ENQUETE_JSON_INPUT_TYPE,
  GENERAL_ENQUETE_VALIDATION_PATTERN,
  NUMBER_OF_ELEMENRS,
  Question,
  QuestionItem,
  Validation,
} from 'src/@types/generalEnquete';
import { AdditionalData } from 'src/@types/overviewEnquete';
import { initRespondentRequestData, RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyEditEntity } from 'src/api/useSurveyEditApi';
import { QUESTION_SAVE_MODE, QUESTIONITEM_SAVE_MODE } from 'src/generalConstants';
import { v1 } from 'uuid';

export const enqueteCreateSlice = createSlice({
  name: 'enqueteCreate',
  initialState: {
    isOpenSelectAndExportModal: false,
    isOpenSelectPersonModal: false,
    isOpenChangeRespondentModal: false,
    isOpenSelectPersonModalAddMode: false,
    openSelectAddressModal: false,
    openImportFileModal: false,
    requestListData: [] as SurveyEditEntity[],
    enqueteData: initRespondentRequestData,
    isValidSaveButton: false,
    isQuestionOpen: false,
    // 一問一答用Question配列編集用
    questions: Array<Question>(),
    // 一問一答(英語)用Question配列編集用。日英変換時に格納する。
    questionsEng: Array<Question>(),
    // 変換ダイアログ画面表示制御
    isModalOpenTranslationSupport: false,
    //アンケート情報(日英変換ダイアログ用)
    respondentRequest: {} as RespondentRequestEntity,
    // 汎用アンケート　日英変換データ　変換ファイルデータ（csvファイル）を格納する。
    questionsTranslationData: [] as string[][],
    // アンケートの説明
    questionDescriptionJpn: '',
    questionDescriptionEng: '',
    isQuestionDescriptionJpnValid: true,
    isQuestionDescriptionEngValid: true,
    // 質問（Question）編集用
    question: {} as Question,
    // 回答選択肢編集用の配列
    questionItem: [] as QuestionItem[],
    // バリデーション編集用
    validation: {
      required: {
        isRequired: false,
        errorMessage: '',
      },
    } as Validation,
    // 回答編集用の配列
    answerItem: Array<AnswerItem>(),
    // プレビューダイアログ画面表示制御
    isModalOpenPreview: false,
    // ロジック設定ダイアログ画面表示制御
    isModalOpenLogic: false,
    // 一問一答ロジック設定用Question配列編集用
    logicQuestions: Array<Question>(),
    // ロジック設定エラーメッセージダイアログ画面表示制御
    isOpenLogicErrorMessageModal: false,
    selectedQuestionId: '',
    // ロジック設定エラーメッセージ
    logicErrorMessages: [] as string[],
    /** 系列別用 start **/
    // 系列別アンケートSBU集約設定モーダル 可視制御
    isOpenSbuAggregationModal: false,
    // 系列別アンケートSBU集約設定モーダル 編集モード
    isEditSbuAggregationModal: false,
    /** 系列別用 end **/
    // 概況表の取引先が削除されたかどうか
    isDeletedCustomer: false,
    // 概況表アンケート実施中に追加された項目を管理
    additionalData: {} as AdditionalData,
  },
  reducers: {
    setOpenSelectAndExportModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSelectAndExportModal = action.payload;
    },
    setIsOpenSelectPersonModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSelectPersonModal = action.payload;
    },
    setIsOpenChangeRespondentModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenChangeRespondentModal = action.payload;
    },
    setIsOpenSelectPersonModalAddMode: (state, action: PayloadAction<boolean>) => {
      state.isOpenSelectPersonModalAddMode = action.payload;
    },
    setOpenSelectAddressModal: (state, action: PayloadAction<boolean>) => {
      state.openSelectAddressModal = action.payload;
    },
    setOpenImportFileModal: (state, action: PayloadAction<boolean>) => {
      state.openImportFileModal = action.payload;
    },
    initRequestListData: (state, action: PayloadAction<SurveyEditEntity[]>) => {
      state.requestListData = action.payload;
    },
    addRequestListData: (state, action: PayloadAction<SurveyEditEntity[]>) => {
      state.requestListData = [...action.payload, ...current(state.requestListData)];
    },
    /** enqueteData start */
    setEnqueteData: (state, action: PayloadAction<RespondentRequestEntity>) => {
      state.enqueteData = action.payload;
    },
    clearEnqueteData: (state) => {
      state.enqueteData = initRespondentRequestData;
    },
    /** enqueteData end */

    /** isValidSaveButton start */
    toEnabledSaveButton: (state) => {
      state.isValidSaveButton = true;
    },
    toDisabledSaveButton: (state) => {
      state.isValidSaveButton = false;
    },
    /** isValidSaveButton end */
    setQuestionDescriptionJpn: (state, action: PayloadAction<string>) => {
      if (action.payload !== undefined) {
        state.questionDescriptionJpn = action.payload;
      } else {
        state.questionDescriptionJpn = '';
      }
    },
    setQuestionDescriptionEng: (state, action: PayloadAction<string>) => {
      state.questionDescriptionEng = action.payload;
    },
    setIsQuestionDescriptionJpnValid: (state, action: PayloadAction<boolean>) => {
      if (state.isQuestionDescriptionJpnValid !== action.payload) {
        state.isQuestionDescriptionJpnValid = action.payload;
      }
    },
    setIsQuestionDescriptionEngValid: (state, action: PayloadAction<boolean>) => {
      if (state.isQuestionDescriptionEngValid !== action.payload) {
        state.isQuestionDescriptionEngValid = action.payload;
      }
    },

    // 質問を初期化
    initQuestions: (state) => {
      state.isQuestionOpen = false;
      state.questions = [];
      state.questionsEng = [];
      state.questionsTranslationData = [];
      state.question = {} as Question;
      state.questionItem = [];
      state.validation = {
        required: {
          isRequired: false,
          errorMessage: '',
        },
      } as Validation;
      state.answerItem = [];
      state.logicQuestions = [];
    },

    //質問作成エリアの表示非表示
    setIsQuestionOpen: (state, action: PayloadAction<boolean>) => {
      state.isQuestionOpen = action.payload;
    },
    // 質問を設定
    setQuestions: (state, action: PayloadAction<Array<Question>>) => {
      state.questions = action.payload;
    },
    // 質問追加
    saveQuestion: (state, action) => {
      const { mode, index, copyData } = action.payload;
      switch (mode) {
        case QUESTION_SAVE_MODE.ADD:
          state.questions.push(state.question);
          state.questionsEng.push(state.question);
          break;
        case QUESTION_SAVE_MODE.DELETE:
          state.questions.splice(index, 1);
          state.questionsEng.splice(index, 1);
          break;
        case QUESTION_SAVE_MODE.EDIT:
          state.questions.splice(index, 1, state.question);
          state.questionsEng.splice(index, 1, state.question);
          break;
        case QUESTION_SAVE_MODE.COPY:
          state.questions.push(copyData);
          state.questionsEng.push(copyData);
          break;
        default:
          break;
      }
    },

    // インプットタイプ設定
    setInputType: (state, action) => {
      state.question.inputType = action.payload;
    },
    //タイトル設定
    setTitle: (state, action) => {
      state.question.title = action.payload;
    },

    // 既存質問を編集
    setQuestion: (state, action) => {
      const { formData, currentInputType, data } = action.payload;
      state.question = {
        id: data ? data.id : v1(),
        title: formData.textQuestion,
        inputType: currentInputType ? currentInputType : state.question.inputType,
        questionItem: state.questionItem,
        conditions: data?.conditions ? data.conditions : [],
        validation: {
          required: { isRequired: formData.requireCheck, errorMessage: formData.requireCheck ? formData.requireErrorMessage : undefined },
          pattern:
            formData.typeCheck && !formData.element ? { value: formData.pattern, errorMessage: formData.typeValidationErrorMessage } : undefined,
          // Memo: formData.setMin などをNumberに変換しているが、呼び出し元のRHFNumericFieldが文字列型となるため、数値型に変換する。
          // 呼び出し元（RHF側）で数値であることを担保しているためこのタイミングで改めてチェックする必要はない。
          min: formData.typeCheck
            ? formData.pattern === GENERAL_ENQUETE_VALIDATION_PATTERN.NUMBER
              ? { value: Number(formData.setMin), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
          max: formData.typeCheck
            ? formData.pattern === GENERAL_ENQUETE_VALIDATION_PATTERN.NUMBER
              ? { value: Number(formData.setMax), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
          minLength: formData.typeCheck
            ? formData.pattern === GENERAL_ENQUETE_VALIDATION_PATTERN.STRING
              ? { value: Number(formData.setMin), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
          maxLength: formData.typeCheck
            ? formData.pattern === GENERAL_ENQUETE_VALIDATION_PATTERN.STRING
              ? { value: Number(formData.setMax), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
          minNumberOfElements: formData.typeCheck
            ? formData.element === NUMBER_OF_ELEMENRS.AT_LEAST
              ? { value: Number(formData.checkboxRange), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
          maxNumberOfElements: formData.typeCheck
            ? formData.element === NUMBER_OF_ELEMENRS.AT_MOST
              ? { value: Number(formData.checkboxRange), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
          numberOfDecimalPlaces: formData.typeCheck
            ? formData.pattern === GENERAL_ENQUETE_VALIDATION_PATTERN.NUMBER && formData.setNumberOfDecimalPlaces
              ? { value: Number(formData.setNumberOfDecimalPlaces), errorMessage: formData.typeValidationErrorMessage }
              : undefined
            : undefined,
        },
        answerItem: [],
      };
    },

    // QuestionItemを設定
    setQuestionItem: (state, action) => {
      const { index, value, mode, currentInputType } = action.payload;
      switch (currentInputType) {
        case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXT:
        case GENERAL_ENQUETE_JSON_INPUT_TYPE.TEXTAREA:
          if (mode === QUESTIONITEM_SAVE_MODE.REFILL) {
            state.questionItem = value;
          } else {
            state.questionItem.splice(0, 1, value);
          }
          break;
        case GENERAL_ENQUETE_JSON_INPUT_TYPE.CHECKBOX:
          if (mode === QUESTIONITEM_SAVE_MODE.ADD) {
            state.questionItem.push(value);
          } else if (mode === QUESTIONITEM_SAVE_MODE.MODIFY) {
            state.questionItem.splice(index, 1, value);
          } else if (mode === QUESTIONITEM_SAVE_MODE.DELETE) {
            state.questionItem = state.questionItem.filter((item, itemIndex) => itemIndex !== index);
          } else if (mode === QUESTIONITEM_SAVE_MODE.REFILL) {
            state.questionItem = value;
          }
          break;
        case GENERAL_ENQUETE_JSON_INPUT_TYPE.RADIOGROUP:
        case GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT:
          if (mode === QUESTIONITEM_SAVE_MODE.ADD) {
            state.questionItem.splice(index, 1, value);
          } else if (mode === QUESTIONITEM_SAVE_MODE.MODIFY) {
            state.questionItem.splice(index, 1, value);
          } else if (mode === QUESTIONITEM_SAVE_MODE.DELETE) {
            state.questionItem = state.questionItem.filter((item, itemIndex) => itemIndex !== index);
          } else if (mode === QUESTIONITEM_SAVE_MODE.REFILL) {
            state.questionItem = value;
          }
          break;
        default:
          break;
      }
    },

    //質問アイテムとバリデーションチェックを削除
    clearQuestionItemOrValidation: (state) => {
      state.question.inputType = '';
      state.question.title = '';
      state.questionItem = Array<QuestionItem>();
      state.validation = { required: { isRequired: false, errorMessage: '' } };
    },

    // 日英変換されたQuestionsを設定
    setQuestionsEng: (state, action: PayloadAction<Array<Question>>) => {
      state.questionsEng = action.payload;
    },

    // 汎用アンケート　日英変換データ　変換ファイルデータ（csvファイル）を格納する。
    setQuestionsTranslationData: (state, action: PayloadAction<Array<Array<string>>>) => {
      state.questionsTranslationData = action.payload;
    },

    // 変換ダイアログ画面表示制御
    setIsModalOpenTranslationSupport: (state, action: PayloadAction<boolean>) => {
      state.isModalOpenTranslationSupport = action.payload;
    },

    // アンケート情報(日英変換ダイアログ用)
    setRespondentRequest: (state, action: PayloadAction<RespondentRequestEntity>) => {
      state.respondentRequest = action.payload;
    },
    // プレビューダイアログ画面表示制御を設定
    setIsModalOpenPreview: (state, action: PayloadAction<boolean>) => {
      state.isModalOpenPreview = action.payload;
    },
    // ロジック設定ダイアログ画面表示制御を設定
    setIsModalOpenLogic: (state, action: PayloadAction<boolean>) => {
      state.isModalOpenLogic = action.payload;
    },
    // 一問一答ロジック設定用Question配列を設定
    setLogicQuestions: (state, action: PayloadAction<Array<Question>>) => {
      state.logicQuestions = action.payload;
    },
    // ロジック設定エラーメッセージダイアログ画面表示制御を設定
    setIsOpenLogicErrorMessageModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenLogicErrorMessageModal = action.payload;
    },
    // ロジック設定エラーメッセージダイアログ画面表示制御を設定
    setLogicErrorMessages: (state, action: PayloadAction<string[]>) => {
      state.logicErrorMessages = action.payload;
    },

    /***** 系列別用 start *****/

    /** isOpenSbuAggregationModal start */
    setIsOpenSbuAggregationModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSbuAggregationModal = action.payload;
    },
    /** isOpenSbuAggregationModal end */
    /** isEditSbuAggregationModal start */
    setIsEditSbuAggregationModal: (state, action: PayloadAction<boolean>) => {
      state.isEditSbuAggregationModal = action.payload;
    },
    /** isEditSbuAggregationModal end */

    /***** 系列別用 end *****/

    // 概況表の取引先が削除されたかどうか
    setIsDeletedCustomer: (state, action: PayloadAction<boolean>) => {
      state.isDeletedCustomer = action.payload;
    },

    // 概況表アンケート実施中に追加された項目を管理
    setAdditionalData: (state, action: PayloadAction<AdditionalData>) => {
      state.additionalData = action.payload;
    },

    // 概況表アンケート実施中に追加された項目をリセットする
    setResetAdditionalData: (state) => {
      state.additionalData = {} as AdditionalData;
    },
  },
});

export const {
  setIsQuestionOpen,
  initQuestions,
  setQuestions,
  saveQuestion,
  setQuestion,
  setInputType,
  setQuestionItem,
  clearQuestionItemOrValidation,
  setQuestionDescriptionJpn,
  setQuestionDescriptionEng,
  setIsQuestionDescriptionJpnValid,
  setIsQuestionDescriptionEngValid,
  setQuestionsEng,
  setQuestionsTranslationData,
  setIsModalOpenTranslationSupport,
  setRespondentRequest,
  setIsModalOpenPreview,
  setIsModalOpenLogic,
  setLogicQuestions,
  setIsOpenLogicErrorMessageModal,
  setLogicErrorMessages,
  setEnqueteData,
  clearEnqueteData,
  toEnabledSaveButton,
  toDisabledSaveButton,
  setIsOpenSbuAggregationModal,
  setIsEditSbuAggregationModal,
  setIsDeletedCustomer,
  setAdditionalData,
  setResetAdditionalData,
} = enqueteCreateSlice.actions;

export default enqueteCreateSlice.reducer;
