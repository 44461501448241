import DirectionsIcon from '@mui/icons-material/Directions';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export const ThreadHeaderBox = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }}>
      {/* todo: フィルター機能実装にコメントアウトを外すこと
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete disablePortal options={surveyList} renderInput={(params) => <TextField {...params} label={thread.enqueteType} />} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="search"
            type="search"
            label="Search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid> */}
      <Grid container justifyContent="right" alignItems="center" sx={{ mt: 2 }}>
        {/* <Grid item>
          <Link sx={{ mr: 2 }}>{t('enqueteThread.list.header.showOnlyMyThread')}</Link>
          <Link>{t('enqueteThread.list.header.showOnlyUnreadThread')}</Link>
        </Grid> */}
        <Grid item>
          <Button variant="contained" endIcon={<DirectionsIcon />} component={RouterLink} to="/general/enquete-threads/create">
            {t('button.createNew')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
