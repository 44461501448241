import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { switchLabel } from 'src/utils/labelUtils';

export const useBonsaiAddedCustomerListColDef = () => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    editable: false,
    filter: true,
  };

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      field: 'customerCode',
      headerName: t('common.code.customer'),
    },
    {
      field: isLangJpn ? 'customerNameJpn' : 'customerNameEng',
      headerName: t('common.customer'),
      valueGetter: (params) => switchLabel(params.data['customerNameJpn'], params.data['customerNameEng'], isLangJpn),
    },
    {
      field: 'parentCustomerCode',
      headerName: t('common.code.parentCustomer'),
    },
    {
      field: isLangJpn ? 'parentCustomerNameJpn' : 'parentCustomerNameEng',
      headerName: t('common.parentCustomer'),
      valueGetter: (params) => switchLabel(params.data['parentCustomerNameJpn'], params.data['parentCustomerNameEng'], isLangJpn),
    },
    {
      field: 'topGlobalCustomerCode',
      headerName: t('common.code.topParentCustomer'),
    },
    {
      field: isLangJpn ? 'topGlobalCustomerNameJpn' : 'topGlobalCustomerNameEng',
      headerName: t('common.topParentCustomer'),
      valueGetter: (params) => switchLabel(params.data['topGlobalCustomerNameJpn'], params.data['topGlobalCustomerNameEng'], isLangJpn),
    },
    {
      field: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
      headerName: t('common.region'),
      valueGetter: (params) => switchLabel(params.data['regionNameJpn'], params.data['regionNameEng'], isLangJpn),
    },
    {
      field: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
      headerName: t('common.country'),
      valueGetter: (params) => switchLabel(params.data['countryNameJpn'], params.data['countryNameEng'], isLangJpn),
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return { defaultColDef, colDef };
};
