import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { DataOrigin } from 'src/constants';
import { AccountsType } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useBearerToken } from './useBearerToken';

const BONSAI_URL = `${apiGateway}/enquete-answer-input/accounts-receivable-payable`;

export interface ReceivablePayableAmount {
  amount: number;
  customerCode: string;
  dataSource: DataOrigin;
  currencyCode: string;
  type: AccountsType;
}

export const useReceivablePayableApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getReceivablePayable = async (respondentTargetId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<ReceivablePayableAmount[]>(`${BONSAI_URL}/${respondentTargetId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteAnswerInput.seriesSurvey.debtBalance') }), {
          variant: 'error',
        });
      } else {
        throw error;
      }
    }
  };

  return { getReceivablePayable };
};
