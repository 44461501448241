import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Control, Controller, FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface PropsEntity {
  name: string;
  defaultValue: string;
  control: Control<FieldValues, any>;
  rules: Rules;
  getValues: UseFormGetValues<FieldValues>;
  list: Option[];
  disabled: boolean;
  toolTip?: React.ReactNode;
}

type Option = {
  value: string;
  label: string;
};

type Rules = {
  required: {
    value: boolean;
    message: string;
  };
};

const InputRadioGroup: React.FC<PropsEntity> = (props) => {
  const { name, defaultValue, control, rules, getValues, list, disabled, toolTip } = props;
  const { t } = useTranslation();
  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
      <Grid item xs={12} md={4}>
        {toolTip === undefined ? (
          <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }} children={t(`enqueteGeneralCommon.${name}`)} />
        ) : (
          <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
            {t(`enqueteGeneralCommon.${name}`)} {toolTip}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <Controller
          control={control}
          rules={rules}
          name={name}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <RadioGroup
                defaultValue={defaultValue ?? undefined}
                value={getValues(name) === undefined ? defaultValue : getValues(name)}
                onChange={onChange}
                row>
                {list.map((e) => {
                  return (
                    <FormControlLabel
                      key={e.value}
                      control={<Radio checked={getValues(name) === e.value} disabled={disabled} />}
                      value={e.value}
                      label={e.label}
                    />
                  );
                })}
              </RadioGroup>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default InputRadioGroup;
