import { faCheckCircle, faCircleExclamation, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, CircularProgress, Collapse, List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { convertToYYYYMMDD } from 'src/utils/convertToYYYYMMDD';
import { getResponseOrganizationInfo } from 'src/utils/getResponseOrganizationInfo';
import { useListToggle } from '../hooks/useListToggle';
import { usePreviousAnswersList } from '../hooks/usePreviousAnswersList';
import { PreviousAnswersLink } from './PreviousAnswerLink';

export const PreviousAnswersList: React.FC = () => {
  const answerList = useAppSelector((state: RootState) => state.enqueteAnswer.enqueteAnswerslistData);
  const { isLoading, isError } = usePreviousAnswersList();
  const { isOpen, handleClick } = useListToggle();
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const tKey = 'enqueteAnswerInput.previousAnswerList';

  const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', mb: 2 }}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100%', mb: 2, ml: 1, color: 'error.main' }}>
        <FontAwesomeIcon icon={faCircleExclamation} style={{ fontSize: '1rem' }} />
        <Typography sx={{ ml: 1, fontSize: '0.8rem' }}>{t(`${tKey}.failedToLoadPreviousAnswers`)}</Typography>
      </Box>
    );
  }

  if (answerList.length === 0 || !answerList) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100%', mb: 2, ml: 1, color: 'info.main' }}>
        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '1rem' }} />
        <Typography sx={{ ml: 1, fontSize: '0.8rem' }}>{t(`${tKey}.noPreviousAnswers`)}</Typography>
      </Box>
    );
  }

  return (
    <List sx={{ width: '100%', mt: -2, textAlign: 'right' }}>
      {/* 直近のアンケートを表示 */}
      <PreviousAnswersLink respondentTargetId={answerList[0].respondentTargetId} label={t(`${tKey}.previousAnswerList`)} indentPl={2} />
      {/* 追加で表示可能なアンケートがあれば、トグルにして表示 */}
      {1 < answerList.length && (
        <>
          <ListItemButton onClick={handleClick} sx={{ color: 'primary.main' }}>
            <ListItemIconStyle>
              <FontAwesomeIcon icon={faClipboardCheck} style={{ fontSize: '20px' }} />
            </ListItemIconStyle>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t(`${tKey}.showMore`)}</Box>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* １件目は表示済みのため除外 */}
              {answerList
                .filter((_, i) => i !== 0)
                .map((item) => {
                  const title = currentLang.value === 'ja' ? item.titleJpn : item.titleEng;
                  return (
                    <PreviousAnswersLink
                      respondentTargetId={item.respondentTargetId}
                      label={`${title} ${getResponseOrganizationInfo(item, currentLang.value)} ${convertToYYYYMMDD(item.updatedAt)} `}
                      key={`previous-answers-link-${item.respondentTargetId}`}
                      indentPl={4}
                    />
                  );
                })}
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
};
