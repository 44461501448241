import { Alert, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { openEditCustomerModal } from 'src/features/manage/customer-management/store/customerManagementSlice';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';

export const CustomerManagementListHeader = () => {
  const { t } = useTranslation();
  const { isViewerAdmin, isLoadingPermission } = usePermission();
  const { isCustomerLoading } = useAppSelector((state: RootState) => state.customerManagement);
  return (
    <Stack direction="row" spacing={2} mb={1} alignItems={'center'} justifyContent={'space-between'} paddingX={0}>
      <Alert severity="info"> {t('customerManagement.description')}</Alert>
      <StyledButton
        onClick={() => {
          dispatch(openEditCustomerModal(null));
        }}
        isDisabled={isCustomerLoading || isLoadingPermission || isViewerAdmin}>
        {t('button.add')}
      </StyledButton>
    </Stack>
  );
};
