import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export type BonsaiAddedCustomer = {
  topGlobalCustomerCode: string;
  topGlobalCustomerNameJpn: string;
  topGlobalCustomerNameEng: string;
  customerCode: string;
  customerNameJpn: string;
  customerNameEng: string;
  parentCustomerCode: string | null;
  parentCustomerNameJpn: string | null;
  parentCustomerNameEng: string | null;
  regionCode: string;
  regionNameJpn: string | null;
  regionNameEng: string | null;
  countryCode: string;
  countryNameJpn: string | null;
  countryNameEng: string | null;
};

export type ParentCustomer = {
  labelJpn: string;
  labelEng: string;
  customerCode: string;
  topGlobalCustomerCode: string;
  topDomesticCustomerCode: string;
  countryCode: string;
  regionCode: string;
};

export type CatsCustomer = {
  labelJpn: string;
  labelEng: string;
  customerCode: string;
  parentCustomerCode: string | null;
  parentCustomerNameJpn: string | null;
  parentCustomerNameEng: string | null;
};

export type CustomerManagement = {
  bonsaiAddedCustomers: BonsaiAddedCustomer[];
  parentCustomers: ParentCustomer[];
  catsCustomers: CatsCustomer[];
};

export type RegisterParentCustomerInfo = {
  customerCode: string;
  topGlobalCustomerCode: string;
  topDomesticCustomerCode: string;
  countryCode: string;
  regionCode: string;
};

export type RegisterCustomerInfo = {
  parent: RegisterParentCustomerInfo;
  customerNameJpn: string;
  customerNameEng: string;
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
};

export const useCustomerManagementApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getCustomerManagement = useCallback(async (): Promise<CustomerManagement> => {
    const token = await getBearerToken();
    try {
      const response = await axios.get<CustomerManagement>(apiGateway + `/customer-management`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('customerManagement.customerList') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
    return { bonsaiAddedCustomers: [], parentCustomers: [], catsCustomers: [] };
  }, [enqueueSnackbar, getBearerToken, t]);

  const registerCustomer = useCallback(
    async (body: RegisterCustomerInfo): Promise<void> => {
      const token = await getBearerToken();
      try {
        await axios.post(apiGateway + `/customer-management`, body, {
          headers: { Authorization: token },
          timeout: 60000,
        });
        enqueueSnackbar(t('message.register'), { variant: 'success' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('common.customer') }), { variant: 'error' });
        } else {
          throw error;
        }
      }
    },
    [enqueueSnackbar, getBearerToken, t],
  );

  const updateCustomer = useCallback(
    async (customerCode: string, body: RegisterCustomerInfo): Promise<void> => {
      const token = await getBearerToken();
      try {
        await axios.put(apiGateway + `/customer-management/${customerCode}`, body, {
          headers: { Authorization: token },
          timeout: 60000,
        });
        enqueueSnackbar(t('message.update'), { variant: 'success' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('common.customer') }), { variant: 'error' });
        } else {
          throw error;
        }
      }
    },
    [enqueueSnackbar, getBearerToken, t],
  );

  const applyA1Code = useCallback(
    async (customerCode: string, body: { a1Code: string }): Promise<void> => {
      const token = await getBearerToken();
      try {
        await axios.put(apiGateway + `/customer-management/applyA1Code/${customerCode}`, body, {
          headers: { Authorization: token },
          timeout: 60000,
        });
        enqueueSnackbar(t('message.update'), { variant: 'success' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('common.customer') }), { variant: 'error' });
        } else {
          throw error;
        }
      }
    },
    [enqueueSnackbar, getBearerToken, t],
  );
  return { getCustomerManagement, registerCustomer, updateCustomer, applyA1Code };
};
