import { Box, Chip, Typography } from '@mui/material';
import { EnqueteComments } from 'src/api/getCommentsApi';
import useLocales from 'src/hooks/useLocales';

interface Props {
  thread: EnqueteComments;
}

export const ThreadHeaderBox = ({ thread }: Props) => {
  const { isPrivate, categories, title } = thread;
  const { isJapanese } = useLocales();

  return (
    <>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
        <Chip label={isPrivate ? 'Private' : 'Public'} color={isPrivate ? 'primary' : 'error'} size="medium" sx={{ verticalAlign: 'middle' }} />
        {categories.map((category) => (
          <Chip label={isJapanese ? category.nameJpn : category.nameEng} key={category.id} />
        ))}
        {/*　memo: 解決済みは2次開発での開発対象外　将来のためコメントアウト */}
        {/* <Chip label={t('enqueteThread.common.isSolved')} color="success" size="medium" /> */}
      </Box>
    </>
  );
};
