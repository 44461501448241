import { Grid } from '@mui/material';
import React from 'react';
import { DragDropContext, Draggable, DraggableLocation, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { StyledBox } from 'src/components/app-components/StyledBox';
import {
  setIsOpenLogicErrorMessageModal,
  setLogicErrorMessages,
  setQuestions,
  setQuestionsEng,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { setIsOpenEditor, setSelectQuestionId } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, useDispatch, useSelector } from 'src/redux/store/index';
import { useLogicSettingCheck } from '../hooks/useLogicSettingCheck';
import EnqueteCreateQuestionPreviewSwitcher from './EnqueteCreateQuestionPreviewSwitcher';

const EnqueteCreateQuestionPreviewForm = () => {
  const questionsJpn = useSelector((state: RootState) => state.enqueteCreate.questions);
  const questionsEng = useSelector((state: RootState) => state.enqueteCreate.questionsEng);
  const isOpenEditor = useSelector((state: RootState) => state.generalPurposeQuestionPreviewEdit.isOpenEditor);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  const questions = currentLang.value === 'ja' ? questionsJpn : questionsEng;
  const { isLogicQuestion } = useLogicSettingCheck();

  const handleOnMouse = (event: React.MouseEvent<HTMLDivElement>) => {
    switch (event.type) {
      case 'mouseenter':
        if (!isOpenEditor) {
          dispatch(setSelectQuestionId(event.currentTarget.getAttribute('data-rbd-drag-handle-draggable-id') as string));
        }
        break;
      case 'mouseleave':
        if (!isOpenEditor) {
          dispatch(setSelectQuestionId(undefined));
          dispatch(setIsOpenEditor(false));
        }
        break;
      default:
        break;
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    // 質問にロジックが設定されているか？
    if (isLogicQuestion(questions, questions[result.source.index])) {
      // ロジックエラーメッセージダイアログを表示する
      dispatch(setLogicErrorMessages([t('enqueteCreate.logic.errorMessage.questionMoveError')]));
      dispatch(setIsOpenLogicErrorMessageModal(true));
    } else {
      const items = Array.from(questionsJpn);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice((result.destination as DraggableLocation).index, 0, reorderedItem);
      dispatch(setQuestions(items));
      const itemsEng = Array.from(questionsEng);
      const [reorderedItemEng] = itemsEng.splice(result.source.index, 1);
      itemsEng.splice((result.destination as DraggableLocation).index, 0, reorderedItemEng);
      dispatch(setQuestionsEng(itemsEng));
    }
  };

  return (
    <Grid container justifyContent="center">
      <StyledBox>
        <Grid item xs={12} md={12}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyle: 'none' }}>
                  {questions.map((question: Question, index: number) => {
                    return (
                      <Draggable key={question.id} draggableId={question.id} index={index}>
                        {(provided) => (
                          <Grid
                            item
                            xs={12}
                            key={question.id}
                            mt={2}
                            mb={2}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onMouseEnter={handleOnMouse}
                            onMouseLeave={handleOnMouse}>
                            <EnqueteCreateQuestionPreviewSwitcher question={question} index={index} />
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </StyledBox>
    </Grid>
  );
};

export default EnqueteCreateQuestionPreviewForm;
