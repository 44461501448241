import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { GeneralEnquete, TableType } from 'src/@types/generalEnquete';
import { initSettings, setMainTableSheetNames, setSubData, setTotalMainTables } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { useAppSelector, useDispatch } from 'src/redux/store/index';
import { setQuestionDescriptionEng, setQuestionDescriptionJpn } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { useSurveyTableAnswerDataService } from './useSurveyTableAnswerDataService';

export const useTableAnswerData = () => {
  const dispatch = useDispatch();
  const { makeTotalMainTables, makeMainTableSheetNames } = useSurveyTableAnswerDataService();

  const surveyDetailData = useAppSelector((stateGeneral) => stateGeneral.enqueteAnswer.surveyDetailData);
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  const surveyDetailTabData = surveyDetailData.surveyFormData.formData.tabData[0] as GeneralEnquete;
  const tabData = !isEmpty(enqueteAnswerData) ? (enqueteAnswerData.answerData.answerInputData.tabData[0] as GeneralEnquete) : surveyDetailTabData;

  useEffect(() => {
    const totalMainTables = makeTotalMainTables(tabData, surveyDetailTabData.tables?.rowHeaders, surveyDetailTabData.tables?.nestedHeaders);
    const mainTableSheetNames = makeMainTableSheetNames(tabData);

    dispatch(setQuestionDescriptionJpn(surveyDetailTabData.questionDescriptionJpn));
    dispatch(setQuestionDescriptionEng(surveyDetailTabData.questionDescriptionEng));
    dispatch(setMainTableSheetNames(mainTableSheetNames));
    dispatch(setTotalMainTables(totalMainTables));
    dispatch(setSubData((tabData.tables as TableType).subDatas));

    // アンマウント時
    return () => {
      dispatch(setQuestionDescriptionJpn(''));
      dispatch(setQuestionDescriptionEng(''));
      dispatch(initSettings());
    };
  }, [surveyDetailData, enqueteAnswerData]); // eslint-disable-line react-hooks/exhaustive-deps
};
