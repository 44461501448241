import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-enterprise';
import { useTranslation } from 'react-i18next';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { currencyFormat } from 'src/utils/currencyFormat';

export const useSummaryAggregateColdef = (previousYear: string, currentYear: string) => {
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { t } = useTranslation();

  const gridOptions: GridOptions = {
    domLayout: 'print',
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
  };

  //ベースとなるColDef
  const colDef: (ColDef | ColGroupDef)[] = [
    {
      field: isLangJpn ? 'customerNameJpn' : 'customerNameEng',
      headerName: t('common.customer'),
      valueFormatter: (params: any): string => (isLangJpn ? trimStockCompany(params.value) : params.value),
    },
    {
      field: isLangJpn ? 'amountJpn' : 'amountEng',
      headerName: t('enqueteStatus.summaryAggregate.amount'),
    },
    {
      field: 'previousYear',
      headerName: `FY${previousYear}`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
    },
    {
      field: 'currentYear',
      headerName: `FY${currentYear}`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
    },
  ];

  return {
    defaultColDef,
    colDef,
    gridOptions,
  };
};
