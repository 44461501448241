import { CardHeader, SxProps, Theme, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { validateIsoDateString } from 'src/utils/validateIsoDateString';
import { ThreadAvatar } from './ThreadAvatar';

type ThreadUserInfoProps = {
  userName: string;
  organizationName: string;
  updatedAt: string | null;
  sx?: SxProps<Theme>;
};

export const ThreadUserInfoBox = ({ userName, organizationName, updatedAt, sx = { p: 0, m: 0 } }: ThreadUserInfoProps) => {
  const { t } = useTranslation();
  let formattedDate: string | undefined;
  if (updatedAt && validateIsoDateString(updatedAt)) {
    formattedDate = format(new Date(updatedAt), 'yyyy-MM-dd HH:mm', { locale: ja });
  }

  return (
    <CardHeader
      avatar={<ThreadAvatar userName={userName} />}
      title={
        <>
          <Typography>{userName}</Typography>
          <Typography sx={{ fontSize: '0.8em' }}>{organizationName}</Typography>
        </>
      }
      subheader={formattedDate && t('enqueteThread.common.updated', { variable: formattedDate })}
      sx={sx}
    />
  );
};
