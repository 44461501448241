import { ComplementaryOrganizationEntity, useComplementaryOrganizationApi } from 'src/api/useComplementaryOrganizationApi';
import { useAppDispatch } from 'src/redux/store';
import { complementaryOrganizationSlice } from '../stores/complementaryOrganizationSlice';

export const useComplementaryOrganizationListGrid = () => {
  const dispatch = useAppDispatch();
  const complementaryOrganizationApi = useComplementaryOrganizationApi();

  /**
   * APIから行データ取得
   */
  const getList = async () => {
    //RespondentRequestデータ取得

    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.initListData([]));
    dispatch(complementaryOrganizationSlice.actions.initDetailData({} as ComplementaryOrganizationEntity));

    const complementaryOrganizationList: ComplementaryOrganizationEntity[] | undefined =
      await complementaryOrganizationApi.getComplementaryOrganizations();
    if (complementaryOrganizationList === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.initListData(complementaryOrganizationList));
  };

  /**
   * 1行削除
   */
  const deleteRow = async (id: string) => {
    await complementaryOrganizationApi.deleteComplementaryOrganization(id);

    dispatch(complementaryOrganizationSlice.actions.deleteRowData(id));
  };

  return { getList, deleteRow };
};
