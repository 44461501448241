import { useTranslation } from 'react-i18next';
import { ROW_COLUMN_HEADER } from 'src/generalConstants';

export const useHeaderSettingFormValidator = () => {
  const { t } = useTranslation();

  const rulesRowColumnHeader = {
    required: {
      value: false,
    },
    validate: {
      isValidCharacter: (value: string) => {
        for (let char of value) {
          if (char && !RegExp(/[ -~\u2460-\u24E9\u3000-\u30ff\u3200-\u33ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\uff9f\uffe0-\uffee]/).exec(char)) {
            return `${t('enqueteCreate.headerSettingModal.validateError.invalidCharacter', { max: ROW_COLUMN_HEADER.MAX_LENGTH })}`;
          }
        }
      },
      isValidMax: (value: string) => {
        if (value !== '' && value !== null && value !== undefined) {
          const splitData = value.split(',');
          if (splitData.some((data) => data.length > ROW_COLUMN_HEADER.MAX_LENGTH)) {
            return `${t('enqueteCreate.headerSettingModal.validateError.maxLength', { max: ROW_COLUMN_HEADER.MAX_LENGTH })}`;
          } else {
            return true;
          }
        }
      },
    },
  };

  return {
    rulesRowColumnHeader,
  };
};
