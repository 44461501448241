// mui コンポーネント
import { Box, Grid } from '@mui/material';
// ag-grid
import {
  CellClassParams,
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  RowStyle,
  TabToNextCellParams,
} from 'ag-grid-community';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import {
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  PersonnelSurveyGridFormData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../../../../hooks/useEnqueteAnswerGrid';
import { useEnqueteAnswerTranslation } from '../../../../hooks/useEnqueteAnswerTranslation';
import {
  DarkRowStyleByPersonnelSurvey,
  NotInputDarkRowStyleByPersonnelSurvey,
  NotInputRowStyleByPersonnelSurvey,
  RowStyleByPersonnelSurvey,
} from '../../../../utils/RowStyleByPersonnelSurvey';
import { GridInputGuideLine } from '../../common/components/GridInputGuideLine';

//-------------------------------------------------------------
// Props
//-------------------------------------------------------------
export interface CreatePriorityDomainGridProps {
  // form定義
  form: PersonnelSurveyGridFormData;
  // 入力データ
  rowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 過去データ
  pastRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 入力データの設定方法
  setRowDataFunction: Function;
  // 【バリデーション用】（参考）データのform定義
  referenceForm?: PersonnelSurveyGridFormData | undefined;
  // 【バリデーション用】（参考）データの入力データ
  referenceRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 【バリデーション用】（参考）データの過去データ
  referencePastRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 【バリデーション用】（参考）データのデータINDEX（どの雇用形態データを利用するか）
  referenceDataIndex?: number | undefined;
}

//-------------------------------------------------------------
// 画面共通：重点分野別人員の入力欄
//-------------------------------------------------------------
export const CreatePriorityDomainGrid: React.FC<CreatePriorityDomainGridProps> = (props) => {
  // テーマ設定
  const { themeMode } = useSettings();
  // テーマの初期値
  const isLight = themeMode === 'light';
  // Grid全体の高さを定義
  const gridHeight = undefined !== props.form.gridHeight ? props.form.gridHeight : '431px';

  // 人件費人員(重点分野)：1.正社員 バリデーション合計エラー状態を取得
  const hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
  );
  // 人件費人員(重点分野)：2.TTCからの出向受入 バリデーション合計エラー状態を取得
  const hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
  );
  // 人件費人員(重点分野)：3.2.以外の出向受入と（参考） バリデーション合計エラー状態を取得
  const hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
  );
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト バリデーション合計エラー状態を取得
  const hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
  );
  // 製造原価人員(重点分野)：1.正社員 バリデーション合計エラー状態を取得
  const hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
  );
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 バリデーション合計エラー状態を取得
  const hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
  );

  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);

  // ローカルの言語
  const { currentLang } = useLocales();
  // 言語情報
  const { t } = useTranslation();
  const useJudg = useJudgmentCurrentLanguage();
  // 回答入力の言語処理
  const useAnswerTranslate = useEnqueteAnswerTranslation();

  const {
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
  } = useEnqueteAnswerGrid();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  // 列の定義
  const [colDef, setColDef] = useState<(ColDef | ColGroupDef)[]>([]);
  // 行データ
  const [rowData, setRowData] = useState<EnqueteAnswerInputEntity[] | undefined>(undefined);
  // （参考）データの入力情報の行データ
  const [referenceRowData, setReferenceRowData] = useState<EnqueteAnswerInputEntity[] | undefined>(undefined);
  // gridのapi
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  // セルの位置
  const [cellPosition, setCellPosition] = useState<CellPosition | undefined>(undefined);
  // 読み込み中フラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // 「重点分野+基盤の人員数の合計」が「人員数」に一致していません
  const [hasErrorPriorityDomainNotMatch, setHasErrorPriorityDomainNotMatch] = useState<boolean>(false);
  // 閉まるフラグ
  const [isDestroyed, setIsDestroyed] = useState(false);

  //-------------------------------------------------------------
  // タブを押した後の選択ボックスの移動
  //-------------------------------------------------------------
  function tabToNextCell(params: TabToNextCellParams): CellPosition {
    // 入力Cell情報取得
    const previousCell = params.previousCellPosition;
    // GridのColDef情報を取得
    const gridColDef = params.api.getColumnDefs();
    // ColDefのColIdを取得
    const gridRowColIds =
      undefined !== gridColDef
        ? gridColDef.map((_: ColDef, i) => {
            return undefined !== _.colId ? _.colId : '';
          })
        : [];
    // 入力CellのColIdを取得
    const colId = previousCell.column.getId();
    // ColIdより現在のIndexを取得
    const colIdIndex = undefined !== gridRowColIds ? gridRowColIds?.indexOf(colId) : 0;

    // 移動先のColIdIndexを取得
    const nextColIdIndex = params.backwards ? colIdIndex - 1 : colIdIndex + 1;

    // 入力CellのColIdIndexを取得
    const lastRowIndex = previousCell.rowIndex;
    // 現在のIndexが存在の場合
    if (undefined !== gridRowColIds && gridRowColIds.length > 0) {
      const colKey = undefined !== gridRowColIds[nextColIdIndex] ? gridRowColIds[nextColIdIndex] : '';
      params.api.setFocusedCell(lastRowIndex, colKey);
    }
    // 移動先のセル
    const nextCell = null !== params.nextCellPosition ? params.nextCellPosition : previousCell;
    // 移動先の位置情報
    const result = {
      rowIndex: lastRowIndex,
      column: nextCell.column,
      rowPinned: nextCell.rowPinned,
    };

    // 位置の設定
    setCellPosition(result);
    return result;
  }
  // グリッド生成完了時の処理
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //-------------------------------------------------------------
  // gridの各種設定情報
  //-------------------------------------------------------------
  const gridOptions: GridOptions = {
    // 行選択:複数行を選択可能
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    // 範囲を選択可能
    enableRangeSelection: true,
    enableFillHandle: true,
    // マウスダウン
    enterMovesDown: true,
    // 編集後マウスダウン
    enterMovesDownAfterEdit: true,
    // 行スタイル
    getRowStyle: isEnqueteAnswerOpen
      ? isLight
        ? RowStyleByPersonnelSurvey
        : DarkRowStyleByPersonnelSurvey
      : isLight
      ? NotInputRowStyleByPersonnelSurvey
      : NotInputDarkRowStyleByPersonnelSurvey,
    // タブを押す字次のセルに移動
    tabToNextCell,
  };

  //-------------------------------------------------------------
  // セル編集後の処理
  //-------------------------------------------------------------
  const onCellValueChanged = (params: CellValueChangedEvent): void => {
    // 編集したらエラー解除
    if (props.form.tabValue.includes('personnel')) {
      switch (props.referenceDataIndex) {
        case 0:
          if (hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal) {
            setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal(false);
          }
          break;
        case 1:
          if (hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal) {
            setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal(false);
          }
          break;
        case 2:
          if (hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal) {
            setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal(false);
          }
          break;
        case 3:
          if (hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal) {
            setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal(false);
          }
          break;

        default:
          break;
      }
    } else {
      switch (props.referenceDataIndex) {
        case 0:
          if (hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal) {
            setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal(false);
          }
          break;
        case 1:
          if (hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal) {
            setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal(false);
          }
          break;
        default:
          break;
      }
    }

    // rawDataを更新するとフォーカスが外れるため、一旦フォーカスを記憶
    const cellPosition = params.api.getFocusedCell();

    // タブ移動を１度リセットする
    setCellPosition(undefined);

    // 画面入力データ
    let displayedData: any = [];
    params.api?.forEachNode((node: any) => {
      // 画面入力データの取得
      const data: any = Object.assign({}, node.data);
      const keepRowData = {
        // ID
        id: data.id,
        // 実見
        resultForecast: data.resultForecast !== undefined ? data.resultForecast : undefined,
        // 年計
        yearPlan: data.yearPlan !== undefined ? data.yearPlan : undefined,
        // 中計
        midTermPlan: data.midTermPlan !== undefined ? data.midTermPlan : undefined,
      };
      displayedData.push(keepRowData);
    });

    // 追加しているTotal行を除外する
    displayedData = displayedData.filter((x: any) => x.id !== 'sumTotal');
    props.setRowDataFunction(displayedData);
    // rawDataが更新された後でフォーカスを元に戻す
    setTimeout(() => {
      params.api.setFocusedCell(cellPosition!.rowIndex, cellPosition!.column);
    }, 100);
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  // 新規作成を考慮してformを監視対象にしてRowを作成する
  useEffect(() => {
    // Row定義を作成する
    refreshColDefData();
    // 参考データの取得
    getRefData();
  }, [isEnqueteAnswerOpen, isLight, currentLang]);

  useEffect(() => {
    // Rowデータを作成する
    refreshRowData();
  }, [props.form, props.rowData, props.pastRowData, referenceRowData]);

  useEffect(() => {
    // エラーチェック
    errorCheck();
  }, [
    hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
    hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
    hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
    hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
    hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
    hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
  ]);

  useEffect(() => {
    // グリッドを再描画する
    destroyGrid();
  }, [isLight, isEnqueteAnswerOpen]); //eslint-disable-line

  //-------------------------------------------------------------
  // テーマ変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  // grid消滅
  const destroyGrid = () => {
    setIsDestroyed(true);
    // タイムアウト
    setTimeout(() => recreateGrid(), 0);
  };
  // gird再生成
  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  //-------------------------------------------------------------
  // スタイル再設定
  //-------------------------------------------------------------
  const colDefReset = (orgColDef: ColDef[]) => {
    // データが存在しない場合のみ、列定義を戻す
    if (!props.form.data) {
      return orgColDef;
    }
    orgColDef.forEach((element) => {
      // コラムが「inputItem」ではない
      if (element.field !== 'inputItem') {
        // コラムが「previousYearPlan」の場合
        if (element.field === 'previousYearPlan') {
          // セルのスタイル
          element.cellStyle = {
            backgroundColor: isLight
              ? props.form.data[1].cellStyle?.backgroundColor
                ? props.form.data[1].cellStyle?.backgroundColor
                : '#fff'
              : '#0b0907',
            textAlign: 'end',
          };

          // コラムが「previousYearPlan」の場合
        } else {
          // セルのスタイル
          element.cellStyle = (params) => ({
            backgroundColor: getColor(params),
            textAlign: 'end',
          });
        }
      }
    });
    return orgColDef;
  };

  //-------------------------------------------------------------
  // スタイル再設定
  //-------------------------------------------------------------
  const rowStyleReset = (params: RowClassParams): RowStyle => {
    let style = undefined;
    if (isEnqueteAnswerOpen) {
      style = isLight ? RowStyleByPersonnelSurvey(params) : DarkRowStyleByPersonnelSurvey(params);
      // 合計行のスタイルを設定する
      if (params.rowIndex === 0) {
        style = { ...style, backgroundColor: isLight ? '#F4F6F8' : '#0b0907', fontWeight: 'bold', textAlign: 'top' };
      }
    } else {
      style = isLight ? NotInputRowStyleByPersonnelSurvey(params) : NotInputDarkRowStyleByPersonnelSurvey(params);
    }
    return style;
  };

  //-------------------------------------------------------------
  // 背景色の取得
  //-------------------------------------------------------------
  const getColor = (params: CellClassParams<any, any>) => {
    // デフォルトは#fff(白)です
    let color: string = '#fff';
    // 最初の行
    if (params.rowIndex === 0) {
      // 1行目の行の背景色(ライトモード)
      const firstRowColorLight = props.form.dataStyle?.backgroundColorLight;
      // 1行目の行の背景色(ダークモード)
      const firstRowColorDark = props.form.dataStyle?.backgroundColorDark;
      // 1行目の行の背景色
      color = isLight ? (firstRowColorLight ? firstRowColorLight : '#fff') : firstRowColorDark ? firstRowColorDark : '#0b0907';
    } else {
      // 2行目以降の行の背景色(ライトモード)
      const otherRowColorLight = props.form.initialValue[params.rowIndex - 1]?.dataStyle?.backgroundColorLight;
      // 2行目以降の行の背景色(ダークモード)
      const otherRowColorDark = props.form.initialValue[params.rowIndex - 1]?.dataStyle?.backgroundColorDark;
      // 2行目以降の行の背景色
      color = isLight ? (otherRowColorLight ? otherRowColorLight : '#fff') : otherRowColorDark ? otherRowColorDark : '#0b0907';
    }
    return color;
  };

  //-------------------------------------------------------------
  // 人件費に紐づく人員数の取得
  //-------------------------------------------------------------
  const getRefData = async () => {
    // FormJsonからグリッドの列項目を取得
    let baseData: EnqueteAnswerInputEntity[] = [];

    // initialValueが存在する場合
    if (undefined !== props.referenceForm?.initialValue) {
      if (props.referenceForm.initialValue.length >= (props.referenceDataIndex ? props.referenceDataIndex : 0)) {
        baseData.push(props.referenceForm.initialValue[props.referenceDataIndex ? props.referenceDataIndex : 0]);
      }
    }

    // AnswerJsonから回答情報を取得する
    let answerRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.referenceRowData) {
      if (props.referenceRowData.length >= (props.referenceDataIndex ? props.referenceDataIndex : 0)) {
        answerRowData.push(props.referenceRowData[props.referenceDataIndex ? props.referenceDataIndex : 0]);
      }
    }

    // FromとAnswerを合体させてRowDataを作成する。
    let tempRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== baseData && undefined !== answerRowData) {
      baseData.forEach((row: any) => {
        // 対応するデータを取得する
        const tempAnswerRowData = answerRowData.filter((x) => x.id === row.id);
        const pushRowData: EnqueteAnswerInputEntity = {
          id: row.id as string,
          inputItem: '',
          resultForecast: tempAnswerRowData[0] && tempAnswerRowData[0].resultForecast !== undefined ? tempAnswerRowData[0].resultForecast : 0,
          yearPlan: tempAnswerRowData[0] && tempAnswerRowData[0].yearPlan !== undefined ? tempAnswerRowData[0].yearPlan : 0,
          midTermPlan: tempAnswerRowData[0] && tempAnswerRowData[0].midTermPlan !== undefined ? tempAnswerRowData[0].midTermPlan : 0,
        };

        tempRowData.push(pushRowData);
      });
    }

    // 過去参照値の取得
    let tempPastRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.referencePastRowData) {
      if (props.referencePastRowData.length >= (props.referenceDataIndex ? props.referenceDataIndex : 0)) {
        tempPastRowData.push(props.referencePastRowData[props.referenceDataIndex ? props.referenceDataIndex : 0]);
      }
    }

    // idと過去参照値の紐付きを作る。
    let pastYearPlans = new Map<string, number>();
    tempPastRowData.forEach((data: EnqueteAnswerLoadInputEntity) => {
      if (undefined !== data.yearPlan) {
        pastYearPlans.set(data.id, data.yearPlan);
      }
    });

    // RowDataの中身をコピーして編集できるようにする。
    let newRowData = _.cloneDeep(tempRowData);
    newRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data) {
        const previousYearPlan = undefined !== pastYearPlans.get(data.id) ? pastYearPlans.get(data.id) : 0;
        data.previousYearPlan = previousYearPlan;
      }
    });

    //過去参照値データがなければ、undefinedに置換
    if (!props.referencePastRowData) {
      newRowData.forEach((data: EnqueteAnswerInputEntity) => {
        if (data !== undefined && data.id !== 'sumTotal') data.previousYearPlan = undefined;
      });
    }
    await setReferenceRowData(newRowData);
  };

  //-------------------------------------------------------------
  // gridの列定義の作成
  //-------------------------------------------------------------
  const refreshColDefData = async () => {
    // ロード中のコンポーネントを表示
    setIsLoading(true);
    // 列定義の作成
    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = await useAnswerTranslate.formatColDef(props.form);
    await setColDef(colDefReset(enqueteAnswerColDef));

    // 0.1秒待つ
    await new Promise((resolve) => setTimeout(resolve, 100));

    // ロード中のコンポーネントを表示されない
    setIsLoading(false);
  };

  //-------------------------------------------------------------
  // エラーチェック
  //-------------------------------------------------------------
  const errorCheck = async () => {
    // エラーチェック：合計行と（参考）データの実見、年計、中計が同じであるか
    if (
      (props.form.tabValue.includes('personnel')
        ? props.referenceDataIndex === 0
          ? hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal
          : props.referenceDataIndex === 1
          ? hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal
          : props.referenceDataIndex === 2
          ? hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal
          : props.referenceDataIndex === 3
          ? hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal
          : undefined
        : props.referenceDataIndex === 0
        ? hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal
        : props.referenceDataIndex === 1
        ? hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal
        : undefined) &&
      props.referenceDataIndex !== undefined
    ) {
      setHasErrorPriorityDomainNotMatch(true);
    } else {
      setHasErrorPriorityDomainNotMatch(false);
    }
  };

  //-------------------------------------------------------------
  // gridの行データの作成
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    if (gridApi && cellPosition) {
      gridApi.setFocusedCell(cellPosition.rowIndex, cellPosition.column.getColId());
    }

    // FormJsonからグリッドの列項目を取得
    let baseData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.form.initialValue) {
      // 行データを追加
      props.form.initialValue.forEach((row: any) => {
        baseData.push(row);
      });
    }

    // AnswerJsonから回答情報を取得する
    let answerRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.rowData) {
      props.rowData.forEach((row: any) => {
        // 回答情報の行データを追加
        answerRowData.push(row);
      });
    }

    // FromとAnswerを合体させてRowDataを作成する
    let tempRowData: EnqueteAnswerInputEntity[] = [];
    // データが存在する場合
    if (undefined !== baseData && undefined !== answerRowData) {
      // 各行の処理を行う
      baseData.forEach((row: any) => {
        const tempAnswerRowData = answerRowData.filter((x) => x.id === row.id);
        const pushRowData: EnqueteAnswerInputEntity = {
          // 行id
          id: row.id as string,
          // 1列目の定義
          inputItem: row.inputItem as string,
          // 実見
          resultForecast:
            tempAnswerRowData[0] && tempAnswerRowData[0].resultForecast !== undefined && tempAnswerRowData[0].resultForecast !== null
              ? tempAnswerRowData[0].resultForecast
              : undefined,
          // 年計
          yearPlan:
            tempAnswerRowData[0] && tempAnswerRowData[0].yearPlan !== undefined && tempAnswerRowData[0].yearPlan !== null
              ? tempAnswerRowData[0].yearPlan
              : undefined,
          // 中計
          midTermPlan:
            tempAnswerRowData[0] && tempAnswerRowData[0].midTermPlan !== undefined && tempAnswerRowData[0].midTermPlan !== null
              ? tempAnswerRowData[0].midTermPlan
              : undefined,
        };
        // 行の多言語対応
        const _row = useAnswerTranslate.formatRowData(pushRowData);

        tempRowData.push(_row as EnqueteAnswerInputEntity);
      });
    }
    // 追加しているTotal行を除外する
    tempRowData = tempRowData.filter((x) => x.id !== 'sumTotal');

    // 過去参照値の取得
    let tempPastRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.pastRowData) {
      props.pastRowData.forEach((row: any) => {
        tempPastRowData.push(row);
      });
    }
    // 追加しているTotal行を除外する
    tempPastRowData = tempPastRowData.filter((x) => x.id !== 'sumTotal');

    // idと過去参照値の紐付きを作る
    let pastYearPlans = new Map<string, number>();
    tempPastRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data.yearPlan) {
        pastYearPlans.set(data.id, data.yearPlan);
      }
    });

    // RowDataの中身をコピーして編集できるようにする。
    let newRowData = _.cloneDeep(tempRowData);
    newRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data) {
        //　過去データが存在しない場合、0で設定
        const previousYearPlan = undefined !== pastYearPlans.get(data.id) ? pastYearPlans.get(data.id) : 0;
        data.previousYearPlan = previousYearPlan;

        // 1列目の文字の処理
        const tempInputItem = data.inputItem as string;
        data.inputItem = tempInputItem.replace('　　', '');
        data.inputItem = '　　' + data.inputItem;
      }
    });

    // 前年度計画の合計
    let previousYearPlanTotal: number = 0;
    // 実見の合計
    let resultForecastTotal: number = 0;
    // 年計の合計
    let yearPlanTotal: number = 0;
    // 中計の合計
    let middlePlanTotal: number = 0;

    // 各行の合計値の計算
    newRowData.forEach((data: any) => {
      // 前年度計画を合計する
      previousYearPlanTotal += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
      // 実見を合計する
      resultForecastTotal += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
      // 年計を合計する
      yearPlanTotal += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      // 中計を合計する
      middlePlanTotal += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
    });

    // 合計行の定義
    const priorityDomainGridTotal: EnqueteAnswerInputEntity = {
      id: 'sumTotal',
      inputItem: useJudg.getText(props.form['gridTotalName'] as languageDataTypes),
      previousYearPlan: previousYearPlanTotal,
      resultForecast: resultForecastTotal,
      yearPlan: yearPlanTotal,
      midTermPlan: middlePlanTotal,
    };

    // 1行目に合計行を追加する
    newRowData.unshift(priorityDomainGridTotal);

    // 過去参照値データがなければ、undefinedに置換
    if (!props.pastRowData) {
      newRowData.forEach((data: EnqueteAnswerInputEntity) => {
        // 行id≠'sumTotal'のみを設定
        if (data !== undefined && data.id !== 'sumTotal') data.previousYearPlan = undefined;
      });
    }
    // 行データ設定
    await setRowData(newRowData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ pb: '2px' }}>
          <h4>
            {/* ラベル */}
            {useJudg.getText(props.form['headline'] as languageDataTypes)}
            {/* 入力時のガイドライン */}
            {props.form.gridInputGuideLine !== undefined && <GridInputGuideLine guideLineData={props.form.gridInputGuideLine} />}
          </h4>
        </Box>

        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: gridHeight,
            width: '80%',
            transition: 'height 0.5s',
          }}>
          {/* 入力グリッド */}
          <LoadableComponent isLoading={isLoading}>
            {!isDestroyed && (
              <StyledAgGrid
                getRowStyle={rowStyleReset}
                coldef={colDef}
                rows={rowData}
                gridOptions={gridOptions}
                onCellValueChanged={onCellValueChanged}
                onGridReady={onGridReady}
              />
            )}
          </LoadableComponent>
        </Box>

        {/* 入力エラー */}
        <p style={{ color: 'red' }}>
          {hasErrorPriorityDomainNotMatch ? (
            <h5>
              {t(
                'enqueteAnswerInput.message.exceptionPersonnelNotMatch_' +
                  ((props.form.tabValue.includes('personnel') ? 0 : 1) + 1) +
                  '_' +
                  ((props.referenceDataIndex ? props.referenceDataIndex : 0) + 1),
              )}
            </h5>
          ) : (
            ''
          )}
        </p>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
};
