// components
import { faBuilding, faCheckCircle, faCircleInfo, faClipboardCheck, faMarker, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { faComments } from '@fortawesome/free-solid-svg-icons';
import { ENQUETE_ANSWER_PATH } from 'src/features/general/enquete-answer/routes/path';
import { ENQUETE_CONFIRM_PATH } from 'src/features/general/enquete-confirm/routes/path';
import { ENQUETE_REQUEST_PATH } from 'src/features/general/enquete-request/routes/path';
import { ORGANIZATION_FUTURE_PATH } from 'src/features/manage/complementary-organization/routes/path';
import { CUSTOMER_MANAGEMENT_PATH } from 'src/features/manage/customer-management/routes/path';
import { NOTIFICATION_PATH } from 'src/features/manage/notification/routes/path';

export const useNavbarRoutes = () => {
  const { t } = useTranslation();
  const style = { fontSize: '20px' };
  // ユーザー情報宣言
  const generalRoutes = {
    REQUEST: {
      title: t('features.general.request.title'),
      path: ENQUETE_REQUEST_PATH.INDEX,
      icon: <FontAwesomeIcon icon={faMarker} style={style} />,
    },
    ANSWER: {
      title: t('features.general.answer.title'),
      path: ENQUETE_ANSWER_PATH.INDEX,
      icon: <FontAwesomeIcon icon={faClipboardCheck} style={style} />,
    },
    CONFIRM: {
      title: t('features.general.confirm.title'),
      path: ENQUETE_CONFIRM_PATH.INDEX,
      icon: <FontAwesomeIcon icon={faCheckCircle} style={style} />,
    },
    THREADS: {
      title: t('features.general.threads.title'),
      path: '/general/enquete-threads',
      icon: <FontAwesomeIcon icon={faComments} style={style} />,
    },
  };

  const manageRoutes = {
    COMPLEMENTARY_ORGANIZATION: {
      title: t('features.manage.complementaryOrganization.title'),
      path: ORGANIZATION_FUTURE_PATH.INDEX,
      icon: <FontAwesomeIcon icon={faSitemap} style={style} />,
    },
    NOTIFICATION: {
      title: t('features.manage.notification.title'),
      path: NOTIFICATION_PATH.INDEX,
      icon: <FontAwesomeIcon icon={faCircleInfo} style={style} />,
    },
    CUSTOMER_MANAGEMENT: {
      title: t('features.manage.customerManagement.title'),
      path: CUSTOMER_MANAGEMENT_PATH.INDEX,
      icon: <FontAwesomeIcon icon={faBuilding} style={style} />,
    },
  };
  return { generalRoutes, manageRoutes };
};
