import { CellValueChangedEvent, ColDef, ColGroupDef, ValueFormatterParams, ValueSetterParams } from 'ag-grid-community';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { ACCOUNTING_CLASS, CONSOLIDATION_CLASS, CustomerPerformance } from 'src/@types/overviewEnquete';
import { setOverviewEnqueteOptionAgGridData, setOverviewEnqueteOptionAgGridRaw } from 'src/features/general/enquete-create/store/enqueteOptionSlice';
import { useDispatch } from 'src/redux/store';

export interface Props {
  overviewEnqueteOptionData: CustomerPerformance[];
  isClosed: boolean;
}

export const useAgGridField = (props: Props) => {
  const { overviewEnqueteOptionData, isClosed } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const saveNewValue = (params: ValueSetterParams<CustomerPerformance>) => {
    const newValue = params.newValue;
    const field = params.column.getColId() as keyof CustomerPerformance;
    // 金額の入力項目はバリデーションを行う
    if ((field === 'salesAmount' || field === 'netProfit') && isInvalidAmount(newValue)) {
      return false;
    }
    const newRow = { ...params.data } as CustomerPerformance;
    // memo: ブラケット記法でneverアクセスエラー出るため、ドットに変更
    switch (field) {
      case 'salesAmount':
        newRow.salesAmount = newValue;
        break;
      case 'netProfit':
        newRow.netProfit = newValue;
        break;
      case 'accountingClass':
        newRow.accountingClass = newValue;
        break;
      case 'consolidationClass':
        newRow.consolidationClass = newValue;
        break;
      default:
        break;
    }
    dispatch(setOverviewEnqueteOptionAgGridRaw(newRow));
    return true;
  };

  const isInvalidAmount = (value: any) => {
    // 全角スペースと半角スペースは許可しない
    if (value.includes(' ') || value.includes('　')) {
      enqueueSnackbar(t('validateError.required', { target: t('common.number') }), { variant: 'error' });
      return true;
    }

    // 何も入力していない状態は許可する
    if (value === '' || isFinite(value)) {
      return false;
    }

    // その他を許可しない
    enqueueSnackbar(t('validateError.required', { target: t('common.number') }), { variant: 'error' });
    return true;
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: false,
    resizable: true,
    editable: true,
    filter: false,
    minWidth: 100,
  };

  const coldef: (ColDef | ColGroupDef)[] = [
    {
      field: 'fiscalYear',
      headerName: t('enqueteCreate.overviewEnqueteOption.gridColDef.fiscalYear'),
      headerTooltip: t('enqueteCreate.overviewEnqueteOption.gridColDef.fiscalYear'),
      editable: false,
      valueFormatter: (params: ValueFormatterParams<string>): string => {
        let fiscalYear = params.value;
        if (typeof fiscalYear === 'string') {
          fiscalYear = new Date(fiscalYear);
          fiscalYear = format(fiscalYear, 'yyyy/MM');
        }
        return fiscalYear;
      },
    },
    {
      field: 'salesAmount',
      headerName: t('enqueteCreate.overviewEnqueteOption.gridColDef.salesAmount'),
      headerTooltip: t('enqueteCreate.overviewEnqueteOption.gridColDef.salesAmount'),
      editable: !isClosed,
      valueSetter: saveNewValue,
      valueFormatter: (params: ValueFormatterParams<string>): string => {
        return params.value ? parseInt(params.value).toLocaleString() : params.value;
      },
    },
    {
      field: 'netProfit',
      headerName: t('enqueteCreate.overviewEnqueteOption.gridColDef.netProfit'),
      headerTooltip: t('enqueteCreate.overviewEnqueteOption.gridColDef.netProfit'),
      editable: !isClosed,
      valueSetter: saveNewValue,
      valueFormatter: (params: ValueFormatterParams<string>): string => {
        return params.value ? parseInt(params.value).toLocaleString() : params.value;
      },
    },
    {
      field: 'accountingClass',
      headerName: t('enqueteCreate.overviewEnqueteOption.gridColDef.accountingClass'),
      headerTooltip: t('enqueteCreate.overviewEnqueteOption.gridColDef.accountingClass'),
      editable: !isClosed,
      valueSetter: saveNewValue,
      valueFormatter: (params: ValueFormatterParams<string>): string => {
        // 見つからないことは想定していないため、0番目にアクセス
        return Object.entries(ACCOUNTING_CLASS)
          .filter(([k, v]) => v === params.value)
          .map(([k, v]) => t('common.accountingClassType.' + k.toLocaleLowerCase()))[0];
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: Object.values(ACCOUNTING_CLASS).map((v) => v),
        formatValue: (accountingClass: string) => {
          // 見つからないことは想定していないため、0番目にアクセス
          return Object.entries(ACCOUNTING_CLASS)
            .filter(([k, v]) => v === accountingClass)
            .map(([k, v]) => t('common.accountingClassType.' + k.toLocaleLowerCase()))[0];
        },
      },
      cellEditorPopup: true,
    },

    {
      field: 'consolidationClass',
      headerName: t('enqueteCreate.overviewEnqueteOption.gridColDef.consolidationClass'),
      headerTooltip: t('enqueteCreate.overviewEnqueteOption.gridColDef.consolidationClass'),
      editable: !isClosed,
      valueSetter: saveNewValue,
      valueFormatter: (params: ValueFormatterParams<string>): string => {
        // 見つからないことは想定していないため、0番目にアクセス
        return Object.entries(CONSOLIDATION_CLASS)
          .filter(([k, v]) => v === params.value)
          .map(([k, v]) => t('common.consolidationClassType.' + k.toLocaleLowerCase()))[0];
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: Object.values(CONSOLIDATION_CLASS).map((v) => v),
        formatValue: (consolidationClass: string) => {
          // 見つからないことは想定していないため、0番目にアクセス
          return Object.entries(CONSOLIDATION_CLASS)
            .filter(([k, v]) => v === consolidationClass)
            .map(([k, v]) => t('common.consolidationClassType.' + k.toLocaleLowerCase()))[0];
        },
      },
      cellEditorPopup: true,
    },
  ];

  const onGridReady = () => {
    dispatch(setOverviewEnqueteOptionAgGridData(overviewEnqueteOptionData));
  };

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    // saveNewValueを発火させるため、フォーカスを外して、再度フォーカスを行う
    const cellPosition = params.api.getFocusedCell();
    setTimeout(() => {
      params.api.setFocusedCell(cellPosition!.rowIndex, cellPosition!.column);
    }, 100);
  };

  return { defaultColDef, coldef, onGridReady, onCellValueChanged };
};
