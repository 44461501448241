import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { useEnqueteConfirmListGrid } from 'src/features/general/enquete-confirm/components/pages/list/hooks/useEnqueteConfirmListGrid';
import { ConfirmRequestStatus } from 'src/features/general/enquete-confirm/store/enqueteConfirmSlice';
import { useAppSelector } from 'src/redux/store';

export default function EnqueteConfirmListToggleButtonGroup() {
  const { t } = useTranslation();
  const hook = useEnqueteConfirmListGrid();
  const { confirmRequestStatus } = useAppSelector((state) => state.enqueteConfirm);
  const toggleButtonStyle = { marginLeft: { xs: '1px', sm: '1em' }, marginRight: { xs: '1px', sm: '1em' }, overflow: 'hiden' };

  const changeToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: ConfirmRequestStatus) => {
    if (newAlignment != null) {
      hook.changeStatus(newAlignment);
    }
  };

  // TODO:style=はmuiの方法に則った指定の方法ではないので、sx=での指定に修正する
  return (
    <ToggleButtonGroup
      value={confirmRequestStatus}
      exclusive
      aria-label="text alignment"
      onChange={changeToggle}
      style={{ marginTop: '5px', marginBottom: '5px', height: '30px', whiteSpace: 'nowrap' }}>
      <ToggleButton color="primary" value={ConfirmRequestStatus.REQUESTED} aria-label="left aligned">
        <Box sx={toggleButtonStyle} style={{ overflow: 'hidden', fontSize: '11px' }}>
          {t('enqueteConfirm.confirmRequestStatus.REQUESTED')}
        </Box>
      </ToggleButton>
      <ToggleButton value={ConfirmRequestStatus.CONFIRMED} aria-label="left aligned">
        <Box sx={toggleButtonStyle} style={{ overflow: 'hidden', fontSize: '11px' }}>
          {t('enqueteConfirm.confirmRequestStatus.CONFIRMED')}
        </Box>
      </ToggleButton>
      <ToggleButton value={ConfirmRequestStatus.ALL} aria-label="left aligned">
        <Box sx={toggleButtonStyle} style={{ overflow: 'hidden', fontSize: '11px' }}>
          {t('enqueteConfirm.confirmRequestStatus.ALL')}
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
