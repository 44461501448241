import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowNode } from 'ag-grid-community';
import { SurveyEditEntity } from 'src/api/useSurveyEditApi';

export interface SelectedRespondentListEntity {
  [rowId: string]: SurveyEditEntity;
}

export const respondentSettingSlice = createSlice({
  name: 'respondentSetting',
  initialState: {
    // 選択されている依頼先
    tempSelectedRespondentList: {} as SelectedRespondentListEntity,
    // 選択されている依頼先の件数
    tempSelectedRespondentCount: 0,
  },
  reducers: {
    initializeRespondentSetting: (state) => {
      state.tempSelectedRespondentList = {};
      state.tempSelectedRespondentCount = 0;
    },
    setTempSelectedRespondentList: (state, action: PayloadAction<RowNode[]>) => {
      const list: SelectedRespondentListEntity = {};
      action.payload.forEach((node: RowNode, i: number) => {
        if (node.isSelected()) list[node.rowIndex as number] = node.data;
      });
      state.tempSelectedRespondentList = list;
      state.tempSelectedRespondentCount = Object.keys(list).length;
    },
    setSelectedRespondentListEntity: (state, action: PayloadAction<SelectedRespondentListEntity>) => {
      state.tempSelectedRespondentList = action.payload;
      state.tempSelectedRespondentCount = Object.keys(action.payload).length;
    },
  },
});

export const { initializeRespondentSetting, setTempSelectedRespondentList, setSelectedRespondentListEntity } = respondentSettingSlice.actions;
