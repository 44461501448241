import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotificationData, NotificationRequestData, useNotificationApi } from 'src/api/useNotificationApi';

export const useNotificationReRegister = (id?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState<NotificationData>();
  const { getByIdForAdmin } = useNotificationApi();
  const { create } = useNotificationApi();

  const reRegisterNotification = useCallback(
    async (data: NotificationRequestData) => {
      if (!data?.titleEng?.trim() || /^[\s\u3000]+&/.test(data.titleEng)) {
        data.titleEng = data.titleJpn;
      }
      if (!data?.contentEng?.trim() || /^[\s\u3000]+&/.test(data.contentEng)) {
        data.contentEng = data.contentJpn;
      }

      setIsLoading(true);

      try {
        await create(data);
        navigate('/manage/notification/edit');
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status !== 403) {
            enqueueSnackbar(t('notifications.messages.failedToReRegister'), { variant: 'error' });
          }
        }
        setHasError(true);
        setIsLoading(false);
      }
    },
    [create, enqueueSnackbar, navigate, t],
  );

  const fetchNotification = useCallback(async () => {
    if (!id || data) return;

    setIsLoading(true);

    try {
      const response = await getByIdForAdmin(id);
      if (!response) throw Error;
      setData(response);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status !== 403) {
          enqueueSnackbar(t('notifications.messages.failedToRetrieve'), { variant: 'error' });
        }
      }
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [data, enqueueSnackbar, getByIdForAdmin, id, t]);

  useEffect(() => {
    fetchNotification();
  }, [fetchNotification]);

  return { isLoading, hasError, notification: data, reRegisterNotification };
};
