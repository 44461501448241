import { Grid, Select } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GENERAL_ENQUETE_JSON_INPUT_TYPE, Question } from 'src/@types/generalEnquete';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import QuestionItemForm from './QuestionItemForm';
import RequiredValidationForm from './RequiredValidationForm';

type QuestionDropdownFormProps = {
  methods: UseFormReturn;
  existingQuestion?: Question;
};

const QuestionDropdownForm = ({ methods, existingQuestion }: QuestionDropdownFormProps) => {
  const { t } = useTranslation();
  const { rulesCreateQuestionTitle } = useValidator(methods);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <RHFTextField
            margin="normal"
            name="textQuestion"
            control={methods.control}
            fullWidth
            rows={2}
            multiline
            placeholder={t('enqueteCreate.inputQuestion')}
            rules={rulesCreateQuestionTitle}
            defaultValue={existingQuestion ? existingQuestion.title : ''}
          />
        </Grid>
      </Grid>
      <QuestionItemForm methods={methods} existingQuestion={existingQuestion} inputType={GENERAL_ENQUETE_JSON_INPUT_TYPE.SELECT}>
        <Select disabled={true} sx={{ marginRight: 1 }} defaultValue="" />
      </QuestionItemForm>
      <RequiredValidationForm methods={methods} existingQuestion={existingQuestion} />
    </>
  );
};

export default QuestionDropdownForm;
