import { ColDef, EditableCallbackParams, ValueSetterParams } from 'ag-grid-community';
import _ from 'lodash';
// 入力上限値設定
const maxLimitValue: number = 999999;

// 列情報の作成（主にfunctionオプションの付与）
export const EnqueteAnswerCreateColumn = (column: any, isEnqueteAnswerOpen?: boolean) => {
  let tempColDef = _.cloneDeep(column);
  if (undefined !== tempColDef.function) {
    // function情報を解析して付与する
    for (let key in tempColDef.function) {
      switch (key) {
        case 'editableCallbackByInput':
          if (isEnqueteAnswerOpen === undefined) {
            tempColDef['editable'] = editableCallbackByInput;
            break;
          } else {
            tempColDef['editable'] = isEnqueteAnswerOpen ? editableCallbackByInput : false;
            break;
          }
        case 'valueFormatterByInput':
          tempColDef['valueFormatter'] = valueFormatterByInput;
          break;
        case 'numberValue':
          tempColDef['valueSetter'] = valueSetterByInput;
          break;
        case 'tooltipSpaceTrim':
          tempColDef['tooltipValueGetter'] = tooltipSpaceTrim;
          break;
        default:
          break;
      }
    }
    delete tempColDef.function;
  }
  const colDef: ColDef = tempColDef;
  return colDef;
};

// 編集可否の制御
const editableCallbackByInput = (params: EditableCallbackParams): boolean => {
  if (params.data.id === 'sumTotal') {
    return false;
  } else {
    return true;
  }
};

// 3桁区切り制御
const valueFormatterByInput = (params: any) => {
  if (isNaN(Number(params.value))) {
    return '';
  } else {
    return Math.trunc(Number(params.value)).toLocaleString();
  }
};

// グリッド入力値を制御
const valueSetterByInput = (params: ValueSetterParams) => {
  const field: string | undefined = params.colDef.field;
  const numberOnlyValue: string = valueSelectionNumber(params.newValue);
  const halfSizeNewValue: string = valueAllHalfSize(numberOnlyValue);
  // フィールドが存在し、整数化できること
  if (field) {
    // 削除時は要素が消えること
    if (params.newValue === '') {
      params.data[field] = undefined;
      return true;
    }
    if (isZeroOrPositiveInteger(halfSizeNewValue)) {
      const integerFloatNewValue = Number.parseFloat(halfSizeNewValue);
      const integerNewValue = Math.round(integerFloatNewValue);
      // 上限値を超えているか
      if (isLimtNumberValue(integerNewValue)) {
        params.data[field] = integerNewValue;
        return true;
      } else {
        params.data[field] = maxLimitValue;
        return true;
      }
    }
  }

  return false;
};

// 数字部分の抽出(全角・半角)
const valueSelectionNumber = (newValue: any): string => {
  const stringNewValue: string = newValue.toString();
  const numberOnlyValue = stringNewValue.replace(/[^0-9.．０-９]/g, '');
  return numberOnlyValue;
};
// 全角→半角化
const valueAllHalfSize = (newValue: string): string => {
  return newValue.replace(/[．０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};
// 数値化できるか判定
const isZeroOrPositiveInteger = (newValue: string | number): boolean => {
  // 文字の場合は空になる
  if (newValue === '') {
    return false;
  }
  const floatValue = typeof newValue === 'string' ? Number.parseFloat(newValue) : newValue;
  const value = Math.round(floatValue);
  const isInteger: boolean = Number.isInteger(value);
  if (isInteger && value >= 0) {
    return true;
  } else {
    return false;
  }
};
// 上限999999以下
const isLimtNumberValue = (newValue: number): boolean => {
  if (newValue < maxLimitValue) {
    return true;
  } else {
    return false;
  }
};

const tooltipSpaceTrim = (params: any) => {
  if (undefined !== params.data && undefined !== params.data.inputItem) {
    const newInputItem: string = params.data.inputItem.replace(/^\s+/g, '');
    return newInputItem;
  }
  return '';
};
