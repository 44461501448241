import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setIsWarningDescription } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

export const WarningDescription = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isWarningDescription } = useAppSelector((state) => state.seriesAnswer);

  return (
    <Accordion expanded={isWarningDescription} onChange={() => dispatch(setIsWarningDescription(!isWarningDescription))}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography sx={{ color: '#F2E700', fontWeight: 600 }}>
          <FontAwesomeIcon icon={faWarning} style={{ fontSize: '20px', marginRight: 2 }} />
          {t('common.title.warning')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{t('enqueteAnswerInput.modal.selectCustomer.message.warningDescription')}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
