import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  NativeSelect,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { ColDef, ColGroupDef, ColumnGroupOpenedEvent, GetContextMenuItems, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { SnackbarKey } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { AccessRoleTypeEnum } from 'src/@types/accessRoleType';
import { AccessRoleType } from 'src/api/useAccessRoleTypeApi';
import { VisibleType } from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import { RootState, useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';
import CloseButton from '../CloseButton';
import { StyledAgGrid } from '../StyledAgGrid';

export interface SearchConditionProps {
  type: 'autocomplete' | 'text';
  id: string;
  label: string;
  style: {
    xs: number;
  };
  disabled: boolean;
  props: SearchConditionAutoCompleteProps | SearchConditionTextFieldProps;
}

export interface SearchConditionAutoCompleteProps {
  value: string | null; // 現在選択されている値
  list: {
    key: string;
    label: string;
  }[]; // ドロップダウンリストに表示する候補情報
  onChange: (value: string | undefined) => void; // ドロップダウンリスト変更時のコールバック
}

export interface SearchConditionTextFieldProps {
  value: string; // 現在入力されている値
  onBlur: (value: string) => void;
}

export interface SearchEditorProps {
  // 条件としてのラベル
  label: string;
  // 現在選択されている値
  value: string | null;
  // ドロップダウンリストに表示する候補情報
  list: string[];
  // ドロップダウンリスト変更時のコールバック
  onChange: (value: string | null) => void;
  // 値入力時のコールバック
  onInputChange: (value: string | null, reason: AutocompleteInputChangeReason) => void;
  // 非活性
  disabled?: boolean;
}

export interface AccessRoleFieldProps {
  label: string; // ラベル
  value: string; // 現在入力されている値
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void; // 値入力コールバック
}

/**
 * 本部極担当のラジオボタン情報
 */
export interface VisibleConditionProps {
  select: { label: string; value: VisibleType }; // 本部極担当のラジオボタン情報
  all: { label: string; value: VisibleType }; // 全回答のラジオボタン情報
  value: VisibleType; // 選択情報
  onChange: (v: VisibleType) => void; // 値選択コールバック
}

export interface ModalGridProps<T> {
  // カラム定義
  colDefs?: (ColDef | ColGroupDef)[] | null | undefined;
  // 行データ群
  rowData?: T[] | null;

  // グリッドの高さ
  height?: string;

  // 行のクリック時の動作
  onRowClicked?: (e: RowClickedEvent) => void;
  // ヘッダーグループの開閉時の動作
  onColumnGroupOpened?: (e: ColumnGroupOpenedEvent) => void;
  // グリッドの準備完了した時の動作
  onGridReady?: (params: GridReadyEvent) => void;
  // マルチ選択
  rowSelection?: 'multiple' | 'single' | undefined;
  // コンテキストメニュー
  contextMenu?: GetContextMenuItems | undefined;

  // 上書きコンポーネント
  component?: React.ReactNode;
}

interface Props<T> {
  isLoading: boolean;
  isOpen: boolean;
  isEdit: boolean;
  isTargetSelect: boolean;
  title: string;
  onClose: () => void;
  button: {
    name: string;
    onClick: () => Promise<void>;
    disabled?: boolean;
  };
  middleButton: {
    name: string;
    onClick: () => Promise<SnackbarKey | undefined>;
    disabled?: boolean;
  };
  // memo: 検索条件を格納、二次元配列として定義し、画面描画時に横並びに配置される
  conditionsForOrganizaion: SearchConditionProps[][];
  conditionsForPerson?: SearchEditorProps[];
  accessRoleConditions: AccessRoleFieldProps;
  visibleCondition: VisibleConditionProps;
  grid: ModalGridProps<T>;
  defaultColDef?: ColDef;
  modalStyleSxProps?: SxProps;
}

export const SelectEditorModal = <T,>(props: Props<T>) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { t } = useTranslation();
  const accessRoleTypeList: AccessRoleType[] = useAppSelector((state: RootState) => state.accessRoleType.accessRoleTypeList);

  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80vw',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const gridStyle: SxProps = {
    height: '50vh',
    width: '100%',
    transition: 'height 0.5s',
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    cellStyle: { border: 'none', backgroundColor: 'inherit' },
  };

  const renderAutoComplete = (condition: SearchConditionProps) => {
    const props = condition.props as SearchConditionAutoCompleteProps;
    return (
      <Autocomplete
        id={condition.id}
        size="small"
        options={props.list}
        // memo: [Encountered two children with the same key] の発生を抑制するため、renderOptionを定義
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {option.label}
          </li>
        )}
        value={props.list.find((e) => e.key === props.value) || { key: '', label: '' }}
        // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
        isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
        onChange={async (_, v) => {
          await props.onChange(v?.key);
        }}
        renderInput={(params) => <TextField {...params} label={condition.label} />}
        disabled={condition.disabled}
      />
    );
  };

  const renderTextfield = (condition: SearchConditionProps) => {
    const props = condition.props as SearchConditionTextFieldProps;
    return (
      <TextField
        fullWidth
        size="small"
        label={condition.label}
        onBlur={(event) => props.onBlur(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            // memo: Enter Key 押下で blur させ、 onBlur を発火させることで、 Enter Key による検索等の処理を実行可能とする
            (event.target as HTMLInputElement).blur();
          }
        }}
        disabled={condition.disabled}
      />
    );
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <>
        {/* モーダル表示時に時間がかかる場合、isLoading が true の間は modal 操作不可となるように circularProgress を描画 */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box component={'div'} sx={{ ...modalStyle, ...props.modalStyleSxProps }}>
          {/* modal title */}
          <Typography variant="h4" component={'h2'}>
            {props.title}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue={props.visibleCondition.value}
              onChange={(_, v) => props.visibleCondition.onChange(v as VisibleType)}>
              <FormControlLabel
                value={props.visibleCondition.select.value}
                control={<Radio color="primary" />}
                label={props.visibleCondition.select.label}
              />
              <FormControlLabel
                value={props.visibleCondition.all.value}
                control={<Radio color="primary" />}
                label={props.visibleCondition.all.label}
              />
            </RadioGroup>
          </FormControl>
          {/* conditions */}
          {props.isTargetSelect && (
            <Grid container component={'div'} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                {props.conditionsForOrganizaion.map((conditions, pIndex) => (
                  <Grid container key={pIndex} sx={{ mt: 1, mr: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {conditions.map((condition, cIindex) => (
                      <Grid key={cIindex} item xs={condition.style.xs}>
                        {condition.type === 'autocomplete' && renderAutoComplete(condition)}
                        {condition.type === 'text' && renderTextfield(condition)}
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {/** User Search */}
          <Grid container component={'div'} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              {props.conditionsForPerson &&
                props.conditionsForPerson.map((condition, index) => (
                  <Grid container key={index} sx={{ mt: 1, mr: 1 }}>
                    <Grid item xs={12}>
                      <Autocomplete
                        size="small"
                        options={condition.list}
                        value={condition.value}
                        filterOptions={(option) => option}
                        // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
                        isOptionEqualToValue={(_option, _value) => {
                          return true;
                        }}
                        onChange={async (e, v) => {
                          await condition.onChange(v);
                        }}
                        onInputChange={async (e, v, r) => {
                          await condition.onInputChange(v, r);
                        }}
                        renderInput={(params) => <TextField {...params} label={condition.label} />}
                        disabled={condition.disabled}
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid container justifyContent="space-between" alignItems="end" sx={{ mt: 2 }}>
              <Grid item>{props.isEdit && <CloseButton onClose={props.onClose} color="primary" />}</Grid>
              <Grid item>
                <Grid container spacing={2} alignItems="end">
                  <Grid item>
                    <FormControl fullWidth size="small">
                      <InputLabel>{t('enqueteCreate.divisionRegionEditor.selectEditorModal.accessRoleLabel')}</InputLabel>
                      <NativeSelect
                        inputProps={{ name: t('enqueteCreate.divisionRegionEditor.selectEditorModal.accessRoleLabel') }}
                        onChange={props.accessRoleConditions.onChange}
                        defaultValue={props.accessRoleConditions.value}>
                        <option aria-label="None" value="" />
                        {accessRoleTypeList.map((role) => (
                          <option value={role.id} key={role.id}>
                            {t(Object.values(AccessRoleTypeEnum).find((val) => val.roleName === role.roleName)?.viewName ?? '')}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        await props.middleButton.onClick();
                      }}
                      disabled={props.middleButton.disabled !== undefined && props.middleButton.disabled}>
                      {props.middleButton.name}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* modal grid table */}
          {!props.isEdit && (
            <>
              <Grid container sx={{ pt: 1.5 }}>
                <Grid item xs={12}>
                  <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={gridStyle}>
                    <StyledAgGrid
                      defaultColDef={props.defaultColDef ? props.defaultColDef : defaultColDef}
                      coldef={props.grid.colDefs}
                      rows={props.grid.rowData}
                      onRowClickedCallback={props.grid.onRowClicked}
                      rowSelection={props.grid.rowSelection}
                      contextMenu={props.grid.contextMenu}
                      onGridReady={props.grid.onGridReady}
                      onColumnGroupOpened={props.grid.onColumnGroupOpened}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* modal bottom actions */}
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={6} justifyContent={'left'}>
                  <Stack direction={'row'} spacing={1}>
                    <CloseButton onClose={props.onClose} color="primary" />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={'row-reverse'}>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        await props.button.onClick();
                      }}
                      disabled={props.button.disabled}>
                      {props.button.name}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </>
    </Modal>
  );
};
