import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { ANSWER_UNIT, AnswerUnit, FORM_TYPE, FormType, SURVEY_TYPE, SurveyType } from 'src/constants';
import { useBearerToken } from './useBearerToken';

export interface SurveyHeaderEntity {
  id: string;
  labelJpn: string;
  labelEng: string;
  description?: string;
  descriptionEng?: string;
  tags: string[];
  type: SurveyType;
  answerUnit: AnswerUnit;
  formType: FormType;
  ownerOrganizationId: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  isDeleted?: boolean;
}

export const initSurveyHeader: SurveyHeaderEntity = {
  id: '',
  labelJpn: '',
  labelEng: '',
  description: '',
  descriptionEng: '',
  tags: [''],
  type: SURVEY_TYPE.GENERAL,
  answerUnit: ANSWER_UNIT.ORGANIZATION,
  formType: FORM_TYPE.QA,
  ownerOrganizationId: '',
};

export const initOverviewSurveyHeader: SurveyHeaderEntity = {
  id: '',
  labelJpn: '',
  labelEng: '',
  description: '',
  descriptionEng: '',
  tags: [''],
  type: SURVEY_TYPE.SPECIAL,
  answerUnit: ANSWER_UNIT.ORGANIZATION,
  formType: FORM_TYPE.OVERVIEW,
  ownerOrganizationId: '',
};

/**
 * SurveyHeader API
 *
 * @returns
 */
export const useSurveyHeaderApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 全情報取得
   *
   * @returns
   */
  const getAllData = async (): Promise<SurveyHeaderEntity[]> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<SurveyHeaderEntity[]>(apiGateway + '/survey-header', {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
        params: {
          filter: {},
        },
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyHeaderApi.failedGetSurveyHeaderList'), { variant: 'error' });
      } else {
        throw error;
      }

      return [];
    }
  };

  /**
   * IDを条件にデータ取得
   *
   * @returns
   */
  const getSurveyHeaderListById = async (id: string): Promise<SurveyHeaderEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<SurveyHeaderEntity[] | undefined>(apiGateway + '/survey-header', {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
        params: {
          filter: {
            where: {
              id: id,
            },
          },
        },
      });

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyHeaderApi.failedGetSurveyHeaderDataById'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * typeがGENERALでownerOrganizationIdを条件にデータ取得
   * @param ownerOrganizationId
   * @param surveyType
   * @param formType
   * @returns @returns SurveyHeaderEntity[]
   */
  const getSurveyHeaderListByOwnerOrganizationId = async (
    ownerOrganizationId: string,
    surveyType: SurveyType,
    formType: FormType | undefined, // 汎用の検索の場合undefined指定
  ): Promise<SurveyHeaderEntity[]> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<SurveyHeaderEntity[]>(apiGateway + '/survey-header', {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
        params: {
          filter: {
            where: {
              type: { equals: surveyType },
              formType: { equals: formType },
              ownerOrganizationId: { equals: ownerOrganizationId },
              isDeleted: { equals: false },
            },
          },
        },
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyHeaderApi.failedGetSurveyHeaderListByOwnerOrganizationId'), { variant: 'error' });
      } else {
        throw error;
      }

      return [];
    }
  };

  return {
    getAllData,
    getSurveyHeaderListById,
    getSurveyHeaderListByOwnerOrganizationId,
  };
};
