import { Box, Button, Grid, Modal, Stack, SxProps, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';
import useConfirmationCommentsModal from '../hooks/useConfirmationCommentsModal';
import { ConfirmRemoveCommentsModal } from './ConfirmRemoveCommentsModal';

interface Props {
  // モーダル自体のタイトル
  title: string;
  // モーダルの表示/非表示フラグ
  open: boolean;
  // OKボタンが押された時のコールバック
  onOk?: () => void;
  // モーダルの枠外が押された時のコールバック
  // または、Closeボタンがある場合はCloseボタンが押された時のコールバック
  onClose: (isOpen: boolean) => {
    payload: boolean;
    type: string;
  };

  // ローディングの状態
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

  // 編集状態
  edited: boolean;
  setEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfirmationCommentsModal: React.FC<Props> = (props) => {
  const { edited: isEdited, setEdited: setIsEdited, onClose, setIsLoading } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);

  const { updateConfirmationComments } = useConfirmationCommentsModal({ onClose, setIsLoading });

  const [confirmationComment, setConfirmationComment] = useState(confirmRequestData.confirmComment);
  const [isOpenConfirmRemoveCommentsModal, setIsOpenConfirmRemoveCommentsModal] = useState(false);

  const handleOpen = () => setIsOpenConfirmRemoveCommentsModal(true);

  const editedCommentStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationComment(event.target.value);
    setIsEdited(confirmRequestData.confirmComment !== confirmationComment);
  };

  const closeConfirmRemoveCommentsModal = () => {
    setIsEdited(false);
    onClose(false);
  };

  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 900,
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const modalOutClose = () => (isEdited ? handleOpen() : onClose(false));
  return (
    <>
      <Modal open={props.open} onClose={modalOutClose}>
        <Box component={'div'} sx={modalStyle}>
          {/* modal title */}
          <Typography variant="h4" component={'h2'}>
            {props.title}
          </Typography>

          {/* conditions */}
          <Grid container component={'div'} sx={{ mt: 1 }} paddingTop={2}>
            <Grid item xs={12} md={12} />
            <Grid item xs={12} md={12} />
            <Grid item xs={12}>
              <TextField
                label={t('enqueteAnswerInput.confirmationCommentsModal.textfield.confirmComment.label')}
                multiline
                rows={6}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 1000 }}
                placeholder={t('enqueteAnswerInput.confirmationCommentsModal.textfield.confirmComment.placeholder')}
                fullWidth={true}
                size="small"
                onChange={editedCommentStatus}
                defaultValue={confirmRequestData.confirmComment}
              />
            </Grid>
          </Grid>

          {/* modal bottom actions */}
          <Grid item xs={6}>
            <Stack direction={'row-reverse'} spacing={2} paddingTop={2}>
              <Button variant="contained" onClick={() => updateConfirmationComments(confirmationComment)}>
                {confirmRequestData.status === 'CONFIRMED'
                  ? t('enqueteAnswerInput.confirmationCommentsModal.withdrawalAction.changeComment')
                  : t('enqueteAnswerInput.confirmationCommentsModal.withdrawalAction.markAsConfirm')}
              </Button>
              <ConfirmRemoveCommentsModal
                title={t('enqueteAnswerInput.confirmationCommentsModal.withdrawalAction.title')}
                open={isOpenConfirmRemoveCommentsModal}
                setOpen={setIsOpenConfirmRemoveCommentsModal}
                onOk={closeConfirmRemoveCommentsModal}
                handleOpen={handleOpen}
                edited={props.edited}
              />
            </Stack>
          </Grid>
        </Box>
      </Modal>
      {/** ローディング */}
      <StyledLoading isOpen={props.isLoading} />
    </>
  );
};
