import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { useBonsaiAddedCustomerListGrid } from 'src/features/manage/customer-management/hooks/useBonsaiAddedCustomerListGrid';
import useTheme from 'src/hooks/useTheme';
import { RootState, useAppSelector } from 'src/redux/store';
import { useBonsaiAddedCustomerListColDef } from '../../../../hooks/useBonsaiAddedCustomerListColDef';

const BonsaiAddedCustomerListGrid: React.FC = React.memo(() => {
  const { className } = useTheme();
  const { contextMenu, onRowDoubleClickedCallback } = useBonsaiAddedCustomerListGrid();
  const { defaultColDef, colDef } = useBonsaiAddedCustomerListColDef();
  const { bonsaiAddedCustomers, isCustomerLoading } = useAppSelector((state: RootState) => state.customerManagement);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box
          className={className}
          sx={{
            height: '95vh',
            width: '100%',
          }}>
          <LoadableComponent isLoading={isCustomerLoading}>
            <StyledAgGrid
              defaultColDef={defaultColDef}
              coldef={colDef}
              rows={bonsaiAddedCustomers}
              contextMenu={contextMenu}
              onRowDoubleClickedCallback={onRowDoubleClickedCallback}
              storageKey={'CustomerListGrid'}
            />
          </LoadableComponent>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
});

export default BonsaiAddedCustomerListGrid;
