import HotTable from '@handsontable/react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Grid, Tab } from '@mui/material';
import { registerAllModules } from 'handsontable/registry';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NestedHeadersSettingModal from 'src/features/general/enquete-create/components/modals/nested-header-setting/components/NestedHeadersSettingModal';
import { HeaderSettingModal } from 'src/features/general/enquete-create/components/modals/header-setting/modals/HeaderSettingModal';
import { EnqueteDescriptionForm } from 'src/features/general/enquete-create/components/tab-containers/common/components/enquete-description-form/components/EnqueteDescriptionForm';
import EnqueteCreateNewTableForm from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/components/EnqueteCreateNewTableForm';
import EnqueteCreatePreviewTableForm from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/components/EnqueteCreatePreviewTableForm';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { useTableData } from '../hooks/useTableData';
import { useTableSave } from '../hooks/useTableSave';

registerAllModules();

export const GeneralTableTab: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const [tabNumber, setTabNumber] = useState('1');

  const hotRef = useMemo(() => {
    return new Array(TABLE_SETTINGS.MAX_TOTAL_TABLE_LENGTH).fill(null).map(() => React.createRef<HotTable>());
  }, []);
  const hotRefForSubSheet = useMemo(() => {
    return new Array(TABLE_SETTINGS.SUB.MAX_LENGTH).fill(null).map(() => React.createRef<HotTable>());
  }, []);

  // ストアに初期定義
  useTableData();
  // ストア保存
  const { saveAllSettings } = useTableSave(hotRef, hotRefForSubSheet);

  // タブ選択
  const handleTabClick = async (_event: React.SyntheticEvent, newTabNumber: string) => {
    if (newTabNumber === '2') {
      await saveAllSettings('nodb');
    }
    setTabNumber(newTabNumber);
  };

  return (
    <>
      {/* memo; NestedHeadersSettingModal は、レンダリングを維持し続ける設計としている関係で、 */}
      {/*       親コンポーネントで定義しておかないと表示不具合が発生します */}
      {/** 列ヘッダ定義ダイアログ **/}
      <NestedHeadersSettingModal />
      {/** 行ヘッダ定義ダイアログ **/}
      <HeaderSettingModal />

      <Box component={'div'} sx={{ mb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <EnqueteDescriptionForm />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabClick} aria-label="table-tab">
                  <Tab label={t('enqueteCreate.table.tab.create')} value="1" />
                  <Tab label={t('enqueteCreate.table.tab.preview')} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <EnqueteCreateNewTableForm hotRef={hotRef} hotRefForSubSheet={hotRefForSubSheet} />
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <EnqueteCreatePreviewTableForm />
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
