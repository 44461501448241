import { HotTable } from '@handsontable/react';
import { Grid, Stack, Typography } from '@mui/material';
import { HyperFormula } from 'hyperformula';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import EnqueteSubTableForm from 'src/components/app-components/bonsai/type-general-table/components/EnqueteSubTableForm';
import Label from 'src/components/Label';
import { hyperformulaLicenseKey } from 'src/config';
import { PreviousAnswersList } from 'src/features/general/enquete-answer/components/tab-containers/common/components/previous-answers-list/components/PreviousAnswersList';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { EnqueteAnswerDescriptionBox } from 'src/features/general/enquete-answer/components/tab-containers/type-general-question/components/tabs/question-and-answer/components/common/EnqueteAnswerDescriptionBox';
import { AnswerMainTablesTabContainer } from './AnswerMainTablesTabContainer';
import { HelpTooltip } from 'src/components/app-components/bonsai/HelpTooltip';
import { useSurveyTables } from 'src/hooks/useSurveyTables';

const hyperformulaInstance = HyperFormula.buildEmpty({
  licenseKey: hyperformulaLicenseKey,
});
// define TRUE and FALSE constants
hyperformulaInstance.addNamedExpression('TRUE', '=TRUE()');
hyperformulaInstance.addNamedExpression('FALSE', '=FALSE()');

interface EnqueteAnswerGeneralTableFormProps {
  answerHotRef: RefObject<HotTable>[];
  answerHotRefForSubSheet: RefObject<HotTable>[];
}

const EnqueteAnswerGeneralTableFormTab: React.FC<EnqueteAnswerGeneralTableFormProps> = (props) => {
  const { answerHotRef, answerHotRefForSubSheet } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const { isMultiAnswerTableSurvey } = useSurveyTables();

  const questionDescriptionJpn = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);
  const subDatas = useAppSelector((state: RootState) => state.tableSetting.subDatas);

  const questionDescription = isJapanese ? questionDescriptionJpn : questionDescriptionEng;

  return (
    <Stack component="form" name="inputForm">
      <Grid container>
        <Grid item xs={12} md={12}>
          {questionDescription && questionDescription !== '' ? <EnqueteAnswerDescriptionBox questionDescription={questionDescription} /> : null}
        </Grid>
        <PreviousAnswersList />
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 1 }}>
          <Typography variant="h5">{t('enqueteAnswerInput.table.answer.title')}</Typography>
          <Label
            role="message"
            variant="ghost"
            sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
            {t('enqueteAnswerInput.table.answer.explanation')}
            {/*複数回答表のみ表示*/}
            {isMultiAnswerTableSurvey && (
              <>
                {t('enqueteGeneralCommon.explanation.referenceAnswerTable')}
                <HelpTooltip contents={t('enqueteGeneralCommon.tooltip.referenceAnswerTableValue')} />
              </>
            )}
          </Label>
        </Grid>
        <Grid item xs={12}>
          <AnswerMainTablesTabContainer
            answerHotRef={answerHotRef}
            answerHotRefForSubSheet={answerHotRefForSubSheet}
            hyperformulaInstance={hyperformulaInstance}
          />
        </Grid>
        {subDatas.length > 0 ? (
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', mb: 1 }}>
            <Typography variant="h5">{t('enqueteAnswerInput.table.sub.title')}</Typography>
            <Label
              role="message"
              variant="ghost"
              sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', p: 1, mb: 2 }}>
              {t('enqueteAnswerInput.table.sub.explanation')}
              <HelpTooltip contents={t('enqueteGeneralCommon.tooltip.referenceRefTableValue')} />
            </Label>
          </Grid>
        ) : null}
        <EnqueteSubTableForm
          hotRef={answerHotRef}
          hotRefForSubSheet={props.answerHotRefForSubSheet}
          hyperformulaInstance={hyperformulaInstance}
          isPreview={true}
          isVisibleContextMenu={false}
        />
      </Grid>
    </Stack>
  );
};

export default EnqueteAnswerGeneralTableFormTab;
