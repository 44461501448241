import { RespondentRequestEntity, initRespondentRequestData } from 'src/api/useRespondentRequestApi';
import { SurveyHeaderEntity, initSurveyHeader } from 'src/api/useSurveyHeaderApi';
import { FormType, SurveyType } from 'src/constants';

/**
 * アンケート種別を変更する。surveyHeader用
 * @param surveyHeader default: initSurveyHeader
 * @param surveyType アンケート種別
 * @param formType フォーム種別
 */
export function changeFormTypeForSurveyHeader(surveyHeader: SurveyHeaderEntity = initSurveyHeader, surveyType: SurveyType, formType: FormType) {
  return { ...surveyHeader, type: surveyType, formType };
}

/**
 * アンケート種別を変更する。respondentRequest用
 * @param rr default: initRespondentRequestData
 * @param surveyType アンケート種別
 * @param formType フォーム種別
 */
export function changeFormType(rr: RespondentRequestEntity = initRespondentRequestData, surveyType: SurveyType, formType: FormType) {
  return {
    ...rr,
    surveyDetail: { ...rr.surveyDetail, surveyHeader: changeFormTypeForSurveyHeader(rr.surveyDetail.surveyHeader, surveyType, formType) },
  };
}
