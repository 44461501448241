import { Box, SxProps } from '@mui/material';
import React from 'react';
import { State } from 'src/@types/generalEnquete';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

export interface StyledBoxProps {
  children: React.ReactNode;
  bgPaletteColor?: string;
  state?: State;
}

export const StyledBox: React.FC<StyledBoxProps> = ({ children, bgPaletteColor, state }: StyledBoxProps) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  let color = isLight ? 'background.paper' : palette.dark.background.paper;
  if (bgPaletteColor === 'neutral') {
    color = isLight ? palette.light.background.neutral : palette.dark.background.neutral;
  }

  const style: SxProps = {
    boxShadow: isLight ? '0px 0px 15px -10px rgba(0,0,0,0.5)' : '0px 0px 15px -10px rgba(255,255,255,0.5)',
    bgcolor: color,
    width: '100%',
    borderRadius: '10px',
    p: 2,
    mb: 2,
    ...(state === 'hidden' && { display: 'none' }),
    ...(state === 'disabled' && { opacity: 0.5 }),
  };

  return (
    <Box component={'div'} sx={style}>
      {children}
    </Box>
  );
};
