import { useTranslation } from 'react-i18next';
import { usePermission } from 'src/hooks/usePermission';
import { useOverviewRoutes } from './useOverviewRoutes';

export const useOverviewPage = () => {
  const { t } = useTranslation();
  const { generalRoutes, manageRoutes } = useOverviewRoutes();
  const { isAdmin, isViewerAdmin, isOrganizationEditor, isCustomerEditor } = usePermission();

  const generalList = Object.values(generalRoutes);
  const manageList = [];

  if (isAdmin || isViewerAdmin || isOrganizationEditor) {
    manageList.push(manageRoutes.COMPLEMENTARY_ORGANIZATION);
  }

  if (isAdmin || isViewerAdmin) {
    manageList.push(manageRoutes.NOTIFICATION);
  }

  if (isAdmin || isViewerAdmin || isCustomerEditor) {
    manageList.push(manageRoutes.CUSTOMER_MANAGEMENT);
  }

  const overviewCardList = [
    {
      genreTitle: t('features.general.title'),
      list: generalList,
    },
    {
      genreTitle: t('features.manage.title'),
      list: manageList,
    },
  ];

  return { overviewCardList };
};
