import { Box, Grid, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledBox } from 'src/components/app-components/StyledBox';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import { setOtherAdditionalQuestions, setOtherFixedTopic } from 'src/features/general/enquete-answer/store/enqueteOverviewAnswerSlice';
import useLocales from 'src/hooks/useLocales';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerValidator } from '../hooks/useEnqueteAnswerValidator';

export interface EnqueteAnswerOtherFormProps {
  methods: UseFormReturn<FieldValues>;
}

const EnqueteAnswerOtherForm: React.FC<EnqueteAnswerOtherFormProps> = (props) => {
  const { methods } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isJapanese } = useLocales();
  const { otherFixedTopic, otherAdditionalQuestions } = useAppSelector((state) => state.enqueteOverviewAnswer);
  const { rulesEnqueteAnswerTarget } = useEnqueteAnswerValidator();

  const prefix = 'other-';
  return (
    <>
      <Box role="topic">{t('enqueteAnswerInput.overviewSurvey.otherTitle')}</Box>
      <StyledBox>
        <Grid container direction="column" rowSpacing={2}>
          <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columns={18} columnSpacing={1}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">{t('enqueteAnswerInput.overviewSurvey.topic')}</Typography>
            </Grid>
            <Grid item xs={15}>
              <RHFonChangeTextField
                name={`${prefix}topic}`}
                defaultValue={otherFixedTopic.answer}
                control={methods.control}
                size="small"
                variant="outlined"
                rules={rulesEnqueteAnswerTarget(t('enqueteAnswerInput.overviewSurvey.topic'), true)}
                multiline={true}
                rows={5}
                handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  dispatch(setOtherFixedTopic(event.target.value));
                }}
              />
            </Grid>
          </Grid>

          {otherAdditionalQuestions &&
            otherAdditionalQuestions.map((value, index) => (
              <>
                {value.isAnswerTarget && (
                  <Grid container item direction="row" alignItems="center" justifyContent="flex-start" columns={18} columnSpacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">{isJapanese ? value.titleJpn : value.titleEng}</Typography>
                    </Grid>
                    <Grid item xs={15}>
                      <RHFonChangeTextField
                        name={`${prefix}AddQuestions${index}`}
                        defaultValue={value.answer}
                        control={methods.control}
                        size="small"
                        variant="outlined"
                        fullWidth
                        rules={rulesEnqueteAnswerTarget(
                          isJapanese ? value.titleJpn : value.titleEng,
                          value.inputType === 'textarea',
                          value.isRequired,
                        )}
                        multiline={value.inputType === 'textarea'}
                        rows={value.inputType === 'textarea' ? 3 : 1}
                        handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          dispatch(setOtherAdditionalQuestions({ value: event.target.value, index }));
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      </StyledBox>
    </>
  );
};

export default EnqueteAnswerOtherForm;
