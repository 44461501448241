import { Grid, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { useAppSelector } from 'src/redux/store';
import { useOwnerOrganizationForm } from '../hooks/useOwnerOrganizationForm';
import RHFInputOwnerAutoComplete from './RHFInputOwnerAutoComplete';

type EnqueteOwnerOrganizationFormProps = {
  methods: UseFormReturn;
  isOverviewCreateMode: boolean;
};

export const EnqueteOwnerOrganizationForm = ({ methods, isOverviewCreateMode }: EnqueteOwnerOrganizationFormProps) => {
  const selectedOwnerOrganization = useAppSelector((state) => state.enqueteInfo.selectedOwnerOrganization);
  const { rulesOwnerOrganization } = useValidator(methods);
  const { t } = useTranslation();
  const { conditions } = useOwnerOrganizationForm();

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
      <Grid item xs={12} md={4}>
        <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
          {t('enqueteGeneralCommon.ownerOrganization')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <RHFInputOwnerAutoComplete
          name="ownerOrganization"
          label={t('enqueteGeneralCommon.ownerOrganization')}
          options={conditions}
          defaultValue={selectedOwnerOrganization}
          methods={methods}
          rules={rulesOwnerOrganization}
          isOverview={isOverviewCreateMode}
        />
      </Grid>
    </Grid>
  );
};
