import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { PersonnelSurveyTextFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../../../../hooks/useEnqueteAnswerGrid';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import { languageDataTypes } from 'src/hooks/useLocales';

export interface EnqueteAnswerTextFormProps {
  form: PersonnelSurveyTextFormData;
  personnelCorporateOtherFormTextValue?: string | undefined;
  setOtherFormFunction: Function;
}

const EnqueteAnswerTextForm: React.FC<EnqueteAnswerTextFormProps> = (props): React.ReactElement => {
  const useJudg = useJudgmentCurrentLanguage();

  // エラー状態を取得
  const hasErrorOtherTextForm = useAppSelector((state) => state.enqueteAnswer.hasErrorOtherTextForm);
  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  const { setHasErrorOtherTextForm } = useEnqueteAnswerGrid();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // エラーの場合はエラーを解除
    if (hasErrorOtherTextForm) {
      setHasErrorOtherTextForm(false);
    }
    props.setOtherFormFunction(event.target.value);
  };
  return (
    <Box component={'div'} sx={{ mb: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            error={hasErrorOtherTextForm}
            label={useJudg.getText(props.form.title as languageDataTypes)}
            multiline
            rows={2}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 1000 }}
            placeholder={useJudg.getText(props.form.text?.placeholder as languageDataTypes)}
            sx={{
              width: '80%',
            }}
            helperText={hasErrorOtherTextForm ? useJudg.getText(props.form.text?.helperText as languageDataTypes) : ''}
            size="small"
            onChange={handleChange}
            defaultValue={props.personnelCorporateOtherFormTextValue}
            disabled={!isEnqueteAnswerOpen}
          />
        </Grid>
        <Grid item xs={12} md={12} />
      </Grid>
    </Box>
  );
};

export default EnqueteAnswerTextForm;
