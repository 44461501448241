import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, FormHelperText, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { QuestionItem } from 'src/@types/generalEnquete';
import RHFRadioGroup from 'src/components/react-hook-form/RHFRadioGroup';
import { Rules, useValidator } from '../../../../hooks/useValidator';

interface Props {
  title: string;
  questionItems: QuestionItem[];
  name: string;
  buttonName?: string;
  rules: Rules;
  defaultValue?: ('public' | 'private' | boolean) | ('send' | 'notSend' | boolean);
  sx?: Object;
  onClick?: () => void | Promise<void>;
  methods: UseFormReturn;
  helpMessage?: string;
}

export const RHFThreadRadioGroup = (props: Props) => {
  const { title, questionItems, name, buttonName, defaultValue, rules, onClick, sx, methods, helpMessage } = props;
  const { rulesThreadMemberDialog } = useValidator(methods);
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
        {helpMessage && (
          <Tooltip
            title={helpMessage.split('\n').map((text: string) => (
              <Box key={text}>{text}</Box>
            ))}>
            <IconButton>
              <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      <Grid container alignItems="center" sx={{ mt: 0, ...sx }}>
        <RHFRadioGroup
          sx={{ display: 'flex', flexDirection: 'row' }}
          defaultValue={defaultValue}
          name={name}
          control={methods.control}
          questionItem={questionItems}
          rules={rules}
          handleOnChange={() => {}}
        />
        {buttonName && (
          <Controller
            name={'threadUserDialog'}
            control={methods.control}
            rules={{
              validate: rulesThreadMemberDialog,
            }}
            render={({ fieldState: { error } }) => {
              return (
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button variant="contained" sx={{ ml: 2 }} onClick={onClick}>
                    {buttonName}
                  </Button>
                  <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
                </Grid>
              );
            }}
          />
        )}
      </Grid>
    </>
  );
};
