import React from 'react';
import { Grid } from '@mui/material';
import SheetNameInput from './SheetNameInput';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { RootState, useAppSelector } from 'src/redux/store';

interface PropsEntity {
  index: number;
}

const MainSheetNamesForm: React.FC<PropsEntity> = ({ index }) => {
  // ストア
  const mainTableSheetNames = useAppSelector((state: RootState) => state.tableSetting.mainTableSheetNames);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ pb: 2 }} spacing={1}>
      <SheetNameInput
        formLabel={TABLE_SETTINGS.MAIN_TABLE_SHEET_NAME_JPN_PATH}
        defaultValue={mainTableSheetNames[index].sheetNameJpn}
        index={index}
      />
      <SheetNameInput
        formLabel={TABLE_SETTINGS.MAIN_TABLE_SHEET_NAME_ENG_PATH}
        defaultValue={mainTableSheetNames[index].sheetNameEng}
        index={index}
      />
    </Grid>
  );
};

export default MainSheetNamesForm;
