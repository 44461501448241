import { lastDayOfMonth, setDate } from 'date-fns';
import { useSnackbar } from 'notistack5';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OverviewEnquete, ReportingPeriod } from 'src/@types/overviewEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { OverviewSurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { useEnqueteCreate } from 'src/features/general/enquete-create/components/pages/create/hooks/useEnqueteCreate';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { ENQUETE_CREATE_PATH } from 'src/features/general/enquete-create/routes/path';
import { setEnqueteData, setResetAdditionalData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { useOverviewOption } from '../../overview-option/hooks/useOverviewOption';
export const useOverviewQuestion = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const enqueteCreateGrid = useEnqueteCreateGrid();
  const { isNewCreateMode } = useEnqueteCreate();
  const { createInitData } = useOverviewOption();

  const questionDescriptionJpn = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);

  const onClickSaveData = async (enqueteData: RespondentRequestEntity, data: FieldValues, isChangeCustomer: boolean) => {
    const surveyDetail = enqueteData.surveyDetail ? Object.assign(enqueteData.surveyDetail) : {};
    const overviewSurveyFormData = surveyDetail?.surveyFormData?.formData as OverviewSurveyFormData;
    const tabData: OverviewEnquete = {
      ...overviewSurveyFormData?.tabData[0],
      questionDescriptionJpn,
      questionDescriptionEng,
      reportingPeriod: setReportingPeriod(data.startDate, data.endDate),
      targetOrgLevelType: data.targetOrgLevelType,
    };

    const newEnqueteData = {
      ...JSON.parse(JSON.stringify(enqueteData)),
      surveyDetail: { ...surveyDetail, surveyFormData: { formData: { ...overviewSurveyFormData, tabData: [tabData] } } },
    };

    if (tabData.customerCode === undefined) {
      // 取引先の設定は必須
      enqueueSnackbar(t('validateError.requiredSelect', { target: t('common.customer') }), { variant: 'error' });
    } else if (isNewCreateMode) {
      // 新規アンケート追加の場合
      const result = await enqueteCreateGrid.saveNewEnqueteData(newEnqueteData, 'overview');
      // エラーハンドリングはsaveNewEnqueteData()で行っているためここでは実装しない
      if (result) {
        dispatch(
          setEnqueteData({
            ...result.respondentRequest,
            surveyDetail: {
              ...result.surveyDetail,
              surveyHeader: result.surveyHeader,
              surveyFormOptionData: await createInitData(result?.surveyDetail.id as string, tabData.customerCode), // 会社概況の登録を行う
            },
          }),
        );
        navigate(ENQUETE_CREATE_PATH.EDIT + '/' + result.respondentRequest.id);
      }
    } else {
      // アンケート編集の場合
      const result = await enqueteCreateGrid.saveSurveyDetailData(newEnqueteData.surveyDetail as SurveyDetailEntity);
      // エラーハンドリングはsaveSurveyDetailData()で行っているためここでは実装しない
      if (result) {
        newEnqueteData.surveyDetail = {
          ...result.data,
          surveyHeader: newEnqueteData.surveyDetail?.surveyHeader, // memo: 更新後の結果に SurveyHeader が含まれないため、明示的にセット
          surveyFormOptionData: isChangeCustomer
            ? await createInitData(result?.data.id as string, tabData.customerCode) // 会社概況の登録を行う
            : surveyDetail.surveyFormOptionData,
        } as SurveyDetailEntity;
        dispatch(setEnqueteData({ ...newEnqueteData }));
        dispatch(setResetAdditionalData());
      }
    }
  };

  /**
   * レート情報を設定する
   * @param 期間開始日
   * @param 期間終了日
   */
  const setReportingPeriod = (startDate: string, endDate: string): ReportingPeriod => {
    return {
      // startDateには月初の日付を設定
      startDate: setDate(new Date(startDate), 1).toLocaleDateString('ja-jp', { timeZone: 'Asia/Tokyo' }),
      // endDateには月末の日付を設定
      endDate: lastDayOfMonth(new Date(endDate)).toLocaleDateString('ja-jp', { timeZone: 'Asia/Tokyo' }),
    };
  };

  return {
    onClickSaveData,
  };
};
