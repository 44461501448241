import { Box, Modal, Stack, SxProps, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TemporaryOrganizationDataTypes } from 'src/api/useTemporaryOrganizationApi';
import TemporaryOrganizationContent from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/temporary-organization/components/TemporaryOrganizationContent';
import { CreateOrgTypeList } from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/temporary-organization/components/TemporaryOrganizationTab';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';

////// Interface //////
// Modal Component Types
interface ModalTypes {
  editRowData: TemporaryOrganizationDataTypes | null;
  surveyDetailId: string;
  createType: CreateOrgTypeList | null;
  closeModalCallback: () => void;
}

// モーダル組織データ初期値
export const InitOrganizationData: TemporaryOrganizationDataTypes = {
  id: '',
  rank: '',
  companyCode: '',
  companyNameJpn: '',
  companyNameEng: '',
  companyAbbreviation: '',
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  sbuCode: '',
  sbuNameJpn: '',
  sbuNameEng: '',
  departmentCode: '',
  departmentNameJpn: '',
  departmentNameEng: '',
  groupCode: '',
  groupNameJpn: '',
  groupNameEng: '',
  regionCode: '',
  regionNameJpn: '',
  regionNameEng: '',
  countryCode: '',
  countryNameJpn: '',
  countryNameEng: '',
};

const TemporaryOrganizationDetailModal: React.FC<ModalTypes> = (props) => {
  const { editRowData, surveyDetailId, createType, closeModalCallback } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isOpenEditModal = useAppSelector((state) => state.temporaryOrganization.isOpenEditModal);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [organizationData, setOrganizationData] = useState<TemporaryOrganizationDataTypes>(InitOrganizationData);

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // モーダル表示時のデータ格納
  const setInputData = () => {
    if (_.isNull(editRowData)) {
      // 新規作成時
      setIsEdit(false);
      setOrganizationData(InitOrganizationData);
    } else {
      // 編集時
      setIsEdit(true);
      setOrganizationData(editRowData);
    }
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    setInputData();
  }, [editRowData]); // eslint-disable-line

  /***********************************************************************************
   * Configuration
   ***********************************************************************************/
  // モーダルスタイルの定義
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 700,
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll',
    maxHeight: '85%',
  };

  // グリッドコンテナスタイルの定義
  const girdStyle: SxProps = {
    pt: 2,
  };

  return (
    <Modal open={isOpenEditModal} onClose={closeModalCallback}>
      <Box component={'div'} sx={modalStyle}>
        {/* modal title */}
        <Typography variant="h4" component={'h2'}>
          {isEdit ? t('enqueteCreate.temporaryOrganization.modal.editTitle') : t('enqueteCreate.temporaryOrganization.modal.createTitle')}
        </Typography>

        {/* Form */}
        <Stack sx={girdStyle} spacing={3}>
          <TemporaryOrganizationContent inputData={organizationData} createType={createType} isEdit={isEdit} surveyDetailId={surveyDetailId} />
        </Stack>
      </Box>
    </Modal>
  );
};

export default TemporaryOrganizationDetailModal;
