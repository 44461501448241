import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { EnqueteAnswerDescriptionBox } from '../../../../type-general-question/components/tabs/question-and-answer/components/common/EnqueteAnswerDescriptionBox';

export const EnqueteAnswerSeriesDescriptionBox = () => {
  const { currentLang } = useLocales();
  const { surveyDetailData } = useAppSelector((state) => state.enqueteAnswer);
  const memolizedEnqueteFormData = useMemo(() => surveyDetailData.surveyFormData.formData.tabData[0], [surveyDetailData]) as SeriesSurveyFormTabData;
  const questionDescription =
    currentLang.value === 'ja' ? memolizedEnqueteFormData?.questionDescriptionJpn : memolizedEnqueteFormData?.questionDescriptionEng;
  return (
    <Grid item xs={12} md={12}>
      {questionDescription && questionDescription !== '' ? <EnqueteAnswerDescriptionBox questionDescription={questionDescription} /> : null}
    </Grid>
  );
};
