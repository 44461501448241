import { stringify } from 'csv-stringify/browser/esm/sync';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { DELIMITER } from 'src/constants';
import { TRANSLATION_SUPPORT_FILE } from '../constant/constant';

interface TranslationTableObject {
  [key: string]: string;
}
export interface TranslationTable extends Array<TranslationTableObject> {}

export const useTranslationDownload = () => {
  const { t } = useTranslation();

  /**
   * For downloading
   * @param csvData
   */
  const handleDownload = (csvData: string) => {
    const element = document.createElement('a');
    const file = new Blob([TRANSLATION_SUPPORT_FILE.BOM_UNIT8ARRAY, csvData], {
      type: TRANSLATION_SUPPORT_FILE.FILE_TYPE + ';' + TRANSLATION_SUPPORT_FILE.CHAR_CODE,
    });
    element.href = URL.createObjectURL(file);
    element.download = TRANSLATION_SUPPORT_FILE.FILE_NAME;
    document.body.appendChild(element);
    element.click();
  };

  /**
   * アンケートデータから日本語文字列を抽出した配列を作る
   * 再帰呼び出しを行う
   *
   * @param enqueteData
   * @returns 日本語文字列の配列
   */
  const getJapaneseArrayFromEnqueteData = (enqueteData: any): string[] => {
    // 重複排除のため、Setを使用する
    const set = new Set<string>();
    for (let key in enqueteData) {
      if (typeof enqueteData[key] === 'string') {
        if (checkCharacterTranslation(enqueteData[key])) {
          set.add(enqueteData[key]);
        }
      } else if (typeof enqueteData[key] === 'object') {
        getJapaneseArrayFromEnqueteData(enqueteData[key]).forEach((value) => set.add(value));
      }
    }
    return Array.from(set);
  };

  /**
   * ダウンロードするcsvデータを作成する
   *
   * @param questionsJpn
   * @param questionsTranslationData questionsの日英変換データ
   * @returns csv文字列
   */
  const createDownloadingCSVData = (questionsJpn: Question[], questionsTranslationData?: string[][]): string => {
    const titleKey = 'enqueteGeneralCommon.translationSupport.csvFile.questionTytle';
    const titleJpn = t(`${titleKey}.jpn`);
    const titleEng = t(`${titleKey}.eng`);
    const map = new Map().set(titleJpn, titleEng);

    getJapaneseArrayFromEnqueteData(questionsJpn).forEach((data) => {
      map.set(data, questionsTranslationData?.find((raw) => raw[0] === data)?.at(1) ?? '');
    });
    return stringify(
      Array.from(map, ([key, value]) => [key, value]),
      {
        quoted: true,
        quoted_empty: true,
        record_delimiter: DELIMITER,
      },
    );
  };

  /**
   * Check if the string includes Japanese characters
   * 日英変換対象文字列チェック
   * [\u3040-\u309Fー]	すべてのひらがな
   * [\u30A0-\u30FF]	すべてのカタカナ
   * U+3400 ～ U+4DBF … CJK Unified Ideographs Extension A（CJK 統一漢字拡張 A）
   * U+4E00 ～ U+9FFF … CJK Unified Ideographs            （CJK統一漢字）
   * U+F900 ～ U+FAFF … CJK Compatibility Ideographs      （CJK互換漢字）
   * \uFF66-\uFF9F' [ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾞﾟ]
   *
   * @param value
   * @returns boolean 日本語を含む文字列の場合 true, それ以外はfalse
   */
  const checkCharacterTranslation = (value: string) => {
    if (value && value.match(/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/)) {
      return true;
    }
    return false;
  };

  return {
    handleDownload,
    createDownloadingCSVData,
    getJapaneseArrayFromEnqueteData,
    checkJapanese: checkCharacterTranslation,
  };
};
