import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import { enUS, jaJP } from 'handsontable/i18n';
import { HyperFormula } from 'hyperformula';
import React, { RefObject } from 'react';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import { handsontableLicenseKey } from 'src/config';
import { TABLE_SETTINGS } from 'src/generalConstants';
import useLocales from 'src/hooks/useLocales';
import { useTableHandler } from '../hooks/useTableHandler';

interface PropsEntity {
  hotRef: RefObject<HotTable>;
  hyperformulaInstance: HyperFormula;
  sheetName: string;
  isPreview: boolean;
  isVisibleContextMenu: boolean;
}

const EnqueteMainTableForm: React.FC<PropsEntity> = React.memo((props) => {
  const { hotRef, hyperformulaInstance, sheetName, isPreview, isVisibleContextMenu } = props;
  const { isJapanese } = useLocales();
  const languageDirectory = isJapanese ? jaJP.languageCode : enUS.languageCode;

  const {
    constextMenuMain,
    beforeCopyHandler,
    afterCopyHandler,
    afterPasteHandler,
    afterAutofillHandler,
    afterCreateColHandler,
    afterUnmergeCellsHandler,
    afterRemoveColHandler,
    afterRemoveRowHandler,
    afterUndoHandler,
    beforeUndoHandler,
    afterRedoHandler,
    beforeRedoHandler,
  } = useTableHandler(hotRef);

  return (
    <HotTable
      style={{ height: TABLE_SETTINGS.MAIN.TABLE_HEIGHT, overflowX: 'hidden', overflowY: 'hidden' }}
      ref={hotRef}
      language={isVisibleContextMenu ? languageDirectory : undefined}
      width="100%"
      manualColumnMove={false}
      manualRowMove={false}
      maxRows={TABLE_SETTINGS.MAIN.MAX_ROWS}
      maxCols={TABLE_SETTINGS.MAIN.MAX_COLS}
      contextMenu={!isPreview ? constextMenuMain : undefined}
      fillHandle={!isPreview}
      beforeCopy={!isPreview ? beforeCopyHandler : undefined}
      afterCopy={!isPreview ? afterCopyHandler : undefined}
      afterPaste={!isPreview ? afterPasteHandler : undefined}
      afterAutofill={!isPreview ? afterAutofillHandler : undefined}
      afterCreateCol={!isPreview ? afterCreateColHandler : undefined}
      afterUnmergeCells={!isPreview ? afterUnmergeCellsHandler : undefined}
      afterRemoveCol={!isPreview ? afterRemoveColHandler : undefined}
      afterRemoveRow={!isPreview ? afterRemoveRowHandler : undefined}
      afterUndo={!isPreview ? afterUndoHandler : undefined}
      beforeUndo={!isPreview ? beforeUndoHandler : undefined}
      afterRedo={!isPreview ? afterRedoHandler : undefined}
      beforeRedo={!isPreview ? beforeRedoHandler : undefined}
      formulas={
        {
          engine: hyperformulaInstance,
          sheetName: sheetName,
        } as any // 型定義しなければエラーとなる。正しい型定義を行うのに調査を要するため、一旦anyを指定
      }
      licenseKey={handsontableLicenseKey}
      renderAllRows={true}
      viewportColumnRenderingOffset={TABLE_SETTINGS.MAIN.MAX_COLS}
    />
  );
});
export default EnqueteMainTableForm;
