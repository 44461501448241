import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Rules } from '../../../../hooks/useValidator';

interface SurveyTitle {
  key: string;
  label: string;
}

export interface Props {
  title: string;
  rules: Rules;
  method: UseFormReturn;
  label: string;
  defaultValue?: SurveyTitle;
  surveyTitles: SurveyTitle[];
}

export const RHFThreadAutoComplete = (props: Props) => {
  const { title, method, surveyTitles, label, rules, defaultValue } = props;
  const { getValues, control } = method;
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Controller
        name="surveyDetailId"
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <Grid sx={{ width: '100%' }}>
            <Autocomplete
              disabled={!!defaultValue}
              fullWidth
              freeSolo
              id="combo-box-demo"
              defaultValue={defaultValue}
              value={getValues('surveyDetailId') === undefined ? { key: '', label: '' } : getValues('surveyDetailId')}
              getOptionLabel={(option) => option.label || ''}
              onChange={(_, newInputValue) => field.onChange(newInputValue)}
              isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
              options={surveyTitles}
              renderOption={(props, option) => (
                <li {...props} key={option.key}>
                  {option.label}
                </li>
              )}
              sx={{ mt: 1 }}
              renderInput={(params) => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} label={label} />}
            />
          </Grid>
        )}
      />
    </>
  );
};
