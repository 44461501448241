import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { RootState } from 'src/redux/store';
import { FormValues, useEnqueteDescriptionForm } from '../hooks/useEnqueteDescriptionForm';

type EnqueteDescriptionInputProps = {
  formLabel: string;
};

export const EnqueteDescriptionInput: React.FC<EnqueteDescriptionInputProps> = ({ formLabel }) => {
  const MAX_LENGTH_QUESTION_DESCRIPTION = 3000;
  const { t } = useTranslation();
  const questionDescriptionJpn = useSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);
  const { control, watch, formState, trigger, setValue, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });
  const labelToWatch = watch('description');
  const questionDescription = formLabel === 'questionDescriptionJpn' ? questionDescriptionJpn : questionDescriptionEng;

  useEnqueteDescriptionForm({ formState, trigger, setValue, getValues, labelToWatch, questionDescription, formLabel });

  return (
    <Grid sx={{ mt: 2 }}>
      <RHFTextField
        name="description"
        label={t(`enqueteCreate.${formLabel}`)}
        control={control}
        rows={5}
        multiline={true}
        fullWidth
        defaultValue={questionDescriptionJpn}
        placeholder={t('validateError.maxLength', { max: MAX_LENGTH_QUESTION_DESCRIPTION.toLocaleString() })}
        rules={{
          maxLength: {
            value: MAX_LENGTH_QUESTION_DESCRIPTION,
            message: t('validateError.maxLength', { max: MAX_LENGTH_QUESTION_DESCRIPTION.toLocaleString() }),
          },
          required: false,
        }}
      />
    </Grid>
  );
};
