import { useSnackbar } from 'notistack5';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getThreadCreatorApi } from 'src/api/getThreadCreatorApi';
import { ENQUETE_THREAD_PATH } from 'src/features/general/enquete-threads/routes/path';
import { fetchThreadPriorInfo } from 'src/features/general/enquete-threads/store/enqueteThreadActions';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { enqueteThreadCreateSlice } from '../../../../store/enqueteThreadCreateSlice';
import { ChipData } from '../components/EnqueteThreadCreateLayout';

const useThreadCreate = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const methods = useForm({ mode: 'onChange', shouldUnregister: false });
  const { getValues, setValue } = methods;

  const selector = useAppSelector((state) => state.enqueteThreadCreate);
  const status = selector.status;
  const thread = useAppSelector((state) => state.enqueteThread.thread);

  const [chipData, setChipData] = useState<{ key: string; label: string; selected: boolean }[] | undefined>([]);
  const [surveyTitles, setSurveyTitles] = useState<{ key: string; label: string }[]>([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const { createThread } = getThreadCreatorApi();

  useEffect(() => {
    dispatch(fetchThreadPriorInfo());
  }, [dispatch]);

  useMemo(() => {
    selector.priorInfo?.surveyTitle &&
      setSurveyTitles(
        selector.priorInfo.surveyTitle.map((v) => {
          return { key: v.surveyDetailId, label: isJapanese ? v.titleJpn : v.titleEng };
        }),
      );
    selector.priorInfo.categories &&
      setChipData(
        selector.priorInfo.categories.map((v) => {
          return {
            key: v.id,
            label: isJapanese ? v.nameJpn : v.nameEng,
            selected: false,
          };
        }),
      );
  }, [isJapanese, selector.priorInfo.categories, selector.priorInfo.surveyTitle]);

  const handleClick = (chipData: ChipData[], chipName: ChipData) => {
    const newChipData = [...chipData];
    const editedChipData = newChipData.map((item) => {
      if (item.key === chipName.key && item.selected === false) {
        return { ...item, selected: true };
      }
      if (item.key === chipName.key && item.selected === true) {
        return { ...item, selected: false };
      }
      return item;
    });
    setChipData(editedChipData);
    setValue('categories', editedChipData, { shouldValidate: true });
  };

  const onSubmit = async (data: FieldValues) => {
    const categories = data.categories.filter((v: ChipData) => v.selected === true);
    const categoryIds = categories?.map((v: ChipData) => v.key);
    const threadUsersId = selector.threadUsers.map((v) => v.email);
    const result = {
      title: data.title,
      isSendMailToUser: data.isSendMailToUser === 'send',
      isPrivate: data.isPrivate === 'private',
      surveyDetailId: data.surveyDetailId.key,
      categories: categoryIds!,
      description: data.description,
      threadUsers: threadUsersId.filter((v) => v !== null) as string[],
    };
    setIsClicked(true);
    try {
      await createThread(result);
      dispatch(enqueteThreadCreateSlice.actions.clearThreadUsers());
      navigate(ENQUETE_THREAD_PATH.INDEX);
    } catch {
      enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('enqueteThread.detail.pageTitle') }), { variant: 'error' });
      setIsClicked(false);
    }
  };

  const onCloseModal = () => {
    setValue('threadUserDialog', 'closed', { shouldValidate: true });
    setIsOpenAddModal(false);
  };

  const onOpenModal = () => {
    setIsOpenAddModal(true);
  };

  return {
    selector,
    getValues,
    isOpenAddModal,
    chipData,
    surveyTitles,
    isClicked,
    handleClick,
    onCloseModal,
    onOpenModal,
    onSubmit,
    status,
    createThread,
    methods,
    id,
    thread,
  };
};

export default useThreadCreate;
