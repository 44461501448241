import { Box, Modal, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

export interface StyledModalProps {
  isOpen: boolean;
  onCloseFunc: () => void;
  modalTitle: string;
  children: React.ReactNode;
  mode?: string;
  /**
   * keepMounted は本来 Modal Component が非表示となる場合に、 unmount される仕様で、
   * それを強制的に Mount 状態を保持するための Property になります
   * そのため、パフォーマンスに影響を及ぼす可能性があり、基本的には使用しないでください
   * これを利用するケースの一例としては、 children の中で RefObject を利用する場合で、
   * RefObject は unmount されると null になってしまい、所望する動作が実現できないことがあり、
   * これを回避するために利用します
   */
  keepMounted?: boolean;
}

export const StyledModal = forwardRef<HTMLHeadingElement, StyledModalProps>((props, ref) => {
  const { keepMounted } = props;
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxshadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll',
    maxHeight: '80%',
  };

  const styleTranslationSupport = {
    position: 'absolute',
    top: '10%',
    bottom: '10%',
    left: '10%',
    right: '10%',
    transform: 'translate(0%, 0%)',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxshadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto',
    maxHeight: '80%',
  };

  const styleHeaderSetting = {
    position: 'absolute',
    top: '40%',
    left: '10%',
    right: '10%',
    transform: 'translate(0%, -40%)',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxshadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto',
    maxHeight: '80%',
  };

  const styleBulkCreateEmailModal = {
    position: 'absolute',
    top: '10%',
    left: '5%',
    right: '5%',
    transform: 'translate(0%, 0%)',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxshadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto',
    maxHeight: '80%',
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={(e) => props.onCloseFunc()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted={keepMounted}>
      <Box
        ref={ref}
        sx={
          props.mode
            ? props.mode === 'general'
              ? styleTranslationSupport
              : 'BulkCreateEmailModal'
              ? styleBulkCreateEmailModal
              : styleHeaderSetting
            : style
        }>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          {props.modalTitle}
        </Typography>
        <Typography id="modal-modal-description" component={'div'} sx={{ mt: 2 }}>
          {props.children}
        </Typography>
      </Box>
    </Modal>
  );
});
