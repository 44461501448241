import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { SurveyDetailEntity } from './useSurveyDetailApi';
import { useBearerToken } from './useBearerToken';

/****************************************************************************************
 *
 * Survey-edit関連のAPIを使用しているが、ソースコード行数が肥大するため、仮組織はここに記載する
 *
 ***************************************************************************************/

/**
 * 仮組織表示用のインターフェース
 */
export interface TemporaryOrganizationDataTypes {
  id: string;
  companyCode: string;
  companyNameJpn: string;
  companyNameEng: string;
  companyAbbreviation: string;
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  sbuCode: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentCode: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupCode: string;
  groupNameJpn: string;
  groupNameEng: string;
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
  rank: string;
}

/**
 * 仮組織マスタ */
export interface TemporaryOrganizationEntity extends TemporaryOrganizationDataTypes {
  orgLevelType: string;
  surveyDetailId: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  isFuture: boolean;
  isTemporary: boolean;
  isDeleted: boolean;
}

/**
 * 仮組織マスタ登録・更新用APIのインターフェース
 */
export interface InputTemporaryOrganizationTypes extends Omit<TemporaryOrganizationDataTypes, 'id'> {}

export const useTemporaryOrganizationApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 一覧取得
   *
   * @param surveyDetailId
   * @returns
   */
  const getTemporaryOrganizationList = async (surveyDetailId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<TemporaryOrganizationEntity[]>(`${apiGateway}/survey-edit/temporary-organizations/${surveyDetailId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.organization') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 登録
   *
   * @param surveyDetailId
   * @param data
   * @returns
   */
  const createTemporaryOrganization = async (surveyDetailId: string, data: InputTemporaryOrganizationTypes[]) => {
    try {
      const token = await getBearerToken();
      const result = await axios.post<SurveyDetailEntity>(
        apiGateway + `/survey-edit/temporary-organizations?surveyDetailId=${surveyDetailId}`,
        data,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useOrganizationApi.failedPostOrganizasionData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 更新
   *
   * @param organizationId
   * @param surveyDetailId
   * @param data
   * @returns
   */
  const updateTemporaryOrganization = async (organizationId: string, surveyDetailId: string, data: InputTemporaryOrganizationTypes) => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<TemporaryOrganizationEntity>(
        apiGateway + `/survey-edit/temporary-organizations/${organizationId}?surveyDetailId=${surveyDetailId}`,
        data,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useOrganizationApi.failedUpdateOrganizasionDataDetail'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 削除
   *
   * @param organizationId
   * @param surveyDetailId
   * @returns
   */
  const deleteTemporaryOrganization = async (organizationId: string, surveyDetailId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.delete<TemporaryOrganizationEntity>(
        apiGateway + `/survey-edit/temporary-organizations/${organizationId}?surveyDetailId=${surveyDetailId}`,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useOrganizationApi.failedDeleteOrganizasionData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getTemporaryOrganizationList, createTemporaryOrganization, updateTemporaryOrganization, deleteTemporaryOrganization };
};
