import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { useEnqueteSave } from 'src/features/general/enquete-create/hooks/useEnqueteSave';
import {
  setIsModalOpenLogic,
  setIsOpenLogicErrorMessageModal,
  setLogicErrorMessages,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useModalHandlers } from '../../../../hooks/useModalHandlers';
import { useLogicData } from '../hooks/useLogicData';
import { ConditionEditSwitcher } from './ConditionEditSwitcher';
import EnqueteLogiccDescriptionGrid from './EnqueteLogiccDescriptionGrid';

const EnqueteLogicForm: React.FC = () => {
  const enqueteData: RespondentRequestEntity = useSelector((state: RootState) => state.enqueteCreate.respondentRequest);
  const logicQuestions: Question[] = useSelector((state: RootState) => state.enqueteCreate.logicQuestions);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onClickSaveData } = useEnqueteSave();
  const { onClickModalCloseLogic } = useModalHandlers();
  const { checkQuestionConditionErrors } = useLogicData();
  // ロジック設定（保存）
  const onClickLogicSave = async () => {
    // 入力チェック

    const errorMessages = checkQuestionConditionErrors();
    if (errorMessages.length === 0) {
      // 保存処理
      // memo:ロジック設定の場合は、logicQuestionsで質問を登録する。
      await onClickSaveData(enqueteData, logicQuestions);
      //　ダイアログクローズ
      dispatch(setIsModalOpenLogic(false));
    } else {
      // エラーメッセージ表示
      dispatch(setLogicErrorMessages(errorMessages));
      dispatch(setIsOpenLogicErrorMessageModal(true));
    }
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="left" xs={12} spacing={1} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <EnqueteLogiccDescriptionGrid />
        <Grid item xs={12} sx={{ overflowY: 'auto', overflowX: 'auto', width: '100%', flex: '1' }}>
          <ConditionEditSwitcher />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky', bottom: 2, flexShrink: '0', pt: 2 }}>
        {/* 閉じるボタン */}
        <Button key="close" variant={'contained'} onClick={onClickModalCloseLogic} color="error">
          {t('button.close')}
        </Button>
        {/* 設定ボタン  */}
        <Button variant={'contained'} onClick={() => onClickLogicSave()} sx={{ ml: 2 }}>
          {t('button.setting')}
        </Button>
      </Box>
    </>
  );
};

export default EnqueteLogicForm;
