import { Button, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import Label from 'src/components/Label';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import useBulkCreateEmailModal from '../hooks/useBulkCreateEmailModal';

export type FormValues = {
  emails: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const BulkCreateEmailModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit, reset, setValue } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { onClick, toArrayEmailsString, onClickNoErrorEmail } = useBulkCreateEmailModal();
  const tKey = 'enqueteCreate.bulkCreateEmailModal';
  const rowLength = 8;
  const [errMessage, setErrMessage] = useState<string>('');
  const [enterEmails, setEnterEmails] = useState<string[]>([]);
  const [isStyledLoading, setIsStyledLoading] = useState(false);

  const storageKey = 'BulkCreateEmailModal';
  const enterEmailsKey = 'EnterEmails';
  const errMessageKey = 'ErrMessage';
  // RespondentRequestID取得
  const { id } = useParams();
  const rrId = id ?? id;
  const savedLocalStorage = (enterEmails: string[], errMessage: string) => {
    localStorage.setItem(`${storageKey}${enterEmailsKey}${rrId}`, JSON.stringify(enterEmails.length > 0 ? enterEmails : []));
    localStorage.setItem(`${storageKey}${errMessageKey}${rrId}`, errMessage ? JSON.stringify(errMessage) : '');
  };

  useEffect(() => {
    const enterEmailsString: string | null = localStorage.getItem(`${storageKey}${enterEmailsKey}${rrId}`);
    const enterEmailsParse: string[] = enterEmailsString ? JSON.parse(enterEmailsString) : [];
    setEnterEmails(enterEmailsParse);
    setValue('emails', enterEmailsParse.join(',').replace(/,/g, '\n'));

    const errMessageString = localStorage.getItem(`${storageKey}${errMessageKey}${rrId}`);
    setErrMessage(errMessageString ? JSON.parse(errMessageString) : '');
  }, []);

  return (
    <>
      <StyledModal isOpen={isOpen} modalTitle={t(`${tKey}.title`)} onCloseFunc={onClose} mode="BulkCreateEmailModal">
        <Grid container xs={12} spacing={1} alignItems="flex-end" style={{ height: '75px' }}>
          <Grid item xs={6}>
            <Label
              children={t(`${tKey}.explanation`)}
              color="info"
              sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            {t(`${tKey}.bulkCreateEmailError`)}{' '}
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Grid container sx={{ mt: 2 }}>
              <RHFTextField
                name="emails"
                label={''}
                control={control}
                rows={rowLength}
                multiline={true}
                fullWidth
                placeholder={t(`${tKey}.placeholder`)}
                rules={{
                  required: { value: true, message: t('validateError.required', { target: t('common.email') }) },
                  validate: {
                    isValidEmails: (email: string) => {
                      const emails = toArrayEmailsString(email);
                      const isValidEmail = (email: string) => {
                        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
                      };
                      for (const email of emails) {
                        if (!isValidEmail(email)) return t('validateError.format.email');
                      }
                      return true;
                    },
                    isOverEmailsCount: (email: string) => {
                      const limit = 1000;
                      const emails = toArrayEmailsString(email);
                      if (limit < emails.length) return t(`${tKey}.maxCount`, { max: limit.toLocaleString() });
                      return true;
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container sx={{ mt: 2 }}>
              <RHFTextField
                name="errEmails"
                label={''}
                control={control}
                rows={rowLength}
                multiline={true}
                fullWidth
                placeholder={''}
                value={errMessage}
                inputProps={{ style: { color: 'red' }, readOnly: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={1} sx={{ mt: 3, mr: 3, width: '100%', textAlign: 'right' }}>
          <Grid container>
            <Grid item xs={6} justifyContent={'left'}>
              <Stack direction={'row'} spacing={1}>
                <Button
                  variant={'contained'}
                  onClick={() => {
                    onClose();
                  }}
                  children={t('button.cancel')}
                  color="error"
                />
              </Stack>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              {errMessage ? (
                <Stack direction={'row-reverse'}>
                  <Button
                    variant={'contained'}
                    onClick={handleSubmit(async (data: FormValues) => {
                      setIsStyledLoading(true);
                      const emailsList = toArrayEmailsString(data.emails);
                      const result = await onClickNoErrorEmail(emailsList, errMessage.split('\n'));
                      if (result) {
                        savedLocalStorage(emailsList, errMessage + '\n' + result);
                        setErrMessage(errMessage + '\n' + result);
                      } else {
                        reset();
                        setValue('emails', '');
                        savedLocalStorage([], errMessage);
                      }
                      setIsStyledLoading(false);
                    })}
                    children={t(`${tKey}.button.RegisterExcludingUnregistrableAddresses`)}
                    sx={{ ml: 2 }}
                    disabled={!formState.isValid}
                  />
                </Stack>
              ) : undefined}{' '}
              <Stack direction={'row-reverse'}>
                <Button
                  variant={'contained'}
                  onClick={handleSubmit(async (data: FormValues) => {
                    setIsStyledLoading(true);
                    const result = await onClick(data);
                    setErrMessage('');
                    setEnterEmails([]);
                    if (result) {
                      const emailsForm = toArrayEmailsString(data.emails);
                      const emails: string[] = [...new Set(emailsForm)];
                      setEnterEmails(emails);
                      setErrMessage(result);
                      savedLocalStorage(emails, result);
                    } else {
                      reset();
                      setValue('emails', '');
                      savedLocalStorage([], '');
                      onClose();
                    }
                    setIsStyledLoading(false);
                  })}
                  children={t('button.register')}
                  sx={{ ml: 2 }}
                  disabled={!formState.isValid}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </StyledModal>

      <StyledLoading isOpen={isStyledLoading} />
    </>
  );
};

export default BulkCreateEmailModal;
