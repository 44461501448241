import { faSquareMinus, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question, QuestionItem } from 'src/@types/generalEnquete';
import RHFonChangeTextField from 'src/components/react-hook-form/RHFonChangeTextField';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { setQuestionItem } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { QUESTIONITEM_SAVE_MODE, QUESTION_ITEM } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store/index';
import { v1 } from 'uuid';
import { useQuestionItemForm } from '../../../hooks/useQuestionItemForm';

type QuestionItemFormProps = {
  methods: UseFormReturn;
  existingQuestion?: Question;
  inputType: String;
  children: React.ReactNode;
};

const QuestionItemForm = ({ methods, existingQuestion, inputType, children }: QuestionItemFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const questionItem = useSelector((state: RootState) => state.enqueteCreate.questionItem);
  const { rulesCreateQuestionItem } = useValidator(methods);
  const { handleDeleteOnClick, handleAddOnClick } = useQuestionItemForm(methods, questionItem, inputType);

  useEffect(() => {
    if (existingQuestion) {
      dispatch(
        setQuestionItem({
          mode: QUESTIONITEM_SAVE_MODE.REFILL,
          currentInputType: inputType,
          value: existingQuestion.questionItem,
        }),
      );
    } else {
      if (!questionItem.length) {
        dispatch(
          setQuestionItem({
            index: 0,
            mode: QUESTIONITEM_SAVE_MODE.ADD,
            currentInputType: inputType,
            value: { id: v1(), label: '' } as QuestionItem,
          }),
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 回答選択肢の追加、削除時にRHFを再設定する。
  useEffect(() => {
    questionItem.forEach((item: QuestionItem, index: number) => {
      methods?.setValue('questionItem' + index, item.label);
    });
  }, [questionItem, methods]);

  const questionItemRender = () => {
    return questionItem.map((item: QuestionItem, index: number) => {
      const { id, label } = item;
      return (
        <ListItem key={id}>
          {children}
          <RHFonChangeTextField
            name={'questionItem' + index}
            control={methods.control}
            rules={rulesCreateQuestionItem}
            defaultValue={label}
            placeholder={t('enqueteCreate.questionItemPlaceholderElement')}
            handleOnChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              dispatch(
                setQuestionItem({
                  index,
                  mode: QUESTIONITEM_SAVE_MODE.MODIFY,
                  currentInputType: inputType,
                  value: { id: id, label: event.currentTarget.value } as QuestionItem,
                }),
              );
            }}
            margin="dense"
            fullWidth
          />

          <ListItemIcon sx={{ m: 1 }} onClick={() => handleAddOnClick()}>
            <FontAwesomeIcon
              icon={faSquarePlus}
              fontSize="xx-large"
              style={{ display: questionItem.length === QUESTION_ITEM.MAX_COUNT ? 'none' : 'block', cursor: 'pointer' }}
            />
          </ListItemIcon>
          <ListItemIcon onClick={() => handleDeleteOnClick(index, id)}>
            <FontAwesomeIcon
              icon={faSquareMinus}
              fontSize="xx-large"
              style={{ display: questionItem.length === QUESTION_ITEM.MIN_COUNT ? 'none' : 'block', cursor: 'pointer' }}
            />
          </ListItemIcon>
        </ListItem>
      );
    });
  };

  return <>{questionItemRender()}</>;
};

export default QuestionItemForm;
