import { Auth } from 'aws-amplify';
import axios from 'axios';
import { apiGateway } from 'src/config';
import { Permission, StatusType } from './api-constants';
import { Category } from './getThreadsApi';

export interface ThreadUser {
  userId: string;
  userNameEng: string;
  userNameJpn: string;
  companyNameJpn: string;
  companyNameEng: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupNameJpn: string;
  groupNameEng: string;
  permission: Permission;
}

export interface Comment {
  id: string;
  comment: string;
  editedAt: string | null;
  updatedAt: string | null;
  user: ThreadUser;
}

export interface EnqueteComments {
  title: string;
  description: string;
  isSolved: boolean;
  isPrivate: boolean;
  updatedAt: string | null;
  createdBy: ThreadUser;
  categories: Category[];
  comments: Comment[];
}
export interface EnqueteCommentEntitiy {
  comments: EnqueteComments;
  status: StatusType;
}

export const getCommentsApi = () => {
  const getBearerToken = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return `Bearer ${token}`;
  };

  const getComments = async (threadId: string) => {
    const token = await getBearerToken();
    const response = await axios.get<EnqueteComments>(apiGateway + `/enquete-threads/detail/${threadId}`, {
      headers: {
        Authorization: token,
      },
      timeout: 60000,
    });
    return response.data;
  };

  const createComment = async (comment: string, threadId: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios({
        url: apiGateway + `/enquete-threads/detail/${threadId}`,
        method: 'POST',
        data: { comment },
        headers: { Authorization: token, 'Content-Type': 'application/json; charset=utf-8 ' },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.status;
      } else {
        throw error;
      }
    }
  };

  return { getComments, createComment };
};
