import { useState } from 'react';

export const useEnqueteDescriptionTab = () => {
  const [tabNumber, setTabNumber] = useState('1');
  const handleTabClick = (_event: React.SyntheticEvent, newTabNumber: string) => {
    // Tablist の onChange のハンドラーとして _event が必要ですが未使用としています
    setTabNumber(newTabNumber);
  };
  return { tabNumber, handleTabClick };
};
