import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SeriesAggregationData,
  SeriesAggregationSbuInfo,
  SeriesSurveyFormAggregationData,
  SeriesSurveyFormTabData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

import _ from 'lodash';
import { useSnackbar } from 'notistack5';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import {
  SearchOrganizationAdditionalConditionTypes,
  useSearchOrganizationConditions,
} from 'src/features/general/enquete-create/hooks/useSearchOrganizationConditions';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { addSuffix, switchLabel } from 'src/utils/labelUtils';
import { useEnqueteCommon } from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteCommon';
import {
  SeriesEnqueteRowData,
  useEnqueteModal,
} from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteModal';

const initSelectedRowData: SeriesEnqueteRowData = {
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  divisionOrganizationId: '',
  destinationSbuCode: '',
  destinationSbuNameJpn: '',
  destinationSbuNameEng: '',
  destinationSbuOrganizationId: '',
  sourceSbuCode: '',
  sourceSbuNameJpn: '',
  sourceSbuNameEng: '',
  sourceSbuOrganizationId: '',
};

export interface ModalSearchConditionProps {
  type: 'autocomplete';
  id: string;
  label: string;
  style: {
    xs: number;
  };
  disabled: boolean;
  props: SearchConditionAutoCompleteProps;
}

export interface SearchConditionAutoCompleteProps {
  value: string | null;
  list: {
    key: string;
    label: string;
  }[]; // ドロップダウンリストに表示する候補情報
  onChange: (value: string | undefined) => void; // ドロップダウンリスト変更時のコールバック
}

// 検索用条件
export interface SearchConditionProps {
  divisionCode: string;
  sbuCode: string;
}

// 検索用条件初期値
export const initSearchCondition: SearchConditionProps = {
  divisionCode: '',
  sbuCode: '',
};

interface Props {
  targetData: SeriesEnqueteRowData | undefined;
  surveyDetailId: string;
}

// TODO useSeriesEnqueteCreateModalのhook処理がコード量が多いため、この処理を分けるリファクタリングを行う
export const useSeriesEnqueteCreateModal = (props: Props) => {
  const { targetData, surveyDetailId } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isJapanese } = useLocales();

  const labelPrefix = 'enqueteCreate.seriesEnquete';

  // 検索用リスト
  const { divisions, sbus, makeSbus } = useSearchOrganizationConditions({ surveyDetailId: surveyDetailId });
  const { closeEditModal, modalNormalMode } = useEnqueteModal();
  const { saveSurveyDetailData } = useEnqueteCreateGrid();
  const { concatAndSort, sortSeriesSurveyFormDataList } = useEnqueteCommon();

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isOpenEditModal = useAppSelector((state: RootState) => state.enqueteCreate.isOpenSbuAggregationModal);
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const isEditMode = useAppSelector((state: RootState) => state.enqueteCreate.isEditSbuAggregationModal);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [searchCondition, setSearchCondition] = useState<SearchConditionProps>(initSearchCondition);
  const [selectedModalData, setSelectedModalData] = useState<SeriesEnqueteRowData>(initSelectedRowData);
  const [selectedModalRowData, setSelectedModalRowData] = useState<SeriesSurveyFormAggregationData[]>([]);
  const [beforeEditModalData, setBeforeEditModalData] = useState<SeriesEnqueteRowData>(initSelectedRowData);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isEnableSelect, setIsEnableSelect] = useState<boolean>(false);

  /**
   * オートコンプリートコールバック
   * SBUを設定時は相関バリデーションを行い、バリデーションエラーの場合はスナックバーを表示し、値のセットを行わない
   * @param key セットされたパラメタのkey名
   * @param value セットされた値
   * @param list セグメント
   */
  const handleAutoCompleteChange = async (
    key: string,
    value: string | undefined,
    list: SearchOrganizationAdditionalConditionTypes[],
  ): Promise<void> => {
    // 表示用データ抽出
    const cond = searchCondition;
    const selectedSegment = list.filter((v) => v.key === value);
    switch (key) {
      case 'division':
        cond.divisionCode = value ? value : '';
        await makeSbus(cond.divisionCode ? cond.divisionCode : undefined);
        // モーダル用オブジェクトに格納
        setSelectedModalData({
          ...selectedModalData,
          ...{
            divisionCode: value ? value : '',
            divisionNameJpn: value && selectedSegment[0].label.jpn ? selectedSegment[0].label.jpn : '',
            divisionNameEng: value && selectedSegment[0].label.eng ? selectedSegment[0].label.eng : '',
            divisionOrganizationId: value && selectedSegment[0].id ? selectedSegment[0].id : '',
          },
        });
        break;
      case 'destination':
        cond.sbuCode = value ? value : '';
        // バリデーションチェック
        if (isDestinationValid(value)) {
          setIsEnableSelect(false);
          return;
        }
        // モーダル用オブジェクトに格納
        setSelectedModalData({
          ...selectedModalData,
          ...{
            destinationSbuCode: value ? value : '',
            destinationSbuNameJpn: value && selectedSegment[0].label.jpn ? selectedSegment[0].label.jpn : '',
            destinationSbuNameEng: value && selectedSegment[0].label.eng ? selectedSegment[0].label.eng : '',
            destinationSbuOrganizationId: value && selectedSegment[0].id ? selectedSegment[0].id : '',
          },
        });
        break;
      case 'source':
        cond.sbuCode = value ? value : '';
        // バリデーションチェック
        if (isSourceValid(value)) {
          setIsEnableSelect(false);
          return;
        }
        // モーダル用オブジェクトに格納
        setSelectedModalData({
          ...selectedModalData,
          ...{
            sourceSbuCode: value ? value : '',
            sourceSbuNameJpn: value && selectedSegment[0].label.jpn ? selectedSegment[0].label.jpn : '',
            sourceSbuNameEng: value && selectedSegment[0].label.eng ? selectedSegment[0].label.eng : '',
            sourceSbuOrganizationId: value && selectedSegment[0].id ? selectedSegment[0].id : '',
          },
        });
        break;
    }
  };

  /**
   * 集約先として設定された際のバリデーションを行う
   * @param sbuCode 入力パラメタ
   * @returns バリデーションエラー時 true
   */
  const isDestinationValid = (sbuCode: string | undefined): boolean => {
    if (!sbuCode) return false;
    // すでにモーダル内で他の集約元に設定しているか
    if (selectedModalData.sourceSbuCode === sbuCode) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t('enqueteCreate.seriesEnquete.source') }), {
        variant: 'warning',
      });
      return true;
    }
    // すでに追加データ内で他の集約元に設定しているか
    let validSbu = validAddData(selectedModalRowData, sbuCode, false);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    // すでに登録データ内で他の集約元に設定しているか
    validSbu = validAddData(aggregationList, sbuCode, false);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceRegistered', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    return false;
  };

  /**
   * 集約もととして設定された際のバリデーションを行う
   * @param sbuCode 入力パラメタ
   * @returns バリデーションエラー時 true
   */
  const isSourceValid = (sbuCode: string | undefined): boolean => {
    if (!sbuCode) return false;
    // すでにモーダル内で集約先で設定しているか
    if (selectedModalData.destinationSbuCode === sbuCode) {
      enqueueSnackbar(
        t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t('enqueteCreate.seriesEnquete.destination') }),
        {
          variant: 'warning',
        },
      );
      return true;
    }
    // すでに追加データで集約元に設定していないか
    let validSbu = validAddData(selectedModalRowData, sbuCode, false);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];

    // すでに登録済みデータで集約元に設定していないか
    validSbu = validAddData(aggregationList, sbuCode);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceRegistered', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    return false;
  };

  /**
   * SBU集約情報にSBUCodeが存在するかチェックを行い存在する場合は、存在する集約元、集約先の文字列を返却する
   * @param rows SBU集約情報
   * @param sbuCode 存在チェックを行うSBUCode
   * @returns SBU集約情報にSBUCodeが存在しない場合 undefined 存在する場合は、存在する集約元、集約先の文字列を返却
   */
  const validAddData = (rows: SeriesSurveyFormAggregationData[], sbuCode: string, isSource: boolean = true) => {
    // すでに集約先で設定されているか、確認
    let result: string | undefined = undefined;
    for (const row of rows) {
      for (const sbu of row.sbu) {
        if (sbu.destination.sbuCode === sbuCode && isSource) {
          // 集約元の判定の時のみ、集約先に設定されているかチェック
          result = 'enqueteCreate.seriesEnquete.destination';
          break;
        }
        // すでに集約元で設定されているか、確認
        for (const source of sbu.source) {
          if (source.sbuCode === sbuCode) {
            result = 'enqueteCreate.seriesEnquete.source';
            break;
          }
        }
      }
      if (result) {
        break;
      }
    }
    return result;
  };

  // AutoComplete構成内容定義
  const makeSearchConditionProp = (
    key: string,
    style: { xs: number },
    value: string | undefined | null,
    multiLabelList: SearchOrganizationAdditionalConditionTypes[],
    isDisable: boolean = false,
  ): ModalSearchConditionProps => {
    const selectedValue = value === undefined ? null : value;
    const list = multiLabelList.map((v) => {
      return {
        ...v,
        ...{
          label: addSuffix(switchLabel(v.label.jpn, v.label.eng, isJapanese), v.key),
        },
      };
    });

    return {
      id: key,
      label: t(`${labelPrefix}.${key}`),
      style,
      type: 'autocomplete',
      props: {
        value: selectedValue,
        list,
        onChange: (value) => {
          handleAutoCompleteChange(key, value, multiLabelList);
        },
      },
      disabled: isDisable,
    };
  };

  // 組織検索要素定義
  const conditions: ModalSearchConditionProps[][] = [
    [
      makeSearchConditionProp('division', { xs: 12 }, selectedModalData.divisionCode, divisions, isEditMode),
      makeSearchConditionProp('destination', { xs: 6 }, selectedModalData.destinationSbuCode, sbus, isEditMode),
      makeSearchConditionProp('source', { xs: 6 }, selectedModalData.sourceSbuCode, sbus),
    ],
  ];

  /**
   * 登録イベント処理
   * モーダルの追加データを登録データを合わせ、APIをコールする
   */
  const onRegister = async () => {
    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    const questionDescriptionJpn = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionJpn || '';
    const questionDescriptionEng = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionEng || '';

    let list = _.cloneDeep(aggregationList);

    selectedModalRowData.forEach((data) => {
      list = concatAndSort(list, data);
    });

    const surveyFormData = {
      enqueteType: '',
      formData: {
        pastRespondentRequestId: '',
        tabData: [
          {
            questionDescriptionJpn: questionDescriptionJpn ?? '',
            questionDescriptionEng: questionDescriptionEng ?? '',
            aggregationList: list,
          },
        ],
      },
    };
    const saveEnquete: SurveyDetailEntity = {
      ...survayDetail,
      surveyFormData: surveyFormData,
    };

    const result = await saveSurveyDetailData(saveEnquete);
    if (result) {
      const surveyDetail = {
        ...saveEnquete,
        updatedAt: (result.data as SurveyDetailEntity).updatedAt,
      };
      dispatch(setEnqueteData({ ...enqueteData, surveyDetail }));
    }

    closeEditModal();
  };

  const onUpdate = async (): Promise<void> => {
    // 更新前と更新後の値が同じかチェック
    if (selectedModalData.sourceSbuCode === beforeEditModalData.sourceSbuCode) {
      // APIはコールせずモーダルを閉じる
      enqueueSnackbar(t('message.save'), { variant: 'success' });
      closeEditModal();
      return;
    }

    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    const questionDescriptionJpn = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionJpn || '';
    const questionDescriptionEng = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionEng || '';

    // 登録済みデータと重複チェック
    if (isDuplication(selectedModalData, aggregationList)) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadyRegistered'), { variant: 'warning' });
      return;
    }
    // 登録データをコピーする
    const newAggregationList: SeriesSurveyFormAggregationData[] = _.cloneDeep(aggregationList);
    // 変更前SBU集約先を削除し、変更SBU集約先を設定する
    for (const aggregation of newAggregationList) {
      if (aggregation.divisionCode === selectedModalData.divisionCode) {
        for (const sbu of aggregation.sbu) {
          if (sbu.destination.sbuCode === selectedModalData.destinationSbuCode) {
            for (const [i, source] of sbu.source.entries()) {
              if (source.sbuCode === beforeEditModalData.sourceSbuCode) {
                // 変更前のものを削除
                sbu.source.splice(i, 1);
                // 変更後を追加
                sbu.source.push({
                  sbuCode: selectedModalData.sourceSbuCode,
                  sbuNameJpn: selectedModalData.sourceSbuNameJpn,
                  sbuNameEng: selectedModalData.sourceSbuNameEng,
                  organizationId: selectedModalData.sourceSbuOrganizationId,
                });
              }
            }
            break;
          }
        }
        break;
      }
    }
    const surveyFormData = {
      enqueteType: '',
      formData: {
        pastRespondentRequestId: '',
        tabData: [
          {
            questionDescriptionJpn: questionDescriptionJpn,
            questionDescriptionEng: questionDescriptionEng,
            aggregationList: newAggregationList,
          },
        ],
      },
    };
    const saveEnquete: SurveyDetailEntity = {
      ...survayDetail,
      surveyFormData: surveyFormData,
    };

    const result = await saveSurveyDetailData(saveEnquete);
    if (result) {
      const surveyDetail = {
        ...saveEnquete,
        updatedAt: (result.data as SurveyDetailEntity).updatedAt,
      };
      dispatch(setEnqueteData({ ...enqueteData, surveyDetail }));
    }

    closeEditModal();
  };

  /**
   * モーダル内の追加ボタン押下時のイベント定義
   */
  const onSelected = () => {
    // 選択リストに追加
    addRowData();
  };

  /**
   * モーダルで選択したデータの整合性を確認し、正常であればモーダル追加リストに選択データを追加する
   * 整合性でエラーが出た場合はスナックバーを表示する
   */
  const addRowData = (): void => {
    const aggregationList = (enqueteData.surveyDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    // 選択済みデータと重複チェック
    if (isDuplication(selectedModalData, selectedModalRowData)) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySelected'), { variant: 'warning' });
      return;
    }
    // 登録済みデータと重複チェック
    if (isDuplication(selectedModalData, aggregationList)) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadyRegistered'), { variant: 'warning' });
      return;
    }
    // モーダルの追加データがなにもない場合
    if (selectedModalRowData.length === 0) {
      saveSelectedModalRowData(concatAndSort(selectedModalRowData, createData(selectedModalData)));
    } else {
      let isEdit = false;
      const data: SeriesSurveyFormAggregationData[] = [...selectedModalRowData];
      data.forEach((row) => {
        if (row.divisionCode === selectedModalData.divisionCode) {
          row.sbu.forEach((s) => {
            if (s.destination.sbuCode === selectedModalData.destinationSbuCode) {
              // 集約元だけ追加の場合
              s.source.push({
                sbuCode: selectedModalData.sourceSbuCode,
                sbuNameJpn: selectedModalData.sourceSbuNameJpn,
                sbuNameEng: selectedModalData.sourceSbuNameEng,
                organizationId: selectedModalData.sourceSbuOrganizationId,
              });
              isEdit = true;
            }
          });
          if (!isEdit) {
            // 集約先、集約元の追加の場合
            row.sbu.push({
              destination: {
                sbuCode: selectedModalData.destinationSbuCode,
                sbuNameJpn: selectedModalData.destinationSbuNameJpn,
                sbuNameEng: selectedModalData.destinationSbuNameEng,
                organizationId: selectedModalData.destinationSbuOrganizationId,
              },
              source: [
                {
                  sbuCode: selectedModalData.sourceSbuCode,
                  sbuNameJpn: selectedModalData.sourceSbuNameJpn,
                  sbuNameEng: selectedModalData.sourceSbuNameEng,
                  organizationId: selectedModalData.sourceSbuOrganizationId,
                },
              ],
            });
            isEdit = true;
          }
          saveSelectedModalRowData(sortSeriesSurveyFormDataList(data));
        }
        if (!isEdit) {
          // 本部が存在しないため新規追加
          saveSelectedModalRowData(concatAndSort(selectedModalRowData, createData(selectedModalData)));
        }
      });
    }
  };

  /**
   * 重複チェックを行う
   * @param aggregationData 重複チェック対象データ
   * @param aggregationDataList 重複確認を行うデータリスト
   * @returns 重複がある場合 true
   */
  const isDuplication = (aggregationData: SeriesEnqueteRowData, aggregationDataList: SeriesSurveyFormAggregationData[]) => {
    let isDuplication = false;
    aggregationDataList
      .filter((data) => data.divisionCode === aggregationData.divisionCode)
      .filter(({ sbu }) =>
        sbu
          .filter(({ destination }) => aggregationData.destinationSbuCode === destination.sbuCode)
          .forEach(({ source }) => (isDuplication = source.some((v) => v.sbuCode === aggregationData.sourceSbuCode))),
      );
    return isDuplication;
  };

  /**
   * モーダルデータを、DB保存用データに加工する
   * @param data モーダルデータ
   * @returns DB保存用データ
   */
  const createData = (data: SeriesEnqueteRowData) => {
    return {
      divisionCode: data.divisionCode,
      divisionNameJpn: data.divisionNameJpn,
      divisionNameEng: data.divisionNameEng,
      organizationId: data.divisionOrganizationId,
      sbu: [
        {
          destination: {
            sbuCode: data.destinationSbuCode,
            sbuNameJpn: data.destinationSbuNameJpn,
            sbuNameEng: data.destinationSbuNameEng,
            organizationId: data.destinationSbuOrganizationId,
          },
          source: [
            {
              sbuCode: data.sourceSbuCode,
              sbuNameJpn: data.sourceSbuNameJpn,
              sbuNameEng: data.sourceSbuNameEng,
              organizationId: data.sourceSbuOrganizationId,
            },
          ],
        },
      ],
    };
  };

  /**
   * モーダル内で追加したSBU集約情報の行を削除する
   * @param data 削除データ
   */
  const deleteModalRaw = (data: SeriesEnqueteRowData): void => {
    // 削除されない本部に紐づくデータの確認
    const noDeleteSbuInfo = selectedModalRowData.filter((v) => v.divisionCode !== data.divisionCode);
    const deleteSbuInfo: SeriesSurveyFormAggregationData | undefined = selectedModalRowData.find((v) => v.divisionCode === data.divisionCode);
    if (!deleteSbuInfo) return;

    const sbuInfo: SeriesAggregationSbuInfo[] = [];
    for (const sbu of deleteSbuInfo.sbu) {
      const sourceList: SeriesAggregationData[] = [];

      if (sbu.destination.sbuCode === data.destinationSbuCode) {
        // 削除する集約先の場合
        for (const source of sbu.source) {
          if (source.sbuCode !== data.sourceSbuCode) {
            sourceList.push(source); // // 削除されない集約元のみ追加
          }
        }
        // 削除しない集約元が存在する場合は集約先と集約元を追加
        if (sourceList.length > 0) {
          const destination = {
            sbuCode: sbu.destination.sbuCode,
            sbuNameJpn: sbu.destination.sbuCode,
            sbuNameEng: sbu.destination.sbuCode,
            organizationId: sbu.destination.organizationId,
          };
          sbuInfo.push({ destination: destination, source: sourceList });
        }
      } else {
        // 削除されない集約先であればそのまま追加
        sbuInfo.push(sbu);
      }
    }
    // 削除後の情報に集約先と集約元がなければ本部ごと削除
    if (sbuInfo.length === 0) {
      saveSelectedModalRowData(sortSeriesSurveyFormDataList([...noDeleteSbuInfo]));
      return;
    }

    const deletedAggregationData: SeriesSurveyFormAggregationData = {
      divisionCode: deleteSbuInfo.divisionCode,
      divisionNameJpn: deleteSbuInfo.divisionNameJpn,
      divisionNameEng: deleteSbuInfo.divisionNameEng,
      organizationId: selectedModalData.divisionOrganizationId,
      sbu: sbuInfo,
    };

    saveSelectedModalRowData(sortSeriesSurveyFormDataList([...noDeleteSbuInfo, deletedAggregationData]));
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  // TODO 以下のuseEffectはモーダルクローズイベント内に定義を行うリファクタリングを行う
  // モーダルクローズ処理
  useEffect(() => {
    if (isOpenEditModal) return;
    resetState();
  }, [isOpenEditModal]); // eslint-disable-line

  /**
   * 設定された値の初期化を行う
   */
  const resetState = () => {
    const createSbu = async () => {
      await makeSbus();
    };
    createSbu();
    setSearchCondition(initSearchCondition);
    saveSelectedModalRowData([]);
    setSelectedModalData(initSelectedRowData);
    setIsEnableSelect(false);
    modalNormalMode();
    setBeforeEditModalData(initSelectedRowData);
  };

  // TODO 以下のuseEffectは編集押下イベント内に定義を行うリファクタリングを行う
  // 編集押下時のバインド
  useEffect(() => {
    if (!targetData || !isEditMode) return;
    const createSbu = async () => {
      // モダールのオートコンプリートを設定する
      await makeSbus(targetData.divisionCode);
    };
    createSbu();
    setSelectedModalData(targetData);
    setBeforeEditModalData(targetData);
  }, [isEditMode]); // eslint-disable-line

  // TODO 以下のuseEffectはselectedModalDataの追加/削除時の処理にに定義を行うリファクタリングを行う
  // 未選択バリデーション
  useEffect(() => {
    checkSelectedValue();
  }, [selectedModalData]); // eslint-disable-line

  // 追加On/Off
  const checkSelectedValue = () => {
    // モーダルにすべての値がセットされていること
    const isEnable = !!selectedModalData.divisionCode && !!selectedModalData.destinationSbuCode && !!selectedModalData.sourceSbuCode;
    setIsEnableSelect(isEnable);
  };

  /**
   * モーダルデータを追加時のラッパー
   * Stateにsetし、保存ボタンの活性制御を行う
   * @param selectedModalRowData
   */
  const saveSelectedModalRowData = (selectedModalRowData: SeriesSurveyFormAggregationData[]) => {
    setSelectedModalRowData(selectedModalRowData);
    setIsSave(selectedModalRowData.length > 0);
  };

  return {
    selectedModalRowData,
    isSave,
    isEnableSelect,
    conditions,
    onRegister,
    onUpdate,
    onSelected,
    deleteModalRaw,
  };
};
