import { DateTime } from 'luxon';
import { CreatedSurveyEntity } from 'src/api/useCreatedSurvey';
import { timeResetDateTimeFormat } from 'src/utils/formatDateTime';

export const setDuplicateEnqueteData = (sourceEnqueteData?: CreatedSurveyEntity) => {
  if (!sourceEnqueteData) return;
  return {
    respondentRequestId: sourceEnqueteData.id,
    surveyDetailId: sourceEnqueteData.surveyDetailId,
    disclosureLevel: sourceEnqueteData.disclosureLevel ?? null,
    titleJpn: sourceEnqueteData.titleJpn + ' - Copy',
    titleEng: sourceEnqueteData.titleEng + ' - Copy',
    targetDate: sourceEnqueteData.targetDate,
    openedAt: DateTime.local().plus({ days: 30 }).toFormat(timeResetDateTimeFormat),
    closedAt: DateTime.local().plus({ days: 60 }).toFormat(timeResetDateTimeFormat),
    labelJpn: sourceEnqueteData.labelJpn,
    labelEng: sourceEnqueteData.labelEng,
    type: sourceEnqueteData.type,
    formType: sourceEnqueteData.formType,
  };
};
