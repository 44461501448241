import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { NotificationReRegisterLayout } from '../components/pages/re-register/components/NotificationReRegisterLayout';

export const NotificationReRegisterPage = () => {
  const { t } = useTranslation();
  const pageTitle = t('notifications.reRegisterPage.pageTitle');

  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('notifications.reRegisterPage.pageTitle'),
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('notifications.editListPage.pageTitle'), href: '/manage/notification/edit' },
    ],
    activeLast: true,
  };

  return <NotificationReRegisterLayout pageTitle={pageTitle} breadCrumbs={breadcrumbs} />;
};
