import { OverviewEnquete } from 'src/@types/overviewEnquete';
import { useAppSelector } from 'src/redux/store';

export const useCreateOverviewAnswerData = () => {
  const { surveyDetailData, enqueteAnswerData } = useAppSelector((state) => state.enqueteAnswer);
  const answerOverviewEnquete = enqueteAnswerData.answerData?.answerInputData?.tabData[0] as OverviewEnquete;
  const overviewEnquete = answerOverviewEnquete ? answerOverviewEnquete : (surveyDetailData.surveyFormData.formData.tabData[0] as OverviewEnquete);

  // 回答保存時のデータを作成
  const {
    purchaseFixedAmount,
    purchaseFixedQuestions,
    salesFixedAmount,
    salesFixedQuestions,
    otherFixedTopic,
    purchaseAdditionalQuestions,
    salesAdditionalQuestions,
    otherAdditionalQuestions,
  } = useAppSelector((state) => state.enqueteOverviewAnswer);

  return {
    questionDescriptionJpn: overviewEnquete.questionDescriptionJpn,
    questionDescriptionEng: overviewEnquete.questionDescriptionEng,
    customerCode: overviewEnquete.customerCode,
    customerNameJpn: overviewEnquete.customerNameJpn,
    customerNameEng: overviewEnquete.customerNameEng,
    parentCustomerCode: overviewEnquete.parentCustomerCode,
    parentCustomerNameJpn: overviewEnquete.parentCustomerNameJpn,
    parentCustomerNameEng: overviewEnquete.parentCustomerNameEng,
    purchaseFixedAmount: purchaseFixedAmount,
    purchaseFixedQuestions: purchaseFixedQuestions,
    salesFixedAmount: salesFixedAmount,
    salesFixedQuestions: salesFixedQuestions,
    otherFixedTopic: otherFixedTopic,
    purchaseAdditionalQuestions: purchaseAdditionalQuestions,
    salesAdditionalQuestions: salesAdditionalQuestions,
    otherAdditionalQuestions: otherAdditionalQuestions,
  } as OverviewEnquete;
};
