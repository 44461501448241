import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { portalApi } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface JobTitleEntity {
  jobTitleCode: string;
  jobTitleNameJpn: string;
  jobTitleNameEng: string;
}

interface PortalJobTitleEntity {
  company_s_cd: string;
  group_company_title_cd: string;
  group_company_title_name: string;
  group_company_title_eng_name: string;
}

// getByFilter で指定可能な検索条件
interface SearchCondition {
  companyCode?: string;
}

const BASE_URL = `${portalApi}/master/jobTitle`;

// memo: JobTitleMaster にはS500以外の職位情報が含まれるため、利用時は注意
export const useJobTitleApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  const getByFilter = async (condition: SearchCondition) => {
    const where = { company_s_cd: condition.companyCode };
    try {
      const token = await getBearerToken();
      const result = await axios.get<PortalJobTitleEntity[]>(BASE_URL, {
        headers: { Authorization: token },
        params: { filter: { where: where, orderBy: [{ group_company_title_cd: 'asc' }] } },
      });

      return result.data.map<JobTitleEntity>((master) => ({
        jobTitleCode: master.group_company_title_cd,
        jobTitleNameJpn: master.group_company_title_name,
        jobTitleNameEng: master.group_company_title_eng_name,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          enqueueSnackbar(t('message.notfound', { data: t('master.jobTitle') }), { variant: 'warning', autoHideDuration: 2000 });
          return;
        }
        enqueueSnackbar(t('apiExceptionMessage.useJobTitleApi.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getByFilter };
};
