import { Container, Grid } from '@material-ui/core';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
//ユーザのロールを確認し、ページを表示するかどうか決める
export const enqueteRequestHistoryRoute = {
  path: 'enquete-request',
  children: [
    {
      path: '',
      element: (
        <Page title="アンケート依頼履歴">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <HeaderBreadcrumbs
                  heading="アンケート依頼履歴"
                  links={[
                    // { name: '一般', href: '/general' },
                    { name: 'アンケート依頼履歴', href: '/general/enquete-request' },
                  ]}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      ),
    },
  ],
};
