import { Autocomplete, AutocompleteInputChangeReason, Box, Button, Grid, Modal, Stack, SxProps, TextField, Typography } from '@mui/material';
import { ColDef, ColGroupDef, ColumnGroupOpenedEvent, GetContextMenuItems, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import { useTranslation } from 'react-i18next';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';
import CloseButton from '../CloseButton';
import { StyledAgGrid } from '../StyledAgGrid';

export interface ConditionProps {
  // 条件としてのラベル
  label: string;
  // 現在選択されている値
  value: string | null;
  // ドロップダウンリストに表示する候補情報
  list: string[];
  // 表示するかどうかのフラグ
  display: boolean;
  // ドロップダウンリスト変更時のコールバック
  onChange: (value: string | null) => void;
  // 値入力時のコールバック
  onInputChange: (value: string | null, reason: AutocompleteInputChangeReason) => void;
}

export interface TableProps<T> {
  // カラム定義
  columnDefs?: (ColDef | ColGroupDef)[] | null | undefined;
  // 行データ群
  rowData?: T[] | null;

  // グリッドの高さ
  height?: string;

  // 行のクリック時の動作
  onRowClicked?: (e: RowClickedEvent) => void;
  // ヘッダーグループの開閉時の動作
  onColumnGroupOpened?: (e: ColumnGroupOpenedEvent) => void;
  // グリッドの準備完了した時の動作
  onGridReady?: (params: GridReadyEvent) => void;
  // マルチ選択
  rowSelection?: 'multiple' | 'single' | undefined;
  // コンテキストメニュー
  contextMenu?: GetContextMenuItems | undefined;

  // 上書きコンポーネント
  component?: React.ReactNode;
}

export interface Props<T> {
  // モーダル自体のタイトル
  title: string;
  // モーダルの表示/非表示フラグ
  open: boolean;
  // モーダルの検索条件部
  conditions: ConditionProps[];
  // グリッドのColDef
  table: TableProps<T>;

  // 追加ボタンが押された時のコールバック
  onAdd?: () => void;
  // OKボタンが押された時のコールバック
  onOk?: () => void;
  // モーダルの枠外が押された時のコールバック
  // または、Closeボタンがある場合はCloseボタンが押された時のコールバック
  onClose: () => void;
  // Deleteボタンが押された時のコールバック
  onDelete?: () => void;
  // モーダルのstyle
  modalStyleSxProps?: SxProps;
}

export function SelectModal<T extends {}>(props: Props<T>) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { t } = useTranslation();

  // モーダルスタイルの定義
  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80vw',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  // グリッドコンテナスタイルの定義
  const girdStyle: SxProps = {
    pt: 1.5,
  };

  // グリッドスタイルの定義
  const tableStyle: SxProps = {
    height: props.table.height ?? '50vh',
    width: '100%',
    transition: 'height 0.5s',
  };

  // デフォルトのカラム設定
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    cellStyle: { border: 'none', backgroundColor: 'inherit' },
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box component={'div'} sx={{ ...modalStyle, ...props.modalStyleSxProps }}>
        {/* modal title */}
        <Typography variant="h4" component={'h2'}>
          {props.title}
        </Typography>

        {/* conditions */}
        <Grid container component={'div'} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            {props.conditions.map((condition, index) =>
              condition.display ? (
                <Grid container key={index} sx={{ mt: 1, mr: 1 }}>
                  <Grid item xs={11}>
                    <Autocomplete
                      size="small"
                      options={condition.list}
                      filterOptions={(option) => option}
                      onChange={(e, v) => condition.onChange(v)}
                      onInputChange={(e, v, r) => condition.onInputChange(v, r)}
                      renderInput={(params) => <TextField {...params} label={condition.label} />}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {index === props.conditions.length - 1 && (
                      <Stack direction={'row-reverse'} sx={{ mt: 0.25 }}>
                        <Button variant="contained" onClick={props.onAdd}>
                          {t('button.add')}
                        </Button>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              ) : null,
            )}
          </Grid>
        </Grid>

        {/* modal grid table */}
        <Grid container sx={girdStyle}>
          <Grid item xs={12}>
            <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={tableStyle} data-testid={'aggrid'}>
              {props.table.component ?? (
                <StyledAgGrid
                  defaultColDef={defaultColDef}
                  coldef={props.table.columnDefs}
                  rows={props.table.rowData}
                  onRowClickedCallback={props.table.onRowClicked}
                  rowSelection={props.table.rowSelection}
                  contextMenu={props.table.contextMenu}
                  onGridReady={props.table.onGridReady}
                  onColumnGroupOpened={props.table.onColumnGroupOpened}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* modal bottom actions */}
        <Grid container sx={girdStyle}>
          <Grid item xs={6} justifyContent={'left'}>
            <Stack direction={'row'} spacing={1}>
              {props.onClose && <CloseButton onClose={props.onClose} color="primary" />}
              {/* <Button variant="outlined" onClick={props.onDelete} color={'error'}>
                Delete
              </Button> */}
            </Stack>
          </Grid>
          {props.onOk && (
            <Grid item xs={6}>
              <Stack direction={'row-reverse'}>
                <Button variant="contained" onClick={props.onOk} disabled={!props.table.rowData || props.table.rowData.length === 0}>
                  OK
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}
