import { ColDef, ColGroupDef } from 'ag-grid-community';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { useUserAgGridColDef } from './useUserAgGridColDef';

export const useSelectUserAgGrid = () => {
  const { defaultColDef, userAgGridColDef } = useUserAgGridColDef({
    companyCodeColName: 'companyCode',
    emailColDefSetting: { key: 'email' },
    fullNameColDefSetting: { key: 'fullName' },
    companyNameColDefSetting: { key: 'companyName' },
    companyAbbreviationColDefSetting: { key: 'companyAbbreviation' },
    assignedCompanyColDefSetting: { key: 'assignedCompanyName' },
    divisionNameColDefSetting: { key: 'divisionName' },
    sbuNameColDefSetting: { key: 'sbuName' },
    departmentNameColDefSetting: { key: 'departmentName' },
    groupNameColDefSetting: { key: 'groupName' },
  });

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'serialNumber',
      tooltipField: 'serialNumber',
      headerName: 'No.',
      valueGetter: 'node.rowIndex + 1',
      minWidth: 30,
      width: 70,
      suppressMenu: true,
      flex: 0,
    },
    ...userAgGridColDef,
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];
  return { defaultColDef, columnDefs };
};
