import { useTranslation } from 'react-i18next';

export const useOverviewRoutes = () => {
  const { t } = useTranslation();
  const generalRoutes = {
    REQUEST: {
      title: t('features.general.request.title'),
      description: t('features.general.request.description'),
      url: '/general/enquete-request',
    },
    ANSWER: {
      title: t('features.general.answer.title'),
      description: t('features.general.answer.description'),
      url: '/general/enquete-answer',
    },
    CONFIRM: {
      title: t('features.general.confirm.title'),
      description: t('features.general.confirm.description'),
      url: '/general/enquete-confirm',
    },
    THREADS: {
      title: t('features.general.threads.title'),
      description: t('features.general.threads.description'),
      url: '/general/enquete-threads',
    },
  };
  const manageRoutes = {
    COMPLEMENTARY_ORGANIZATION: {
      title: t('features.manage.complementaryOrganization.title'),
      description: t('features.manage.complementaryOrganization.description'),
      url: '/manage/complementary-organization',
    },
    NOTIFICATION: {
      title: t('features.manage.notification.title'),
      description: t('features.manage.notification.description'),
      url: '/manage/notification/edit',
    },
    CUSTOMER_MANAGEMENT: {
      title: t('features.manage.customerManagement.title'),
      description: t('features.manage.customerManagement.description'),
      url: '/manage/customer-management',
    },
  };
  return { generalRoutes, manageRoutes };
};
