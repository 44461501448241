import { GridApi, GridReadyEvent, ValueFormatterFunc, ValueGetterParams } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { PartialCustomerData } from 'src/api/useCustomerApi';
import { GridProps } from 'src/components/app-components/bonsai/SearchModal';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { switchLabel } from 'src/utils/labelUtils';

interface Props {
  orgs: PartialCustomerData[];
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  getSelectedRowCustomerNames: () => string[];
  gridApi: MutableRefObject<GridApi<any> | null>;
}

export const useSearchCustomerGridSettings = (props: Props) => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const { orgs, setIsSelected, getSelectedRowCustomerNames, gridApi } = props;

  // ダイアログ内の Grid 定義を構築
  const isJapanese = currentLang.value === 'ja';
  const headerNameKey = 'common';
  const makeColDefIncludeLangSwitch = (key: string, minWidth: number, valueFormatter?: string | ValueFormatterFunc) => {
    return {
      field: isJapanese ? `${key}NameJpn` : `${key}NameEng`,
      headerName: t(`${headerNameKey}.${key}`),
      minWidth: minWidth,
      valueFormatter,
      valueGetter: (params: ValueGetterParams) => {
        return switchLabel(params.data[`${key}NameJpn`], params.data[`${key}NameEng`], isJapanese);
      },
    };
  };
  const coldefLangSwitch = (key: string, minWidth: number, headerKey: string = headerNameKey) => {
    return {
      field: key,
      headerName: t(`${headerKey}.${key}`),
      minWidth: minWidth,
    };
  };
  const coldefHide = (key: string) => {
    return {
      field: key,
      headerName: t(`${headerNameKey}.${key}`),
      hide: true,
    };
  };
  const customerNameFormatter = (params: any): string => (isJapanese ? trimStockCompany(params.value) : params.value);

  const grid: GridProps<PartialCustomerData> = {
    colDefs: [
      coldefLangSwitch('customerCode', 200, 'enqueteAnswerInput.modal.selectCustomer.condition'),
      makeColDefIncludeLangSwitch('customer', 250, customerNameFormatter),
      makeColDefIncludeLangSwitch('parentCustomer', 250, customerNameFormatter),
      makeColDefIncludeLangSwitch('region', 100),
      coldefHide('regionCode'),
      makeColDefIncludeLangSwitch('country', 100),
      coldefHide('countryCode'),
      coldefLangSwitch('address', 250, 'enqueteAnswerInput.modal.selectCustomer.condition'),
      coldefLangSwitch('entryDate', 250, 'enqueteAnswerInput.modal.selectCustomer.condition'),
    ],
    rowData: orgs,
    onGridReady: (params: GridReadyEvent) => {
      gridApi.current = params.api;
    },
    onSelectionChanged: () => {
      setIsSelected(!!getSelectedRowCustomerNames().length);
    },
  };
  return { grid };
};
