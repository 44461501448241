import { Box, Grid, Modal, SxProps, Typography } from '@mui/material';
import { ColDef, ColGroupDef, ColumnState, GridReadyEvent, HeaderCheckboxSelectionCallbackParams, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback } from 'react';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { LIST_STATE_KEY_PREFIX } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';
import CloseButton from '../CloseButton';
import { savedColumnStateOnLocalStorage } from '../StyledAgGrid';
import { StyledBox } from '../StyledBox';
import { StyledButton } from '../StyledButton';
import { SearchConditionProps, SearchModalExtendedForm } from './SearchModalExtendedForm';

export interface GridProps<T> {
  colDefs: (ColDef | ColGroupDef)[] | null | undefined;
  rowData: T[] | null;
  onGridReady: (params: GridReadyEvent) => void;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
}

interface Props<T> {
  title: string;
  onClose: () => void;
  buttons: {
    onclick: () => void;
    disabled?: boolean;
    content?: JSX.Element;
    color: 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  }[];
  // memo: 検索条件を格納、二次元配列として定義し、画面描画時に横並びに配置される
  conditions: SearchConditionProps[][];
  searchResultGrid: GridProps<T>;
  selectedTargetGrid: GridProps<T>;
  setSelectedDataOnSearchResult: (selectedData: SelectionChangedEvent<OrganizationEntity>) => void;
  updateSelectedDataOnSearchResult: () => void;
  setIsSelectedDataOnSelectedTarget: (value: boolean) => void;
}

export const SearchModalExtended = <T,>(props: Props<T>) => {
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const storageKey = 'SearchModalExtended';

  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const setColumnState = useCallback(() => {
    const columnStatesString = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.COLUMN}${storageKey}`);
    if (columnStatesString === null) {
      return props.searchResultGrid.colDefs;
    }
    const columnStates = JSON.parse(columnStatesString);
    if (!columnStates || columnStates.length === 0) {
      return props.searchResultGrid.colDefs;
    }

    const updatedColumnStates = columnStates.map((columnState: any) => {
      const suffix = isLangJpn ? 'Jpn' : 'Eng';
      let colId = columnState.colId;
      if (columnState.colId?.endsWith('Eng') || columnState.colId?.endsWith('Jpn')) {
        colId = columnState.colId.substring(0, columnState.colId.length - 3) + suffix;
      }
      return { ...columnState, colId };
    });

    const newColDefs = updatedColumnStates.map((columnState: ColumnState) => {
      const updatedColdef = props?.searchResultGrid?.colDefs?.find((colDef: ColDef) => colDef.field === columnState.colId);
      return {
        ...columnState,
        ...updatedColdef,
      };
    });
    return newColDefs;
  }, [isLangJpn, props.searchResultGrid.colDefs]);

  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80%',
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll',
    maxHeight: '90%',
  };

  const gridStyle: SxProps = {
    height: '50vh',
    width: '100%',
    transition: 'height 0.5s',
  };

  const isFirstColumn = (params: HeaderCheckboxSelectionCallbackParams) => {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    editable: false,
    filter: true,
    headerCheckboxSelection: isFirstColumn,
    checkboxSelection: isFirstColumn,
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      {/* モーダルのオープンは上位コンポーネントで制御する */}
        <Box component={'div'} sx={modalStyle}>
          {/* modal title */}
          <Typography variant="h4" component={'h2'} sx={{ mb: 2 }}>
            {props.title}
          </Typography>

          {/* conditions */}
          <StyledBox>
            <SearchModalExtendedForm conditions={props.conditions} />
            {/* modal grid table */}
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={gridStyle}>
                <AgGridReact
                  defaultColDef={defaultColDef}
                  columnDefs={setColumnState()}
                  rowData={props.searchResultGrid.rowData}
                  rowSelection={'multiple'}
                  rowMultiSelectWithClick={true}
                  onGridReady={props.searchResultGrid.onGridReady}
                  onColumnResized={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onColumnMoved={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onColumnVisible={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onSortChanged={(event) => {
                    savedColumnStateOnLocalStorage(event, storageKey);
                  }}
                  onSelectionChanged={(event) => {
                    props.setSelectedDataOnSearchResult(event);
                  }}
                  onRowDataUpdated={props.updateSelectedDataOnSearchResult}
                />
              </Box>
            </Grid>
          </StyledBox>
          <StyledBox>
            <Grid container sx={{ pt: 1.5 }}>
              <Grid item xs={12}>
                <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={gridStyle}>
                  <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={setColumnState()}
                    rowData={props.selectedTargetGrid.rowData}
                    rowSelection={'multiple'}
                    rowMultiSelectWithClick={true}
                    onGridReady={props.selectedTargetGrid.onGridReady}
                    onColumnResized={(event) => {
                      savedColumnStateOnLocalStorage(event, storageKey);
                    }}
                    onColumnMoved={(event) => {
                      savedColumnStateOnLocalStorage(event, storageKey);
                    }}
                    onColumnVisible={(event) => {
                      savedColumnStateOnLocalStorage(event, storageKey);
                    }}
                    onSortChanged={(event) => {
                      savedColumnStateOnLocalStorage(event, storageKey);
                    }}
                    onSelectionChanged={(event) => {
                      if (event.api.getSelectedRows().length > 0) {
                        props.setIsSelectedDataOnSelectedTarget(true);
                      } else {
                        props.setIsSelectedDataOnSelectedTarget(false);
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* modal bottom actions */}
            <Grid container mt={2}>
              <Grid item xs={6}>
                <CloseButton onClose={props.onClose} color="primary" />
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                {props.buttons.map((button, index) => (
                  <StyledButton key={index} variant="contained" color={button.color} onClick={button.onclick} isDisabled={button.disabled}>
                    {button.content}
                  </StyledButton>
                ))}
              </Grid>
            </Grid>
          </StyledBox>
        </Box>
    </Modal>
  );
};
