import { GridApi } from 'ag-grid-community';
import React, { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchModal } from 'src/components/app-components/bonsai/SearchModal';
import Label from 'src/components/Label';
import { useGetSelectedRow } from '../hooks/useGetSelectedRow';
import { useSearchConditionList } from '../hooks/useSearchConditionList';
import { useSearchCustomerGridSettings } from '../hooks/useSearchCustomerGridSettings';
import { useSearchCustomer } from '../hooks/useSearchCustomerMaster';
import { WarningDescription } from './WarningDescription';

// modalのプルダウンの表示条件
export interface SearchListCondition {
  parentCustomerCondition: string[];
}

// 取引先一覧の表示条件
export interface ListCondition {
  gridList?: string[];
  excludeCondition?: { customerCode: string[]; parentCustomerCode: string[]; topGlobalCustomerCode: string[]; excludeCustomer: string[] };
  detailCondition?: { entryDate: string };
}

// gridの設定
export interface GridSetting {
  gridApi: MutableRefObject<GridApi<any> | null>;
  isSingleSelection?: boolean;
}

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gridSettingCondition: GridSetting;
  searchListCondition?: SearchListCondition;
  listCondition?: ListCondition;
  onClick: () => Promise<void> | void;
  isAddCustomerLoading?: boolean;
};

export const SelectCustomerModal: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, gridSettingCondition, searchListCondition, listCondition, onClick, isAddCustomerLoading = false } = props;
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { isLoading, parentCustomer, countries, regions } = useSearchConditionList(searchListCondition?.parentCustomerCondition ?? []);
  const { isSearchLoading, conditions, orgs } = useSearchCustomer({
    list: { parentCustomer, countries, regions },
    methods: { setIsSelected },
    customerCondition: listCondition?.gridList,
    excludeCondition: listCondition?.excludeCondition ?? { customerCode: [], parentCustomerCode: [], topGlobalCustomerCode: [], excludeCustomer: [] },
    gridApi: gridSettingCondition.gridApi,
    detailCondition: {
      entryDate: listCondition?.detailCondition?.entryDate,
    },
  });

  // 選択された取引先を取得する関数
  const { getSelectedRowCustomerNames } = useGetSelectedRow({ gridApi: gridSettingCondition.gridApi });

  const { grid } = useSearchCustomerGridSettings({
    orgs,
    setIsSelected,
    getSelectedRowCustomerNames,
    gridApi: gridSettingCondition.gridApi,
  });

  return (
    <SearchModal
      isLoading={isLoading || isAddCustomerLoading || isSearchLoading}
      buttons={[
        {
          name: t('button.add'),
          onclick: onClick,
          disabled: !isSelected,
        },
      ]}
      conditions={conditions}
      grid={grid}
      isOpen={isOpen}
      onClose={() => {
        setIsSelected(false);
        setIsOpen(false);
      }}
      title={t('button.searchCustomer')}
      isSingleSelection={gridSettingCondition.isSingleSelection}>
      <>
        <WarningDescription />
        <Label color="info" sx={{ lineHeight: 'normal', whiteSpace: 'normal', height: 'auto' }}>
          {t('message.gettingMaximumNumber', { max: '100' })}
        </Label>
      </>
    </SearchModal>
  );
};
