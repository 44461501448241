import { HotTable } from '@handsontable/react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import 'handsontable/dist/handsontable.full.min.css';
import { Settings as HiddenColumnsSettings } from 'handsontable/plugins/hiddenColumns';
import { Settings as HiddenRowsSettings } from 'handsontable/plugins/hiddenRows';
import { HyperFormula } from 'hyperformula';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { TableData } from 'src/@types/generalEnquete';
import { useSubTableData } from 'src/components/app-components/bonsai/type-general-table/hooks/useSubTableData';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import { handsontableLicenseKey } from 'src/config';
import { useTableSave } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useTableSave';
import { deleteSubData } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { TABLE_SETTINGS } from 'src/generalConstants';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { useSubTableListToggle } from '../hooks/useSubTableListToggle';
import { enUS, jaJP } from 'handsontable/i18n';
import useLocales from 'src/hooks/useLocales';
import Label from 'src/components/Label';

interface PropsEntity {
  hotRef: RefObject<HotTable>[];
  hotRefForSubSheet: RefObject<HotTable>[];
  hyperformulaInstance: HyperFormula;
  isPreview: boolean;
  isVisibleContextMenu: boolean;
}

const EnqueteSubTableForm: React.FC<PropsEntity> = React.memo((props) => {
  const { hotRef, hotRefForSubSheet, hyperformulaInstance, isPreview, isVisibleContextMenu } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const languageDirectory = isLangJpn ? jaJP.languageCode : enUS.languageCode;

  const subDatas = useSelector((state: RootState) => state.tableSetting.subDatas);

  const constextMenuSub = {
    items: {
      row_above: {},
      row_below: {},
      col_left: {},
      col_right: {},
      remove_row: {},
      remove_col: {},
    },
  };

  // subデータ読み込み
  useSubTableData(hotRefForSubSheet, isPreview);
  const { saveAllSettings } = useTableSave(hotRef, hotRefForSubSheet);
  // sub表開閉
  const { isOpen, handleOpenCloseClick } = useSubTableListToggle();

  // sub表削除ボタンクリック
  const handleDeleteSubClick = async (index: number) => {
    // 削除前にグローバルステートを保存
    await saveAllSettings('nodb');
    for (let i = 0; i < TABLE_SETTINGS.SUB.MAX_LENGTH; i++) {
      hotRefForSubSheet[i].current?.hotInstance?.loadData([[]]);
    }
    dispatch(deleteSubData(index));
  };

  // EnqueteSubTableForm生成
  const createEnqueteSubTableForm = (subDatas: TableData[]) => {
    return subDatas.map((subData: TableData, index: number) => {
      const sheetName = 'sub' + (index + 1);
      return (
        <React.Fragment key={index}>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', mb: 1, alignItems: 'center' }}>
            {isOpen[index] ? (
              <ExpandMore onClick={() => handleOpenCloseClick(index)} />
            ) : (
              <ChevronRight onClick={() => handleOpenCloseClick(index)} />
            )}
            <Typography variant="h5">
              {sheetName}
              {!isPreview ? (
                <Button variant="outlined" color="error" onClick={() => handleDeleteSubClick(index)} sx={{ ml: 5 }}>
                  {t('enqueteCreate.table.sub.deleteButton')}
                </Button>
              ) : undefined}
            </Typography>
          </Grid>
          <Grid item container xs={12} sx={{ mb: 1, display: isOpen[index] ? 'flex' : 'none' }}>
            <Label
              sx={{
                px: 1,
                py: 2,
                mb: 2,
              }}>
              {t('enqueteGeneralCommon.referenceTableSheetReferenceWord', { tableNumber: index + 1 })}
            </Label>
            <HotTable
              style={{ height: TABLE_SETTINGS.SUB.TABLE_HEIGHT, overflowX: 'hidden', overflowY: 'hidden' }}
              ref={hotRefForSubSheet[index]}
              language={isVisibleContextMenu ? languageDirectory : undefined}
              width="100%"
              manualColumnMove={false}
              manualRowMove={false}
              maxRows={TABLE_SETTINGS.SUB.MAX_ROWS}
              maxCols={TABLE_SETTINGS.SUB.MAX_COLS}
              contextMenu={!isPreview ? constextMenuSub : undefined}
              hiddenColumns={isPreview as HiddenColumnsSettings}
              hiddenRows={isPreview as HiddenRowsSettings}
              readOnly={isPreview}
              fillHandle={!isPreview}
              formulas={
                {
                  engine: hyperformulaInstance,
                  sheetName: sheetName,
                } as any // 型定義しなければエラーとなる。正しい型定義を行うのに調査を要するため、一旦anyを指定
              }
              licenseKey={handsontableLicenseKey}
            />
          </Grid>
        </React.Fragment>
      );
    });
  };

  return <>{createEnqueteSubTableForm(subDatas)}</>;
});
export default EnqueteSubTableForm;
