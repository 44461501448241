import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralEnquete } from 'src/@types/generalEnquete';
import { OverviewEnquete } from 'src/@types/overviewEnquete';
import { SeriesAnswerEnquete } from 'src/@types/seriesEnquete';
import { useUserApi } from 'src/api/useUserApi';
import { ANSWER_STATUS, AnswerStatus } from 'src/constants';
import useEnqueteAnswer from 'src/features/general/enquete-answer/hooks/useEnqueteAnswer';
import { useEnqueteAnswerGrid } from 'src/features/general/enquete-answer/hooks/useEnqueteAnswerGrid';
import { EnqueteAnswerEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { MAX_DATA_SIZE } from 'src/generalConstants';
import useAuth from 'src/hooks/useAuth';
import { useAppSelector } from 'src/redux/store';

const useEnqueteAnswerSave = (respondentTargetId: string) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { getByEmail } = useUserApi();
  const { saveAnswer, tempSaveAnswer, updateAnswer, tempUpdateAnswer } = useEnqueteAnswerGrid();
  const { fetchConfirmHistories } = useEnqueteAnswer();

  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const answerStatus = !isEmpty(enqueteAnswerData) ? enqueteAnswerData.status : undefined;
  const isAnswered = [ANSWER_STATUS.ANSWERED].some((v) => v === answerStatus) || !isEnqueteAnswerOpen;

  const commonSaveHandler = async (tabData: GeneralEnquete | SeriesAnswerEnquete | OverviewEnquete, answerStatus: AnswerStatus) => {
    setIsSaving(true);

    const userId = null !== user ? user.username : '';
    const userEmail = null !== user ? user.email : '';

    const myInfo = await getByEmail(userEmail);
    if (!myInfo || myInfo.length === 0) {
      setIsSaving(false);
      throw Error(t('message.exceptionNotFoundUserData'));
    }

    const params: EnqueteAnswerEntity = {
      userId: userId,
      userName: myInfo[0].fullNameJpn,
      userNameEng: myInfo[0].fullNameEng,
      status: answerStatus,
      respondentTargetId: respondentTargetId ?? '',
      answerData: { answerInputData: { tabData: [tabData] } },
      isDeleted: false,
    };

    try {
      if (JSON.stringify(tabData).length > MAX_DATA_SIZE.ANSWER) {
        enqueueSnackbar(t('message.exceptionDataSizeOver', { target: t('common.answer') }), { variant: 'error' });
      } else {
        if (!isEmpty(enqueteAnswerData) && enqueteAnswerData.id) {
          const answerId = enqueteAnswerData.id;
          answerStatus === ANSWER_STATUS.SAVED ? await tempUpdateAnswer(params, answerId) : await updateAnswer(params, answerId);
          await fetchConfirmHistories(answerId);
        } else {
          answerStatus === ANSWER_STATUS.SAVED ? await tempSaveAnswer(params) : await saveAnswer(params);
        }
      }
    } finally {
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    isAnswered,
    commonSaveHandler,
  };
};

export default useEnqueteAnswerSave;
