import { Box } from '@mui/material';
import React from 'react';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import useTheme from 'src/hooks/useTheme';
import { useAppSelector } from 'src/redux/store';
import { MODE } from '../../../../../../../utils/constants';
import { useOverviewAggregateSupplementTab } from '../hooks/useOverviewAggregateSupplementTab';
import { DivisionRepTabContainer } from './DivisionRepTabContainer';
import { OwnerTabContainer } from './OwnerTabContainer';

const OverviewAggregateSupplementTab: React.FC = () => {
  const { className } = useTheme();
  const { isLoading } = useOverviewAggregateSupplementTab();
  const { mode } = useAppSelector((state) => state.overviewAggregateSupplement);

  const render = () => {
    switch (mode) {
      case MODE.OWNER:
        return <OwnerTabContainer />;
      case MODE.DIVISION_REP:
        return <DivisionRepTabContainer />;
      default:
        return null;
    }
  };

  return (
    <LoadableComponent isLoading={isLoading}>
      <Box
        className={className}
        sx={{
          height: '95vh',
          width: '100%',
        }}>
        {render()}
      </Box>
    </LoadableComponent>
  );
};

export default OverviewAggregateSupplementTab;
