import useLocales, { languageDataTypes } from 'src/hooks/useLocales';

const useJudgmentCurrentLanguage = () => {
  const { currentLang } = useLocales();

  const getText = (languageData: languageDataTypes | undefined) => {
    if (languageData === undefined) return '';
    let outputData: string = '';

    //日本語
    if (currentLang.value === 'ja') {
      //日本語データが存在せず英語データが存在する場合は、英語データを設定
      if (languageData['ja'] === null && languageData['en']) {
        outputData = languageData['en'];
      } else if (languageData['ja']) {
        outputData = languageData['ja'];
      }
    }

    //英語
    if (currentLang.value === 'en') {
      //英語データが存在せず日本語データが存在する場合は、日本語データを設定
      if (languageData['en'] === null && languageData['ja']) {
        outputData = languageData['ja'];
      } else if (languageData['en']) {
        outputData = languageData['en'];
      }
    }

    return outputData;
  };

  return { getText };
};

export default useJudgmentCurrentLanguage;
