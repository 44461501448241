import { useSelector } from 'react-redux';
import { Customer, ParentCustomer, SeriesAnswerEnquete } from 'src/@types/seriesEnquete';
import { RootState } from 'src/redux/store';

export const useCreateSeriesAnswerData = () => {
  // 回答保存時のデータを作成
  const { customerGroups, currency } = useSelector((state: RootState) => state.seriesAnswer);

  const counterparties: ParentCustomer[] = [];
  customerGroups.forEach((customerGroup) => {
    const customers: Customer[] = customerGroup.childrenCustomer.map((customer) => {
      return {
        customerCode: customer.customerCode,
        customerName: customer.customerName,
        customerNameEng: customer.customerNameEng,
        countryCode: customer.countryCode,
        countryName: customer.countryName,
        countryNameEng: customer.countryNameEng,
        regionCode: customer.regionCode,
        regionName: customer.regionName,
        regionNameEng: customer.regionNameEng,
        currencyUnit: currency,
        salesComment: customer.salesComment,
        purchaseComment: customer.purchaseComment,
        receivableBalance: customer.receivableBalance ?? null,
        payableBalance: customer.payableBalance ?? null,
        previousYear: {
          systemSalesAmount: customer.previousYear.systemSalesAmount,
          answerSalesAmount: customer.previousYear.answerSalesAmount,
          systemPurchaseAmount: customer.previousYear.systemPurchaseAmount,
          answerPurchaseAmount: customer.previousYear.answerPurchaseAmount,
          sourceType: customer.previousYear.sourceType,
          aggregateType: customer.previousYear.aggregateType,
        },
        currentYear: {
          systemSalesAmount: customer.currentYear.systemSalesAmount,
          answerSalesAmount: customer.currentYear.answerSalesAmount,
          systemPurchaseAmount: customer.currentYear.systemPurchaseAmount,
          answerPurchaseAmount: customer.currentYear.answerPurchaseAmount,
          sourceType: customer.currentYear.sourceType,
          aggregateType: customer.currentYear.aggregateType,
        },
      };
    });
    const parentCustomer = {
      parentCustomerCode: customerGroup.parentCustomerCode,
      parentCustomerName: customerGroup.parentCustomerName,
      parentCustomerNameEng: customerGroup.parentCustomerNameEng,
      customers: customers,
    };
    counterparties.push(parentCustomer);
  });

  // 保存する回答情報を作成する
  const seriesAnswerData: SeriesAnswerEnquete = {
    counterparties: counterparties,
  };

  return seriesAnswerData;
};
