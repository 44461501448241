import { Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { Rules } from '../../../../hooks/useValidator';

interface Props {
  title: string;
  control: Control<FieldValues, any>;
  name: string;
  label: string;
  rules?: Rules;
  defaultValue?: any;
  multiline?: boolean;
  rows?: number | string;
}

const RHFThreadTextField = (props: Props) => {
  const { title, control, name, label, rules, defaultValue, multiline, rows } = props;
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>
      <RHFTextField
        name={name}
        control={control}
        rules={rules}
        fullWidth
        size="medium"
        sx={{ mt: 1, width: '100%' }}
        label={label}
        variant="outlined"
        defaultValue={defaultValue ?? ''}
        multiline={multiline}
        rows={rows}
      />
    </>
  );
};

export default RHFThreadTextField;
