import { useTranslation } from 'react-i18next';
import { UserEntity } from 'src/api/useUserApi';
import { SelectModal } from 'src/components/app-components/bonsai/SelectModal';
import { useSelectRespondentManagerGrid } from './hooks/useSelectRespondentManagerGrid';

type SelectRespondentManagerModalProps = {
  open: boolean;
  onClose: () => void;
  onOk: (values: UserEntity[]) => void;
  onValidation: (values: UserEntity[]) => boolean;
};

export const SelectRespondentManagerModal = ({ open, onClose, onOk, onValidation }: SelectRespondentManagerModalProps) => {
  const { t } = useTranslation();
  const { rowData, conditions, columnDefs, onGridReady, contextMenu, onSelected, onApplyed } = useSelectRespondentManagerGrid(
    open,
    onOk,
    onValidation,
  );

  return (
    <SelectModal
      title={t('enqueteCreate.selectRespondentManagerModal.title')}
      open={open}
      conditions={conditions}
      table={{
        columnDefs: columnDefs,
        rowData: rowData,
        onGridReady,
        contextMenu: contextMenu,
      }}
      onClose={onClose}
      onAdd={onSelected}
      onOk={onApplyed}
    />
  );
};
