import React from 'react';
import { FORM_TYPE, FormType } from 'src/constants';
import { EnqueteStatusTabContainer } from '../../../tab-containers/type-default/components/EnqueteStatusTabContainer';
import { OverviewEnqueteStatusTabContainer } from '../../../tab-containers/type-overview-survey/components/OverviewEnqueteStatusTabContainer';
import { SeriesEnqueteStatusTabContainer } from '../../../tab-containers/type-series-survey/components/SeriesEnqueteStatusTabContainer';

export interface Props {
  formType: FormType;
}

export const EnqueteStatusFormTypeSwitcher: React.FC<Props> = (props) => {
  const { formType } = props;

  switch (formType) {
    case FORM_TYPE.SERIES:
      return <SeriesEnqueteStatusTabContainer />;
    case FORM_TYPE.OVERVIEW:
      return <OverviewEnqueteStatusTabContainer />;
    default:
      return <EnqueteStatusTabContainer />;
  }
};
