import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, SxProps, Typography } from '@mui/material';
import { useEffect } from 'react';
import { DragDropContext, Draggable, DraggableLocation, Droppable, DropResult } from 'react-beautiful-dnd';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DISPLAY_ACCOUNTS_CUSTOMER } from 'src/constants';
import { setCustomerGroups, setHasError } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';
import { TradingVolumeForm } from './TradingVolumeForm';

interface Props {
  methods: UseFormReturn<FieldValues, any>;
}

export const ParentPanelComponent: React.FC<Props> = (props) => {
  const { methods } = props;
  const { currentLang } = useLocales();
  const { themeMode } = useSettings();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { customerGroups, defaultValues } = useAppSelector((state) => state.seriesAnswer);
  const isJapanese = currentLang.value === 'ja';
  const isLight = themeMode === 'light';
  let color = isLight ? 'background.paper' : palette.dark.background.paper;
  const style: SxProps = {
    boxShadow: isLight ? '0px 0px 15px -10px rgba(0,0,0,0.5)' : '0px 0px 15px -10px rgba(255,255,255,0.5)',
    bgcolor: color,
    width: '100%',
    borderRadius: '10px',
    p: 2,
    mb: 2,
  };

  // Accordionの開閉制御
  const handleOpenCustomerGroup = (parentCustomerCode: string) => {
    const newGroup = customerGroups.map((group) => {
      if (group.parentCustomerCode === parentCustomerCode) {
        return {
          ...group,
          isOpen: !group.isOpen,
        };
      }
      return group;
    });
    dispatch(setCustomerGroups([...newGroup]));
  };

  // Drag and Drop
  const handleOnDragEnd = (result: DropResult) => {
    const items = Array.from(customerGroups);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice((result.destination as DraggableLocation).index, 0, reorderedItem);
    dispatch(setCustomerGroups(items));
  };

  useEffect(() => {
    const updateErrorState = async () => {
      await methods.trigger();
      dispatch(setHasError(!methods.formState.isValid));
    };
    updateErrorState();
  }, [methods.formState.isValid, dispatch, Object.keys(methods.getValues()).length]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="companyGroups">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyle: 'none' }}>
            {customerGroups.map((customerGroup, index) => {
              return (
                <Draggable key={customerGroup.parentCustomerCode} draggableId={customerGroup.parentCustomerCode} index={index}>
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={style}>
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Accordion
                          expanded={customerGroup.isOpen}
                          onChange={() => {
                            handleOpenCustomerGroup(customerGroup.parentCustomerCode);
                          }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography variant="subtitle1">
                              {t('enqueteAnswerInput.seriesSurvey.parentCompany')}：
                              {isJapanese ? trimStockCompany(customerGroup.parentCustomerName) : customerGroup.parentCustomerNameEng}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {customerGroup.childrenCustomer.map((customer) => {
                              const defaultValue = defaultValues.find((value) => value.customerCode === customer.customerCode);
                              return (
                                <TradingVolumeForm
                                  key={customer.customerCode}
                                  customerCode={customer.customerCode}
                                  customerName={isJapanese ? trimStockCompany(customer.customerName) : customer.customerNameEng}
                                  parentCustomerCode={customerGroup.parentCustomerCode}
                                  region={isJapanese ? customer.regionName : customer.regionNameEng}
                                  country={isJapanese ? customer.countryName : customer.countryNameEng}
                                  purchasePreYear={customer.previousYear.answerPurchaseAmount}
                                  purchaseCurrentYear={customer.currentYear.answerPurchaseAmount}
                                  salesPreYear={customer.previousYear.answerSalesAmount}
                                  salesCurrentYear={customer.currentYear.answerSalesAmount}
                                  salesComment={customer.salesComment}
                                  purchaseComment={customer.purchaseComment}
                                  methods={methods}
                                  receivableBalance={customer.receivableBalance}
                                  payableBalance={customer.payableBalance}
                                  previousSourceType={customer.previousYear.sourceType}
                                  currentSourceType={customer.currentYear.sourceType}
                                  customerStatus={customer.customerStatus}
                                  isDisplay={DISPLAY_ACCOUNTS_CUSTOMER.includes(customerGroup.parentCustomerCode)}
                                  defaultValue={defaultValue}
                                />
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Box>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
