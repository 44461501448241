import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import { Role } from '../@types/role';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: Role[];
  children: ReactNode | string;
  admin?: Boolean;
};

const useCurrentRole = () => {
  // Logic here to get current user role
  const { user } = useAuth();
  if (user) {
    const role: String[] = user['role'];
    return role;
  }
  return [''];
};

const useCurrentRoleType = () => {
  const { user } = useAuth();
  if (user) {
    const roleType: String = user['authority'];
    return roleType;
  }
  return 'NONE';
};

const roleCheck = (arr1: String[] = [], arr2: String[] = [], adminList: String, admin?: Boolean) => {
  const result = [...arr1, ...arr2].filter((item) => arr1.includes(item) && arr2.includes(item)).length > 0;
  if (admin) {
    if (!result) {
      return adminList === 'ADMIN';
    }
  }
  return result;
};

export default function RoleBasedGuard({ accessibleRoles, children, admin }: RoleBasedGuardProp) {
  const currentRole = useCurrentRole();
  const currentRoleType = useCurrentRoleType();

  if (!roleCheck(currentRole, accessibleRoles, currentRoleType, admin)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
