import HelpIcon from '@mui/icons-material/Help';
import { Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { GridInputGuideLineTypes } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';

interface PageProps {
  guideLineData: GridInputGuideLineTypes;
}

export const GridInputGuideLine: React.FC<PageProps> = (props) => {
  // 取得したRespondentTargetの情報を格納
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  const { currentLang } = useLocales();
  const useJudg = useJudgmentCurrentLanguage();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [inputGuideLine, setInputGuideLine] = useState<string>('');

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    if (
      undefined !== respondentTargetData &&
      undefined !== respondentTargetData.organization &&
      undefined !== respondentTargetData.organization.companyCode &&
      undefined !== props.guideLineData.baseGuideLine &&
      undefined !== props.guideLineData.extendTTCGuideLine
    ) {
      setInputGuideLine(
        respondentTargetData.organization.companyCode === 'S500'
          ? useJudg.getText(props.guideLineData.baseGuideLine) + useJudg.getText(props.guideLineData.extendTTCGuideLine)
          : useJudg.getText(props.guideLineData.baseGuideLine),
      );
    }
  }, [currentLang, respondentTargetData]); //eslint-disable-line

  // Styleを上書きしたツールチップを実装
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fcfcfc',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: currentLang.value === 'JP' ? 500 : 1000,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  return (
    <>
      <HtmlTooltip
        title={inputGuideLine.split('\n').map((x, i) => (
          <div key={i}>{x !== '' ? x : '　'}</div>
        ))}
        placement="top">
        <Button variant="outlined" size="small" startIcon={<HelpIcon />} sx={{ pt: '0px', pb: '0px', textTransform: 'none' }}>
          {props.guideLineData.label !== undefined && useJudg.getText(props.guideLineData.label)}
        </Button>
      </HtmlTooltip>
    </>
  );
};
