// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

import { green } from '@material-ui/core/colors';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

let useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    square: {
      // color: theme.palette.getContrastText(deepOrange[500]),
      // backgroundColor: deepOrange[500],
      //margin: theme.spacing(7),
    },
    rounded: {
      color: '#fff',
      backgroundColor: green[500],
    },
  }),
);

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const classes = useStyles();
  return (
    <MAvatar
      className={classes.square}
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}>
      {createAvatar(user?.email).name}
    </MAvatar>
  );
}
