import { useTranslation } from 'react-i18next';
import { AdditionalQuestion } from 'src/@types/overviewEnquete';
import { REQUEST_STATUS } from 'src/constants';
import { OverviewSurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { setAdditionalData, setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import {
  QUESTION_TYPE,
  QuestionType,
} from '../../../tab-containers/type-overview-survey/components/tabs/overview-question/components/OverviewInputEditBox';
import { QuestionPlaceInfo } from '../../../tab-containers/type-overview-survey/components/tabs/overview-question/components/OverviewQuestions';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedValue: React.Dispatch<React.SetStateAction<QuestionType>>;
  formValues: AdditionalQuestion;
  setFormValues: React.Dispatch<React.SetStateAction<AdditionalQuestion>>;
}

export const useSetInputItem = (props: Props) => {
  const { setIsOpen, setIsEdit, setSelectedValue, formValues, setFormValues } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueteData, additionalData } = useAppSelector((state: RootState) => state.enqueteCreate);
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);

  // 入力エリアのラジオボタン変更時の処理
  const handleInputAreaRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as QuestionType);
  };

  // 入力フォームの値変更時の処理
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  // テキストタイプのラジオボタン変更時の処理
  const handleTextTypeRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      inputType: event.target.value as 'text' | 'textarea',
    });
  };

  /**
   * モーダルの保存ボタン押下にグローバルステートを更新する
   * @param questionType 追加項目の入力エリア
   * @param additionalQuestion モーダルで入力された情報
   * @param isEdit 編集かどうか
   * @param questionIndex 追加項目のインデックス
   */
  const saveInputItemOnClick = (
    questionType: QuestionType,
    additionalQuestion: AdditionalQuestion,
    isEdit: boolean,
    questionPlaceInfo?: QuestionPlaceInfo,
  ) => {
    const surveyDetail = enqueteData.surveyDetail ? Object.assign(enqueteData.surveyDetail) : {};
    const overviewSurveyFormData = surveyDetail?.surveyFormData?.formData as OverviewSurveyFormData;
    const tabData = overviewSurveyFormData?.tabData[0];

    let purchaseAdditionalQuestions: AdditionalQuestion[] = [...(tabData?.purchaseAdditionalQuestions ?? [])];
    let salesAdditionalQuestions: AdditionalQuestion[] = [...(tabData?.salesAdditionalQuestions ?? [])];
    let otherAdditionalQuestions: AdditionalQuestion[] = [...(tabData?.otherAdditionalQuestions ?? [])];
    const questionIndex = questionPlaceInfo?.questionIndex;
    const preQuestionType = questionPlaceInfo?.questionType;

    if (isEdit && questionIndex !== undefined) {
      const spliceQuestion = (question: AdditionalQuestion[]) => {
        question.splice(questionIndex, 1);
      };
      // 項目編集の場合
      switch (questionType) {
        case QUESTION_TYPE.PURCHASE:
          if (preQuestionType === QUESTION_TYPE.PURCHASE) {
            // 入力エリアを変更せずにそれ以外を編集した場合
            purchaseAdditionalQuestions = [
              ...purchaseAdditionalQuestions.slice(0, questionIndex),
              additionalQuestion,
              ...purchaseAdditionalQuestions.slice(questionIndex + 1),
            ];
          } else {
            // 入力エリアを変更した場合、新規でリストに追加し、元のリストからは削除する
            purchaseAdditionalQuestions.push(additionalQuestion);
            preQuestionType === QUESTION_TYPE.SALES ? spliceQuestion(salesAdditionalQuestions) : spliceQuestion(otherAdditionalQuestions);
          }
          break;
        case QUESTION_TYPE.SALES:
          if (preQuestionType === QUESTION_TYPE.SALES) {
            // 入力エリアを変更せずにそれ以外を編集した場合
            salesAdditionalQuestions = [
              ...salesAdditionalQuestions.slice(0, questionIndex),
              additionalQuestion,
              ...salesAdditionalQuestions.slice(questionIndex + 1),
            ];
          } else {
            // 入力エリアを変更した場合、新規でリストに追加し、元のリストからは削除する
            salesAdditionalQuestions.push(additionalQuestion);
            preQuestionType === QUESTION_TYPE.PURCHASE ? spliceQuestion(purchaseAdditionalQuestions) : spliceQuestion(otherAdditionalQuestions);
          }
          break;
        case QUESTION_TYPE.OTHER:
          if (preQuestionType === QUESTION_TYPE.OTHER) {
            // 入力エリアを変更せずにそれ以外を編集した場合
            otherAdditionalQuestions = [
              ...otherAdditionalQuestions.slice(0, questionIndex),
              additionalQuestion,
              ...otherAdditionalQuestions.slice(questionIndex + 1),
            ];
          } else {
            // 入力エリアを変更した場合、新規でリストに追加し、元のリストからは削除する
            otherAdditionalQuestions.push(additionalQuestion);
            preQuestionType === QUESTION_TYPE.PURCHASE ? spliceQuestion(purchaseAdditionalQuestions) : spliceQuestion(salesAdditionalQuestions);
          }
          break;
      }
    } else {
      // 新規項目追加の場合
      switch (questionType) {
        case QUESTION_TYPE.PURCHASE:
          purchaseAdditionalQuestions.push(additionalQuestion);
          break;
        case QUESTION_TYPE.SALES:
          salesAdditionalQuestions.push(additionalQuestion);
          break;
        case QUESTION_TYPE.OTHER:
          otherAdditionalQuestions.push(additionalQuestion);
          break;
      }
    }

    // アンケート依頼期間中に追加した項目を保持する
    if (requestStatus === REQUEST_STATUS.IN_PROGRESS) {
      let purchaseAdditionalData: AdditionalQuestion[] = [...(additionalData?.purchaseAdditionalQuestions ?? [])];
      let salesAdditionalData: AdditionalQuestion[] = [...(additionalData?.salesAdditionalQuestions ?? [])];
      let otherAdditionalData: AdditionalQuestion[] = [...(additionalData?.otherAdditionalQuestions ?? [])];

      switch (questionType) {
        case QUESTION_TYPE.PURCHASE:
          purchaseAdditionalData.push(additionalQuestion);
          break;
        case QUESTION_TYPE.SALES:
          salesAdditionalData.push(additionalQuestion);
          break;
        case QUESTION_TYPE.OTHER:
          otherAdditionalData.push(additionalQuestion);
          break;
      }

      dispatch(
        setAdditionalData({
          ...additionalData,
          purchaseAdditionalQuestions: purchaseAdditionalData,
          salesAdditionalQuestions: salesAdditionalData,
          otherAdditionalQuestions: otherAdditionalData,
        }),
      );
    }

    const form: OverviewSurveyFormData = {
      pastRespondentRequestId: overviewSurveyFormData?.pastRespondentRequestId,
      tabData: [
        {
          ...tabData,
          purchaseAdditionalQuestions: purchaseAdditionalQuestions,
          salesAdditionalQuestions: salesAdditionalQuestions,
          otherAdditionalQuestions: otherAdditionalQuestions,
        },
      ],
    };

    dispatch(
      setEnqueteData({
        ...enqueteData,
        surveyDetail: {
          ...enqueteData.surveyDetail,
          surveyFormData: { ...enqueteData.surveyDetail?.surveyFormData, formData: form },
          surveyHeader: enqueteData.surveyDetail?.surveyHeader,
        },
      }),
    );

    setIsEdit(false);
    setIsOpen(false);
  };

  const validationRule = (target: string) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: target }),
      },
      maxLength: {
        value: 30,
        message: t('validateError.maxLength', { max: 30 }),
      },
    };
  };

  return { saveInputItemOnClick, handleInputAreaRadioChange, handleInputChange, handleTextTypeRadioChange, validationRule };
};
