// 言語コード。
export const LANGUAGE_CODE = {
  ENGLISH: 'en',
};

// 変換ファイル定義
export const TRANSLATION_SUPPORT_FILE = {
  // BLOBコード定義。
  BOM_UNIT8ARRAY: new Uint8Array([0xef, 0xbb, 0xbf]),
  // 変換ファイル名。
  FILE_NAME: 'translation',
  // 変換ファイルType
  FILE_TYPE: 'text/csv',
  // 変換ファイル文字コード
  CHAR_CODE: 'charset=utf-8',
  // 最大文字列
  MAXIMUM_STRING: 500,
  // 最大ファイルサイズ（バイト）
  MAXIMUM_FILE_SIZE: 1000000,
  // 列数
  COL_LENGTH: 2,
} as const;
