import { SxProps } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchModal } from 'src/components/app-components/bonsai/SearchModal';
import Label from 'src/components/Label';
import { AnswerUnit, SELECT_RESPONDENT_LINE_MAX_LENGTH } from 'src/constants';
import { useSearchConditionList } from '../../../../hooks/useSearchConditionList';
import { useSearchPerson } from '../../../../hooks/useSearchPerson';
import { useSearchPersonGrid } from '../../../../hooks/useSearchPersonGrid';
import useRespondentPersonSave from '../hooks/useRespondentPersonSave';

export interface Props {
  surveyDetailId: string;
  isOpen: boolean;
  onClose: () => void;
  respondentTargetId: string;
  respondentRequestId: string;
  answerUnit: AnswerUnit;
}

export const SelectRespondentPersonModal: React.FC<Props> = (props) => {
  const { respondentTargetId, respondentRequestId, answerUnit } = props;
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const {
    isLoading,
    companies,
    countries,
    divisions,
    sbus,
    departments,
    groups,
    bases,
    jobTitles,
    gradeJobTitles,
    makeBases,
    makeJobTitles,
    makeSbus,
    makeDepartments,
    makeGroups,
  } = useSearchConditionList({ surveyDetailId: props.surveyDetailId });
  const { isSearchLoading, users, conditions } = useSearchPerson({
    list: { companies, countries, divisions, sbus, departments, groups, bases, jobTitles, gradeJobTitles },
    methods: { makeBases, makeJobTitles, makeSbus, makeDepartments, makeGroups, setIsSelected },
    isOpen: props.isOpen,
  });
  const { grid, getSelectedRows } = useSearchPersonGrid({ users, setIsSelected });
  const { onClickAddRespondent, isClickAddRespondent } = useRespondentPersonSave({
    respondentTargetId,
    respondentRequestId,
    answerUnit,
    getSelectedRows,
  });

  const buttons = [
    {
      name: t('enqueteCreate.selectRespondentPersonModal.button.add'),
      onclick: onClickAddRespondent,
      disabled: !isSelected,
    },
  ];

  const modalStyle: SxProps = {
    overflow: 'scroll',
    maxHeight: '95%',
  };

  return (
    <SearchModal
      isLoading={isLoading || isSearchLoading || isClickAddRespondent}
      isOpen={props.isOpen}
      title={t('enqueteCreate.selectRespondentPersonModal.title')}
      buttons={buttons}
      onClose={() => {
        setIsSelected(false);
        props.onClose();
      }}
      conditions={conditions}
      grid={grid}
      isSingleSelection={false}
      modalStyleSxProps={modalStyle}>
      <Label color="info" sx={{ lineHeight: 'normal', whiteSpace: 'normal', height: 'auto' }}>
        {t('enqueteCreate.selectRespondentPersonModal.message', {
          selectRespondentLineMaxLength: new Intl.NumberFormat('en-US').format(SELECT_RESPONDENT_LINE_MAX_LENGTH),
        })}
      </Label>
    </SearchModal>
  );
};
