import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { currencyFormat } from 'src/utils/currencyFormat';

interface Props {
  previousSalesAggregate: number;
  previousPurchaseAggregate: number;
  currentSalesAggregate: number;
  currentPurchaseAggregate: number;
}

const AggregateSupplementPanelHeader: React.FC<Props> = (props) => {
  const { previousSalesAggregate, previousPurchaseAggregate, currentSalesAggregate, currentPurchaseAggregate } = props;
  const { t } = useTranslation();

  const previousYear = useAppSelector((state) => state.seriesAggregateSupplement.previousYear);
  const currentYear = useAppSelector((state) => state.seriesAggregateSupplement.currentYear);

  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <Grid item xs={3}></Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            FY{previousYear}
            {t('enqueteStatus.aggregateSupplement.salesAmount', { amount: currencyFormat(previousSalesAggregate) })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          FY{previousYear}
          {t('enqueteStatus.aggregateSupplement.purchaseAmount', { amount: currencyFormat(previousPurchaseAggregate) })}
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={3}></Grid>
        <Grid item xs={4}>
          FY{currentYear}
          {t('enqueteStatus.aggregateSupplement.salesAmount', { amount: currencyFormat(currentSalesAggregate) })}
        </Grid>
        <Grid item xs={4}>
          FY{currentYear}
          {t('enqueteStatus.aggregateSupplement.purchaseAmount', { amount: currencyFormat(currentPurchaseAggregate) })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AggregateSupplementPanelHeader;
