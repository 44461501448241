import { Box, Grid } from '@mui/material';
import 'ag-grid-enterprise';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeriesEnqueteOptionData } from 'src/@types/seriesEnquete';
import { useEnqueteOptionApi } from 'src/api/useEnqueteOptionApi';
import { StyledButton } from 'src/components/app-components/StyledButton';

import { useSnackbar } from 'notistack5';
import { RootState, useAppSelector } from 'src/redux/store';
import CustomerManagerForm from './CustomerManagerForm';

type Props = {
  surveyDetailId: string;
  seriesEnqueteOptionData?: SeriesEnqueteOptionData;
  isClosed: boolean;
};
const EnqueteAnswerOption: React.FC<Props> = (props) => {
  const { surveyDetailId, seriesEnqueteOptionData, isClosed } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const pageApi = useEnqueteOptionApi();

  const saveOptionData = useAppSelector((state: RootState) => state.enqueteOption.seriesEnqueteOptionData);

  // 保存ボタン
  const onClickCSaveButton = async () => {
    const result = await pageApi.putEnqueteOption(surveyDetailId, saveOptionData);
    if (result) {
      enqueueSnackbar(t('button.saved'), { variant: 'success' });
    }
  };

  return (
    <>
      <Box component={'div'} sx={{ mb: 1 }}>
        <Grid container alignItems={'center'} sx={{ mb: 1 }}>
          <Grid item justifyContent={'flex-start'} flex={'50%'} xs={6}></Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={'flex-end'} flex={'50%'} sx={{ paddingRight: 2, marginBottom: 1 }}>
              {/* 保存ボタン */}
              <Grid item>
                <StyledButton onClick={onClickCSaveButton} isDisabled={isClosed}>
                  {t('button.save')}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>

          <CustomerManagerForm seriesEnqueteOptionData={seriesEnqueteOptionData} isClosed={isClosed} />
        </Grid>
      </Box>
    </>
  );
};

export default EnqueteAnswerOption;
