import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { EnqueteAnswersListGridLayout } from '../components/pages/list/components/EnqueteAnswersListGridLayout';

export const EnqueteAnswerListPage = () => {
  const { t } = useTranslation();

  const enqueteAnswersListTitle = t('enqueteAnswerList.pageTitle');
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: enqueteAnswersListTitle,
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };
  return (
    <PageLayout title={enqueteAnswersListTitle} breadcrumbs={breadcrumbs}>
      <EnqueteAnswersListGridLayout />
    </PageLayout>
  );
};
