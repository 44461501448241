import React from 'react';
import { FORM_TYPE } from 'src/constants';
import { TabValue } from 'src/features/general/enquete-create/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { OverviewSurveyTabContainer } from '../../../tab-containers/type-overview-survey/components/OverviewSurveyTabContainer';
import { PersonalSurveyTabContainer } from '../../../tab-containers/type-personnel-survey/components/PersonalSurveyTabContainer';
import { SeriesSurveyTabContainer } from '../../../tab-containers/type-serires-survey/components/SeriesSurveyTabContainer';

export interface SpecialEnqueteTypeSwitcherProps {
  id: string | undefined;
  selectedTabValue: TabValue;
}

export const SpecialEnqueteTypeSwitcher: React.FC<SpecialEnqueteTypeSwitcherProps> = (props) => {
  const { id, selectedTabValue } = props;

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const formType = enqueteData.surveyDetail?.surveyHeader?.formType;

  switch (formType) {
    // 人員計画調査アンケート
    case FORM_TYPE.SPECIAL:
      return <PersonalSurveyTabContainer id={id} selectedTabValue={selectedTabValue} />;
    // 系列別調査アンケート
    case FORM_TYPE.SERIES:
      return <SeriesSurveyTabContainer id={id} selectedTabValue={selectedTabValue} />;
    // 取引先概況表アンケート
    case FORM_TYPE.OVERVIEW:
      return <OverviewSurveyTabContainer id={id} selectedTabValue={selectedTabValue} />;
    default:
      return null;
  }
};
