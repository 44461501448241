import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';

const useBulkCreateEmailModal = () => {
  const { id: respondentRequestId } = useParams();
  const { getRequestAnswerList } = useEnqueteCreateGrid();
  const { bulkCreateRespondentByEmail } = useSurveyEditApi();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const toArrayEmailsString = (emails: string) => {
    return emails.split('\n');
  };

  const onClick = async (data: FieldValues) => {
    if (respondentRequestId) {
      const emails = toArrayEmailsString(data.emails as string);
      const emailList: string[] = [...new Set(emails)];
      // memo: 同一メールアドレスが存在した場合は重複を削除してAPIをcallします
      const ret = await bulkCreateRespondentByEmail(respondentRequestId, emailList);
      // memo: 登録後の最新状態を再取得し、一覧部の表示をリフレッシュする
      await getRequestAnswerList(respondentRequestId);
      const retEmail = ret?.data.message.toString().replace(/,/g, '\n');
      return retEmail;
    }
  };

  const onClickNoErrorEmail = async (enterEmails: string[], errEmails: string[]) => {
    if (respondentRequestId) {
      const emails: string[] = enterEmails.filter((item) => !errEmails.includes(item));
      // memo: 同一メールアドレスが存在した場合は重複を削除してAPIをcallします
      let ret;
      if (emails?.length > 0) {
        ret = await bulkCreateRespondentByEmail(respondentRequestId, emails);
      } else {
        return undefined;
      }
      // memo: 登録後の最新状態を再取得し、一覧部の表示をリフレッシュする
      await getRequestAnswerList(respondentRequestId);

      const errMessage = ret?.data.message.toString().replace(/,/g, '\n');

      return errMessage;
    }
  };

  return {
    isOpen,
    openModal,
    closeModal,
    onClick,
    toArrayEmailsString,
    onClickNoErrorEmail,
  };
};

export default useBulkCreateEmailModal;
