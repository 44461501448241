import { FormHelperText, SelectChangeEvent } from '@material-ui/core';
import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import { Control, Controller } from 'react-hook-form';

type RHFSelect = {
  name: string;
  label?: string;
  control: Control<any>;
  defaultValue?: string;
  rules?: Object;
  handleOnChange: (event: SelectChangeEvent<string>) => void | Promise<void>;
  children?: any;
  disabled?: boolean;
};
const RHFDropdown = ({ name, label, control, defaultValue, rules, handleOnChange, children, disabled, ...props }: RHFSelect) => {
  return (
    <FormControl sx={{ minWidth: 150 }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              label={label}
              disabled={disabled}
              {...field}
              onChange={async (event) => {
                field.onChange(event);
                await handleOnChange(event);
              }}>
              {children}
            </Select>
            <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
};

export default RHFDropdown;
