import { Box, Button } from '@mui/material';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { StyledBox } from 'src/components/app-components/StyledBox';
import {
  clearQuestionItemOrValidation,
  saveQuestion,
  setIsQuestionOpen,
  setQuestion,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { setIsOpenEditor } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { QUESTION_SAVE_MODE } from 'src/generalConstants';
import { RootState, useDispatch, useSelector } from 'src/redux/store/index';
import QuestionNumberGrid from './QuestionNumberGrid';

type QuestionFormProps = {
  methods: UseFormReturn;
  existingQuestion?: Question;
  index?: number;
  children: React.ReactNode;
};

const QuestionForm = ({ methods, existingQuestion, index, children }: QuestionFormProps) => {
  const questions = useSelector((state: RootState) => state.enqueteCreate.questions);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSaveQuestion = (formData: FieldValues) => {
    if (existingQuestion) {
      dispatch(setQuestion({ formData, currentInputType: existingQuestion.inputType, data: existingQuestion }));
      dispatch(saveQuestion({ mode: QUESTION_SAVE_MODE.EDIT, index }));
    } else {
      dispatch(setQuestion({ formData }));
      dispatch(saveQuestion({ mode: QUESTION_SAVE_MODE.ADD }));
    }
    dispatch(setIsQuestionOpen(false));
    dispatch(setIsOpenEditor(false));
    dispatch(clearQuestionItemOrValidation());
  };

  const handleCancelOnClick = () => {
    dispatch(setIsQuestionOpen(false));
    dispatch(setIsOpenEditor(false));
    dispatch(clearQuestionItemOrValidation());
    methods.reset();
  };

  return (
    <StyledBox>
      <QuestionNumberGrid questionsNo={index !== undefined ? index + 1 : questions.length + 1} />
      {children}
      <Box sx={{ mt: 2 }}>
        <Button variant={'outlined'} color="error" onClick={handleCancelOnClick}>
          {t('button.cancel')}
        </Button>
        <Button variant={'contained'} sx={{ ml: 2 }} onClick={methods.handleSubmit(handleSaveQuestion)} disabled={!methods.formState.isValid}>
          {t('button.setting')}
        </Button>
      </Box>
    </StyledBox>
  );
};

export default QuestionForm;
