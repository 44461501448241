import { Box, BoxProps } from '@material-ui/core';
import { SvgIcon } from '@mui/material';
import { ReactElement } from 'react';
import { currentEnv } from 'src/config';
import { ReactComponent as BonsaiIcon } from '../assets/bonsai.svg';
import { ReactComponent as NewYearIcon } from '../assets/new_year.svg';
import { ReactComponent as XmasIcon } from '../assets/xmas.svg';

// アプリロゴ
// 特別なロゴを表示したい場合は、ここに追加する
// getMonth()は0から始まるので、12月は11を返す
const specialLogoMap: Map<number, ReactElement> = new Map([
  [0, <NewYearIcon />],
  [11, <XmasIcon />],
]);

export default function Logo({ sx }: BoxProps) {
  const isProduction = currentEnv === 'production';
  const month = new Date().getMonth();
  const isSpecialLogo = !isProduction && specialLogoMap.has(month);
  const iconSize = isSpecialLogo ? 52 : 40;
  const renderIcon = () => {
    if (isSpecialLogo) {
      return specialLogoMap.get(month);
    }
    return <BonsaiIcon />;
  };
  return (
    <Box sx={{ width: iconSize, height: iconSize, ...sx }}>
      <SvgIcon sx={{ width: '100%', height: '100%' }}>{renderIcon()}</SvgIcon>
    </Box>
  );
}
