import { useState } from 'react';

export const useSubTableListToggle = () => {
  const [isOpen, setOpen] = useState([false, false, false, false, false]);
  const handleOpenCloseClick = (subTableIndex: number) => {
    setOpen(isOpen.map((isOpen, index) => (index === subTableIndex ? !isOpen : isOpen)));
  };
  return {
    isOpen,
    handleOpenCloseClick,
  };
};
