import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SurveyEditEntity, useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { TAB_VALUE } from 'src/features/general/enquete-create/constants';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerHeaderInfo } from '../../../../../enquete-answer/components/pages/input/components/header-box/hooks/useEnqueteAnswerHeaderInfo';
import { ENQUETE_CREATE_PATH } from '../../../../routes/path';
import { MailFormDataProps } from '../../../pages/response-request-mail/hooks/useRequestMailForm';

interface Props {
  onCloseConfirmModal: () => void;
  mailFormData: MailFormDataProps;
  rowData: SurveyEditEntity[];
  attachedFiles: File[];
}

const useSendConfirmationModal = ({ onCloseConfirmModal, mailFormData, rowData, attachedFiles }: Props) => {
  const navigate = useNavigate();
  const { respondentRequestId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData);

  const { sendAnswerRequest } = useSurveyEditApi();
  const { requestStatusTerm } = useEnqueteAnswerHeaderInfo(enqueteData);

  const [isSending, setIsSending] = useState<boolean>(false);

  const sendMail = async () => {
    if (respondentRequestId === undefined) return;
    setIsSending(true);

    let formData = new FormData();
    formData.append('subject', mailFormData.subject);
    formData.append(
      'content',
      `アンケートタイトル: ${enqueteData.titleJpn}\nTitle: ${enqueteData.titleEng}\n依頼期間/Survey Term (JST): ${requestStatusTerm}\n\n${mailFormData.content}`,
    );
    rowData.forEach((row) => formData.append('respondentPersonsId', row.respondentPersonId));
    if (attachedFiles.length > 0) {
      attachedFiles.forEach((file) => {
        // memo : 送信時にファイルの拡張子を全部小文字にして、API側の拡張子バリデーションではじかれないようにする
        const extention = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        const baseName = file.name.slice(0, file.name.lastIndexOf('.'));
        formData.append('files', file, encodeURIComponent(`${baseName}${extention}`));
      });
    }

    try {
      const res = await sendAnswerRequest(respondentRequestId, formData);
      if (res) {
        navigate(`${ENQUETE_CREATE_PATH.EDIT}/${respondentRequestId}?tab=${TAB_VALUE.RESPONDENT}&updatedList=1`);
        enqueueSnackbar(t('message.send'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t('message.exceptionSend'), { variant: 'error' });
    } finally {
      onCloseConfirmModal();
      setIsSending(false);
    }
  };

  return {
    isSending,
    sendMail,
  };
};

export default useSendConfirmationModal;
