import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import { useConfirmRequestApi } from 'src/api/useConfirmRequestApi';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { NetworkAccessError } from 'src/errors/errors';
import { ConfirmHistoryEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useSettings from 'src/hooks/useSettings';
import palette from 'src/theme/palette';

interface Props {
  openDeleteConfirmerModal: boolean;
  setOpenDeleteConfirmerModal: React.Dispatch<React.SetStateAction<boolean>>;
  baseColumnDefs: (ColDef | ColGroupDef)[];
  targetRowData: ConfirmHistoryEntity | undefined;
  refreshRowData: () => Promise<void>;
}

export const ConfirmerDeletingModal: React.FC<Props> = (props) => {
  const { openDeleteConfirmerModal, setOpenDeleteConfirmerModal, baseColumnDefs, targetRowData, refreshRowData } = props;
  const { t } = useTranslation();
  const confirmApi = useConfirmRequestApi();

  // data tabel theme
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  // default coldef
  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
  };
  const { enqueueSnackbar } = useSnackbar();
  //-------------------------------------------------------------
  // Confirm Requestの更新
  //-------------------------------------------------------------
  const deleteConfirmRequest = async () => {
    if (undefined === targetRowData) {
      setOpenDeleteConfirmerModal(false);
      return;
    }
    try {
      await confirmApi.deleteByConfirmRequestId(targetRowData.id);
    } catch (error) {
      if (error instanceof NetworkAccessError) {
        enqueueSnackbar(t('apiExceptionMessage.useConfirmRequestApi.failedDeleteConfirmRequest'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
      }
    } finally {
      setOpenDeleteConfirmerModal(false);
      refreshRowData();
    }
  };

  return (
    <Modal open={openDeleteConfirmerModal} onClose={() => setOpenDeleteConfirmerModal(false)}>
      <Box
        component={'div'}
        className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
        data-testid={'aggrid'}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: 900,
          bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}>
        <Typography variant="h4" component={'h2'}>
          {t('answerConfirmStatus.confirmInfoDataTable.message.confirmDeleteConfirmer')}
        </Typography>
        <Grid container component={'div'} sx={{ mt: 1 }} paddingTop={2}>
          <Grid item xs={12}>
            <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: 110, width: '100%' }}>
              <StyledAgGrid
                defaultColDef={defaultColDef}
                coldef={baseColumnDefs}
                rows={[targetRowData]}
                suppressAutoSize={true}
                tooltipShowDelay={0}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row-reverse'} spacing={2} paddingTop={2}>
              <Button variant="contained" onClick={deleteConfirmRequest}>
                {t('button.ok')}
              </Button>
              <Button color="error" variant="contained" onClick={() => setOpenDeleteConfirmerModal(false)}>
                {t('button.cancel')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
