import { GridApi, GridReadyEvent, ITooltipParams, SelectionChangedEvent, ValueGetterParams } from 'ag-grid-community';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { GridProps } from 'src/components/app-components/bonsai/SearchModal';
import { LIST_STATE_KEY_PREFIX } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { switchLabel } from 'src/utils/labelUtils';

interface Props {
  orgs: OrganizationEntity[];
}

export const useSearchOrganizationGrid = (props: Props) => {
  const storageKey = 'SearchModalExtended';
  const { orgs } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  // memo: グリッドAPIのストア用
  const [searchResultGridApi, setSearchResultGridApi] = useState<GridApi>();
  const [selectedTargetGridApi, setSelectedTargetGridApi] = useState<GridApi>();

  // memo: 検索結果表示グリッドでのアイテムの選択(チェック)状態
  const [selectedIdsOnSearchResult, setSelectedIdsOnSearchResult] = useState<string[]>([]);

  // memo: 回答対象保存用グリッドでのアイテムの選択(チェック)状態
  const [isSelectedDataOnSelectedTarget, setIsSelectedDataOnSelectedTarget] = useState(false);

  // memo: 回答対象保存用グリッド上のアイテムのストア
  const [dataOnSelectedTarget, setDataOnSelectedTarget] = useState<OrganizationEntity[] | null | undefined>([]);

  useEffect(() => {
    const rowDataForSelectedTargetGrid = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.RESPONDENT_DATA}${storageKey}`);
    if (!rowDataForSelectedTargetGrid) return;
    setDataOnSelectedTarget(JSON.parse(rowDataForSelectedTargetGrid));
  }, []);

  // memo: 回答対象保存用グリッドからチェックされたデータを削除する
  const removeDataFromsSelectedTarget = useCallback(() => {
    const selectedRows = selectedTargetGridApi?.getSelectedRows();
    const updatedAdditionalList = dataOnSelectedTarget?.filter((item) => !selectedRows?.some((selectedRow) => selectedRow.id === item.id));
    setDataOnSelectedTarget(updatedAdditionalList);
    if (searchResultGridApi && dataOnSelectedTarget) {
      searchResultGridApi.forEachNode((node) => {
        if (selectedRows?.some((selectedRow) => selectedRow.id === node.data.id)) {
          node.setSelected(false);
        }
      });
    }
    localStorage.setItem(`${LIST_STATE_KEY_PREFIX.RESPONDENT_DATA}${storageKey}`, JSON.stringify(updatedAdditionalList));
  }, [dataOnSelectedTarget, searchResultGridApi, selectedTargetGridApi]);

  const handleOnSelectionChanged = () => {
    const selectedIds: string[] = [];
    searchResultGridApi?.getSelectedRows().forEach((element) => {
      if (element?.id) {
        selectedIds.push(element?.id);
      }
    });
    setSelectedIdsOnSearchResult(selectedIds);
  };

  // memo: 検索結果用グリッドでアイテムがチェックされたときの処理(SearchModalExtended用)
  const setSelectedDataOnSearchResult = debounce((event: SelectionChangedEvent) => {
    const selectedNodes = event.api.getSelectedRows();
    if (!dataOnSelectedTarget) return;

    // memo: 検索結果用グリッドがら選択が解除されたアイテム
    const removedItems = dataOnSelectedTarget.filter(
      (additionalItem) =>
        !selectedNodes.some((selectedNode) => selectedNode.id === additionalItem.id) && orgs.some((org) => org.id === additionalItem.id),
    );

    // memo: 検索結果用グリッドであらたに選択されたアイテム
    const newAdditionalItems = selectedNodes.filter(
      (selectedNode: any) => !dataOnSelectedTarget.some((additionalItem) => additionalItem.id === selectedNode.id),
    );

    // memo: 回答対象保存用グリッドのアイテムを更新する
    const updatedAdditionalList = [...dataOnSelectedTarget.filter((item) => !removedItems.includes(item)), ...newAdditionalItems];
    setDataOnSelectedTarget(updatedAdditionalList);
    localStorage.setItem(`${LIST_STATE_KEY_PREFIX.RESPONDENT_DATA}${storageKey}`, JSON.stringify(updatedAdditionalList));
  }, 500);

  // memo: 回答対象保存用グリッド上のアイテムをもとに検索結果用グリッドのチェックを付ける
  const updatedSelectedDataOnSearchResult = useCallback(() => {
    if (searchResultGridApi && dataOnSelectedTarget) {
      searchResultGridApi.forEachNode((node) => {
        node.setSelected(dataOnSelectedTarget?.some((additionalItem) => additionalItem.id === node.data.id));
      });
    }
  }, [dataOnSelectedTarget, searchResultGridApi]);

  // memo: ダイアログ内のグリッド定義を構築 グリッド定義は基本的に検索結果用グリッドと回答対象保存用グリッドで同じとする
  const isJapanese = currentLang.value === 'ja';
  const headerNameKey = 'enqueteCreate.selectRespondentAndExportModal.gridColDef.header';

  const makeColDefIncludeLangSwitch = (key: string, minWidth: number) => {
    const jpn = `${key}NameJpn`;
    const eng = `${key}NameEng`;
    return {
      field: isJapanese ? `${key}NameJpn` : `${key}NameEng`,
      headerName: ['region', 'country'].includes(key) ? t(`common.${key}`) : t(`${headerNameKey}.${key}`),
      minWidth: minWidth,
      valueGetter: (params: ValueGetterParams) => switchLabel(params.data[jpn], params.data[eng], isJapanese),
      tooltipValueGetter: (params: ITooltipParams) => switchLabel(params.data[jpn], params.data[eng], isJapanese),
    };
  };

  // memo: 検索結果用グリッド
  const searchResultGrid: GridProps<OrganizationEntity> = {
    colDefs: [
      makeColDefIncludeLangSwitch('company', 250),
      makeColDefIncludeLangSwitch('division', 180),
      makeColDefIncludeLangSwitch('sbu', 180),
      makeColDefIncludeLangSwitch('department', 180),
      makeColDefIncludeLangSwitch('group', 180),
      {
        field: 'rank',
        headerName: t(`${headerNameKey}.rank`),
        minWidth: 80,
      },
      makeColDefIncludeLangSwitch('region', 100),
      makeColDefIncludeLangSwitch('country', 100),
    ],
    rowData: orgs,
    onGridReady: (params: GridReadyEvent) => {
      setSearchResultGridApi(params.api);
    },
    onSelectionChanged: handleOnSelectionChanged,
  };

  // memo: 回答対象保存用グリッド
  const selectedTargetGrid: GridProps<OrganizationEntity> = {
    colDefs: [
      makeColDefIncludeLangSwitch('company', 250),
      makeColDefIncludeLangSwitch('division', 180),
      makeColDefIncludeLangSwitch('sbu', 180),
      makeColDefIncludeLangSwitch('department', 180),
      makeColDefIncludeLangSwitch('group', 180),
      {
        field: 'rank',
        headerName: t(`${headerNameKey}.rank`),
        minWidth: 80,
      },
      makeColDefIncludeLangSwitch('region', 100),
      makeColDefIncludeLangSwitch('country', 100),
    ],
    rowData: dataOnSelectedTarget ? dataOnSelectedTarget : [],
    onGridReady: (params: GridReadyEvent) => {
      setSelectedTargetGridApi(params.api);
    },
  };

  return {
    searchResultGrid,
    selectedTargetGrid,
    setSelectedDataOnSearchResult,
    updatedSelectedDataOnSearchResult,
    removeDataFromsSelectedTarget,
    isSelectedDataOnSelectedTarget,
    setIsSelectedDataOnSelectedTarget,
    selectedIdsOnSearchResult,
    setSelectedIdsOnSearchResult,
  };
};
