import { useEffect } from 'react';
import { OverviewEnquete } from 'src/@types/overviewEnquete';
import { useCustomerApi } from 'src/api/useCustomerApi';
import { setIsDeletedCustomer } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';

export const useOverviewSurveyTabContainer = () => {
  const { getCustomersByFilter } = useCustomerApi();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  useEffect(() => {
    if (enqueteData?.surveyDetail?.surveyFormData?.formData.tabData[0]) {
      const tabData = enqueteData.surveyDetail.surveyFormData.formData.tabData[0] as OverviewEnquete;
      if (tabData.customerCode) {
        getCustomersByFilter({ customerCode: [tabData.customerCode] }).then((customers) => {
          if (customers && customers.length > 0) {
            dispatch(setIsDeletedCustomer(customers[0].isDeleted));
          }
        });
      }
    }
    return () => {
      dispatch(setIsDeletedCustomer(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueteData]);
  return {};
};
