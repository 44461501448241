import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { SurveyInformationModal } from 'src/components/app-components/bonsai/survey-information/components/SurveyInformationModal';
import { FORM_TYPE, REQUEST_STATUS, SURVEY_TYPE } from 'src/constants';
import { CancelConfirmModal } from 'src/features/general/enquete-create/components/modals/cancel-confirm/components/CancelConfirmModal';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { formatterDateTime, yearMonthFormat } from 'src/utils/formatDateTime';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useEnqueteCreate } from '../hooks/useEnqueteCreate';
import useEnquetePageCommonGrid from '../hooks/useEnquetePageCommonGrid';

const EnquetePageCommonGrid: React.FC = () => {
  const { currentLang } = useLocales();

  const dispatch = useDispatch();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const { isAdmin, isSurveyOwner } = usePermission(enqueteData.surveyDetail?.id);
  const [isOpenCancelConfirmModal, setIsOpenCancelConfirmModal] = useState(false);
  const { t } = useTranslation();
  const { getRequestStatusTerm, getDisclosureLevel, onClickExportCustomer } = useEnquetePageCommonGrid();
  const isGeneral = enqueteData.surveyDetail?.surveyHeader?.type === SURVEY_TYPE.GENERAL;
  const isOverview =
    enqueteData.surveyDetail?.surveyHeader?.type === SURVEY_TYPE.SPECIAL && enqueteData.surveyDetail?.surveyHeader?.formType === FORM_TYPE.OVERVIEW;
  const warnMessage =
    isGeneral && REQUEST_STATUS.IN_PROGRESS === requestStatus
      ? t('enqueteCreate.warnMessage.inProgress')
      : isOverview && REQUEST_STATUS.IN_PROGRESS === requestStatus
      ? t('enqueteCreate.warnMessage.inOverviewProgress')
      : REQUEST_STATUS.COMPLETE === requestStatus
      ? t('enqueteCreate.warnMessage.complete')
      : undefined;

  // memo: アンケート新規作成・編集モーダルの管理
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isNewCreateMode } = useEnqueteCreate();

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4} sx={{ whiteSpace: 'pre-line' }}>
          <Typography variant="caption">
            <Box sx={{ fontWeight: 'bold' }}>{t('enqueteCreate.enqueteLabel')}</Box>
          </Typography>
          <Typography
            variant="body1"
            children={
              currentLang.value === 'ja'
                ? enqueteData.surveyDetail.surveyHeader?.labelJpn ?? ''
                : enqueteData.surveyDetail.surveyHeader?.labelEng ?? ''
            }
          />
        </Grid>
        <Grid item xs={12} md={8} sx={{ whiteSpace: 'pre-line' }}>
          <Typography variant="caption">
            <Box sx={{ fontWeight: 'bold' }}>{t('enqueteCreate.enqueteTitle')}</Box>
          </Typography>
          <Typography variant="body1" children={currentLang.value === 'ja' ? enqueteData.titleJpn : enqueteData.titleEng} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4} sx={{ whiteSpace: 'pre-line' }}>
          <Typography variant="caption">
            <Box sx={{ fontWeight: 'bold' }}>{t('enqueteCreate.enqueteRequestTermTitle')}</Box>
          </Typography>
          <Typography variant="body1" children={getRequestStatusTerm(enqueteData)} />
        </Grid>
        <Grid item xs={12} md={2} sx={{ whiteSpace: 'pre-line' }}>
          <Typography variant="caption">
            <Box sx={{ fontWeight: 'bold' }}>{t('enqueteCreate.enqueteTargetDate')}</Box>
          </Typography>
          <Typography variant="body1" children={formatterDateTime(enqueteData.targetDate ?? '-', yearMonthFormat)} />
        </Grid>
        <Grid item xs={12} md={2} sx={{ whiteSpace: 'pre-line' }}>
          <Typography variant="caption">
            <Box sx={{ fontWeight: 'bold' }}>{t('enqueteGeneralCommon.disclosureLevel')}</Box>
          </Typography>
          <Typography variant="body1" children={getDisclosureLevel(enqueteData)} />
        </Grid>
        <Grid item xs={12} md={2} sx={{ whiteSpace: 'pre-line' }}>
          <Typography variant="caption">
            <Box sx={{ fontWeight: 'bold' }}>{t('enqueteCreate.enqueteDataUpdateTitle')}</Box>
          </Typography>
          <Typography
            variant="body1"
            children={!isNewCreateMode && enqueteData.surveyDetail?.updatedAt ? formatterDateTime(enqueteData.surveyDetail.updatedAt.toString()) : ''}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <StyledButton onClick={() => setIsModalOpen(true)}>{t('button.edit')}</StyledButton>
            {(isGeneral || isOverview) && requestStatus === REQUEST_STATUS.IN_PROGRESS && (
              <StyledButton onClick={() => setIsOpenCancelConfirmModal(true)} color="error" sx={{ marginLeft: '10px' }}>
                {t('enqueteCreate.button.cancel')}
              </StyledButton>
            )}
            {enqueteData.surveyDetail?.surveyHeader?.formType === FORM_TYPE.SERIES && (
              <StyledButton
                variant="outlined"
                sx={{ marginLeft: '1em' }}
                isDisabled={!isAdmin && !isSurveyOwner}
                onClick={() => {
                  onClickExportCustomer();
                }}>
                {t('enqueteCreate.button.customer')}
              </StyledButton>
            )}
          </Box>
        </Grid>
      </Grid>
      {warnMessage && (
        <Label role="message" color="warning" variant="ghost" sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}>
          {warnMessage}
        </Label>
      )}
      <CancelConfirmModal
        isOpen={isOpenCancelConfirmModal}
        onClose={(entity?: RespondentRequestEntity) => {
          // memo:
          //   DB更新後の状態を反映し、画面を再描画
          //   undefined の場合は、更新しなかった or 更新失敗のため、 set しない
          if (entity !== undefined) {
            dispatch(
              setEnqueteData({
                ...entity,
                surveyDetail: enqueteData.surveyDetail,
              }),
            );
          }
          setIsOpenCancelConfirmModal(false);
        }}
        respondentRequestId={enqueteData.id}
      />
      {/** アンケート作成・編集モーダル */}
      <SurveyInformationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewCreateMode={isNewCreateMode}
        modalTitle={t('enqueteCreate.editEnqueteModal.title')}
      />
    </>
  );
};

export default EnquetePageCommonGrid;
