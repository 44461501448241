import { useSurveyAnswerApi } from 'src/api/useSurveyAnswer';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { ToggleButtonValue } from '../components/pages/list/components/components/EnqueteAnswerListToggleButton';
import { enqueteAnswerSlice } from '../store/enqueteAnswerSlice';

export const useEnqueteAnswersListGrid = () => {
  const dispatch = useAppDispatch();
  const surveyDetail = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);
  const page = useAppSelector((state: RootState) => state.enqueteAnswer.page);

  const surveyHeaderId = surveyDetail?.surveyHeaderId;
  const surveyDetailId = surveyDetail?.id;
  const version = surveyDetail?.version;

  /**
   * アンケート回答一覧画面のグリッドの初期化処理
   */

  const enqAnswerListApi = useSurveyAnswerApi();

  /**
   * APIからデータ取得
   */
  const loadList = async () => {
    const enqueteAnswerList = await enqAnswerListApi.getSurveyPastAnswer(surveyHeaderId, surveyDetailId, version);
    if (enqueteAnswerList === undefined) return;

    // ストアの初期化
    dispatch(enqueteAnswerSlice.actions.initEnqueteAnswersListData(enqueteAnswerList));
  };

  /**
   * APIからデータ取得
   */
  const countLimitedLoadList = async (filterModel: { [key: string]: any }, currentLang: string, isAll: boolean) => {
    const countEnqueteAnswerList = await enqAnswerListApi.getCountLimitedSurveyAnswer(filterModel, currentLang, isAll);
    if (countEnqueteAnswerList === undefined) {
      return 1;
    }

    return countEnqueteAnswerList;
  };

  /**
   * APIからデータ取得
   */
  const limitedLoadList = async (
    blockSize: number,
    startRow: number,
    sortModel: any,
    filterModel: { [key: string]: any },
    currentLang: string,
    isAll: boolean,
  ) => {
    const enqueteAnswerList = await enqAnswerListApi.getLimitedSurveyAnswer(blockSize, startRow, sortModel, filterModel, currentLang, isAll);
    if (enqueteAnswerList === undefined) return;

    // ストアの初期化
    dispatch(enqueteAnswerSlice.actions.initEnqueteAnswersLimitedListData(enqueteAnswerList));
    dispatch(enqueteAnswerSlice.actions.setReturnAllAnswer(isAll));
  };

  /**
   * ステータスとページ数を更新するhooks
   */
  const changeIsAll = async (isAll: boolean, page: number) => {
    dispatch(enqueteAnswerSlice.actions.setReturnAllAnswer(isAll));
    if (page > 1) {
      dispatch(enqueteAnswerSlice.actions.setPage(1));
    }
  };

  /**
   * 回答一覧でのページを変更するhooks
   */
  const handlePage = (pageNumber: number) => {
    dispatch(enqueteAnswerSlice.actions.setPage(pageNumber));
  };

  /**
   * 回答一覧で宛先を表すトグルの状態変更するhooks
   */
  const changeToggle = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== undefined && newAlignment !== null) {
      changeIsAll(newAlignment === ToggleButtonValue.all, page);
    }
  };

  return { loadList, limitedLoadList, countLimitedLoadList, changeIsAll, handlePage, changeToggle };
};
