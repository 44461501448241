import { Backdrop, CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { SearchModalExtended } from 'src/components/app-components/bonsai/SearchModalExtended';
import useLocales from 'src/hooks/useLocales';
import { useSearchConditionList } from '../../../../hooks/useSearchConditionList';
import { useSearchOrganization } from '../../../../hooks/useSearchOrganization';
import { useSearchOrganizationGrid } from '../../../../hooks/useSearchOrganizationGrid';

export interface Props {
  surveyDetailId: string;
  onClose: () => void;
}

export const SelectRespondentAndExportModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    isLoading,
    orgLevelTypes,
    companies,
    divisions,
    sbus,
    departments,
    groups,
    ranks,
    countries,
    regions,
    makeSbus,
    makeDepartments,
    makeGroups,
  } = useSearchConditionList({ surveyDetailId: props.surveyDetailId });
  const { conditions, orgs } = useSearchOrganization({
    surveyDetailId: props.surveyDetailId,
    list: { orgLevelTypes, companies, divisions, sbus, departments, groups, ranks, countries, regions },
    methods: { makeSbus, makeDepartments, makeGroups },
  });
  const {
    searchResultGrid,
    selectedTargetGrid,
    removeDataFromsSelectedTarget,
    setSelectedDataOnSearchResult,
    updatedSelectedDataOnSearchResult,
    isSelectedDataOnSelectedTarget,
    setIsSelectedDataOnSelectedTarget,
  } = useSearchOrganizationGrid({
    orgs,
  });
  const { download } = useSurveyEditApi();
  const { id } = useParams();

  // memo: 日英モード判定
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  // memo: ボタンの設定
  const buttons: {
    onclick: () => void;
    disabled?: boolean;
    content?: JSX.Element;
    color: 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  }[] = useMemo(
    () => [
      {
        onclick: () => {
          removeDataFromsSelectedTarget();
          setIsSelectedDataOnSelectedTarget(false);
        },
        disabled: !isSelectedDataOnSelectedTarget,
        content: <>{t('enqueteCreate.selectRespondentAndExportModal.button.delete')}</>,
        color: 'error',
      },
      {
        onclick: () => {
          const ids = selectedTargetGrid.rowData ? selectedTargetGrid.rowData.map((item) => item.id) : [];
          id && download(id, ids, isLangJpn ? 'jpn' : 'eng');
        },
        disabled: !(selectedTargetGrid.rowData && selectedTargetGrid.rowData.length > 0),
        content: (
          <>
            {selectedTargetGrid.rowData && selectedTargetGrid.rowData.length > 0
              ? selectedTargetGrid.rowData.length + (isLangJpn ? '件 ' : ' selected ')
              : ''}
            {t('enqueteCreate.selectRespondentAndExportModal.button.export')}
          </>
        ),
        color: 'primary',
      },
    ],
    [
      isSelectedDataOnSelectedTarget,
      t,
      selectedTargetGrid.rowData,
      removeDataFromsSelectedTarget,
      setIsSelectedDataOnSelectedTarget,
      id,
      download,
      isLangJpn,
    ],
  );

  return (
    <>
      {/* モーダル表示時に時間がかかる場合、isLoading が true の間は modal 操作不可となるように circularProgress を描画 */}
      {/* conditions のフェッチ中はマウントしないことでパフォーマンスを改善する */}
      {isLoading ? (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <SearchModalExtended
          title={t('enqueteCreate.selectRespondentAndExportModal.title')}
          buttons={buttons}
          onClose={props.onClose}
          conditions={conditions}
          // memo: 検索結果用グリッド
          searchResultGrid={searchResultGrid}
          setSelectedDataOnSearchResult={setSelectedDataOnSearchResult}
          updateSelectedDataOnSearchResult={updatedSelectedDataOnSearchResult}
          // memo: 回答対象保存用グリッド
          selectedTargetGrid={selectedTargetGrid}
          setIsSelectedDataOnSelectedTarget={setIsSelectedDataOnSelectedTarget}
        />
      )}
    </>
  );
};
