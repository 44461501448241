import { Auth } from 'aws-amplify';

export async function getBearerToken(): Promise<string | undefined> {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return `Bearer ${token}`;
  } catch (error) {
    window.alert(
      'セッションの取得に失敗しました。解決されない場合、ページリロードを試すか、再ログインしてください。(Failed to get session. If you have not resolved this problem, please reload this page or log in again.)',
    );
    throw error;
  }
}
