import {
  ComplementaryOrganizationEntity,
  CountryEntity,
  RankEntity,
  RegionEntity,
  useComplementaryOrganizationApi,
} from 'src/api/useComplementaryOrganizationApi';
import { OrganizationEntity, useOrganizationApi } from 'src/api/useOrganizationApi';
import { useAppDispatch } from 'src/redux/store';
import { complementaryOrganizationSlice } from '../stores/complementaryOrganizationSlice';

export const useComplementaryOrganizationDetail = () => {
  const dispatch = useAppDispatch();
  const complementaryOrganizationApi = useComplementaryOrganizationApi();
  const organizationApi = useOrganizationApi();

  /**
   * APIから行データ取得
   */
  const getDetail = async (id: string) => {
    //RespondentRequestデータ取得
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.initDetailData({} as ComplementaryOrganizationEntity));

    const complementaryOrganizationDetail: ComplementaryOrganizationEntity | undefined =
      await complementaryOrganizationApi.getComplementaryOrganizationDetail(id);
    if (complementaryOrganizationDetail === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.initDetailData(complementaryOrganizationDetail));
    return complementaryOrganizationDetail;
  };

  const getTTC = async () => {
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.ttcData({} as OrganizationEntity));

    const ttc: OrganizationEntity[] | undefined = await organizationApi.getOrgLevelType('COMPANY');
    if (ttc === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.ttcData(ttc.find((x) => x.companyCode === 'S500') || ({} as OrganizationEntity)));
    return ttc;
  };

  const getCountriesByRegionCode = async (regionCode: string) => {
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.countryData([] as CountryEntity[]));

    const countries: CountryEntity[] | undefined = await complementaryOrganizationApi.getCountriesByRegionCode(regionCode);
    if (countries === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.countryData(countries));
    return countries;
  };

  const getRegions = async () => {
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.regionData([] as RegionEntity[]));

    const regions: RegionEntity[] | undefined = await complementaryOrganizationApi.getRegions();
    if (regions === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.regionData(regions));
    return regions;
  };

  const getRanks = async () => {
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.rankData([] as RankEntity[]));

    const ranks: RankEntity[] | undefined = await complementaryOrganizationApi.getRanks();
    if (ranks === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.rankData(ranks));
    return ranks;
  };

  const create = async (data: ComplementaryOrganizationEntity) => {
    await complementaryOrganizationApi.createComplementaryOrganization(data);
    return;
  };
  const update = async (id: string, data: ComplementaryOrganizationEntity) => {
    await complementaryOrganizationApi.updateComplementaryOrganization(id, data);
    return;
  };

  return { getDetail, getTTC, getCountriesByRegionCode, getRanks, getRegions, create, update };
};
