export const TAB_VALUE = {
  QUESTION: 'question',
  RESPONDENT: 'respondent',
  OWNER: 'owner',
  RESPONDENT_MANAGER: 'respondentManager',
  DIVISION_REGION_EDITOR: 'tivisionRegionEditor',
  TEMPORARY_ORGANIZATION: 'temporaryOrganizationManagement',
  OPTION: 'option',
} as const;

export type TabValue = typeof TAB_VALUE[keyof typeof TAB_VALUE];
