import { useTranslation } from 'react-i18next';
import { useOverviewRoutes } from './useOverviewRoutes';

export const useOnlyAnswerOverviewPage = () => {
  const { t } = useTranslation();
  const { generalRoutes } = useOverviewRoutes();

  // 回答だけといいつつ確認とスレッドも入っているが、これは回答から〜許可みたいな意味合いと思ってもらえるとよい
  const overviewCardList = [
    {
      genreTitle: t('features.general.title'),
      list: [generalRoutes.ANSWER, generalRoutes.CONFIRM, generalRoutes.THREADS],
    },
  ];

  return { overviewCardList };
};
