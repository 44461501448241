import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateDetail, AggregateDetailRow } from 'src/api/useEnqueteStatusPageApi';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import useTheme from 'src/hooks/useTheme';
import { useSummaryAggregateDetailColdef } from '../hooks/useSummaryAggregateDetailColdef';

interface Props {
  aggregateDetailMap: { [key: string]: AggregateDetail };
  previousYear: string;
  currentYear: string;
}

export const SummaryAggregateDetailGrid: React.FC<Props> = ({ aggregateDetailMap, previousYear, currentYear }) => {
  const { defaultColDef, gridOptions, colDef, firstDataRendered } = useSummaryAggregateDetailColdef(previousYear, currentYear);
  const { className } = useTheme();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { t } = useTranslation();
  const [rows, setRows] = useState<AggregateDetailRow[]>(Object.values(aggregateDetailMap)[0].aggregateDetailRows);
  const handleChange = (_event: SyntheticEvent, newValue: AggregateDetailRow[]) => {
    setRows(newValue);
  };

  const renderTabs = () => (
    <Tabs variant="scrollable" scrollButtons={false} sx={{ mb: 2 }} value={rows} onChange={handleChange}>
      {Object.entries(aggregateDetailMap).map(([key, value]) => {
        const label = isLangJpn ? trimStockCompany(value.customerNameJpn) : value.customerNameEng;
        return <Tab key={`tab-${key}`} value={value.aggregateDetailRows} label={label} />;
      })}
    </Tabs>
  );
  const render = (
    <Grid container>
      <Grid item xs={12}>
        <Box className={className}>
          <Typography variant="h5">{t('enqueteStatus.summaryAggregate.gridTitle.detail')}</Typography>
          {Object.keys(aggregateDetailMap).length > 0 && renderTabs()}
          <StyledAgGrid defaultColDef={defaultColDef} coldef={colDef} rows={rows} gridOptions={gridOptions} onFirstDataRendered={firstDataRendered} />
        </Box>
      </Grid>
    </Grid>
  );

  return Object.is(aggregateDetailMap, {}) ? null : render;
};
