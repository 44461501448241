import { Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { replaceURLWithHTMLLinks } from 'src/utils/replaceURLWithHTMLLinks';

export const EnqueteDescriptionPreviewBox: React.FC = () => {
  const questionDescriptionJpn = useSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);

  return (
    <>
      <Typography style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{replaceURLWithHTMLLinks(questionDescriptionJpn)}</Typography>
      <Divider sx={{ my: 2 }} />
      <Typography style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{replaceURLWithHTMLLinks(questionDescriptionEng)}</Typography>
    </>
  );
};
