import { Grid, Stack } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TemporaryOrganizationDataTypes } from 'src/api/useTemporaryOrganizationApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { useAppSelector } from 'src/redux/store';
import useTemporaryOrganization from '../hooks/useTemporaryOrganization';
import SegmentInputForm from './SegmentInputForm';
import SegmentPullDown from './SegmentPullDown';
import { CreateOrgTypeList } from './TemporaryOrganizationTab';

interface componentTypes {
  inputData: TemporaryOrganizationDataTypes;
  createType: CreateOrgTypeList | null;
  isEdit: boolean;
  surveyDetailId: string;
}

export enum SegmentTypeList {
  company = 'company',
  division = 'division',
  sbu = 'sbu',
  department = 'department',
  group = 'group',
  region = 'region',
  country = 'country',
  rank = 'rank',
}

export enum orgLevelTypeList {
  COMPANY = 'COMPANY',
  DIVISION = 'DIVISION',
  SBU = 'SBU',
  DEPARTMENT = 'DEPARTMENT',
}

const TemporaryOrganizationContent: React.FC<componentTypes> = memo((props) => {
  const { inputData, createType, isEdit, surveyDetailId } = props;
  const { t } = useTranslation();

  const {
    companyCode,
    divisionCode,
    sbuCode,
    departmentCode,
    groupCode,
    companyNameJpn,
    divisionNameJpn,
    sbuNameJpn,
    departmentNameJpn,
    groupNameJpn,
    companyNameEng,
    divisionNameEng,
    sbuNameEng,
    departmentNameEng,
    groupNameEng,
    isCheckDivision,
    isCheckSbu,
    isCheckDepartment,
    isCheckGroup,
    divisionOptionsList,
    sbuOptionsList,
    departmentOptionsList,
    groupOptionsList,
    autocompleteDivisionProps,
    autocompleteSbuProps,
    autocompleteDepartmentProps,
    autocompleteGroupProps,
    autocompleteRegionProps,
    autocompleteCountryProps,
    autocompleteRankProps,
    setCompanyNameJpn,
    setDivisionNameJpn,
    setSbuNameJpn,
    setDepartmentNameJpn,
    setGroupNameJpn,
    setCompanyNameEng,
    setDivisionNameEng,
    setSbuNameEng,
    setDepartmentNameEng,
    setGroupNameEng,
    callbackCheckBoxDivision,
    callbackCheckBoxSbu,
    callbackCheckBoxDepartment,
    callbackCheckBoxGroup,
    insertData,
    updateData,
  } = useTemporaryOrganization({ inputData, createType, surveyDetailId });

  // バリデーション
  const isErrorCompanyNames = useAppSelector((state) => state.temporaryOrganization.isErrorCompanyNames);
  const isErrorDivisionNames = useAppSelector((state) => state.temporaryOrganization.isErrorDivisionNames);
  const isErrorDivisionPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorDivisionPullDown);
  const isErrorSbuNames = useAppSelector((state) => state.temporaryOrganization.isErrorSbuNames);
  const isErrorSbuPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorSbuPullDown);
  const isErrorDepartmentNames = useAppSelector((state) => state.temporaryOrganization.isErrorDepartmentNames);
  const isErrorDepartmentPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorDepartmentPullDown);
  const isErrorGroupNames = useAppSelector((state) => state.temporaryOrganization.isErrorGroupNames);
  const isErrorGroupPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorGroupPullDown);
  const isErrorRegionPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorRegionPullDown);
  const isErrorCountryPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorCountryPullDown);
  const isErrorRankPullDown = useAppSelector((state) => state.temporaryOrganization.isErrorRankPullDown);

  return (
    <>
      {/** Company **/}
      <SegmentInputForm
        segmentType={SegmentTypeList.company}
        segmentCode={companyCode}
        segmentNameJpn={t('enqueteCreate.temporaryOrganization.modal.form.companyNameJpn')}
        segmentNameEng={t('enqueteCreate.temporaryOrganization.modal.form.companyNameEng')}
        segmentDataJpn={companyNameJpn}
        setSegmentDataJpn={setCompanyNameJpn}
        segmentDataEng={companyNameEng}
        setSegmentDataEng={setCompanyNameEng}
        createType={createType}
        isEdit={isEdit}
        isChecked={null}
        optionsList={[]}
        autocompleteProps={null}
        checkboxChangeCallback={null}
        validationNames={isErrorCompanyNames}
        validationPullDown={null}
      />

      {/** Division **/}
      <SegmentInputForm
        segmentType={SegmentTypeList.division}
        segmentCode={divisionCode}
        segmentNameJpn={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.divisionTypeTtcNameJpn')
            : t('enqueteCreate.temporaryOrganization.modal.form.divisionTypeGroupNameJpn')
        }
        segmentNameEng={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.divisionTypeTtcNameEng')
            : t('enqueteCreate.temporaryOrganization.modal.form.divisionTypeGroupNameEng')
        }
        segmentDataJpn={divisionNameJpn}
        setSegmentDataJpn={setDivisionNameJpn}
        segmentDataEng={divisionNameEng}
        setSegmentDataEng={setDivisionNameEng}
        createType={createType}
        isEdit={isEdit}
        isChecked={isCheckDivision}
        optionsList={divisionOptionsList}
        autocompleteProps={autocompleteDivisionProps}
        checkboxChangeCallback={callbackCheckBoxDivision}
        validationNames={isErrorDivisionNames}
        validationPullDown={isErrorDivisionPullDown}
      />

      {/** SBU **/}
      <SegmentInputForm
        segmentType={SegmentTypeList.sbu}
        segmentCode={sbuCode}
        segmentNameJpn={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.sbuTypeTtcNameJpn')
            : t('enqueteCreate.temporaryOrganization.modal.form.sbuTypeGroupNameJpn')
        }
        segmentNameEng={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.sbuTypeTtcNameEng')
            : t('enqueteCreate.temporaryOrganization.modal.form.sbuTypeGroupNameEng')
        }
        segmentDataJpn={sbuNameJpn}
        setSegmentDataJpn={setSbuNameJpn}
        segmentDataEng={sbuNameEng}
        setSegmentDataEng={setSbuNameEng}
        createType={createType}
        isEdit={isEdit}
        isChecked={isCheckSbu}
        optionsList={sbuOptionsList}
        autocompleteProps={autocompleteSbuProps}
        checkboxChangeCallback={callbackCheckBoxSbu}
        validationNames={isErrorSbuNames}
        validationPullDown={isErrorSbuPullDown}
      />

      {/** Department **/}
      <SegmentInputForm
        segmentType={SegmentTypeList.department}
        segmentCode={departmentCode}
        segmentNameJpn={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.departmentTypeTtcNameJpn')
            : t('enqueteCreate.temporaryOrganization.modal.form.departmentTypeGroupNameJpn')
        }
        segmentNameEng={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.departmentTypeTtcNameEng')
            : t('enqueteCreate.temporaryOrganization.modal.form.departmentTypeGroupNameEng')
        }
        segmentDataJpn={departmentNameJpn}
        setSegmentDataJpn={setDepartmentNameJpn}
        segmentDataEng={departmentNameEng}
        setSegmentDataEng={setDepartmentNameEng}
        createType={createType}
        isEdit={isEdit}
        isChecked={isCheckDepartment}
        optionsList={departmentOptionsList}
        autocompleteProps={autocompleteDepartmentProps}
        checkboxChangeCallback={callbackCheckBoxDepartment}
        validationNames={isErrorDepartmentNames}
        validationPullDown={isErrorDepartmentPullDown}
      />

      {/** Group **/}
      <SegmentInputForm
        segmentType={SegmentTypeList.group}
        segmentCode={groupCode}
        segmentNameJpn={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.groupTypeTtcNameJpn')
            : t('enqueteCreate.temporaryOrganization.modal.form.groupTypeGroupNameJpn')
        }
        segmentNameEng={
          createType === CreateOrgTypeList.ttc
            ? t('enqueteCreate.temporaryOrganization.modal.form.groupTypeTtcNameEng')
            : t('enqueteCreate.temporaryOrganization.modal.form.groupTypeGroupNameEng')
        }
        segmentDataJpn={groupNameJpn}
        setSegmentDataJpn={setGroupNameJpn}
        segmentDataEng={groupNameEng}
        setSegmentDataEng={setGroupNameEng}
        createType={createType}
        isEdit={isEdit}
        isChecked={createType === CreateOrgTypeList.group ? isCheckGroup : null}
        optionsList={groupOptionsList}
        autocompleteProps={autocompleteGroupProps}
        checkboxChangeCallback={callbackCheckBoxGroup}
        validationNames={isErrorGroupNames}
        validationPullDown={isErrorGroupPullDown}
      />

      {/** 単体作成/編集時は非表示 **/}
      {((isEdit && companyCode !== 'S500') || (!isEdit && createType !== CreateOrgTypeList.ttc)) && (
        <Grid container>
          {/** Region **/}
          <Grid item xs={4} sx={{ mr: 2 }}>
            <SegmentPullDown
              id={autocompleteRegionProps.id}
              label={autocompleteRegionProps.label}
              keyCode={autocompleteRegionProps.key}
              list={autocompleteRegionProps.list}
              onChange={autocompleteRegionProps.onChange}
              validationPullDown={isErrorRegionPullDown}
            />
          </Grid>

          {/** Company **/}
          <Grid item xs={4} sx={{ mr: 2 }}>
            <SegmentPullDown
              id={autocompleteCountryProps.id}
              label={autocompleteCountryProps.label}
              keyCode={autocompleteCountryProps.key}
              list={autocompleteCountryProps.list}
              onChange={autocompleteCountryProps.onChange}
              validationPullDown={isErrorCountryPullDown}
            />
          </Grid>

          {/** Rank **/}
          <Grid item xs={3}>
            <SegmentPullDown
              id={autocompleteRankProps.id}
              label={autocompleteRankProps.label}
              keyCode={autocompleteRankProps.key}
              list={autocompleteRankProps.list}
              onChange={autocompleteRankProps.onChange}
              validationPullDown={isErrorRankPullDown}
            />
          </Grid>
        </Grid>
      )}

      {/* modal bottom actions */}
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack direction={'row-reverse'}>
            <StyledButton
              onClick={() => {
                isEdit ? updateData() : insertData();
              }}
              children={isEdit ? t('button.save') : t('button.create')}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});

export default TemporaryOrganizationContent;
