import { Box, Stack } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import MailIcon from '@mui/icons-material/Mail';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useEffect } from 'react';
import { useEnqueteConfirmListGrid } from 'src/features/general/enquete-confirm/components/pages/list/hooks/useEnqueteConfirmListGrid';
import { joinEmails } from 'src/utils/joinEmails';
import { UseUserTooltip } from 'src/hooks/useUserTooltip';

export interface Props {
  id: string;
  bluePageEmailAddress: string;
  name: string;
  reactContainer?: HTMLElement;
}

export const EnqueteConfirmListUserTooltipBox = (props: Props) => {
  const hook = useEnqueteConfirmListGrid();
  const { organizationInfo, assignedCompanyInfo, handlerOrganizationInfo, handlerAssignedCompanyInfo } = UseUserTooltip();

  // cssを追加
  props.reactContainer!.classList.add('ag-tooltip');

  useEffect(() => {
    hook.getUserInfo(props.id, {
      onSuccess: (data) => {
        // 組織情報
        handlerOrganizationInfo(data);
        // 出向情報
        handlerAssignedCompanyInfo(data);
      },
    });
  }, []); // eslint-disable-line

  return (
    <Box m={1}>
      <h4>{props.name}</h4>
      <Stack direction="row" m={1} spacing={1} alignItems="center">
        <BusinessIcon />
        <Box>{organizationInfo}</Box>
      </Stack>
      {assignedCompanyInfo && (
        <Stack direction="row" m={1} spacing={1} alignItems="center">
          <HandshakeIcon />
          <Box>{assignedCompanyInfo}</Box>
        </Stack>
      )}
      <Stack direction="row" m={1} spacing={1} alignItems="center">
        <MailIcon />
        <Box>{joinEmails(props.id, props.bluePageEmailAddress)}</Box>
      </Stack>
    </Box>
  );
};
