import { Grid, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { EnqueteThread } from 'src/api/getThreadsApi';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { useThreads } from 'src/features/general/enquete-threads/components/pages/list/hooks/useThreads';
import { useAppSelector } from 'src/redux/store';
import { checkIfAPIResponseHasError } from 'src/utils/checkIfAPIResponseHasError';
import { ThreadCard } from './ThreadCard';
import { ThreadHeaderBox } from './ThreadHeaderBox';

export const EnqueteThreadListLayout = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { page, count, setPage } = useThreads();

  const threadStatus = useAppSelector((state) => state.enqueteThreads.status);
  const threadSelector = useAppSelector<EnqueteThread[]>((state) => state.enqueteThreads.threads.enqueteThreads);
  const hasError = checkIfAPIResponseHasError(threadStatus);

  if (threadStatus === 'pending' || threadStatus === '') {
    return <StyledLoading isOpen={true} />;
  }

  if (hasError) {
    enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteThread.pageTitle') }), { variant: 'error' });
  }

  return (
    <Grid container direction="column" sx={{ p: 0 }} gap={2}>
      <ThreadHeaderBox />
      {threadSelector?.map((thread: EnqueteThread) => (
        <ThreadCard thread={thread} key={thread.id} />
      ))}
      <Pagination count={count} page={page} onChange={(_, value) => setPage(value)} sx={{ display: 'inline', alignSelf: 'center', m: 2 }} />
    </Grid>
  );
};
