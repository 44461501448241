import { AnswerItem, GeneralEnquete, Question, QuestionState } from 'src/@types/generalEnquete';
import { SurveyDetailEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

/**
 * paramをもとに言語変換後questions（グローバルステートに設定するためのquestions（回答データあり）を生成する。
 * 返却値を生成する際は、questionsInのanswerItemを
 * questionsOutのanswerItemに設定するが、answerItem.labelをquestionsOutのquestionItem.labelで置き換える。
 * @param questionsIn 言語変換前questions（グローバルステートに保持していた）
 * @param questionsOut 言語変換後questions（グローバルステートに設定するためのquestions（回答データなし））
 * @returns Question[]　言語変換後questions（グローバルステートに設定するためのquestions（回答データあり））
 */
export const createTranslationAnswerQuestions = (
  questionsIn: Question[], //
  questionsOut: Question[], //
): Question[] => {
  questionsIn.forEach((question, index) => {
    const answerItems: AnswerItem[] = [];

    if ((question.answerItem as AnswerItem[]).length !== 0) {
      (question.answerItem as AnswerItem[]).forEach((answerItem, answerItemIndex) => {
        const answerItemData: AnswerItem = {
          id: answerItem.id,
          label: questionsOut[index].questionItem[answerItemIndex].label,
          other: answerItem.other,
          questionItemId: answerItem.questionItemId,
          value: answerItem.value,
        };
        answerItems.push(answerItemData);
      });
      questionsOut[index].answerItem = answerItems;
    }
  });
  return questionsOut;
};

/**
 * 回答保存時のquestionsJpn、questionsEng作成
 * 引数のグローバルステートquestionsに保持している回答（answerItem）を
 * 引数のsurveyDetailDataをもとに、別の言語（currentLangがjaの場合はen、enの場合はjaの）回答を生成する。
 * @param currentLang useLocales() で取得できるcurrentLang.value　の値
 * @param surveyDetailData 回答フォーム初期表示時点で取得しているSurveyDetailEntity
 * @param questions 回答（answerItem）保持済みのグローバルステートquestions
 * @param questionStates 回答画面表示制御データ のグローバルステートquestionStates
 * @returns { jpn: Question[]; eng: Question[] }
 */
export const createAnswerQuestions = (
  currentLang: string, //
  surveyDetailData: SurveyDetailEntity, //
  questions: Question[], //
  questionStates: QuestionState[] | null,
): { jpn: Question[]; eng: Question[] } => {
  // 返却用　questions
  let questionsJpn: Question[] = [];
  let questionsEng: Question[] = [];

  let newQuestions: Question[] = [];

  if (questionStates !== null) {
    // 非表示、非活性の質問の回答をクリアする
    newQuestions = questionStates.map((questionState, index) => {
      if (questionState.state === 'hidden' || questionState.state === 'disabled') {
        const newquestion: Question = {
          id: questions[index].id,
          title: questions[index].title,
          inputType: questions[index].inputType,
          questionItem: questions[index].questionItem,
          validation: questions[index].validation,
          answerItem: [],
          conditions: questions[index].conditions,
        };
        return newquestion;
      } else {
        return questions[index];
      }
    });
  } else {
    newQuestions = questions;
  }

  if (currentLang === 'ja') {
    questionsJpn = newQuestions;
    questionsEng = createTranslationAnswerQuestions(
      questionsJpn,
      ((surveyDetailData.surveyFormData.formData.tabData[0] as GeneralEnquete).questionsEng as Question[]).map((question) => ({
        ...question,
      })),
    );
  } else {
    questionsEng = newQuestions;
    questionsJpn = createTranslationAnswerQuestions(
      questionsEng,
      ((surveyDetailData.surveyFormData.formData.tabData[0] as GeneralEnquete).questionsJpn as Question[]).map((question) => ({
        ...question,
      })),
    );
  }
  return { jpn: questionsJpn, eng: questionsEng };
};
