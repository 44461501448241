import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DivisionRegionEditorDataTypes, RequestVisibleConditionTypes, useVisibleConditionsApi } from 'src/api/useVisibleConditionsApi';
import { AccessRoleFieldProps, SearchConditionProps, SearchEditorProps } from 'src/components/app-components/bonsai/SelectEditorModal';
import { VISIBLE_TYPE, VisibleType } from 'src/constants';
import {
  SearchOrganizationMultiConditionTypes,
  useSearchOrganizationConditions,
} from 'src/features/general/enquete-create/hooks/useSearchOrganizationConditions';
import useLocales from 'src/hooks/useLocales';
import useSuggestUser from 'src/hooks/useSuggestUser';
import { useAppSelector } from 'src/redux/store';
import { joinEmails } from 'src/utils/joinEmails';
import { addSuffix, switchLabel } from 'src/utils/labelUtils';
import { useDivisionRegionEditor } from './useDivisionRegionEditor';

type OmitSelectedTypes = 'id';
export interface SelectedRowDataTypes extends Omit<DivisionRegionEditorDataTypes, OmitSelectedTypes> {}

const initSelectedRowData: SelectedRowDataTypes = {
  email: '',
  bluePageEmailAddress: '',
  userNameJpn: '',
  userNameEng: '',
  userCompanyCode: '',
  userCompanyNameJpn: '',
  userCompanyNameEng: '',
  userCompanyAbbreviation: '',
  assignedCompanyNameJpn: '',
  assignedCompanyNameEng: '',
  userDivisionCode: '',
  userDivisionNameJpn: '',
  userDivisionNameEng: '',
  userSbuCode: '',
  userSbuNameJpn: '',
  userSbuNameEng: '',
  userDepartmentCode: '',
  userDepartmentNameJpn: '',
  userDepartmentNameEng: '',
  userGroupCode: '',
  userGroupNameJpn: '',
  userGroupNameEng: '',
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  sbuCode: '',
  sbuNameJpn: '',
  sbuNameEng: '',
  departmentCode: '',
  departmentNameJpn: '',
  departmentNameEng: '',
  groupCode: '',
  groupNameJpn: '',
  groupNameEng: '',
  regionCode: '',
  regionNameJpn: '',
  regionNameEng: '',
  countryCode: '',
  countryNameJpn: '',
  countryNameEng: '',
  accessRoleId: '',
  visibleType: VISIBLE_TYPE.SELECT,
};

// 検索用条件
export interface SearchConditonProps {
  divisionCode: string;
  sbuCode: string;
  departmentCode: string;
  groupCode: string;
  regionCode: string;
  countryCode: string;
}

// 検索用条件初期値
export const initSearchCondition: SearchConditonProps = {
  divisionCode: '',
  sbuCode: '',
  departmentCode: '',
  groupCode: '',
  regionCode: '',
  countryCode: '',
};

interface Props {
  isEdit: boolean;
  targetData: DivisionRegionEditorDataTypes;
  surveyDetailId: string;
  registeredData: DivisionRegionEditorDataTypes[] | undefined;
}

export const useDivisionRegionEditorDetail = (props: Props) => {
  const { isEdit, targetData, surveyDetailId, registeredData } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isJapanese } = useLocales();

  const labelPrefix = 'enqueteCreate.selectRespondentAndExportModal.condition';

  const { createVisibleCondition, updateVisibleCondition } = useVisibleConditionsApi();
  const { updateRefreshCounter, closeEditModal } = useDivisionRegionEditor();
  const { userSuggest, updateUserSuggest } = useSuggestUser();

  // 検索用リスト
  const { isLoading, divisions, sbus, departments, groups, countries, regions, makeSbus, makeDepartments, makeGroups } =
    useSearchOrganizationConditions({ surveyDetailId: surveyDetailId });

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isOpenEditModal = useAppSelector((state) => state.divisionRegionEditor.isOpenEditModal);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [searchCondition, setSearchCondition] = useState<SearchConditonProps>(initSearchCondition);
  const [selectedDivisionRegionEditorData, setSelectedDivisionRegionEditorData] = useState<SelectedRowDataTypes>(initSelectedRowData);
  const [selectedEditor, setSelectedEditor] = useState<string>('');
  const [selectedRowData, setSelectedRowData] = useState<SelectedRowDataTypes[]>([]);
  const [isEnableSelect, setIsEnableSelect] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isTargetSelect, setTargetSelect] = useState<boolean>(true);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // AutoCompleteコールバック定義
  const handleAutoCompleteChange = async (key: string, value: string | undefined, list: SearchOrganizationMultiConditionTypes[]) => {
    const cond = { ...searchCondition };
    setIsSearchLoading(true);
    const updatedVisibleCondition: { [key in keyof SelectedRowDataTypes]?: SelectedRowDataTypes[key] } = {};
    switch (key) {
      case 'division':
        cond.divisionCode = value ? value : '';
        await makeSbus(cond.divisionCode ? cond.divisionCode : undefined);
        await makeDepartments(cond.divisionCode ? cond.divisionCode : undefined, cond.sbuCode ? cond.sbuCode : undefined);
        await makeGroups(
          cond.divisionCode ? cond.divisionCode : undefined,
          cond.sbuCode ? cond.sbuCode : undefined,
          cond.departmentCode ? cond.departmentCode : undefined,
        );
        updatedVisibleCondition.sbuCode = '';
        updatedVisibleCondition.sbuNameEng = '';
        updatedVisibleCondition.sbuNameJpn = '';
        updatedVisibleCondition.departmentCode = '';
        updatedVisibleCondition.departmentNameEng = '';
        updatedVisibleCondition.departmentNameJpn = '';
        updatedVisibleCondition.groupCode = '';
        updatedVisibleCondition.groupNameEng = '';
        updatedVisibleCondition.groupNameJpn = '';
        break;

      case 'sbu':
        cond.sbuCode = value ? value : '';
        await makeDepartments(cond.divisionCode ? cond.divisionCode : undefined, cond.sbuCode ? cond.sbuCode : undefined);
        await makeGroups(
          cond.divisionCode ? cond.divisionCode : undefined,
          cond.sbuCode ? cond.sbuCode : undefined,
          cond.departmentCode ? cond.departmentCode : undefined,
        );
        updatedVisibleCondition.departmentCode = '';
        updatedVisibleCondition.departmentNameEng = '';
        updatedVisibleCondition.departmentNameJpn = '';
        updatedVisibleCondition.groupCode = '';
        updatedVisibleCondition.groupNameEng = '';
        updatedVisibleCondition.groupNameJpn = '';
        break;

      case 'department':
        cond.departmentCode = value ? value : '';
        await makeGroups(
          cond.divisionCode ? cond.divisionCode : undefined,
          cond.sbuCode ? cond.sbuCode : undefined,
          cond.departmentCode ? cond.departmentCode : undefined,
        );
        updatedVisibleCondition.groupCode = '';
        updatedVisibleCondition.groupNameEng = '';
        updatedVisibleCondition.groupNameJpn = '';
        break;

      case 'group':
        cond.groupCode = value ? value : '';
        break;

      case 'region':
        cond.regionCode = value ? value : '';
        break;

      case 'country':
        cond.countryCode = value ? value : '';
        break;
    }

    // 表示用データ抽出
    const selectedSegment = list.filter((v) => v.key === value);
    // 登録用オブジェクトに格納
    setSelectedDivisionRegionEditorData({
      ...selectedDivisionRegionEditorData,
      ...{
        [`${key}Code`]: value ? value : '',
        [`${key}NameJpn`]: value ? selectedSegment[0].label.jpn : '',
        [`${key}NameEng`]: value ? selectedSegment[0].label.eng : '',
      },
      ...updatedVisibleCondition,
    });
    setSearchCondition({
      divisionCode: cond.divisionCode,
      sbuCode: cond.sbuCode,
      departmentCode: cond.departmentCode,
      groupCode: cond.groupCode,
      regionCode: cond.regionCode,
      countryCode: cond.countryCode,
    });
    setIsSearchLoading(false);
  };

  // AutoComplete構成内容定義
  const makeSearchConditionProp = (
    key: string,
    style: { xs: number },
    value: string | undefined | null,
    multiLabelList: SearchOrganizationMultiConditionTypes[],
    isDisable: boolean = false,
  ): SearchConditionProps => {
    const selectedValue = value === undefined ? null : value;
    const isBranchKey = key === 'region' || key === 'country';

    const list = multiLabelList.map((v) => {
      return {
        ...v,
        ...{
          // 極・国はコードを含めない
          label: isBranchKey
            ? switchLabel(v.label.jpn, v.label.eng, isJapanese)
            : addSuffix(switchLabel(v.label.jpn, v.label.eng, isJapanese), v.key),
        },
      };
    });

    return {
      id: key,
      label: isBranchKey ? t(`common.${key}`) : t(`${labelPrefix}.${key}`),
      style,
      type: 'autocomplete',
      props: {
        value: selectedValue,
        list,
        onChange: async (value) => {
          if (!isModified) setIsModified(true);
          await handleAutoCompleteChange(key, value, multiLabelList);
        },
      },
      disabled: isDisable,
    };
  };

  const onChangeAccessRoleId = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    // 登録用オブジェクトに格納
    setSelectedDivisionRegionEditorData({
      ...selectedDivisionRegionEditorData,
      ...{
        accessRoleId: event.target.value as string,
      },
    });
  };

  // 担当者が変更された時の処理
  const onChangeEditor = (value: string | null) => {
    // 選択されている文字列を更新
    setSelectedEditor(value ? value : '');
  };

  // 担当者名整形(編集時用)
  const formatEditUserName = (data: DivisionRegionEditorDataTypes) => {
    const isToyotsu = data.userCompanyCode === 'S500';
    const getLocalizedName = (isJapanese: boolean, japaneseName: string | null, englishName: string | null) => {
      return isJapanese ? japaneseName?.trim() || englishName?.trim() : englishName?.trim() || japaneseName?.trim() || '';
    };
    const userName = getLocalizedName(isJapanese, data.userNameJpn, data.userNameEng);
    const companyName = getLocalizedName(isJapanese, data.userCompanyNameJpn, data.userCompanyNameEng);
    const divisionName = getLocalizedName(isJapanese, data.userDivisionNameJpn, data.userDivisionNameEng);
    const sbuName = getLocalizedName(isJapanese, data.userSbuNameJpn, data.userSbuNameEng);
    const departmentName = getLocalizedName(isJapanese, data.userDepartmentNameJpn, data.userDepartmentNameEng);
    const groupName = getLocalizedName(isJapanese, data.userGroupNameJpn, data.userGroupNameEng);
    const userEmail = data.email ? joinEmails(data.email, data.bluePageEmailAddress) : '';

    const organizationStr = isToyotsu
      ? `${[companyName, divisionName, sbuName, departmentName, groupName].filter(Boolean).join(' - ')}`
      : `${[companyName].filter(Boolean).join(' - ')}`;
    return `${[userName, organizationStr, userEmail].filter(Boolean).join(' - ')}`;
  };

  // 選択処理
  const onSelected = async () => {
    // 担当者選択バリデーション
    const editor = userSuggest.find((person) => person.key === selectedEditor);
    if (!editor) return;

    // 選択用データ整形
    const addRowData: SelectedRowDataTypes = {
      email: editor.entity.email ? editor.entity.email : '',
      bluePageEmailAddress: editor.entity.bluePageEmailAddress ? editor.entity.bluePageEmailAddress : '',
      userNameJpn: editor.entity.fullNameJpn ? editor.entity.fullNameJpn : '',
      userNameEng: editor.entity.fullNameEng ? editor.entity.fullNameEng : '',
      userCompanyCode: editor.entity.companyCode ? editor.entity.companyCode : '',
      userCompanyNameJpn: editor.entity.companyNameJpn ? editor.entity.companyNameJpn : '',
      userCompanyNameEng: editor.entity.companyNameEng ? editor.entity.companyNameEng : '',
      userCompanyAbbreviation: editor.entity.companyAbbreviation ?? '',
      assignedCompanyNameJpn: editor.entity.assignedCompanyNameJpn ? editor.entity.assignedCompanyNameJpn : '',
      assignedCompanyNameEng: editor.entity.assignedCompanyNameEng ? editor.entity.assignedCompanyNameEng : '',
      userDivisionCode: editor.entity.divisionCode ? editor.entity.divisionCode : '',
      userDivisionNameJpn: editor.entity.divisionNameJpn ? editor.entity.divisionNameJpn : '',
      userDivisionNameEng: editor.entity.divisionNameEng ? editor.entity.divisionNameEng : '',
      userSbuCode: editor.entity.sbuCode ? editor.entity.sbuCode : '',
      userSbuNameJpn: editor.entity.sbuNameJpn ? editor.entity.sbuNameJpn : '',
      userSbuNameEng: editor.entity.sbuNameEng ? editor.entity.sbuNameEng : '',
      userDepartmentCode: editor.entity.departmentCode ? editor.entity.departmentCode : '',
      userDepartmentNameJpn: editor.entity.departmentNameJpn ? editor.entity.departmentNameJpn : '',
      userDepartmentNameEng: editor.entity.departmentNameEng ? editor.entity.departmentNameEng : '',
      userGroupCode: editor.entity.groupCode ? editor.entity.groupCode : '',
      userGroupNameJpn: editor.entity.groupNameJpn ? editor.entity.groupNameJpn : '',
      userGroupNameEng: editor.entity.groupNameEng ? editor.entity.groupNameEng : '',
      divisionCode: selectedDivisionRegionEditorData.divisionCode ? selectedDivisionRegionEditorData.divisionCode : '',
      divisionNameJpn: selectedDivisionRegionEditorData.divisionNameJpn ? selectedDivisionRegionEditorData.divisionNameJpn : '',
      divisionNameEng: selectedDivisionRegionEditorData.divisionNameEng ? selectedDivisionRegionEditorData.divisionNameEng : '',
      sbuCode: selectedDivisionRegionEditorData.sbuCode ? selectedDivisionRegionEditorData.sbuCode : '',
      sbuNameJpn: selectedDivisionRegionEditorData.sbuNameJpn ? selectedDivisionRegionEditorData.sbuNameJpn : '',
      sbuNameEng: selectedDivisionRegionEditorData.sbuNameEng ? selectedDivisionRegionEditorData.sbuNameEng : '',
      departmentCode: selectedDivisionRegionEditorData.departmentCode ? selectedDivisionRegionEditorData.departmentCode : '',
      departmentNameJpn: selectedDivisionRegionEditorData.departmentNameJpn ? selectedDivisionRegionEditorData.departmentNameJpn : '',
      departmentNameEng: selectedDivisionRegionEditorData.departmentNameEng ? selectedDivisionRegionEditorData.departmentNameEng : '',
      groupCode: selectedDivisionRegionEditorData.groupCode ? selectedDivisionRegionEditorData.groupCode : '',
      groupNameJpn: selectedDivisionRegionEditorData.groupNameJpn ? selectedDivisionRegionEditorData.groupNameJpn : '',
      groupNameEng: selectedDivisionRegionEditorData.groupNameEng ? selectedDivisionRegionEditorData.groupNameEng : '',
      regionCode: selectedDivisionRegionEditorData.regionCode ? selectedDivisionRegionEditorData.regionCode : '',
      regionNameJpn: selectedDivisionRegionEditorData.regionNameJpn ? selectedDivisionRegionEditorData.regionNameJpn : '',
      regionNameEng: selectedDivisionRegionEditorData.regionNameEng ? selectedDivisionRegionEditorData.regionNameEng : '',
      countryCode: selectedDivisionRegionEditorData.countryCode ? selectedDivisionRegionEditorData.countryCode : '',
      countryNameJpn: selectedDivisionRegionEditorData.countryNameJpn ? selectedDivisionRegionEditorData.countryNameJpn : '',
      countryNameEng: selectedDivisionRegionEditorData.countryNameEng ? selectedDivisionRegionEditorData.countryNameEng : '',
      accessRoleId: selectedDivisionRegionEditorData.accessRoleId,
      visibleType: selectedDivisionRegionEditorData.visibleType,
    };

    // 既にデータ登録されているか検証
    const alreadyRegistered = compareRowData(addRowData, registeredData);
    if (!alreadyRegistered)
      return enqueueSnackbar(t('enqueteCreate.divisionRegionEditor.message.failedRegisterVisibleCondition'), { variant: 'warning' });
    if (alreadyRegistered.length !== 0)
      return enqueueSnackbar(t('enqueteCreate.divisionRegionEditor.message.alreadyRegistered'), { variant: 'warning' });

    // 重複確認
    const exists = compareRowData(addRowData, selectedRowData);
    if (!exists || exists.length !== 0)
      return enqueueSnackbar(t('enqueteCreate.divisionRegionEditor.message.alreadySelected'), { variant: 'warning' });

    // 選択リストに追加
    setSelectedRowData([...selectedRowData, addRowData]);
  };

  // 登録処理
  const onRegister = async () => {
    const requestList: RequestVisibleConditionTypes[] = selectedRowData.map((v) => {
      return formatRequestData(v);
    });

    // 保存
    await createVisibleCondition(surveyDetailId!, requestList);

    updateRefreshCounter();
    closeEditModal();
  };

  // 更新処理
  const onUpdate = async () => {
    // 編集操作がされているか確認
    // 編集操作がされていなければ、そのまま更新
    // 編集操作がされている場合の処理
    if (isModified) {
      // 編集前と差分があるか確認
      const sameRowData = compareRowData(targetData, [selectedDivisionRegionEditorData]);
      if (sameRowData && sameRowData?.length > 0) {
        // 編集差分がなければ、更新
        await updateProcessing();
        return;
      }

      // 編集差分があれば、編集された内容が既存のデータで登録されているか検証
      const alreadyRegistered = compareRowData(selectedDivisionRegionEditorData, registeredData);
      if (!alreadyRegistered)
        return enqueueSnackbar(t('enqueteCreate.divisionRegionEditor.message.failedRegisterVisibleCondition'), { variant: 'warning' });
      if (alreadyRegistered.length !== 0)
        return enqueueSnackbar(t('enqueteCreate.divisionRegionEditor.message.alreadyRegistered'), { variant: 'warning' });

      // 重複が問題なければ、更新
      await updateProcessing();
      return;
    }

    // 更新
    await updateProcessing();
  };

  // データ更新、グリッド更新、モーダルクローズ
  const updateProcessing = async () => {
    await updateVisibleCondition(surveyDetailId!, targetData.id, formatRequestData(selectedDivisionRegionEditorData));

    updateRefreshCounter();
    closeEditModal();
  };

  // 選択データ比較
  const compareRowData = (
    comparisonSource: SelectedRowDataTypes | DivisionRegionEditorDataTypes,
    comparisonTarget: SelectedRowDataTypes[] | DivisionRegionEditorDataTypes[] | undefined,
  ) => {
    return (
      comparisonTarget &&
      comparisonTarget.filter((row) => JSON.stringify(formatRequestData(row, true)) === JSON.stringify(formatRequestData(comparisonSource, true)))
    );
  };

  // リクエスト用にデータ整形
  const formatRequestData = (data: SelectedRowDataTypes | DivisionRegionEditorDataTypes, isCheck: boolean = false) => {
    const formatData = {
      email: data.email ? data.email : '',
      bluePageEmailAddress: data.bluePageEmailAddress ? data.bluePageEmailAddress : null,
      companyCode: '', // 項目がないため毎回空文字を指定
      divisionCode: isDisplay(data.divisionCode, data.visibleType),
      sbuCode: isDisplay(data.sbuCode, data.visibleType),
      departmentCode: isDisplay(data.departmentCode, data.visibleType),
      groupCode: isDisplay(data.groupCode, data.visibleType),
      regionCode: isDisplay(data.regionCode, data.visibleType),
      countryCode: isDisplay(data.countryCode, data.visibleType),
      accessRoleId: data.accessRoleId,
      visibleType: data.visibleType,
    };
    if (isCheck) {
      // アクセス権限はデータ比較に関係ないため、比較時は対象から除く
      formatData.accessRoleId = '';
      formatData.visibleType = VISIBLE_TYPE.ALL;
    }
    return formatData;
  };

  const isDisplay = (code: string | null, type: VisibleType): string => {
    return code && type !== VISIBLE_TYPE.ALL ? code : '';
  };

  // 未選択バリデーション
  const checkSelectedValue = () => {
    const isSelectedOrganization =
      (!!selectedDivisionRegionEditorData.divisionCode ||
        !!selectedDivisionRegionEditorData.sbuCode ||
        !!selectedDivisionRegionEditorData.departmentCode ||
        !!selectedDivisionRegionEditorData.groupCode ||
        !!selectedDivisionRegionEditorData.regionCode ||
        !!selectedDivisionRegionEditorData.countryCode ||
        VISIBLE_TYPE.ALL === selectedDivisionRegionEditorData.visibleType) &&
      !!selectedDivisionRegionEditorData.accessRoleId;
    setIsEnableSelect(!!selectedEditor && isSelectedOrganization);
  };

  // リセット
  const resetState = () => {
    setSearchCondition(initSearchCondition);
    setSelectedDivisionRegionEditorData(initSelectedRowData);
    setSelectedEditor('');
    setSelectedRowData([]);
    setIsModified(false);
    setTargetSelect(true);
    makeSbus();
    makeDepartments();
    makeGroups();
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  // モーダルクローズ処理
  useEffect(() => {
    if (isOpenEditModal) return;
    resetState();
  }, [isOpenEditModal]); // eslint-disable-line

  // 未選択バリデーション
  useEffect(() => {
    checkSelectedValue();
  }, [selectedDivisionRegionEditorData, selectedEditor, isEnableSelect]); // eslint-disable-line

  // グリッドデータ有無監視
  useEffect(() => {
    setIsSelected(selectedRowData.length > 0);
  }, [selectedRowData]);

  // 編集時処理
  // 編集内容でプルダウンの中身がAutoCompleteされるように修正
  useEffect(() => {
    if (isEdit) {
      setSelectedDivisionRegionEditorData({ ...targetData });
      setSelectedEditor(formatEditUserName(targetData));
      makeSbus(targetData.divisionCode ? targetData.divisionCode : undefined);
      makeDepartments(targetData.divisionCode ? targetData.divisionCode : undefined, targetData.sbuCode ? targetData.sbuCode : undefined);
      makeGroups(
        targetData.divisionCode ? targetData.divisionCode : undefined,
        targetData.sbuCode ? targetData.sbuCode : undefined,
        targetData.departmentCode ? targetData.departmentCode : undefined,
      );
    }
  }, [isEdit, targetData]); // eslint-disable-line

  /***********************************************************************************
   * Definition
   ***********************************************************************************/
  // 組織検索要素定義
  const conditionsForOrganization: SearchConditionProps[][] = [
    [
      makeSearchConditionProp('division', { xs: 6 }, selectedDivisionRegionEditorData.divisionCode, divisions),
      makeSearchConditionProp('sbu', { xs: 6 }, selectedDivisionRegionEditorData.sbuCode, sbus),
    ],
    [
      makeSearchConditionProp('department', { xs: 6 }, selectedDivisionRegionEditorData.departmentCode, departments),
      makeSearchConditionProp('group', { xs: 6 }, selectedDivisionRegionEditorData.groupCode, groups),
    ],
    [
      makeSearchConditionProp(
        'region',
        { xs: 6 },
        selectedDivisionRegionEditorData.regionCode,
        regions,
        !!selectedDivisionRegionEditorData.countryCode,
      ),
      makeSearchConditionProp(
        'country',
        { xs: 6 },
        selectedDivisionRegionEditorData.countryCode,
        countries,
        !!selectedDivisionRegionEditorData.regionCode,
      ),
    ],
  ];

  // 担当者検索要素定義
  const conditionsForPerson: SearchEditorProps[] = [
    {
      label: t('enqueteCreate.divisionRegionEditor.selectEditorModal.selectEditorLabel'),
      value: selectedEditor,
      list: userSuggest.map((resp) => resp.key),
      onChange: onChangeEditor,
      onInputChange: async (v, r) => {
        if (r !== 'reset') await updateUserSuggest(v);
      },
      disabled: isEdit,
    },
  ];

  // 担当者アクセス権限定義
  const accessRoleConditions: AccessRoleFieldProps = {
    label: t('enqueteCreate.divisionRegionEditor.selectEditorModal.accessRoleLabel'),
    value: selectedDivisionRegionEditorData.accessRoleId,
    onChange: async (event: React.ChangeEvent<HTMLSelectElement>) => {
      await onChangeAccessRoleId(event);
    },
  };

  // コンディションタイプ定義
  const visibleCondition = {
    select: {
      label: t('enqueteCreate.divisionRegionEditor.selectEditorModal.targetSelectLabel'),
      value: VISIBLE_TYPE.SELECT,
    },
    all: {
      label: t('enqueteCreate.divisionRegionEditor.selectEditorModal.targetSelectAllLabel'),
      value: VISIBLE_TYPE.ALL,
    },
    value: selectedDivisionRegionEditorData.visibleType,
    onChange: (type: VisibleType) => {
      setTargetSelect(!isTargetSelect); // モーダルの可視制御
      setSelectedDivisionRegionEditorData({
        ...selectedDivisionRegionEditorData,
        ...{
          visibleType: type,
        },
      });
    },
  };

  return {
    conditionsForOrganization,
    conditionsForPerson,
    accessRoleConditions,
    selectedRowData,
    isLoading,
    isSearchLoading,
    isEnableSelect,
    isSelected,
    isTargetSelect,
    setTargetSelect,
    onSelected,
    onRegister,
    onUpdate,
    setSelectedRowData,
    visibleCondition,
  };
};
