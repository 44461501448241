import { TAB_VALUE, TabValue } from '../constants';

/**
 * URLパラメータで受け取ったタブの値が有効なものかチェックする
 * @param tabValue :
 */
export function getValidTabValue(tabValue: string | null | undefined): TabValue {
  if (tabValue && Object.values(TAB_VALUE).includes(tabValue as TabValue)) {
    return tabValue as TabValue;
  }
  return TAB_VALUE.QUESTION;
}
