import { SxProps } from '@mui/material';
import { useSnackbar } from 'notistack5';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { SearchModal } from 'src/components/app-components/bonsai/SearchModal';
import { useSearchConditionList } from '../../../../hooks/useSearchConditionList';
import { useSearchOrganization } from '../../../../hooks/useSearchOrganization';
import { useSearchOrganizationGrid } from '../../../../hooks/useSearchOrganizationGrid';
import { ChangeRespondentConfirmationModal } from './ChangeRespondentConfirmationModal';
import { CurrentRespondentTargetOrgBox } from './CurrentRespondentTargetOrgBox';

interface Props {
  surveyDetailId: string;
  respondentRequestId: string;
  respondentTarget: RespondentsListEntity;
  isOpen: boolean;
  onClose: () => void;
}

export const ChangeRespondentModal = ({ surveyDetailId, respondentRequestId, isOpen, onClose, respondentTarget }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const {
    isLoading,
    orgLevelTypes,
    companies,
    divisions,
    sbus,
    departments,
    groups,
    ranks,
    countries,
    regions,
    makeSbus,
    makeDepartments,
    makeGroups,
  } = useSearchConditionList({ surveyDetailId });
  const { conditions, orgs } = useSearchOrganization({
    surveyDetailId: surveyDetailId,
    list: { orgLevelTypes, companies, divisions, sbus, departments, groups, ranks, countries, regions },
    methods: { makeSbus, makeDepartments, makeGroups },
  });
  const { searchResultGrid, selectedIdsOnSearchResult, setSelectedIdsOnSearchResult } = useSearchOrganizationGrid({ orgs });

  const buttons = [
    {
      name: t('button.confirm'),
      onclick: () => {
        if (respondentTarget.targetOrganizationId === selectedIdsOnSearchResult[0]) {
          enqueueSnackbar(t('enqueteCreate.changeRespondentSelectTargetMordal.message.sameOrganizationIsSelected'), { variant: 'warning' });
        } else {
          setIsConfirmationModalOpen(true);
        }
      },
      disabled: !(selectedIdsOnSearchResult && selectedIdsOnSearchResult?.length > 0),
    },
  ];

  // memo : SearchModalで選択された組織情報
  const newRespondentTargetOrg = useMemo(() => orgs.find((org) => org.id === selectedIdsOnSearchResult[0]), [selectedIdsOnSearchResult, orgs]);

  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '80%',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll',
    maxHeight: '90%',
  };

  return (
    <>
      <SearchModal
        isLoading={isLoading}
        isOpen={isOpen}
        title={t('enqueteCreate.changeRespondentSelectTargetMordal.title')}
        buttons={buttons}
        onClose={() => {
          onClose();
          setSelectedIdsOnSearchResult([]);
        }}
        conditions={conditions}
        grid={searchResultGrid}
        isSingleSelection={true}
        modalStyleSxProps={modalStyle}>
        <CurrentRespondentTargetOrgBox respondentTarget={respondentTarget} />
      </SearchModal>

      {isConfirmationModalOpen && newRespondentTargetOrg && (
        <ChangeRespondentConfirmationModal
          respondentRequestId={respondentRequestId}
          respondentTarget={respondentTarget}
          isConfirmationModalOpen={isConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          newRespondentTargetOrg={newRespondentTargetOrg}
          onClose={() => {
            onClose();
            setSelectedIdsOnSearchResult([]);
          }}
        />
      )}
    </>
  );
};
