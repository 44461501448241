import { useContext } from 'react';
import { Role } from 'src/@types/role';
import { ONLY_ANSWER_ROLES } from 'src/constants';
import { AuthContext } from 'src/contexts/AwsCognitoContext';

/**
 * Data-Insight IDかつ回答メニューのみ表示するロールの場合は、dashboardとoverviewで表示する機能を切り替えたいため
 * 判定のためのカスタムフックを作成しました
 */
export const useMenuSwitcher = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  // Data-Insight IDであるか
  const isDataInsightID = context.method === 'COGNITO';

  const roles: Role[] = context.user?.role ?? [];
  // bonsaiのONLY_ANSWER(回答メニューのみ表示するロール)以外のロールを持っているか
  const hasRoleHighLevel = roles.find((role: Role) => role.startsWith('bonsai-') && !ONLY_ANSWER_ROLES.includes(role));

  // memo: bonsai-answer-seriesを持っていてもbonsai-adminを持っている場合は 全ての機能を表示したいのでhasRoleHighLevelの否定を取る
  return { isDisplayOnlyAnswer: isDataInsightID && !hasRoleHighLevel };
};
