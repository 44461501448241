import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import React, { ChangeEvent, memo, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteConditionProps, dummyOrganizationCodePrefix, initAutocompleteValue } from '../hooks/useTemporaryOrganization';
import { SegmentTypeList } from './TemporaryOrganizationContent';
import { CreateOrgTypeList } from './TemporaryOrganizationTab';

export interface SegmentInputFormTypes {
  segmentType: string;
  segmentCode: string;
  segmentNameJpn: string;
  segmentNameEng: string;
  segmentDataJpn: string;
  segmentDataEng: string;
  setSegmentDataJpn: React.Dispatch<React.SetStateAction<string>>;
  setSegmentDataEng: React.Dispatch<React.SetStateAction<string>>;
  createType: CreateOrgTypeList | null;
  isEdit: boolean;
  isChecked: boolean | null;
  optionsList: string[];
  autocompleteProps: AutocompleteConditionProps | null;
  checkboxChangeCallback: ((e: SyntheticEvent<Element, Event>) => void) | null;
  validationNames: boolean | null;
  validationPullDown: boolean | null;
}

const SegmentInputForm: React.FC<SegmentInputFormTypes> = memo((props) => {
  const {
    segmentType,
    segmentCode,
    segmentNameJpn,
    segmentNameEng,
    segmentDataJpn,
    segmentDataEng,
    setSegmentDataJpn,
    setSegmentDataEng,
    createType,
    isEdit,
    isChecked,
    optionsList,
    autocompleteProps,
    checkboxChangeCallback,
    validationNames,
    validationPullDown,
  } = props;
  const { t } = useTranslation();

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [isCreateCompanyForm, setIsCreateCompanyForm] = useState<boolean>(false);
  const [isDisableForm, setIsDisableForm] = useState<boolean>(false);

  // const [isCreateGroup, setIsCreateGroup] = useState<boolean>(false);

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    setSegmentDataJpn(segmentDataJpn);
    setSegmentDataEng(segmentDataEng);
  }, []); //eslint-disable-line

  useEffect(() => {
    // グループ会社作成か判定
    // setIsCreateGroup(createType === CreateOrgTypeList.group);
    // フォーム非活性判定
    setIsCreateCompanyForm(createType === CreateOrgTypeList.ttc && segmentType === SegmentTypeList.company);
  }, [createType, segmentType]); // eslint-disable-line

  // 条件によりフォームを無効化
  useEffect(() => {
    const isDummyCode = segmentCode.indexOf(dummyOrganizationCodePrefix) !== -1;

    setIsDisableForm((isEdit && segmentCode === '') || (isEdit && !isDummyCode));
  }, [isEdit, segmentCode]);

  return (
    <Box>
      {/** フォーム切り替えチェックボックス **/}

      {!_.isNull(isChecked) && !isEdit && (
        <Box sx={{ textAlign: 'right' }}>
          <FormControl>
            <FormControlLabel
              onChange={checkboxChangeCallback ? checkboxChangeCallback : undefined}
              checked={isChecked}
              value={isChecked}
              control={<Checkbox size="small" />}
              label={t('enqueteCreate.temporaryOrganization.modal.form.checkBoxLabel')}
              labelPlacement="start"
            />
          </FormControl>
        </Box>
      )}

      {/** 既存組織検索 **/}
      {!isChecked && (
        <Stack spacing={1}>
          <TextField
            id="outlined-basic"
            label={segmentNameJpn}
            value={segmentDataJpn ?? undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSegmentDataJpn(e.target.value)}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 100 }}
            fullWidth
            disabled={isCreateCompanyForm || isDisableForm}
          />
          <TextField
            id="outlined-basic"
            label={segmentNameEng}
            value={segmentDataEng ?? undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSegmentDataEng(e.target.value)}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 100 }}
            fullWidth
            disabled={isCreateCompanyForm || isDisableForm}
          />

          {/* エラーメッセージ */}
          {validationNames && (
            <Typography sx={{ mt: 1, fontSize: 12, color: 'red' }} children={t('enqueteCreate.temporaryOrganization.error.required')} />
          )}
        </Stack>
      )}

      {/** 既存組織検索 **/}
      {isChecked && (
        <>
          <Autocomplete
            id={autocompleteProps ? autocompleteProps.id : ''}
            options={autocompleteProps ? autocompleteProps.list : []}
            value={(autocompleteProps && autocompleteProps.list.find((e) => e.key === autocompleteProps.key)) ?? initAutocompleteValue}
            onChange={(e, v, r) => {
              autocompleteProps && autocompleteProps.onChange(v ?? initAutocompleteValue, r);
            }}
            isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
            renderOption={(props, option) => (
              <li {...props} key={option.key}>
                {option.label}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label={autocompleteProps && autocompleteProps.label} size="small" fullWidth />}
            sx={{ mt: 0 }}
            disabled={_.isNull(optionsList.length === 0)}
          />

          {/* エラーメッセージ */}
          {validationPullDown && (
            <Typography sx={{ mt: 1, fontSize: 12, color: 'red' }} children={t('enqueteCreate.temporaryOrganization.error.required')} />
          )}
        </>
      )}
    </Box>
  );
});

export default SegmentInputForm;
