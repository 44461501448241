import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { UserEntity, useUserApi } from 'src/api/useUserApi';
import useLocales from 'src/hooks/useLocales';
import { joinEmails } from '../utils/joinEmails';

interface KeyEntity<T> {
  key: string;
  entity: T;
}

const useSuggestUser = () => {
  const DEBOUNCE_TIME = 500;
  const { isJapanese } = useLocales();
  const userApi = useUserApi();
  const [userSuggest, setUserSuggest] = useState<KeyEntity<UserEntity>[]>([]);

  const makeSuggestListUpdated = useCallback((user: UserEntity, isJapanese: boolean, isToyotsu: boolean): string => {
    const {
      fullNameJpn, // fullName
      fullNameEng, // engFullName
      companyNameJpn, // companyNameMain
      companyNameEng, // companyEngNameMain
      companyAbbreviation, // companyAbbreviationMain
      divisionNameJpn, // headquartersNameMain
      divisionNameEng, // headquertersEngNameMain
      sbuNameJpn, // sbuNameMain
      sbuNameEng, // sbuEngNameMain
      departmentNameJpn, // departmentNameMain
      departmentNameEng, // departmentEngNameMain
      groupNameJpn, // groupNameMain
      groupNameEng, // groupEngNameMain
      email, // emailAddress 補)テストデータ以外はemailがちゃんと入っている
      bluePageEmailAddress, // bluePageEmailAddress
      assignedCompanyNameJpn, // 出向先 (カナ名)
      assignedCompanyNameEng, // 出向先 (英語名)
    } = user;

    const getLocalizedName = (isJapanese: boolean, japaneseName: string | null, englishName: string | null) => {
      return isJapanese ? japaneseName?.trim() || englishName?.trim() : englishName?.trim() || japaneseName?.trim() || '';
    };
    const userName = getLocalizedName(isJapanese, fullNameJpn, fullNameEng);
    const companyName = getLocalizedName(isJapanese, companyNameJpn, companyNameEng);
    const companyAbbreviationName = companyAbbreviation ? companyAbbreviation : '';
    const assignedCompanyName = getLocalizedName(isJapanese, assignedCompanyNameJpn, assignedCompanyNameEng);
    const divisionName = getLocalizedName(isJapanese, divisionNameJpn, divisionNameEng);
    const sbuName = getLocalizedName(isJapanese, sbuNameJpn, sbuNameEng);
    const departmentName = getLocalizedName(isJapanese, departmentNameJpn, departmentNameEng);
    const groupName = getLocalizedName(isJapanese, groupNameJpn, groupNameEng);
    const userEmail = email ? joinEmails(email, bluePageEmailAddress) : '';

    const organizationStr = isToyotsu
      ? `${[companyName, companyAbbreviationName, assignedCompanyName, divisionName, sbuName, departmentName, groupName].filter(Boolean).join(' - ')}`
      : `${[companyName, companyAbbreviationName].filter(Boolean).join(' - ')}`;
    return `${[userName, organizationStr, userEmail].filter(Boolean).join(' - ')}`;
  }, []);

  const makeSuggestList = useCallback(
    async (orgFilter?: string | null) => {
      //全角スペースを半角に変換
      const filter = orgFilter?.replace(/　/g, ' ');
      const users = await userApi.getByKeyword(filter ?? undefined);
      if (!users) return [];

      const suggestList: KeyEntity<UserEntity>[] = [];
      for (const user of users) {
        const isToyotsu = user.companyCode === 'S500';
        const key = makeSuggestListUpdated(user, isJapanese, isToyotsu);
        suggestList.push({ key, entity: user });
      }

      return suggestList;
    },
    [isJapanese, userApi, makeSuggestListUpdated],
  );

  const updateUserSuggest = useMemo(
    () =>
      debounce(async (filter?: string | null) => {
        const suggestList = await makeSuggestList(filter);
        setUserSuggest(suggestList);
      }, DEBOUNCE_TIME),
    [makeSuggestList],
  );

  return { userSuggest, updateUserSuggest };
};

export default useSuggestUser;
