import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

type Props = {
  contents: string;
};

/**
 *  ヘルプツールチップ
 * @param contents
 */
export const HelpTooltip: React.FC<Props> = ({ contents }) => {
  return (
    <Tooltip
      title={contents.split('\n').map((text) => (
        <Box key={text}>{text}</Box>
      ))}>
      <IconButton>
        <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
      </IconButton>
    </Tooltip>
  );
};
