import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway, portalApi } from 'src/config';
import { OrganizationEntity } from './useOrganizationApi';
import { useBearerToken } from './useBearerToken';

/**
 * 補完組織マスタ検索結果用のインターフェース
 *
 */
export type OmitField = 'isFuture' | 'isDeleted';
export interface ComplementaryOrganizationEntity extends Omit<OrganizationEntity, OmitField> {
  validTo: string | null;
  validFrom: string | null;
  createdBy: string | null;
  updatedBy: string | null;
}

export interface CopmpanyEntity {
  companyCode: string;
  companyNameJpn: string | null;
  companyNameEng: string | null;
}
export interface CountryEntity {
  countryCode: string | null;
  countryNameJpn: string | null;
  countryNameEng: string | null;
  regionCode: string | null;
}

export interface RegionEntity {
  regionCode: string | null;
  regionNameJpn: string | null;
  regionNameEng: string | null;
}

export interface RankEntity {
  rank: string | null;
}

export const useComplementaryOrganizationApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 補完組織情報の一覧を取得するためのAPI
   * @returns
   */
  const getComplementaryOrganizations = async (keyword?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<ComplementaryOrganizationEntity[]>(apiGateway + '/complementary-organization', {
        headers: { Authorization: token },
        params: { keyword: keyword },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetOrganizasionDataList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getComplementaryOrganizationDetail = async (id: string, keyword?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<ComplementaryOrganizationEntity>(apiGateway + `/complementary-organization/${id}`, {
        headers: { Authorization: token },
        params: { id: id },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetOrganizasionDataDetail'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const createComplementaryOrganization = async (data: ComplementaryOrganizationEntity) => {
    try {
      const token = await getBearerToken();
      const result = await axios.post<ComplementaryOrganizationEntity>(apiGateway + `/complementary-organization/`, data, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedPostOrganizasionData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const updateComplementaryOrganization = async (id: string, data: ComplementaryOrganizationEntity) => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<ComplementaryOrganizationEntity>(apiGateway + `/complementary-organization/${id}`, data, {
        headers: { Authorization: token },
        params: { id: id },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedUpdateOrganizasionDataDetail'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const deleteComplementaryOrganization = async (id: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.delete<ComplementaryOrganizationEntity>(apiGateway + `/complementary-organization/${id}`, {
        headers: { Authorization: token },
        params: { id: id },
        timeout: 60000,
      });
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedDeleteOrganizasionData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getCountriesByRegionCode = async (regionCode?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(apiGateway + `/complementary-organization/countries/${regionCode}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.country') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getRegions = async () => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(apiGateway + `/complementary-organization/regions`, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.region') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  const getRanks = async (filter?: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get(portalApi + `/master/rank`, {
        headers: { Authorization: token },
        timeout: 60000,
        params: {
          filter: filter || {
            where: {
              rank: {
                not: 'ZZ',
              },
            },
          },
        },
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useComplementaryOrganizationApi.failedGetRanks'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getRanks,
    getRegions,
    getCountriesByRegionCode,
    getComplementaryOrganizations,
    getComplementaryOrganizationDetail,
    createComplementaryOrganization,
    updateComplementaryOrganization,
    deleteComplementaryOrganization,
  };
};
