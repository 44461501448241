import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useState } from 'react';
import { MIconButton } from 'src/components/@material-extend';
import { NotificationModal } from 'src/components/app-components/bonsai/notification/components/NotificationModal';

export const NotificationIcon = () => {
  const [key, setKey] = useState<number>(0);
  const handleOnClick = () => {
    localStorage.removeItem('closedNotificationTime');
    setKey((prev) => prev + 1);
  };

  return (
    <Box>
      <MIconButton
        onClick={handleOnClick}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          fontSize: 30,
        }}>
        <FontAwesomeIcon icon={faBell} />
      </MIconButton>
      {key !== 0 ? <NotificationModal key={key} calledByClick={true} /> : null}
    </Box>
  );
};
