import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { useRank } from 'src/hooks/useRank';
import useStatus from 'src/hooks/useStatus';
import { formatterDateTime } from 'src/utils/formatDateTime';
import { showUserName } from 'src/utils/gridValueUtils';
import { switchLabel } from 'src/utils/labelUtils';

const useRequestTargetDataGridColDef = () => {
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { t } = useTranslation();
  const { toStringAnswerStatus } = useStatus();
  const { complementRank } = useRank();

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
    filter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true,
    },
    floatingFilter: true,
  };

  const columnDefs: ColDef[] = [
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.answerStatus'),
      field: 'statusAns',
      tooltipValueGetter: (params) => params.value,
      suppressMenu: true,
      sortable: true,
      width: 130,
      minWidth: 100,
      valueGetter: function (params) {
        return toStringAnswerStatus(params.data.statusAns);
      },
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.reversionHistory'),
      field: 'reversionHistory',
      tooltipValueGetter: (params) => params.value,
      suppressMenu: true,
      sortable: true,
      width: 100,
      minWidth: 100,
      valueGetter: function (params) {
        return params.data.hasReversionHistory ? '◯' : '';
      },
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.respondent'),
      valueGetter: (params: ValueGetterParams<RespondentsListEntity>) => showUserName(params, 'userNameJpn', 'userNameEng', isLangJpn),
      tooltipValueGetter: (params) => params.value,
      suppressMenu: true,
      sortable: true,
      width: 240,
      minWidth: 100,
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.updateDate'),
      field: 'updateAtAns',
      tooltipValueGetter: (params) => params.value,
      suppressMenu: true,
      sortable: true,
      width: 200,
      minWidth: 150,
      valueGetter: function (params) {
        return formatterDateTime(params.data.updateAtAns);
      },
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.companyCode'),
      field: 'companyCode',
      tooltipValueGetter: (params) => params.value,
      suppressMenu: true,
      sortable: true,
      width: 100,
      minWidth: 100,
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.rank'),
      field: 'rank',
      valueGetter: (params) => {
        return complementRank(params.data.companyCode, params.data.rank);
      },
      suppressMenu: true,
      sortable: true,
      width: 100,
      minWidth: 100,
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.answeredCompany'),
      field: isLangJpn ? 'companyNameJpn' : 'companyNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.companyNameJpn, params.data.companyNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 240,
      minWidth: 100,
      valueGetter: (params) => {
        return switchLabel(params.data.companyNameJpn, params.data.companyNameEng, isLangJpn);
      },
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.companyAbbreviation'),
      field: 'companyAbbreviation',
      tooltipValueGetter: (params) => params.value,
      suppressMenu: true,
      sortable: true,
      minWidth: 100,
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.answeredDivision'),
      field: isLangJpn ? 'divisionNameJpn' : 'divisionNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.divisionNameJpn, params.data.divisionNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.divisionNameJpn, params.data.divisionNameEng, isLangJpn),
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.answeredSbu'),
      field: isLangJpn ? 'sbuNameJpn' : 'sbuNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.sbuNameJpn, params.data.sbuNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 120,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.sbuNameJpn, params.data.sbuNameEng, isLangJpn),
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.answeredDepartment'),
      field: isLangJpn ? 'departmentNameJpn' : 'departmentNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.departmentNameJpn, params.data.departmentNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 120,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.departmentNameJpn, params.data.departmentNameEng, isLangJpn),
    },
    {
      headerName: t('enqueteStatus.answerStatusGridColDef.answeredGroup'),
      field: isLangJpn ? 'groupNameJpn' : 'groupNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.groupNameJpn, params.data.groupNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 120,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.groupNameJpn, params.data.groupNameEng, isLangJpn),
    },
    {
      headerName: t('common.assignedCompany'),
      field: isLangJpn ? 'assignedCompanyNameJpn' : 'assignedCompanyNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.assignedCompanyNameJpn, params.data.assignedCompanyNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 100,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.assignedCompanyNameJpn, params.data.assignedCompanyNameEng, isLangJpn),
    },
    {
      headerName: t('common.region'),
      field: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.regionNameJpn, params.data.regionNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 100,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.regionNameJpn, params.data.regionNameEng, isLangJpn),
    },
    {
      headerName: t('common.country'),
      field: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
      tooltipValueGetter: (params) => switchLabel(params.data.countryNameJpn, params.data.countryNameEng, isLangJpn),
      suppressMenu: true,
      sortable: true,
      width: 100,
      minWidth: 100,
      valueGetter: (params) => switchLabel(params.data.countryNameJpn, params.data.countryNameEng, isLangJpn),
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 80,
      filter: false,
      pinned: 'right',
    },
  ];

  return {
    defaultColDef,
    columnDefs,
  };
};

export default useRequestTargetDataGridColDef;
