import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplementaryOrganizationEntity, RegionEntity } from 'src/api/useComplementaryOrganizationApi';
import { useAppSelector } from 'src/redux/store';
import { v4 as uuidv4 } from 'uuid';
import { Country, Organization } from '../components/OrganizationDetail';
import { ORGANIZATION_FUTURE_PATH } from '../routes/path';
import { useComplementaryOrganizationDetail } from './useComplementaryOrganizationDetail';

export const useOrganizationDetailSave = (getValues: any, targetOrganizationList: Organization[], targetCountryList: Country[]) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const regions = useAppSelector((state) => state.complementaryOrganization.regionData);
  const { update, create } = useComplementaryOrganizationDetail();

  //入力した値を組織種別でDBのどのカラムに追加するか設定
  const makeInputData = useCallback(
    (code: string, jpn: string, eng: string) => {
      const orgLevelType = getValues('orgLevelType');
      if (orgLevelType === 'COMPANY') {
        // memo:
        //   orgLevelType === 'COMPANY' はグループ会社を登録/更新するケースである
        //   会社略称は画面上設定不可のため、一律nullをセットする
        return { companyCode: code, companyNameJpn: jpn, companyNameEng: eng, companyAbbreviation: null };
      } else if (orgLevelType === 'DIVISION') {
        return { divisionCode: code, divisionNameJpn: jpn, divisionNameEng: eng };
      } else if (orgLevelType === 'SBU') {
        return { sbuCode: code, sbuNameJpn: jpn, sbuNameEng: eng };
      } else if (orgLevelType === 'DEPARTMENT') {
        return { departmentCode: code, departmentNameJpn: jpn, departmentNameEng: eng };
      } else if (orgLevelType === 'GROUP') {
        return { groupCode: code, groupNameJpn: jpn, groupNameEng: eng };
      }
    },
    [getValues],
  );

  const onSubmit = async () => {
    const { code, jpn, eng, upperOrg, regionCode, country, managedOrgLevelType, ttc, ...inputValue } = getValues();

    const upperOrgData = targetOrganizationList.find((x: Organization) => x.key === upperOrg)?.entity;
    const regionsData = regions.find((x: RegionEntity) => x.regionCode === regionCode) || {
      regionCode: '',
      regionNameJpn: '',
      regionNameEng: '',
    };
    const countryData = targetCountryList.find((x: Country) => x.key === country)?.entity;

    const data = {
      ...inputValue,
      ...upperOrgData,
      ...regionsData,
      ...countryData,
      ...makeInputData(code ? code : `DUMMY-${uuidv4()}`, jpn, eng),
    } as ComplementaryOrganizationEntity;

    id ? await update(id, data) : await create({ ...data });

    navigate(`${ORGANIZATION_FUTURE_PATH.INDEX}`);
  };

  return { onSubmit };
};
