import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { HeaderBreadcrumbsProps } from '../../../../components/HeaderBreadcrumbs';
import { ComplementaryOrganizationDetail } from '../components/OrganizationDetail';
import { ORGANIZATION_FUTURE_PATH } from '../routes/path';

export const ComplementaryOrganizationDetailPage = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(t('complementaryOrganizationDetail.pageTitle') + t('complementaryOrganizationList.companyType.ttc'));
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('complementaryOrganizationList.pageTitle'), href: ORGANIZATION_FUTURE_PATH.INDEX },
    ],
    activeLast: true,
  };
  return (
    <PageLayout title={title} breadcrumbs={breadcrumbs}>
      <ComplementaryOrganizationDetail setTitle={setTitle} />
    </PageLayout>
  );
};
