import { createSlice } from '@reduxjs/toolkit';
import { API_CALL_STATUS } from 'src/api/api-constants';
import { EnqueteThreadPriorInfo } from 'src/api/getThreadCreatorApi';
import { EnqueteThreadEntity, EnqueteThreadsEntity } from 'src/api/getThreadsApi';
import { fetchThreadById } from './enqueteThreadActions';

export const enqueteThreadSlice = createSlice({
  name: 'enquete-thread',
  initialState: {
    thread: {} as EnqueteThreadEntity,
    priorInfo: {} as EnqueteThreadPriorInfo,
    status: '' as EnqueteThreadsEntity['status'],
  },
  reducers: {
    clearThread(state) {
      state.thread = {} as EnqueteThreadEntity;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreadById.fulfilled, (state, action) => {
      if (action.payload) {
        state.thread = action.payload[0];
        state.priorInfo = action.payload[1];
      }
      state.status = API_CALL_STATUS.FULFILLED;
    });
    builder.addCase(fetchThreadById.pending, (state, action) => {
      state.status = API_CALL_STATUS.PENDING;
    });
    builder.addCase(fetchThreadById.rejected, (state, action) => {
      state.status = action.error;
    });
  },
});

export const { clearThread } = enqueteThreadSlice.actions;
export default enqueteThreadSlice.reducer;
