import { useCurrencyApi } from 'src/api/useCurrencyApi';
import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { setCurrencyList, setRate } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useAppDispatch } from 'src/redux/store';

export const useGetCurrency = () => {
  const { getAllCurrency } = useCurrencyApi();
  const { getRate } = useEnqueteAnswerInputApi();
  const dispatch = useAppDispatch();

  // 通貨の取得
  const getCurrencies = async (respondentTargetId: string) => {
    const response = await getAllCurrency(respondentTargetId);
    if (!response) return;
    const result = response.currencies.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
    dispatch(setCurrencyList(result));
    return result;
  };

  // レートの取得
  const getRateForCurrency = async (currencyUnit: string, year: number) => {
    const result = await getRate(currencyUnit === '' ? 'JPY' : currencyUnit, year);

    // 円だった場合はrateが0なので1に修正
    dispatch(setRate(parseFloat(result.rate)));
  };

  return { getCurrencies, getRateForCurrency };
};
