import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { EnqueteThreadLayout } from '../components/pages/detail/components/EnqueteThreadLayout';

export const EnqueteThreadPage = () => {
  const { t } = useTranslation();
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: t('enqueteThread.commentList'),
    links: [
      { name: 'TOP', href: '/top' },
      { name: t('enqueteThread.pageTitle'), href: '/general/enquete-threads/' },
    ],
    activeLast: true,
  };
  return (
    <PageLayout title={t('enqueteThread.commentList')} breadcrumbs={breadcrumbs}>
      <EnqueteThreadLayout />
    </PageLayout>
  );
};
