import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { OverviewEnquete } from 'src/@types/overviewEnquete';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { EnqueteAnswerDescriptionBox } from '../../../../../type-general-question/components/tabs/question-and-answer/components/common/EnqueteAnswerDescriptionBox';
import { useEnqueteAnswer } from '../hooks/useEnqueteAnswer';
import { EnqueteAnswerForm } from './EnqueteAnswerForm';

export const EnqueteAnswerOverviewTab: React.FC = () => {
  const { currentLang } = useLocales();
  const { surveyDetailData } = useAppSelector((state) => state.enqueteAnswer);

  const overviewEnquete = surveyDetailData.surveyFormData.formData.tabData[0] as OverviewEnquete;
  const questionDescription = currentLang.value === 'ja' ? overviewEnquete?.questionDescriptionJpn : overviewEnquete?.questionDescriptionEng;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { initOverviewEnquete } = useEnqueteAnswer();

  useEffect(() => {
    // // アンケート内容を初期セット
    initOverviewEnquete(setIsLoading);
  }, []); //eslint-disable-line
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Grid item xs={12} md={12}>
        {questionDescription && questionDescription !== '' ? <EnqueteAnswerDescriptionBox questionDescription={questionDescription} /> : null}
      </Grid>
      {!isLoading && (
        <Grid item xs={12} md={12}>
          <EnqueteAnswerForm />
        </Grid>
      )}
    </Grid>
  );
};
