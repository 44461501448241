import { Typography } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import { GetContextMenuItems, GetContextMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { isAfter } from 'date-fns';
import 'handsontable/dist/handsontable.full.min.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { StyledBox } from 'src/components/app-components/StyledBox';
import 'src/components/app-components/bonsai/type-general-table/styles/table.css';
import { SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { RootState, useAppSelector } from 'src/redux/store';
import { useEnqueteGrid } from '../hooks/useEnqueteGrid';
import { SeriesEnqueteRowData, useEnqueteModal } from '../hooks/useEnqueteModal';

type Props = {
  setTargetData: React.Dispatch<React.SetStateAction<SeriesEnqueteRowData | undefined>>;
};

// 初期定義
export const seriesEnqueteCreateData: SeriesSurveyFormTabData = {
  questionDescriptionJpn: '',
  questionDescriptionEng: '',
  aggregationList: [],
};

const EnqueteCreateForm: React.FC<Props> = (props) => {
  const { setTargetData } = props;
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const tabData = enqueteData?.surveyDetail?.surveyFormData?.formData.tabData[0] as SeriesSurveyFormTabData;
  const { defaultColDef, colDef, onDelete } = useEnqueteGrid();
  const { modalEditMode, toViewData, openEditModal } = useEnqueteModal();
  const { isViewerAdmin, isSurveyOwner } = usePermission(enqueteData.surveyDetailId);
  const { t } = useTranslation();
  const onRowDoubleClickedCallback = (e: RowDoubleClickedEvent) => editRowCallback(e.data);
  const data = toViewData(tabData ? tabData?.aggregationList : seriesEnqueteCreateData.aggregationList);
  // アンケートクローズ状態の確認
  const isClosed = isAfter(new Date(), new Date(enqueteData.closedAt));
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const isNotViewContextMenu = () => {
    return isClosed || isViewerAdmin;
  };

  // 編集処理
  const editRowCallback = (data: SeriesEnqueteRowData) => {
    if (isNotViewContextMenu() && !isSurveyOwner) return;
    setTargetData(data);
    modalEditMode();
    openEditModal();
  };

  // 削除処理
  const deleteRowCallback = async (data: SeriesEnqueteRowData) => {
    await onDelete(data);
  };

  const contextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.edit'),
        action: () => {
          if (params.node) editRowCallback(params.node.data);
        },
        disabled: isNotViewContextMenu() && !isSurveyOwner,
      },
      {
        name: t('button.delete'),
        action: () => {
          if (params.node) deleteRowCallback(params.node.data);
        },
        disabled: isNotViewContextMenu() && !isSurveyOwner,
      },
    ];
  };

  return (
    <>
      <StyledBox>
        <Grid item xs={12} md={12}>
          <Typography>{''}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: '60vh', width: '100%' }}>
                {/* <LoadableComponent isLoading={false}> */}
                <StyledAgGrid
                  defaultColDef={defaultColDef}
                  coldef={colDef}
                  rows={data}
                  contextMenu={contextMenuItems}
                  onRowDoubleClickedCallback={onRowDoubleClickedCallback}
                />
                {/* </LoadableComponent> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </StyledBox>
    </>
  );
};

export default EnqueteCreateForm;
