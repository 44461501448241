import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { useCustomerManagementList } from 'src/features/manage/customer-management/hooks/useCustomerManagementList';
import { RootState, useAppSelector } from 'src/redux/store';
import ApplyA1CodeModal from '../../../modals/apply-a1-code/components/ApplyA1CodeModal';
import CustomerManagementEditModal from '../../../modals/edit/components/CustomerManagementEditModal';
import BonsaiAddedCustomerListGrid from './BonsaiAddedCustomerListGrid';
import { CustomerManagementListHeader } from './CustomerManagementListHeader';

export interface Props {
  title: string;
  breadcrumbs: HeaderBreadcrumbsProps;
}

const CustomerManagementListLayout = (props: Props) => {
  const { title, breadcrumbs } = props;
  const { parentCustomers, catsCustomers, regions, countries, countryRegions } = useCustomerManagementList();
  const { isCustomerLoading } = useAppSelector((state: RootState) => state.customerManagement);

  return (
    <>
      <PageLayout title={title} breadcrumbs={breadcrumbs}>
        <CustomerManagementListHeader />
        <BonsaiAddedCustomerListGrid />
      </PageLayout>

      {!isCustomerLoading && (
        <>
          <CustomerManagementEditModal parentCustomers={parentCustomers} regions={regions} countries={countries} countryRegions={countryRegions} />
          <ApplyA1CodeModal catsCustomers={catsCustomers} />
        </>
      )}
    </>
  );
};

export default CustomerManagementListLayout;
