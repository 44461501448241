import { Stack } from '@mui/material';
import { useAppSelector } from 'src/redux/store';
import { AnswerInfoDataGrid } from './AnswerInfoDataGrid';
import { ConfirmInfoDataGrid } from './ConfirmInfoDataGrid';

export const EnqueteConfirmTab: React.FC = () => {
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);

  return (
    <Stack spacing={5}>
      <AnswerInfoDataGrid />
      <ConfirmInfoDataGrid surveyDetailId={surveyDetailData?.id} />
    </Stack>
  );
};
