import { SyntheticEvent, useState } from 'react';
import { TAB_VALUE, TabValue } from '../constants';

export const useTabs = (selectedTabValue: TabValue | null) => {
  //タブ
  const [tabValue, setTabValue] = useState<TabValue>(selectedTabValue ?? TAB_VALUE.QUESTION);

  //タブ切り替え関数
  const handleChangeTab = (_e: SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  return { tabValue, handleChangeTab };
};
