import { CardContent } from '@mui/material';
import { ThreadTextBox } from './ThreadTextBox';

interface Props {
  description: string;
}

export const ThreadDescriptionCard = ({ description }: Props) => {
  return (
    <CardContent sx={{ pb: 0, pl: 1 }}>
      <ThreadTextBox text={description} lineCount={5} />
    </CardContent>
  );
};
