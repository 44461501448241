import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { EnqueteRequestListActionsForm } from 'src/features/general/enquete-request/components/modals/alert-personal-data/components/EnqueteRequestListActionsForm';
import { usePermission } from 'src/hooks/usePermission';
import { HeaderBreadcrumbsProps } from '../../../../components/HeaderBreadcrumbs';
import { EnqueteRequestListGrid } from '../components/pages/list/components/EnqueteRequestListGrid';

export const EnqueteRequestListPage = () => {
  const { t } = useTranslation();
  const title = t('enqueteRequest.pageTitle');
  const { isAdmin, isViewerAdmin, isCreator, isOverViewCreator } = usePermission();
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };
  return (
    <PageLayout title={title} breadcrumbs={breadcrumbs}>
      {(isAdmin || isViewerAdmin || isCreator || isOverViewCreator) && <EnqueteRequestListActionsForm />}
      <EnqueteRequestListGrid />
    </PageLayout>
  );
};
