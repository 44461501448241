import { MenuItem, SelectChangeEvent } from '@material-ui/core';
import { Select } from '@mui/material';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Question, State } from 'src/@types/generalEnquete';
import RHFDropdown from 'src/components/react-hook-form/RHFDropdown';
import { useEnqueteAnswerHandleOnChange } from '../hooks/useEnqueteAnswerHandleOnChange';

type EnqueteAnswerDropdownFormProps = {
  question: Question;
  index: number;
  methods?: UseFormReturn;
  validationRule?: Object;
  questionState?: State;
};
const EnqueteAnswerDropdownForm = ({ question, index, methods, validationRule, questionState }: EnqueteAnswerDropdownFormProps) => {
  const { handleOnChangeDropdownAndRadio, dropdownAndRadioDefaultValue } = useEnqueteAnswerHandleOnChange();

  // 質問表示状態が変ったらルールをスイッチする。
  useEffect(() => {
    if (methods && question.answerItem) {
      if (methods?.getValues(question.id) !== undefined) {
        const stateRule = questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule;
        const value = methods?.getValues(question.id);
        methods?.control.unregister(question.id);
        methods?.control.register(question.id, stateRule);
        methods?.setValue(question.id, value);
        methods?.trigger(question.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionState]);

  return (
    <>
      {methods ? (
        <RHFDropdown
          name={question.id}
          label={question.title}
          control={methods.control}
          defaultValue={dropdownAndRadioDefaultValue(index, question, methods)}
          rules={questionState === 'disabled' || questionState === 'hidden' ? {} : validationRule}
          key={question.id}
          handleOnChange={(event: SelectChangeEvent<string>) => {
            handleOnChangeDropdownAndRadio(event, index, question);
          }}
          disabled={questionState === 'disabled'}>
          <MenuItem value={''}>
            <em>None</em>
          </MenuItem>
          {question.questionItem.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </RHFDropdown>
      ) : (
        <Select sx={{ minWidth: 150 }} key={question.id} defaultValue="">
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {question.questionItem.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
export default EnqueteAnswerDropdownForm;
