import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { CreatedSurveyEntity } from 'src/api/useCreatedSurvey';

export const enqueteRequestSlice = createSlice({
  name: 'enqueteCreate',
  initialState: {
    listData: [] as CreatedSurveyEntity[],
  },
  reducers: {
    initListData: (state, action: PayloadAction<CreatedSurveyEntity[]>) => {
      state.listData = action.payload;
    },
    deleteRowData: (state, action: PayloadAction<string>) => {
      const listData = current(state.listData);
      state.listData = listData.filter((rowData) => rowData.id !== action.payload);
    },
  },
});
