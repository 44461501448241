import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { setIsRequested } from '../../../../../../../../store/sendTextModalSlice';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isRequest: boolean;
}

export const useSendReversion = () => {
  const { t } = useTranslation();
  const { postRequestReversion, executeReversion } = useEnqueteAnswerInputApi();
  const { enqueueSnackbar } = useSnackbar();
  const respondentTarget = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  const comment = useAppSelector((state) => state.sendTextModal.comment);
  const dispatch = useAppDispatch();

  const onSendReversion = async (props: Props) => {
    const { setIsOpen, isRequest } = props;
    dispatch(setIsRequested(true));

    // Validation
    if (!comment) {
      return enqueueSnackbar(t('enqueteAnswerInput.modal.submitReversion.message.NotEmptyComment'), { variant: 'error' });
    }
    // 送信
    isRequest ? await postRequestReversion(respondentTarget.id, comment) : await executeReversion(respondentTarget.id, comment);

    setIsOpen(false);
  };

  return { onSendReversion };
};
