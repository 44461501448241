import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { usePermission } from 'src/hooks/usePermission';
import { NotificationCreateTabContainer } from '../../../tab-containers/create/components/NotificationCreateTabContainer';
import { useNotificationCreate } from '../hooks/useNotificationCreate';

type NotificationCreateLayoutProps = {
  pageTitle: string;
  breadCrumbs: HeaderBreadcrumbsProps;
};

export const NotificationCreateLayout = ({ pageTitle, breadCrumbs }: NotificationCreateLayoutProps) => {
  const { isLoading, notification, createNotification } = useNotificationCreate();
  const { t } = useTranslation();
  const { isAdmin, isViewerAdmin } = usePermission();

  const conditionalRender = () => {
    if (!isAdmin && !isViewerAdmin) {
      return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Typography variant="body1">{t('notifications.messages.noPermissions')}</Typography>
        </Grid>
      );
    } else if (isLoading) {
      return <StyledLoading isOpen={isLoading} />;
    } else {
      return <NotificationCreateTabContainer notification={notification} createNotification={createNotification} />;
    }
  };

  return (
    <PageLayout title={pageTitle} breadcrumbs={breadCrumbs}>
      <Container maxWidth="xl">{conditionalRender()}</Container>
    </PageLayout>
  );
};
