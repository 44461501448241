import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotificationRequestData, useNotificationApi } from 'src/api/useNotificationApi';

export const useNotificationCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState<NotificationRequestData>();
  const navigate = useNavigate();
  const { create } = useNotificationApi();

  const createNotification = useCallback(
    async (data: NotificationRequestData) => {
      if (!data?.titleEng?.trim() || /^[\s\u3000]+&/.test(data.titleEng)) {
        data.titleEng = data.titleJpn;
      }
      if (!data?.contentEng?.trim() || /^[\s\u3000]+&/.test(data.contentEng)) {
        data.contentEng = data.contentJpn;
      }

      setIsLoading(true);

      try {
        setData(data);
        await create(data);
        navigate('/manage/notification/edit');
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status !== 403) {
            enqueueSnackbar(t('notifications.messages.failedToCreate'), { variant: 'error' });
          }
        }
        setHasError(true);
        setIsLoading(false);
      }
    },
    [create, enqueueSnackbar, navigate, t],
  );
  return { isLoading, hasError, notification: data, createNotification };
};
