import { useMenuSwitcher } from 'src/hooks/useMenuSwitcher';
import { DashboardLayout } from './DashboardLayout';
import { OnlyAnswerDashboardLayout } from './OnlyAnswerDashboardLayout';
export default function DashboardSwitcher() {
  const { isDisplayOnlyAnswer } = useMenuSwitcher();

  // memo:
  // Data-Insight IDかつ回答メニューのみ表示するロールの場合は、OnlyAnswerOverviewPageを表示する
  if (isDisplayOnlyAnswer) {
    return <OnlyAnswerDashboardLayout />;
  }
  return <DashboardLayout />;
}
