import React from 'react';
import { Grid, Typography } from '@mui/material';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMainSheetNameForm } from '../hooks/useMainSheetNameForm';

interface PropsEntity {
  formLabel: string;
  defaultValue: string | undefined;
  index: number;
}

const SheetNameInput: React.FC<PropsEntity> = ({ formLabel, defaultValue, index }) => {
  const { t } = useTranslation();
  const formName = formLabel + index;

  // RHF
  const {
    control,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  // Hooks
  const { rulesSheetName } = useMainSheetNameForm({ watch, setValue, isValid, formLabel, formName, index });

  return (
    <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={6}>
      <Grid item xs={12} md={4}>
        <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }} children={t(formLabel)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <RHFTextField name={formName} control={control} defaultValue={defaultValue} size="small" variant="outlined" rules={rulesSheetName} />
      </Grid>
    </Grid>
  );
};

export default SheetNameInput;
