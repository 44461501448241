import { Button, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { Control, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationRequestData } from 'src/api/useNotificationApi';
import { StyledBox } from 'src/components/app-components/StyledBox';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';

type NotificationFormTabProps = {
  title: string | undefined;
  titleName: 'titleJpn' | 'titleEng';
  content: string | undefined;
  contentName: 'contentJpn' | 'contentEng';
  control: Control<NotificationRequestData>;
  getValues: UseFormGetValues<NotificationRequestData>;
};

export const NotificationFormTab = ({ title, titleName, content, contentName, control, getValues }: NotificationFormTabProps) => {
  const MAX_LENGTH_OF_TITLE = 300;
  const MAX_LENGTH_OF_CONTENT = 5000;
  const { t } = useTranslation();
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const titleForPreview = getValues(titleName);
  const contentForPreview = getValues(contentName);

  if (isPreviewVisible) {
    return (
      <>
        <Container maxWidth="xl" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {titleForPreview}
          </Typography>
          <StyledBox>
            <div dangerouslySetInnerHTML={{ __html: contentForPreview }} />
          </StyledBox>
        </Container>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
          <Button onClick={() => setPreviewVisible(false)}>{t('button.close')}</Button>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid sx={{ mt: 2 }}>
        <RHFTextField
          name={titleName}
          label={t('notifications.form.title')}
          control={control}
          rows={1}
          multiline={true}
          defaultValue={title ?? ''}
          fullWidth
          placeholder={t('notifications.form.validation.titleIsInvalid')}
          rules={{
            maxLength: { value: MAX_LENGTH_OF_TITLE, message: t('notifications.form.validation.titleIsInvalid') },
            required: { value: titleName === 'titleJpn', message: t('notifications.form.validation.required') },
          }}
        />
      </Grid>
      <Grid sx={{ mt: 2 }}>
        <RHFTextField
          name={contentName}
          label={t('notifications.form.body')}
          control={control}
          defaultValue={content ?? ''}
          rows={10}
          multiline={true}
          fullWidth
          placeholder={t('notifications.form.validation.bodyIsInvalid')}
          rules={{
            maxLength: { value: MAX_LENGTH_OF_CONTENT, message: t('notifications.form.validation.bodyIsInvalid') },
            required: { value: contentName === 'contentJpn', message: t('notifications.form.validation.required') },
          }}
        />
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
        <Button onClick={() => setPreviewVisible(true)}>{t('notifications.form.preview')}</Button>
      </Grid>
    </>
  );
};
