import { useTranslation } from 'react-i18next';
import { ErrorMessageEntity } from 'src/api/useEnqueteStatusPageApi';
import useLocales from 'src/hooks/useLocales';

export const ERROR_CODE = {
  NONE: 0,
  EMPTY_RECORDS: 1,
  HEADER_MISMATCH: 2,
  REQUIRED: 3,
  REQUIRE_STRING: 4,
  REQUIRE_NUMERIC: 5,
  REQUIRE_INTEGER: 6,
  OUT_OF_RANGE: 7,
  ORGANIZATION_MISMATCH: 8,
  MAX_LENGTH_LIMIT_EXCEEDED: 9,
  MAX_VALUE_LIMIT_EXCEEDED: 10,
  NOT_POSITIVE_NUMBER: 11,
  ORGANIZATION_UNRESOLVED: 12,
  USER_NOT_FOUND: 13,
  USER_ALREADY_EXISTS: 14,
  REQUIRE_OTHER_REASON: 15,
  INVALID_CORPORATE_EMPLOYMENT_SUM: 16,
  INVALID_SALES_EMPLOYMENT_SUM: 17,
  INVALID_CORPORATE_AND_SALES_SUM: 18,
  PORTALUSER_ORGANIZATION_MISMATCH: 19,
  PORTALUSER_ORGANIZATION_UNRESOLVED: 20,
  RESPONDNET_PREVIOUSLY_DELETED: 21,
  REQUIRE_INPUT_DIFFERENCE_PERSON_COUNT_AND_REASON: 22,
  TOO_MANY_RECORDS: 23,
  REQUIRE_NOT_ZERO: 24,
  MIN_VALUE_LIMIT_EXCEEDED: 25,
  INVALID_ORGANIZATION_FORMAT: 26,
  INVALID_SEGMENT_PATTERN: 27,
  INVALID_RANK: 28,
  NOT_EXPECTED_VALUE: 29,
  REQUIRE_NEW_SEGMENT: 30,
  NOT_EXISTS_REGISTRATED_SEGMENT_PATTERN: 31,
  NOT_EXISTS_REGION: 32,
  NOT_EXISTS_COUNTRY_IN_REGION: 33,
  INVALID_CODE: 34,
  DUPLICATE_ROW: 35,
  INVALID_DECIMAL: 36,
  REQUIRE_ALPHANUMERIC: 37,
  TARGET_DATE_MISMATCH: 38,
  MISSING_RESPONDENT_TARGET: 39,
  HAS_LINE_BREAKES: 40,
  REQUIRE_NUMBER_OR_STRING: 41,
  DUPLICATE_COLUMN: 42,
  // 人件費人員(重点分野別)の雇用形態の値がドメイン定義と一致していない時のエラーコード
  REQUIRE_EMPLOYMENT_STATUS_PERSONNEL_PRIORITY_DOMAIN: 43,
  // 製造原価人員(重点分野別)の雇用形態の値がドメイン定義と一致していない時のエラーコード
  REQUIRE_EMPLOYMENT_STATUS_MANUFYEAR_PLANUR_PRIORITY_DOMAIN: 44,
  // 人件費人員(重点分野別)、製造原価人員(重点分野別)の重点分野の値がドメイン定義と一致していない時のエラーコード
  REQUIRE_PRIORITY_DOMAIN: 45,
  // 差異人数入力欄(重点分野別)で、雇用形態または重点分野または数値または理由のいずれかしか入力されていない時のエラーコード
  REQUIRE_INPUT_EMPLOYMENT_STATUS_PRIORITY_DOMAIN_DIFFERENCE_PERSON_COUNT_AND_REASON: 46,
  // 雇用形態ごとに人件費人員(重点分野別)が人件費人員(雇用形態別)と一致していない時のエラーコード
  INVALID_PERSONNEL_PRIORITY_DOMAIN_SUM: 47,
  // 雇用形態ごとに製造原価人員(重点分野別)が製造原価人員(雇用形態別)と一致していない時のエラーコード
  INVALID_MANUFYEAR_PLANUR_PRIORITY_DOMAIN_SUM: 48,
};

//ExcelバリデーションHooks
const useImportExcelValidate = () => {
  const { currentLang } = useLocales();
  const { t } = useTranslation();

  //配列でエラーコード毎のメッセージを取得
  const getErrorMessageList = (errorObjList: ErrorMessageEntity[] | [] | string) => {
    let errorList = [];

    //文字列だった場合の処理
    if (typeof errorObjList === 'string') {
      errorList.push(errorObjList);
      return errorList;
    }

    switch (errorObjList[0].code) {
      case ERROR_CODE.HEADER_MISMATCH:
        // 列項目が同じか判定
        errorList.push(t('errorReport.errorMessage.layoutIsDifferent'));
        break;

      case ERROR_CODE.EMPTY_RECORDS:
        errorList.push(t('errorReport.errorMessage.emptyRecords'));
        break;

      case ERROR_CODE.DUPLICATE_COLUMN:
        const header = errorObjList[0].value && 'header' in errorObjList[0].value ? errorObjList[0].value.header : '';
        const value = errorObjList[0].value && 'value' in errorObjList[0].value ? errorObjList[0].value.value : '';
        errorList.push(t('errorReport.errorMessage.duplicateColumn', { header, value }));
        break;
      default:
        errorList = errorObjList.map((v) => getErrorMessage(v));
        break;
    }

    return errorList;
  };

  //エラーコード毎のメッセージを取得
  const getErrorMessage = (errorObj: ErrorMessageEntity) => {
    let errorMessage = '';

    switch (errorObj.code) {
      //必須が不足したケース
      case ERROR_CODE.REQUIRED:
        errorMessage = t('errorReport.errorMessage.required', { column: errorObj.column, row: errorObj.row });
        break;

      //文字列が不足したケース
      case ERROR_CODE.REQUIRE_STRING:
        errorMessage = t('errorReport.errorMessage.requireString', { column: errorObj.column, row: errorObj.row });
        break;

      //数値が不足したケース
      case ERROR_CODE.REQUIRE_NUMERIC:
        errorMessage = t('errorReport.errorMessage.requireNumeric', { column: errorObj.column, row: errorObj.row });
        break;

      //数値が不足したケース
      case ERROR_CODE.REQUIRE_INTEGER:
        errorMessage = t('errorReport.errorMessage.requireInteger', { column: errorObj.column, row: errorObj.row });
        break;

      //回答対象会社に一致しなかったケース
      case ERROR_CODE.ORGANIZATION_MISMATCH:
        errorMessage = t('errorReport.errorMessage.organizationMismatch', { row: errorObj.row });
        break;

      //最大文字数（文字列）に収まっていない時のエラーコード
      case ERROR_CODE.MAX_LENGTH_LIMIT_EXCEEDED:
        const limit = errorObj.value && 'limit' in errorObj.value ? errorObj.value.limit : '';
        errorMessage = t('errorReport.errorMessage.maxLengthLimitExceeded', { column: errorObj.column, row: errorObj.row, limit });
        break;

      //最大文字数（数値）に収まっていない時のエラーコード
      case ERROR_CODE.MAX_VALUE_LIMIT_EXCEEDED:
        errorMessage = t('errorReport.errorMessage.maxValueLimitExceeded', { column: errorObj.column, row: errorObj.row });
        break;

      //正数でないケースのエラーコード
      case ERROR_CODE.NOT_POSITIVE_NUMBER:
        errorMessage = t('errorReport.errorMessage.notPositiveNumber', { column: errorObj.column, row: errorObj.row });
        break;

      //回答対象組織情報が解決できなかった時のエラーコード
      case ERROR_CODE.ORGANIZATION_UNRESOLVED:
        errorMessage = t('errorReport.errorMessage.organizationUnresolved', { row: errorObj.row });
        break;

      //ユーザーがポータルに存在していなかった時のコード
      case ERROR_CODE.USER_NOT_FOUND:
        errorMessage = t('errorReport.errorMessage.userNotFound', { row: errorObj.row });
        break;

      //その他の理由が入力されていない時のコード
      case ERROR_CODE.REQUIRE_OTHER_REASON:
        errorMessage = t('errorReport.errorMessage.requireOtherReason', { row: errorObj.row });
        break;

      //コーポレート合計が条件に一致しない時のコード
      case ERROR_CODE.INVALID_CORPORATE_EMPLOYMENT_SUM:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 「コーポレート人員の合計」が「人件費に紐づく人員数の合計」を越えています`
            : `Row ${errorObj.row}, "Admin Person Total" exceeds "Headcount personnel cost relater Total"`;
        break;

      //営業人員合計が条件に一致しない時のコード
      case ERROR_CODE.INVALID_SALES_EMPLOYMENT_SUM:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 「営業人員の合計」が「人件費に紐づく人員数の合計」を越えています`
            : `Row ${errorObj.row}, "Sales Person Total" exceeds "Total of Headcount personnel cost relater Total"`;
        break;

      //人件費人員(機能別)のコーポレート人員合計と営業人員合計の合計が人件費人員(雇用形態別)と不一致の時のコード
      case ERROR_CODE.INVALID_CORPORATE_AND_SALES_SUM:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 「コーポレート人員と営業人員の合計」が「人件費に紐づく人員数の合計」に一致していません`
            : `Row ${errorObj.row}, Sum of "Admin Person" and "Sales Person" does not match "Headcount personnel cost relater"`;
        break;

      //ポータルの組織情報をBONSAIの組織マスタで解決できなかった時のエラーコード
      case ERROR_CODE.PORTALUSER_ORGANIZATION_UNRESOLVED:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 回答者の組織コードがシステム内で正しく処理できませんでした`
            : `Row ${errorObj.row}, Respondent's organization code could not be processed correctly within the system`;
        break;

      //差異人数、差異理由が揃っていない時のコード
      case ERROR_CODE.REQUIRE_INPUT_DIFFERENCE_PERSON_COUNT_AND_REASON:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 差異人数、もしくは差異理由が揃っていない項目があります`
            : `Row ${errorObj.row}, require input difference person count and reason`;
        break;

      //E10-13画面のインポートで２件上のレコードをインポートした時のコード
      case ERROR_CODE.TOO_MANY_RECORDS:
        errorMessage = currentLang.value === 'ja' ? `インポートできるのは1件です` : `Only one organization can be imported.`;
        break;

      //E10-13画面のインポートで差異人数の値が0だった時のコード
      case ERROR_CODE.REQUIRE_NOT_ZERO:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列の差異の人数は0以外を入力して下さい`
            : `Please enter numbers other than 0 for the difference in row ${errorObj.row} and column ${errorObj.column}`;
        break;

      //最小文字数（数値）に収まっていない時のエラーコード
      case ERROR_CODE.MIN_VALUE_LIMIT_EXCEEDED:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列は文字数制限（6桁）を超過しています`
            : `Row ${errorObj.row} and column ${errorObj.column} exceeds the character limit (6 digits)`;
        break;

      // 無効な組織フォーマット時のエラー
      case ERROR_CODE.INVALID_ORGANIZATION_FORMAT:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 有効なセグメントの組み合わせではありません`
            : `Row ${errorObj.row} Not a valid segment combination.`;
        break;

      // 仮組織の後に既存組織を設定した時のエラー
      case ERROR_CODE.INVALID_SEGMENT_PATTERN:
        errorMessage =
          currentLang.value === 'ja' ? `${errorObj.row}行目 インポート可能な入力になっていません` : `Row ${errorObj.row} Not an importable input.`;
        break;

      // 無効な格付け時のエラー
      case ERROR_CODE.INVALID_RANK:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列 無効な格付を指定しています`
            : `Row ${errorObj.row} and column ${errorObj.column} You have specified an invalid company rating.`;
        break;

      // 期待値と不一致時のエラー
      case ERROR_CODE.NOT_EXPECTED_VALUE:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列 無効な値を指定しています`
            : `Row ${errorObj.row} and column ${errorObj.column} You are specifying an invalid value.`;
        break;

      // 新規セグメントでなければいけない時のエラー
      case ERROR_CODE.REQUIRE_NEW_SEGMENT:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列 仮組織を指定してください`
            : `Row ${errorObj.row} and column ${errorObj.column} Please specify temporary organization.`;
        break;

      // 既存セグメントの組み合わせがDBに登録がない時のエラー
      case ERROR_CODE.NOT_EXISTS_REGISTRATED_SEGMENT_PATTERN:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 無効な組織コードの組み合わせです`
            : `Row ${errorObj.row} Invalid organization code combination.`;
        break;

      // 極のコードがDBに登録がない時のエラー
      case ERROR_CODE.NOT_EXISTS_REGION:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列 無効な極コードを指定しています`
            : `Row ${errorObj.row} and column ${errorObj.column} You are specifying an invalid region code.`;
        break;

      // 国のコードが極コードに関連していない時のエラー
      case ERROR_CODE.NOT_EXISTS_COUNTRY_IN_REGION:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列 無効な国コードを指定しています`
            : `Row ${errorObj.row} and column ${errorObj.column} You are specifying an invalid country code.`;
        break;

      // コードがDBに存在しない時のエラー
      case ERROR_CODE.INVALID_CODE:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列 無効なコードを指定しています`
            : `Row ${errorObj.row} and column ${errorObj.column} You are specifying an invalid code.`;
        break;

      // 行が重複している時のエラー
      case ERROR_CODE.DUPLICATE_ROW:
        errorMessage = currentLang.value === 'ja' ? `${errorObj.row}行目 指定内容が重複しています` : `Row ${errorObj.row} Duplicate specification.`;
        break;

      // 与えられた値が整数でないまたは指定された小数点桁数を超えている時のエラー
      case ERROR_CODE.INVALID_DECIMAL:
        const decimalDigits = errorObj.value && 'decimal_digits' in errorObj.value ? errorObj.value.decimal_digits : '';
        const message = decimalDigits === 0 ? 'errorReport.errorMessage.invalidNumber' : 'errorReport.errorMessage.invalidDecimal';
        errorMessage = t(message, {
          column: errorObj.column,
          row: errorObj.row,
          digit: decimalDigits,
        });
        break;

      // 英数字でない時のエラー
      case ERROR_CODE.REQUIRE_ALPHANUMERIC:
        errorMessage = t('errorReport.errorMessage.requireAlphanumric', { column: errorObj.column, row: errorObj.row });
        break;

      // 数値が範囲を超えているときのエラー (概況表の金額に対するバリデーション)
      case ERROR_CODE.OUT_OF_RANGE:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列は桁数の上限（12桁）を超過しています`
            : `Row ${errorObj.row} and column ${errorObj.column} exceeds maximum digit limit (12 digits).`;
        break;

      // 組織コードに一致する回答対象が見つからないときのエラー (概況表の組織情報に対するバリデーション)
      case ERROR_CODE.MISSING_RESPONDENT_TARGET:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目に一致する回答対象が見つかりませんでした`
            : `Row ${errorObj.row} No matching respondent target found for the organization information on this line.`;
        break;

      // 文字列に改行が含まれているときのエラー
      case ERROR_CODE.HAS_LINE_BREAKES:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列は改行を含むことのできない項目です`
            : `Row ${errorObj.row} and column ${errorObj.column} cannot contain line breakes.`;
        break;

      case ERROR_CODE.REQUIRE_NUMBER_OR_STRING:
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列は文字列または数値で入力してください`
            : `Only numbers or string can be entered in row ${errorObj.row} and column ${errorObj.column}.`;
        break;

      // 人件費人員(重点分野別)の雇用形態の値がドメイン定義と一致していない時のエラー
      case ERROR_CODE.REQUIRE_EMPLOYMENT_STATUS_PERSONNEL_PRIORITY_DOMAIN:
        // エラーメッセージ
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列には「1.正社員」「2.TTCからの出向受入」「3.2以外の出向受入」「4.派遣、嘱託、パート、アルバイト」のいずれかを入力して下さい`
            : `Row ${errorObj.row} and column ${errorObj.column}, Please enter one of the following in the column: "1.Full time Employee","2.Expat from TTC","3.Expat other" or "4.Temporary/Contract/Part-time"`;
        break;

      // 製造原価人員(重点分野別)の雇用形態の値がドメイン定義と一致していない時のエラー
      case ERROR_CODE.REQUIRE_EMPLOYMENT_STATUS_MANUFYEAR_PLANUR_PRIORITY_DOMAIN:
        // エラーメッセージ
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列には「1.正社員」「2.1以外の従業員/派遣/その他」のいずれかを入力して下さい`
            : `Row ${errorObj.row} and column ${errorObj.column}, Please enter one of the following in the column: "1.Full time Employee" or "2.Temp/others"`;
        break;

      // 人件費人員(重点分野別)、製造原価人員(重点分野別)の重点分野の値がドメイン定義と一致していない時のエラー
      case ERROR_CODE.REQUIRE_PRIORITY_DOMAIN:
        // エラーメッセージ
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目${errorObj.column}列には「ネクストモビリティ」「再生可能エネルギー・エネルギーマネジメント」「アフリカ」「循環型静脈」「バッテリー」「水素・代替燃料」「Economy of Life」「基盤事業」のいずれかを入力して下さい`
            : `Row ${errorObj.row} and column ${errorObj.column}, Please enter one of the following in the column: "Next Mobility","Renewable Energy & Energy Management","Africa","Circular Economy","Batteries","Hydrogen & Alternative Fuels","Economy of Life" or "Organic Business"`;
        break;

      // 差異人数入力欄(重点分野別)で、雇用形態または重点分野または数値または理由のいずれかしか入力されていない時のエラー
      case ERROR_CODE.REQUIRE_INPUT_EMPLOYMENT_STATUS_PRIORITY_DOMAIN_DIFFERENCE_PERSON_COUNT_AND_REASON:
        // エラーメッセージ
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 雇用形態、重点分野、差異人数、差異理由が揃っていない項目があります`
            : `Row ${errorObj.row}, Please enter the employment status, priority domains, difference person count and reason`;
        break;

      // 雇用形態ごとに人件費人員(重点分野別)が人件費人員(雇用形態別)と一致していない時のエラー
      case ERROR_CODE.INVALID_PERSONNEL_PRIORITY_DOMAIN_SUM:
        // 雇用形態
        const personnelEmploymentStatus = errorObj.value && 'employment_status' in errorObj.value ? errorObj.value.employment_status : '';
        // エラーメッセージ
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 「${
                personnelEmploymentStatus !== '' ? personnelEmploymentStatus.jpn : ''
              }」について「重点分野の人員数合計」が「人件費に紐づく人員数」に一致していません`
            : `${errorObj.row}, (${
                personnelEmploymentStatus !== '' ? personnelEmploymentStatus.eng : ''
              }) "Total of Headcount of Personnel cost relater (Priority Domains)" does not match "Headcount personnel cost relater (Employment Status)"`;
        break;

      // 雇用形態ごとに製造原価人員(重点分野別)が製造原価人員(雇用形態別)と一致していない時のエラー
      case ERROR_CODE.INVALID_MANUFYEAR_PLANUR_PRIORITY_DOMAIN_SUM:
        // 雇用形態
        const manufyearPlanurEmploymentStatus = errorObj.value && 'employment_status' in errorObj.value ? errorObj.value.employment_status : '';
        // エラーメッセージ
        errorMessage =
          currentLang.value === 'ja'
            ? `${errorObj.row}行目 「${
                manufyearPlanurEmploymentStatus !== '' ? manufyearPlanurEmploymentStatus.jpn : ''
              }」について「重点分野の人員数合計」が「製造原価に紐づく人員数」に一致していません`
            : `${errorObj.row}, (${
                manufyearPlanurEmploymentStatus !== '' ? manufyearPlanurEmploymentStatus.eng : ''
              }) "Total of Headcount of Production cost relater (Priority Domains)" does not match "Headcount of Production cost relater (Employment Status)"`;
        break;
      default:
    }

    return errorMessage;
  };

  return { getErrorMessageList, getErrorMessage };
};

export default useImportExcelValidate;
