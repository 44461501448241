import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';

export const enqueteInfoSlice = createSlice({
  name: 'enqueteInfo',
  initialState: {
    // 新規作成時にアンケート情報を設定したか
    isSetup: false,
    // 主管部として選択可能なリスト
    ownerOrganizations: [] as { key: string; label: string }[],
    // 選択されている主管部
    selectedOwnerOrganization: { key: '', label: '' },
    // アンケート種別の一覧（SurveyHeaderEntityをそのまま配列で管理）
    enqueteTypeList: [] as SurveyHeaderEntity[],
  },
  reducers: {
    initialize: (state) => {
      state.isSetup = false;
      state.ownerOrganizations = [];
      state.selectedOwnerOrganization = { key: '', label: '' };
      state.enqueteTypeList = [];
    },
    setOwnerOrganizations: (state, action: PayloadAction<{ key: string; label: string }[]>) => {
      state.ownerOrganizations = action.payload;
    },
    setEnqueteTypeList: (state, action: PayloadAction<SurveyHeaderEntity[]>) => {
      state.enqueteTypeList = action.payload;
    },
    save: (state, action: PayloadAction<{ key: string; label: string }>) => {
      state.selectedOwnerOrganization = action.payload;
      state.isSetup = true;
    },
    setSelectedOwnerOrganization: (state, action: PayloadAction<{ key: string; label: string }>) => {
      state.selectedOwnerOrganization = action.payload;
    },
  },
});

export const { initialize, setOwnerOrganizations, setEnqueteTypeList, save, setSelectedOwnerOrganization } = enqueteInfoSlice.actions;
