import { Grid, Stack } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { useSummaryAggregate } from '../hooks/useSummaryAggregate';
import { SummaryAggregateDetailGrid } from './SummaryAggregateDetailGrid';
import { SummaryAggregateGrid } from './SummaryAggregateGrid';
import { ExchangeRateModal } from './modals/exchange-rate/components/ExchangeRateModal';

const TypeSeriesSummaryAggregateTab: React.FC = React.memo(() => {
  const { id: respondentRequestId } = useParams();
  const { isLoading, previousYear, currentYear, aggregateRows, aggregateDetailMap, exchangeRateRows } = useSummaryAggregate(respondentRequestId);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const renderDetailGrid = () => {
    if (!aggregateDetailMap || Object.values(aggregateDetailMap).length === 0) {
      return null;
    }
    return <SummaryAggregateDetailGrid aggregateDetailMap={aggregateDetailMap} previousYear={previousYear} currentYear={currentYear} />;
  };
  return (
    <Stack direction="column" spacing={2} sx={{ mb: 8 }}>
      <LoadableComponent isLoading={isLoading}>
        <Grid container alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ textAlign: 'right' }}>
          <StyledButton onClick={() => setIsOpen(true)}>{t('enqueteStatus.summaryAggregate.exchangeRateModal.button')}</StyledButton>
        </Grid>
        <SummaryAggregateGrid aggregateRows={aggregateRows} previousYear={previousYear} currentYear={currentYear} />
        {renderDetailGrid()}
        <ExchangeRateModal
          exchangeRateRows={exchangeRateRows}
          isOpen={isOpen}
          onCloseFunc={() => setIsOpen(false)}
          currentYear={currentYear}></ExchangeRateModal>
      </LoadableComponent>
    </Stack>
  );
});

export default TypeSeriesSummaryAggregateTab;
