import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SendTextModal } from 'src/features/general/enquete-answer/components/modal/send-text/components/SendTextModal';
import { SubmitConfirmationModal } from 'src/features/general/enquete-answer/components/modal/send-text/components/SubmitConfirmationModal';
import { useAppSelector } from 'src/redux/store';
import { useSendReversion } from '../hooks/useSendReversion';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isRequest: boolean;
}

export const SwitchReversionModalLayout: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, isRequest } = props;
  const { showConfirm } = useAppSelector((state) => state.sendTextModal);
  const { onSendReversion } = useSendReversion();
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });

  return (
    <>
      <SendTextModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={isRequest ? t('enqueteAnswerInput.modal.requestReversion.title') : t('enqueteAnswerInput.modal.reversion.title')}
        label={isRequest ? t('enqueteAnswerInput.modal.requestReversion.textField.label') : t('enqueteAnswerInput.modal.reversion.textField.label')}
        placeholder={
          isRequest
            ? t('enqueteAnswerInput.modal.requestReversion.textField.placeholder')
            : t('enqueteAnswerInput.modal.reversion.textField.placeholder')
        }
        methods={methods}
      />
      {showConfirm && (
        <SubmitConfirmationModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={t('enqueteAnswerInput.modal.submit.title')}
          description={
            isRequest ? t('enqueteAnswerInput.modal.submitRequestReversion.description') : t('enqueteAnswerInput.modal.submitReversion.description')
          }
          onClick={async () => await onSendReversion({ setIsOpen, isRequest })}
          methods={methods}
        />
      )}
    </>
  );
};
