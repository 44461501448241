import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OverviewEnqueteOptionData } from 'src/@types/overviewEnquete';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import { useAddOptionItemForm } from '../hooks/useAddOptionItemForm';

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOverviewEnqueteOptionData: React.Dispatch<React.SetStateAction<OverviewEnqueteOptionData>>;
  overviewInfoData: OverviewEnqueteOptionData;
};

export const AddOptionItemForm: React.FC<Props> = (props) => {
  const { setIsModalOpen, setOverviewEnqueteOptionData, overviewInfoData } = props;
  const { t } = useTranslation();
  const { onClickSaveModal, modalValidation } = useAddOptionItemForm();

  const methods = useForm({
    mode: 'onChange',
  });

  const sx = { wordBreak: 'keep-all', textAlign: 'center' };

  /** モーダル保存ボタン押下時イベント */
  const onClickSaveData = (data: FieldValues) => {
    onClickSaveModal(data, overviewInfoData, setOverviewEnqueteOptionData, setIsModalOpen);
  };

  return (
    <Grid container>
      {/** 追加項目(日本語) **/}
      <Grid item container alignItems="center" p={2}>
        <Grid item md={4}>
          <Typography variant="body1" sx={sx}>
            {t('enqueteCreate.overviewEnqueteOption.itemJpn')}
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Grid item xs={10}>
            <RHFTextField
              name={'itemJpn'}
              defaultValue={''}
              rules={modalValidation(t('enqueteCreate.overviewEnqueteOption.itemJpn'))}
              control={methods.control}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      {/** 追加項目(英語) **/}
      <Grid item container alignItems="center" p={2}>
        <Grid item md={4}>
          <Typography variant="body1" sx={sx}>
            {t('enqueteCreate.overviewEnqueteOption.itemEng')}
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Grid item xs={10}>
            <RHFTextField
              name={'itemEng'}
              defaultValue={''}
              rules={modalValidation(t('enqueteCreate.overviewEnqueteOption.itemEng'))}
              control={methods.control}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={4}>
        <Grid item xs={6} justifyContent={'left'}>
          <Stack direction={'row'} spacing={1}>
            {/* キャンセルボタン */}
            <Button variant={'outlined'} onClick={() => setIsModalOpen(false)} children={t('button.close')} color="error" />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={'row-reverse'}>
            {/* 保存ボタン */}
            <Button
              variant={'contained'}
              onClick={methods.handleSubmit(onClickSaveData)}
              children={t('button.save')}
              sx={{ ml: 2 }}
              disabled={!methods.formState.isValid}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
