import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrencyRow } from 'src/api/useCurrencyApi';
import { PartialCustomerData } from 'src/api/useCustomerApi';

export const CUSTOMER_STATUS = {
  OUTSIDES_COMPANY: 'OUTSIDES_COMPANY',
  DELETE: 'DELETE',
  EXIST: 'EXIST',
};
export type CustomerStatus = typeof CUSTOMER_STATUS[keyof typeof CUSTOMER_STATUS];

export const AGGREGATE_TYPE = {
  AGGREGATION: 'AGGREGATION',
  OUTSIDES_COMPANY: 'OUTSIDES_COMPANY',
  NO_DATA: 'NO_DATA',
  NO_AGGREGATION: 'NO_AGGREGATION',
} as const;
export type AggregateType = typeof AGGREGATE_TYPE[keyof typeof AGGREGATE_TYPE];

export const DATA_SOURCE = {
  JAST: 'JAST',
  WEST: 'WEST',
} as const;
export type DataSource = typeof DATA_SOURCE[keyof typeof DATA_SOURCE];

export const AMOUNT_SOURCE_TYPE = {
  ...DATA_SOURCE,
  ANSWERED: 'ANSWERED',
  PRE_ANSWERED: 'PRE_ANSWERED',
} as const;
export type AmountSourceType = typeof AMOUNT_SOURCE_TYPE[keyof typeof AMOUNT_SOURCE_TYPE];

export const ACCOUNTS_TYPE = {
  PAYABLE: 'PAYABLE',
  RECEIVABLE: 'RECEIVABLE',
} as const;
export type AccountsType = typeof ACCOUNTS_TYPE[keyof typeof ACCOUNTS_TYPE];

export interface CustomerGroup {
  parentCustomerCode: string;
  parentCustomerName: string;
  parentCustomerNameEng: string;
  isOpen: boolean;
  childrenCustomer: ChildrenCustomer[];
}

export interface ChildrenCustomer {
  customerCode: string;
  customerName: string;
  customerNameEng: string;
  regionCode: string;
  regionName: string;
  regionNameEng: string;
  countryCode: string;
  countryName: string;
  countryNameEng: string;
  currentYear: {
    // AGGREGATION, OUTSIDES_COMPANY, NO_DATA 集計対象の区分
    aggregateType: string;
    // アンケート回答結果
    answerPurchaseAmount: number | null;
    // アンケート回答結果
    answerSalesAmount: number | null;
    // ANSWERED, JAST, WEST, PRE_ANSWERED = 入力
    sourceType: AmountSourceType;
    // JAST、WESTのシステムからの取得値
    systemPurchaseAmount: number | null;
    // JAST、WESTのシステムからの取得値
    systemSalesAmount: number | null;
  };
  previousYear: {
    // AGGREGATION, OUTSIDES_COMPANY, NO_DATA 集計対象の区分
    aggregateType: string;
    // アンケート回答結果
    answerPurchaseAmount: number | null;
    // アンケート回答結果
    answerSalesAmount: number | null;
    // ANSWERED, JAST, WEST, PRE_ANSWERED = 入力
    sourceType: AmountSourceType;
    // JAST、WESTのシステムからの取得値
    systemPurchaseAmount: number | null;
    // JAST、WESTのシステムからの取得値
    systemSalesAmount: number | null;
  };
  // 債権
  receivableBalance: number | null;
  // 債務
  payableBalance: number | null;
  // 売上高コメント
  salesComment: string;
  // 仕入高高コメント
  purchaseComment: string;
  // 会社の状態管理（削除されたり、系列から除外された時などのデータを保持）
  customerStatus: CustomerStatus;
}

export interface DefaultValue {
  // 取引先コード
  customerCode: string;
  // 債権
  receivableBalance: number | null;
  // 債務
  payableBalance: number | null;
}

export const seriesAnswerSlice = createSlice({
  name: 'seriesAnswer',
  initialState: {
    customerGroups: [] as CustomerGroup[],
    currency: '',
    currencies: [] as CurrencyRow[],
    isUsePastData: false,
    hasError: false as boolean,
    numberOfDecimalPlaces: null as number | null,
    rate: 0,
    defaultValues: [] as DefaultValue[],
    isWarningDescription: true,
    customers: [] as PartialCustomerData[],
  },
  reducers: {
    setCustomerGroups: (state, action: PayloadAction<CustomerGroup[]>) => {
      state.customerGroups = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setCurrencyList: (state, action: PayloadAction<CurrencyRow[]>) => {
      state.currencies = action.payload;
    },
    setIsUsePastData: (state, action: PayloadAction<boolean>) => {
      state.isUsePastData = action.payload;
    },
    // アンケート回答入力時の状態を保持する
    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    setNumberOfDecimalPlaces: (state, action: PayloadAction<number>) => {
      state.numberOfDecimalPlaces = action.payload;
    },
    setRate: (state, action: PayloadAction<number>) => {
      state.rate = action.payload;
    },
    setInitIsUsePastData: (state) => {
      state.isUsePastData = false;
    },
    setDefaultValues: (state, action: PayloadAction<DefaultValue[]>) => {
      state.defaultValues = action.payload;
    },
    setIsWarningDescription: (state, action: PayloadAction<boolean>) => {
      state.isWarningDescription = action.payload;
    },
    setCustomers: (state, action: PayloadAction<PartialCustomerData[]>) => {
      state.customers = action.payload;
    },
  },
});
export const {
  setCustomerGroups,
  setCurrency,
  setCurrencyList,
  setIsUsePastData,
  setHasError,
  setNumberOfDecimalPlaces,
  setRate,
  setInitIsUsePastData,
  setDefaultValues,
  setIsWarningDescription,
  setCustomers,
} = seriesAnswerSlice.actions;

export default seriesAnswerSlice.reducer;
