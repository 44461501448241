import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Question } from 'src/@types/generalEnquete';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { setInitGeneralQuestions } from 'src/features/general/enquete-answer/store/enqueteGeneralAnswerSlice';
import { dispatch, RootState } from 'src/redux/store';
import { useModalHandlers } from '../../../../hooks/useModalHandlers';
import { useTranslationUpload } from '../hooks/useTranslationUpload';
import EnqueteTranslationSupportForm from './EnqueteTranslationSupportForm';

const EnqueteTranslationSupportModal: React.FC = () => {
  const { t } = useTranslation();
  const { replaceTranslationQuestionsData, convertFileArrayToTranslationTable } = useTranslationUpload();
  const { onClickModalCloseTranslationSupport } = useModalHandlers();
  const questionsTranslationData = useSelector((state: RootState) => state.enqueteCreate.questionsTranslationData);
  const questionsJpn: Question[] = useSelector((state: RootState) => state.enqueteCreate.questions);
  const questionsTranslationDataArray = convertFileArrayToTranslationTable(questionsTranslationData);
  const questionsEng: Question[] = replaceTranslationQuestionsData(questionsJpn, questionsTranslationDataArray);

  useEffect(() => {
    dispatch(setInitGeneralQuestions(questionsEng));
  }, [questionsEng]);

  // 日英変換ダイアログ表示制御
  const isModalOpenTranslationSupport = useSelector((state: RootState) => state.enqueteCreate.isModalOpenTranslationSupport);

  return (
    <StyledModal
      isOpen={isModalOpenTranslationSupport}
      onCloseFunc={onClickModalCloseTranslationSupport}
      modalTitle={t('enqueteCreate.translationSupportTitle')}
      mode={'general'}
      children={<EnqueteTranslationSupportForm />}
    />
  );
};

export default EnqueteTranslationSupportModal;
