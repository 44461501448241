import { Stack } from '@mui/material';
import { isEmpty, isNaN } from 'lodash';
import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BonsaiRole } from 'src/@types/role';
import { useUserApi } from 'src/api/useUserApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import {
  EnqueteAnswerEntity,
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  ManufyearPlanurEmploymentPriorityDomainTabAnswerData,
  ManufyearPlanurEmploymentPriorityDomainTabData,
  ManufyearPlanurEmploymentTabAnswerData,
  ManufyearPlanurEmploymentTabData,
  PersonnelEmploymentTabAnswerData,
  PersonnelEmploymentTabData,
  PersonnelFunctionTabAnswerData,
  PersonnelFunctionTabData,
  PersonnelPriorityDomainTabAnswerData,
  PersonnelPriorityDomainTabData,
  PersonnelSurveyDiffFormAnswerInputData,
  PersonnelSurveyPriorityDomainDiffFormAnswerInputData,
  SurveyFormAnswerData,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useAuth from 'src/hooks/useAuth';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import { languageDataTypes } from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../../../hooks/useEnqueteAnswerGrid';

// TODO: Function渡すだけで動くようにしたい
export interface EnqueteAnswerSaveButtonProps {
  personnelEmploymentRowData?: EnqueteAnswerLoadInputEntity[];
  personnelEmploymentResultAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[];
  personnelEmploymentPlanAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[];
  personnelCorporateRowData?: EnqueteAnswerLoadInputEntity[];
  personnelCorporateOtherFormTextValue?: string;
  personnelSalesRowData?: EnqueteAnswerLoadInputEntity[];
  // 人件費人員（重点分野別）1.正社員の行データ
  personnelPriorityDomainRegularEmployeeRowData?: EnqueteAnswerLoadInputEntity[];
  // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
  personnelPriorityDomainSecondmentFromTTCRowData?: EnqueteAnswerLoadInputEntity[];
  // 人件費人員（重点分野別）3.2以外の出向受入の行データ
  personnelPriorityDomainSecondmentFromOtherRowData?: EnqueteAnswerLoadInputEntity[];
  // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
  personnelPriorityDomainDispatchEtcRowData?: EnqueteAnswerLoadInputEntity[];
  // 人件費人員（重点分野別）年計 - 実見のデータ
  personnelPriorityDomainResultAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
  // 人件費人員（重点分野別）中計 - 年計のデータ
  personnelPriorityDomainPlanAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
  manufyearPlanurEmploymentStatusRowData?: EnqueteAnswerLoadInputEntity[];
  manufyearPlanurEmploymentResultAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[];
  manufyearPlanurEmploymentPlanAndPlanDiffForm?: TempPersonnelSurveyDiffFormAnswerInputData[];
  // 製造原価人員（重点分野別）1.正社員の行データ
  manufyearPlanurPriorityDomainRegularEmployeeRowData?: EnqueteAnswerLoadInputEntity[];
  // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
  manufyearPlanurPriorityDomainOtherEmployeesRowData?: EnqueteAnswerLoadInputEntity[];
  // 製造原価人員（重点分野別）年計 - 実見のデータ
  manufyearPlanurPriorityDomainResultAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
  // 製造原価人員（重点分野別）中計 - 年計のデータ
  manufyearPlanurPriorityDomainPlanAndPlanDiffForm?: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
  respondentTargetId?: string;
  comifrmRequestId?: string | null;
  enqueteId?: string;
}
export const EnqueteAnswerSaveButtons: React.FC<EnqueteAnswerSaveButtonProps> = (props): React.ReactElement => {
  const userApi = useUserApi();
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  // ユーザー情報宣言
  const { user } = useAuth();
  const userRole = null !== user ? user.role : [];
  const {
    saveAnswer,
    tempSaveAnswer,
    updateAnswer,
    tempUpdateAnswer,
    setHasErrorOtherTextForm,
    setHasErrorPersonnelGridNotMatchTotal,
    setHasErrorPersonnelCorporateGridTotalOver,
    setHasErrorPersonnelSalesGridTotalOver,
    setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm,
    setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm,
    setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm,
    setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // エラー状態設定：人件費人員(重点分野)
    // 人件費人員(重点分野)：1.正社員の人員合計と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 人件費人員(重点分野)：2.TTCからの出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
    // 人件費人員(重点分野)：3.2.以外の出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトと（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
    // 人件費人員(重点分野)年計 - 実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されていないエラーの設定
    setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員(重点分野)中計 - 年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されていないエラーの設定
    setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm,
    // エラー状態設定：製造原価人員(重点分野)
    // 製造原価人員(重点分野)年計 - 実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されていないエラーの設定
    setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されていないエラーの設定
    setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員(重点分野)：1.正社員の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
  } = useEnqueteAnswerGrid();
  // 取得したSurveyDetailの情報を格納
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);
  // 取得したConfirmRequestの情報を格納
  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);
  // 取得したAnswerの情報を格納
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  // 人件費人員(雇用形態別)のTotalを格納
  const personnelEmploymentTotal = useAppSelector((state) => state.enqueteAnswer.personnelEmploymentTotal);
  // 人件費人員(重点分野)：1.正社員のTotal
  const personnelPriorityDomainRegularEmployeeGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.personnelPriorityDomainRegularEmployeeGridTotal,
  );
  // 人件費人員(重点分野)：2.TTCからの出向受入のTotal
  const personnelPriorityDomainSecondmentFromTTCGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.personnelPriorityDomainSecondmentFromTTCGridTotal,
  );
  // 人件費人員(重点分野)：3.2.以外の出向受入のTotal
  const personnelPriorityDomainSecondmentFromOtherGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.personnelPriorityDomainSecondmentFromOtherGridTotal,
  );
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトのTotal
  const personnelPriorityDomainDispatchEtcGridTotal = useAppSelector((state) => state.enqueteAnswer.personnelPriorityDomainDispatchEtcGridTotal);
  // 製造原価人員(重点分野)：1.正社員のTotal
  const manufyearPlanurPriorityDomainRegularEmployeeGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.manufyearPlanurPriorityDomainRegularEmployeeGridTotal,
  );
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他のTotal
  const manufyearPlanurPriorityDomainOtherEmployeesGridTotal = useAppSelector(
    (state) => state.enqueteAnswer.manufyearPlanurPriorityDomainOtherEmployeesGridTotal,
  );
  // コーポレート人員のTotalを格納
  const corporateGridTotal = useAppSelector((state) => state.enqueteAnswer.corporateGridTotal);
  // 営業人員のTotalを格納
  const salesGridTotal = useAppSelector((state) => state.enqueteAnswer.salesGridTotal);
  // タブ：人件費人員(雇用形態別)の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorPersonnelEmploymentResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelEmploymentResultAndPlanDiffForm,
  );
  // タブ：人件費人員(雇用形態別)の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorPersonnelEmploymentPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelEmploymentPlanAndPlanDiffForm,
  );
  // タブ：製造原価人員(雇用形態別)の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm,
  );
  // タブ：製造原価人員(雇用形態別)の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm,
  );
  // タブ：人件費人員（重点分野別）の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorPersonnelPriorityDomainResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainResultAndPlanDiffForm,
  );
  // タブ：人件費人員（重点分野別）の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm,
  );
  // タブ：製造原価人員（重点分野別）の差異項目(年計-実見)におけるエラーフラグを取得
  const hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm,
  );
  // タブ：製造原価人員（重点分野別）の差異項目(中計-年計)におけるエラーフラグを取得
  const hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const useJudg = useJudgmentCurrentLanguage();

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    const answerStatus: string = (!isEmpty(enqueteAnswerData) ? enqueteAnswerData.status : t('answerStatus.unAnswered')) as string;
    // 回答状況に王座して
    if (answerStatus === 'ANSWERED' || !isEnqueteAnswerOpen) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }
  }, [enqueteAnswerData, isEnqueteAnswerOpen]); //eslint-disable-line

  //-------------------------------------------------------------
  // 回答情報JSON作成
  //-------------------------------------------------------------
  // 人件費人員(雇用形態別)タブ回答情報
  let personnelEmploymentAnswerInputData: PersonnelEmploymentTabAnswerData | {};
  // 人件費人員(機能別)タブ回答情報
  let personnelFunctionTabAnswerData: PersonnelFunctionTabAnswerData | {};
  // 人件費人員(重点分野)タブ回答情報
  let personnelPriorityDomainTabAnswerData: PersonnelPriorityDomainTabAnswerData | {};
  // 製造原価人員(雇用形態別)タブ回答情報
  let manufyearPlanurEmploymentTabAnswerData: ManufyearPlanurEmploymentTabAnswerData | {};
  // 製造原価人員(重点分野)タブ回答情報
  let manufyearPlanurEmploymentPriorityDomainTabAnswerData: ManufyearPlanurEmploymentPriorityDomainTabAnswerData | {};
  //-------------------------------------------------------------
  // 差異人数を文字から数値に変換する
  //-------------------------------------------------------------
  const isDiffValueChangeNumber = (
    diffFormAnswerInputData: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined,
  ): PersonnelSurveyDiffFormAnswerInputData[] | undefined => {
    if (diffFormAnswerInputData !== undefined) {
      const tempDiffFormAnswerInputData: PersonnelSurveyDiffFormAnswerInputData[] = diffFormAnswerInputData.map((item, count) => {
        if (item !== undefined) {
          // 文字列から数値に変換を行う
          const tempDiffMemberInputValue =
            item.diffMemberInputValue !== null && item.diffMemberInputValue !== '' ? Number(item.diffMemberInputValue) : null;
          return {
            count: item.count,
            diffMemberInputValue: isNaN(tempDiffMemberInputValue) || tempDiffMemberInputValue === 0 ? null : tempDiffMemberInputValue,
            diffMemberReasonInputValue: item.diffMemberReasonInputValue,
            diffMemberReasonSelectValue: item.diffMemberReasonSelectValue,
          };
        } else {
          return {
            count: count,
            diffMemberInputValue: null,
            diffMemberReasonInputValue: '',
            diffMemberReasonSelectValue: '',
          };
        }
      });
      return tempDiffFormAnswerInputData;
    }
    return undefined;
  };

  //-------------------------------------------------------------
  // 差異人数と差異理由に未入力が無いかチェックする
  //-------------------------------------------------------------
  const isEmptyCheckDiffInputValue = (diffFormAnswerInputData: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined): boolean => {
    if (diffFormAnswerInputData !== undefined) {
      let errorFlag = false;
      diffFormAnswerInputData.forEach((item) => {
        const checkTargetValue = item.diffMemberInputValue !== null ? item.diffMemberInputValue.toString() : null;
        const checkTargetInputReasonValue = item.diffMemberReasonInputValue !== null ? item.diffMemberReasonInputValue : null;
        // 差異理由が存在する場合に差異人数が入力されているか確認する
        if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue !== '') {
          if (checkTargetValue === null || checkTargetValue.length === 0) {
            errorFlag = true;
          }
        }
        // 差異人数が存在する場合に差異人数が入力されているか確認する
        if (checkTargetValue !== null && checkTargetValue !== '') {
          if (checkTargetInputReasonValue === null || checkTargetInputReasonValue.length === 0) {
            errorFlag = true;
          }
        }
      });
      if (errorFlag) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  //-------------------------------------------------------------
  // 差異人数、差異理由、雇用形態と重点分野に未入力が無いかチェックする
  //-------------------------------------------------------------
  const isEmptyCheckPriorityDomainDiffInputValue = (
    diffFormAnswerInputData: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined,
  ): boolean => {
    if (diffFormAnswerInputData !== undefined) {
      let errorFlag = false;
      diffFormAnswerInputData.forEach((item) => {
        // 差異人数
        const checkTargetValue = item !== undefined && item.diffMemberInputValue ? item.diffMemberInputValue.toString() : null;
        // 差異理由
        const checkTargetInputReasonValue = item !== undefined && item.diffMemberReasonInputValue ? item.diffMemberReasonInputValue : null;
        // 雇用形態
        const checkEmployment = item !== undefined && item.employment ? item.employment : null;
        // 重点分野
        const checkPriorityDomain = item !== undefined && item.priorityDomain ? item.priorityDomain : null;
        // 差異理由が存在する場合に他の項目が入力されているか確認する
        if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue !== '') {
          if (
            checkTargetValue === null ||
            checkTargetValue.length === 0 ||
            checkEmployment === null ||
            checkEmployment.length === 0 ||
            checkPriorityDomain === null ||
            checkPriorityDomain.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
        // 差異人数が存在する場合に他の項目が入力されているか確認する
        if (checkTargetValue !== null && checkTargetValue !== '') {
          if (
            checkTargetInputReasonValue === null ||
            checkTargetInputReasonValue.length === 0 ||
            checkEmployment === null ||
            checkEmployment.length === 0 ||
            checkPriorityDomain === null ||
            checkPriorityDomain.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
        // 雇用形態が存在する場合に他の項目が入力されているか確認する
        if (checkEmployment !== null && checkEmployment !== '') {
          if (
            checkTargetInputReasonValue === null ||
            checkTargetInputReasonValue.length === 0 ||
            checkTargetValue === null ||
            checkTargetValue.length === 0 ||
            checkPriorityDomain === null ||
            checkPriorityDomain.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
        // 重点分野が存在する場合に他の項目が入力されているか確認する
        if (checkPriorityDomain !== null && checkPriorityDomain !== '') {
          if (
            checkTargetInputReasonValue === null ||
            checkTargetInputReasonValue.length === 0 ||
            checkTargetValue === null ||
            checkTargetValue.length === 0 ||
            checkEmployment === null ||
            checkEmployment.length === 0
          ) {
            // エラーがある
            errorFlag = true;
          }
        }
      });
      if (errorFlag) {
        // エラーがある
        return true;
      } else {
        // エラーがない
        return false;
      }
    }
    // エラーがない
    return false;
  };
  //-------------------------------------------------------------
  // 差異人数(重点分野)を文字から数値に変換する
  //-------------------------------------------------------------
  const isPriorityDomainDiffValueChangeNumber = (
    diffFormAnswerInputData: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined,
  ): PersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined => {
    if (diffFormAnswerInputData !== undefined) {
      const tempDiffFormAnswerInputData: PersonnelSurveyPriorityDomainDiffFormAnswerInputData[] = diffFormAnswerInputData.map((item, count) => {
        if (item !== undefined && item !== null) {
          // 文字列から数値に変換を行う
          const tempDiffMemberInputValue =
            item.diffMemberInputValue !== null && item.diffMemberInputValue !== '' ? Number(item.diffMemberInputValue) : null;
          return {
            // 行番号
            count: item.count,
            // 雇用形態
            employment: item.employment,
            // 重点分野
            priorityDomain: item.priorityDomain,
            // 差異人数
            diffMemberInputValue: isNaN(tempDiffMemberInputValue) || tempDiffMemberInputValue === 0 ? null : tempDiffMemberInputValue,
            // 差異理由
            diffMemberReasonInputValue: item.diffMemberReasonInputValue,
            diffMemberReasonSelectValue: item.diffMemberReasonSelectValue,
          };
        } else {
          return {
            // 行番号
            count: count,
            // 雇用形態
            employment: '',
            // 重点分野
            priorityDomain: '',
            // 差異人数
            diffMemberInputValue: null,
            // 差異理由
            diffMemberReasonInputValue: '',
            diffMemberReasonSelectValue: '',
          };
        }
      });
      return tempDiffFormAnswerInputData;
    }
    return undefined;
  };

  //-------------------------------------------------------------
  // 人件費人員(雇用形態別)
  //-------------------------------------------------------------
  if (
    props.personnelEmploymentRowData !== undefined ||
    props.personnelEmploymentResultAndPlanDiffForm !== undefined ||
    props.personnelEmploymentPlanAndPlanDiffForm !== undefined
  ) {
    // 人件費人員(雇用形態別)設問情報
    const personnelEmploymentFormData = surveyDetailData.surveyFormData.formData.tabData[0] as PersonnelEmploymentTabData;
    // 人件費人員(雇用形態別)：グリッド回答情報
    const personnelEmploymentRowData =
      props.personnelEmploymentRowData !== undefined ? props.personnelEmploymentRowData.filter((x: any) => x.id !== 'sumTotal') : undefined;
    // 人件費人員(雇用形態別)の回答情報を組み合わせてタブ全体の回答情報を作成する
    personnelEmploymentAnswerInputData = {
      tabId: 'personnelEmployment',
      tabTitle: useJudg.getText(personnelEmploymentFormData['tabTitle'] as languageDataTypes),
      tabInputData: [
        {
          id: 'personnelEmploymentGrid',
          title: useJudg.getText(personnelEmploymentFormData.tabFormData[0]['title'] as languageDataTypes),
          type: 'grid',
          data: personnelEmploymentRowData as EnqueteAnswerLoadInputEntity[],
        },
        {
          id: 'planVsAct',
          type: 'diffForm',
          data: isDiffValueChangeNumber(props.personnelEmploymentResultAndPlanDiffForm) as PersonnelSurveyDiffFormAnswerInputData[],
        },
        {
          id: 'midTermPlanVsPlan',
          type: 'diffForm',
          data: isDiffValueChangeNumber(props.personnelEmploymentPlanAndPlanDiffForm) as PersonnelSurveyDiffFormAnswerInputData[],
        },
      ],
    };
  } else {
    personnelEmploymentAnswerInputData = {};
  }

  //-------------------------------------------------------------
  // 人件費人員(機能別)
  //-------------------------------------------------------------
  if (props.personnelCorporateRowData !== undefined || props.personnelSalesRowData !== undefined) {
    // 人件費人員(機能別)設問回答情報
    const personnelFunctionTabFormData = surveyDetailData.surveyFormData.formData.tabData[1] as PersonnelFunctionTabData;
    // 人件費人員(機能別)：コーポレートグリッド回答情報
    let personnelCorporateRowData =
      props.personnelCorporateRowData !== undefined ? props.personnelCorporateRowData.filter((x) => x.id !== 'sumTotal') : undefined;

    // 人件費人員(機能別)：営業グリッド回答情報
    let personnelSalesRowData =
      props.personnelSalesRowData !== undefined ? props.personnelSalesRowData.filter((x) => x.id !== 'sumTotal') : undefined;
    // 人件費人員(機能別)の回答情報を組み合わせてタブ全体の回答情報を作成する
    personnelFunctionTabAnswerData = {
      tabId: 'personnelCorporate',
      tabTitle: useJudg.getText(personnelFunctionTabFormData['tabTitle'] as languageDataTypes),
      tabInputData: [
        {
          id: 'personnelCorporateGrid',
          title: useJudg.getText(personnelFunctionTabFormData.tabFormData[0]['title'] as languageDataTypes),
          type: 'grid',
          data: personnelCorporateRowData as EnqueteAnswerLoadInputEntity[],
        },
        { id: 'personnelCorporateText', type: 'text', data: props.personnelCorporateOtherFormTextValue as string },
        {
          id: 'personnelSalesGrid',
          title: useJudg.getText(personnelFunctionTabFormData.tabFormData[2]['title'] as languageDataTypes),
          type: 'grid',
          data: personnelSalesRowData as EnqueteAnswerLoadInputEntity[],
        },
      ],
    };
  } else {
    personnelFunctionTabAnswerData = {};
  }

  //-------------------------------------------------------------
  // 人件費人員(重点分野)
  //-------------------------------------------------------------
  if (
    props.personnelPriorityDomainRegularEmployeeRowData !== undefined ||
    props.personnelPriorityDomainSecondmentFromTTCRowData !== undefined ||
    props.personnelPriorityDomainSecondmentFromOtherRowData !== undefined ||
    props.personnelPriorityDomainDispatchEtcRowData !== undefined ||
    props.personnelPriorityDomainResultAndPlanDiffForm !== undefined ||
    props.personnelPriorityDomainPlanAndPlanDiffForm !== undefined
  ) {
    // 人件費人員(重点分野)設問回答情報
    const personnelPriorityDomainTabFormData = surveyDetailData.surveyFormData.formData.tabData[3] as PersonnelPriorityDomainTabData;
    // 人件費人員(重点分野)：1.正社員 回答情報
    let personnelPriorityDomainRegularEmployeeRowData =
      props.personnelPriorityDomainRegularEmployeeRowData !== undefined
        ? props.personnelPriorityDomainRegularEmployeeRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;
    // 人件費人員(重点分野)：2.TTCからの出向受入 回答情報
    let personnelPriorityDomainSecondmentFromTTCRowData =
      props.personnelPriorityDomainSecondmentFromTTCRowData !== undefined
        ? props.personnelPriorityDomainSecondmentFromTTCRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;
    // 人件費人員(重点分野)：3.2.以外の出向受入 回答情報
    let personnelPriorityDomainSecondmentFromOtherRowData =
      props.personnelPriorityDomainSecondmentFromOtherRowData !== undefined
        ? props.personnelPriorityDomainSecondmentFromOtherRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 回答情報
    let personnelPriorityDomainDispatchEtcRowData =
      props.personnelPriorityDomainDispatchEtcRowData !== undefined
        ? props.personnelPriorityDomainDispatchEtcRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;

    // 人件費人員(重点分野)の回答情報を組み合わせてタブ全体の回答情報を作成する
    personnelPriorityDomainTabAnswerData = {
      tabId: 'personnelPriorityDomain',
      tabTitle: useJudg.getText(personnelPriorityDomainTabFormData['tabTitle'] as languageDataTypes),
      tabInputData: [
        {
          // 人件費人員(重点分野)：1.正社員 回答情報
          id: 'personnelPriorityDomainFullTimeEmployeeGrid',
          // タイトル
          title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[0]['title'] as languageDataTypes),
          // タイプ
          type: 'grid',
          // データ
          data: personnelPriorityDomainRegularEmployeeRowData as EnqueteAnswerLoadInputEntity[],
        },
        {
          // 人件費人員(重点分野)：2.TTCからの出向受入 回答情報
          id: 'personnelPriorityDomainExpatFromTtcGrid',
          // タイトル
          title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[1]['title'] as languageDataTypes),
          // タイプ
          type: 'grid',
          // データ
          data: personnelPriorityDomainSecondmentFromTTCRowData as EnqueteAnswerLoadInputEntity[],
        },
        {
          // 人件費人員(重点分野)：3.2.以外の出向受入 回答情報
          id: 'personnelPriorityDomainExpatOtherGrid',
          // タイトル
          title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[2]['title'] as languageDataTypes),
          // タイプ
          type: 'grid',
          // データ
          data: personnelPriorityDomainSecondmentFromOtherRowData as EnqueteAnswerLoadInputEntity[],
        },
        {
          // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 回答情報
          id: 'personnelPriorityDomainOtherGrid',
          // タイトル
          title: useJudg.getText(personnelPriorityDomainTabFormData.tabFormData[3]['title'] as languageDataTypes),
          // タイプ
          type: 'grid',
          // データ
          data: personnelPriorityDomainDispatchEtcRowData as EnqueteAnswerLoadInputEntity[],
        },
        // 人件費人員(重点分野)：年計-実見 回答情報
        {
          id: 'planVsAct',
          // タイプ
          type: 'diffForm',
          // データ
          data: isPriorityDomainDiffValueChangeNumber(
            props.personnelPriorityDomainResultAndPlanDiffForm,
          ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
        },
        // 人件費人員(重点分野)：中計-年計 回答情報
        {
          id: 'midTermPlanVsPlan',
          // タイプ
          type: 'diffForm',
          // データ
          data: isPriorityDomainDiffValueChangeNumber(
            props.personnelPriorityDomainPlanAndPlanDiffForm,
          ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
        },
      ],
    };
  } else {
    personnelPriorityDomainTabAnswerData = {};
  }
  //-------------------------------------------------------------
  // 製造原価人員(雇用形態別)
  //-------------------------------------------------------------
  if (
    props.manufyearPlanurEmploymentStatusRowData !== undefined ||
    props.manufyearPlanurEmploymentResultAndPlanDiffForm !== undefined ||
    props.manufyearPlanurEmploymentPlanAndPlanDiffForm !== undefined
  ) {
    // 製造原価人員(雇用形態別)設問情報
    const manufyearPlanurEmploymentTabFormData = surveyDetailData.surveyFormData.formData.tabData[2] as ManufyearPlanurEmploymentTabData;
    // 製造原価人員(雇用形態別)：グリッド回答情報
    const manufyearPlanurEmploymentStatusRowData =
      undefined !== props.manufyearPlanurEmploymentStatusRowData
        ? props.manufyearPlanurEmploymentStatusRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;
    // 製造原価人員(雇用形態別)の回答情報を組み合わせてタブ全体の回答情報を作成する
    manufyearPlanurEmploymentTabAnswerData = {
      tabId: 'manufyearPlanurEmployment',
      tabTitle: useJudg.getText(manufyearPlanurEmploymentTabFormData['tabTitle'] as languageDataTypes),
      tabInputData: [
        {
          id: 'manufyearPlanurEmploymentGrid',
          title: useJudg.getText(manufyearPlanurEmploymentTabFormData.tabFormData[0]['tabTitle'] as languageDataTypes),
          type: 'grid',
          data: manufyearPlanurEmploymentStatusRowData as EnqueteAnswerLoadInputEntity[],
        },
        {
          id: 'planVsAct',
          type: 'diffForm',
          data: isDiffValueChangeNumber(props.manufyearPlanurEmploymentResultAndPlanDiffForm) as PersonnelSurveyDiffFormAnswerInputData[],
        },
        {
          id: 'midTermPlanVsPlan',
          type: 'diffForm',
          data: isDiffValueChangeNumber(props.manufyearPlanurEmploymentPlanAndPlanDiffForm) as PersonnelSurveyDiffFormAnswerInputData[],
        },
      ],
    };
  } else {
    manufyearPlanurEmploymentTabAnswerData = {};
  }

  //-------------------------------------------------------------
  // 製造原価人員(重点分野)
  //-------------------------------------------------------------
  if (
    props.manufyearPlanurPriorityDomainRegularEmployeeRowData !== undefined ||
    props.manufyearPlanurPriorityDomainOtherEmployeesRowData !== undefined ||
    props.manufyearPlanurPriorityDomainResultAndPlanDiffForm !== undefined ||
    props.manufyearPlanurPriorityDomainPlanAndPlanDiffForm !== undefined
  ) {
    // 製造原価人員(重点分野)設問情報
    const manufyearPlanurEmploymentPriorityDomainTabFormData = surveyDetailData.surveyFormData.formData
      .tabData[4] as ManufyearPlanurEmploymentPriorityDomainTabData;
    // 製造原価人員(重点分野) 1.正社員：グリッド回答情報
    const manufyearPlanurPriorityDomainRegularEmployeeRowData =
      undefined !== props.manufyearPlanurPriorityDomainRegularEmployeeRowData
        ? props.manufyearPlanurPriorityDomainRegularEmployeeRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;
    // 製造原価人員(重点分野) 2.1以外の従業員/派遣/その他：グリッド回答情報
    const manufyearPlanurPriorityDomainOtherEmployeesRowData =
      undefined !== props.manufyearPlanurPriorityDomainOtherEmployeesRowData
        ? props.manufyearPlanurPriorityDomainOtherEmployeesRowData.filter((x) => x.id !== 'sumTotal')
        : undefined;
    // 製造原価人員(重点分野)の回答情報を組み合わせてタブ全体の回答情報を作成する
    manufyearPlanurEmploymentPriorityDomainTabAnswerData = {
      tabId: 'manufyearPlanurEmploymentPriorityDomain',
      tabTitle: useJudg.getText(manufyearPlanurEmploymentPriorityDomainTabFormData['tabTitle'] as languageDataTypes),
      tabInputData: [
        // 製造原価人員(重点分野)：1.正社員 回答情報
        {
          id: 'manufyearPlanurPriorityDomainFullTimeEmployeeGrid',
          // タイトル
          title: useJudg.getText(manufyearPlanurEmploymentPriorityDomainTabFormData.tabFormData[0]['tabTitle'] as languageDataTypes),
          // タイプ
          type: 'grid',
          // データ
          data: manufyearPlanurPriorityDomainRegularEmployeeRowData as EnqueteAnswerLoadInputEntity[],
        },
        // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 回答情報
        {
          id: 'manufyearPlanurPriorityDomainOtherGrid',
          // タイトル
          title: useJudg.getText(manufyearPlanurEmploymentPriorityDomainTabFormData.tabFormData[1]['tabTitle'] as languageDataTypes),
          // タイプ
          type: 'grid',
          // データ
          data: manufyearPlanurPriorityDomainOtherEmployeesRowData as EnqueteAnswerLoadInputEntity[],
        },
        // 製造原価人員(重点分野)：年計-実見 回答情報
        {
          id: 'planVsAct',
          // タイプ
          type: 'diffForm',
          // データ
          data: isPriorityDomainDiffValueChangeNumber(
            props.manufyearPlanurPriorityDomainResultAndPlanDiffForm,
          ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
        },
        // 製造原価人員(重点分野)：中計-年計 回答情報
        {
          id: 'midTermPlanVsPlan',
          // タイプ
          type: 'diffForm',
          // データ
          data: isPriorityDomainDiffValueChangeNumber(
            props.manufyearPlanurPriorityDomainPlanAndPlanDiffForm,
          ) as PersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
        },
      ],
    };
  } else {
    manufyearPlanurEmploymentPriorityDomainTabAnswerData = {};
  }

  // 上記で作成したタブ回答情報をくみわせて保存する回答情報を作成する
  const surveyFormAnswerData: SurveyFormAnswerData = {
    enqueteType: 'personnelSurvey',
    answerInputData: {
      tabData: [
        personnelEmploymentAnswerInputData,
        personnelFunctionTabAnswerData,
        manufyearPlanurEmploymentTabAnswerData,
        personnelPriorityDomainTabAnswerData,
        manufyearPlanurEmploymentPriorityDomainTabAnswerData,
      ],
    },
  };

  //-------------------------------------------------------------
  // コーポレート人員+営業人員グリッド合計値チェック
  //-------------------------------------------------------------
  const isGridTotalError = (): boolean => {
    const corporateResultForecastTotal = isNaN(Number(corporateGridTotal['resultForecast'])) ? 0 : Number(corporateGridTotal['resultForecast']);
    const salesResultForecastTotal = isNaN(Number(salesGridTotal['resultForecast'])) ? 0 : Number(salesGridTotal['resultForecast']);
    const personnelResultForecastTotal = isNaN(Number(personnelEmploymentTotal.resultForecast)) ? 0 : Number(personnelEmploymentTotal.resultForecast);
    if (personnelResultForecastTotal !== corporateResultForecastTotal + salesResultForecastTotal) {
      setHasErrorPersonnelGridNotMatchTotal(true);
      return true;
    }
    const corporateYearPlanTotal = isNaN(Number(corporateGridTotal['yearPlan'])) ? 0 : Number(corporateGridTotal['yearPlan']);
    const salesYearPlanTotal = isNaN(Number(salesGridTotal['yearPlan'])) ? 0 : Number(salesGridTotal['yearPlan']);
    const personnelYearPlanTotal = isNaN(Number(personnelEmploymentTotal.yearPlan)) ? 0 : Number(personnelEmploymentTotal.yearPlan);
    if (personnelYearPlanTotal !== corporateYearPlanTotal + salesYearPlanTotal) {
      setHasErrorPersonnelGridNotMatchTotal(true);
      return true;
    }
    const corporateMiddlePlanTotal = isNaN(Number(corporateGridTotal['midTermPlan'])) ? 0 : Number(corporateGridTotal['midTermPlan']);
    const salesMiddlePlanTotal = isNaN(Number(salesGridTotal['midTermPlan'])) ? 0 : Number(salesGridTotal['midTermPlan']);
    const personnelMiddlePlanTotal = isNaN(Number(personnelEmploymentTotal.midTermPlan)) ? 0 : Number(personnelEmploymentTotal.midTermPlan);
    if (personnelMiddlePlanTotal !== corporateMiddlePlanTotal + salesMiddlePlanTotal) {
      setHasErrorPersonnelGridNotMatchTotal(true);
      return true;
    }
    setHasErrorPersonnelGridNotMatchTotal(false);
    return false;
  };

  //-------------------------------------------------------------
  // コーポレート人員グリッド合計値チェック
  //-------------------------------------------------------------
  const isCorporateGridTotalOverError = (): boolean => {
    const corporateResultForecastTotal = isNaN(Number(corporateGridTotal['resultForecast'])) ? 0 : Number(corporateGridTotal['resultForecast']);
    const personnelEmploymentResultForecastTotal = isNaN(Number(personnelEmploymentTotal['resultForecast']))
      ? 0
      : Number(personnelEmploymentTotal['resultForecast']);
    if (personnelEmploymentResultForecastTotal < corporateResultForecastTotal) {
      setHasErrorPersonnelCorporateGridTotalOver(true);
      return true;
    }
    const corporateYearPlanTotal = isNaN(Number(corporateGridTotal['yearPlan'])) ? 0 : Number(corporateGridTotal['yearPlan']);
    const personnelEmploymentYearPlanTotal = isNaN(Number(personnelEmploymentTotal['yearPlan'])) ? 0 : Number(personnelEmploymentTotal['yearPlan']);
    if (personnelEmploymentYearPlanTotal < corporateYearPlanTotal) {
      setHasErrorPersonnelCorporateGridTotalOver(true);
      return true;
    }
    const corporateMiddlePlanTotal = isNaN(Number(corporateGridTotal['midTermPlan'])) ? 0 : Number(corporateGridTotal['midTermPlan']);
    const personnelEmploymentMidTermPlanTotal = isNaN(Number(personnelEmploymentTotal['midTermPlan']))
      ? 0
      : Number(personnelEmploymentTotal['midTermPlan']);
    if (personnelEmploymentMidTermPlanTotal < corporateMiddlePlanTotal) {
      setHasErrorPersonnelCorporateGridTotalOver(true);
      return true;
    }
    setHasErrorPersonnelCorporateGridTotalOver(false);
    return false;
  };

  //-------------------------------------------------------------
  // 営業人員グリッド合計値チェック
  //-------------------------------------------------------------
  const isSalesGridTotalOverError = (): boolean => {
    const salesResultForecastTotal = isNaN(Number(salesGridTotal['resultForecast'])) ? 0 : Number(salesGridTotal['resultForecast']);
    const personnelEmploymentResultForecastTotal = isNaN(Number(personnelEmploymentTotal['resultForecast']))
      ? 0
      : Number(personnelEmploymentTotal['resultForecast']);
    if (personnelEmploymentResultForecastTotal < salesResultForecastTotal) {
      setHasErrorPersonnelSalesGridTotalOver(true);
      return true;
    }
    const salesYearPlanTotal = isNaN(Number(salesGridTotal['yearPlan'])) ? 0 : Number(salesGridTotal['yearPlan']);
    const personnelEmploymentYearPlanTotal = isNaN(Number(personnelEmploymentTotal['yearPlan'])) ? 0 : Number(personnelEmploymentTotal['yearPlan']);
    if (personnelEmploymentYearPlanTotal < salesYearPlanTotal) {
      setHasErrorPersonnelSalesGridTotalOver(true);
      return true;
    }
    const corporateMiddlePlanTotal = isNaN(Number(salesGridTotal['midTermPlan'])) ? 0 : Number(salesGridTotal['midTermPlan']);
    const personnelEmploymentMidTermPlanTotal = isNaN(Number(personnelEmploymentTotal['midTermPlan']))
      ? 0
      : Number(personnelEmploymentTotal['midTermPlan']);
    if (personnelEmploymentMidTermPlanTotal < corporateMiddlePlanTotal) {
      setHasErrorPersonnelSalesGridTotalOver(true);
      return true;
    }
    setHasErrorPersonnelSalesGridTotalOver(false);
    return false;
  };

  //-------------------------------------------------------------
  // 「その他」詳細入力チェック
  //-------------------------------------------------------------
  const shouldOtherDetailTextFormInput = (): boolean => {
    const tempOtherValue =
      props.personnelCorporateRowData !== undefined ? props.personnelCorporateRowData.filter((x) => x.id === 'Other') : undefined;
    const otherYearPlanValue = undefined !== tempOtherValue ? tempOtherValue[0].yearPlan : 0;
    const otherDetail = props.personnelCorporateOtherFormTextValue;
    const checkYearPlanValue = 100;
    if (otherYearPlanValue !== undefined) {
      // 「その他」の年計が100を超えているか？
      if (otherYearPlanValue >= checkYearPlanValue) {
        // 「その他」詳細が記入してあるか？
        if (otherDetail) {
          setHasErrorOtherTextForm(false);
        } else {
          setHasErrorOtherTextForm(true);
          return true;
        }
      } else {
        setHasErrorOtherTextForm(false);
      }
    } else {
      setHasErrorOtherTextForm(false);
    }
    return false;
  };

  //-------------------------------------------------------------
  // 重点分野別 グリッド合計値チェック
  //-------------------------------------------------------------
  const isPriorityDomainTotalOverError = (
    // 重点分野データの合計値
    totalRowData: EnqueteAnswerInputEntity,
    // 雇用形態別で入力されたデータ
    personnelEmploymentRowData: EnqueteAnswerLoadInputEntity,
  ): boolean => {
    // 重点分野別で入力された実見の合計
    const resultForecastTotal = isNaN(Number(totalRowData['resultForecast'])) ? 0 : Number(totalRowData['resultForecast']);
    // 人件費人員（雇用形態別）あるいは製造原価人員(雇用形態別)で入力された実見
    const personnelEmploymentResultForecast = isNaN(Number(personnelEmploymentRowData['resultForecast']))
      ? 0
      : Number(personnelEmploymentRowData['resultForecast']);
    // 同じではない場合
    if (resultForecastTotal !== personnelEmploymentResultForecast) {
      // エラーになる
      return true;
    }
    // 重点分野別で入力された年計の合計
    const yearPlanTotal = isNaN(Number(totalRowData['yearPlan'])) ? 0 : Number(totalRowData['yearPlan']);
    // 人件費人員（雇用形態別）あるいは製造原価人員(雇用形態別)で入力された年計
    const personnelEmploymentYearPlan = isNaN(Number(personnelEmploymentRowData['yearPlan'])) ? 0 : Number(personnelEmploymentRowData['yearPlan']);
    // 同じではない場合
    if (yearPlanTotal !== personnelEmploymentYearPlan) {
      // エラーになる
      return true;
    }
    // 重点分野別で入力された中計の合計
    const middlePlanTotal = isNaN(Number(totalRowData['midTermPlan'])) ? 0 : Number(totalRowData['midTermPlan']);
    // 人件費人員（雇用形態別）あるいは製造原価人員(雇用形態別)で入力された中計
    const personnelEmploymentMidTermPlan = isNaN(Number(personnelEmploymentRowData['midTermPlan']))
      ? 0
      : Number(personnelEmploymentRowData['midTermPlan']);
    // 同じではない場合
    if (middlePlanTotal !== personnelEmploymentMidTermPlan) {
      // エラーになる
      return true;
    }
    // エラーがない
    return false;
  };

  // ユーザー情報宣言
  const userId = null !== user ? user.username : '';
  const userEmail = null !== user ? user.email : '';

  //-------------------------------------------------------------
  // 「提出」処理
  //-------------------------------------------------------------
  const InputSubmitHandler = async () => {
    //ユーザー名取得
    const myInfo = await userApi.getByEmail(userEmail);
    if (!myInfo || myInfo.length === 0) {
      throw Error(t('message.exceptionNotFoundUserData'));
    }

    let params: EnqueteAnswerEntity = {
      userId: userId,
      userName: myInfo[0].fullNameJpn,
      userNameEng: myInfo[0].fullNameEng,
      status: 'ANSWERED',
      respondentTargetId: props.respondentTargetId ? props.respondentTargetId : '',
      answerData: surveyFormAnswerData,
      isDeleted: false,
    };

    //-------------------------------------------------------------
    // エラーチェック：人件費人員(雇用形態別)
    //-------------------------------------------------------------
    // 人件費人員(雇用形態別)：グリッドにエラー無しフラグ
    let tempNotErrorPersonnelEmploymentTab = true;
    // 人件費人員(雇用形態別)：年計-実見入力内容のエラーフラグ
    let tempPersonnelEmploymentResultAndPlanDiffForm = false;
    // 人件費人員(雇用形態別)：中計-年計入力内容のエラーフラグ
    let tempPersonnelEmploymentPlanAndPlanDiffForm = false;

    if (props.personnelEmploymentResultAndPlanDiffForm !== undefined) {
      await setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(false);
      tempPersonnelEmploymentResultAndPlanDiffForm = isEmptyCheckDiffInputValue(props.personnelEmploymentResultAndPlanDiffForm);
      if (tempPersonnelEmploymentResultAndPlanDiffForm) {
        await setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(true);
      }
    }
    if (props.personnelEmploymentPlanAndPlanDiffForm !== undefined) {
      await setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(false);
      tempPersonnelEmploymentPlanAndPlanDiffForm = isEmptyCheckDiffInputValue(props.personnelEmploymentPlanAndPlanDiffForm);
      if (tempPersonnelEmploymentPlanAndPlanDiffForm) {
        await setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(true);
      }
    }
    if (
      hasErrorPersonnelEmploymentResultAndPlanDiffForm ||
      hasErrorPersonnelEmploymentPlanAndPlanDiffForm ||
      tempPersonnelEmploymentResultAndPlanDiffForm ||
      tempPersonnelEmploymentPlanAndPlanDiffForm
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_1'), { variant: 'error' });
      tempNotErrorPersonnelEmploymentTab = false;
    }
    //-------------------------------------------------------------
    // エラーチェック：人件費人員(機能別)
    //-------------------------------------------------------------
    let tempNotErrorPersonnelCorporateTab = true;
    // 人件費人員(雇用形態別)Total === コーポレート人員Total + 営業人員Total をチェック
    if (isGridTotalError()) {
      tempNotErrorPersonnelCorporateTab = false;
    }
    if (isCorporateGridTotalOverError()) {
      tempNotErrorPersonnelCorporateTab = false;
    }
    if (isSalesGridTotalOverError()) {
      tempNotErrorPersonnelCorporateTab = false;
    }
    // その他詳細の入力チェック：人件費人員(機能別)
    if (shouldOtherDetailTextFormInput()) {
      tempNotErrorPersonnelCorporateTab = false;
    }

    if (!tempNotErrorPersonnelCorporateTab) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_2'), { variant: 'error' });
    }
    //-------------------------------------------------------------
    // エラーチェック：人件費人員（重点分野別）
    //-------------------------------------------------------------
    // 人件費人員（重点分野別）：グリッドにエラー無しフラグ
    let tempNotErrorPersonnelPriorityDomainTab = true;
    // 人件費人員（重点分野別）：年計-実見入力内容のエラーフラグ
    let tempPersonnelPriorityDomainResultAndPlanDiffForm = false;
    // 人件費人員（重点分野別）：中計-年計入力内容のエラーフラグ
    let tempPersonnelPriorityDomainPlanAndPlanDiffForm = false;
    // ディフォルト値
    let personnelEmploymentRowData: EnqueteAnswerLoadInputEntity = { id: 'rowData' };
    // 人件費人員(重点分野)正社員の合計チェック
    const hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainRegularEmployeeGridTotal,
      props.personnelEmploymentRowData !== undefined ? props.personnelEmploymentRowData[0] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)正社員のエラー状態を設定する
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal(hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal);
    // 人件費人員(重点分野)TTCからの出向受入の合計チェック
    const hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainSecondmentFromTTCGridTotal,
      props.personnelEmploymentRowData !== undefined ? props.personnelEmploymentRowData[1] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)TTCからの出向受入のエラー状態を設定する
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal(hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal);
    // 人件費人員(重点分野)2以外の出向受入の合計チェック
    const hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainSecondmentFromOtherGridTotal,
      props.personnelEmploymentRowData !== undefined ? props.personnelEmploymentRowData[2] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)2以外の出向受入のエラー状態を設定する
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal(hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal);
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトの合計チェック
    const hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal = isPriorityDomainTotalOverError(
      personnelPriorityDomainDispatchEtcGridTotal,
      props.personnelEmploymentRowData !== undefined ? props.personnelEmploymentRowData[3] : personnelEmploymentRowData,
    );
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトのエラー状態を設定する
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal(hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal);
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトの合計チェック
    // 人件費人員（重点分野別）に人員合計と（参考）人件費人員（雇用形態別）で入力した人件費に紐づく人員数が同じではないタブがある場合
    if (
      hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal ||
      hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal ||
      hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal ||
      hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal
    ) {
      // 人件費人員（重点分野別）：グリッドにエラーがある
      tempNotErrorPersonnelPriorityDomainTab = false;
    }
    if (props.personnelPriorityDomainResultAndPlanDiffForm !== undefined) {
      // エラー状態の初期値を設定する
      await setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(false);
      // データ全部入力されているチェック
      tempPersonnelPriorityDomainResultAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(props.personnelPriorityDomainResultAndPlanDiffForm);
      // エラーがある場合
      if (tempPersonnelPriorityDomainResultAndPlanDiffForm) {
        // データ全部入力されていない
        await setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(true);
      }
    }
    if (props.personnelPriorityDomainPlanAndPlanDiffForm !== undefined) {
      // エラー状態の初期値を設定する
      await setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(false);
      // データ全部入力されているチェック
      tempPersonnelPriorityDomainPlanAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(props.personnelPriorityDomainPlanAndPlanDiffForm);
      // エラーがある場合
      if (tempPersonnelPriorityDomainPlanAndPlanDiffForm) {
        // データ全部入力されていない
        await setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(true);
      }
    }
    // 人件費人員（重点分野別）のタブにエラーがある場合
    if (
      hasErrorPersonnelPriorityDomainResultAndPlanDiffForm ||
      hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm ||
      tempPersonnelPriorityDomainResultAndPlanDiffForm ||
      tempPersonnelPriorityDomainPlanAndPlanDiffForm ||
      !tempNotErrorPersonnelPriorityDomainTab
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_4'), { variant: 'error' });
      // グリッドにエラーがある
      tempNotErrorPersonnelPriorityDomainTab = false;
    }

    //-------------------------------------------------------------
    // エラーチェック：製造原価人員(雇用形態別)
    //-------------------------------------------------------------
    // 製造原価人員(雇用形態別)：グリッドにエラー無しフラグ
    let tempNotErrorManufyearPlanurEmploymentTab = true;
    // 製造原価人員(雇用形態別)：年計-実見入力内容のエラーフラグ
    let tempManufyearPlanurEmploymentResultAndPlanDiffForm = false;
    // 製造原価人員(雇用形態別)：中計-年計入力内容のエラーフラグ
    let tempManufyearPlanurEmploymentPlanAndPlanDiffForm = false;

    if (props.manufyearPlanurEmploymentResultAndPlanDiffForm !== undefined) {
      await setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm(false);
      tempManufyearPlanurEmploymentResultAndPlanDiffForm = isEmptyCheckDiffInputValue(props.manufyearPlanurEmploymentResultAndPlanDiffForm);
      if (tempManufyearPlanurEmploymentResultAndPlanDiffForm) {
        await setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm(true);
      }
    }
    if (props.manufyearPlanurEmploymentPlanAndPlanDiffForm !== undefined) {
      await setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(false);
      tempManufyearPlanurEmploymentPlanAndPlanDiffForm = isEmptyCheckDiffInputValue(props.manufyearPlanurEmploymentPlanAndPlanDiffForm);
      if (tempManufyearPlanurEmploymentPlanAndPlanDiffForm) {
        await setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(true);
      }
    }

    if (
      hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm ||
      hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm ||
      tempManufyearPlanurEmploymentResultAndPlanDiffForm ||
      tempManufyearPlanurEmploymentPlanAndPlanDiffForm
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_3'), { variant: 'error' });
      tempNotErrorManufyearPlanurEmploymentTab = false;
    }

    //-------------------------------------------------------------
    // エラーチェック：製造原価人員（重点分野別）
    //-------------------------------------------------------------
    // 製造原価人員（重点分野別）：グリッドにエラー無しフラグ
    let tempNotErrorManufyearPlanurPriorityDomainTab = true;
    // 製造原価人員（重点分野別）：年計-実見入力内容のエラーフラグ
    let tempManufyearPlanurPriorityDomainResultAndPlanDiffForm = false;
    // 製造原価人員（重点分野別）：中計-年計入力内容のエラーフラグ
    let tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm = false;
    // ディフォルト値
    let manufyearPlanurEmploymentStatusRowData: EnqueteAnswerLoadInputEntity = { id: 'rowData' };
    // 製造原価人員(重点分野)正社員の合計チェック
    const hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal = isPriorityDomainTotalOverError(
      manufyearPlanurPriorityDomainRegularEmployeeGridTotal,
      props.manufyearPlanurEmploymentStatusRowData !== undefined
        ? props.manufyearPlanurEmploymentStatusRowData[0]
        : manufyearPlanurEmploymentStatusRowData,
    );
    // 製造原価人員(重点分野)正社員のエラー状態を設定する
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal(hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal);
    // 製造原価人員(重点分野)1以外の従業員/派遣/その他の合計チェック
    const hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal = isPriorityDomainTotalOverError(
      manufyearPlanurPriorityDomainOtherEmployeesGridTotal,
      props.manufyearPlanurEmploymentStatusRowData !== undefined
        ? props.manufyearPlanurEmploymentStatusRowData[1]
        : manufyearPlanurEmploymentStatusRowData,
    );
    // 製造原価人員(重点分野)正社員のエラー状態を設定する
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal(hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal);
    // 製造原価人員(重点分野別)に人員合計と（参考）製造原価人員(雇用形態別)で入力した人件費に紐づく人員数が同じではないタブがある場合
    if (
      hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal ||
      hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal
    ) {
      // 製造原価人員（重点分野別）：グリッドにエラーがある
      tempNotErrorManufyearPlanurPriorityDomainTab = false;
    }
    if (props.manufyearPlanurPriorityDomainResultAndPlanDiffForm !== undefined) {
      // エラー状態の初期値を設定する
      await setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(false);
      // データ全部入力されているチェック
      tempManufyearPlanurPriorityDomainResultAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(
        props.manufyearPlanurPriorityDomainResultAndPlanDiffForm,
      );
      // エラーがある場合
      if (tempManufyearPlanurPriorityDomainResultAndPlanDiffForm) {
        // データ全部入力されていない
        await setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(true);
      }
    }
    if (props.manufyearPlanurPriorityDomainPlanAndPlanDiffForm !== undefined) {
      // エラー状態の初期値を設定する
      await setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(false);
      // データ全部入力されているチェック
      tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm = isEmptyCheckPriorityDomainDiffInputValue(
        props.manufyearPlanurPriorityDomainPlanAndPlanDiffForm,
      );
      // エラーがある場合
      if (tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm) {
        // データ全部入力されていない
        await setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(true);
      }
    }
    // 製造原価人員（重点分野別）のタブにエラーがある場合
    if (
      hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm ||
      hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm ||
      tempManufyearPlanurPriorityDomainResultAndPlanDiffForm ||
      tempManufyearPlanurPriorityDomainPlanAndPlanDiffForm ||
      !tempNotErrorManufyearPlanurPriorityDomainTab
    ) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_5'), { variant: 'error' });
      // グリッドにエラーがある
      tempNotErrorManufyearPlanurPriorityDomainTab = false;
    }

    // エラーが無い場合に保存処理を行う
    if (
      tempNotErrorPersonnelEmploymentTab &&
      tempNotErrorPersonnelCorporateTab &&
      tempNotErrorManufyearPlanurEmploymentTab &&
      tempNotErrorPersonnelPriorityDomainTab &&
      tempNotErrorManufyearPlanurPriorityDomainTab
    ) {
      try {
        if (props.enqueteId) {
          // update
          await updateAnswer(params, props.enqueteId);
        } else {
          // create
          await saveAnswer(params);
        }
      } catch (error) {}
    } else {
      return;
    }
  };

  //-------------------------------------------------------------
  // 「一時保存」処理
  //-------------------------------------------------------------

  const InputTempSubmitHandler = async () => {
    //ユーザー名取得
    const myInfo = await userApi.getByEmail(userEmail);
    if (!myInfo || myInfo.length === 0) {
      throw Error(t('message.exceptionNotFoundUserData'));
    }

    let params: EnqueteAnswerEntity = {
      userId: userId,
      userName: myInfo[0].fullNameJpn,
      userNameEng: myInfo[0].fullNameEng,
      status: 'SAVED',
      respondentTargetId: props.respondentTargetId ? props.respondentTargetId : '',
      answerData: surveyFormAnswerData,
      isDeleted: false,
    };
    // エラー無しフラグ：人件費人員(雇用形態別)
    let tempNotErrorPersonnelEmploymentTab = true;
    if (hasErrorPersonnelEmploymentResultAndPlanDiffForm || hasErrorPersonnelEmploymentPlanAndPlanDiffForm) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_1'), { variant: 'error' });
      tempNotErrorPersonnelEmploymentTab = false;
    }
    // エラー無しフラグ：製造原価人員(雇用形態別)
    let tempNotErrorManufyearPlanurEmploymentTab = true;
    if (hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm || hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_3'), { variant: 'error' });
      tempNotErrorManufyearPlanurEmploymentTab = false;
    }
    // エラー無しフラグ：人件費人員（重点分野別）
    let tempNotErrorPersonnelPriorityDomainTab = true;
    // 人件費人員（重点分野別）年計 - 実見　あるいは　中計 - 年計のデータの形はエラーがある
    if (hasErrorPersonnelPriorityDomainResultAndPlanDiffForm || hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_4'), { variant: 'error' });
      // グリッドにエラーがある
      tempNotErrorPersonnelPriorityDomainTab = false;
    }
    // エラー無しフラグ：製造原価人員（重点分野別）
    let tempNotErrorManufyearPlanurPriorityDomainTab = true;
    // 製造原価人員（重点分野別）年計 - 実見　あるいは　中計 - 年計のデータの形はエラーがある
    if (hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm || hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm) {
      enqueueSnackbar(t('enqueteAnswerInput.message.existErrorInput_5'), { variant: 'error' });
      // グリッドにエラーがある
      tempNotErrorManufyearPlanurPriorityDomainTab = false;
    }
    // エラーが無い場合に保存処理を行う
    if (
      tempNotErrorPersonnelEmploymentTab &&
      tempNotErrorManufyearPlanurEmploymentTab &&
      tempNotErrorPersonnelPriorityDomainTab &&
      tempNotErrorManufyearPlanurPriorityDomainTab
    ) {
      // ここのtry-cathcは、呼び出し先で巻き取ってるので不要
      try {
        if (props.enqueteId) {
          // update
          await tempUpdateAnswer(params, props.enqueteId);
        } else {
          // create
          await tempSaveAnswer(params);
        }
      } catch (error) {}
    }
  };
  const marginTopValue =
    props.comifrmRequestId && (confirmRequestData.confirmerId === userId || userRole.includes(BonsaiRole.ADMIN)) ? '20px' : '50px';

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ marginTop: marginTopValue }}>
      <StyledButton onClick={InputTempSubmitHandler} isDisabled={isAnswered}>
        {t('button.temporarilySave')}
      </StyledButton>
      <StyledButton onClick={InputSubmitHandler} isDisabled={!isEnqueteAnswerOpen}>
        {t('button.submit')}
      </StyledButton>
    </Stack>
  );
};
