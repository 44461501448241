import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { NotificationEditListLayout } from '../components/pages/edit-list/components/NotificationEditListLayout';

export const NotificationEditListPage = () => {
  const { t } = useTranslation();
  const pageTitle = t('notifications.editListPage.pageTitle');
  const breadcrumbs: HeaderBreadcrumbsProps = {
    heading: pageTitle,
    links: [{ name: 'TOP', href: '/top' }],
    activeLast: true,
  };

  return <NotificationEditListLayout pageTitle={pageTitle} breadCrumbs={breadcrumbs} />;
};
